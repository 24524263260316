import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import { CiSquarePlus } from "react-icons/ci";
import ExtraInfoItemView from "./ExtraInfoItemView";
import DataUtils from "../utils/DataUtils";
import {useParams} from "react-router-dom";

const AddButton = styled.button`
  border: none;
  background: none;
  margin-left: auto;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: #ffcccb;
  }
`;

const ExtraInformationView = ({ dataObjList, setDataObjList, setIsValidMap, objKey, title, height, isForEdit }) => {

    const { t } = useTranslation();
    const { menuId } = useParams();

    /* states */
    const [genCount, setGenCount] = useState(1);

    /* handler */
    const handleAddInfo = async () => {
        let generatedInfoKey = t("menu.franchiseMenu.menuInfo.detail.extraInfo.default.key") + " " + genCount;
        let generatedInfoValue = t("menu.franchiseMenu.menuInfo.detail.extraInfo.default.value") + " " + genCount;
        let genCountTemp = genCount;
        while (DataUtils.isObjKeyExistInObjArray(dataObjList, generatedInfoKey)) {
            generatedInfoKey = t("menu.franchiseMenu.menuInfo.detail.extraInfo.default.key") + " " + (genCountTemp + 1);
            generatedInfoValue = t("menu.franchiseMenu.menuInfo.detail.extraInfo.default.value") + " " + (genCountTemp + 1);
            genCountTemp = genCountTemp + 1;
        }
        setGenCount(genCountTemp + 1);
        let newDataObjList = [...dataObjList];
        let newObject = {};
        newObject[generatedInfoKey] = generatedInfoValue;
        newDataObjList.push(newObject);
        setDataObjList(newDataObjList);
        /* check validity */
        let newIsValid = true;
        for (let i=0 ; i < newDataObjList.length ; i++) {
            if ((Object.keys(newDataObjList[i])[0] === "") || (Object.values(newDataObjList[i])[0] === "")) {
                newIsValid = false;
            }
        }
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[objKey] = newIsValid;
            return newObj;
        });
    };

    const handleKeyChange = (index, prevKey, newKey) => {
        let newDataObjList = [...dataObjList];
        newDataObjList[index][newKey] = newDataObjList[index][prevKey];
        delete newDataObjList[index][prevKey];
        setDataObjList(newDataObjList);
        /* check validity */
        let newIsValid = true;
        for (let i=0 ; i < newDataObjList.length ; i++) {
            if ((Object.keys(newDataObjList[i])[0] === "") || (Object.values(newDataObjList[i])[0] === "")) {
                newIsValid = false;
            }
        }
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[objKey] = newIsValid;
            return newObj;
        });
    };

    const handleValueChange = (index, key, newValue) => {
        let newDataObjList = [...dataObjList];
        newDataObjList[index][key] = newValue;
        setDataObjList(newDataObjList);
        /* check validity */
        let newIsValid = true;
        for (let i=0 ; i < newDataObjList.length ; i++) {
            if ((Object.keys(newDataObjList[i])[0] === "") || (Object.values(newDataObjList[i])[0] === "")) {
                newIsValid = false;
            }
        }
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[objKey] = newIsValid;
            return newObj;
        });
    };

    const handleDeleteObj = (index) => {
        let newDataObjList = [...dataObjList];
        newDataObjList.splice(index, 1);
        setDataObjList(newDataObjList);
        /* check validity */
        if (newDataObjList.length === 0) {
            setIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj[objKey] = true;
                return newObj;
            });
        } else {
            let newIsValid = true;
            for (let i=0 ; i < newDataObjList.length ; i++) {
                if ((Object.keys(newDataObjList[i])[0] === "") || (Object.values(newDataObjList[i])[0] === "")) {
                    newIsValid = false;
                }
            }
            setIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj[objKey] = newIsValid;
                return newObj;
            });
        }
    };

    /* effects */
    useEffect(() => {
        setGenCount(1);
    }, [menuId]);

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <div
                style={{
                    textAlign: 'left',
                    paddingLeft: '10px',
                    marginBottom: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {/* title */}
                <span
                    style={{
                        color: '#8f8f8f',
                        fontSize: '14px'
                    }}
                >
                    {title}
                </span>
                {/* add button */}
                <AddButton
                    onClick={handleAddInfo}
                >
                    <CiSquarePlus
                        size={"25"}
                    />
                </AddButton>
            </div>
            <div
                style={{
                    width: '100%',
                    height: height + 'px',
                    overflow: 'auto',
                    border: '1px solid #ebebeb',
                    color: '#696969',
                    borderRadius: '5px',
                    padding: '10px',
                    textAlign: 'center'
                }}
            >
                {
                    (dataObjList.length > 0) ?
                        dataObjList.map((object, index) => {
                            return (
                                <ExtraInfoItemView
                                    key={index}
                                    item={object}
                                    index={index}
                                    handleKeyChange={handleKeyChange}
                                    handleValueChange={handleValueChange}
                                    isForEdit={isForEdit}
                                    onDelete={() => handleDeleteObj(index)}
                                />
                            );
                        })
                        :
                        <span
                            style={{
                                fontSize: '14px'
                            }}
                        >
                            {t("menu.franchiseMenu.message.noExtraInfo")}
                        </span>
                }
            </div>
        </div>
    );
};

export default ExtraInformationView;