import React from 'react';
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import {RiRefreshFill} from "react-icons/ri";
import styled from "styled-components";
import {Outlet, useLocation, useNavigate} from "react-router-dom";


const TitleContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 17px;
`;

const SelectButton = styled.button`
  height: auto;
  background: none;
  color: ${(props) => props.enabled ? "#fc7242" : "#c2c2c2"};
  border: 1px solid ${(props) => props.enabled ? "#fc7242" : "#c2c2c2"};
  border-radius: 10px;
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 10px;
  background-color: ${(props) => props.enabled ? "#fcf9ed" : "white"};
  
  &:focus {
    outline: none;
  }
`;

const BoothErrorView = () => {

    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    /* utils */
    const hasRelativePath = (value, depth) => {
        let path = location.pathname;
        let pathList = path.split('/');
        if (pathList.length === 0 || (pathList.length - 1) < depth) {
            return false;
        } else {
            return pathList[depth] === value;
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col>
                    {/* title */}
                    <TitleContainer>
                        <Title>
                            {t("menu.boothControl.sideMenus.boothErrors.title")}
                        </Title>
                    </TitleContainer>
                </Col>
            </Row>
            <Row
                style={{
                    marginTop: '5px'
                }}
            >
                {/* select buttons */}
                <div
                    style={{
                        width: '100%',
                        height: '50px',
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    }}
                >
                    <SelectButton
                        enabled={hasRelativePath("unresolved", 5)}
                        onClick={() => navigate("unresolved")}
                    >
                        {t("menu.boothControl.sideMenus.boothErrors.unresolvedError.title")}
                    </SelectButton>
                    <SelectButton
                        enabled={hasRelativePath("resolved", 5)}
                        onClick={() => navigate("resolved")}
                    >
                        {t("menu.boothControl.sideMenus.boothErrors.resolvedError.title")}
                    </SelectButton>
                </div>
            </Row>
            <Row
                style={{
                    marginTop: '5px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '665px'
                    }}
                >
                    <Outlet />
                </div>
            </Row>
        </Container>
    );
};

export default BoothErrorView;