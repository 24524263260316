import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import useInterval from "../../../customHooks/useInterval";
import DashboardAxiosService from "../service/DashBoardAxiosService";
import styled from "styled-components";
import _ from "lodash";
import { CiCircleChevRight } from "react-icons/ci";
import OverlayToolTip from "../../menu/common/toolTip/OverlayToolTip";
import {useNavigate} from "react-router-dom";
import {LuPackageCheck} from "react-icons/lu";
import {TbTruckDelivery} from "react-icons/tb";
import DataParseService from "../../../services/DataParseService";
import DateUtils from "../../schedule/utils/DateUtils";
import Chart from 'chart.js/auto';
import {Line} from "react-chartjs-2";

const LABEL_INIT = ['none'];

const chartJSOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
        },
    },
    scales: {
        y: {
            min: 0
        },
    },
};

const BlockContainer = styled.div`
  margin-right: ${props => props.isLast ? "0px" : "10px"};
  width: 100%;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  position: relative;
`;

const BlockTitle = styled.span`
  color: #757575;
  font-size: 14px;
  font-weight: bold;
`;

const MenuTitle = styled.span`
  color: #757575;
  font-size: 14px;
  position: absolute;
  left: 10px;
  top: 2%;
`;

const RelativeMenuTitle = styled.span`
  color: #757575;
  font-size: 14px;
`;

const SmallMessage = styled.span`
  color: #c2c2c2;
  font-size: 14px;
`;

const NumberView = styled.span`
  margin-top: 5px;
  color: ${props => props.color ? props.color : "#757575"};
  font-size: 27px;
  font-weight: bold;
`;

const PlusButton = styled.button`
  position: absolute;
  right: 5px;
  top: 1%;
  background: none;
  color: #c2c2c2;
  border: none;
  transition: all ease-in-out 0.2s;
  padding: 0px;
  
  &:hover {
    color: #757575;
  }
  
  &:focus {
    outline: none;
  }
`;

const CoverageSelectContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 10px;
  top: 2%;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CoverageSelectButton = styled.button`
  background: none;
  color: ${props => props.enabled ? "#fc7242" : "#c2c2c2"};
  border: 1px solid ${props => props.enabled ? "#fc7242" : "#c2c2c2"};
  transition: all ease-in-out 0.2s;
  padding: 5px 5px;
  margin-left: 15px;
  font-size: 14px;
  border-radius: 10px;

  &:focus {
    outline: none;
  }
`;

const DataRangeView = styled.span`
  position: absolute;
  bottom: 1%;
  left: 1%;
  color: #c2c2c2;
  font-size: 14px;
`;

const TotalAmountView = styled.span`
  position: absolute;
  top: 1%;
  right: 1%;
  color: #757575;
  font-size: 19px;
`;

const AmountUnitView = styled.span`
  margin-left: 10px;
  color: #111111;
  font-weight: bold;
`;


const colorMap = {
    "error" : "#ff5c33",
    "normal" : "#3399ff",
    "info" : "#fc7242"
};

const OrdersAndSalesPart = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [orderInfo, setOrderInfo] = useState({});
    const [salesInfo, setSalesInfo] = useState({});

    const [selectedCoverage, setSelectedCoverage] = useState("day"); /** day, week, month */

    const [isLoading, setIsLoading] = useState(true);

    const getOrderAndSalesInfo = async () => {
        await setIsLoading(true);
        const onSuccessCallback = async (data) => {
            let newOrderInfo = {
                "prepareOrderInfo" : data["prepareOrderInfo"],
                "onShipOrderInfo" : data["onShipOrderInfo"]
            };
            await setOrderInfo(newOrderInfo);
            await setSalesInfo(data['salesInfo']);
            await setSelectedCoverage("day");
            await formatDailyChart(data['salesInfo']['daily']['paymentList']);
            await setIsLoading(false);
        };
        const onFailureCallback = async (e) => {
            await setOrderInfo({});
            await setSalesInfo({});
            await setIsLoading(false);
            console.log(e);
        };
        await DashboardAxiosService.fetchOrderAndSalesInfo(onSuccessCallback, onFailureCallback);
    };

    /* effects */
    useEffect(() => {
        getOrderAndSalesInfo();
    }, []);

    /* update */
    /* TIMER : update date */
    /* every 300 sec */
    useInterval(() => {
        getOrderAndSalesInfo();
    }, 300000);

    /** ------------------------------------- */
    /** ------------------------------------- */
    /** Only for chart view ----------------- */

    const [chartData, setChartData] = useState({
        LABEL_INIT,
        datasets: [
            {
                data: [],
                borderColor: '#fc7242',
                backgroundColor: '#ffddcc',
                fill: true
            }
        ],
    });

    const getWeekDaysList = () => {
        const daysOfWeek = [];

        for (let day = 0; day < 7; day++) {
            const date = new Date(Date.UTC(2023, 0, day + 1));
            const dayName = new Intl.DateTimeFormat(i18n.language, { weekday: 'long' }).format(date);
            daysOfWeek.push(dayName);
        }

        return daysOfWeek;
    };

    const getWeekDayNumberWithDateTimeString = (str) => {
        const date = new Date(str);
        return date.getDay();
    };

    const getDaysOfTheNextMonth = (dateTimeString) => {
        const startDate = new Date(dateTimeString);

        const startOfNextMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
        const dateList = [];

        let currentDate = new Date(startOfNextMonth);
        while (currentDate.getMonth() === startOfNextMonth.getMonth()) {
            const formattedDate = new Intl.DateTimeFormat(i18n.language, {
                month: 'long',
                day: 'numeric'
            }).format(currentDate);
            dateList.push(formattedDate);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateList;
    };

    const formatDailyChart = async (givenData) => {
        let labels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
        for (let i=0 ; i<24 ; i++) {
            if (labels[i] < 12) {
                labels[i] = t("menu.dashboard.salesInfo.chart.hourAM", { hour : labels[i] });
            } else {
                labels[i] = t("menu.dashboard.salesInfo.chart.hourPM", { hour : labels[i] });
            }
        }
        let dataList = [];
        for (let i=0 ; i<24 ; i++) {
            dataList.push(0);
        }
        givenData.map((paymentObj) => {
            /* get amount and hour */
            let payedAmount = paymentObj['paymentAmount'];
            let dateTimeString = paymentObj['paymentDate'];
            let hour = parseInt(dateTimeString.split('T')[1].split(':')[0]);
            dataList[hour - 1] = dataList[hour - 1] + payedAmount;
        });
        let newChartData = {
            labels,
            datasets: [
                {
                    data: dataList,
                    borderColor: '#fc7242',
                    backgroundColor: '#ffddcc',
                    fill: true
                }
            ],
        };
        await setChartData(newChartData);
    };

    const formatWeeklyChart = async (givenData) => {
        let labels = getWeekDaysList();
        let dataList = [];
        for (let i=0 ; i<24 ; i++) {
            dataList.push(0);
        }
        givenData.map((paymentObj) => {
            /* get amount and day */
            let payedAmount = paymentObj['paymentAmount'];
            let dateTimeString = paymentObj['paymentDate'];
            let dayIndex = getWeekDayNumberWithDateTimeString(dateTimeString);
            dataList[dayIndex] = dataList[dayIndex] + payedAmount;
        });
        let newChartData = {
            labels,
            datasets: [
                {
                    data: dataList,
                    borderColor: '#fc7242',
                    backgroundColor: '#ffddcc',
                    fill: true
                }
            ],
        };
        await setChartData(newChartData);
    };

    const formatMonthlyChart = async (givenData, prevMonthDateTimeString) => {
        let labels = getDaysOfTheNextMonth(prevMonthDateTimeString);
        let dataList = [];
        for (let i=0 ; i<labels.length ; i++) {
            dataList.push(0);
        }
        givenData.map((paymentObj) => {
            /* get amount and day */
            let payedAmount = paymentObj['paymentAmount'];
            let dateTimeString = paymentObj['paymentDate'];
            let dateEntity = new Date(dateTimeString);
            let dayIndex = dateEntity.getDate();
            dataList[dayIndex - 1] = dataList[dayIndex - 1] + payedAmount;
        });
        let newChartData = {
            labels,
            datasets: [
                {
                    labels: 'Monthth',
                    data: dataList,
                    borderColor: '#fc7242',
                    backgroundColor: '#ffddcc',
                    fill: true
                }
            ],
        };
        await setChartData(newChartData);
    };


    useEffect(() => {
        if (salesInfo && (!_.isEqual(salesInfo, {}))) {
            if (selectedCoverage === "day") {
                formatDailyChart(salesInfo['daily']['paymentList']);
            } else if (selectedCoverage === "week") {
                formatWeeklyChart(salesInfo['weekly']['paymentList']);
            } else { /* month */
                formatMonthlyChart(salesInfo['monthly']['paymentList'], salesInfo['monthly']['startDate']);
            }
        }
    }, [selectedCoverage]);


    /** ------------------------------------- */
    /** ------------------------------------- */

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            {/* order information */}
            <div
                style={{
                    width: '50%',
                    height: '100%',
                    backgroundColor: 'white',
                    marginRight: '10px',
                    borderRadius: '5px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {/* title */}
                <MenuTitle>
                    {t("menu.dashboard.orderInfo.title")}
                </MenuTitle>
                {/* move button */}
                <OverlayToolTip
                    direction={"top"}
                    text={t("menu.dashboard.orderInfo.messages.moveToOrder")}
                >
                    <PlusButton
                        onClick={() => navigate('/order')}
                    >
                        <CiCircleChevRight size={"25"} />
                    </PlusButton>
                </OverlayToolTip>
                <BlockContainer>
                    <TbTruckDelivery
                        color={"#c2c2c2"}
                        size={"80"}
                    />
                    <BlockTitle>
                        {t("menu.dashboard.orderInfo.prepared")}
                    </BlockTitle>
                    {
                        isLoading &&
                        <Spinner size={"sm"} style={{ marginTop: '5px' }} />
                    }
                    {
                        (!isLoading) &&
                        (_.isEqual(orderInfo, {})) &&
                        <SmallMessage>
                            {t("menu.dashboard.orderInfo.messages.serverFailed")}
                        </SmallMessage>
                    }
                    {
                        (!isLoading) &&
                        (!_.isEqual(orderInfo, {})) &&
                        <NumberView
                            color={orderInfo['prepareOrderInfo']['count'] > 0 ? colorMap["info"] : null}
                        >
                            {orderInfo['prepareOrderInfo']['count']}
                        </NumberView>
                    }
                </BlockContainer>

                <BlockContainer
                    isLast={true}
                >
                    <LuPackageCheck
                        color={"#c2c2c2"}
                        size={"80"}
                    />
                    <BlockTitle>
                        {t("menu.dashboard.orderInfo.onShip")}
                    </BlockTitle>
                    {
                        isLoading &&
                        <Spinner size={"sm"} style={{ marginTop: '5px' }} />
                    }
                    {
                        (!isLoading) &&
                        (_.isEqual(orderInfo, {})) &&
                        <SmallMessage>
                            {t("menu.dashboard.orderInfo.messages.serverFailed")}
                        </SmallMessage>
                    }
                    {
                        (!isLoading) &&
                        (!_.isEqual(orderInfo, {})) &&
                        <NumberView
                            color={orderInfo['onShipOrderInfo']['count'] > 0 ? colorMap["info"] : null}
                        >
                            {orderInfo['onShipOrderInfo']['count']}
                        </NumberView>
                    }
                </BlockContainer>
            </div>
            {/* sales information */}
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {/* total sales */}
                {
                    salesInfo &&
                    (!_.isEqual(salesInfo, {})) &&
                    (selectedCoverage === "day") &&
                    <TotalAmountView>
                        {DataParseService.formatNumberWithCommas(salesInfo['daily']['totalAmount'])}
                        <AmountUnitView>
                            {"KRW"}
                        </AmountUnitView>
                    </TotalAmountView>
                }
                {
                    salesInfo &&
                    (!_.isEqual(salesInfo, {})) &&
                    (selectedCoverage === "week") &&
                    <TotalAmountView>
                        {DataParseService.formatNumberWithCommas(salesInfo['weekly']['totalAmount'])}
                        <AmountUnitView>
                            {"KRW"}
                        </AmountUnitView>
                    </TotalAmountView>
                }
                {
                    salesInfo &&
                    (!_.isEqual(salesInfo, {})) &&
                    (selectedCoverage === "month") &&
                    <TotalAmountView>
                        {DataParseService.formatNumberWithCommas(salesInfo['monthly']['totalAmount'])}
                        <AmountUnitView>
                            {"KRW"}
                        </AmountUnitView>
                    </TotalAmountView>
                }
                <CoverageSelectContainer>
                    {/* title */}
                    <RelativeMenuTitle>
                        {t("menu.dashboard.salesInfo.title")}
                    </RelativeMenuTitle>
                    {/* select coverage */}
                    <CoverageSelectButton
                        onClick={() => setSelectedCoverage("day")}
                        enabled={selectedCoverage === "day"}
                    >
                        {t("menu.dashboard.salesInfo.categories.daily")}
                    </CoverageSelectButton>
                    <CoverageSelectButton
                        onClick={() => setSelectedCoverage("week")}
                        enabled={selectedCoverage === "week"}
                    >
                        {t("menu.dashboard.salesInfo.categories.weekly")}
                    </CoverageSelectButton>
                    <CoverageSelectButton
                        onClick={() => setSelectedCoverage("month")}
                        enabled={selectedCoverage === "month"}
                    >
                        {t("menu.dashboard.salesInfo.categories.monthly")}
                    </CoverageSelectButton>
                </CoverageSelectContainer>
                {/* data duration view */}
                {
                    salesInfo &&
                    (!_.isEqual(salesInfo, {})) &&
                    ((selectedCoverage === "week") || (selectedCoverage === "month")) &&
                    <DataRangeView>
                        {
                            (selectedCoverage === "week") &&
                            t("menu.dashboard.salesInfo.messages.duration", {
                                "fromDate" : DateUtils.craftLocaleString(DataParseService.dateTimeToDate(salesInfo['weekly']['startDate']), i18n.language),
                                "toDate" : DateUtils.craftLocaleString(DataParseService.dateTimeToDate(salesInfo['weekly']['endDate']), i18n.language)
                            })
                        }
                        {
                            (selectedCoverage === "month") &&
                            t("menu.dashboard.salesInfo.messages.duration", {
                                "fromDate" : DateUtils.craftLocaleString(DataParseService.dateTimeToDate(salesInfo['monthly']['startDate']), i18n.language),
                                "toDate" : DateUtils.craftLocaleString(DataParseService.dateTimeToDate(salesInfo['monthly']['endDate']), i18n.language)
                            })
                        }
                    </DataRangeView>
                }

                {/* graph */}
                <div
                    style={{
                        width: '85%',
                        height: '170px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    }}
                >
                    {
                        salesInfo &&
                        (!_.isEqual(salesInfo, {})) &&
                        <Line
                            data={chartData}
                            options={chartJSOptions}
                            width={"100"}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default OrdersAndSalesPart;