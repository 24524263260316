
const hasGetCode = (code) => {
    return (code & 1) === 1;
}
const hasPutCode = (code) => {
    return (code & 2) === 2;
}

const hasPostCode = (code) => {
    return (code & 4) === 4;
}

const hasDeleteCode = (code) => {
    return (code & 8) === 8;
}

const deleteGetCode = (code) => {
    return code & 14;
}

const deletePutCode = (code) => {
    return code & 13;
}

const deletePostCode = (code) => {
    return code & 11;
}

const deleteDeleteCode = (code) => {
    return code & 7;
}

const addGetCode = (code) => {
    return code | 1;
}

const addPutCode = (code) => {
    return code | 2;
}

const addPostCode = (code) => {
    return code | 4;
}

const addDeleteCode = (code) => {
    return code | 8;
}

const AuthParseService = {
    hasGetCode,
    hasPutCode,
    hasPostCode,
    hasDeleteCode,
    deleteGetCode,
    deletePutCode,
    deletePostCode,
    deleteDeleteCode,
    addGetCode,
    addPutCode,
    addPostCode,
    addDeleteCode
};

export default AuthParseService;