import React, {useEffect, useState} from 'react';
import {Container, Row, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import TextCell from "./table/cells/TextCell";
import ErrorLevelCell from "./table/cells/ErrorLevelCell";
import DateTimeCell from "./table/cells/DateTimeCell";
import ErrorTable from "./table/ErrorTable";
import UserService from "../../../../../services/UserService";
import axios from "axios";
import BoothService from "../../axiosServices/BoothService";
import _ from "lodash";
import HoverMessageCell from "./table/cells/HoverMessageCell";

const UnresolvedErrorView = () => {

    const { t } = useTranslation();
    const { boothId } = useParams();


    /* states */
    const [jsonSchema, setJsonSchema] = useState({});
    const [currentErrorList, setCurrentErrorList] = useState([]);
    const [errorCodeDefineList, setErrorCodeDefineList] = useState([]);
    const [isJsonLoading, setIsJsonLoading] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);

    /* column data for the table */
    const columnData = [
        {
            accessor: "errorCode",
            Header: t("menu.boothControl.sideMenus.boothErrors.fields.errorCode"),
            Cell: ({cell: {value}}) => <TextCell value={value} />
        },
        {
            accessor: "level",
            Header: t("menu.boothControl.sideMenus.boothErrors.fields.level"),
            Cell: ({cell: {value}}) => <ErrorLevelCell value={value} />
        },
        {
            accessor: "device",
            Header: t("menu.boothControl.sideMenus.boothErrors.fields.device"),
            Cell: ({cell: {value}}) => <TextCell value={value} />
        },
        {
            accessor: "title",
            Header: t("menu.boothControl.sideMenus.boothErrors.fields.title"),
            Cell: ({cell: {value}}) => <HoverMessageCell value={value} />
        },
        {
            accessor: "lastProbeDate",
            Header: t("menu.boothControl.sideMenus.boothErrors.fields.lastProbeDate"),
            Cell: ({cell: {value}}) => <DateTimeCell value={value} />
        }
    ];

    const initialize = async () => {
        await setIsJsonLoading(true);
        await setIsDataLoading(true);
        fetchBoothInfo();
    };

    const fetchBoothInfo = async (callback) => {
        await setIsJsonLoading(true);
        const onSuccessCallback = async (data) => {
            await fetchJsonSchema(data['boothVersionInfo']['boothJsonVersion'], fetchErrorList);
        };
        const onFailureCallback = async (e) => {
            await setJsonSchema({});
            await setCurrentErrorList([]);
            await setIsJsonLoading(false);
            await setIsDataLoading(false);
            console.log(e);
        };
        await BoothService.fetchBoothBasicInfo(boothId, onSuccessCallback, onFailureCallback);
    };

    const fetchJsonSchema = async (boothJsonSchemaVersion, callback) => {
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
            + `/booth-control-wrapper/schema/${boothJsonSchemaVersion}`;
        try {
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setJsonSchema(res.data.item['booth']);
            await setIsJsonLoading(false);
            await callback();
        } catch (e) {
            console.log(e);
            await setJsonSchema({});
            await setCurrentErrorList([]);
            await setIsJsonLoading(false);
            await setIsDataLoading(false);
        }
    };

    const fetchErrorList = async () => {
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
            + `/booth/${boothId}/error-history/unresolved`;
        try {
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setCurrentErrorList(res.data.item['boothUnresolvedErrorList']);
            await setErrorCodeDefineList(res.data.item['boothErrorCodeList']);
            await setIsDataLoading(false);
        } catch (e) {
            console.log(e);
            await setCurrentErrorList([]);
            await setIsDataLoading(false);
        }
    };

    const formatErrorCodeList = (prevErrorCodeList) => {
        let newErrorCodeList = [];
        prevErrorCodeList.map(prevData => {
            let newData = {};
            newData['id'] = prevData['errorCode'] + prevData['lastProbeDate'];
            newData['lastProbeDate'] = prevData['lastProbeDate'];
            newData['errorCode'] = prevData['errorCode'];
            /** level and device */
            /* find by "errorCode" */
            let isFound = false;
            for (let i=0 ; i<errorCodeDefineList.length ; i++) {
                if (errorCodeDefineList[i]['errorCode'] === prevData['errorCode']) {
                    isFound = true;
                    newData['level'] = errorCodeDefineList[i]['level'];
                    newData['title'] = [errorCodeDefineList[i][t("language.dataKey.boothErrorCode.titleF")], errorCodeDefineList[i][t("language.dataKey.boothErrorCode.descriptionF")]];
                    newData['device'] = findDeviceNameFromSchema(jsonSchema, errorCodeDefineList[i]['device']);
                }
            }
            if (!isFound) {
                newData['level'] = "Unknown";
                newData['title'] = [t("menu.boothControl.sideMenus.boothErrors.fields.Unknown"), t("menu.boothControl.sideMenus.boothErrors.fields.Unknown")];
                newData['device'] = t("menu.boothControl.sideMenus.boothErrors.fields.Unknown");
            }
            newErrorCodeList.push(newData);
        });
        return newErrorCodeList;
    };

    const findDeviceNameFromSchema = (schema, id) => {
        if (schema == null || (_.isEqual(schema, {}))) {
            return t("menu.boothControl.sideMenus.boothErrors.fields.Unknown");
        }
        let deviceList = jsonSchema['spec']['devices'];
        let isFound = false;
        let res = "";
        deviceList.map(deviceObj => {
           if (deviceObj['metadata']['id'] === id) {
               isFound = true;
               res = deviceObj['metadata'][t("language.dataKey.boothControl.json.label")];
           }
        });
        if (isFound) {
            return res;
        } else {
            return t("menu.boothControl.sideMenus.boothErrors.fields.Unknown");
        }
    };

    /* effects */
    useEffect(() => {
        initialize();
    }, [boothId]);

    return (
        <Container fluid>
            <Row>
                {/* table view area */}
                <div
                    style={{
                        backgroundColor: 'white',
                        width: '100%',
                        height: '665px',
                        overflow: 'auto'
                    }}
                >
                    {
                        (isDataLoading || isJsonLoading) &&
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                padding: '50px'
                            }}
                        >
                            <Spinner size={"sm"} />
                        </div>
                    }
                    {
                        !isDataLoading &&
                        !isJsonLoading &&
                        (currentErrorList.length === 0) &&
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                padding: '50px',
                                fontSize: '14px',
                                color: '#757575'
                            }}
                        >
                            {t("menu.boothControl.sideMenus.boothErrors.messages.noErrorExist")}
                        </div>
                    }
                    {
                        !isDataLoading &&
                        !isJsonLoading &&
                        (currentErrorList.length > 0) &&
                        <ErrorTable
                            columns={columnData}
                            data={formatErrorCodeList(currentErrorList)}
                        />
                    }
                </div>
            </Row>
        </Container>
    );
};

export default UnresolvedErrorView;