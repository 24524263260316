import boothControlAuthService from "../auth/BoothControlAuthService";

/* -------------------------------- */
/* Supported Render Types           */
/* -- each type means ONLY          */
/* -- GET type is default for PUT   */
/* -------------------------------- */
/* STRING_GET                       */
/* STRING_PUT                       */
/* STRING_POST                      */
/* CAT_STRING_GET                   */
/* CAT_STRING_PUT                   */
/* CAT_STRING_POST                  */
/* INTEGER_GET                      */
/* INTEGER_PUT                      */
/* INTEGER_POST                     */
/* BOOLEAN_GET                      */
/* BOOLEAN_PUT                      */
/* BOOLEAN_POST                     */
/* PRIMITIVE_OBJECT_GET             */
/* PRIMITIVE_OBJECT_PUT             */
/* PRIMITIVE_OBJECT_POST            */
/* PRIMITIVE_ARRAY_GET              */
/* PRIMITIVE_ARRAY_PUT              */
/* -------------------------------- */
const extractTypeAndAuthCode = (schema) => {
    let res = {};
    res['authCode'] = schema['superPermission'];
    res['type'] = schema['primitive'];
    if (schema['primitive'] === 'string') {
        res['isCategorical'] = schema['isCategorical'];
    } else {
        res['isCategorical'] = false;
    }
    return res;
};

const extractRenderType = (dataSchema) => {
    let parseRes = extractTypeAndAuthCode(dataSchema);
    let type = parseRes['type']
    let authCode = parseRes['authCode'];
    if (type === "string") {
        if (parseRes['isCategorical']) {
            /* Categorical String */
            if (!boothControlAuthService.hasGetAuth(authCode)) {
                if (boothControlAuthService.hasPostAuth(authCode)) {
                    return "CAT_STRING_POST";
                } else {
                    return "NONE";
                }
            } else {
                if (boothControlAuthService.hasPutAuth(authCode)) {
                    return "CAT_STRING_PUT";
                } else {
                    return "CAT_STRING_GET";
                }
            }
        } else {
            /* Just String */
            if (!boothControlAuthService.hasGetAuth(authCode)) {
                if (boothControlAuthService.hasPostAuth(authCode)) {
                    return "STRING_POST";
                } else {
                    return "NONE";
                }
            } else {
                if (boothControlAuthService.hasPutAuth(authCode)) {
                    return "STRING_PUT";
                } else {
                    return "STRING_GET";
                }
            }
        }
    } else if (type === "integer") {
        if (!boothControlAuthService.hasGetAuth(authCode)) {
            if (boothControlAuthService.hasPostAuth(authCode)) {
                return "INTEGER_POST";
            } else {
                return "NONE";
            }
        } else {
            if (boothControlAuthService.hasPutAuth(authCode)) {
                return "INTEGER_PUT";
            } else {
                return "INTEGER_GET";
            }
        }
    } else if (type === "boolean") {
        if (!boothControlAuthService.hasGetAuth(authCode)) {
            if (boothControlAuthService.hasPostAuth(authCode)) {
                return "BOOLEAN_POST";
            } else {
                return "NONE";
            }
        } else {
            if (boothControlAuthService.hasPutAuth(authCode)) {
                return "BOOLEAN_PUT";
            } else {
                return "BOOLEAN_GET";
            }
        }
    } else if (type === "object") {
        if (!boothControlAuthService.hasGetAuth(authCode)) {
            if (boothControlAuthService.hasPostAuth(authCode)) {
                return "PRIMITIVE_OBJECT_POST";
            } else {
                return "NONE";
            }
        } else {
            if (boothControlAuthService.hasPutAuth(authCode)) {
                return "PRIMITIVE_OBJECT_PUT";
            } else {
                return "PRIMITIVE_OBJECT_GET";
            }
        }
    } else if (type === "array") {
        if (dataSchema['validator']['items']['type'] === "object") {
            /* Object array not supported! */
            return "NONE";
        } else {
            if (!boothControlAuthService.hasGetAuth(authCode)) {
                return "NONE";
            } else {
                if (boothControlAuthService.hasPutAuth(authCode)) {
                    return "PRIMITIVE_ARRAY_PUT";
                } else {
                    return "PRIMITIVE_ARRAY_GET";
                }
            }
        }
    } else {
        return "NONE";
    }
};

const extractListFromPattern = (pattern) => {
    let slicedStr = pattern.slice(2, -2);
    return slicedStr.split('|');
};

/* ----------------------------------- */
/* Supported array types               */
/* ----------------------------------- */
/* STRING_GET                          */
/* CAT_STRING_GET                      */
/* INTEGER_GET      (not implemented)  */
/* BOOLEAN_GET      (not implemented)  */
/* STRING_PUT       (not implemented)  */
/* CAT_STRING_PUT                      */
/* INTEGER_PUT      (not implemented)  */
/* BOOLEAN_PUT      (not implemented)  */
/* ----------------------------------- */
const extractArrayType = (primitive, isCategorical, isPutAuthExist) => {
    if (primitive === "string") {
        if (isCategorical) {
            if (isPutAuthExist) {
                return "CAT_STRING_PUT";
            } else {
                return "CAT_STRING_GET";
            }
        } else {
            if (isPutAuthExist) {
                return "STRING_PUT";
            } else {
                return "STRING_GET";
            }
        }
    } else if (primitive === "integer") {
        if (isPutAuthExist) {
            return "INTEGER_PUT";
        } else {
            return "INTEGER_GET";
        }
    } else if (primitive === "boolean") {
        if (isPutAuthExist) {
            return "BOOLEAN_PUT";
        } else {
            return "BOOLEAN_GET";
        }
    } else {
        return "NONE";
    }
};


const SchemaParser = {
    extractRenderType,
    extractListFromPattern,
    extractArrayType
};

export default SchemaParser;