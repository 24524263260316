import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import DataParseService from "../../services/DataParseService";
import RenderOnRole from "../../auth/RenderOnRole";
import {Container, Row, Spinner, Tooltip} from "react-bootstrap";
import { ReactComponent as MagnifierIcon } from '../../icons/ic_search.svg';
import Table from "../common/table/Table";
import PaginationHandler from "../common/pagination/PaginationHandler";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ReactComponent as BranchAddIcon } from "../../icons/add_branch.svg";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import styled from "styled-components";
import BranchTable from "./table/BranchTable";
import SelectSearch from "react-select-search";
import "../common/style/SelectSearchStyle.css";

const FranchiseExist = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #fc7242;
  background-color: white;
  color: #fc7242;
  padding: 5px 15px;
  font-size: 13px;
`;

const FranchiseNotExist = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #c2c2c2;
  background-color: white;
  color: #c2c2c2;
  padding: 5px 15px;
  font-size: 13px;
`;

const Empty = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid transparent;
  background: none;
  padding: 5px 5px;
  font-size: 13px;
`;

const BranchManagement = () => {

    const { t } = useTranslation();

    /* branch list */
    const [branchList, setBranchList] = useState([]);
    /* pagination */
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);
    /* search */
    const [searchField, setSearchField] = useState('all');
    const [searchLike, setSearchLike] = useState('');
    /** New: franchise */
    const [franchiseList, setFranchiseList] = useState([]);
    const [selectedFranchiseId, setSelectedFranchiseId] = useState("all"); /* all, none, {franchiseId} */

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    /* data re-formatting */
    const renameBranchIdToId = (objectList) => {
        let newObjList = [...objectList];
        const iter = 9 - objectList.length;
        if (newObjList.length < 9) {
            for (let i=0; i < iter; i++) {
                newObjList.push({
                    "branchId" : null
                });
            }
        }
        return newObjList.map(obj => {
            if ('branchId' in obj) {
                obj['id'] = obj['branchId'];
                delete obj['branchId'];
            }
            if ('registerDate' in obj) {
                obj['registerDate'] = DataParseService.dateTimeToDate(obj['registerDate']);
            }
            return obj;
        });
    }

    /* fetch branch list */
    const fetchBranchList = async () => {
        await setIsLoading(true);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/branch?size=9&sort=desc&page=${currentPageIndex}&fr_id=${selectedFranchiseId}`;
            if (searchLike !== '') {
                fetchUrl = fetchUrl + `&field=${searchField}&like=${searchLike}`;
            }
            const axiosCall = () => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                let dataList = [];
                for (let i=0; i<8; i++) {
                    dataList.push({
                        "id" : null
                    });
                }
                setBranchList(dataList);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            } else {
                let dataList = [...res.data.item.branchList];
                /** serialize franchise information */
                for (let i=0 ; i<dataList.length ; i++) {
                    if (dataList[i]['franchiseInformation'] == null) {
                        dataList[i]['franchiseName'] = "none";
                    } else {
                        dataList[i]['franchiseName'] = dataList[i]['franchiseInformation']['franchiseName'];
                    }
                }
                setBranchList(renameBranchIdToId(dataList));
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            }
        } catch (err) {
            console.log(err);
        } finally {
            await setIsLoading(false);
        }
    };

    /* fetch franchise list */
    const fetchFranchiseList = async () => {
        await setIsLoading(true);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`;
            const axiosCall = () => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            let newList = [];
            const dataList = res.data.item.franchiseList;
            dataList.map(groupAndFranchiseObj => {
                let tempGroupId = Object.keys(groupAndFranchiseObj)[0];
                let franchiseList = groupAndFranchiseObj[tempGroupId];
                for (let i=0 ; i<franchiseList.length ; i++) {
                    newList.push(franchiseList[i]);
                }
            });
            await setFranchiseList(await generateSelectSearchOption(newList));
        } catch (err) {
            await setFranchiseList([]);
            console.log(err);
        } finally {
            await setIsLoading(false);
        }
    };

    /* handlers */
    const handleSearchFieldChange = (value) => {
        setSearchField(value);
    };

    const handleSearchLikeChange = (value) => {
        setSearchLike(value);
    };

    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    };

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    };

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    };

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    };

    const enterEventHandler = (e) => {
        if (e.key === 'Enter') {
            fetchBranchList();
        }
    };

    /* table column data */
    const columnData = [
        {
            accessor: 'registerDate',
            Header: t('common.registerDate'),
        },
        {
            accessor: 'branchGroupName',
            Header: t('common.group'),
        },
        {
            accessor: 'franchiseName',
            Header: t('menu.branch.franchise.title'),
            Cell: ({ cell: { value } }) => <FranchiseNameView value={value} />
        },
        {
            accessor: 'branchMasterName',
            Header: t('common.masterName'),
        },
        {
            accessor: 'locationAddress',
            Header: t('common.address'),
        },
        {
            accessor: 'branchName',
            Header: t('common.branchName'),
        }
    ];

    const FranchiseNameView = (value) => {
        if (value.value == null) {
            return (
                <Empty>
                    {"　"}
                </Empty>
            );
        } else if (value.value == "none") {
            return (
                <FranchiseNotExist>
                    {t("menu.branch.franchise.search.none")}
                </FranchiseNotExist>
            );
        } else {
            return (
                <FranchiseExist>
                    {value.value}
                </FranchiseExist>
            );
        }
    };

    /* effects */
    useEffect(() => {
        fetchBranchList();
    }, [currentPageIndex, selectedFranchiseId]);

    useEffect(() => {
        fetchFranchiseList();
    }, []);

    /* tooltips */
    const renderToolTip = (name) => {
        return (
            <Tooltip id="tooltip">
                {name}
            </Tooltip>
        );
    };

    /* select search option generator */
    const generateSelectSearchOption = async (givenFranchiseList) => {
        let newOptions = [];
        {/* franchise list */}
        givenFranchiseList.map((franchiseInfo) => {
            newOptions.push({
                "name" : franchiseInfo['franchiseName'],
                "value" : franchiseInfo['franchiseId']
            });
        });
        newOptions.unshift({
            "name" : t("menu.branch.franchise.search.none"),
            "value" : "none"
        });
        newOptions.unshift({
            "name" : t("menu.branch.franchise.search.all"),
            "value" : "all"
        });
        return newOptions;
    };

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.branch.title")]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    {/* main area */}
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                height: '800px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {/* search bar */}
                            <div className={"contentInner"}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        display: 'flex'
                                    }}
                                >
                                    {/** New: franchise selection */}
                                    <div
                                        style={{
                                            width: '270px',
                                            height: '50px'
                                        }}
                                    >
                                        {
                                            franchiseList &&
                                            <SelectSearch
                                                options={franchiseList}
                                                value={selectedFranchiseId}
                                                name={"franchise-select"}
                                                placeholder={t("menu.franchise.selectFranchise")}
                                                search={true}
                                                autoComplete={true}
                                                onChange={async (value) => {
                                                    await setSelectedFranchiseId(value);
                                                }}
                                            />
                                        }
                                    </div>
                                    <div
                                        style={{
                                            width: '270px',
                                            height: '50px',
                                            marginLeft: '10px'
                                        }}
                                    >
                                        <select
                                            id={"search-field-select-box"}
                                            defaultValue={"all"}
                                            onChange={(e) => handleSearchFieldChange(e.target.value)}
                                        >
                                            <option value={"all"}>{t("common.search.fields.all")}</option>
                                            <option value={"id"}>{t("common.search.fields.id")}</option>
                                            <option value={"name"}>{t("common.search.fields.branchName")}</option>
                                            <option value={"masterId"}>{t("common.search.fields.customerId")}</option>
                                        </select>
                                    </div>
                                    <div
                                        style={{
                                            width: '270px',
                                            height: '50px',
                                            marginLeft: '10px'
                                        }}
                                    >
                                        <input
                                            id={"search-like-input"}
                                            type={"text"}
                                            placeholder={t("common.search.placeholder")}
                                            value={searchLike}
                                            onChange={(e) => handleSearchLikeChange(e.target.value)}
                                            onKeyDown={(e) => enterEventHandler(e)}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            marginLeft: '10px'
                                        }}
                                    >
                                        <button
                                            id={"box-btn"}
                                            onClick={() => fetchBranchList()}
                                        >
                                            <MagnifierIcon />
                                        </button>
                                    </div>
                                    {/* branch add button */}
                                    <RenderOnRole type={"POST"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        <div
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                marginLeft: 'auto'
                                            }}
                                        >
                                            <OverlayTrigger placement={"bottom"} overlay={renderToolTip(t("menu.branch.add.title"))}>
                                                <button
                                                    id={"box-btn"}
                                                    onClick={() => navigate(`/branch/add`)}
                                                >
                                                    <BranchAddIcon />
                                                </button>
                                            </OverlayTrigger>
                                        </div>
                                    </RenderOnRole>
                                </div>
                            </div>
                            <div
                                className={"contentInner"}
                                style={{
                                    marginTop: '10px'
                                }}
                            >
                                {/* table */}
                                <div
                                    style={{
                                        width: '100%',
                                        height: '600px',
                                        marginTop: '15px'
                                    }}
                                >
                                    <BranchTable columns={columnData} data={branchList} baseUrl={'detail'} />
                                </div>
                                {/* pagination */}
                                <div
                                    style={{
                                        width: '400px',
                                        height: '50px',
                                        marginTop: '20px',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    {/*goBefore, goAfter, goFirst, goLast, pageSize, currentIndex, maxIndex, totalItemNumber*/}
                                    <PaginationHandler
                                        goBefore={handleGoBefore}
                                        goAfter={handleGoAfter}
                                        goFirst={handleGoFirst}
                                        goLast={handleGoLast}
                                        pageSize={9}
                                        currentIndex={currentPageIndex}
                                        maxIndex={maxPageIndex}
                                        totalItemNumber={totalElements}
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default BranchManagement;