import React from 'react';
import styled from "styled-components";

const colors = {
    buttonColor : "#fc7242",
    buttonHoverColor : "#fff1ec",
    buttonDisabledColor : "#ebebeb"
};

const Button = styled.button`
  border: 1px solid ${colors['buttonColor']};
  border-radius: 10px;
  font-size: 14px;
  color: ${colors['buttonColor']};
  background: none;
  padding: 7px 15px;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${colors['buttonHoverColor']};
  }
`;

const ButtonDisabled = styled.button`
  border: 1px solid ${colors['buttonDisabledColor']};
  border-radius: 10px;
  font-size: 14px;
  color: ${colors['buttonDisabledColor']};
  background: none;
  padding: 7px 15px;
  transition: all ease-in-out 0.2s;

  &:focus {
    outline: none;
  }
`;

const OrangeButton = ({ onClickCallback, isEnabled, label, style }) => {

    if (isEnabled) {
        return (
            <Button
                style={
                    style ?
                        style
                        :
                        {}
                }
                onClick={() => onClickCallback()}
            >
                {label}
            </Button>
        );
    } else {
        return (
            <ButtonDisabled
                style={
                    style ?
                        style
                        :
                        {}
                }
            >
                {label}
            </ButtonDisabled>
        );
    }
};

export default OrangeButton;