import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import DataCompareService from "../../services/DataCompareService";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import axios from "axios";
import DataParseService from "../../services/DataParseService";
import UserService from '../../services/UserService';
import ModalCloseButton from "../common/modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";

const requestBodyInit = {
    onShipStartDate : "",
    comment : "",
    invoiceNumber : ""
};

const isValidMapInit = {
    onShipStartDate : true,
    comment : false,
    invoiceNumber : true
};

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '400px',
        height: '390px',
        margin: 'auto'
    }
}


const EditOnShipInfo = ({ orderId, isOpen, setIsOpen, onRefresh, prevData }) => {

    const { t } = useTranslation();
    const [fixedData, setFixedData] = useState(requestBodyInit);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    const [isEdited, setIsEdited] = useState(false);

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    /* handlers */
    const handleOnShipStartDateInput = (value) => {
        updateRequestBody("onShipStartDate", value);
    }

    const handleInvoiceNumberInput = (value) => {
        updateRequestBody("invoiceNumber", value);
    }

    const handleCommentInput = (value) => {
        updateRequestBody("comment", value);
    }

    /* validators */
    const validateOnShipStartDate = () => {
        if (requestBody["onShipStartDate"] === "") {
            updateIsValidMap("onShipStartDate", false);
        } else {
            updateIsValidMap("onShipStartDate", true);
        }
    }

    const validateInvoiceNumber = () => {
        if (requestBody["invoiceNumber"] === "") {
            updateIsValidMap("invoiceNumber", false);
        } else {
            updateIsValidMap("invoiceNumber", true);
        }
    }

    const validateComment = () => {
        if (requestBody["comment"] === "") {
            updateIsValidMap("comment", false);
        } else {
            updateIsValidMap("comment", true);
        }
    };

    /* edit onShip information (PUT) */
    const putOnShipInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/order/${orderId}/on-ship`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            onRefresh();
            setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* effects */
    /* validate */
    useEffect(() => {
        validateOnShipStartDate();
    }, [requestBody["onShipStartDate"]]);

    useEffect(() => {
        validateInvoiceNumber();
    }, [requestBody["invoiceNumber"]]);

    useEffect(() => {
        validateComment();
    }, [requestBody["comment"]]);

    useEffect(() => {
        setRequestBody(() => {
           let data = DataCompareService.deepCopyObj(prevData);
           let newRequestBody = {};
           newRequestBody["onShipStartDate"] = DataParseService.dateTimeToDate(data["onShipStartDate"]);
           newRequestBody["invoiceNumber"] = data["invoiceNumber"];
           newRequestBody["comment"] = "";
           return newRequestBody;
        });
        setFixedData(() => {
            let data = DataCompareService.deepCopyObj(prevData);
            let newRequestBody = {};
            newRequestBody["onShipStartDate"] = DataParseService.dateTimeToDate(data["onShipStartDate"]);
            newRequestBody["invoiceNumber"] = data["invoiceNumber"];
            newRequestBody["comment"] = "";
            return newRequestBody;
        });
    }, [prevData]);

    /* isEdited */
    useEffect(() => {
        setIsEdited(!DataCompareService.isSameObj(fixedData, requestBody));
    }, [requestBody]);

    /* isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <ModalCloseButton
                title={t("menu.order.detail.content.editOnShip")}
                setIsOpen={setIsOpen}
            />
            <div
                style={{
                    width: '100%',
                    height: '290px'
                }}
            >
                <Container fluid>
                    <Row>
                        <TitleAndInputBox
                            title={t("menu.order.detail.content.deliveryHistory.OnShipStartDate")}
                            value={requestBody["onShipStartDate"]}
                            onChange={handleOnShipStartDateInput}
                            isForEdit={true}
                            type={"date"}
                            isValid={isValidMap["onShipStartDate"]}
                            isEnabled={true}
                        />
                    </Row>
                    <Row
                        style={{ marginTop: '5px' }}
                    >
                        <TitleAndInputBox
                            title={t("menu.order.detail.content.deliveryHistory.invoiceNumber")}
                            value={requestBody["invoiceNumber"]}
                            onChange={handleInvoiceNumberInput}
                            isForEdit={true}
                            type={"text"}
                            isValid={isValidMap["invoiceNumber"]}
                            isEnabled={true}
                        />
                    </Row>
                    <Row
                        style={{ marginTop: '5px' }}
                    >
                        <TitleAndInputBox
                            title={t("menu.order.detail.content.deliveryHistory.comment")}
                            value={requestBody["comment"]}
                            onChange={handleCommentInput}
                            isForEdit={true}
                            type={"text"}
                            isValid={isValidMap["comment"]}
                            isEnabled={true}
                        />
                    </Row>
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                marginTop: '25px'
                            }}
                        >
                            {
                                isValid ?
                                    <button
                                        id={"long-save-btn"}
                                        onClick={() => putOnShipInfo()}
                                    >
                                        {t("button.save")}
                                    </button>
                                    :
                                    <button
                                        id={"long-save-btn-disabled"}
                                    >
                                        {t("button.save")}
                                    </button>
                            }
                        </div>
                    </Row>
                </Container>
            </div>
        </Modal>
    );
};

export default EditOnShipInfo;