import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

const TitleAndInputBox = ({ title, value, onChange, isForEdit, type, isValid, isEnabled, placeHolder }) => {

    const [isEnabledWrapper, setIsEnabledWrapper] = useState(true);

    useEffect(() => {
        if (typeof isEnabled === "undefined") {
            setIsEnabledWrapper(true);
        } else {
            setIsEnabledWrapper(isEnabled);
        }
    }, [isEnabled]);

    if (!isEnabledWrapper) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            paddingLeft: '10px',
                            marginBottom: '5px'
                        }}
                    >
                        <span
                            style={{
                                color: '#8f8f8f',
                                fontSize: '14px'
                            }}
                        >
                            {title}
                        </span>
                    </div>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <input
                            id={"data-view-input-disabled"}
                            readOnly={true}
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            paddingLeft: '10px',
                            marginBottom: '5px'
                        }}
                    >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px'
                        }}
                    >
                        {title}
                    </span>
                    </div>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <input
                            id={"data-view-input"}
                            type={type}
                            readOnly={!isForEdit}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            style={isValid ? {} : { borderColor: 'red' }}
                            placeholder={placeHolder ? placeHolder : ""}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default TitleAndInputBox;