import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as BackWardIcon } from "../../../icons/backWard.svg";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import BoothService from "./axiosServices/BoothService";
import RenderOnRole from "../../../auth/RenderOnRole";
import uuid from "react-uuid";
import JsonService from "./axiosServices/JsonService";
import _ from "lodash";
import BoothOperationView from "./boothOperation/BoothOperationView";
import { MdPlaylistAddCheck } from "react-icons/md";
import BackwardButton from "../../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../../common/layoutSub/PageTitleWithDepth";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import OverlayToolTip from "../../menu/common/toolTip/OverlayToolTip";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";
import ImsSystemRole from "../../../auth/roles/ImsSystemRole";
import DeleteConfirmModal from "../../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../../services/DeleteService";


const MenuItem = styled.button`
  border: none;
  background-color: white;
  color: #595959;
  align-items: center;
  width: 100%;
  padding: 12px 15px;
  text-align: left;
  transition: all ease-in-out 0.2s;
  font-size: 14px;
  
  &:hover {
    color: black;
  }
  
  &:focus {
    outline: none;
  }
`;

const MenuItemSelected = styled.button`
  border: none;
  background-color: #fcf9ed;
  color: #fc7242;
  align-items: center;
  width: 100%;
  padding: 12px 15px;
  text-align: left;
  transition: all ease-in-out 0.2s;
  font-size: 14px;

  &:focus {
    outline: none;
  }
`;

const TransButton = styled.button`
  border: none;
  background: none;
  padding-bottom: 5px;
  
  &:focus {
    outline: none;
  }
`;

const TransButtonBoothOps = styled.button`
  border: none;
  background: none;
  position: relative;
  color: #757575;
  transition: all ease-in-out 0.2s;
  margin-right: 10px;
  
  &:hover {
    color: #fc7242;
  }
  
  &:focus {
    outline: none;
  }
`;

const BoothOpsBadge = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: center;
  align-items: center;
  position: absolute;
  background-color: #ff6865;
  color: white;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  font-size: 13px;
  font-weight: bold;
  bottom: 16px;
  right: 35px;
  transition: all ease-in-out 0.2s;
`;

const DeleteButton = styled.button`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ff0000;
  color: #ff0000;
  align-items: center;
  width: 100%;
  padding: 8px 15px;
  text-align: center;
  transition: all ease-in-out 0.2s;
  font-size: 14px;

  &:hover {
    background-color: #ffe6e6;
  }

  &:focus {
    outline: none;
  }
`;

const BoothDetail = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { boothId } = useParams("boothId");
    const location = useLocation();
    /* loading */
    const [isLoading, setIsLoading] = useState(true);
    /* status message */
    const [statusMessage, setStatusMessage] = useState("");

    /* load success */
    const [boothInfoLoadSuccess, setBoothInfoLoadSuccess] = useState(false);
    const [boothJsonLoadSuccess, setBoothJsonLoadSuccess] = useState(false);
    const [boothDataLoadSuccess, setBoothDataLoadSuccess] = useState(false);

    /* booth information */
    const [boothInfo, setBoothInfo] = useState({});
    /* booth json schema */
    const [boothJsonSchemaVersion, setBoothJsonSchemaVersion] = useState("");
    const [boothJsonSchema, setBoothJsonSchema] = useState({});
    /* booth data */
    const [boothData, setBoothData] = useState({});

    /* booth operation view */
    const [isBoothOperationOpen, setIsBoothOperationOpen] = useState(false);
    const [badgeColor, setBadgeColor] = useState("#ffb3b2");
    const [badgeNum, setBadgeNum] = useState(0);

    /* unSeenCount */
    const [unSeenCount, setUnSeenCount] = useState(0);

    /* delete */
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    /* booth operation utils */
    const notifyBoothOperation = (num) => {
        setBadgeNum(num);
        if (num !== 0) {
            setBadgeColor("#ff0500");
            setTimeout(() => setBadgeColor("#ffb3b2"), 200);
        }
    };

    /* fetch booth basic info */
    const fetchBoothBasicInfo = () => {
        const onSuccessCallBack = async (data) => {
            await setBoothJsonSchemaVersion(data['boothVersionInfo']['boothJsonVersion']);
            setBoothInfo(data);
            setStatusMessage(t("menu.booth.statusMessage.good"));
            setIsLoading(false);
        };
        const onFailureCallBack = (e) => {
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
            setBoothInfo({});
            setStatusMessage(t("menu.booth.statusMessage.serverBad"));
            setIsLoading(false);
            toast.error(<ToastAlertView message={t("message.serverFailed")} />);
        };
        setIsLoading(true);
        BoothService.fetchBoothBasicInfo(boothId, onSuccessCallBack, onFailureCallBack);
    };

    /* fetch json schema */
    const fetchJsonSchema = async () => {
        const onSuccessCallBack = async (data) => {
            await setBoothJsonSchema(data);
            /* passing through localStorage */
            await window.localStorage.setItem("ims_json_schema", JSON.stringify(data));
            await setStatusMessage(t("menu.booth.statusMessage.good"));
            await setIsLoading(false);
            await fetchBoothData();
        };
        const onFailureCallBack = async () => {
            await setBoothJsonSchema({});
            await window.localStorage.setItem("ims_json_schema", null);
            await window.localStorage.setItem("is_booth_online", 'false');
            await setStatusMessage(t("menu.booth.statusMessage.serverBad"));
            await setIsLoading(false);
        };
        setIsLoading(true);
        JsonService.fetchBoothJsonSchema(boothJsonSchemaVersion, onSuccessCallBack, onFailureCallBack);
    };

    /* fetch booth data */
    const fetchBoothData = async () => {
        const onSuccessCallBack = async (data) => {
            await setBoothData(data);
            await setStatusMessage(t("menu.booth.statusMessage.good"));
            await window.localStorage.setItem("is_booth_online", 'true');
            await setIsLoading(false);
        };
        const onFailureCallBack = async () => {
            await setBoothData({});
            await window.localStorage.setItem("is_booth_online", 'false');
            await setStatusMessage(t("menu.booth.statusMessage.serverBad"));
            await setIsLoading(false);
        };
        setIsLoading(true);
        BoothService.fetchBoothData(boothId, onSuccessCallBack, onFailureCallBack);
    };

    /* utils */
    const hasRelativePath = (value, depth) => {
        let path = location.pathname;
        let pathList = path.split('/');
        if (pathList.length === 0 || (pathList.length - 1) < depth) {
            return false;
        } else {
            return pathList[depth] === value;
        }
    };

    const isRootPath = (minDepth) => {
        let path = location.pathname;
        let pathList = path.split('/');
        return pathList.length === minDepth;
    };

    /* effects */
    useEffect(() => {
        /* fetch booth info */
        fetchBoothBasicInfo();
    }, []);

    useEffect(() => {
        if (boothJsonSchemaVersion === "") {
            return;
        } else {
            /* fetch json schema */
            fetchJsonSchema();
        }
    }, [boothJsonSchemaVersion]);

    useEffect(() => {
        setBoothJsonLoadSuccess(!_.isEmpty(boothJsonSchema));
    }, [boothJsonSchema]);

    useEffect(() => {
        setBoothInfoLoadSuccess(!_.isEmpty(boothInfo));
    }, [boothInfo]);

    useEffect(() => {
        setBoothDataLoadSuccess(!_.isEmpty(boothData));
    }, [boothData]);

    const renderSideNavItems = () => {
        let elementList = [];
        /* booth info */
        if (hasRelativePath("info", 4)) {
            elementList.push(
                <MenuItemSelected
                    key={uuid()}
                >
                    {t("menu.booth.detail.sideBar.fixElements.boothInfo")}
                </MenuItemSelected>
            );
        } else {
            elementList.push(
                <MenuItem
                    key={uuid()}
                    onClick={() => navigate('info')}
                >
                    {t("menu.booth.detail.sideBar.fixElements.boothInfo")}
                </MenuItem>
            );
        }
        /* booth detailed info */
        if (hasRelativePath("detailed-info", 4)) {
            elementList.push(
                <MenuItemSelected
                    key={uuid()}
                >
                    {t("menu.booth.detail.sideBar.fixElements.boothDetailedInfo")}
                </MenuItemSelected>
            );
        } else {
            elementList.push(
                <MenuItem
                    key={uuid()}
                    onClick={() => navigate('detailed-info')}
                >
                    {t("menu.booth.detail.sideBar.fixElements.boothDetailedInfo")}
                </MenuItem>
            );
        }
        /* error history */
        if (hasRelativePath("errors", 4)) {
            elementList.push(
                <MenuItemSelected
                    key={uuid()}
                >
                    {t("menu.booth.detail.sideBar.fixElements.boothErrorView")}
                </MenuItemSelected>
            );
        } else {
            elementList.push(
                <MenuItem
                    key={uuid()}
                    onClick={() => navigate('errors/unresolved')}
                >
                    {t("menu.booth.detail.sideBar.fixElements.boothErrorView")}
                </MenuItem>
            );
        }
        /* message history */
        if (hasRelativePath("message-log", 4)) {
            elementList.push(
                <MenuItemSelected
                    key={uuid()}
                >
                    {t("menu.booth.detail.sideBar.fixElements.boothMessageHistory")}
                </MenuItemSelected>
            );
        } else {
            elementList.push(
                <MenuItem
                    key={uuid()}
                    onClick={() => navigate('message-log')}
                >
                    {t("menu.booth.detail.sideBar.fixElements.boothMessageHistory")}
                </MenuItem>
            );
        }
        /* menu recipe diff */
        if (hasRelativePath("menu-diff", 4)) {
            elementList.push(
                <MenuItemSelected
                    key={uuid()}
                >
                    {t("menu.boothMenuDiff.title")}
                </MenuItemSelected>
            );
        } else {
            elementList.push(
                <MenuItem
                    key={uuid()}
                    onClick={() => navigate('menu-diff')}
                >
                    {t("menu.boothMenuDiff.title")}
                </MenuItem>
            );
        }
        return elementList;
    };

    const renderDeviceList = () => {
        let elementList = [];
        /* additional (HARD-CODED) booth alarms */
        if (hasRelativePath("booth-alarms", 4)) {
            elementList.push(
                <MenuItemSelected
                    key={uuid()}
                >
                    {t("menu.boothControl.sideMenus.boothAlarms.title")}
                </MenuItemSelected>
            );
        } else {
            elementList.push(
                <MenuItem
                    key={uuid()}
                    onClick={() => navigate(`booth-alarms`)}
                >
                    {t("menu.boothControl.sideMenus.boothAlarms.title")}
                </MenuItem>
            );
        }
        /* pushed by json schema */
        boothJsonSchema['spec']['devices'].map(deviceObj => {
            let deviceMetaData = deviceObj['metadata'];
            if (hasRelativePath(deviceMetaData['id'], 5)) {
                elementList.push(
                    <MenuItemSelected
                        key={uuid()}
                    >
                        {deviceMetaData[t("language.dataKey.boothControl.json.label")]}
                    </MenuItemSelected>
                );
            } else {
                elementList.push(
                    <MenuItem
                        key={uuid()}
                        onClick={() => navigate(`device/${deviceMetaData['id']}/status`)}
                    >
                        {deviceMetaData[t("language.dataKey.boothControl.json.label")]}
                    </MenuItem>
                );
            }
        });
        return elementList;
    };

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            <Container fluid>
                <Row
                    style={{
                        padding: '0px'
                    }}
                >
                    {/* top bar */}
                    <div
                        style={{
                            width: '100%',
                            height: '50px',
                            backgroundColor: 'white',
                            display: 'flex'
                        }}
                    >
                        <BackwardButton
                            isShow={true}
                            link={'/booth'}
                        />
                        <PageTitleWithDepth
                            titles={[t("menu.booth.title"), t("menu.booth.detail.title")]}
                            depth={2}
                        />
                        {/* booth name and serial */}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '10px'
                            }}
                        >
                                <span
                                    style={{
                                        color: '#757575'
                                    }}
                                >
                                    {
                                        boothInfo['serialNumber']
                                    }
                                </span>
                        </div>
                        {/* status message */}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '20px'
                            }}
                        >
                            {
                                isLoading ?
                                    <Spinner animation="border" variant="secondary" size={"sm"} />
                                    :
                                    <span
                                        style={{
                                            color: '#8c8c8c',
                                            fontSize: '13px'
                                        }}
                                    >
                                        {statusMessage}
                                    </span>
                            }
                        </div>
                    </div>
                </Row>
                <Row
                    style={{
                        padding: '0px'
                    }}
                >
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        {/* view area */}
                        <div
                            style={{
                                marginTop: '15px',
                                width: '100%',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                display: 'flex'
                            }}
                        >
                            {/* side nav */}
                            <div
                                style={{
                                    width: '15%',
                                    marginRight: '1%',
                                    overflow: 'auto'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '750px',
                                        backgroundColor: 'white'
                                    }}
                                >
                                    {
                                        boothInfoLoadSuccess ?
                                            renderSideNavItems()
                                            :
                                            <></>
                                    }
                                    {
                                        boothJsonLoadSuccess ?
                                            (
                                                boothDataLoadSuccess ?
                                                    renderDeviceList()
                                                    :
                                                    <></>
                                            )
                                            :
                                            <div
                                                style={{
                                                    paddingTop: '50px'
                                                }}
                                            >
                                                <Spinner animation="grow" size={"sm"} />
                                            </div>
                                    }
                                </div>
                                {/* delete button */}
                                <RenderOnRole type={"DELETE"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
                                    <div
                                        style={{
                                            width: '100%',
                                            padding: '5px',
                                            marginTop: '5px'
                                        }}
                                    >
                                        <DeleteButton
                                            onClick={() => setIsDeleteConfirmOpen(true)}
                                        >
                                            {t("language.message.deletion.button.booth")}
                                        </DeleteButton>
                                        {/* delete confirmation */}
                                        <DeleteConfirmModal
                                            isOpen={isDeleteConfirmOpen}
                                            setIsOpen={setIsDeleteConfirmOpen}
                                            isDelayed={true}
                                            confirmMessage={t("language.message.deletion.confirmation", { entityName : boothInfo['serialNumber'] + " " + t("language.message.deletion.depsList.booth.self") })}
                                            entity={DeleteService.ValidEntities.BOOTH}
                                            entityName={boothInfo['serialNumber'] + " " + t("language.message.deletion.depsList.booth.self")}
                                            id={`booth/${boothId}`}
                                            onDeleteSuccess={async () => {
                                                await navigate('/booth');
                                            }}
                                            depsToShow={[
                                                t("language.message.deletion.depsList.booth.menuSale"),
                                                t("language.message.deletion.depsList.booth.menuCustom"),
                                                t("language.message.deletion.depsList.booth.operationHistory"),
                                                t("language.message.deletion.depsList.booth.orderRequest")
                                            ]}
                                            validateMessage={t("language.message.deletion.verificationMessage", { entityName : t("language.message.deletion.depsList.booth.self") })}
                                            delayToUndo={DeleteService.getMediumDelay()}
                                            onUndoSuccess={async () => {
                                                await navigate(`/booth/detail/${boothId}/detailed-info`);
                                            }}
                                        />
                                    </div>
                                </RenderOnRole>
                            </div>
                            {/* main area */}
                            <div
                                style={{
                                    width: '84%',
                                    height: '850px'
                                }}
                            >
                                {
                                    isRootPath(4) ?
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                paddingTop: '30px'
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: '#757575'
                                                }}
                                            >
                                                {t("menu.booth.statusMessage.chooseMenu")}
                                            </span>
                                        </div>
                                        :
                                        boothJsonSchemaVersion &&
                                        <Outlet
                                            context={{
                                                boothJsonSchemaVersion
                                            }}
                                        />
                                }
                            </div>
                        </div>
                    </Col>
                    <BoothOperationView
                        isView={isBoothOperationOpen}
                        boothId={boothId}
                        onNote={notifyBoothOperation}
                        badgeNum={badgeNum}
                        unSeenCount={unSeenCount}
                        setUnSeenCount={setUnSeenCount}
                        isBoothOnline={Object.keys(boothData).length !== 0}
                    />
                </Row>
            </Container>
            {/* booth operation view button */}
            <div
                style={{
                    position: 'absolute',
                    top: isBoothOperationOpen ? '85px' : '60px',
                    right: '0px',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {
                    isBoothOperationOpen ?
                        <TransButtonBoothOps
                            onClick={() => {
                                setBadgeNum(0);
                                setUnSeenCount(0);
                                setIsBoothOperationOpen(!isBoothOperationOpen);
                            }}
                        >
                            <BsArrowRightCircle
                                size={"25"}
                            />
                            {
                                (badgeNum === 0) ?
                                    <></>
                                    :
                                    <BoothOpsBadge
                                        style={{
                                            backgroundColor: badgeColor
                                        }}
                                    >
                                        {badgeNum}
                                    </BoothOpsBadge>
                            }
                        </TransButtonBoothOps>
                        :
                        <OverlayToolTip
                            direction={"left"}
                            text={t("menu.boothOperation.title")}
                        >
                            <TransButtonBoothOps
                                onClick={() => {
                                    setBadgeNum(0);
                                    setUnSeenCount(0);
                                    setIsBoothOperationOpen(!isBoothOperationOpen);
                                }}
                            >
                                <BsArrowLeftCircle
                                    size={"25"}
                                />
                                {
                                    (badgeNum === 0) ?
                                        <></>
                                        :
                                        <BoothOpsBadge
                                            style={{
                                                backgroundColor: badgeColor
                                            }}
                                        >
                                            {badgeNum}
                                        </BoothOpsBadge>
                                }
                            </TransButtonBoothOps>
                        </OverlayToolTip>
                }
            </div>
        </RenderOnRole>
    );
};

export default BoothDetail;