import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import RenderOnRole from "../../../../auth/RenderOnRole";
import BackwardButton from "../../../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../../../common/layoutSub/PageTitleWithDepth";
import {useTranslation} from "react-i18next";
import UserService from "../../../../services/UserService";
import axios from "axios";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import MenuNotExists from "../../franchiseMenu/components/message/MenuNotExists";
import {Accordion, AccordionHeader, AccordionItem} from "react-headless-accordion";
import DataUtils from "../../common/utils/DataUtils";
import MenuItem from "../../common/accordionItems/MenuItem";
import styled from "styled-components";
import ImsSystemRole from "../../../../auth/roles/ImsSystemRole";
const TransButton = styled.button`
  width: 100%;
  display: block;
  background: none;
  border: none;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
`;

const MenuItemView = styled.div`
  width: 100%;
  margin-top: 5px;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
`;

const MenuItemViewSelected = styled.div`
  width: 100%;
  margin-top: 5px;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  background-color: #fff3e0;
`;

const MenuNameDisabled = styled.span`
  font-size: 14px;
  color: #c2c2c2;
  margin-left: 5px;
`;

const MenuNameEnabled = styled.span`
  font-size: 14px;
  color: #757575;
  margin-left: 5px;
`;

const MenuNameSelected = styled.span`
  font-size: 14px;
  color: #fc7242;
  margin-left: 5px;
`;


const BranchMenuDiffManagement = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { branchId } = useParams();
    const location = useLocation();

    /* states */
    const [menuList, setMenuList] = useState([]);
    /* loading */
    const [isLoading, setIsLoading] = useState(false);

    /* fetch menu list */
    const fetchMenuList = async () => {
        await setIsLoading(true);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/branch-menu/branch/${branchId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newMenuList = res.data.item['branchMenuList'];
            /* filter by isEnabled */
            let newDisabledMenuList = newMenuList.filter(menuInfo => {
                let isEnabled = menuInfo['menuInfoBeforeMergeDiff']['isEnabled'];
                /* check diff for isEnabled */
                if ((menuInfo['menuInfoAfterMergeDiff']) && (menuInfo['menuInfoAfterMergeDiff']['isEnabled'] != null)) {
                    isEnabled = menuInfo['menuInfoAfterMergeDiff']['isEnabled'];
                }
                return isEnabled;
            });
            newMenuList = newMenuList.filter(menuInfo => {
                let isEnabled = menuInfo['menuInfoBeforeMergeDiff']['isEnabled'];
                /* check diff for isEnabled */
                if ((menuInfo['menuInfoAfterMergeDiff']) && (menuInfo['menuInfoAfterMergeDiff']['isEnabled'] != null)) {
                    isEnabled = menuInfo['menuInfoAfterMergeDiff']['isEnabled'];
                }
                return !isEnabled;
            });
            newMenuList = newMenuList.concat(newDisabledMenuList).reverse();
            /* default redirection */
            if ((location.pathname.split('/').length < 6) && (newMenuList.length > 0)) {
                navigate(`menu/${newMenuList[0]['menuInfoBeforeMergeDiff']['franchiseMenuId']}`);
            }
            /* set data */
            setMenuList(newMenuList);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setMenuList([]);
            setIsLoading(false);
        }
    };

    /* effects */
    useEffect(() => {
        fetchMenuList();
    }, [branchId]);


    /* renderers */
    const renderMenuItems = () => {
        if (menuList.length === 0) {
            return (<MenuNotExists />);
        } else {
            let newMenuItems = [];
            menuList.map(menuInfoObj => {
                let label = "";
                let isEnabled = menuInfoObj['menuInfoBeforeMergeDiff']['isEnabled'];
                /* check diff for isEnabled */
                if ((menuInfoObj['menuInfoAfterMergeDiff']) && (menuInfoObj['menuInfoAfterMergeDiff']['isEnabled'] != null)) {
                    isEnabled = menuInfoObj['menuInfoAfterMergeDiff']['isEnabled'];
                }
                if (menuInfoObj['menuInfoAfterMergeDiff']) {
                    label = menuInfoObj['menuInfoAfterMergeDiff'][t("language.dataKey.menu.detail.name")] ?
                        menuInfoObj['menuInfoAfterMergeDiff'][t("language.dataKey.menu.detail.name")]
                        :
                        menuInfoObj['menuInfoBeforeMergeDiff'][t("language.dataKey.menu.detail.name")]
                } else {
                    label = menuInfoObj['menuInfoBeforeMergeDiff'][t("language.dataKey.menu.detail.name")]
                }
                if (DataUtils.hasIdInPath(location.pathname, menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuId'])) {
                    /* selected */
                    newMenuItems.push(
                        <TransButton
                            onClick={() => navigate(`/branch/detail/${branchId}/menu-diff/menu/${menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuId']}`)}
                            key={menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuId'] + menuList.length}
                        >
                            <MenuItemViewSelected>
                                <div>
                                    <MenuNameSelected>
                                        {label}
                                    </MenuNameSelected>
                                </div>
                            </MenuItemViewSelected>
                        </TransButton>
                    );
                } else {
                    /* not selected */
                    newMenuItems.push(
                        <TransButton
                            onClick={() => navigate(`/branch/detail/${branchId}/menu-diff/menu/${menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuId']}`)}
                            key={menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuId'] + menuList.length}
                        >
                            <MenuItemView>
                                <div>
                                    {
                                        isEnabled ?
                                            <MenuNameEnabled>
                                                {label}
                                            </MenuNameEnabled>
                                            :
                                            <MenuNameDisabled>
                                                {label}
                                            </MenuNameDisabled>
                                    }
                                </div>
                            </MenuItemView>
                        </TransButton>
                    );
                }
            });
            return newMenuItems;
        }
    };

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={`/branch/detail/${branchId}`}
                />
                <PageTitleWithDepth
                    titles={[t("menu.branch.title"), t("menu.branch.detail.title"), t("menu.branch.detail.menu.title")]}
                    depth={3}
                />
            </div>
            {/* main */}
            <div className={"contentContainer"}>
                <Container fluid>
                    <Row>
                        <Col
                            md={"2"}
                            style={{
                                padding: '0px'
                            }}
                        >
                            <div
                                className={"contentInner"}
                                style={{
                                    height: '783px',
                                    overflow: 'auto'
                                }}
                            >
                                {
                                    isLoading ?
                                        <Spinner
                                            size={"sm"}
                                        />
                                        :
                                        renderMenuItems()
                                }
                            </div>
                        </Col>
                        <Col
                            md={"10"}
                            style={{
                                padding: '0px',
                                paddingLeft: '10px'
                            }}
                        >
                            <Outlet />
                        </Col>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default BranchMenuDiffManagement;