import Ajv from "ajv";

const _schemaObj = {
    "type": "array",
    "maxItems": 2000,
    "items": [
        {
            "type": "object",
            "properties": {
                "code": {
                    "type": "string"
                },
                "properties": {
                    "type": "object",
                    "properties": {
                        "origin": {
                            "type": "string",
                            "pattern" : "^(Booth)$"
                        },
                        "permission": {
                            "type": "integer",
                            "minimum" : 0,
                            "maximum" : 7
                        },
                        "device": {
                            "type": "string",
                            "format": "uuid"
                        },
                        "level": {
                            "type": "string",
                            "pattern": "^(Info|Warning|Debug|Error|Critical|Fatal)$"
                        },
                        "title": {
                            "type": "string"
                        },
                        "description": {
                            "type": "string"
                        },
                        "title_1": {
                            "type": ["string", "null"]
                        },
                        "description_1": {
                            "type": ["string", "null"]
                        },
                        "title_2": {
                            "type": ["string", "null"]
                        },
                        "description_2": {
                            "type": ["string", "null"]
                        },
                        "title_3": {
                            "type": ["string", "null"]
                        },
                        "description_3": {
                            "type": ["string", "null"]
                        },
                        "title_4": {
                            "type": ["string", "null"]
                        },
                        "description_4": {
                            "type": ["string", "null"]
                        },
                        "title_5": {
                            "type": ["string", "null"]
                        },
                        "description_5": {
                            "type": ["string", "null"]
                        }
                    },
                    "required": [
                        "origin",
                        "permission",
                        "device",
                        "level",
                        "title",
                        "description"
                    ]
                }
            },
            "required": [
                "code",
                "properties"
            ]
        }
    ]
};

/**
 * return obj
 * 1.  "isValid" : true | false
 * 2.  "reason" : (-> false only) "PARSE_FAIL", "VALIDATE_FAIL", "DUPLICATE_CODE", "INVALID_DEVICE_ID"
 * 3.  "invalidData" : (-> "DUPLICATE_CODES" and "INVALID_DEVICE_ID" only)
 * */
const validateErrorCodeJson = (jsonStr, validDeviceIdList) => {
    let returnObj = {};
    const ajv = new Ajv({
        strict: false,
        strictSchema: false
    });
    const validate = ajv.compile(_schemaObj);

    /** ---- 0.  parsing jsonStr ------------- */
    let jsonObj = {};
    try {
        jsonObj = JSON.parse(jsonStr);
    } catch (e) {
        returnObj['isValid'] = false;
        returnObj['reason'] = "PARSE_FAIL";
        return returnObj;
    }

    /** ---- 1.  validate with schema -------- */
    try {
        let tempResult = validate(jsonObj);
        if (!tempResult) {
            returnObj['isValid'] = false;
            returnObj['reason'] = "VALIDATE_FAIL";
            throw returnObj;
        }
    } catch (ero) {
        return ero;
    }

    /** ---- 2.  code duplication ------------ */
    let codeList = [];
    jsonObj.map(obj => {
        codeList.push(obj['code']);
    });
    let newCodeList = [];
    try {
        codeList.map(code => {
            if (!newCodeList.includes(code)) {
                newCodeList.push(code);
            } else {
                returnObj['isValid'] = false;
                returnObj['reason'] = "DUPLICATE_CODE";
                returnObj['invalidData'] = code;
                throw returnObj;
            }
        });
    } catch (ero) {
        return ero;
    }

    /** ---- 3.  device id validation -------- */
    let deviceIdListInJson = [];
    jsonObj.map(obj => {
        deviceIdListInJson.push(obj['properties']['device']);
    });
    /* filter duplicated */
    let deviceIdListInJsonFiltered = [];
    deviceIdListInJson.map(deviceId => {
        if (!deviceIdListInJsonFiltered.includes(deviceId)) {
            deviceIdListInJsonFiltered.push(deviceId);
        }
    });
    /* validation */
    for (let i=0 ; i < deviceIdListInJsonFiltered.length ; i++) {
        if (!validDeviceIdList.includes(deviceIdListInJsonFiltered[i])) {
            returnObj['isValid'] = false;
            returnObj['reason'] = "INVALID_DEVICE_ID";
            returnObj['invalidData'] = deviceIdListInJsonFiltered[i];
            return returnObj;
        }
    }

    /** good */
    returnObj['isValid'] = true;
    return returnObj;
};

/* deprecated */
const getPlaceHolder = () => {
    return "e.g.\n[\n    {\n        " +
        "\"code\": \"CODE FOR ERROR\",\n" +
        "        \"properties\": {\n" +
        "            \"origin\": \"Booth\",\n" +
        "            \"permission\": 1,\n" +
        "            \"device\": \"UUID FOR DEVICE\",\n" +
        "            \"level\": \"Error\",\n" +
        "            \"title\": \"English title\",\n" +
        "            \"description\": \"English description\",\n" +
        "            \"title_1\": null,\n" +
        "            \"description_1\": null,\n" +
        "            \"title_2\": null,\n" +
        "            \"description_2\": null,\n" +
        "            \"title_3\": null,\n" +
        "            \"description_3\": null,\n" +
        "            \"title_4\": null,\n" +
        "            \"description_4\": null,\n" +
        "            \"title_5\": null,\n" +
        "            \"description_5\": null\n " +
        "       }, ...\n    }\n]";
};

const ErrorCodeJsonValidateService = {
    getPlaceHolder,
    validateErrorCodeJson
};

export default ErrorCodeJsonValidateService;