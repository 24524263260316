import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import DataCompareService from "../../services/DataCompareService";
import RenderOnRole from "../../auth/RenderOnRole";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import DataParseService from "../../services/DataParseService";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../services/DeleteService";
import _ from "lodash";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import TitleAndSearchSelectBox from "../common/dataView/TitleAndSearchSelectBox";

const requestBodyInit = {
    branchName : "",
    branchDescription : "",
    locationPostalCode : "",
    locationAddress : "",
    locationAddressDetail : "",
    locationLatitude : "",
    locationLongitude : "",
    branchMasterId : "",
    businessRegistrationNumber : "",
    branchGroupId : ""
};

const isValidMapInit = {
    branchName : true,
    branchDescription : true,
    locationPostalCode : true,
    locationAddress : true,
    locationAddressDetail : true,
    locationLatitude : true,
    locationLongitude : true,
    branchMasterId : true,
    businessRegistrationNumber : true,
    branchGroupId : true
};

const BranchEdit = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { branchId } = useParams("branchId");
    const location = useLocation();
    /* branch information */
    const [branchInfo, setBranchInfo] = useState({});
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    /* isValid, isEdited */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    /** New: franchise */
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [franchiseList, setFranchiseList] = useState([]);
    const [prevFranchiseId, setPrevFranchiseId] = useState("");
    const [afterFranchiseId, setAfterFranchiseId] = useState("");
    const [isFranchiseEdited, setIsFranchiseEdited] = useState(false);

    /* fetch franchise list */
    const fetchFranchiseList = async (targetGroupId) => {
        const initialFranchiseList = [
            {
                franchiseName : t("menu.branch.franchise.search.none"),
                franchiseId : "none"
            }
        ];
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            let newFranchiseList = _.cloneDeep(initialFranchiseList);
            const dataList = res.data.item.franchiseList;
            dataList.map(groupAndFranchiseObj => {
                let franchiseList = groupAndFranchiseObj[targetGroupId];
                for (let i=0 ; i<franchiseList.length ; i++) {
                    newFranchiseList.push(franchiseList[i]);
                }
            });
            await setFranchiseList(newFranchiseList);
        } catch (e) {
            console.log(e);
            await setFranchiseList(initialFranchiseList);
        }
    };

    /* fetch branch information */
    const fetchBranchDetail = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/branch/${branchId}`;
            const axiosCall = () => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            await setBranchInfo(res.data.item.branchInfo);
            let requestDataObj = {
                branchName : res.data.item.branchInfo.branchName,
                branchDescription : res.data.item.branchInfo.branchDescription,
                locationPostalCode : res.data.item.branchInfo.locationPostalCode,
                locationAddress : res.data.item.branchInfo.locationAddress,
                locationAddressDetail : res.data.item.branchInfo.locationAddressDetail,
                locationLatitude : res.data.item.branchInfo.locationLatitude,
                locationLongitude : res.data.item.branchInfo.locationLongitude,
                branchMasterId : res.data.item.branchInfo.branchMasterId,
                businessRegistrationNumber : res.data.item.branchInfo.businessRegistrationNumber,
                branchGroupId : res.data.item.branchInfo.branchGroupId
            };
            await setRequestBody(DataCompareService.deepCopyObj(requestDataObj));
            await setFixedRequestBody(DataCompareService.deepCopyObj(requestDataObj));
            /** New: franchise */
            await setIsFranchiseEdited(false);
            if (res.data.item.branchInfo['franchiseInformation'] == null) {
                await setPrevFranchiseId("none");
                await setAfterFranchiseId("none");
            } else {
                await setPrevFranchiseId(res.data.item.branchInfo['franchiseInformation']['franchiseId']);
                await setAfterFranchiseId(res.data.item.branchInfo['franchiseInformation']['franchiseId']);
            }
            /* fetch franchise list */
            await fetchFranchiseList(res.data.item.branchInfo.branchGroupId);
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    const putBranchInfo = async () => {
        await setIsSaveLoading(true);

        if (isEdited) {
            try {
                let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/branch/${branchId}`;
                const axiosCall = () => axios.put(
                    fetchUrl ,
                    JSON.stringify(requestBody),
                    {
                        headers: {
                            Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                            "Content-Type" : "application/json"
                        }
                    }
                );
                const res = await UserService.updateToken(axiosCall);
                if (!isFranchiseEdited) {
                    toast.success(<ToastAlertView message={t("message.saved")} />);
                    navigate(`/branch/detail/${branchId}`);
                }
            } catch (e) {
                toast.error(<ToastAlertView message={t("message.failed")} />);
                console.log(e);
                return;
            }
        }

        if (isFranchiseEdited) {
            if (afterFranchiseId === "none") {
                /* delete mapping */
                /** register to the franchise */
                let newFetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                    + `/api/v1/franchise/${afterFranchiseId}/branch/${branchId}`;
                try {
                    await UserService.updateToken(() => axios.delete(
                        newFetchUrl,
                        {
                            headers: {
                                Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                            }
                        }
                    ));
                    toast.success(<ToastAlertView message={t("message.saved")} />);
                    await setIsSaveLoading(false);
                    navigate(`/branch/detail/${branchId}`);
                } catch (e) {
                    console.log(e);
                    if (isEdited) {
                        toast.error(<ToastAlertView message={t("menu.branch.franchise.message.failed")} />);
                    } else {
                        toast.error(<ToastAlertView message={t("message.failed")} />);
                    }
                    await setIsSaveLoading(false);
                    navigate(`/branch/detail/${branchId}`);
                    return;
                }
            } else {
                /** register to the franchise */
                let newFetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                    + `/api/v1/franchise/${afterFranchiseId}/branch`;
                let newRequestBody = {
                    franchiseBranchBranchId : branchId
                };
                try {
                    await UserService.updateToken(() => axios.post(
                        newFetchUrl,
                        JSON.stringify(newRequestBody),
                        {
                            headers: {
                                Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                                "Content-Type" : "application/json"
                            }
                        }
                    ));
                    toast.success(<ToastAlertView message={t("message.saved")} />);
                    await setIsSaveLoading(false);
                    navigate(`/branch/detail/${branchId}`);
                } catch (e) {
                    console.log(e);
                    if (isEdited) {
                        toast.error(<ToastAlertView message={t("menu.branch.franchise.message.failed")} />);
                    } else {
                        toast.error(<ToastAlertView message={t("message.failed")} />);
                    }
                    await setIsSaveLoading(false);
                    navigate(`/branch/detail/${branchId}`);
                    return;
                }
            }
        }

    };

    /* utils */
    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* handlers */
    const handleBranchNameInput = (value) => {
        updateRequestBody("branchName", value);
    };

    const handleBusinessNumberInput = (value) => {
        updateRequestBody("businessRegistrationNumber", value);
    };

    const handlePostalCodeInput = (value) => {
        updateRequestBody("locationPostalCode", value);
    };

    const handleAddressInput = (value) => {
        updateRequestBody("locationAddress", value);
    };

    const handleAddressDetailInput = (value) => {
        updateRequestBody("locationAddressDetail", value);
    };

    const handleDescriptionInput = (value) => {
        updateRequestBody("branchDescription", value);
    };

    /* validators */
    const validateBranchNameInput = () => {
        if (requestBody["branchName"].length < 3 || requestBody["branchName"].length > 20) {
            updateIsValidMap("branchName", false);
        } else {
            updateIsValidMap("branchName", true);
        }
    };

    const validateBranchDescriptionInput = () => {
        if (requestBody["branchDescription"] === "") {
            updateIsValidMap("branchDescription", false);
        } else {
            updateIsValidMap("branchDescription", true);
        }
    };

    const validateLocationPostalCodeInput = () => {
        if (requestBody["locationPostalCode"] === "") {
            updateIsValidMap("locationPostalCode", false);
        } else {
            updateIsValidMap("locationPostalCode", true);
        }
    };

    const validateLocationAddressInput = () => {
        if (requestBody["locationAddress"] === "") {
            updateIsValidMap("locationAddress", false);
        } else {
            updateIsValidMap("locationAddress", true);
        }
    };

    const validateLocationAddressDetailInput = () => {
        if (requestBody["locationAddressDetail"] === "") {
            updateIsValidMap("locationAddressDetail", false);
        } else {
            updateIsValidMap("locationAddressDetail", true);
        }
    };

    const validateBusinessNumberInput = () => {
        const isValidForm = DataCompareService.regexValidator(requestBody["businessRegistrationNumber"], "^(\\d{3}-\\d{2}-\\d{5})$");
        if (isValidForm) {
            updateIsValidMap("businessRegistrationNumber", true);
        } else {
            updateIsValidMap("businessRegistrationNumber", false);
        }
    };

    /* effects */
    useEffect(() => {
        fetchBranchDetail();
    }, []);

    /* input variables */
    useEffect(() => {
        validateBranchNameInput();
    }, [requestBody["branchName"]]);

    useEffect(() => {
        validateBranchDescriptionInput();
    }, [requestBody["branchDescription"]]);

    useEffect(() => {
        validateLocationPostalCodeInput();
    }, [requestBody["locationPostalCode"]]);

    useEffect(() => {
        validateLocationAddressInput();
    }, [requestBody["locationAddress"]]);

    useEffect(() => {
        validateLocationAddressDetailInput();
    }, [requestBody["locationAddressDetail"]]);

    useEffect(() => {
        validateBusinessNumberInput();
    }, [requestBody["businessRegistrationNumber"]]);

    /* isEdited, isValid */
    useEffect(() => {
        setIsEdited(!DataCompareService.isSameObj(requestBody, fixedRequestBody));
    }, [requestBody]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        setIsFranchiseEdited(afterFranchiseId !== prevFranchiseId);
    }, [afterFranchiseId]);

    return (
        <RenderOnRole type={"PUT"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={`/branch/detail/${branchId}`}
                />
                <PageTitleWithDepth
                    titles={[t("menu.branch.title"), t("menu.branch.detail.title"), t("menu.branch.edit.title")]}
                    depth={3}
                />
            </div>
            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                    <Container fluid>
                        <Row>
                            {/* main area */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '700px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '650px',
                                        display: 'flex',
                                        overflow: 'auto',
                                        padding: '10px 0px 20px 0px'
                                    }}
                                >
                                    <Container fluid>
                                        <Row>
                                            <TitleAndInputBox
                                                title={t("common.name")}
                                                value={requestBody["branchName"]}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["branchName"]}
                                                onChange={handleBranchNameInput}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <Col
                                                style={{
                                                    padding: '0px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.customer")}
                                                    value={branchInfo.branchMasterName}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    onChange={() => {}}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.group")}
                                                    value={branchInfo.branchGroupName}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    onChange={() => {}}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                {/* select franchise */}
                                                <TitleAndSearchSelectBox
                                                    title={t("menu.branch.franchise.title")}
                                                    innerValue={afterFranchiseId}
                                                    valueList={franchiseList}
                                                    valueSelector={"franchiseId"}
                                                    viewSelector={"franchiseName"}
                                                    onChange={async (value) => await setAfterFranchiseId(value)}
                                                    placeHolderStr={t("menu.franchise.selectFranchise")}
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.businessNumber")}
                                                value={requestBody["businessRegistrationNumber"]}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["businessRegistrationNumber"]}
                                                onChange={handleBusinessNumberInput}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <Col
                                                style={{
                                                    padding: '0px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("menu.order.detail.content.shipInfo.postalCode")}
                                                    value={requestBody["locationPostalCode"]}
                                                    isForEdit={true}
                                                    type={"text"}
                                                    isValid={isValidMap["locationPostalCode"]}
                                                    onChange={handlePostalCodeInput}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("menu.order.detail.content.shipInfo.address")}
                                                    value={requestBody["locationAddress"]}
                                                    isForEdit={true}
                                                    type={"text"}
                                                    isValid={isValidMap["locationAddress"]}
                                                    onChange={handleAddressInput}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("menu.order.detail.content.shipInfo.detail")}
                                                    value={requestBody["locationAddressDetail"]}
                                                    isForEdit={true}
                                                    type={"text"}
                                                    isValid={isValidMap["locationAddressDetail"]}
                                                    onChange={handleAddressDetailInput}
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <TitleAndTextArea
                                                title={t("common.description")}
                                                value={requestBody["branchDescription"]}
                                                onChange={handleDescriptionInput}
                                                isForEdit={true}
                                                isValid={isValidMap["branchDescription"]}
                                                height={"200"}
                                            />
                                        </Row>
                                    </Container>
                                </div>
                                {/* bottom btn */}
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    {/* deletion */}
                                    <div>
                                        <RenderOnRole type={"DELETE"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                            <Link
                                                id={"delete-btn"}
                                                onClick={async () => {
                                                    await setIsDeleteConfirmOpen(true);
                                                }}
                                            >
                                                {t("button.delete")}
                                            </Link>
                                            {/* deletion modal */}
                                            <DeleteConfirmModal
                                                isOpen={isDeleteConfirmOpen}
                                                setIsOpen={setIsDeleteConfirmOpen}
                                                isDelayed={true}
                                                confirmMessage={t("language.message.deletion.confirmation", { entityName : branchInfo['branchName'] })}
                                                entity={DeleteService.ValidEntities.BRANCH}
                                                entityName={branchInfo['branchName']}
                                                id={`branch/${branchId}`}
                                                onDeleteSuccess={async () => {
                                                    await navigate('/branch');
                                                }}
                                                depsToShow={[
                                                    t("language.message.deletion.depsList.branch.couponHistory"),
                                                    t("language.message.deletion.depsList.branch.sales"),
                                                    t("language.message.deletion.depsList.branch.branchMenuDiff"),
                                                    t("language.message.deletion.depsList.branch.review")
                                                ]}
                                                validateMessage={t("language.message.deletion.verificationMessage", { entityName : branchInfo['branchName'] })}
                                                delayToUndo={DeleteService.getMediumDelay()}
                                                onUndoSuccess={async () => {
                                                    const currLocation = location.pathname;
                                                    await navigate(currLocation);
                                                }}
                                            />
                                        </RenderOnRole>
                                    </div>
                                    <div
                                        style={{
                                            marginRight: '10px',
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        <Link
                                            id={"edit-btn"}
                                            to={`/branch/detail/${branchId}`}
                                        >
                                            {t("button.cancel")}
                                        </Link>
                                    </div>
                                    <div>
                                        {
                                            (isFranchiseEdited || isEdited) && isValid
                                                ?
                                                <Link
                                                    id={"save-btn"}
                                                    onClick={() => putBranchInfo()}
                                                >
                                                    {
                                                        isSaveLoading ?
                                                            <Spinner size={"sm"} />
                                                            :
                                                            t("button.save")
                                                    }
                                                </Link>
                                                :
                                                <Link
                                                    id={"save-btn-disabled"}
                                                >
                                                    {t("button.save")}
                                                </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default BranchEdit;