import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import _ from "lodash";
import RecipeUtils from "../../../../../common/jsonDynamic/util/RecipeUtils";
import ProductJsonAxiosService from "../../../../../common/jsonDynamic/service/ProductJsonAxiosService";
import TitleAndColDiv from "../components/TitleAndColDiv";
import TitleAndInputBox from "../../../../../../common/dataView/TitleAndInputBox";
import TitleAndSelectBox from "../../../../../../common/dataView/TitleAndSelectBox";
import {Spinner} from "react-bootstrap";
import styled from "styled-components";
import ConfirmModal from "../../../../../../common/confirm/ConfirmModal";
import {toast} from "react-toastify";
import RecipeView from "./dynamic/RecipeView";

const MessageView = styled.div`
  text-align: center;
  padding: 10px 20px;
  color: #757575;
  font-size: 14px;
`;

const SimpleButton = styled.button`
  font-size: 14px;
  padding: 7px 10px;
  color: #fc7242;
  border: 1px solid #fc7242;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  background-color: white;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #ffeee6;
  }
`;

/** props                                                              */
/** 1. selectedBoothVersionId : Indicate the selected booth version id */
/** 2. menuRecipeList : A list of menuDetails, actually.               */
const MenuRecipeView = ({ menuInfo, selectedBoothVersionId, boothVersionList, menuRecipeList, refreshMenuList }) => {

    const location = useLocation();
    const { t } = useTranslation();
    const { franchiseId, menuId } = useParams();

    /* states */
    const [productJsonList, setProductJsonList] = useState([]);
    const [selectedProductJson, setSelectedProductJson] = useState("");
    const [tempProductJson, setTempProductJson] = useState("");
    const [currentMenuDetail, setCurrentMenuDetail] = useState({});
    const [currentMenuJsonRecipeStr, setCurrentMenuJsonRecipeStr] = useState({});

    /* isLoading */
    const [isValidationLoading, setIsValidationLoading] = useState(true);
    const [isMenuDetailSaveLoading, setIsMenuDetailSaveLoading] = useState(false);

    /* modals */
    const [productJsonChangeConfirmModalOpen, setProductJsonChangeConfirmModalOpen] = useState(false);
    const [menuDetailInitializeConfirmModalOpen, setMenuDetailInitializeConfirmModalOpen] = useState(false);

    /* alert */
    const AlertInner = (message) => {
        return (
            <div
                style={{
                    width: '100%',
                    textAlign: 'left'
                }}
            >
                <span
                    style={{
                        color: '#757575',
                        fontSize: '14px'
                    }}
                >
                    {message}
                </span>
            </div>
        );
    };

    /* data fetch */
    const fetchProductJsonList = async (boothVersionId) => {
        let newMenuDetail = {};
        const onSuccessCallback = async (data) => {
            await setProductJsonList(data);
            /* optional */
            menuRecipeList.map(menuRecipeObj => {
                if (menuRecipeObj['franchiseMenuDetailBoothVersionId'] === boothVersionId) {
                    newMenuDetail = _.cloneDeep(menuRecipeObj);
                    setSelectedProductJson(newMenuDetail['franchiseMenuDetailProductJsonVersion']);
                }
            });
            /* by default */
            if ((data.length > 0) && (_.isEqual(newMenuDetail, {}))) {
                await setSelectedProductJson(data[0]['metadata']['id']);
            }
            await setCurrentMenuDetail(newMenuDetail);
            await setCurrentMenuJsonRecipeStr(newMenuDetail['franchiseMenuDetailRecipe']);
        };
        const onFailureCallback = async (e)  => {
            await setCurrentMenuDetail(newMenuDetail);
            await setProductJsonList([]);
            console.log(e);
        };
        await ProductJsonAxiosService.getProductJsonList(franchiseId, menuId, selectedBoothVersionId, onSuccessCallback, onFailureCallback);
        await setIsValidationLoading(false);
    };

    /* product json change */
    const handleProductJsonChange = async (newId) => {
        if (newId === selectedProductJson) {
            return;
        } else {
            /* confirm modal open */
            await setTempProductJson(newId);
            await setProductJsonChangeConfirmModalOpen(true);
        }
    };

    const setMenuJsonRecipeByDefault = async (productJsonId) => {
        await setIsMenuDetailSaveLoading(true);
        /* save prev. product json */
        let prevProductJsonId = selectedProductJson;
        let prevMenuDetail = currentMenuDetail;
        try {
            /* new product json */
            let newJsonSchema = RecipeUtils.extractJsonSchemaFromList(productJsonId, productJsonList);

            /* generate default data */
            let newDefaultData = RecipeUtils.generateDefaultData(newJsonSchema);

            if (_.isEqual(newDefaultData, {})) {
                /* failed to generate default data */
                throw { message: "Failed to generate a default menu.", alertMsgKey: "genDataError" };
            }

            /* validation of data */
            let resOfValidation = RecipeUtils.validateJsonDataWithSchema(newJsonSchema, JSON.stringify(newDefaultData));
            if (resOfValidation === false) {
                throw { message: "Failed to validate a new default menu.", alertMsgKey: "genDataError" };
            }

            /* update the selected product json */
            await setSelectedProductJson(productJsonId);

            /* save menu detail */
            let newRequestBody = {};
            newRequestBody['franchiseMenuDetailBoothVersionId'] = selectedBoothVersionId;
            newRequestBody['isEnabled'] = false;
            newRequestBody['franchiseMenuDetailRecipe'] = JSON.stringify(newDefaultData);
            newRequestBody['franchiseMenuDetailProductJsonVersion'] = productJsonId;
            let isFailed = false;
            const onSuccessCallback = async (res) => {
                /* refresh all */
                await refreshMenuList(location.pathname);
                await setIsMenuDetailSaveLoading(false);
            };
            const onFailureCallback = async (e) => {
                console.log(e);
                isFailed = true;
            };
            if (isFailed) {
                throw { message: "Failed to save a new default menu.", alertMsgKey: "saveError" };
            }
            await ProductJsonAxiosService.saveOrGenerateMenuDetail(franchiseId, menuId, newRequestBody, onSuccessCallback, onFailureCallback);
        } catch (err) {
            console.log(err);
            /* alert */
            let message = t(`menu.franchiseMenu.recipeInfo.menuRecipe.message.error.${err['alertMsgKey']}`);
            toast.error(AlertInner(message));
            /* roll-back */
            await setSelectedProductJson(prevProductJsonId);
            await setCurrentMenuDetail(prevMenuDetail);
            setIsMenuDetailSaveLoading(false);
        }
    };

    const extractJsonLabel = (id) => {
        let res = "unknown";
        let metaDataList = RecipeUtils.extractMetadataFromProductJsonList(productJsonList);
        metaDataList.map((metaData) => {
            if (metaData['id'] === id) {
                res = metaData[t("language.dataKey.boothControl.json.label")];
            }
        });
        return res;
    };

    /* update currentMenuDetail state for the given booth version id */
    /* if not exists, set to be an empty object. */
    const updateMenuDetail = async (boothVersionId, callback) => {
        await setIsValidationLoading(true);
        await callback(boothVersionId);
    };

    /* effects */
    useEffect(() => {
        updateMenuDetail(selectedBoothVersionId, fetchProductJsonList);
    }, [selectedBoothVersionId, menuRecipeList]);

    if (isValidationLoading) {
        return (
            <TitleAndColDiv
                title={t("menu.franchiseMenu.recipeInfo.menuRecipe.title")}
                marginBtw={"12"}
                marginTop={"3"}
                height={"700"}
            >
                <Spinner size={"sm"} />
            </TitleAndColDiv>
        );

    } else if (productJsonList.length === 0) {
        return (
            <TitleAndColDiv
                title={t("menu.franchiseMenu.recipeInfo.menuRecipe.title")}
                marginBtw={"12"}
                marginTop={"3"}
                height={"700"}
            >
                <MessageView>
                    {t("menu.franchiseMenu.recipeInfo.menuRecipe.message.noSchema")}
                </MessageView>
            </TitleAndColDiv>
        );

    } else if (_.isEqual(currentMenuDetail, {})) {
        /* menuDetail NOT exists */
        return (
            <>
                <TitleAndColDiv
                    title={t("menu.franchiseMenu.recipeInfo.menuRecipe.title")}
                    marginBtw={"12"}
                    marginTop={"3"}
                    height={"700"}
                >
                    <MessageView>
                        {t("menu.franchiseMenu.recipeInfo.menuRecipe.message.noMenuDetail")}
                    </MessageView>
                    {/* init button */}
                    <SimpleButton
                        onClick={() => setMenuDetailInitializeConfirmModalOpen(true)}
                    >
                        {t("menu.franchiseMenu.recipeInfo.menuRecipe.button.registerRecipe")}
                    </SimpleButton>
                </TitleAndColDiv>
                <ConfirmModal
                    title={t("menu.franchiseMenu.recipeInfo.menuRecipe.message.sureToRegister", {
                        menuName : menuInfo['menuInfo'][t("language.dataKey.menu.franchise.info.name")],
                        boothVersionName : RecipeUtils.getBoothVersionNameFromBoothVersionList(selectedBoothVersionId, boothVersionList) })}
                    isOpen={menuDetailInitializeConfirmModalOpen}
                    setIsOpen={setMenuDetailInitializeConfirmModalOpen}
                    onConfirm={async () => await setMenuJsonRecipeByDefault(selectedProductJson)}
                    onReject={() => {}}
                    confirmLabel={t("button.yes")}
                    rejectLabel={t("button.no")}
                />
            </>
        );

    } else if ((!_.isEqual(currentMenuDetail, {}))
        && (!RecipeUtils.validateJsonDataWithSchema(RecipeUtils.extractJsonSchemaFromList(selectedProductJson, productJsonList), currentMenuDetail['franchiseMenuDetailRecipe']))) {
        /* menuDetail exists BUT NOT validated */
        return (
            <>
                <TitleAndColDiv
                    title={t("menu.franchiseMenu.recipeInfo.menuRecipe.title")}
                    marginBtw={"12"}
                    marginTop={"3"}
                    height={"700"}
                >
                    <MessageView>
                        {t("menu.franchiseMenu.recipeInfo.menuRecipe.message.validationFail")}
                    </MessageView>
                    <SimpleButton
                        onClick={() => setMenuDetailInitializeConfirmModalOpen(true)}
                    >
                        {t("menu.franchiseMenu.recipeInfo.menuRecipe.button.initializeRecipe")}
                    </SimpleButton>
                </TitleAndColDiv>
                <ConfirmModal
                    title={t("menu.franchiseMenu.recipeInfo.menuRecipe.message.sureToInitialize", { menuName : menuInfo['menuInfo'][t("language.dataKey.menu.franchise.info.name")], boothVersionName : RecipeUtils.getBoothVersionNameFromBoothVersionList(selectedBoothVersionId, boothVersionList) })}
                    isOpen={menuDetailInitializeConfirmModalOpen}
                    setIsOpen={setMenuDetailInitializeConfirmModalOpen}
                    onConfirm={async () => await setMenuJsonRecipeByDefault(selectedProductJson)}
                    onReject={() => {}}
                    confirmLabel={t("button.yes")}
                    rejectLabel={t("button.no")}
                />
            </>
        );

    } else {
        /* all good */
        return (
            <>
                <TitleAndColDiv
                    title={t("menu.franchiseMenu.recipeInfo.menuRecipe.title")}
                    marginBtw={"12"}
                    marginTop={"3"}
                    height={"auto"}
                >
                    <TitleAndSelectBox
                        title={t("menu.franchiseMenu.recipeInfo.menuRecipe.field.productSchema")}
                        innerValue={selectedProductJson}
                        valueList={RecipeUtils.extractMetadataFromProductJsonList(productJsonList)}
                        valueSelector={"id"}
                        viewSelector={t("language.dataKey.boothControl.json.label")}
                        onChange={(value) => handleProductJsonChange(value)}
                    />
                </TitleAndColDiv>
                <div
                    className={"contentInner"}
                    style={{
                        marginTop: '10px',
                        height: '590px'
                    }}
                >
                    {/* dynamic area */}
                    {
                        isMenuDetailSaveLoading ?
                            <Spinner size={"sm"} />
                            :
                            <RecipeView
                                currentMenuDetail={currentMenuDetail}
                                boothJsonVersionId={selectedBoothVersionId}
                                productJsonSchema={RecipeUtils.extractJsonSchemaFromList(selectedProductJson, productJsonList)}
                                refreshMenuList={refreshMenuList}
                            />
                    }
                </div>
                {/* confirmation modal : for change */}
                <ConfirmModal
                    title={t("menu.franchiseMenu.recipeInfo.menuRecipe.message.changeProductSchema")
                        + '\n'
                        + t("menu.franchiseMenu.recipeInfo.menuRecipe.message.sureToChange", { menuTypeName: extractJsonLabel(tempProductJson) })}
                    isOpen={productJsonChangeConfirmModalOpen}
                    setIsOpen={setProductJsonChangeConfirmModalOpen}
                    onConfirm={async () => await setMenuJsonRecipeByDefault(tempProductJson)}
                    onReject={() => {}}
                    confirmLabel={t("button.yes")}
                    rejectLabel={t("button.no")}
                />
            </>
        );
    }

};

export default MenuRecipeView;