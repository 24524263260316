import React, {useEffect, useState, useTransition} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import UserService from "../../services/UserService";
import RenderOnRole from "../../auth/RenderOnRole";
import {Container, Row} from "react-bootstrap";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import PaginationHandler from "../common/pagination/PaginationHandler";
import DateParseService from "../../services/DataParseService";
import Table from "../common/table/Table";
import QnaCategoryAdd from "./qna/QnaCategoryAdd";
import QnaCategoryEdit from "./qna/QnaCategoryEdit";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const FaqManagement = () => {

    const { t, i18n } = useTranslation();

    const [qnaCategoryList, setQnaCategoryList] = useState([{
        qnaCategoryId: "all",
        qnaCategoryName: "All",
        qnaCategoryName1: t("common.search.fields.all"),
        qnaCategoryName2: t("common.search.fields.all"),
        qnaCategoryName3: t("common.search.fields.all"),
        qnaCategoryName4: t("common.search.fields.all"),
        qnaCategoryName5: t("common.search.fields.all")
    }]);
    const [selectedCategoryId, setSelectedCategoryId] = useState("all");

    const [isCategoryAddModalOpen, setIsCategoryAddModalOpen] = useState(false);
    const [isCategoryEditModalOpen, setIsCategoryEditModalOpen] = useState(false);


    /* pagination */
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);

    const navigate = useNavigate();
    const [faqList, setFaqList] = useState([]);

    const fetchQnaCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/qna-category`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                let newQnaCategoryList = res.data.item.qnaCategoryList;
                newQnaCategoryList.unshift({
                    qnaCategoryId: "all",
                    qnaCategoryName: "All",
                    qnaCategoryName1: t("common.search.fields.all"),
                    qnaCategoryName2: t("common.search.fields.all"),
                    qnaCategoryName3: t("common.search.fields.all"),
                    qnaCategoryName4: t("common.search.fields.all"),
                    qnaCategoryName5: t("common.search.fields.all")
                })
                setQnaCategoryList(newQnaCategoryList);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const renameFaqIdToId = (objectList) => {
        let newObjList = [...objectList];
        const iter = 8 - objectList.length;
        if (newObjList.length < 8) {
            for (let i=0; i < iter; i++) {
                newObjList.push({
                    "faqId" : null
                });
            }
        }
        return newObjList.map(obj => {
            if ('faqId' in obj) {
                obj['id'] = obj['faqId'];
                delete obj['faqId'];
            }
            if ('generateDate' in obj) {
                obj['generateDate'] = DateParseService.dateTimeToDate(obj['generateDate']);
            }
            if (obj['faqAdminName'] == null) {
                obj['faqAdminName'] = t("common.noInfo");
            }
            return obj;
        })
    }

    const fetchFaqList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/faq?size=8&`
                + `category=${selectedCategoryId}`
                + `&page=${currentPageIndex}`
                + `&sort_target=lastEditDate`
                + `&sort=desc`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                let dataList = [];
                for (let i=0; i<7; i++) {
                    dataList.push({
                        "id" : null
                    });
                }
                setFaqList(dataList);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            } else {
                let dataList = [...res.data.item.faqList];
                let newDataList = [];
                dataList.map(prevObj => {
                    let newObj = {...prevObj};
                    newObj['qnaCategoryName'] = prevObj['qnaCategoryInfo']['qnaCategoryName'];
                    newObj['qnaCategoryName1'] = prevObj['qnaCategoryInfo']['qnaCategoryName1'];
                    newObj['qnaCategoryName2'] = prevObj['qnaCategoryInfo']['qnaCategoryName2'];
                    newObj['qnaCategoryName3'] = prevObj['qnaCategoryInfo']['qnaCategoryName3'];
                    newObj['qnaCategoryName4'] = prevObj['qnaCategoryInfo']['qnaCategoryName4'];
                    newObj['qnaCategoryName5'] = prevObj['qnaCategoryInfo']['qnaCategoryName5'];
                    newDataList.push(newObj);
                });
                setFaqList(renameFaqIdToId(newDataList));
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleCategoryChange = (value) => {
        setSelectedCategoryId(value);
    }


    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    };

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    };

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    };

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    };

    const initializeAll = () => {
        fetchQnaCategoryList();
        fetchFaqList();
    }

    useEffect(() => {
        initializeAll();
    }, []);

    useEffect(() => {
        fetchFaqList();
    }, [currentPageIndex, selectedCategoryId]);


    const columnData = [
        {
            accessor: t("language.dataKey.qna.category.name"),
            Header: t('common.category')
        },
        {
            accessor: 'faqTitle',
            Header: t('common.title')
        },
        {
            accessor: 'faqGroupName',
            Header: t('common.group')
        },
        {
            accessor: 'faqAdminName',
            Header: t('common.adminName')
        },
        {
            accessor: 'generateDate',
            Header: t('common.generateDate'),
        },
        {
            accessor: 'viewsCount',
            Header: t('common.viewsCount')
        },
    ];


    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.QNA_MANAGEMENT} allowedRing={2}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.faq.title")]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    {/* buttons */}
                    <Row>
                        <div
                            className={"contentInner"}
                            style={{
                                width: '100%',
                                display: 'flex'
                            }}
                        >
                            <div
                                style={{
                                    width: '150px',
                                    textAlign: 'center'
                                }}
                            >
                                <RenderOnRole type={"POST"} roleName={ImsSystemRole.QNA_MANAGEMENT} allowedRing={2} nothingOnRejected={true}>
                                    <button
                                        id={"category-add-btn"}
                                        onClick={() => navigate('/faq/add')}
                                    >
                                        {t("button.addFaq")}
                                    </button>
                                </RenderOnRole>
                            </div>
                            <div
                                style={{
                                    width: '150px',
                                    textAlign: 'center',
                                    marginLeft: 'auto'
                                }}
                            >
                                <RenderOnRole type={""} roleName={""} allowedRing={0} isForSuperMaster={false} nothingOnRejected={true}>
                                    <button
                                        id={"category-add-btn"}
                                        onClick={() => setIsCategoryAddModalOpen(true)}
                                    >
                                        {t("button.addQnaCategory")}
                                    </button>
                                </RenderOnRole>
                            </div>
                            <div
                                style={{
                                    width: '150px',
                                    textAlign: 'center',
                                    marginLeft: '10px'
                                }}
                            >
                                <RenderOnRole type={""} roleName={""} allowedRing={0} isForSuperMaster={false} nothingOnRejected={true}>
                                    <button
                                        id={"category-edit-btn"}
                                        onClick={() => setIsCategoryEditModalOpen(true)}
                                    >
                                        {t("button.editQnaCategory")}
                                    </button>
                                </RenderOnRole>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div
                            className={"contentInner"}
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex'
                                }}
                            >
                                {/* option panel */}
                                <div
                                    style={{
                                        width: '300px'
                                    }}
                                >
                                    {/*title, innerValue, valueList, valueSelector, viewSelector, onChange*/}
                                    <TitleAndSelectBox
                                        title={t("menu.faq.options.category")}
                                        innerValue={selectedCategoryId}
                                        valueList={qnaCategoryList}
                                        valueSelector={"qnaCategoryId"}
                                        viewSelector={t("language.dataKey.qna.category.name")}
                                        onChange={handleCategoryChange}
                                    />
                                </div>
                            </div>
                            {/* table */}
                            <div
                                style={{
                                    width: '100%',
                                    marginTop: '15px'
                                }}
                            >
                                <Table columns={columnData} data={faqList} baseUrl={'detail'} />
                            </div>
                            {/* pagination */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    marginTop: '10px',
                                    display: 'flex',
                                    flexDirection: 'row-reverse'
                                }}
                            >
                                <div
                                    style={{
                                        width: '400px',
                                        height: '100%'
                                    }}
                                >
                                    <PaginationHandler
                                        goBefore={handleGoBefore}
                                        goAfter={handleGoAfter}
                                        goFirst={handleGoFirst}
                                        goLast={handleGoLast}
                                        pageSize={10}
                                        currentIndex={currentPageIndex}
                                        maxIndex={maxPageIndex}
                                        totalItemNumber={totalElements}
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
            <QnaCategoryAdd
                isOpen={isCategoryAddModalOpen}
                setIsOpen={setIsCategoryAddModalOpen}
                onRefresh={initializeAll}
            />
            <QnaCategoryEdit
                isOpen={isCategoryEditModalOpen}
                setIsOpen={setIsCategoryEditModalOpen}
                onRefresh={initializeAll}
            />
        </RenderOnRole>
    );
};

export default FaqManagement;
