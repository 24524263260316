import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import SchemaParser from "../../utils/SchemaParser";
import uuid from "react-uuid";
import _ from "lodash";
import DataCompareService from "../../../../../services/DataCompareService";
import BoothControlAuthService from "../../auth/BoothControlAuthService";
import StringField from "./connection/StringField";
import CatStringField from "./connection/CatStringField";
import IntegerField from "./connection/IntegerField";
import BooleanField from "./connection/BooleanField";
import {Spinner} from "react-bootstrap";
import BoothService from "../../axiosServices/BoothService";
import ConfirmModal from "../../../../common/confirm/ConfirmModal";
import {toast} from "react-toastify";
import AuthService from "../../../../../services/AuthService";
import ImsSystemRole from "../../../../../auth/roles/ImsSystemRole";

const SaveButton = styled.button`
  width: 30%;
  border: 1px solid #fc7242;
  background-color: white;
  color: #fc7242;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #fff1ec;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  width: 30%;
  border: 1px solid #ebebeb;
  background-color: white;
  color: #ebebeb;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  
  &:focus {
    outline: none;
  }
`;

const DataContainer = styled.div`
  width: 100%;
  padding: 15px 15px 15px 15px;
  background-color: white;
  border-radius: 5px;
`;

const Data = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const ContentBox = styled.div`
  width: 100%;
  padding: 2px 10px;
`;

const Title = styled.div`
  color: #757575;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 15px;
`;

const CategorySelect = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: left;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
`;

const ConnectionTypeButton = styled.button`
  width: 100px;
  margin-left: 10px;
  border: 1px solid #ebebeb;
  background-color: white;
  color: #ebebeb;
  padding: 3px 5px;
  border-radius: 15px;
  font-weight: bold;
  
  &:focus {
    outline: none;
  }
`;

const ConnectionTypeButtonSelected = styled.button`
  width: 100px;
  margin-left: 10px;
  border: 1px solid #fc7242;
  background-color: #fcf9ed;
  color: #fc7242;
  padding: 3px 5px;
  border-radius: 15px;
  font-weight: bold;
  
  &:focus {
    outline: none;
  }
`;

const ConnectionInner = ({ connectionData, connectionSchema, onRefresh }) => {

    const { t, i18n } = useTranslation();

    /* params */
    const { boothId, deviceId } = useParams();

    /* request body */
    const [requestBody, setRequestBody] = useState({});
    const [fixedRequestBody, setFixedRequestBody] = useState({});

    /* isForEdit */
    const [isForEdit, setIsForEdit] = useState(false);

    /* isValid & isEdited */
    const [isValidMap, setIsValidMap] = useState({});
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);

    /* connection types */
    const [connectionTypeList, setConnectionTypeList] = useState([]);
    const [currentConnectionType, setCurrentConnectionType] = useState("");

    /* isButtonLoading */
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    /* confirmation */
    const [isPutConfirmModalOpen, setIsPutConfirmModalOpen] = useState(false);

    /* for toast alert */
    const ToastMessage = (text) => {
        return (
            <span
                style={{
                    fontSize: '14px',
                    color: '#757575'
                }}
            >
                {text}
            </span>
        );
    };

    const toastAlert = (text, isSuccess) => {
        if (isSuccess) {
            toast.success(ToastMessage(text));
        } else {
            toast.error(ToastMessage(text));
        }
    };

    /* put connection info */
    const putConnection = async () => {
        let otherProtocols = [];
        connectionTypeList.map(connectionType => {
           if (connectionType !== currentConnectionType) {
               otherProtocols.push(connectionType);
           }
        });
        let axiosBody = {
            protocol : currentConnectionType,
        };
        axiosBody[currentConnectionType] = requestBody;
        otherProtocols.map(otherProtocolName => {
            axiosBody[otherProtocolName] = connectionData[otherProtocolName];
        });
        /* callbacks */
        const onSuccess = () => {
            setIsButtonLoading(false);
            setIsEdited(false);
            onRefresh();
            toastAlert(t("menu.boothControl.infoMessage.editRequestSuccess"), true);
        };
        const onFailure = (e) => {
            setIsButtonLoading(false);
            toastAlert(t("menu.boothControl.infoMessage.editRequestFail"), false);
        };
        await setIsButtonLoading(true);
        let requestPath = `/booth/${boothId}/device/${deviceId}/connection`;
        BoothService.putBoothDataToEss(requestPath, axiosBody, onSuccess, onFailure);
    }

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* validation */
    const validateValue = (fieldKey, value) => {
        /* string, cat_string, integer, boolean */
        let dataType = connectionSchema[currentConnectionType]['properties'][fieldKey]['primitive'];
        if (dataType === "string") {
            if (connectionSchema[currentConnectionType]['properties'][fieldKey]['isCategorical']) {
                dataType = "cat_string";
            }
        }
        /* validator & property */
        let validator = connectionSchema[currentConnectionType]['validator']['properties'][fieldKey];
        let property = connectionSchema[currentConnectionType]['properties'][fieldKey];
        /* empty check */
        if (typeof value === "undefined" || value == null || value === "") {
            return false;
        } else {
            /* handling case by case */
            if (dataType === "string") {
                let lengthValidity = true;
                if (property['hasLengthLimit']) {
                    lengthValidity = (value.length <= validator['minLength']) && (value.length >= validator['maxLength']);
                }
               return lengthValidity;
            } else if (dataType === "cat_string") {
                /* no validation */
                return true;
            } else if (dataType === "integer") {
                let intValue = parseInt(value);
                let rangeValidity = true;
                let multipleOfValidity = true;
                if (property['hasRangeLimit']) {
                    rangeValidity = (intValue >= validator['minimum']) && (intValue <= validator['maximum']);
                }
                if (validator['multipleOf'] !== 0) {
                    multipleOfValidity = ((intValue % validator['multipleOf']) === 0);
                }
                return (rangeValidity && multipleOfValidity);
            } else { // boolean
                /* no validation */
                return true;
            }
        }
    };

    /* handle input */
    const handleChange = (fieldKey, value) => {
        /* string, cat_string, integer, boolean */
        let dataType = connectionSchema[currentConnectionType]['properties'][fieldKey]['primitive'];
        if (dataType === "integer") {
            updateRequestBody(fieldKey, parseInt(value));
        } else {
            updateRequestBody(fieldKey, value);
        }
        updateIsValidMap(fieldKey, validateValue(fieldKey, value));
    };

    /* effects */
    useEffect(() => {
        if (Object.keys(connectionSchema).length !== 0) {
            setConnectionTypeList(SchemaParser.extractListFromPattern(connectionSchema['protocol']['validator']['pattern']));
        }
        if (Object.keys(connectionData).length !== 0) {
            setCurrentConnectionType(connectionData['protocol']);
        }
    }, [connectionSchema]);

    useEffect(() => {
        if (currentConnectionType !== "") {
            let fieldKeyList = Object.keys(connectionSchema[currentConnectionType]['properties']);
            let newRequestBody = {};
            fieldKeyList.map(key => {
                newRequestBody[key] = connectionData[currentConnectionType][key];
            });
            setRequestBody(newRequestBody);
            setFixedRequestBody(_.cloneDeep(newRequestBody));
            /* isValidMap initialization */
            let newIsValidMap = {};
            Object.keys(newRequestBody).map(fieldKey => {
                newIsValidMap[fieldKey] = validateValue(fieldKey, newRequestBody[fieldKey]);
            });
            setIsValidMap(newIsValidMap);
            /* auth code parsing */
            let authCode = connectionSchema[currentConnectionType]['superPermission'];
            setIsForEdit(BoothControlAuthService.hasPutAuth(authCode) && AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "PUT"));
        } else {
            return;
        }
    }, [currentConnectionType]);

    /* isEdited tracking */
    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);

    /* isValid tracking */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    const renderConnectionTypeList = () => {
        let connectionButtonElements = [];
        if (connectionTypeList.length === 0) {
            return (<></>);
        } else {
            connectionTypeList.map(connectionType => {
                if (connectionType === currentConnectionType) {
                    connectionButtonElements.push(
                        <ConnectionTypeButtonSelected
                            key={uuid()}
                        >
                            {connectionType}
                        </ConnectionTypeButtonSelected>
                    );
                } else {
                    connectionButtonElements.push(
                        <ConnectionTypeButton
                            key={uuid()}
                            onClick={() => setCurrentConnectionType(connectionType)}
                        >
                            {connectionType}
                        </ConnectionTypeButton>
                    );
                }
            });
            return connectionButtonElements;
        }
    };

    const renderRequestBody = () => {
        if (currentConnectionType === "") {
            /* select message */
            return (
                <ContentBox>
                    <span>
                        {t("menu.boothControl.schema.connection.chooseConnectionMessage")}
                    </span>
                </ContentBox>
            );
        } else if (_.isEqual(Object.keys(connectionData[currentConnectionType]), Object.keys(requestBody))) {
            if (Object.keys(requestBody).length === 0) {
                return (<></>);
            } else {
                let fieldElements = [];
                let fieldKeyList = Object.keys(connectionSchema[currentConnectionType]['properties']);
                let requestBodySchema = connectionSchema[currentConnectionType];
                fieldKeyList.map(fieldKey => {
                    const title = () => {
                        return (
                            <Title>
                                {property[t("language.dataKey.boothControl.json.label")]}
                            </Title>
                        );
                    };
                    let validator = requestBodySchema['validator']['properties'][fieldKey];
                    let property = requestBodySchema['properties'][fieldKey];
                    let primitive = property['primitive'];
                    if (primitive === "string" && property['isCategorical']) {
                        fieldElements.push(
                            <div
                                key={currentConnectionType + fieldKey}
                            >
                                <ContentBox>
                                    {title()}
                                    <CatStringField
                                        fieldKey={fieldKey}
                                        value={requestBody[fieldKey]}
                                        updateValue={handleChange}
                                        isForEdit={isForEdit}
                                        pattern={validator['pattern']}
                                    />
                                </ContentBox>
                            </div>
                        );
                    } else if (primitive === "string") {
                        fieldElements.push(
                            <div
                                key={currentConnectionType + fieldKey}
                            >
                                <ContentBox>
                                    {title()}
                                    <StringField
                                        fieldKey={fieldKey}
                                        value={requestBody[fieldKey]}
                                        isValid={isValidMap[fieldKey]}
                                        updateValue={handleChange}
                                        isForEdit={isForEdit}
                                    />
                                </ContentBox>
                            </div>
                        );
                    } else if (primitive === "integer") {
                        fieldElements.push(
                            <div
                                key={currentConnectionType + fieldKey}
                            >
                                <ContentBox>
                                    {title()}
                                    <IntegerField
                                        fieldKey={fieldKey}
                                        value={requestBody[fieldKey]}
                                        isValid={isValidMap[fieldKey]}
                                        updateValue={handleChange}
                                        isForEdit={isForEdit}
                                    />
                                </ContentBox>
                            </div>
                        );
                    } else { // boolean
                        fieldElements.push(
                            <div
                                key={currentConnectionType + fieldKey}
                            >
                                <ContentBox>
                                    {title()}
                                    <BooleanField
                                        fieldKey={fieldKey}
                                        value={requestBody[fieldKey]}
                                        updateValue={handleChange}
                                        isForEdit={isForEdit}
                                    />
                                </ContentBox>
                            </div>
                        );
                    }
                });
                return fieldElements;
            }
        }
    }

    if (Object.keys(connectionSchema).length === 0) {
        return (
            <DataContainer>
                <Data>
                    <span
                        style={{
                            color: '#757575'
                        }}
                    >
                        {t("menu.boothControl.schema.connection.noConnMessage")}
                    </span>
                </Data>
            </DataContainer>
        );
    } else {
        if (Object.keys(requestBody).length === 0) {
            return (<></>);
        }
        return (
            <DataContainer>
                <CategorySelect>
                    {renderConnectionTypeList()}
                </CategorySelect>
                <Data>
                    {renderRequestBody()}
                </Data>
                <div
                    style={{
                        textAlign: 'right',
                        marginTop: '30px'
                    }}
                >
                    {
                        isEdited && isValid ?
                            <SaveButton
                                onClick={() => setIsPutConfirmModalOpen(true)}
                            >
                                {
                                    isButtonLoading ?
                                        <Spinner size={"sm"} />
                                        :
                                        t("menu.boothControl.button.sendRequest")
                                }
                            </SaveButton>
                            :
                            <SaveButtonDisabled>
                                {t("menu.boothControl.button.sendRequest")}
                            </SaveButtonDisabled>
                    }
                </div>
                <ConfirmModal
                    isOpen={isPutConfirmModalOpen}
                    setIsOpen={setIsPutConfirmModalOpen}
                    title={t("menu.boothControl.infoMessage.sureToPut")}
                    confirmLabel={t("button.yes")}
                    rejectLabel={t("button.no")}
                    onConfirm={putConnection}
                    onReject={() => {}}
                />
            </DataContainer>
        );
    }
};

export default ConnectionInner;