import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useParams, useNavigate} from "react-router-dom";
import DataCompareService from "../../services/DataCompareService";
import axios from "axios";
import RenderOnRole from "../../auth/RenderOnRole";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import DataParseService from "../../services/DataParseService";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import LanguageInput from "../../language/component/LanguageInput";
import LanguageSelectBox from "../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const isValidMapInit = {
    productProductCategoryId : true,
    productName: true,
    productName1 : true,
    productName2 : true,
    productName3 : true,
    productName4 : true,
    productName5 : true,
    productDescription : true,
    productDescription1 : true,
    productDescription2 : true,
    productDescription3 : true,
    productDescription4 : true,
    productDescription5 : true,
    productAmount : true,
    productUnit : true,
    productUnitAmount : true,
    productPrice : true,
    productPriceUnit : true,
    futureRestockingDate : true,
    productStatusCode : true,
    productGroupId : true
};

const ProductEdit = () => {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    /* path variable */
    const { productId } = useParams();

    const productStatusList = [
        {
            value : 0,
            statusName : t("menu.product.status.inactive")
        },
        {
            value : 1,
            statusName : t("menu.product.status.active")
        },
        {
            value : 2,
            statusName : t("menu.product.status.out_of_order")
        }
    ];

    /* state for language */
    const targetFieldsString = `"${t("common.name")}", "${t("common.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* group list */
    const [groupList, setGroupList] = useState([]);
    const [productCategoryList, setProductCategoryList] = useState([]);
    const [groupProductCategoryList, setGroupProductCategoryList] = useState([]);

    /* product information */
    const [prevProductInfo, setPrevProductInfo] = useState({});
    const [afterProductInfo, setAfterProductInfo] = useState({});

    /* trackers */
    const [isEdited, setIsEdited] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);

    /* fetch group list */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setGroupList(res.data.item.groupList);
        } catch (e) {
            console.log(e);
        }
    }

    const fetchProductCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product-category`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setProductCategoryList(res.data.item.productCategoryList);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* fetch product information */
    const fetchProductInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product/${productId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let productInfoObj = {};
            productInfoObj["productProductCategoryId"] = res.data.item.productDetail["productProductCategoryId"];
            productInfoObj["productName"] = res.data.item.productDetail["productName"];
            productInfoObj["productName1"] = res.data.item.productDetail["productName1"];
            productInfoObj["productName2"] = res.data.item.productDetail["productName2"];
            productInfoObj["productName3"] = res.data.item.productDetail["productName3"];
            productInfoObj["productName4"] = res.data.item.productDetail["productName4"];
            productInfoObj["productName5"] = res.data.item.productDetail["productName5"];
            productInfoObj["productDescription"] = res.data.item.productDetail["productDescription"];
            productInfoObj["productDescription1"] = res.data.item.productDetail["productDescription1"];
            productInfoObj["productDescription2"] = res.data.item.productDetail["productDescription2"];
            productInfoObj["productDescription3"] = res.data.item.productDetail["productDescription3"];
            productInfoObj["productDescription4"] = res.data.item.productDetail["productDescription4"];
            productInfoObj["productDescription5"] = res.data.item.productDetail["productDescription5"];
            productInfoObj["productAmount"] = res.data.item.productDetail["productAmount"];
            productInfoObj["productUnit"] = res.data.item.productDetail["productUnit"];
            productInfoObj["productUnitAmount"] = res.data.item.productDetail["productUnitAmount"];
            productInfoObj["productPrice"] = res.data.item.productDetail["productPrice"];
            productInfoObj["productPriceUnit"] = res.data.item.productDetail["productPriceUnit"];
            if (res.data.item.productDetail["futureRestockingDate"] != null) {
                productInfoObj["futureRestockingDate"] = DataParseService.dateTimeToDate(res.data.item.productDetail["futureRestockingDate"]);
            }
            productInfoObj["productStatusCode"] = res.data.item.productDetail["productStatusCode"];
            productInfoObj["productGroupId"] = res.data.item.productDetail["productGroupId"];
            setPrevProductInfo(productInfoObj);
            setAfterProductInfo(DataCompareService.deepCopyObj(productInfoObj));
        } catch (e) {
            console.log(e);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    /* utils */
    const updateAfterProductInfo = (key, value) => {
        setAfterProductInfo(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    /* handlers */
    const handleProductAmountInput = (value) => {
        updateAfterProductInfo("productAmount", parseFloat(value));
    };

    const handleProductUnitInput = (value) => {
        updateAfterProductInfo("productUnit", value);
    };

    const handleProductUnitAmountInput = (value) => {
        updateAfterProductInfo("productUnitAmount", parseFloat(value));
    };

    const handleProductPriceInput = (value) => {
        updateAfterProductInfo("productPrice", parseFloat(value));
    };

    const handleProductPriceUnitInput = (value) => {
        updateAfterProductInfo("productPriceUnit", value);
    };

    const handleFutureRestockingDateInput = (value) => {
        updateAfterProductInfo("futureRestockingDate", value);
    };

    const handleProductStatusCodeChange = (value) => {
        updateAfterProductInfo("productStatusCode", parseInt(value));
    };

    const handleGroupIdChange = (value) => {
        updateAfterProductInfo("productGroupId", value);
    };

    const handleProductCategoryChange = (value) => {
        updateAfterProductInfo("productProductCategoryId", value);
    };

    /* put product info */
    const putProductInformation = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product/${productId}`;
            let requestBody = JSON.stringify(afterProductInfo);
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                requestBody,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate(`/product/detail/${productId}`);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    /* effects */
    useEffect(() => {
        fetchGroupList();
        fetchProductCategoryList();
        fetchProductInfo();
    }, []);

    /* trackers */
    useEffect(() => {
        setIsEdited(!DataCompareService.isSameObj(prevProductInfo, afterProductInfo));
    }, [afterProductInfo]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        if (afterProductInfo["productGroupId"] == null) {
            return;
        }
        let filteredList = productCategoryList.filter(product => {
            return (product.productCategoryGroupId === afterProductInfo["productGroupId"]);
        });
        if (filteredList.length === 0) {
            updateAfterProductInfo("productProductCategoryId", "");
            updateIsValidMap("productProductCategoryId", false);
        } else {
            if (afterProductInfo["productGroupId"] === prevProductInfo["productGroupId"]) {
                updateAfterProductInfo("productProductCategoryId", prevProductInfo["productProductCategoryId"]);
                updateIsValidMap("productProductCategoryId", true);
            } else {
                updateAfterProductInfo("productProductCategoryId", filteredList[0].productCategoryId);
                updateIsValidMap("productProductCategoryId", true);
            }
        }
        setGroupProductCategoryList(filteredList);
    }, [afterProductInfo["productGroupId"], productCategoryList]);

    return (
        <RenderOnRole type={"PUT"} roleName={ImsSystemRole.PRODUCT_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={`/product/detail/${productId}`}
                />
                <PageTitleWithDepth
                    titles={[t("menu.product.title"), t("menu.product.detail.title"), t("menu.product.edit.title")]}
                    depth={3}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                <Container fluid>
                    {/* main area */}
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                height: '655px'
                            }}
                        >
                            <Container fluid>
                                <Row>
                                    <LanguageSelectBox
                                        title={t("language.selectBox.title.product")}
                                        languageMetadataList={languageMetadataList}
                                        currentLang={currentLang}
                                        setCurrentLang={setCurrentLang}
                                        setIsHighlightOn={setIsHighlightOn}
                                        targetFieldString={targetFieldsString}
                                    />
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <LanguageInput
                                        type={"text"}
                                        title={t("common.name") + ` (${t("language.callName." + currentLang)})`}
                                        dataKeyTranslationPath={"language.dataKey.product.name"}
                                        valueMap={afterProductInfo}
                                        updateValueMap={updateAfterProductInfo}
                                        isValidMap={isValidMap}
                                        updateIsValidMap={updateIsValidMap}
                                        isForEdit={true}
                                        currentLang={currentLang}
                                        isHighlightOn={isHighlightOn}
                                    />
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <LanguageInput
                                        type={"textarea"}
                                        title={t("common.description") + ` (${t("language.callName." + currentLang)})`}
                                        dataKeyTranslationPath={"language.dataKey.product.description"}
                                        valueMap={afterProductInfo}
                                        updateValueMap={updateAfterProductInfo}
                                        isValidMap={isValidMap}
                                        updateIsValidMap={updateIsValidMap}
                                        isForEdit={true}
                                        height={"160"}
                                        currentLang={currentLang}
                                        isHighlightOn={isHighlightOn}
                                    />
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.amount")}
                                            value={afterProductInfo["productAmount"]}
                                            onChange={handleProductAmountInput}
                                            isForEdit={true}
                                            type={"number"}
                                            isValid={isValidMap["productAmount"]}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.unitAmount")}
                                            value={afterProductInfo["productUnitAmount"]}
                                            onChange={handleProductUnitAmountInput}
                                            isForEdit={true}
                                            type={"number"}
                                            isValid={isValidMap["productUnitAmount"]}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.unit")}
                                            value={afterProductInfo["productUnit"]}
                                            onChange={handleProductUnitInput}
                                            isForEdit={true}
                                            type={"text"}
                                            isValid={isValidMap["productUnit"]}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.price")}
                                            value={afterProductInfo["productPrice"]}
                                            onChange={handleProductPriceInput}
                                            isForEdit={true}
                                            type={"number"}
                                            isValid={isValidMap["productPrice"]}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.priceUnit")}
                                            value={afterProductInfo["productPriceUnit"]}
                                            onChange={handleProductPriceUnitInput}
                                            isForEdit={true}
                                            type={"text"}
                                            isValid={isValidMap["productPriceUnit"]}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.futureRestockingDate")}
                                            value={afterProductInfo["futureRestockingDate"]}
                                            onChange={handleFutureRestockingDateInput}
                                            isForEdit={true}
                                            type={"date"}
                                            isValid={isValidMap["futureRestockingDate"]}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndSelectBox
                                            title={t("common.status")}
                                            innerValue={afterProductInfo["productStatusCode"]}
                                            onChange={handleProductStatusCodeChange}
                                            valueList={productStatusList}
                                            valueSelector={"value"}
                                            viewSelector={"statusName"}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndSelectBox
                                            title={t("common.group")}
                                            innerValue={afterProductInfo["productGroupId"]}
                                            onChange={handleGroupIdChange}
                                            valueList={groupList}
                                            valueSelector={"groupId"}
                                            viewSelector={"groupName"}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndSelectBox
                                            title={t("common.category")}
                                            innerValue={afterProductInfo["productProductCategoryId"]}
                                            onChange={handleProductCategoryChange}
                                            valueList={groupProductCategoryList}
                                            valueSelector={"productCategoryId"}
                                            viewSelector={t("language.dataKey.product.category.name")}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Row>
                    {/* buttons */}
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                height: '40px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'right'
                            }}
                        >
                            <div
                                style={{ marginRight: '10px' }}
                            >
                                <Link
                                    id={"edit-btn"}
                                    to={`/product/detail/${productId}`}
                                >
                                    {t("button.cancel")}
                                </Link>
                            </div>
                            <div>
                                {
                                    (isEdited && isValid)
                                        ?
                                        <Link
                                            id={"save-btn"}
                                            onClick={() => putProductInformation()}
                                        >
                                            {t("button.save")}
                                        </Link>
                                        :
                                        <Link
                                            id={"save-btn-disabled"}
                                        >
                                            {t("button.save")}
                                        </Link>
                                }
                            </div>
                        </div>
                    </Row>
                </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default ProductEdit;