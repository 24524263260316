const Ajv = require("ajv");
const addFormats = require("ajv-formats");

/** @param schema::Object
 * @param data::Object */
const validateWithJSONSchema = (schema, data) => {

    /* non-object */
    if ((typeof schema === "undefined") || (schema == null) || (typeof schema !== "object")) {
        return false;
    }

    /* validation field by field */
    const ajv = new Ajv({
        strict: true,
        validateFormats: true
    });
    addFormats(ajv);

    /* validation */
    try {
        const validate = ajv.compile(schema);
        let tempResult = validate(data);
        if (!tempResult) {
            return false;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
    return true;
};

const JsonValidateUtils = {
    validateWithJSONSchema
};

export default JsonValidateUtils;