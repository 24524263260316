import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndTextArea from "../../../common/dataView/TitleAndTextArea";
import OverlayToolTip from "../../../menu/common/toolTip/OverlayToolTip";
import { MdOutlineCancel, MdDeleteForever, MdAddCircleOutline, MdCheckCircleOutline } from "react-icons/md";
import _ from "lodash";
import DataUtils from "../../../menu/common/utils/DataUtils";
import ConfirmModal from "../../../common/confirm/ConfirmModal";
import {toast} from "react-toastify";
import ToastAlertView from "../../../common/alert/ToastAlertView";
import axios from "axios";
import UserService from "../../../../services/UserService";
import DeleteConfirmModal from "../../../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../../../services/DeleteService";

const Dot = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${(props) => props.color ? props.color : "black"};
  border-radius: 50%;
  display: flex;
  margin-left: 3px;
`;

const OutContainer = styled.div`
  width: 100%;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  margin-bottom: 10px;
  transition: all ease-in-out 0.2s;
  padding: 5px;
  
  &:hover {
    -webkit-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    -moz-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
  }
`;

const Title = styled.span`
  font-size: 14px;
  color: #757575;
  margin-left: 15px;
`;

const ButtonMini = styled.button`
  background: none;
  border: none;
  transition: all ease-in-out 0.2s;
  color: ${props => props.color};
  
  &:hover {
    color: ${props => props.hoverColor};
  }
  
  &:focus {
    outline: none;
  }
`;

const BottomButton = styled.button`
  background: none;
  border: 1px solid ${props => props.color};
  color: ${props => props.color};
  border-radius: 10px;
  font-size: 14px;
  padding: 3px 10px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: ${props => props.hoverColor};
  }
  
  &:focus {
    outline: none;
  }
`;

const colorMap = {
    0 : "#c2c2c2", /* canceled */
    1 : "#f87171", /* planned */
    2 : "#3399ff", /* done */
    "button" : {
        "toCancel" : {
            "main" : "#c2c2c2",
            "hover" : "#757575"
        },
        "toDone" : {
            "main" : "#99ccff",
            "hover" : "#1a8cff"
        },
        "toPlanned" : {
            "main" : "#ff8080",
            "hover" : "#ff4d4d"
        }
    }
};

const requestBodyInit = {
    "scheduleTitle" : "",
    "scheduleContent" : "",
    "scheduleStatusCode" : 0,
    "scheduleDate" : ""
};

const ScheduleItemView = ({ scheduleId, title, content, status, date, refreshData }) => {

    const { t } = useTranslation();

    /* states */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    const [isEdited, setIsEdited] = useState(false);

    /* confirmation */
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    const handleContentInput = (value) => {
        DataUtils.updateHelper(setRequestBody, "scheduleContent", value);
    };

    /* api */

    const putSchedule = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/schedule`
                + `/${scheduleId}`;
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await refreshData();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    const toDoneSchedule = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/schedule`
                + `/${scheduleId}`;
            let newRequestBody = _.cloneDeep(fixedRequestBody);
            newRequestBody['scheduleStatusCode'] = 2;
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await refreshData();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    const toCanceledSchedule = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/schedule`
                + `/${scheduleId}`;
            let newRequestBody = _.cloneDeep(fixedRequestBody);
            newRequestBody['scheduleStatusCode'] = 0;
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await refreshData();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    const toPlannedSchedule = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/schedule`
                + `/${scheduleId}`;
            let newRequestBody = _.cloneDeep(fixedRequestBody);
            newRequestBody['scheduleStatusCode'] = 1;
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await refreshData();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* effects */
    useEffect(() => {
        let newRequestBody = {};
        newRequestBody["scheduleTitle"] = title;
        newRequestBody["scheduleContent"] = content;
        newRequestBody["scheduleStatusCode"] = status;
        newRequestBody["scheduleDate"] = date;
        setRequestBody(newRequestBody);
        setFixedRequestBody(_.cloneDeep(newRequestBody));
    }, [scheduleId, title, content, status, date]);

    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);

    return (
        <OutContainer>
            <Container fluid>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            display: 'inline-flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            overflow: 'auto'
                        }}
                    >
                        {/* status dot */}
                        <Dot
                            style={{
                                display: 'block'
                            }}
                            color={colorMap[status]}
                        />

                        {/* title */}
                        <Title>
                            {title}
                        </Title>

                        {/* status change button */}
                        {/* PLANNED -> DONE */}
                        {/* PLANNED -> CANCELED */}
                        {/* DONE -> PLANNED */}
                        {/* CANCELED -> PLANNED */}
                        {
                            (status === 1) &&
                            <OverlayToolTip direction={"top"} text={t("menu.schedule.button.toCanceled")}>
                                <ButtonMini
                                    color={colorMap['button']['toCancel']['main']}
                                    hoverColor={colorMap['button']['toCancel']['hover']}
                                    style={{
                                        marginLeft: 'auto'
                                    }}
                                    onClick={toCanceledSchedule}
                                >
                                    <MdOutlineCancel
                                        size={"23"}
                                    />
                                </ButtonMini>
                            </OverlayToolTip>
                        }
                        {
                            (status === 1) &&
                            <OverlayToolTip direction={"top"} text={t("menu.schedule.button.toDone")}>
                                <ButtonMini
                                    color={colorMap['button']['toDone']['main']}
                                    hoverColor={colorMap['button']['toDone']['hover']}
                                    style={{
                                        marginLeft: '5px'
                                    }}
                                    onClick={toDoneSchedule}
                                >
                                    <MdCheckCircleOutline
                                        size={"23"}
                                    />
                                </ButtonMini>
                            </OverlayToolTip>
                        }
                        {
                            (status === 0) &&
                            <OverlayToolTip direction={"top"} text={t("menu.schedule.button.toPlanned")}>
                                <ButtonMini
                                    color={colorMap['button']['toPlanned']['main']}
                                    hoverColor={colorMap['button']['toPlanned']['hover']}
                                    style={{
                                        marginLeft: 'auto'
                                    }}
                                    onClick={toPlannedSchedule}
                                >
                                    <MdAddCircleOutline
                                        size={"23"}
                                    />
                                </ButtonMini>
                            </OverlayToolTip>
                        }
                        {
                            (status === 2) &&
                            <OverlayToolTip direction={"top"} text={t("menu.schedule.button.toPlanned")}>
                                <ButtonMini
                                    color={colorMap['button']['toPlanned']['main']}
                                    hoverColor={colorMap['button']['toPlanned']['hover']}
                                    style={{
                                        marginLeft: 'auto'
                                    }}
                                    onClick={toPlannedSchedule}
                                >
                                    <MdAddCircleOutline
                                        size={"23"}
                                    />
                                </ButtonMini>
                            </OverlayToolTip>
                        }
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* content */}
                    <TitleAndTextArea
                        title={""}
                        value={requestBody["scheduleContent"]}
                        onChange={handleContentInput}
                        isForEdit={true}
                        isValid={true}
                    />
                </Row>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            display: 'inline-flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }}
                    >
                        {/* delete button */}
                        <BottomButton
                            color={"#ff3300"}
                            hoverColor={"#ffebe6"}
                            style={{
                                marginLeft: 'auto'
                            }}
                            onClick={() => setIsDeleteConfirmOpen(true)}
                        >
                            {t("button.delete")}
                        </BottomButton>

                        {/* save button */}
                        {
                            isEdited &&
                            <BottomButton
                                color={"#fc7242"}
                                hoverColor={"#ffffe6"}
                                style={{
                                    marginLeft: '10px'
                                }}
                                onClick={putSchedule}
                            >
                                {t("button.save")}
                            </BottomButton>
                        }
                        {
                            (!isEdited) &&
                            <BottomButton
                                color={"#c2c2c2"}
                                hoverColor={"white"}
                                style={{
                                    marginLeft: '10px'
                                }}
                            >
                                {t("button.save")}
                            </BottomButton>
                        }
                    </div>
                </Row>
            </Container>
            <DeleteConfirmModal
                isOpen={isDeleteConfirmOpen}
                setIsOpen={setIsDeleteConfirmOpen}
                isDelayed={false}
                confirmMessage={t("language.message.deletion.confirmation", { entityName : t("language.message.deletion.depsList.schedule.self") })}
                entity={DeleteService.ValidEntities.SCHEDULE}
                entityName={t("language.message.deletion.depsList.schedule.self")}
                id={`schedule/${scheduleId}`}
                onDeleteSuccess={async () => await refreshData()}
            />
        </OutContainer>
    );
};

export default ScheduleItemView;