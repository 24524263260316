import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import OrderStatusCode from "../../enums/OrderStatusCode";
import DataParseService from "../../services/DataParseService";
import RenderOnRole from "../../auth/RenderOnRole";
import {Container, Row, Form, Tooltip} from "react-bootstrap";
import Table from "../common/table/Table";
import PaginationHandler from "../common/pagination/PaginationHandler";
import { ReactComponent as MagnifierIcon } from '../../icons/ic_search.svg';
import {useNavigate} from "react-router-dom";
import { BsCartPlus } from "react-icons/bs";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import Switch from "react-switch";
import styled from "styled-components";
import OrderTable from "./table/OrderTable";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const Payed = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #24a0ed;
  background-color: #f3ffff;
  color: #24a0ed;
  padding: 3px 0px;
  width: 70%;
  font-size: 13px;
`;

const NotPayed = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #ff3333;
  background-color: #ffe6e6;
  color: #ff3333;
  padding: 3px 0px;
  width: 70%;
  font-size: 13px;
`;

const Empty = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid transparent;
  background: none;
  padding: 3px 0px;
  width: 70%;
  font-size: 13px;
`;

const OrderManagement = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    /* order list */
    const [orderList, setOrderList] = useState([]);
    /* pagination variables */
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);
    /* payment filter */
    /* ALL, BEFORE, AFTER */
    const [paymentFilterValue, setPaymentFilterValue] = useState("AFTER");
    /* search variables */
    const [searchField, setSearchField] = useState('userId');
    const [searchLike, setSearchLike] = useState('');
    const [searchType, setSearchType] = useState('PREPARE')

    /* fetch order list */
    const fetchOrderList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/order?size=9&page=${currentPageIndex}&order_type=${searchType}`;
            if (searchLike !== '') {
                fetchUrl = fetchUrl + `&field=${searchField}&like=${searchLike}`;
            }
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                let dataList = [];
                for (let i=0; i<8; i++) {
                    dataList.push({
                        "id" : null
                    });
                }
                setOrderList(dataList);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            } else {
                let dataList = [...res.data.item.orderList];
                setOrderList(renameOrderIdToIdAndFillEmptyAndStatusCodeRevision(dataList));
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* utils */
    const renameOrderIdToIdAndFillEmptyAndStatusCodeRevision = (objectList) => {
        let newObjList = [...objectList];
        const iter = 9 - objectList.length;
        if (newObjList.length < 9) {
            for (let i=0; i < iter; i++) {
                newObjList.push({
                    "orderId" : null
                });
            }
        }
        return newObjList.map(obj => {
            if ('orderId' in obj) {
                obj['id'] = obj['orderId'];
                delete obj['orderId'];
            }
            if ('orderStatusCode' in obj) {
                obj['orderStatusCode'] = t("language.enums.orderStatusCode." + obj['orderStatusCode']);
            }
            if ('orderDate' in obj) {
                obj['orderDate'] = DataParseService.dateTimeFormat(obj['orderDate']);
            }
            if ('isPaymentCompleted' in obj) {
                obj['isPaymentCompleted'] = obj['isPaymentCompleted'] ? "payed" : "not payed";
            }
            return obj;
        });
    }

    /* handlers */
    const handleSearchFieldChange = (value) => {
        setSearchField(value);
    };

    const handleSearchLikeChange = (value) => {
        setSearchLike(value);
    };

    const handleSearchTypeChange = (value) => {
        setSearchType(value);
    };

    const handlePaymentFilterValueChange = (value) => {
        if (paymentFilterValue === value) {
            return;
        } else {
            setPaymentFilterValue(value);
        }
    };

    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    };

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    };

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    };

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    };

    const enterEventHandler = (e) => {
        if (e.key === 'Enter') {
            fetchOrderList();
        }
    };

    /* column data */
    const columnData = [
        {
            accessor: 'orderDate',
            Header: t('common.orderDate'),
        },
        {
            accessor: 'orderGroupName',
            Header: t('common.group'),
        },
        {
            accessor: 'orderBranchName',
            Header: t('common.branch'),
        },
        {
            accessor: 'orderUserName',
            Header: t('common.customerName'),
        },
        {
            accessor: 'orderUserId',
            Header: t('common.search.fields.customerId'),
        },
        {
            accessor: 'isPaymentCompleted',
            Header: t('common.payment'),
            Cell: ({ cell: { value } }) => <IsPayed value={value} />
        },
        {
            accessor: 'orderStatusCode',
            Header: t('common.status'),
        }
    ];

    /* inner-table component */
    const IsPayed = (value) => {
        if (value.value === "payed") {
            return (
                <Payed>
                    {t("menu.order.paymentStatus.after")}
                </Payed>
            );
        } else if (value.value === "not payed") {
            return (
                <NotPayed>
                    {t("menu.order.paymentStatus.before")}
                </NotPayed>
            );
        } else {
            return (
                <Empty>
                    {"　"}
                </Empty>
            );
        }
    };

    /* effects */
    useEffect(() => {
        fetchOrderList();
    }, [currentPageIndex, searchType]);

    useEffect(() => {
        if (paymentFilterValue === "ALL") {
            setSearchType("ALL");
        } else if (paymentFilterValue === "AFTER") {
            setSearchType("PREPARE");
        } else { // BEFORE
            setSearchType("TAKEN");
        }
    }, [paymentFilterValue]);

    /* tooltips */
    const renderToolTip = (name) => {
        return (
            <Tooltip id="tooltip">
                {name}
            </Tooltip>
        );
    };

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.ORDER_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.order.title")]}
                    depth={1}
                />
            </div>
            {/* main */}
            <div className={"contentContainer"}>
                <Container fluid>
                    {/* main area */}
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                height: '800px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {/* search bar */}
                            <div className={"contentInner"}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        display: 'flex'
                                    }}
                                >
                                    {/* order status filter */}
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginRight: '10px',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#757575'
                                                    }}
                                                >
                                                    {t("menu.order.paymentStatus.all")}
                                                </span>
                                            </div>
                                            <div>
                                                <input
                                                    type={"checkbox"}
                                                    checked={paymentFilterValue === "ALL"}
                                                    onChange={() => handlePaymentFilterValueChange("ALL")}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginRight: '10px',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#757575'
                                                    }}
                                                >
                                                    {t("menu.order.paymentStatus.before")}
                                                </span>
                                            </div>
                                            <div>
                                                <input
                                                    type={"checkbox"}
                                                    checked={paymentFilterValue === "BEFORE"}
                                                    onChange={() => handlePaymentFilterValueChange("BEFORE")}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginRight: '15px',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#757575'
                                                    }}
                                                >
                                                    {t("menu.order.paymentStatus.after")}
                                                </span>
                                            </div>
                                            <div>
                                                <input
                                                    type={"checkbox"}
                                                    checked={paymentFilterValue === "AFTER"}
                                                    onChange={() => handlePaymentFilterValueChange("AFTER")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: '200px',
                                            height: '50px'
                                        }}
                                    >
                                        <select
                                            id={"search-field-select-box"}
                                            value={searchType}
                                            onChange={(e) => handleSearchTypeChange(e.target.value)}
                                        >
                                            {
                                                (paymentFilterValue === "ALL") &&
                                                <option value={"ALL"}>{t("menu.order.types.all")}</option>
                                            }
                                            {
                                                ((paymentFilterValue === "ALL") || (paymentFilterValue === "BEFORE")) &&
                                                <option value={"TAKEN"}>{t("menu.order.types.taken")}</option>
                                            }
                                            {
                                                ((paymentFilterValue === "ALL") || (paymentFilterValue === "AFTER")) &&
                                                <>
                                                    <option value={"PREPARE"}>{t("menu.order.types.prepare")}</option>
                                                    <option value={"ON_SHIP"}>{t("menu.order.types.onShip")}</option>
                                                    <option value={"COMPLETE"}>{t("menu.order.types.complete")}</option>
                                                    <option value={"CANCEL"}>{t("menu.order.types.cancel")}</option>
                                                </>
                                            }
                                        </select>
                                    </div>
                                    <div
                                        style={{
                                            width: '150px',
                                            height: '50px',
                                            marginLeft: '10px'
                                        }}
                                    >
                                        <select
                                            id={"search-field-select-box"}
                                            defaultValue={"all"}
                                            onChange={(e) => handleSearchFieldChange(e.target.value)}
                                        >
                                            <option value={"userId"}>{t("common.search.fields.customerId")}</option>
                                        </select>
                                    </div>
                                    <div
                                        style={{
                                            width: '270px',
                                            height: '50px',
                                            marginLeft: '10px'
                                        }}
                                    >
                                        <input
                                            id={"search-like-input"}
                                            type={"text"}
                                            placeholder={t("common.search.placeholder")}
                                            value={searchLike}
                                            onChange={(e) => handleSearchLikeChange(e.target.value)}
                                            onKeyDown={(e) => enterEventHandler(e)}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            marginLeft: '10px'
                                        }}
                                    >
                                        <button
                                            id={"box-btn"}
                                            onClick={() => fetchOrderList()}
                                        >
                                            <MagnifierIcon />
                                        </button>
                                    </div>
                                    <RenderOnRole roleName={ImsSystemRole.ORDER_MANAGEMENT} type={"POST"} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        <div
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                marginLeft: 'auto'
                                            }}
                                        >
                                            <OverlayTrigger placement={"bottom"} overlay={renderToolTip(t("menu.order.add.title"))}>
                                                <button
                                                    id={"box-btn"}
                                                    onClick={() => navigate('/order/add')}
                                                >
                                                    <BsCartPlus color={'white'} size={"30"} />
                                                </button>
                                            </OverlayTrigger>
                                        </div>
                                    </RenderOnRole>
                                </div>
                            </div>
                            {/* table */}
                            <div
                                className={"contentInner"}
                                style={{
                                    marginTop: '10px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '600px',
                                        marginTop: '15px'
                                    }}
                                >
                                    <OrderTable columns={columnData} data={orderList} baseUrl={'detail'} />
                                </div>
                                {/* pagination */}
                                <div
                                    style={{
                                        width: '400px',
                                        height: '50px',
                                        marginTop: '20px',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    {/*goBefore, goAfter, goFirst, goLast, pageSize, currentIndex, maxIndex, totalItemNumber*/}
                                    <PaginationHandler
                                        goBefore={handleGoBefore}
                                        goAfter={handleGoAfter}
                                        goFirst={handleGoFirst}
                                        goLast={handleGoLast}
                                        pageSize={9}
                                        currentIndex={currentPageIndex}
                                        maxIndex={maxPageIndex}
                                        totalItemNumber={totalElements}
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default OrderManagement;