import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import axios from "axios";
import UserService from "../../../../../services/UserService";
import DataCompareService from "../../../../../services/DataCompareService";
import _ from "lodash";
import DataParseService from "../../../../../services/DataParseService";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import TitleAndSelectBox from "../../../../common/dataView/TitleAndSelectBox";
import TitleAndInputBox from "../../../../common/dataView/TitleAndInputBox";
import BoothService from "../../axiosServices/BoothService";
import {toast} from "react-toastify";
import ToastAlertView from "../../../../common/alert/ToastAlertView";
import ModalCloseButton from "../../../../common/modal/ModalCloseButton";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '440px',
        margin: 'auto'
    }
};

const SaveButton = styled.button`
  width: 100%;
  padding: 5px;
  background: none;
  border: 1px solid #fc7242;
  border-radius: 5px;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #fbe1d5;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  width: 100%;
  padding: 5px;
  background: none;
  border: 1px solid #757575;
  border-radius: 5px;
  color: #757575;

  &:focus {
    outline: none;
  }
`;

const requestBodyInit = {
    purchaseDate : "",
    boothUserId : "",
    boothWarrantyId : "noneId"
};

const isValidMapInit = {
    purchaseDate : true,
    boothUserId : true,
    boothWarrantyId : true
};

const BoothSalesEditModal = ({ isOpen, setIsOpen, onRefresh, groupId, boothId, prevInfo }) => {

    const { t, i18n } = useTranslation();

    /* customerList : dependent on groupId */
    const [customerList, setCustomerList] = useState([]);
    /* warrantyList : dependent on groupId */
    const [warrantyList, setWarrantyList] = useState([]);

    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);

    /* isValid */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);

    /* isEdited */
    const [isEdited, setIsEdited] = useState(false);

    /* fetch customerList */
    const fetchCustomerList = async () => {
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user`
            + `/type/master`
            + `/group/${groupId}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        if (res.data['error_code'] === "GET_EMPTY_DATA") {
            setCustomerList([]);
        } else {
            handleCustomerIdChange(res.data.item.userList[0]['userId']);
            setCustomerList(res.data.item.userList);
        }
    };

    /* fetch warrantyList */
    const fetchWarrantyList = async () => {
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/warranty`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        if (res.data['error_code'] === "GET_EMPTY_DATA") {
            let newWarrantyList = [];
            newWarrantyList.unshift({
                "warrantyId" : "noneId",
                "warrantyName" : "None",
                "warrantyName1" : t("common.none"),
                "warrantyName2" : t("common.none"),
                "warrantyName3" : t("common.none"),
                "warrantyName4" : t("common.none"),
                "warrantyName5" : t("common.none")
            });
            setWarrantyList(newWarrantyList);
        } else {
            let newWarrantyList = res.data.item.warrantyList.filter(warranty => {
                return (warranty['warrantyGroupId'] === groupId);
            });
            newWarrantyList.unshift({
                "warrantyId" : "noneId",
                "warrantyName" : "None",
                "warrantyName1" : t("common.none"),
                "warrantyName2" : t("common.none"),
                "warrantyName3" : t("common.none"),
                "warrantyName4" : t("common.none"),
                "warrantyName5" : t("common.none")
            });
            setWarrantyList(newWarrantyList);
        }
    };

    const putBoothSalesInfo = () => {
        let newRequestBody = _.cloneDeep(requestBody);
        if (newRequestBody['boothWarrantyId'] === "noneId") {
            delete newRequestBody['boothWarrantyId'];
        }
        const onSuccess = () => {
            toast.success(<ToastAlertView message={t("message.saved")} />);
            onRefresh();
            setIsOpen(false);
        };
        const onFailure = (e) => {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        };
        BoothService.putBoothSalesInfo(boothId, newRequestBody, onSuccess, onFailure);
    }

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* change handler */
    const handleCustomerIdChange = (value) => {
        updateRequestBody("boothUserId", value);
        if (value === "" | value == null) {
            updateIsValidMap("boothUserId", false);
        } else {
            updateIsValidMap("boothUserId", true);
        }
    };

    const handleWarrantyIdChange = (value) => {
        updateRequestBody("boothWarrantyId", value);
    };

    const handlePurchaseDateInput = (value) => {
        updateRequestBody("purchaseDate", value);
        if (value === "" | value == null) {
            updateIsValidMap("purchaseDate", false);
        } else {
            updateIsValidMap("purchaseDate", true);
        }
    };

    /* effects */
    useEffect(() => {
        if (groupId != null && !_.isEqual(prevInfo, {}) && prevInfo['isSold']) {
            fetchCustomerList();
            fetchWarrantyList();
            let newRequestBody = {};
            newRequestBody['boothUserId'] = prevInfo['boothUserId'];
            newRequestBody['purchaseDate'] = DataParseService.dateTimeToDate(prevInfo['purchaseDate']);
            if (prevInfo['boothWarrantyInfo'] == null) {
                newRequestBody['boothWarrantyId'] = "noneId";
            } else {
                newRequestBody['boothWarrantyId'] = prevInfo['boothWarrantyInfo']['warrantyId'];
            }
            setRequestBody(newRequestBody);
            setFixedRequestBody(_.cloneDeep(newRequestBody));
            setIsValidMap(isValidMapInit);
        }
    }, [isOpen, prevInfo]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.booth.detail.sales.edit.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                {/* booth owner user */}
                <Row>
                    <TitleAndSelectBox
                        title={t("menu.booth.detail.sales.boothUser")}
                        innerValue={requestBody["boothUserId"]}
                        valueList={customerList}
                        valueSelector={"userId"}
                        viewSelector={"userName"}
                        onChange={handleCustomerIdChange}
                    />
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'right'
                        }}
                    >
                        <span
                            style={{
                                fontSize: '13px',
                                color: '#757575'
                            }}
                        >
                            {t("menu.booth.detail.sales.edit.message")}
                        </span>
                    </div>
                </Row>
                {/* purchase date */}
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndInputBox
                        title={t("menu.booth.detail.sales.purchaseDate")}
                        value={requestBody["purchaseDate"]}
                        onChange={handlePurchaseDateInput}
                        isForEdit={true}
                        type={"date"}
                        isValid={isValidMap["purchaseDate"]}
                        isEnabled={true}
                    />
                </Row>
                {/* booth warranty */}
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndSelectBox
                        title={t("menu.booth.detail.sales.boothWarrantyInfo.detail.title")}
                        innerValue={requestBody["boothWarrantyId"]}
                        valueList={warrantyList}
                        valueSelector={"warrantyId"}
                        viewSelector={t("language.dataKey.warranty.name")}
                        onChange={handleWarrantyIdChange}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '40px'
                    }}
                >
                    {
                        isValid && isEdited ?
                            <SaveButton
                                onClick={() => putBoothSalesInfo()}
                            >
                                {t("button.save")}
                            </SaveButton>
                            :
                            <SaveButtonDisabled>
                                {t("button.save")}
                            </SaveButtonDisabled>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default BoothSalesEditModal;