import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import uuid from "react-uuid";
import SchemaParser from "../../../../../booth/detail/utils/SchemaParser";

const Title = styled.span`
  font-size: 14px;
  color: #8f8f8f;
  padding-left: 5px;
`;

const CategoryContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const Category = styled.div`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
`;

const CategoryButton = styled.button`
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid #e4e7ea;
  color: #212529;
  background-color: white;
  font-size: 13px;
  
  &:focus {
    outline: none;
  }
`;

const CategoryButtonSelected = styled.button`
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid #fc7242;
  color: white;
  background-color: #fc7242;
  font-size: 13px;
  
  &:focus {
    outline: none;
  }
`;

const CategoricalStringFieldView = ({ title,
                                        data,
                                        validator,
                                        updateData,
                                        updateIsValidMap,
                                        isValid,
                                        isForEdit,
                                        fieldKey }) => {

    let pattern = validator['pattern'];

    /* category list */
    const [categoryList, setCategoryList] = useState([]);

    const handleValueChange = (newValue) => {
        if (!isForEdit) {
            return;
        } else {
            updateData(newValue);
            updateIsValidMap(true);
        }
    };

    const renderCategories = () => {
        let categoryElements = [];
        categoryList.map(category => {
            if (category === data) {
                let color = "#fc7242";
                /* ONLY for status render */
                if (category === "Active") {
                    color = "#28AEFF";
                } else if (category === "Pending") {
                    color = "#FFC314";
                } else if (category === "Failed") {
                    color = "#FF3232";
                } else if (category === "Unknown") {
                    color = "#C45FDD";
                }
                categoryElements.push(
                    <Category
                        key={fieldKey + category}
                    >
                        <CategoryButtonSelected
                            style={{
                                backgroundColor : color,
                                borderColor : color
                            }}
                        >
                            {category}
                        </CategoryButtonSelected>
                    </Category>
                );
            } else {
                categoryElements.push(
                    <Category
                        key={fieldKey + category}
                    >
                        <CategoryButton
                            onClick={() => handleValueChange(category)}
                        >
                            {category}
                        </CategoryButton>
                    </Category>
                );
            }
        });
        return categoryElements;
    };

    /* effects */
    /* initialization */
    useEffect(() => {
        setCategoryList(SchemaParser.extractListFromPattern(pattern));
    }, [validator, data]);

    /* enabled case only */
    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* title */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '5px',
                    textAlign: 'left'
                }}
            >
                <Title>
                    {title}
                </Title>
            </div>
            {/* categories */}
            <div
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                <CategoryContainer>
                    {renderCategories()}
                </CategoryContainer>
            </div>
        </div>
    );
};

export default CategoricalStringFieldView;