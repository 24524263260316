import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import styled from "styled-components";
import BoothMenuDiffAxiosService from "./common/service/BoothMenuDiffAxiosService";
import BoothMenuAccordionView from "./layout/BoothMenuAccordionView";
import _ from "lodash";
import MessageView from "./common/component/MessageView";
import BoothMenuDiffUtils from "./common/service/BoothMenuDiffUtils";

const TitleContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 17px;
`;

const BoothMenuDiffManagement = () => {

    const { t } = useTranslation();
    const { boothId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    /* states */
    const [boothMenuList, setBoothMenuList] = useState([]);
    const [selectedMenuId, setSelectedMenuId] = useState("");
    const [boothInfo, setBoothInfo] = useState({});

    /* loading */
    const [isLoading, setIsLoading] = useState(false);

    /* data fetch */
    const fetchBoothMenuList = async (boothVersionId) => {
        await setIsLoading(true);
        const onSuccessCallback = async (data) => {
            let newMenuList = data["boothMenuList"];

            /* filter out menus that the booth cannot produce,    */
            /* which means that the detail entity does not exist. */
            let newMenuListAfterFilter = BoothMenuDiffUtils.filterOutDetailNotExistingMenus(newMenuList, boothVersionId);

            /* sorting : disabled things go to the last */
            let disabledMenuItems = [];
            let enabledMenuItems = [];
            newMenuListAfterFilter.map(menuInfoObj => {
                let targetMenuDetail = BoothMenuDiffUtils.extractTargetDetailEntityByBoothVersionBeforeMerge(menuInfoObj['menuDetailListBeforeMergeDiff'], boothVersionId);
                let targetDiffEntity = BoothMenuDiffUtils.extractTargetDetailDiffEntityByBoothId(menuInfoObj['menuDetailDiffList'], boothId);
                /* isEnabled */
                let isEnabled = targetDiffEntity == null ?
                    targetMenuDetail['isEnabled']
                    :
                    (targetDiffEntity['isEnabled'] ? targetDiffEntity['isEnabled'] : targetMenuDetail['isEnabled']);
                if (isEnabled) {
                    enabledMenuItems.push(menuInfoObj);
                } else {
                    disabledMenuItems.push(menuInfoObj);
                }
            });
            let newBoothMenuList = enabledMenuItems.concat(disabledMenuItems);
            await setBoothMenuList(newBoothMenuList);

            /* default */
            if ((selectedMenuId === "")
                && (location.pathname.split("/").length < 6)
                && (newBoothMenuList.length > 0)) {
                await setSelectedMenuId(newBoothMenuList[0]['menuInfo']['franchiseMenuId']);
                navigate(`menu/${newBoothMenuList[0]['menuInfo']['franchiseMenuId']}`);
            }
            await setIsLoading(false);
        };
        const onFailureCallback = async (err) => {
            await setBoothInfo({});
            await setSelectedMenuId("");
            await setBoothMenuList([]);
            console.log(err);
            await setIsLoading(false);
        };
        await BoothMenuDiffAxiosService.fetchBoothMenuList(boothId, onSuccessCallback, onFailureCallback);
    };

    const fetchBoothInfo = async (callback) => {
        await setIsLoading(true);
        const onSuccessCallback = async (data) => {
            await setBoothInfo(data['boothDetail']);
            await callback(data['boothDetail']['boothVersionInfo']['boothVersionId']);
        };
        const onFailureCallback = async (err) => {
            console.log(err);
            await setBoothInfo({});
            await setIsLoading(false);
        };
        await BoothMenuDiffAxiosService.fetchBoothInfo(boothId, onSuccessCallback, onFailureCallback);
    };

    /* effects */
    useEffect(() => {
        fetchBoothInfo(fetchBoothMenuList);
    }, [boothId]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    {/* title */}
                    <TitleContainer>
                        <Title>
                            {t("menu.boothMenuDiff.title")}
                        </Title>
                    </TitleContainer>

                    {/* main area */}
                    <div
                        style={{
                            marginTop: '20px',
                            width: '100%',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        {
                            (!isLoading) && (_.isEqual(boothInfo, {})) ?
                                <MessageView
                                    message={t("menu.boothMenuDiff.messages.failedToFetchBoothInfo")}
                                />
                                :
                                (isLoading) ?
                                    <Spinner size={"sm"} />
                                    :
                                    <>
                                        {/* accordion */}
                                        <div
                                            style={{
                                                width: '17%',
                                                height: '705px'
                                            }}
                                        >
                                            <BoothMenuAccordionView
                                                boothMenuList={boothMenuList}
                                                selectedMenuId={selectedMenuId}
                                                isLoading={isLoading}
                                                boothVersionId={boothInfo['boothVersionInfo']['boothVersionId']}
                                            />
                                        </div>
                                        {/* outlet area */}
                                        <div
                                            style={{
                                                width: '83%',
                                                height: '100%'
                                            }}
                                        >
                                            <Outlet
                                                context={{
                                                    fetchBoothMenuList,
                                                    boothInfo
                                                }}
                                            />
                                        </div>
                                    </>
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default BoothMenuDiffManagement;