import React from 'react';
import styled from "styled-components";
import {Image} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ProductStatusCode from "../../../enums/ProductStatusCode";
import DataParseService from "../../../services/DataParseService";
import defaultBoxImage from "../../../icons/productDefaultPicture.png";

const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(194,194,194,1);
  -moz-box-shadow: 0px 0px 5px 2px rgba(194,194,194,1);
  box-shadow: 0px 0px 5px 2px rgba(194,194,194,1);
  
  &:hover {
    -webkit-box-shadow: 0px 0px 5px 7px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 5px 7px rgba(194,194,194,1);
    box-shadow: 0px 0px 5px 7px rgba(194,194,194,1);
  }
`;

const ProductView = ({ productInfo, onClick }) => {

    const { t, i18n } = useTranslation();

    const setStatusColor = () => {
        if (productInfo.productStatusCode === 0) {
            return "#656565";
        } else if (productInfo.productStatusCode === 1) {
            return "blue";
        } else {
            return "red";
        }
    }

    const getStatusStr = (code) => {
        if (code === 0) {
            return t("menu.product.status.inactive");
        } if (code === 1) {
            return t("menu.product.status.active");
        } else {
            return t("menu.product.status.out_of_order");
        }
    };

    const statusRender = () => {
        return (
            <span
                style={{
                    color: setStatusColor(),
                    fontSize: '14px'
                }}
            >
                {getStatusStr(productInfo.productStatusCode)}
            </span>
        );
    }

    const priceRender = () => {
        return (
          <span
            style={{
                color: '#fc7242',
                fontWeight: 'bold',
                fontSize: '14px'
            }}
          >
            {
                productInfo.productPriceUnit + " " + DataParseService.formatNumberWithCommas(productInfo.productPrice)
            }
          </span>
        );
    }

    if (typeof productInfo === "undefined") {
        return (<></>);
    }

    return (
        <div
            style={{
                width: '20%',
                height : '100%',
                padding : '7px'
            }}
            onClick={onClick}
        >
            <Card>
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '15px'
                    }}
                >
                    <Image
                        src={
                            (productInfo.productImageUrl == null) ?
                                defaultBoxImage
                                :
                                `${productInfo.productImageUrl}`
                        }
                        alt="Profile Image"
                        style={{ width: '150px', height: '150px', display: 'inline-block', border: '0'}}
                    />
                </div>
                <div
                    style={{
                        marginTop: '5px',
                        textAlign: 'center'
                    }}
                >
                    <span
                        style={{
                            fontWeight: 'bold',
                            color: '#757575',
                            fontSize: '14px'
                        }}
                    >
                        {productInfo[t("language.dataKey.product.name")]}
                    </span>
                </div>
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {statusRender()}
                </div>
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {priceRender()}
                </div>
            </Card>
        </div>
    );
};

export default ProductView;