import React from 'react';

const TextCell = ({ value }) => {
    return (
        <span
            style={{
                fontSize: '14px',
                color: '#757575'
            }}
        >
            {value}
        </span>
    );
};

export default TextCell;