import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import DataParseService from "../../services/DataParseService";
import axios from "axios";
import UserService from "../../services/UserService";
import RenderOnRole from "../../auth/RenderOnRole";
import {Container, Row, Tooltip} from "react-bootstrap";
import Table from "../common/table/Table";
import PaginationHandler from "../common/pagination/PaginationHandler";
import { ReactComponent as MagnifierIcon } from '../../icons/ic_search.svg';
import { ReactComponent as BoothAddIcon } from '../../icons/boothAdd.svg';
import AuthService from "../../services/AuthService";
import { FaListOl } from "react-icons/fa";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import BackwardButton from "../common/layoutSub/BackwardButton";
import BoothTable from "./common/BoothTable";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import styled from "styled-components";
import SelectSearch from "react-select-search";
import "../common/style/SelectSearchStyle.css";

const FranchiseExist = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #fc7242;
  background-color: white;
  color: #fc7242;
  padding: 5px 15px;
  font-size: 13px;
`;

const FranchiseNotExist = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #c2c2c2;
  background-color: white;
  color: #c2c2c2;
  padding: 5px 15px;
  font-size: 13px;
`;

const BranchExist = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #757575;
  background-color: white;
  color: #757575;
  padding: 5px 15px;
  font-size: 13px;
`;

const BranchNotExist = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #c2c2c2;
  background-color: white;
  color: #c2c2c2;
  padding: 5px 15px;
  font-size: 13px;
`;

const SoldView = styled.span`
  color: blue;
`;

const NotSoldView = styled.span`
  color: red;
`;

const Empty = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid transparent;
  background: none;
  padding: 5px 5px;
  font-size: 13px;
`;

const BoothManagement = () => {

    const { t } = useTranslation();

    /* boothList */
    const [boothList, setBoothList] = useState([]);
    /* pagination */
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);
    /* search */
    const [searchField, setSearchField] = useState('all');
    const [searchLike, setSearchLike] = useState('');
    const [boothStatus, setBoothStatus] = useState('all');

    const navigate = useNavigate();

    /** New: franchise */
    const [franchiseList, setFranchiseList] = useState([]);
    const [selectedFranchiseId, setSelectedFranchiseId] = useState("all"); /* all, none, {franchiseId} */

    /* data re-formatting */
    const renameBoothIdToId = (objectList) => {
        let newObjList = [...objectList];
        const iter = 9 - objectList.length;
        if (newObjList.length < 9) {
            for (let i=0; i < iter; i++) {
                newObjList.push({
                    "boothId" : null
                });
            }
        }
        return newObjList.map(obj => {
            if ('boothId' in obj) {
                obj['id'] = obj['boothId'];
                delete obj['boothId'];
            }
            if (obj['id'] != null) {
                if ('manufactureDate' in obj) {
                    obj['manufactureDate'] = DataParseService.dateTimeToDate(obj['manufactureDate']);
                }
                if ('sold' in obj) {
                    if (obj['sold']) {
                        obj['isSoldMessage'] = t("common.sold");
                    } else {
                        obj['isSoldMessage'] = t("common.notSold");
                    }
                }
                if (obj['franchiseInformation'] == null) {
                    obj['franchiseName'] = "none";
                } else {
                    obj['franchiseName'] = obj['franchiseInformation']['franchiseName'];
                }
                if (obj['boothBranchName'] == null) {
                    obj['boothBranchName'] = "none";
                } else {
                    obj['boothBranchName'] = obj['boothBranchName'];
                }
            }
            return obj;
        });
    }

    /* data fetch */
    const fetchBoothList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/booth`
                + `?size=9&page=${currentPageIndex}&booth_status=${boothStatus}&fr_id=${selectedFranchiseId}`;
            if (searchLike !== '') {
                fetchUrl = fetchUrl + `&field=${searchField}&like=${searchLike}`;
            }
            const axiosCall = () => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                let dataList = [];
                for (let i=0; i<8; i++) {
                    dataList.push({
                        "id" : null
                    });
                }
                setBoothList(dataList);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            } else {
                let dataList = [...res.data.item.boothList];
                setBoothList(renameBoothIdToId(dataList));
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            }
        } catch (err) {
            console.log(err);
        }
    };

    /* fetch franchise list */
    const fetchFranchiseList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`;
            const axiosCall = () => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            let newList = [];
            const dataList = res.data.item.franchiseList;
            dataList.map(groupAndFranchiseObj => {
                let tempGroupId = Object.keys(groupAndFranchiseObj)[0];
                let franchiseList = groupAndFranchiseObj[tempGroupId];
                for (let i=0 ; i<franchiseList.length ; i++) {
                    newList.push(franchiseList[i]);
                }
            });
            await setFranchiseList(await generateSelectSearchOption(newList));
        } catch (err) {
            await setFranchiseList([]);
            console.log(err);
        }
    };

    /* table column data */
    const columnData = [
        {
            accessor: 'franchiseName',
            Header: t('menu.branch.franchise.title'),
            Cell: ({ cell: { value } }) => <FranchiseNameView value={value} />
        },
        {
            accessor: "boothBranchName",
            Header: t('menu.booth.branch.title'),
            Cell: ({ cell: { value } }) => <BranchNameView value={value} />
        },
        {
            accessor: 'manufactureDate',
            Header: t('menu.booth.table.manufactureDate'),
        },
        {
            accessor: 'boothName',
            Header: t('menu.booth.table.boothName'),
        },
        {
            accessor: 'boothVersion',
            Header: t('menu.booth.table.boothVersion'),
        },
        {
            accessor: 'serialNumber',
            Header: t('menu.booth.table.serialNumber'),
        },
        {
            accessor: 'isSoldMessage',
            Header: t('menu.booth.table.isSoldMessage'),
            Cell: ({ cell: { value } }) => <IsSoldView value={value} />
        }
    ];

    /* handlers */
    const handleSearchFieldChange = (value) => {
        setSearchField(value);
    };

    const handleSearchLikeChange = (value) => {
        setSearchLike(value);
    };

    const handleBoothStatusChange = (value) => {
        setBoothStatus(value);
    };

    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    };

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    };

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    };

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    };

    const enterEventHandler = (e) => {
        if (e.key === 'Enter') {
            fetchBoothList();
        }
    };

    /* effects */
    useEffect(() => {
        fetchBoothList();
    }, [currentPageIndex, boothStatus, selectedFranchiseId]);

    useEffect(() => {
        fetchFranchiseList();
    }, []);

    /* tooltips */
    const renderToolTip = (name) => {
        return (
            <Tooltip id="tooltip">
                {name}
            </Tooltip>
        );
    };

    /* select search option generator */
    const generateSelectSearchOption = async (givenFranchiseList) => {
        let newOptions = [];
        {/* franchise list */}
        givenFranchiseList.map((franchiseInfo) => {
            newOptions.push({
                "name" : franchiseInfo['franchiseName'],
                "value" : franchiseInfo['franchiseId']
            });
        });
        newOptions.unshift({
            "name" : t("menu.branch.franchise.search.none"),
            "value" : "none"
        });
        newOptions.unshift({
            "name" : t("menu.branch.franchise.search.all"),
            "value" : "all"
        });
        return newOptions;
    };

    const FranchiseNameView = (value) => {
        if (value.value == null) {
            return (
                <Empty>
                    {"　"}
                </Empty>
            );
        } else if (value.value == "none") {
            return (
                <FranchiseNotExist>
                    {t("menu.branch.franchise.search.none")}
                </FranchiseNotExist>
            );
        } else {
            return (
                <FranchiseExist>
                    {value.value}
                </FranchiseExist>
            );
        }
    };

    const BranchNameView = (value) => {
        if (value.value == null) {
            return (
                <Empty>
                    {"　"}
                </Empty>
            );
        } else if (value.value === "none") {
            return (
                <BranchNotExist>
                    {t("menu.booth.branch.none")}
                </BranchNotExist>
            );
        } else {
            return (
                <BranchExist>
                    {value.value}
                </BranchExist>
            );
        }
    };

    const IsSoldView = (value) => {
        if (value.value == null) {
            return (
                <Empty>
                    {"　"}
                </Empty>
            );
        } else if (value.value === t("common.sold")) {
            return (
                <SoldView>
                    {t("common.sold")}
                </SoldView>
            );
        } else {
            return (
                <NotSoldView>
                    {value.value}
                </NotSoldView>
            );
        }
    };

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.booth.title")]}
                    depth={1}
                />
            </div>

            {/* search & buttons */}
            <div className={"contentContainer"}>
                <Container fluid>
                    <Row>
                        <div className={"contentInner"}>
                            {/* search bar */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    display: 'flex'
                                }}
                            >
                                {/** New: franchise selection */}
                                <div
                                    style={{
                                        width: '270px',
                                        height: '50px'
                                    }}
                                >
                                    {
                                        franchiseList &&
                                        <SelectSearch
                                            options={franchiseList}
                                            value={selectedFranchiseId}
                                            name={"franchise-select"}
                                            placeholder={t("menu.franchise.selectFranchise")}
                                            search={true}
                                            autoComplete={true}
                                            onChange={async (value) => {
                                                await setSelectedFranchiseId(value);
                                            }}
                                        />
                                    }
                                </div>
                                <div
                                    style={{
                                        width: '150px',
                                        height: '50px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <select
                                        id={"search-field-select-box"}
                                        defaultValue={"all"}
                                        onChange={(e) => handleBoothStatusChange(e.target.value)}
                                    >
                                        <option value={"all"}>{t("menu.booth.detail.boothStatus.all")}</option>
                                        <option value={"release"}>{t("menu.booth.detail.boothStatus.release")}</option>
                                        <option value={"sold"}>{t("menu.booth.detail.boothStatus.sold")}</option>
                                        <option value={"installed"}>{t("menu.booth.detail.boothStatus.installed")}</option>
                                    </select>
                                </div>
                                <div
                                    style={{
                                        width: '270px',
                                        height: '50px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <select
                                        id={"search-field-select-box"}
                                        defaultValue={"all"}
                                        onChange={(e) => handleSearchFieldChange(e.target.value)}
                                    >
                                        <option value={"all"}>{t("common.search.fields.all")}</option>
                                        <option value={"serial"}>{t("common.search.fields.serial")}</option>
                                        <option value={"id"}>{t("common.search.fields.id")}</option>
                                    </select>
                                </div>
                                <div
                                    style={{
                                        width: '270px',
                                        height: '50px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <input
                                        id={"search-like-input"}
                                        type={"text"}
                                        placeholder={t("common.search.placeholder")}
                                        value={searchLike}
                                        onChange={(e) => handleSearchLikeChange(e.target.value)}
                                        onKeyDown={(e) => enterEventHandler(e)}
                                    />
                                </div>
                                <div
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <button
                                        id={"box-btn"}
                                        onClick={() => fetchBoothList()}
                                    >
                                        <MagnifierIcon />
                                    </button>
                                </div>
                                <div
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    <RenderOnRole type={"POST"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        <OverlayTrigger placement={"bottom"} overlay={renderToolTip(t("menu.booth.add.title"))}>
                                            <button
                                                id={"box-btn"}
                                                onClick={() => navigate(`/booth/add`)}
                                            >
                                                <BoothAddIcon />
                                            </button>
                                        </OverlayTrigger>
                                    </RenderOnRole>
                                </div>
                                    <RenderOnRole type={""} roleName={""} allowedRing={0} isForSuperMaster={false} nothingOnRejected={true}>
                                        <div
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                marginLeft: '10px'
                                            }}
                                        >
                                            <OverlayTrigger placement={"bottom"} overlay={renderToolTip(t("menu.booth.version.title"))}>
                                                <button
                                                    id={"box-btn"}
                                                    onClick={() => navigate(`/booth/version`)}
                                                >
                                                    <FaListOl
                                                        size={"23"}
                                                        color={"white"}
                                                    />
                                                </button>
                                            </OverlayTrigger>
                                        </div>
                                    </RenderOnRole>
                                <div
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <OverlayTrigger placement={"bottom"} overlay={renderToolTip(t("menu.booth.warranty.title"))}>
                                        <button
                                            id={"box-btn"}
                                            onClick={() => navigate(`/booth/warranty`)}
                                        >
                                            <HiOutlineClipboardDocumentList
                                                size={"27"}
                                                color={"white"}
                                            />
                                        </button>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </Row>

                    {/* main area */}
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <div className={"contentInner"}>
                        <div
                            style={{
                                width: '100%',
                                height: '700px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {/* table */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '600px',
                                    marginTop: '15px'
                                }}
                            >
                                <BoothTable columns={columnData} data={boothList} baseUrl={'detail'} />
                            </div>
                            {/* pagination */}
                            <div
                                style={{
                                    width: '400px',
                                    height: '50px',
                                    marginTop: '20px',
                                    marginLeft: 'auto'
                                }}
                            >
                                {/*goBefore, goAfter, goFirst, goLast, pageSize, currentIndex, maxIndex, totalItemNumber*/}
                                <PaginationHandler
                                    goBefore={handleGoBefore}
                                    goAfter={handleGoAfter}
                                    goFirst={handleGoFirst}
                                    goLast={handleGoLast}
                                    pageSize={9}
                                    currentIndex={currentPageIndex}
                                    maxIndex={maxPageIndex}
                                    totalItemNumber={totalElements}
                                />
                            </div>
                        </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default BoothManagement;