import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import DateUtils from "../../schedule/utils/DateUtils";
import axios from "axios";
import UserService from "../../../services/UserService";
import DataUtils from "../../menu/common/utils/DataUtils";
import CalendarView from "../../schedule/components/CalendarView";
import styled from "styled-components";
import {CiCircleChevRight} from "react-icons/ci";
import OverlayToolTip from "../../menu/common/toolTip/OverlayToolTip";
import ScheduleItemView from "../../schedule/components/item/ScheduleItemView";
import DashboardAxiosService from "../service/DashBoardAxiosService";
import useInterval from "../../../customHooks/useInterval";
import TodoItemView from "./items/TodoItemView";


const MessageView = styled.div`
  width: 100%;
  padding: 30px;
  text-align: center;
  color: #757575;
  font-size: 14px;
`;

const PlusButton = styled.button`
  position: absolute;
  right: 5px;
  top: 1%;
  background: none;
  color: #c2c2c2;
  border: none;
  transition: all ease-in-out 0.2s;
  padding: 0px;
  
  &:hover {
    color: #757575;
  }
  
  &:focus {
    outline: none;
  }
`;

const MenuTitle = styled.span`
  color: #757575;
  font-size: 14px;
  position: absolute;
  left: 10px;
  top: 2%;
`;

const Dot = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${(props) => props.color ? props.color : "black"};
  border-radius: 50%;
  display: flex;
  margin-left: 3px;
`;

const colorMap = {
    0 : "#c2c2c2", /* canceled */
    1 : "#f87171", /* planned */
    2 : "#3399ff", /* done */
};

const SchedulePart = ({ isForLarge, calHeight }) => {

    /* translation */
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    /* states */
    const [selectedDate, setSelectedDate] = useState(DateUtils.formatDate(new Date(), '-'));
    const [scheduleList, setScheduleList] = useState([]);
    const [formattedScheduleData, setFormattedScheduleData] = useState({});
    const [currentViewYear, setCurrentViewYear] = useState(DateUtils.getYearAndMonth(new Date())['year']);
    const [currentViewMonth, setCurrentViewMonth] = useState(DateUtils.getYearAndMonth(new Date())['month']);

    const [todoList, setTodoList] = useState([]);

    /* fetch todo */
    const fetchTodoList = async () => {
        const onSuccessCallback = async (item) => {
            await setTodoList(item['todoList']);
        };
        const onFailureCallback = async (err) => {
            await setTodoList([]);
        };
        await DashboardAxiosService.fetchNotDoneTodoList(onSuccessCallback, onFailureCallback);
    };

    /* fetch schedule */
    const fetchScheduleList = async (year, month) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/schedule`
                + `?year=${year}&month=${month}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            let newScheduleList = res.data.item['scheduleList'];
            newScheduleList.sort((ol, or) => {
                return DataUtils.objectSortHelper('scheduleTitle', ol, or);
            });
            await setScheduleList(newScheduleList);
        } catch (e) {
            console.log(e);
            await setScheduleList([]);
        }
    };

    /* effects */
    useEffect(() => {
        /* fetch schedule */
        fetchScheduleList(currentViewYear, currentViewMonth);
    }, [currentViewYear, currentViewMonth]);

    useEffect(() => {
        setFormattedScheduleData(DateUtils.scheduleListToObj(scheduleList));
    }, [scheduleList]);

    useEffect(() => {
        fetchTodoList();
    }, []);

    /* update */
    /* TIMER : update date */
    /* every 300 sec */
    useInterval(() => {
        fetchTodoList();
    }, 300000);

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            {/* schedule information */}
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    marginRight: '10px',
                    borderRadius: '5px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                }}
            >
                {/* calendar */}
                <CalendarView
                    calWidth={"70%"}
                    calHeight={calHeight}
                    isForSmall={!isForLarge}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    onMonthChange={async (newDate) => {
                        await setCurrentViewYear(DateUtils.getYearAndMonth(newDate)['year']);
                        await setCurrentViewMonth(DateUtils.getYearAndMonth(newDate)['month']);
                        await setSelectedDate(DateUtils.formatDate(newDate, '-'));
                    }}
                    scheduleData={formattedScheduleData}
                />

                {/* planned items */}
                <div
                    style={{
                        width: '30%',
                        height: '100%',
                        marginLeft: '10px',
                        position: 'relative',
                        paddingTop: '50px'
                    }}
                >
                    {/* title and navigation button */}
                    <MenuTitle>
                        {t("menu.dashboard.scheduleInfo.messages.schedules", {"date" : DateUtils.craftLocaleString(selectedDate, i18n.language)})}
                    </MenuTitle>

                    <OverlayToolTip
                        direction={"top"}
                        text={t("menu.dashboard.scheduleInfo.messages.moveToSchedule")}
                    >
                        <PlusButton
                            onClick={() => navigate('/schedule')}
                        >
                            <CiCircleChevRight size={"25"} />
                        </PlusButton>
                    </OverlayToolTip>

                    {/* schedule items */}
                    <div
                        style={{
                            width: '100%',
                            height: '95%',
                            overflow: 'auto',
                            padding: '5px'
                        }}
                    >
                        {
                            ((formattedScheduleData[selectedDate] == null)
                                || (formattedScheduleData[selectedDate]['countOfPlanned'] === 0)) &&
                            <MessageView>
                                {t("menu.schedule.messages.noSchedule")}
                            </MessageView>
                        }
                        {
                            (formattedScheduleData[selectedDate] != null) &&
                            (formattedScheduleData[selectedDate]['countOfPlanned'] > 0) &&
                            formattedScheduleData[selectedDate]['plannedSchedules'].map(scheduleInfo => {
                                return (
                                    <ScheduleItemView
                                        key={scheduleInfo['scheduleId']}
                                        scheduleId={scheduleInfo['scheduleId']}
                                        title={scheduleInfo['scheduleTitle']}
                                        content={scheduleInfo['scheduleContent']}
                                        status={1}
                                        refreshData={() => fetchScheduleList(currentViewYear, currentViewMonth)}
                                        date={selectedDate}
                                    />
                                );
                            })
                        }
                    </div>
                </div>
            </div>

            {/* todo information */}
            <div
                style={{
                    width: '70%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: '40px'
                }}
            >
                {/* title */}
                <MenuTitle>
                    {t("menu.dashboard.todoInfo.title")}
                </MenuTitle>

                {/* items */}
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        padding: '10px',
                        overflow: 'auto'
                    }}
                >
                    {
                        todoList &&
                        (todoList.length === 0) &&
                        <MessageView>
                            {t("menu.dashboard.todoInfo.messages.noTodo")}
                        </MessageView>
                    }
                    {/* not done */}
                    {
                        todoList &&
                        (todoList.length > 0) &&
                        todoList.map((todoInfoObj) => {
                           return (
                               <TodoItemView
                                   todoInfoObj={todoInfoObj}
                               />
                           );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default SchedulePart;