import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import BoothMenuDiffAxiosService from "../../common/service/BoothMenuDiffAxiosService";
import MessageView from "../../common/component/MessageView";
import {Spinner} from "react-bootstrap";
import OptionRecipeDiffViewInner from "./inner/OptionRecipeDiffViewInner";
import TitleAndSelectBox from "../../../../common/dataView/TitleAndSelectBox";

/** This component manages the state of selectedOption and optionList */
const OptionRecipeDiffView = ({ boothVersionId }) => {

    const { t } = useTranslation();
    const { boothId, menuId } = useParams();

    /* states */
    const [selectedOptionId, setSelectedOptionId] = useState("");
    const [optionInfoList, setOptionInfoList] = useState([]);

    /* isLoading */
    const [isLoading, setIsLoading] = useState(false);

    /* utils */
    const convertOptionInfoListToSelectList = (targetOptionInfoList) => {
        let newList = [];
        targetOptionInfoList.map(optionInfo => {
            let optionMeta = optionInfo['optionInfo'];
            let newItem = {};
            newItem["franchiseMenuOptionAlias"] = `(${optionMeta['optionCategoryInfo']["franchiseMenuOptionCategoryName"]}) ${optionMeta["franchiseMenuOptionAlias"]}`;
            newItem["franchiseMenuOptionAlias1"] = `(${optionMeta['optionCategoryInfo']["franchiseMenuOptionCategoryName1"]}) ${optionMeta["franchiseMenuOptionAlias1"]}`;
            newItem["franchiseMenuOptionAlias2"] = `(${optionMeta['optionCategoryInfo']["franchiseMenuOptionCategoryName2"]}) ${optionMeta["franchiseMenuOptionAlias2"]}`;
            newItem["franchiseMenuOptionAlias3"] = `(${optionMeta['optionCategoryInfo']["franchiseMenuOptionCategoryName3"]}) ${optionMeta["franchiseMenuOptionAlias3"]}`;
            newItem["franchiseMenuOptionAlias4"] = `(${optionMeta['optionCategoryInfo']["franchiseMenuOptionCategoryName4"]}) ${optionMeta["franchiseMenuOptionAlias4"]}`;
            newItem["franchiseMenuOptionAlias5"] = `(${optionMeta['optionCategoryInfo']["franchiseMenuOptionCategoryName5"]}) ${optionMeta["franchiseMenuOptionAlias5"]}`;
            newItem["franchiseMenuOptionId"] = optionMeta['franchiseMenuOptionId'];
            newList.push(newItem);
        });
        return newList;
    }

    /* get option list */
    const fetchOptionList = async () => {
        await setIsLoading(true);
        const onSuccessCallback = async (data) => {
            let newBoothMenuList = data['boothMenuList'];
            /* select the target menuInfo */
            let menuInfo = {};
            newBoothMenuList.map(tempMenuInfo => {
                if (tempMenuInfo['menuInfo']['franchiseMenuId'] === menuId) {
                    menuInfo = tempMenuInfo;
                }
            });
            let newOptionInfoList = menuInfo['optionList'];
            /* filter out detail not existing options */
            let newOptionInfoListAfterFilter = [];
            newOptionInfoList && newOptionInfoList.map(optionInfo => {
                let tempOptionDetailList = optionInfo['optionDetailBeforeMergeDiff'];
                tempOptionDetailList.map(optionDetailInfo => {
                    if (optionDetailInfo['franchiseMenuOptionDetailBoothVersionId'] === boothVersionId) {
                        newOptionInfoListAfterFilter.push(optionInfo);
                    }
                });
            });
            /* default */
            if (newOptionInfoListAfterFilter.length > 0) {
                await setSelectedOptionId(newOptionInfoListAfterFilter[0]['optionInfo']['franchiseMenuOptionId']);
            } else {
                await setSelectedOptionId("");
            }
            await setOptionInfoList(newOptionInfoListAfterFilter);
            await setIsLoading(false);
        };
        const onFailureCallback = async (err) => {
            console.log(err);
            await setSelectedOptionId("");
            await setOptionInfoList([]);
            await setIsLoading(false);
        };
        await BoothMenuDiffAxiosService.fetchBoothMenuList(boothId, onSuccessCallback, onFailureCallback);
    };

    /* effects */
    useEffect(() => {
        fetchOptionList();
    }, [menuId]);

    if (isLoading) {
        return (
            <div
                style={{
                    width: '100%',
                    padding: '30px 0px',
                    textAlign: 'center'
                }}
            >
                <Spinner size={"sm"} />
            </div>
        );
    } else if ((!isLoading) && (optionInfoList.length === 0)) {
        /* option detail not exist */
        return (
            <div
                style={{
                    width: '100%',
                    padding: '30px 0px'
                }}
            >
                <MessageView
                    message={t("menu.boothMenuDiff.messages.optionDetailNotFound")}
                />
            </div>
        );
    } else if ((!isLoading) && (optionInfoList.length > 0) && (selectedOptionId === "")) {
        /* option not selected */
        return (
            <div
                style={{
                    width: '100%',
                    padding: '30px 0px'
                }}
            >
                <MessageView
                    message={t("menu.boothMenuDiff.messages.optionNotSelected")}
                />
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: '100%'
                }}
            >
                {/* option select */}
                <TitleAndSelectBox
                    title={t("menu.boothMenuDiff.field.optionName")}
                    innerValue={selectedOptionId}
                    valueList={convertOptionInfoListToSelectList(optionInfoList)}
                    valueSelector={"franchiseMenuOptionId"}
                    viewSelector={t("language.dataKey.menu.option.name")}
                    onChange={(value) => setSelectedOptionId(value)}
                />
                {/* option recipe view */}
                <div
                    style={{
                        width: '100%',
                        marginTop: '10px'
                    }}
                >
                    {
                        (selectedOptionId !== "") &&
                        <OptionRecipeDiffViewInner
                            boothVersionId={boothVersionId}
                            selectedOptionId={selectedOptionId}
                        />
                    }
                </div>
            </div>
        );
    }
};

export default OptionRecipeDiffView;