import React from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import TitleAndColDiv from "../components/TitleAndColDiv";
import BoothVersionItemView from "./items/BoothVersionItemView";
import styled from "styled-components";


const MessageView = styled.div`
  text-align: center;
  padding: 40px 0px;
  color: #757575;
  font-size: 14px;
`;

const BoothNameAndVersionView = ({ selectedBoothVersionId,
                                     onSelectCallback,
                                     boothVersionList,
                                     menuRecipeList }) => {

    const { t } = useTranslation();
    const { franchiseId, menuId } = useParams();
    
    const checkIsDetailExist = (boothVersionId) => {
        let res = false;
        menuRecipeList.map(menuInfoObj => {
            if (menuInfoObj['franchiseMenuDetailBoothVersionId'] === boothVersionId) {
                res = true;
            }
        });
        return res;
    };

    return (
        <TitleAndColDiv
            title={t("menu.franchiseMenu.recipeInfo.boothVersion.title")}
            height={"700"}
            marginTop={"3"}
            marginBtw={"10"}
            toolTip={t("menu.franchiseMenu.recipeInfo.boothVersion.toolTip.whyBoothVersion")}
            toolTipDirection={"top"}
            padding={"10"}
        >
            {
                boothVersionList && (boothVersionList.length > 0) &&
                boothVersionList.map(boothVersionInfo => {
                   return (
                       <BoothVersionItemView
                           key={boothVersionInfo['boothVersionId']}
                           boothName={boothVersionInfo['boothName']}
                           boothVersion={boothVersionInfo['boothVersion']}
                           isSelected={selectedBoothVersionId === boothVersionInfo['boothVersionId']}
                           isEnabled={checkIsDetailExist(boothVersionInfo['boothVersionId'])}
                           onClickCallback={() => onSelectCallback(boothVersionInfo['boothVersionId'])}
                       />
                   );
                })
            }
            {
                boothVersionList && (boothVersionList.length === 0) &&
                <MessageView>
                    {t("menu.franchiseMenu.recipeInfo.message.noBoothVersion")}
                </MessageView>
            }
        </TitleAndColDiv>
    );
};

export default BoothNameAndVersionView;