import React from 'react';
import styled from "styled-components";
import { IoCopyOutline } from "react-icons/io5";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Slide, toast, ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const KeyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

const KeyHolder = styled.div`
  width: 90%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  color: #757575;
  font-size: 14px;
  padding: 5px 10px;
  overflow: auto;
  white-space: nowrap;
`;

const CopyButton = styled.button`
  border: none;
  background: none;
  text-align: center;
  transition: all ease-in-out 0.2s;
  color: #c2c2c2;
  
  &:hover {
    color: #757575;
  }
  
  &:focus {
    outline: none;
  }
`;

const KeyTextViewAndCopyButton = ({ apiKey }) => {

    const { t } = useTranslation();

    const AlertInner = () => {
        return (
            <div
                style={{
                    width: '100%',
                    textAlign: 'left'
                }}
            >
                <span
                    style={{
                        color: '#757575',
                        fontSize: '14px'
                    }}
                >
                    {t("menu.franchiseApiKey.detail.message.copied")}
                </span>
            </div>
        );
    };

    /* tooltips */
    const renderToolTip = (name) => {
        return (
            <Tooltip id="tooltip">
                {name}
            </Tooltip>
        );
    };

    return (
        <KeyContainer>
            {/* key text */}
            <KeyHolder>
                {apiKey}
            </KeyHolder>
            {/* copy button */}
            <OverlayTrigger placement={"top"} overlay={renderToolTip(t("menu.franchiseApiKey.detail.message.copy"))}>
                <CopyToClipboard
                    text={apiKey}
                    onCopy={() => toast(AlertInner())}
                >
                    <CopyButton>
                        <IoCopyOutline
                            size={"20"}
                        />
                    </CopyButton>
                </CopyToClipboard>
            </OverlayTrigger>
        </KeyContainer>
    );
};

export default KeyTextViewAndCopyButton;