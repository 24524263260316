import React from 'react';
import {HiOutlineDocumentSearch} from "react-icons/hi";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import BoothTable from "../../branch/booth/BoothTable";

const BoothVersion = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #24a0ed;
  background-color: #f3ffff;
  color: #24a0ed;
  padding: 5px 0px;
  width: 70%;
  font-size: 14px;
  text-align: center;
`;

const DetailButton = styled.button`
  border: none;
  background: none;
  color: #757575;
  padding: 0px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    color: #fc7242;
  }
  
  &:focus {
    outline: none;
  }
`;

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '750px',
        height: '500px',
        margin: 'auto'
    }
};

const BoothListViewModal = ({ isOpen, setIsOpen, boothList, title }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const BoothVersionCell = (value) => {
        return (
            <BoothVersion>
                {value.value}
            </BoothVersion>
        );
    };

    const BoothDetailCell = (value) => {
        return (
            <DetailButton
                onClick={() => {
                    setIsOpen(false);
                    navigate(`/booth/detail/${value.value}/info`);
                }}
            >
                <HiOutlineDocumentSearch
                    size={"30"}
                />
            </DetailButton>
        );
    };

    const renameBoothIdToId = (boothList) => {
        boothList.map(boothInfo => {
            boothInfo['id'] = boothInfo['boothId'];
            return boothInfo;
        });
        return boothList;
    };

    /* table column data */
    const columnData = [
        {
            accessor: "serialNumber",
            Header: t("menu.branch.detail.booth.table.serial")
        },
        {
            accessor: "boothName",
            Header: t("menu.branch.detail.booth.table.boothName")
        },
        {
            accessor: 'boothVersion',
            Header: t("menu.branch.detail.booth.table.boothVersion"),
            Cell: ({ cell: { value } }) => <BoothVersionCell value={value} />
        },
        {
            accessor: 'boothId',
            Header: t("menu.branch.detail.booth.table.detail"),
            Cell: ({ cell: { value } }) => <BoothDetailCell value={value} />
        }
    ];

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={title}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                {
                    boothList && (boothList.length > 0) ?
                        <Row>
                            {/* table */}
                            <div
                                style={{
                                    height: '250px',
                                    width: '100%',
                                    overflow: 'auto'
                                }}
                            >
                                {
                                    boothList &&
                                    <BoothTable
                                        columns={columnData}
                                        data={renameBoothIdToId(boothList)}
                                    />
                                }
                            </div>
                        </Row>
                        :
                        <Row>
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    padding: '50px'
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '14px',
                                        color: '#757575'
                                    }}
                                >
                                    {t("menu.dashboard.boothInfo.messages.noBooths")}
                                </span>
                            </div>
                        </Row>
                }
            </Container>
        </Modal>
    );
};

export default BoothListViewModal;