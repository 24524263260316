import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom";
import UserService from "../../services/UserService";
import axios from "axios";
import _ from "lodash";
import DataCompareService from "../../services/DataCompareService";
import {Container, Row, Tooltip} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import {useTranslation} from "react-i18next";
import {value} from "lodash/seq";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import styled from "styled-components";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../services/DeleteService";
import {CiSquareCheck} from "react-icons/ci";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const companyInfoValidMapInit = {
    companyName : true,
    description : true
};

const groupInfoValidMapInit = {
    groupName : true,
    description : true
};

const SaveButton = styled.button`
  border: 1px solid #fc7242;
  border-radius: 10px;
  color: #fc7242;
  padding: 7px 20px;
  font-size: 14px;
  background-color: white;
  transition: all ease-in-out 0.2s;
  margin-left: auto;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #fcf9ed;
  }
`;

const SaveButtonDisabled = styled.button`
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  color: #c2c2c2;
  padding: 7px 20px;
  font-size: 14px;
  background-color: white;
  margin-left: auto;
  
  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled.button`
  border: 1px solid #ff0000;
  border-radius: 10px;
  color: #ff0000;
  padding: 7px 20px;
  font-size: 14px;
  background-color: white;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #ffe6e6;
  }
`;

const DeleteButtonDisabled = styled.button`
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  color: #c2c2c2;
  padding: 7px 20px;
  font-size: 14px;
  background-color: white;

  &:focus {
    outline: none;
  }
`;

const UpgradeButton = styled.button`
  width: 150px;
  border: 1px solid #24a0ed;
  border-radius: 10px;
  color: #24a0ed;
  padding: 7px;
  font-size: 14px;
  background-color: white;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #f3ffff;
  }
`;

const UpgradeButtonDisabled = styled.button`
  width: 150px;
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  color: #c2c2c2;
  padding: 7px;
  font-size: 14px;
  background-color: white;

  &:focus {
    outline: none;
  }
`;

const DegradeButton = styled.button`
  width: 150px;
  border: 1px solid #e64545;
  border-radius: 10px;
  color: #e64545;
  padding: 7px;
  font-size: 14px;
  background-color: white;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #e6b8b8;
  }
`;

const DegradeButtonDisabled = styled.button`
  width: 150px;
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  color: #c2c2c2;
  padding: 7px;
  font-size: 14px;
  background-color: white;

  &:focus {
    outline: none;
  }
`;

const GroupSelected = () => {

    const { groupId, companyId } = useParams();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const { updateGroupAndCompanyList } = useOutletContext();

    /* states */
    /* group info */
    const [targetGroupInfo, setTargetGroupInfo] = useState({});
    const [fixedTargetGroupInfo, setFixedTargetGroupInfo] = useState({});
    /* company info */
    const [targetCompanyInfo, setTargetCompanyInfo] = useState({});
    const [fixedTargetCompanyInfo, setFixedTargetCompanyInfo] = useState({});
    const [isOnlyMasterCompany, setIsOnlyMasterCompany] = useState(false);
    /* isEdited, isValid */
    const [isCompanyInfoEdited, setIsCompanyInfoEdited] = useState(false);
    const [isCompanyInfoValid, setIsCompanyInfoValid] = useState(false);
    const [isGroupInfoEdited, setIsGroupInfoEdited] = useState(false);
    const [isGroupInfoValid, setIsGroupInfoValid] = useState(false);
    /* valid map */
    const [companyInfoValidMap, setCompanyInfoValidMap] = useState(companyInfoValidMapInit);
    const [groupInfoValidMap, setGroupInfoValidMap] = useState(groupInfoValidMapInit);

    /* deletion */
    const [isGroupDeleteConfirmOpen, setIsGroupDeleteConfirmOpen] = useState(false);
    const [isCompanyDeleteConfirmOpen, setIsCompanyDeleteConfirmOpen] = useState(false);

    /* fetch group list */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                let newGroupList = res.data.item.groupList;
                let isFound = false;
                for (let i=0 ; i<newGroupList.length ; i++) {
                    if (newGroupList[i]['groupId'] === groupId) {
                        isFound = true;
                        let newGroupInfo = {};
                        newGroupInfo['groupName'] = newGroupList[i]['groupName'];
                        newGroupInfo['description'] = newGroupList[i]['description'];
                        newGroupInfo['isMasterGroup'] = newGroupList[i]['masterGroup'];
                        /* update states */
                        setTargetGroupInfo(_.cloneDeep(newGroupInfo));
                        setFixedTargetGroupInfo(_.cloneDeep(newGroupInfo));
                        setIsGroupInfoValid(true);
                        setGroupInfoValidMap(groupInfoValidMapInit);
                        setIsGroupInfoEdited(false);
                    }
                }
                /* not found */
                if (!isFound) {
                    navigate('/not-found');
                }
            } else {
                setTargetGroupInfo({});
                setFixedTargetGroupInfo({});
                setIsGroupInfoValid(true);
                setGroupInfoValidMap(groupInfoValidMapInit);
                setIsGroupInfoEdited(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* fetch company list */
    const fetchCompanyList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                let newCompanyList = res.data.item.companyList;
                let isFound = false;
                for (let i=0 ; i<newCompanyList.length ; i++) {
                    if (newCompanyList[i]['companyId'] === companyId) {
                        isFound = true;
                        let newCompanyInfo = {};
                        newCompanyInfo['companyAdminCount'] = newCompanyList[i]['companyAdminCount'];
                        newCompanyInfo['companyName'] = newCompanyList[i]['companyName'];
                        newCompanyInfo['description'] = newCompanyList[i]['description'];
                        newCompanyInfo['isMasterCompany'] = newCompanyList[i]['masterCompany'];
                        if (newCompanyInfo['isMasterCompany']) {
                            setIsOnlyMasterCompany(checkIsOnlyMasterCompanyInList(newCompanyList, newCompanyList[i]));
                        } else {
                            setIsOnlyMasterCompany(false);
                        }
                        /* update states */
                        setTargetCompanyInfo(_.cloneDeep(newCompanyInfo));
                        setFixedTargetCompanyInfo(_.cloneDeep(newCompanyInfo));
                        setCompanyInfoValidMap(companyInfoValidMapInit);
                        setIsCompanyInfoValid(true);
                        setIsCompanyInfoEdited(false);
                    }
                }
                /* not found */
                if (!isFound) {
                    navigate('/not-found');
                }
            } else {
                setTargetCompanyInfo({});
                setFixedTargetCompanyInfo({});
                setCompanyInfoValidMap(companyInfoValidMapInit);
                setIsCompanyInfoValid(true);
                setIsCompanyInfoEdited(false);
                /* not found */
                navigate('/not-found');
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* put & post */
    const putGroupInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group/${groupId}`;
            let requestBody = _.cloneDeep(targetGroupInfo);
            delete requestBody['isMasterGroup'];
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await updateGroupAndCompanyList();
            await fetchGroupList();
            await fetchCompanyList();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
        }
    };

    const putCompanyInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company/${companyId}`;
            let requestBody = _.cloneDeep(targetCompanyInfo);
            delete requestBody['isMasterCompany'];
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await updateGroupAndCompanyList();
            await fetchGroupList();
            await fetchCompanyList();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
        }
    };

    /* upgrade company */
    const upgradeCompany = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company/${companyId}/upgrade`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                {},
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.upgraded")} />);
            await updateGroupAndCompanyList();
            await fetchGroupList();
            await fetchCompanyList();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* degrade company */
    const degradeCompany = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company/${companyId}/degrade`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                {},
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.degraded")} />);
            await updateGroupAndCompanyList();
            await fetchGroupList();
            await fetchCompanyList();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* utils */
    const checkIsOnlyMasterCompanyInList = (companyList, targetCompanyInfo) => {
        let isOnly = true;
        companyList.map(companyInfo => {
            if (companyInfo['companyGroupId'] === targetCompanyInfo['companyGroupId']) {
                if (companyInfo['companyId'] !== targetCompanyInfo['companyId']) {
                    if (companyInfo['masterCompany']) {
                        isOnly = false;
                    }
                }
            }
        });
        return isOnly;
    };

    const updateCompanyInfo = (key, value) => {
        setTargetCompanyInfo(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    const updateCompanyIsValidMap = (key, value) => {
        setCompanyInfoValidMap(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    const updateGroupInfo = (key, value) => {
        setTargetGroupInfo(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateGroupIsValidMap = (key, value) => {
        setGroupInfoValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* input handlers */
    const handleGroupNameInput = (value) => {
        updateGroupInfo("groupName", value);
        if (value.length < 3 || value.length > 15) {
            updateGroupIsValidMap("groupName", false);
        } else {
            updateGroupIsValidMap("groupName", true);
        }
    };

    const handleGroupDescriptionInput = (value) => {
        updateGroupInfo("description", value);
        if (value === "") {
            updateGroupIsValidMap("description", false);
        } else {
            updateGroupIsValidMap("description", true);
        }
    };

    const handleCompanyNameInput = (value) => {
        updateCompanyInfo("companyName", value);
        if (value.length < 3 || value.length > 15) {
            updateCompanyIsValidMap("companyName", false);
        } else {
            updateCompanyIsValidMap("companyName", true);
        }
    };

    const handleCompanyDescriptionInput = (value) => {
        updateCompanyInfo("description", value);
        if (value === "") {
            updateCompanyIsValidMap("description", false);
        } else {
            updateCompanyIsValidMap("description", true);
        }
    };

    /* effects */
    /* initializing */
    useEffect(() => {
        fetchGroupList();
        fetchCompanyList();
    }, [groupId, companyId]);

    /* track isValid map */
    useEffect(() => {
        setIsCompanyInfoValid(DataCompareService.checkIsAllTrue(companyInfoValidMap));
    }, [companyInfoValidMap]);

    useEffect(() => {
        setIsGroupInfoValid(DataCompareService.checkIsAllTrue(groupInfoValidMap));
    }, [groupInfoValidMap]);

    /* track isEdited */
    useEffect(() => {
        setIsCompanyInfoEdited(!_.isEqual(targetCompanyInfo, fixedTargetCompanyInfo));
    }, [targetCompanyInfo]);

    useEffect(() => {
        setIsGroupInfoEdited(!_.isEqual(targetGroupInfo, fixedTargetGroupInfo));
    }, [targetGroupInfo]);

    const renderToolTip = (name) => {
        return (
            <Tooltip id="tooltip">
                {name}
            </Tooltip>
        );
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* group information */}
            <div className={"contentInner"}>
                <Container fluid>
                    <Row>
                        <TitleAndInputBox
                            title={t("menu.groupCompany.group.detail.groupName")}
                            value={targetGroupInfo['groupName']}
                            onChange={handleGroupNameInput}
                            isForEdit={true}
                            type={"text"}
                            isValid={groupInfoValidMap['groupName']}
                            isEnabled={true}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <TitleAndTextArea
                            title={t("menu.groupCompany.group.detail.groupDescription")}
                            value={targetGroupInfo['description']}
                            onChange={handleGroupDescriptionInput}
                            isForEdit={true}
                            isValid={groupInfoValidMap['description']}
                            height={"120"}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        {/* save button */}
                        <div
                            style={{
                                width: '100%',
                                display: 'inline-flex',
                                flexDirection: 'row'
                            }}
                        >
                            {/* group delete button */}
                            {
                                targetGroupInfo['isMasterGroup'] ?
                                    <DeleteButtonDisabled>
                                        {t("language.message.deletion.button.group")}
                                    </DeleteButtonDisabled>
                                    :
                                    <>
                                        <DeleteButton
                                            onClick={async () => await setIsGroupDeleteConfirmOpen(true)}
                                        >
                                            {t("language.message.deletion.button.group")}
                                        </DeleteButton>
                                        {/* delete confirmation */}
                                        <DeleteConfirmModal
                                            isOpen={isGroupDeleteConfirmOpen}
                                            setIsOpen={setIsGroupDeleteConfirmOpen}
                                            isDelayed={true}
                                            confirmMessage={t("language.message.deletion.confirmation", { entityName : targetGroupInfo['groupName'] })}
                                            entity={DeleteService.ValidEntities.GROUP}
                                            entityName={targetGroupInfo['groupName']}
                                            id={`group/${groupId}`}
                                            onDeleteSuccess={async () => {
                                                /* have to check */
                                                await updateGroupAndCompanyList();
                                                await navigate('/manage-gr-cm');
                                            }}
                                            depsToShow={[
                                                t("language.message.deletion.depsList.group.user"),
                                                t("language.message.deletion.depsList.group.admin"),
                                                t("language.message.deletion.depsList.group.qna"),
                                                t("language.message.deletion.depsList.group.order"),
                                                t("language.message.deletion.depsList.group.product"),
                                                t("language.message.deletion.depsList.group.productCategory"),
                                                t("language.message.deletion.depsList.group.branch"),
                                                t("language.message.deletion.depsList.group.warranty"),
                                                t("language.message.deletion.depsList.group.board"),
                                                t("language.message.deletion.depsList.group.faq"),
                                                t("language.message.deletion.depsList.group.company"),
                                                t("language.message.deletion.depsList.group.franchise")
                                            ]}
                                            validateMessage={t("language.message.deletion.verificationMessage", { entityName : targetGroupInfo['groupName'] })}
                                            delayToUndo={DeleteService.getLongDelay()}
                                            onUndoSuccess={async () => {
                                                const currLocation = location.pathname;
                                                await navigate(currLocation);
                                            }}
                                        />
                                    </>
                            }
                            {
                                isGroupInfoEdited && isGroupInfoValid ?
                                    <SaveButton
                                        onClick={async () => {
                                            await putGroupInfo();
                                        }}
                                    >
                                        {t("button.save")}
                                    </SaveButton>
                                    :
                                    <SaveButtonDisabled>
                                        {t("button.save")}
                                    </SaveButtonDisabled>
                            }
                        </div>
                    </Row>
                </Container>
            </div>
            {/* company information */}
            <div
                className={"contentInner"}
                style={{
                    marginTop: '10px'
                }}
            >
                <Container fluid>
                    <Row>
                        <TitleAndInputBox
                            title={t("menu.groupCompany.company.detail.companyName")}
                            value={targetCompanyInfo['companyName']}
                            onChange={handleCompanyNameInput}
                            isForEdit={true}
                            type={"text"}
                            isValid={companyInfoValidMap['companyName']}
                            isEnabled={true}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <TitleAndTextArea
                            title={t("menu.groupCompany.company.detail.companyDescription")}
                            value={targetCompanyInfo['description']}
                            onChange={handleCompanyDescriptionInput}
                            isForEdit={true}
                            isValid={companyInfoValidMap['description']}
                            height={"120"}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        {/* save button */}
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            {/* delete company */}
                            {
                                (targetCompanyInfo['isMasterCompany'] && isOnlyMasterCompany) || (targetCompanyInfo['companyAdminCount'] > 0) ?
                                    (
                                        (targetCompanyInfo['companyAdminCount'] > 0) ?
                                        <OverlayTrigger
                                            placement={"top"}
                                            overlay={
                                                targetCompanyInfo['companyAdminCount'] === 1 ?
                                                    renderToolTip(t("language.message.deletion.depsList.company.adminNoteSingle", { adminCount : 1 }))
                                                    :
                                                    renderToolTip(t("language.message.deletion.depsList.company.adminNote", { adminCount : targetCompanyInfo['companyAdminCount'] }))
                                            }
                                        >
                                            <DeleteButtonDisabled>
                                                {t("language.message.deletion.button.company")}
                                            </DeleteButtonDisabled>
                                        </OverlayTrigger>
                                        :
                                        <DeleteButtonDisabled>
                                            {t("language.message.deletion.button.company")}
                                        </DeleteButtonDisabled>
                                    )
                                    :
                                    <>
                                        <DeleteButton
                                            onClick={async () => await setIsCompanyDeleteConfirmOpen(true)}
                                        >
                                            {t("language.message.deletion.button.company")}
                                        </DeleteButton>
                                        {/* delete confirmation */}
                                        <DeleteConfirmModal
                                            isOpen={isCompanyDeleteConfirmOpen}
                                            setIsOpen={setIsCompanyDeleteConfirmOpen}
                                            isDelayed={true}
                                            confirmMessage={t("language.message.deletion.confirmation", { entityName : targetCompanyInfo['companyName'] })}
                                            entity={DeleteService.ValidEntities.COMPANY}
                                            entityName={targetCompanyInfo['companyName']}
                                            id={`company/${companyId}`}
                                            onDeleteSuccess={async () => {
                                                /* have to check */
                                                await updateGroupAndCompanyList(groupId);
                                            }}
                                            depsToShow={[]}
                                            validateMessage={t("language.message.deletion.verificationMessage", { entityName : targetCompanyInfo['companyName'] })}
                                            delayToUndo={DeleteService.getMediumDelay()}
                                            onUndoSuccess={async () => {
                                                const currLocation = location.pathname;
                                                await navigate(currLocation);
                                            }}
                                        />
                                    </>
                            }
                            {/* upgrade company */}
                            {
                                !targetGroupInfo['isMasterGroup'] ?
                                    (
                                        (!targetCompanyInfo['isMasterCompany']) ?
                                            <UpgradeButton
                                                style={{
                                                    marginLeft: 'auto'
                                                }}
                                                onClick={upgradeCompany}
                                            >
                                                {t("button.upgradeCompany")}
                                            </UpgradeButton>
                                            :
                                            <UpgradeButtonDisabled
                                                style={{
                                                    marginLeft: 'auto'
                                                }}
                                            >
                                                {t("button.upgradeCompany")}
                                            </UpgradeButtonDisabled>
                                    )
                                    :
                                    <></>
                            }
                            {/* degrade company */}
                            {
                                !targetGroupInfo['isMasterGroup'] ?
                                    (
                                        targetCompanyInfo['isMasterCompany'] && (!isOnlyMasterCompany) ?
                                            <DegradeButton
                                                style={{
                                                    marginLeft: '10px'
                                                }}
                                                onClick={degradeCompany}
                                            >
                                                {t("button.degradeCompany")}
                                            </DegradeButton>
                                            :
                                            <DegradeButtonDisabled
                                                style={{
                                                    marginLeft: '10px'
                                                }}
                                            >
                                                {t("button.degradeCompany")}
                                            </DegradeButtonDisabled>
                                    )
                                    :
                                    <></>
                            }
                            {
                                isCompanyInfoEdited && isCompanyInfoValid ?
                                    <SaveButton
                                        style={
                                            targetGroupInfo['isMasterGroup'] ?
                                                {
                                                    marginLeft: 'auto'
                                                }
                                                :
                                                {
                                                    marginLeft: '10px'
                                                }
                                        }
                                        onClick={() => {
                                            putCompanyInfo();
                                        }}
                                    >
                                        {t("button.save")}
                                    </SaveButton>
                                    :
                                    <SaveButtonDisabled
                                        style={
                                            targetGroupInfo['isMasterGroup'] ?
                                                {
                                                    marginLeft: 'auto'
                                                }
                                                :
                                                {
                                                    marginLeft: '10px'
                                                }
                                        }
                                    >
                                        {t("button.save")}
                                    </SaveButtonDisabled>
                            }
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default GroupSelected;