import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import SchemaParser from "../../utils/SchemaParser";
import _ from "lodash";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import styled from "styled-components";
import { MdAddBox, MdDisabledByDefault } from "react-icons/md";
import BoothService from "../../axiosServices/BoothService";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import ToastAlertView from "../../../../common/alert/ToastAlertView";

const SaveButton = styled.button`
  width: 20%;
  border: 1px solid #fc7242;
  background-color: white;
  color: #fc7242;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #fff1ec;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  width: 20%;
  border: 1px solid #ebebeb;
  background-color: white;
  color: #ebebeb;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  
  &:focus {
    outline: none;
  }
`;

const CategoryElement = styled.div`
  background-color: #ebebeb;
  padding: 5px 15px;
  color: #757575;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 15px;
`;

const CategoryElementAdded = styled.div`
  background-color: #fcf9ed;
  padding: 5px 15px;
  color: #fc7242;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 15px;
`;

const IconButtonForAdd = styled.button`
  border: none;
  background: none;
  color: #757575;
  padding: 5px;
  transition: all ease-in-out 0.2s;
  position: relative;
  
  &:hover {
    color: #fc7242;
  }
  &:focus {
    outline: none;
  }
`;

const IconButtonForDelete = styled.button`
  border: none;
  background: none;
  color: red;
  padding: 5px;
  transition: all ease-in-out 0.2s;
  position: relative;
  
  &:hover {
    color: #fc7242;
  }
  &:focus {
    outline: none;
  }
`;

const ArrayView = ({ isTitleUsed, title, arrayValue, isForEdit, updateValue, updateIsValid, mainKey, validator, itemProperty }) => {

    const { t, i18n } = useTranslation();

    const {boothId, deviceId} = useParams();

    /* array that will be sent to the server */
    const [dataArray, setDataArray] = useState([]);

    /* isButtonLoading */
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    /* --------------------------- */
    /* ONLY for CAT_STRING_* types */
    const [categoryList, setCategoryList] = useState([]);
    const [isArrayEdited, setIsArrayEdited] = useState(false);
    /* --------------------------- */

    /* ----------------------------------- */
    /* Supported array types               */
    /* ----------------------------------- */
    /* STRING_GET                          */
    /* CAT_STRING_GET                      */
    /* INTEGER_GET      (not implemented)  */
    /* BOOLEAN_GET      (not implemented)  */
    /* STRING_PUT       (not implemented)  */
    /* CAT_STRING_PUT                      */
    /* INTEGER_PUT      (not implemented)  */
    /* BOOLEAN_PUT      (not implemented)  */
    /* ----------------------------------- */
    /* array type */
    const [arrayType, setArrayType] = useState("NONE");

    /* put array */
    const putArray = () => {
        let axiosBody = {};
        axiosBody[mainKey] = dataArray;
        const onSuccess = () => {
            setIsButtonLoading(false);
            setIsArrayEdited(false);
            toast.success(<ToastAlertView message={t("menu.boothControl.infoMessage.editRequestSuccess")} />);
        };
        const onFailure = () => {
            setIsButtonLoading(false);
            setIsArrayEdited(false);
            toast.success(<ToastAlertView message={t("menu.boothControl.infoMessage.editRequestFail")} />);
        }
        setIsButtonLoading(true);
        let requestPath = `/booth/${boothId}/device/${deviceId}`;
        BoothService.putBoothDataToEss(requestPath, axiosBody, onSuccess, onFailure);
    };

    /* initialize arrayType and dataList */
    useEffect(() => {
        /* arrayType */
        let newArrayType = validator['items']['type'];
        let isCategorical = itemProperty['isCategorical']
        let isPutAuthExist = isForEdit;
        setArrayType(SchemaParser.extractArrayType(newArrayType, isCategorical, isPutAuthExist));
        /* dataList */
        setDataArray(_.cloneDeep(arrayValue));
    }, [arrayValue, validator, itemProperty]);

    /* --------------------------- */
    /* ONLY for CAT_STRING_* types */
    /* data add & delete */
    const addCategoryDataToList = (data) => {
        /* add to dataArray */
        setDataArray(prevArray => {
            let newArray = [...prevArray];
            newArray.unshift(data);
            return newArray;
        });
        /* revoke data from categoryList */
        setCategoryList(prevList => {
            let newArray = [...prevList];
            newArray.map((value, index) => {
               if (value === data) {
                   newArray.splice(index, 1);
               }
            });
            return newArray;
        });
    };
    const deleteCategoryDataFromList = (data) => {
        /* revoke data from dataArray */
        setDataArray(prevArray => {
           let newArray = [...prevArray];
           newArray.map((value, index) => {
                if (value === data) {
                    newArray.splice(index, 1);
                }
           });
           return newArray;
        });
        /* add to categoryList */
        setCategoryList(prevArray => {
           let newArray = [...prevArray];
           newArray.unshift(data);
           return newArray;
        });
    };

    /* initialize categoryList */
    useEffect(() => {
        if (arrayType === "CAT_STRING_GET" || arrayType === "CAT_STRING_PUT") {
            let pattern = validator['items']['pattern'];
            let newCategoryList = SchemaParser.extractListFromPattern(pattern);
            /* initial value filter */
            newCategoryList = newCategoryList.filter(category => {
                return !arrayValue.includes(category);
            });
            setCategoryList(newCategoryList);
        } else {
            return;
        }
    }, [arrayType, validator, arrayValue]);

    /* tracking changes */
    useEffect(() => {
        if (dataArray != null && arrayValue != null) {
            setIsArrayEdited(!(JSON.stringify(dataArray.sort()) === JSON.stringify(arrayValue.sort())));
        } else {
            setIsArrayEdited(false);
        }
    }, [dataArray]);
    /* --------------------------- */


    /* render */
    if (arrayType === "NONE") {
        /* not supported */
        return (
            <span
                style={{
                    color : '#757575'
                }}
            >
                    {t("menu.boothControl.infoMessage.notSupport")}
                </span>
        );
    } else if (arrayType !== "CAT_STRING_GET" && arrayType !== "CAT_STRING_PUT") {
        /* not implemented */
        return (
            <span
                style={{
                    color : '#757575'
                }}
            >
                    {t("menu.boothControl.infoMessage.notImplemented")}
            </span>
        );
    } else {
        /* supported and implemented types */
        if (arrayType === "CAT_STRING_GET" || arrayType === "CAT_STRING_PUT") {
            return (
                <Container fluid>
                    <Row>
                        {/* only put */}
                        {/* category select options */}
                        {
                            isForEdit ?
                                <Col>
                                    <div
                                        style={{
                                            height: (categoryList.length < 9) ? 'auto' : '450px',
                                            overflow: 'auto',
                                            padding: '5px'
                                        }}
                                    >
                                        {
                                            categoryList.sort().map(category => {
                                              return (
                                                  <CategoryElement
                                                      key={category}
                                                  >
                                                      <div>
                                                          <span>
                                                              {category}
                                                          </span>
                                                      </div>
                                                      <div
                                                          style={{
                                                              marginLeft: 'auto'
                                                          }}
                                                      >
                                                          <IconButtonForAdd
                                                              onClick={() => addCategoryDataToList(category)}
                                                          >
                                                              <MdAddBox
                                                                size={"25"}
                                                              />
                                                          </IconButtonForAdd>
                                                      </div>
                                                  </CategoryElement>
                                              );
                                            })
                                        }
                                    </div>
                                </Col>
                                :
                                <></>
                        }
                        {/* current elements */}
                        <Col>
                            <div
                                style={{
                                    height: (dataArray.length < 9) ? 'auto' : '450px',
                                    overflow: 'auto',
                                    padding: '5px'
                                }}
                            >
                                {
                                    dataArray.sort().map(category => {
                                        return (
                                            <CategoryElementAdded
                                                key={category}
                                            >
                                                <div>
                                                    <span>
                                                      {category}
                                                    </span>
                                                </div>
                                                {
                                                    isForEdit ?
                                                        <div
                                                            style={{
                                                                marginLeft: 'auto'
                                                            }}
                                                        >
                                                            <IconButtonForDelete
                                                                onClick={() => deleteCategoryDataFromList(category)}
                                                            >
                                                                <MdDisabledByDefault
                                                                    size={"25"}
                                                                />
                                                            </IconButtonForDelete>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </CategoryElementAdded>
                                        );
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    {/* only put */}
                    {/* save button */}
                    {
                        isForEdit ?
                            <Row
                                style={{
                                    marginTop: '10px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'right'
                                    }}
                                >
                                    {
                                        isArrayEdited ?
                                            <SaveButton
                                                onClick={() => putArray()}
                                            >
                                                {
                                                    isButtonLoading ?
                                                        <Spinner size={"sm"} />
                                                        :
                                                        t("menu.boothControl.button.sendRequest")
                                                }
                                            </SaveButton>
                                            :
                                            <SaveButtonDisabled>
                                                {t("menu.boothControl.button.sendRequest")}
                                            </SaveButtonDisabled>
                                    }
                                </div>
                            </Row>
                            :
                            <></>
                    }
                </Container>
            );
        }
    }
};

export default ArrayView;