import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import DeleteService from "../../../services/DeleteService";
import {toast} from "react-toastify";
import styled from "styled-components";
import TitleAndTextInput from "../../booth/detail/dataView/TitleAndTextInput";
import TitleAndInputBox from "../dataView/TitleAndInputBox";


const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        maxWidth: '90vh',
        maxHeight: '80vh',
        borderRadius: '15px',
        overflowY: 'auto',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const UndoButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  font-size: 16px;
  color: #111111;
  margin-left: auto;
`;


/** This component handle all the process of the data deletion.
 *  - Necessary Props
 *      1. isOpen
 *      2. setIsOpen
 *      3. isDelayed
 *      4. confirmMessage
 *      5. entity
 *      6. entityName
 *      7. id (or path)
 *      8. onDeleteSuccess
 *  - Optional Props (for the delayed deletion)
 *      1. depsToShow
 *      2. validateMessage
 *      3. delayToUndo
 *      4. onUndoSuccess
 */
const DeleteConfirmModal = ({ isOpen,
                                setIsOpen,
                                isDelayed,
                                depsToShow,
                                confirmMessage,
                                validateMessage,
                                delayToUndo,
                                entity,
                                entityName,
                                id,
                                onDeleteSuccess,
                                onUndoSuccess}) => {

    const { t } = useTranslation();

    const [isReady, setIsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputStr, setInputStr] = useState("");

    useEffect(() => {
        setIsReady(false);
        setIsLoading(false);
        setInputStr("");
    }, [isOpen, isDelayed, id]);

    useEffect(() => {
        setIsReady(inputStr === validateMessage);
    }, [inputStr]);

    const craftToastContent = (type, entityName, isSuccess) => {
        /** When the quick deletion type is given, show just a notification. */
        if (type === "quick") {
            if (isSuccess) {
                return (
                    <>
                        {t("language.message.deletion.success", { entityName: entityName })}
                    </>
                );
            } else {
                return (
                    <>
                        {t("language.message.deletion.failed", { entityName: entityName })}
                    </>
                );
            }
        }
        /** When the slow deletion type is given, show "undo" interface with a notification. */
        if (type === "slow") {
            if (isSuccess) {
                return (
                    <div
                        style={{
                            width: '100%',
                            display: 'inline-flex',
                            flexDirection: 'column'
                        }}
                    >
                        <span
                            style={{
                                marginBottom: '5px'
                            }}
                        >
                            {t("language.message.deletion.success", { entityName: entityName })}
                        </span>
                        <UndoButton
                            onClick={async () => {
                                await undoSlowDelete();
                            }}
                        >
                            {t("language.message.deletion.undo")}
                        </UndoButton>
                    </div>
                );
            } else {
                return (
                    <>
                        {t("language.message.deletion.failed", { entityName: entityName })}
                    </>
                );
            }
        }
        /** undo result */
        if (type === "undo") {
            if (isSuccess) {
                return (
                    <>
                        {t("language.message.deletion.successToUndo")}
                    </>
                );
            } else {
                return (
                    <>
                        {t("language.message.deletion.failedToCancel")}
                    </>
                );
            }
        }
    };

    const doQuickDelete = async () => {
        await setIsLoading(true);
        const onSuccessCallback = async () => {
            /* show alert */
            toast.success(craftToastContent("quick", entityName, true));
            /* do onDeleteSuccess */
            await onDeleteSuccess();
            await setIsLoading(false);
            await setIsOpen(false);
        };
        const onFailureCallback = async (e) => {
            console.log(e);
            /* show alert */
            toast.error(craftToastContent("quick", entityName, false));
            await setIsLoading(false);
            await setIsOpen(false);
        };
        await DeleteService.doDelete(entity, id, onSuccessCallback, onFailureCallback);
    };

    const doSlowDelete = async () => {
        await setIsLoading(true);
        const onSuccessCallback = async () => {
            /* show alert */
            const genUndoToastId = toast.success(craftToastContent("slow", entityName, true), { autoClose: (delayToUndo * 1000) });
            await window.sessionStorage.setItem(id + "_tid", JSON.stringify(genUndoToastId));
            /* do onDeleteSuccess */
            await onDeleteSuccess();
            await setIsLoading(false);
            await setIsOpen(false);
        };
        const onFailureCallback = async (e) => {
            console.log(e);
            /* show alert */
            await toast.error(craftToastContent("slow", entityName, false));
            await setIsLoading(false);
            await setIsOpen(false);
        };
        await DeleteService.registerDelete(entity, id, onSuccessCallback, onFailureCallback);
    };

    const undoSlowDelete = async () => {
        await setIsLoading(true);
        const onSuccessCallback = async () => {
            /* show alert */
            const tid = JSON.parse(window.sessionStorage.getItem(id + "_tid"));
            await toast.dismiss(tid);
            await toast.success(craftToastContent("undo", entityName, true));
            /* do onUndoSuccess */
            await onUndoSuccess();
            await setIsLoading(false);
            await setIsOpen(false);
        };
        const onFailureCallback = async (e) => {
            console.log(e);
            /* show alert */
            await toast.error(craftToastContent("undo", entityName, false));
            await setIsLoading(false);
            await setIsOpen(false);
        };
        await DeleteService.undoDelete(entity, id, onSuccessCallback, onFailureCallback);
    }

    if (isDelayed) {
        return (
            <Modal
                style={modalStyle}
                isOpen={isOpen}
                onRequestClose={() => {
                    setIsOpen(false);
                }}
            >
                <Container fluid>
                    {
                        depsToShow && (depsToShow.length > 0) &&
                        <Row>
                            {/* deps view */}
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    padding: '5px 20px',
                                    display: 'inline-flex',
                                    flexDirection: 'column',
                                    whiteSpace: 'normal'
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '16px',
                                        color: '#696969',
                                        marginBottom: '5px'
                                    }}
                                >
                                    {t("language.message.deletion.depsView")}
                                </span>
                                {
                                    depsToShow.map(dep => {
                                        return (
                                            <span
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: '500',
                                                    color: '#696969',
                                                    marginBottom: '5px'
                                                }}
                                                key={dep}
                                            >
                                                {"- " + dep}
                                            </span>
                                        );
                                    })
                                }
                            </div>
                        </Row>
                    }
                    <Row>
                        {/* verification */}
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                padding: '5px 20px',
                                display: 'inline-flex',
                                flexDirection: 'column',
                                whiteSpace: 'normal'
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '16px',
                                    color: '#696969',
                                    marginBottom: '5px'
                                }}
                            >
                                {t("language.message.deletion.verificationMessageWrapper")}
                            </span>
                            <span
                                style={{
                                    fontSize: '16px',
                                    color: '#696969',
                                    marginBottom: '5px',
                                    fontWeight: '500'
                                }}
                            >
                                {validateMessage}
                            </span>
                        </div>
                    </Row>
                    <Row>
                        {/* verification message */}
                        <TitleAndInputBox
                            title={""}
                            value={inputStr}
                            onChange={setInputStr}
                            isForEdit={true}
                            type={"text"}
                            isValid={isReady}
                            isEnabled={true}
                            placeHolder={validateMessage}
                        />
                    </Row>
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                padding: '5px 20px',
                                display: 'inline-flex',
                                flexDirection: 'column',
                                whiteSpace: 'normal'
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '16px',
                                    color: '#ff6666'
                                }}
                            >
                                {t("language.message.deletion.inUndo", { delayTime : delayToUndo })}
                            </span>
                        </div>
                    </Row>
                    <Row>
                        {/* confirmation message */}
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                padding: '5px 20px',
                                display: 'inline-flex',
                                flexDirection: 'column',
                                whiteSpace: 'normal'
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '16px',
                                    color: '#696969',
                                    marginBottom: '5px'
                                }}
                            >
                                {confirmMessage}
                            </span>
                        </div>
                    </Row>
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <Col>
                            <button
                                className={isReady ? "modal-confirmBtn" : "modal-confirmBtn-disabled"}
                                onClick={async () => {
                                    if ((!isLoading) && isReady) {
                                        await doSlowDelete();
                                    }
                                }}
                            >
                                {
                                    isLoading ?
                                        <Spinner size={"sm"}/>
                                        :
                                        <>{t("language.message.deletion.yes")}</>
                                }
                            </button>
                        </Col>
                        <Col>
                            <button
                                className={"modal-rejectBtn"}
                                onClick={async () => {
                                    if (!isLoading) {
                                        await setIsOpen(false);
                                    }
                                }}
                            >
                                {t("language.message.deletion.no")}
                            </button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        );
    } else {
        return (
            <Modal
                style={modalStyle}
                isOpen={isOpen}
                onRequestClose={async () => {
                    if (!isLoading) {
                        await setIsOpen(false);
                    }
                }}
            >
                <Container fluid>
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                padding: '20px',
                                display: 'inline-flex',
                                flexDirection: 'column',
                                whiteSpace: 'normal'
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '16px',
                                    color: '#696969',
                                    marginBottom: '5px'
                                }}
                            >
                                {confirmMessage}
                            </span>
                            <span
                                style={{
                                    fontSize: '16px',
                                    color: '#ff6666'
                                }}
                            >
                                {t("language.message.deletion.noUndo")}
                            </span>
                        </div>
                    </Row>
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <Col>
                            <button
                                className={"modal-confirmBtn"}
                                onClick={async () => {
                                    if (!isLoading) {
                                        await doQuickDelete();
                                    }
                                }}
                            >
                                {
                                    isLoading ?
                                        <Spinner size={"sm"}/>
                                        :
                                        <>{t("language.message.deletion.yes")}</>
                                }
                            </button>
                        </Col>
                        <Col>
                            <button
                                className={"modal-rejectBtn"}
                                onClick={async () => {
                                    if (!isLoading) {
                                        await setIsOpen(false);
                                    }
                                }}
                            >
                                {t("language.message.deletion.no")}
                            </button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        );
    }


};

export default DeleteConfirmModal;