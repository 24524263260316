import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import DataCompareService from "../../services/DataCompareService";
import DataParseService from "../../services/DataParseService";
import {Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import Modal from "react-modal";
import UserService from '../../services/UserService';
import ModalCloseButton from "../common/modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";

const requestBodyInit = {
    deliveryCharge : "",
    deliveryChargeUnit : ""
};

const isValidMapInit = {
    deliveryCharge : true,
    deliveryChargeUnit : true
};

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '400px',
        height: '330px',
        margin: 'auto'
    }
}

const EditDeliveryCharge = ({ orderId, isOpen, setIsOpen, onRefresh, prevData }) => {

    const { t } = useTranslation();
    const [fixedData, setFixedData] = useState(requestBodyInit);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    const [isEdited, setIsEdited] = useState(false);

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    /* handlers */
    const handleDeliveryChargeInput = (value) => {
        let floatValue = parseFloat(value);
        if (isNaN(floatValue)) {
            updateRequestBody("deliveryCharge", value);
        } else {
            updateRequestBody("deliveryCharge", floatValue);
        }
    }

    const handleDeliveryChargeUnitInput = (value) => {
        updateRequestBody("deliveryChargeUnit", value);
    }

    /* validators */
    const validateDeliveryCharge = () => {
        if ((requestBody["deliveryCharge"] == null)
            || (requestBody["deliveryCharge"] === "")
            || (typeof requestBody["deliveryCharge"] !== "number")) {
            updateIsValidMap("deliveryCharge", false);
        } else {
            if (requestBody["deliveryCharge"] < 0) {
                updateIsValidMap("deliveryCharge", false);
            } else {
                updateIsValidMap("deliveryCharge", true);
            }
        }
    }

    const validateDeliveryChargeUnit = () => {
        if (requestBody["deliveryChargeUnit"] === "") {
            updateIsValidMap("deliveryChargeUnit", false);
        } else {
            updateIsValidMap("deliveryChargeUnit", true);
        }
    };

    /* edit onShip information (PUT) */
    const putOnShipInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/order/${orderId}`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            onRefresh();
            setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* effects */
    /* validate */
    useEffect(() => {
        validateDeliveryCharge();
    }, [requestBody["deliveryCharge"]]);

    useEffect(() => {
        validateDeliveryChargeUnit();
    }, [requestBody["deliveryChargeUnit"]]);

    useEffect(() => {
        setRequestBody(() => {
            let data = DataCompareService.deepCopyObj(prevData);
            let newRequestBody = {};
            newRequestBody["deliveryCharge"] = data["deliveryCharge"]
            newRequestBody["deliveryChargeUnit"] = data["deliveryChargeUnit"];
            return newRequestBody;
        });
        setFixedData(() => {
            let data = DataCompareService.deepCopyObj(prevData);
            let newRequestBody = {};
            newRequestBody["deliveryCharge"] = data["deliveryCharge"];
            newRequestBody["deliveryChargeUnit"] = data["deliveryChargeUnit"];
            return newRequestBody;
        });
    }, [prevData]);

    /* isEdited */
    useEffect(() => {
        setIsEdited(!DataCompareService.isSameObj(fixedData, requestBody));
    }, [requestBody]);

    /* isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <ModalCloseButton
                title={t("button.editDeliveryCharge")}
                setIsOpen={setIsOpen}
            />
            <div
                style={{
                    width: '100%'
                }}
            >
                <Container fluid>
                    <Row>
                        <TitleAndInputBox
                            title={t("menu.order.detail.content.deliveryCharge")}
                            value={requestBody["deliveryCharge"]}
                            onChange={handleDeliveryChargeInput}
                            isForEdit={true}
                            type={"number"}
                            isValid={isValidMap["deliveryCharge"]}
                            isEnabled={true}
                        />
                    </Row>
                    <Row
                        style={{ marginTop: '5px' }}
                    >
                        <TitleAndInputBox
                            title={t("menu.order.detail.content.deliveryChargeUnit")}
                            value={requestBody["deliveryChargeUnit"]}
                            onChange={handleDeliveryChargeUnitInput}
                            isForEdit={true}
                            type={"text"}
                            isValid={isValidMap["deliveryChargeUnit"]}
                            isEnabled={true}
                        />
                    </Row>
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                marginTop: '25px'
                            }}
                        >
                            {
                                (isValid && isEdited) ?
                                    <button
                                        id={"long-save-btn"}
                                        onClick={() => putOnShipInfo()}
                                    >
                                        {t("button.save")}
                                    </button>
                                    :
                                    <button
                                        id={"long-save-btn-disabled"}
                                    >
                                        {t("button.save")}
                                    </button>
                            }
                        </div>
                    </Row>
                </Container>
            </div>
        </Modal>
    );
};

export default EditDeliveryCharge;