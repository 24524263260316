import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import _ from "lodash";
import ProductJsonAxiosService from "../../../common/jsonDynamic/service/ProductJsonAxiosService";
import RecipeUtils from "../../../common/jsonDynamic/util/RecipeUtils";
import {Col, Container, Row} from "react-bootstrap";
import BoothNameAndVersionView from "./recipe/boothVersionView/BoothNameAndVersionView";
import MenuRecipeView from "./recipe/menuRecipe/MenuRecipeView";
import OptionRecipeView from "./recipe/optionRecipe/OptionRecipeView";

const MessageView = styled.div`
  text-align: center;
  padding: 40px 0px;
  color: #757575;
  font-size: 14px;
`;

const FranchiseMenuRecipeManagement = () => {

    const { t } = useTranslation();
    const { franchiseId, menuId } = useParams();
    const { refreshMenuList, franchiseMenuList } = useOutletContext();
    const navigate = useNavigate();

    /* states */
    const [boothVersionList, setBoothVersionList] = useState([]);
    const [menuRecipeList, setMenuRecipeList] = useState([]);
    const [selectedBoothVersionId, setSelectedBoothVersionId] = useState("");

    /* utils */
    const getTargetMenuInfoFromList = () => {
        let targetMenuInfo = {};
        let isFound = false;
        franchiseMenuList.map(menuInfo => {
            if (menuInfo['menuInfo']['franchiseMenuId'] === menuId) {
                isFound = true;
                targetMenuInfo = _.cloneDeep(menuInfo);
            }
        });
        if (!isFound) {
            navigate('/not-found');
        }
        return targetMenuInfo;
    };

    const isBoothMenu = () => {
        let targetMenuInfo = getTargetMenuInfoFromList();
        return targetMenuInfo['menuInfo'] ? targetMenuInfo['menuInfo']['isBoothMenu'] : false;
    };

    /* initialization */
    const initialize = async () => {
        let targetMenuInfo = getTargetMenuInfoFromList();
        setMenuRecipeList(targetMenuInfo['franchiseMenuDetailList']);
    };

    /* data fetch */
    const fetchBoothVersionList = async (callback) => {
        const onSuccessCallback = async (data) => {
            /* sort by booth name and version */
            await RecipeUtils.sortBoothVersionArray(data);
            /* optional : default selected booth version */
            if ((data.length > 0) && (selectedBoothVersionId === "")) {
                await setSelectedBoothVersionId(data[0]['boothVersionId']);
            }
            await setBoothVersionList(data);
        };
        const onFailureCallback = async (e)  => {
            await setBoothVersionList([]);
            console.log(e);
        };
        await ProductJsonAxiosService.getBoothVersionList(franchiseId, onSuccessCallback, onFailureCallback);
        callback();
    };

    /* effects */
    useEffect(() => {
        fetchBoothVersionList(initialize);
    }, [menuId, franchiseMenuList]);

    if (isBoothMenu()) {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <div
                    style={{
                        width: '250px'
                    }}
                >
                    {/* view booth versions */}
                    <BoothNameAndVersionView
                        selectedBoothVersionId={selectedBoothVersionId}
                        onSelectCallback={setSelectedBoothVersionId}
                        boothVersionList={boothVersionList}
                        menuRecipeList={menuRecipeList}
                    />
                </div>
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <Container fluid>
                        {
                            (selectedBoothVersionId !== "") &&
                            <Row>
                                <Col
                                    style={{
                                        padding: '0px',
                                        paddingLeft: '10px'
                                    }}
                                >
                                    {/* menu recipe */}
                                    <MenuRecipeView
                                        key={selectedBoothVersionId + JSON.stringify(menuRecipeList)}
                                        menuInfo={getTargetMenuInfoFromList()}
                                        selectedBoothVersionId={selectedBoothVersionId}
                                        boothVersionList={boothVersionList}
                                        menuRecipeList={menuRecipeList}
                                        refreshMenuList={refreshMenuList}
                                    />
                                </Col>
                                <Col
                                    style={{
                                        padding: '0px',
                                        paddingLeft: '10px'
                                    }}
                                >
                                    {/* option recipe */}
                                    <OptionRecipeView
                                        key={selectedBoothVersionId + JSON.stringify(menuRecipeList)}
                                        menuInfo={getTargetMenuInfoFromList()}
                                        selectedBoothVersionId={selectedBoothVersionId}
                                        boothVersionList={boothVersionList}
                                        menuRecipeList={menuRecipeList}
                                        refreshMenuList={refreshMenuList}
                                    />
                                </Col>
                            </Row>
                        }
                        {
                            (selectedBoothVersionId === "") &&
                            <Row>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                >
                                    <MessageView>
                                        {t("menu.franchiseMenu.recipeInfo.message.selectBoothVersion")}
                                    </MessageView>
                                </div>
                            </Row>
                        }
                    </Container>
                </div>
            </div>
        );
    }
};

export default FranchiseMenuRecipeManagement;