import React, {useRef} from 'react';
import styled from "styled-components";
import RecipeUtils from "../../util/RecipeUtils";
import OverlayToolTip from "../../../toolTip/OverlayToolTip";
import {useTranslation} from "react-i18next";

const Title = styled.span`
  font-size: 14px;
  color: #8f8f8f;
  padding-left: 5px;
`;

const RangeInput = styled.input`
  width: 73%;
  border: 1px solid #e4e7ea;
  border-radius: 5px;
  padding: 7px 10px;
  color: #212529;
  font-size: 15px;
`;

const PreviewInput = styled.input`
  margin-left: 2%;
  width: 25%;
  border: 1px solid ${(props) => props.isValid ? "#e4e7ea" : "red"};
  border-radius: 5px;
  padding: 7px 10px;
  color: #212529;
  font-size: 15px;
`;

const TextInput = styled.input`
  width: 100%;
  border: 1px solid ${(props) => props.isValid ? "#e4e7ea" : "red"};
  border-radius: 5px;
  padding: 7px 10px;
  color: #212529;
  font-size: 15px;
  transition: all ease-in-out 0.2s;
  
  :focus {
    border-color: #fc7242;
  }
`;

const IntegerFieldView = ({ title,
                              data,
                              validator,
                              updateData,
                              updateIsValidMap,
                              isValid,
                              isForEdit,
                              unitOfValue,
                              hasRangeLimit }) => {

    const { t } = useTranslation();

    /** to prevent scroll */
    const inputElem = useRef();

    const handleOnWheel = (e) => {
        e.preventDefault();
        inputElem.current.blur();
    };

    const handleInputChange = async (valueStr) => {
        if (isForEdit) {
            const regex = /^(0|[-]?[1-9]\d*)$/g;
            let numOfValue = parseInt(valueStr);
            if ((typeof valueStr === "undefined") || (valueStr == null) || (valueStr === "")) {
                await updateData("");
                updateIsValidMap(false);
            } else {
                if (!regex.test(valueStr)) {
                    updateIsValidMap(false);
                } else {
                    await updateData(numOfValue);
                    updateIsValidMap(RecipeUtils.validatePrimitiveData(validator, numOfValue));
                }
            }
        } else {
            return;
        }
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* title */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '5px',
                    textAlign: 'left'
                }}
            >
                <Title>
                    {title + ` (${unitOfValue})`}
                </Title>
            </div>
            {/* input box */}
            {
                hasRangeLimit &&
                <div
                    style={{
                        width: '100%',
                        marginTop: '5px'
                    }}
                >
                    <OverlayToolTip
                        text={t("menu.franchiseMenu.recipeInfo.menuRecipe.message.inputHelpMessage.integer",
                            {
                                fieldName: title,
                                minValue: validator['minimum'],
                                maxValue: validator['maximum'],
                                multipleOf: validator['multipleOf']
                            })}
                        direction={"left"}
                    >
                        <RangeInput
                            type={"range"}
                            readOnly={!isForEdit}
                            value={data}
                            min={validator['minimum']}
                            max={validator['maximum']}
                            step={
                                validator['multipleOf'] === 0 ?
                                    1
                                    :
                                    validator['multipleOf']
                            }
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    </OverlayToolTip>
                    <PreviewInput
                        type={"number"}
                        readOnly={!isForEdit}
                        onChange={(e) => handleInputChange(e.target.value)}
                        value={
                            data == null ?
                                ""
                                :
                                data
                        }
                        isValid={isValid}
                        ref={inputElem}
                        onWheel={handleOnWheel}
                    />
                </div>
            }
            {
                (!hasRangeLimit) &&
                <div
                    style={{
                        width: '100%',
                        marginTop: '5px'
                    }}
                >
                    <OverlayToolTip
                        text={t("menu.franchiseMenu.recipeInfo.menuRecipe.message.inputHelpMessage.integer",
                            {
                                fieldName: title,
                                minValue: validator['minimum'] ? validator['minimum'] : "-infinite",
                                maxValue: validator['maximum'] ? validator['maximum'] : "+infinite",
                                multipleOf: validator['multipleOf'] ? validator['multipleOf'] : "1"
                            })}
                        direction={"left"}
                    >
                        <TextInput
                            type={"integer"}
                            readOnly={!isForEdit}
                            value={data}
                            onChange={(e) => handleInputChange(e.target.value)}
                            isValid={isValid}
                            step={
                                validator['multipleOf'] === 0 ?
                                    1
                                    :
                                    validator['multipleOf']
                            }
                            ref={inputElem}
                            onWheel={handleOnWheel}
                        />
                    </OverlayToolTip>
                </div>
            }
        </div>
    );
};

export default IntegerFieldView;