import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import UserService from "../../../services/UserService";
import axios from "axios";
import Modal from "react-modal";
import {Col, Container, Row} from "react-bootstrap";
import DataCompareService from "../../../services/DataCompareService";
import TitleAndSelectBox from "../../common/dataView/TitleAndSelectBox";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../common/dataView/TitleAndTextArea";
import styled from "styled-components";
import LanguageInput from "../../../language/component/LanguageInput";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import LanguageSelectBox from "../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '700px',
        height: '700px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    warrantyName: "",
    warrantyName1: null,
    warrantyName2: null,
    warrantyName3: null,
    warrantyName4: null,
    warrantyName5: null,
    description:"",
    description1: null,
    description2: null,
    description3: null,
    description4: null,
    description5: null,
    warrantyGroupId: ""
};

const isValidMapInit = {
    warrantyName: false,
    warrantyName1: true,
    warrantyName2: true,
    warrantyName3: true,
    warrantyName4: true,
    warrantyName5: true,
    description: false,
    description1: true,
    description2: true,
    description3: true,
    description4: true,
    description5: true,
    warrantyGroupId: true
};

const SaveButton = styled.button`
  background: none;
  border-radius: 5px;
  font-size: 15px;
  width: 100%;
  color: #fc7242;
  border: 1px solid #fc7242;
  transition: all ease-in-out 0.2s;
  padding: 10px 15px;

  &:hover {
    background-color: #fc7242;
    color: white;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  background: none;
  border-radius: 5px;
  font-size: 15px;
  width: 100%;
  color: #c2c2c2;
  border: 1px solid #c2c2c2;
  padding: 10px 15px;
  
  &:focus {
    outline: none;
  }
`;

const WarrantyAdd = ({ isOpen, setIsOpen, onRefresh }) => {

    const { t } = useTranslation();

    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    /* validity */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    /* group list */
    const [groupList, setGroupList] = useState([]);

    /* state for language */
    const targetFieldsString = `"${t("menu.booth.warranty.detail.name")}", "${t("menu.booth.warranty.detail.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* fetch group list */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setGroupList(res.data.item.groupList);
                updateRequestBody("warrantyGroupId", res.data.item.groupList[0]['groupId'])
            } else {
                setIsOpen(false);
                toast.error(<ToastAlertView message={t("message.getGroupFail")} />);
            }
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.getGroupFail")} />);
        }
    };

    /* change handlers */
    const handleGroupIdChange = (value) => {
        updateRequestBody("warrantyGroupId", value);
    }

    /* post warranty */
    const postWarranty = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/warranty`;
            await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            setIsOpen(false);
            onRefresh();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    }

    useEffect(() => {
        fetchGroupList();
    }, [isOpen]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                onRefresh();
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.booth.warranty.add")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <LanguageSelectBox
                        title={t("language.selectBox.title.warranty")}
                        languageMetadataList={languageMetadataList}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        setIsHighlightOn={setIsHighlightOn}
                        targetFieldString={targetFieldsString}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndSelectBox
                        title={t("menu.booth.warranty.detail.group")}
                        innerValue={requestBody["warrantyGroupId"]}
                        valueList={groupList}
                        valueSelector={"groupId"}
                        viewSelector={"groupName"}
                        onChange={handleGroupIdChange}
                    />
                </Row>
                {/* warranty names */}
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <LanguageInput
                        type={"text"}
                        title={t("menu.booth.warranty.detail.name")}
                        dataKeyTranslationPath={"language.dataKey.warranty.name"}
                        valueMap={requestBody}
                        updateValueMap={updateRequestBody}
                        isValidMap={isValidMap}
                        updateIsValidMap={updateIsValidMap}
                        isForEdit={true}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                {/* warranty content */}
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <LanguageInput
                        type={"textarea"}
                        title={t("menu.booth.warranty.detail.description")}
                        dataKeyTranslationPath={"language.dataKey.warranty.description"}
                        valueMap={requestBody}
                        updateValueMap={updateRequestBody}
                        isValidMap={isValidMap}
                        updateIsValidMap={updateIsValidMap}
                        isForEdit={true}
                        height={"280"}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                {/* save button */}
                <Row
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {
                        isValid ?
                            <SaveButton
                                onClick={() => postWarranty()}
                            >
                                {t("button.save")}
                            </SaveButton>
                            :
                            <SaveButtonDisabled>
                                {t("button.save")}
                            </SaveButtonDisabled>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default WarrantyAdd;