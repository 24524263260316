
/* sort the given obj or obj list */
const sortObj = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(sortObj).sort();
    } else if (obj !== null && typeof obj === 'object') {
        const sortedKeys = Object.keys(obj).sort();
        const result = {};
        sortedKeys.forEach(key => {
            result[key] = sortObj(obj[key]);
        });
        return result;
    }
    return obj;
};

/* compare the given two objects are same or nor */
const isSameObj = (obj1, obj2) => {
    const sortedObj1 = sortObj(obj1);
    const sortedObj2 = sortObj(obj2);
    return JSON.stringify(sortedObj1) === JSON.stringify(sortedObj2);
};

/* compare the given two lists are same or not */
/* including object list, the comparison will ignore sequences like a set. */
const isSameList = (list1, list2) => {
    const sortedList1 = list1.map(sortObj).sort();
    const sortedList2 = list2.map(sortObj).sort();
    return JSON.stringify(sortedList1) === JSON.stringify(sortedList2);
};

/* make a deep copy of an object */
const deepCopyObj = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

/* make a deep copy of a list */
/* including object list, the copy of an object list must also deep copy objects in the list. */
const deepCopyList = (list) => {
    return JSON.parse(JSON.stringify(list));
};

/* check is all true value in the object, objects in object can be covered recursively. */
const checkIsAllTrue = (obj) => {
    // If not object, check if it's true value
    if (typeof obj !== 'object' || obj === null) {
        return obj === true;
    }
    // recursively check
    for (let key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            if (!checkIsAllTrue(obj[key])) {
                return false;
            }
        }
    }
    return true;
}

/* check the given value is validated by the given pattern */
const regexValidator = (value, pattern) => {
    const regex = new RegExp(pattern);
    return regex.test(value);
}

const DataCompareService = {
    isSameObj,
    isSameList,
    deepCopyObj,
    deepCopyList,
    checkIsAllTrue,
    regexValidator
}

export default DataCompareService;