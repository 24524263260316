import React, {useState} from 'react';
import Modal from "react-modal";
import {Col, Container, Row} from "react-bootstrap";
import "./ConfirmModalStyle.css";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '150px',
        margin: 'auto',
        borderRadius: '15px'
    }
};

const ConfirmModal = ({ isOpen, setIsOpen, title, confirmLabel, rejectLabel, onConfirm, onReject }) => {

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                onReject();
                setIsOpen(false);
            }}
        >
            <div
                style={{
                    width: '100%'
                }}
            >
                <Container fluid>
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                height: '80px',
                                overflow: 'auto'
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '16px',
                                    color: '#696969'
                                }}
                            >
                                {title}
                            </span>
                        </div>
                    </Row>
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <Col>
                            <button
                                className={"modal-confirmBtn"}
                                onClick={() => {
                                    setIsOpen(false);
                                    onConfirm();
                                }}
                            >
                                {confirmLabel}
                            </button>
                        </Col>
                        <Col>
                            <button
                                className={"modal-rejectBtn"}
                                onClick={() => {
                                    setIsOpen(false);
                                    onReject();
                                }}
                            >
                                {rejectLabel}
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Modal>
    );
};

export default ConfirmModal;