import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import UserService from "../../services/UserService";
import {Alert, Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import DataParseService from "../../services/DataParseService";
import RenderOnRole from "../../auth/RenderOnRole";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../services/DeleteService";

const FaqDetail = () => {

    const { t, i18n } = useTranslation();
    const { faqId } = useParams("faqId");
    const [faqInfo, setFaqInfo] = useState({});

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    const navigate = useNavigate();

    const fetchFaqDetail = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/faq/${faqId}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization :  "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            setFaqInfo(res.data.item.faqDetail);
        } catch (e) {
            console.error(e);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    useEffect(() => {
        fetchFaqDetail();
    }, []);

    console.log(faqInfo);

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.QNA_MANAGEMENT} allowedRing={2}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/faq"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.faq.title"), t("menu.faq.detail.title")]}
                    depth={2}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                <Container fluid>
                    <Row>
                        {/* main area */}
                        <div
                            style={{
                                width: '100%',
                                height: '700px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    overflow: 'auto',
                                    padding: '10px 0px 20px 0px'
                                }}
                            >
                                <Container fluid>
                                    <Row>
                                        <TitleAndInputBox
                                            title={t("common.title")}
                                            value={faqInfo.faqTitle}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            onChange={() => {}}
                                        />
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <Col
                                            style={{
                                                padding: '0px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.group")}
                                                value={faqInfo.faqGroupName}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                        <Col
                                            style={{
                                                padding: '0px',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.adminName")}
                                                value={faqInfo.faqAdminName ? faqInfo.faqAdminName : t("common.noInfo")}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                        <Col
                                            style={{
                                                padding: '0px',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.category")}
                                                value={t("language.dataKey.qna.category.name")}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <TitleAndTextArea
                                            title={t("common.questionContent")}
                                            value={faqInfo.questionContent}
                                            onChange={() => {}}
                                            isForEdit={false}
                                            isValid={true}
                                            height={"170"}
                                        />
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <TitleAndTextArea
                                            title={t("common.answerContent")}
                                            value={faqInfo.answerContent}
                                            onChange={() => {}}
                                            isForEdit={false}
                                            isValid={true}
                                            height={"170"}
                                        />
                                    </Row>
                                    <Row>
                                        <div
                                            style={{
                                                textAlign: 'right',
                                                width: '100%'
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: '#757575',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {
                                                    t("common.generatedAt")
                                                    + " "
                                                    + DataParseService.dateTimeToDate(faqInfo.generateDate)
                                                    + " , "
                                                    + t("common.lastEditedAt")
                                                    + " "
                                                    + DataParseService.dateTimeToDate(faqInfo.lastEditDate)
                                                }
                                            </span>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                            {/* bottom btn */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <div>
                                    <RenderOnRole type={"DELETE"} roleName={ImsSystemRole.QNA_MANAGEMENT} allowedRing={2} nothingOnRejected={true}>
                                        <Link
                                            id={"reset-password-btn"}
                                            onClick={async () => {
                                                await setIsDeleteConfirmOpen(true);
                                            }}
                                        >
                                            {t("button.delete")}
                                        </Link>
                                    </RenderOnRole>
                                </div>
                                <div
                                    style={{ marginLeft: 'auto' }}
                                >
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.QNA_MANAGEMENT} allowedRing={2} nothingOnRejected={true}>
                                        <Link
                                            id={"edit-btn"}
                                            to={`/faq/edit/${faqId}`}
                                        >
                                            {t("button.edit")}
                                        </Link>
                                    </RenderOnRole>
                                </div>
                                <div
                                    style={{
                                        marginLeft: '10px'
                                    }}
                                >
                                    <Link
                                        id={"save-btn"}
                                        to={"/faq"}
                                    >
                                        {t("button.complete")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Row>
                    {/* delete confirmation */}
                    <DeleteConfirmModal
                        isOpen={isDeleteConfirmOpen}
                        setIsOpen={setIsDeleteConfirmOpen}
                        isDelayed={false}
                        confirmMessage={t("language.message.deletion.confirmation", { entityName: t("language.message.deletion.depsList.faq.self") })}
                        entity={DeleteService.ValidEntities.FAQ}
                        entityName={t("language.message.deletion.depsList.faq.self")}
                        id={`faq/${faqId}`}
                        onDeleteSuccess={async () => {
                            await navigate('/faq');
                        }}
                    />
                </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default FaqDetail;
