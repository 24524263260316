import React, {useEffect, useState} from 'react';
import UserService from "../../../../services/UserService";
import axios from "axios";
import DataUtils from "../../common/utils/DataUtils";
import _ from "lodash";
import Modal from "react-modal";
import {Col, Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../../common/modal/ModalCloseButton";
import OrangeButton from "../../common/buttons/OrangeButton";
import {useTranslation} from "react-i18next";
import MenuCategoryItemView from "./modalInner/MenuCategoryItemView";
import OptionCategoryItemView from "./modalInner/OptionCategoryItemView";
import OptionCategoryAddModal from "./OptionCategoryAddModal";
import LanguageSelectBox from "../../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '650px',
        height: '700px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    franchiseMenuOptionCategoryName: "",
    franchiseMenuOptionCategoryName1: null,
    franchiseMenuOptionCategoryName2: null,
    franchiseMenuOptionCategoryName3: null,
    franchiseMenuOptionCategoryName4: null,
    franchiseMenuOptionCategoryName5: null
};

const isValidMapInit = {
    franchiseMenuOptionCategoryName: true,
    franchiseMenuOptionCategoryName1: true,
    franchiseMenuOptionCategoryName2: true,
    franchiseMenuOptionCategoryName3: true,
    franchiseMenuOptionCategoryName4: true,
    franchiseMenuOptionCategoryName5: true
};

const OptionCategoryEditModal = ({ isOpen, setIsOpen, franchiseId, onRefresh }) => {

    const { t } = useTranslation();

    /* states */
    const [optionCategoryList, setOptionCategoryList] = useState([]);

    /* request states */
    const [requestBodyList, setRequestBodyList] = useState([]);
    const [isValidMapList, setIsValidMapList] = useState([]);
    const [isEditedList, setIsEditedList] = useState([]);
    const [isValidList, setIsValidList] = useState([]);

    /* state for language */
    const targetFieldsString = `"${t("menu.franchiseMenu.optionCategoryEdit.detail.categoryName")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* modals */
    const [isOptionCategoryAddModalOpen, setIsOptionCategoryAddModalOpen] = useState(false);

    /* fetch data */
    const fetchOptionCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/franchise-option-category/franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newOptionCategoryList = res.data.item['franchiseMenuOptionCategoryList'];
            /* sort by id */
            newOptionCategoryList.sort((a, b) => DataUtils.objectSortHelper("franchiseMenuOptionCategoryId", a, b));
            setOptionCategoryList(newOptionCategoryList);
            await initializeRequestStates(newOptionCategoryList);
        } catch (e) {
            console.log(e);
            setOptionCategoryList([]);
        }
    };

    /* delete category */
    const deleteCategory = async (index) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-option-category`
                + `/franchise/${franchiseId}`
                + `/category/${optionCategoryList[index]["franchiseMenuOptionCategoryId"]}`;
            await UserService.updateToken(() => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            /* success */
            toast.success(<ToastAlertView message={t("message.deleted")} />);
            fetchOptionCategoryList();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* build states */
    const initializeRequestStates = async (newOptionCategoryList) => {
        /* init */
        await setRequestBodyList([]);
        await setIsValidMapList([]);
        await setIsEditedList([]);
        await setIsValidList([]);
        /* check length */
        const length = newOptionCategoryList.length;
        /* new lists */
        let newRequestBodyList = [];
        let newIsValidMapList = [];
        let newIsEditedList = [];
        let newIsValidList = [];
        for (let i=0 ; i<length ; i++) {
            /* requestBody */
            let newRequestBody = {};
            newRequestBody["franchiseMenuOptionCategoryName"] = newOptionCategoryList[i]["franchiseMenuOptionCategoryName"];
            newRequestBody["franchiseMenuOptionCategoryName1"] = newOptionCategoryList[i]["franchiseMenuOptionCategoryName1"];
            newRequestBody["franchiseMenuOptionCategoryName2"] = newOptionCategoryList[i]["franchiseMenuOptionCategoryName2"];
            newRequestBody["franchiseMenuOptionCategoryName3"] = newOptionCategoryList[i]["franchiseMenuOptionCategoryName3"];
            newRequestBody["franchiseMenuOptionCategoryName4"] = newOptionCategoryList[i]["franchiseMenuOptionCategoryName4"];
            newRequestBody["franchiseMenuOptionCategoryName5"] = newOptionCategoryList[i]["franchiseMenuOptionCategoryName5"];
            newRequestBodyList.push(newRequestBody);
            /* isValidMap */
            newIsValidMapList.push(_.cloneDeep(isValidMapInit));
            /* isValid */
            newIsValidList.push(true);
            /* isEdited */
            newIsEditedList.push(false);
        }
        /* set */
        await setRequestBodyList(newRequestBodyList);
        await setIsValidMapList(newIsValidMapList);
        await setIsEditedList(newIsEditedList);
        await setIsValidList(newIsValidList);
    };

    /* effects */
    useEffect(() => {
        fetchOptionCategoryList();
    }, [isOpen]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchiseMenu.optionCategoryEdit.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    {/* option category add button */}
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end'
                            }}
                        >
                            {/* language selection */}
                            <LanguageSelectBox
                                title={t("language.selectBox.title.menuOptionCategory")}
                                languageMetadataList={languageMetadataList}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}
                                setIsHighlightOn={setIsHighlightOn}
                                targetFieldString={targetFieldsString}
                            />
                            <div
                                style={{
                                    marginLeft: 'auto',
                                    width: '200px'
                                }}
                            >
                                <OrangeButton
                                    onClickCallback={() => setIsOptionCategoryAddModalOpen(true)}
                                    isEnabled={true}
                                    label={t("menu.franchiseMenu.optionCategoryEdit.button.addOptionCategory")}
                                />
                            </div>
                        </div>
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '550px',
                            padding: '5px',
                            overflow: 'auto',
                            borderRadius: '5px'
                        }}
                    >
                        {/* category items */}
                        {
                            (optionCategoryList.length > 0) &&
                            optionCategoryList.map((optionCategoryInfo, index) => {
                                return (
                                    <OptionCategoryItemView
                                        key={optionCategoryList['franchiseMenuOptionCategoryId'] + optionCategoryList.length}
                                        optionCategoryInfo={optionCategoryInfo}
                                        updateRequestBody={(key, value) => DataUtils.updateObjectArrayHelper(setRequestBodyList, index, key, value)}
                                        updateIsValidMap={(key, value) => DataUtils.updateObjectArrayHelper(setIsValidMapList, index, key, value)}
                                        updateIsValid={(value) => DataUtils.updateArrayHelper(setIsValidList, index, value)}
                                        updateIsEdited={(value) => DataUtils.updateArrayHelper(setIsEditedList, index, value)}
                                        requestBody={requestBodyList[index]}
                                        isValidMap={isValidMapList[index]}
                                        isValid={isValidList[index]}
                                        isEdited={isEditedList[index]}
                                        refreshCategoryList={() => {
                                            fetchOptionCategoryList();
                                            onRefresh();
                                        }}
                                        onDeleteCallback={() => deleteCategory(index)}
                                        currentLang={currentLang}
                                        isHighlightOn={isHighlightOn}
                                    />
                                );
                            })
                        }
                        {
                            (optionCategoryList.length === 0) &&
                            <div
                                style={{
                                    width: '100%',
                                    padding: '30px',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    color: '#757575'
                                }}
                            >
                                {t("menu.franchiseMenu.optionCategoryEdit.message.noOptionCategory")}
                            </div>
                        }
                    </div>
                </Row>
            </Container>
            {/* option category add modal */}
            <OptionCategoryAddModal
                isOpen={isOptionCategoryAddModalOpen}
                setIsOpen={setIsOptionCategoryAddModalOpen}
                onRefresh={fetchOptionCategoryList}
                franchiseId={franchiseId}
            />
        </Modal>
    );
};

export default OptionCategoryEditModal;