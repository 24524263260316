
/* update the state object */
import {value} from "lodash/seq";

const updateHelper = (setState, key, value) => {
    setState(prevObj => {
       let newObj = {...prevObj};
       newObj[key] = value;
       return newObj;
    });
};

const updateObjectArrayHelper = (setState, index, key, value) => {
    setState(prevArray => {
        let newArray = [...prevArray];
        newArray[index][key] = value;
        return newArray;
    });
};

const updateArrayHelper = (setState, index, value) => {
    setState(prevArray => {
       let newArray = [...prevArray];
       newArray[index] = value;
       return newArray;
    });
};

const objectSortHelper = (key, objL, objR) => {
    let nameA = objL[key].toUpperCase();
    let nameB = objR[key].toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
};

const hasIdInPath = (pathname, id) => {
    return pathname.split("/").includes(id);
};

const hasValuesInPath = (pathname, valueList) => {
    for (let i=0 ; i<valueList.length ; i++) {
        if (pathname.split("/").includes(valueList[i])) {
            return true;
        }
    }
    return false;
};
const extractIdsFromObjList = (key, objList) => {
    let newIdList = [];
    objList.map(obj => {
        newIdList.push(obj[key]);
    });
    return newIdList;
};

const isEmptyStr = (value) => {
    if (typeof value === "undefined") {
        return true;
    } else if (value == null) {
        return true;
    } else {
        return (value === "");
    }
};

const isValidNumStr = (value) => {
    const valueParsed = parseFloat(value);
    if (isNaN(valueParsed)) {
        return false;
    } else {
        return true;
    }
};

const formatObjArrayToObj = (objArray) => {
    let newObj = {};
    for (let i=0 ; i < objArray.length ; i++) {
        const key = Object.keys(objArray[i])[0];
        newObj[key] = objArray[i][key];
    }
    return newObj;
};

const isObjKeyExistInObjArray = (objArray, key) => {
    for (let i=0 ; i<objArray.length ; i++) {
        if (Object.keys(objArray[i]).includes(key)) {
            return true;
        }
    }
    return false;
};

const DataUtils = {
    updateHelper,
    updateObjectArrayHelper,
    updateArrayHelper,
    objectSortHelper,
    hasIdInPath,
    hasValuesInPath,
    extractIdsFromObjList,
    isEmptyStr,
    isValidNumStr,
    formatObjArrayToObj,
    isObjKeyExistInObjArray
};

export default DataUtils;