import React from 'react';
import styled from "styled-components";
import {CiSquareMinus, CiSquarePlus} from "react-icons/ci";

const BranchItem = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  padding: 10px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  color: #fc7242;

  &:hover {
    -webkit-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    -moz-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    color: #fc7242;
  }
`;

const BranchName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #757575;
  margin-right: 15px;
`;

const Owner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  font-size: 14px;
  border: 1px solid #fc7242;
  color: #fc7242;
  background-color: #fcf9ed;
  border-radius: 5px;
  margin-right: 15px;
`;

const Address = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  font-size: 14px;
  border: 1px solid #757575;
  color: #757575;
  border-radius: 5px;
`;

const DeleteButton = styled.button`
  margin-left: auto;
  border: none;
  text-align: center;
  background: none;
  transition: all ease-in-out 0.2s;
  color: #e62e2e;

  &:hover {
    color: red;
  }
  
  &:focus {
    outline: none;
  }
`;

const DeleteButtonDisabled = styled.button`
  margin-left: auto;
  border: none;
  text-align: center;
  background: none;
  color: #ebebeb;
  
  &:focus {
    outline: none;
  }
`;


const InBranch = ({ branchName, masterName, masterId, address, onClick, isEnabled }) => {

    return (
        <BranchItem>
            <BranchName>
                {branchName}
            </BranchName>
            <Owner>
                {masterName}
            </Owner>
            <Address>
                {address}
            </Address>
            {
                isEnabled ?
                    <DeleteButton
                        onClick={onClick}
                    >
                        <CiSquareMinus
                            size={"25"}
                        />
                    </DeleteButton>
                    :
                    <DeleteButtonDisabled>
                        <CiSquareMinus
                            size={"25"}
                            color={"#c2c2c2"}
                        />
                    </DeleteButtonDisabled>
            }
        </BranchItem>
    );
};

export default InBranch;