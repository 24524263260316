import React, {useEffect, useRef, useState} from 'react';
import {Image} from "react-bootstrap";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import UserService from "../../../../services/UserService";

const TransButton = styled.button`
  background: none;
  border: none;
  z-index: 9998;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  &:focus {
    outline: none;
  }
`;

const DropDownItemButton = styled.button`
  background: none;
  border: none;
  z-index: 9998;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${(props) => props.color ? props.color : "#757575"};
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: ${(props) => props.hoverColor ? props.hoverColor : "black"};
  }
`;

const DropDownContainer = styled.ul`
  position: absolute;
  right: 0px;
  top: 50px;
  width: 180px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.38);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.38);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.38);
  z-index: 9998;
`;

const DropDownItem = styled.li`
  padding: 7px 0px;
  text-align: center;
`;

const ProfileTop = () => {

    const { t } = useTranslation();
    const areaRef = useRef();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const NavItem = (props) => {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                }}
            >
                <TransButton className="icon-button" onClick={() => setOpen(!open)}>
                    {props.icon}
                </TransButton>
                {open && props.children}
            </div>
        );
    };

    useEffect(() => {
        const handleFocus = (e) => {
            if (areaRef.current && !areaRef.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mouseup", handleFocus);
        return () => { document.removeEventListener("mouseup", handleFocus); }
    }, [areaRef]);

    return (
        <NavItem
            icon={
                <Image
                    src={
                        window.sessionStorage.getItem("ims_imageUrl") ?
                            `${window.sessionStorage.getItem("ims_imageUrl")}`
                            :
                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    style={{
                        width: '25px',
                        height: '25px',
                        border: '1px solid #c0c0c0'
                    }}
                    onError={(e) => e.currentTarget.src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}
                    roundedCircle
                />
            }
        >
            <DropDownContainer
                ref={areaRef}
            >
                <DropDownItem>
                    <DropDownItemButton
                        onClick={() => {
                            setOpen(false);
                            navigate('/my-page');
                        }}
                    >
                        {t("button.myPage")}
                    </DropDownItemButton>
                </DropDownItem>
                <DropDownItem>
                    <DropDownItemButton
                        onClick={() => {
                            setOpen(false);
                            navigate('/schedule');
                        }}
                    >
                        {t("menu.schedule.title")}
                    </DropDownItemButton>
                </DropDownItem>
                <DropDownItem>
                    <DropDownItemButton
                        color={"#ff9999"}
                        hoverColor={"#ff1a1a"}
                        onClick={() => UserService.doLogout()}
                    >
                        {t("button.logout")}
                    </DropDownItemButton>
                </DropDownItem>
            </DropDownContainer>
        </NavItem>
    );
};

export default ProfileTop;