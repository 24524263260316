import UserService from "./UserService";
import ImsSystemRole from "../auth/roles/ImsSystemRole";

/** private methods */
const _parseRoleString = (str) => {
    if (str == null || str === '' || typeof str == "undefined") {
        return [];
    } else {
        return JSON.parse(str);
    }
}

const _hasAuthByRoleNameAndCoverage = (coverage, roleName, type) => {
    let roleList = [];
    if (coverage === "group") {
        if (typeof window.sessionStorage.getItem("ims_group_roleList") != "undefined") {
            roleList = _parseRoleString(window.sessionStorage.getItem("ims_group_roleList"));
        } else {
            return false;
        }
    } else if (coverage === "company") {
        if (typeof window.sessionStorage.getItem("ims_company_roleList") != "undefined") {
            roleList = _parseRoleString(window.sessionStorage.getItem("ims_company_roleList"));
        } else {
            return false;
        }
    } else { /* admin */
        if (typeof window.sessionStorage.getItem("ims_roleList") != "undefined") {
            roleList = _parseRoleString(window.sessionStorage.getItem("ims_roleList"));
        } else {
            return false;
        }
    }
    for (const role of roleList) {
        if (role['roleName'] === roleName) {
            if (type === "GET") {
                return ((role['accessCode'] & 1) === 1);
            } else if (type === "PUT") {
                return ((role['accessCode'] & 2) === 2);
            } else if (type === "POST") {
                return ((role['accessCode'] & 4) === 4);
            } else if (type === "DELETE") {
                return ((role['accessCode'] & 8) === 8);
            } else {
                return false;
            }
        }
    }
    return false;
}


/** public methods */
const hasKeycloakRole= (roles) => {
    return UserService.hasKeycloakRole(roles);
}

const hasAuthByRoleNameAndType = (roleName, roleType) => {
    /* get auth type */
    let authType = (window.sessionStorage.getItem("ims_authType") != null) ? parseInt(window.sessionStorage.getItem("ims_authType")) : -1;

    if (authType === -1) {
        return false;
    }
    /* check auth */
    if (authType === 0) {
        return true;
    } else if (authType === 1) {
        /* for the group */
        return _hasAuthByRoleNameAndCoverage("group", roleName, roleType);
    } else { /* authType === 2 */
        if (_hasAuthByRoleNameAndCoverage("admin", ImsSystemRole.MANAGER_OF_COMPANY, roleType)) {
            /* for the company */
            return _hasAuthByRoleNameAndCoverage("company", roleName, roleType);
        } else {
            return _hasAuthByRoleNameAndCoverage("admin", roleName, roleType);
        }
    }
};

/** authType < 2 */
/** True for "company_management", "group_management", or "ims_system_management" */
const hasAuthForOverRingTwo = () => {
    if (typeof window.sessionStorage.getItem("ims_authType") != "undefined") {
        return parseInt(window.sessionStorage.getItem("ims_authType")) < 2;
    }
    return false;
};

/** authType < 1 */
/** True for "group_management" or "ims_system_management" */
const hasAuthForOverRingOne = () => {
    if (typeof window.sessionStorage.getItem("ims_authType") != "undefined") {
        return parseInt(window.sessionStorage.getItem("ims_authType")) < 1;
    }
    return false;
};

/** True for "ims_system_management" */
const hasSuperMasterAuth = () => {
    if (typeof window.sessionStorage.getItem("ims_isSuperMaster") != "undefined") {
        return JSON.parse(window.sessionStorage.getItem("ims_isSuperMaster"));
    }
    return false;
};

const AuthService = {
    hasKeycloakRole,
    hasAuthByRoleNameAndType,
    hasAuthForOverRingOne,
    hasAuthForOverRingTwo,
    hasSuperMasterAuth
};

export default AuthService;