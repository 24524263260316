import React, {useEffect, useState} from 'react';
import RenderOnRole from "../../auth/RenderOnRole";
import {Col, Container, Row, Tooltip} from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import DataCompareService from "../../services/DataCompareService";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import axios from "axios";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const requestBodySchema = {
    "adminId": {
        "type": "string",
        "minLength": 5,
        "maxLength": 20,
        "pattern": "^[A-Za-z]{1}[A-Za-z0-9_-]{4,19}$"
    },
    "adminName": {
        "type": "string",
        "minLength": 2,
        "maxLength": 100,
        "pattern": "^[가-힣]{1}[가-힣\\s]{0,15}[가-힣]{1}$|^[a-zA-Z]{1}[a-zA-Z\\s]{0,98}[a-zA-Z]{1}$"
    },
    "adminEmail": {
        "type": "string",
        "minLength": 6,
        "maxLength": 40,
        "pattern": "^[0-9a-zA-Z-_]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\\.[a-zA-Z]+$"
    },
    "adminPhone": {
        "type" : "string"
    },
    "adminPassword": {
        "type" : "string",
        "pattern" : "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&]{8,20}$"
    },
    "adminDepartmentId": {
        "type" : "string"
    },
    "adminGroupId": {
        "type" : "string"
    },
    "adminCompanyId": {
        "type" : "string"
    }
}

const requestBodyInit = {
    adminId: "",
    adminName: "",
    adminEmail: "",
    adminPhone: "",
    adminPassword: "",
    adminDepartmentId: "",
    adminGroupId: "",
    adminCompanyId: ""
}

const isValidMapInit = {
    adminId: false,
    adminName: false,
    adminEmail: false,
    adminPhone: false,
    adminPassword: false,
    adminDepartmentId: true,
    adminGroupId: true,
    adminCompanyId: true
}


const StaffAdd = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [passwordVerify, setPasswordVerify] = useState("");
    const [isPasswordVerify, setIsPasswordVerify] = useState(false);

    /* group, company, department list */
    const [groupList, setGroupList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);

    /* fetch */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setGroupList(res.data.item.groupList);
            handleAdminGroupIdChange(res.data.item.groupList[0].groupId);
        } catch (e) {
            console.log(e);
        }
    }

    const fetchCompanyList = async () => {
        if (requestBody["adminGroupId"] === "") {
            return;
        }
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group/${requestBody["adminGroupId"]}/company`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setCompanyList(res.data.item.companyList);
            handleAdminCompanyIdChange(res.data.item.companyList[0].companyId);
        } catch (e) {
            console.log(e);
        }
    }

    const fetchDepartmentList = async () => {
        if (requestBody["adminCompanyId"] === "") {
            return;
        }
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company/${requestBody["adminCompanyId"]}/department`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setDepartmentList(res.data.item.departmentList);
            handleAdminDepartmentIdChange(res.data.item.departmentList[0].adminDepartmentId);
        } catch (e) {
            console.log(e);
        }
    }

    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    }

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    }

    const tooltip = (
        <Tooltip id="tooltip">
            <span>
                {t("message.passwordRule")}
            </span>
        </Tooltip>
    );

    useEffect(() => {
        fetchGroupList();
    }, []);

    useEffect(() => {
        fetchCompanyList();
    }, [requestBody["adminGroupId"]]);

    useEffect(() => {
        fetchDepartmentList();
    }, [requestBody["adminCompanyId"]]);

    const handleAdminIdInput = (value) => {
        updateRequestBody("adminId", value);
    }

    const handleAdminNameInput = (value) => {
        updateRequestBody("adminName", value);
    }

    const handleAdminEmailInput = (value) => {
        updateRequestBody("adminEmail", value);
    }

    const handleAdminPhoneInput = (value) => {
        updateRequestBody("adminPhone", value);
    }

    const handleAdminPasswordInput = (value) => {
        updateRequestBody("adminPassword", value);
    }

    const handleAdminPasswordVerifyInput = (value) => {
        setPasswordVerify(value);
    }

    const handleAdminGroupIdChange = (value) => {
        updateRequestBody("adminGroupId", value);
    }

    const handleAdminCompanyIdChange = (value) => {
        updateRequestBody("adminCompanyId", value);
    }

    const handleAdminDepartmentIdChange = (value) => {
        updateRequestBody("adminDepartmentId", value);
    }

    /* validation */
    const validateAdminId = async () => {
        if (requestBody["adminId"] === "") {
            return;
        }
        try {
            let validity =
                DataCompareService.regexValidator(requestBody["adminId"], "^[A-Za-z]{1}[A-Za-z0-9_-]{4,19}$")
                && (requestBody["adminId"].length >= 5)
                && (requestBody["adminId"].length <= 20)
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/check/id/duplicate?target=${requestBody["adminId"]}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            const isDuplicate = res.data.item.result;
            updateIsValidMap("adminId", validity && !isDuplicate);
        } catch (e) {
            console.log(e);
        }
    }

    const validateAdminName = () => {
        if (requestBody["adminName"] === "") {
            return;
        }
        let validity = DataCompareService.regexValidator(requestBody["adminName"], "^[가-힣]{1}[가-힣\\s]{0,15}[가-힣]{1}$|^[a-zA-Z]{1}[a-zA-Z\\s]{0,98}[a-zA-Z]{1}$");
        updateIsValidMap("adminName", validity);
    }

    const validateAdminEmail = async () => {
        if (requestBody["adminEmail"] === "") {
            return;
        }
        try {
            let validity =
                DataCompareService.regexValidator(requestBody["adminEmail"], "^[0-9a-zA-Z-_]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\\.[a-zA-Z]+$")
                && (requestBody["adminEmail"].length >= 6)
                && (requestBody["adminEmail"].length <= 40)
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/check/email/duplicate?target=${requestBody["adminEmail"]}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            const isDuplicate = res.data.item.result;
            updateIsValidMap("adminEmail", validity && !isDuplicate);
        } catch (e) {
            console.log(e);
        }
    }

    const validateAdminPhone = async () => {
        if (requestBody["adminPhone"] === "") {
            return;
        }
        try {
            let validity = true;
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/check/phone/duplicate?target=${requestBody["adminPhone"]}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            const isDuplicate = res.data.item.result;
            updateIsValidMap("adminPhone", validity && !isDuplicate);
        } catch (e) {
            console.log(e);
        }
    }

    const validateAdminPassword = () => {
        if (requestBody["adminPassword"] === "") {
            return;
        }
        let validity = DataCompareService.regexValidator(requestBody["adminPassword"], "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&]{8,20}$");
        updateIsValidMap("adminPassword", validity);
    }

    const postAdmin = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin`;
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl ,
                JSON.stringify(requestBody),
                {
                    headers: {
                        "Authorization" : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate(`/staff`);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        setIsPasswordVerify(passwordVerify === requestBody["adminPassword"]);
    }, [passwordVerify]);

    useEffect(() => {
        validateAdminId();
    }, [requestBody["adminId"]]);

    useEffect(() => {
        validateAdminName();
    }, [requestBody["adminName"]]);

    useEffect(() => {
        validateAdminEmail();
    }, [requestBody["adminEmail"]]);

    useEffect(() => {
        validateAdminPhone();
    }, [requestBody["adminPhone"]]);

    useEffect(() => {
        validateAdminPassword();
    }, [requestBody["adminPassword"]]);

    return (
        <RenderOnRole type={"POST"} roleName={ImsSystemRole.STAFF_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/staff"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.staff.title"), t("menu.staff.add.title")]}
                    depth={2}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                    <Container fluid>
                        <Row>
                            {/* main area */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '700px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '650px'
                                    }}
                                >
                                    <Container fluid>
                                        <Row>
                                            <TitleAndInputBox
                                                title={t("common.id")}
                                                value={requestBody["adminId"]}
                                                onChange={handleAdminIdInput}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["adminId"]}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '15px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.name")}
                                                value={requestBody["adminName"]}
                                                onChange={handleAdminNameInput}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["adminName"]}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '15px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.email")}
                                                value={requestBody["adminEmail"]}
                                                onChange={handleAdminEmailInput}
                                                isForEdit={true}
                                                type={"email"}
                                                isValid={isValidMap["adminEmail"]}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '15px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.phone")}
                                                value={requestBody["adminPhone"]}
                                                onChange={handleAdminPhoneInput}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["adminPhone"]}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '15px'
                                            }}
                                        >
                                            <OverlayTrigger placement="left" overlay={tooltip}>
                                                <button
                                                    style={{
                                                        outline: 'none',
                                                        border: 'none',
                                                        background: 'none',
                                                        padding: '0px'
                                                    }}
                                                >
                                                    <AiOutlineQuestionCircle
                                                        size={20}
                                                        style={{
                                                            color: '#c2c2c2',
                                                            marginLeft: '10px'
                                                        }}
                                                    />
                                                </button>
                                            </OverlayTrigger>
                                        </Row>
                                        <Row>
                                            <Col
                                                style={{ padding: '0px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.password")}
                                                    value={requestBody["adminPassword"]}
                                                    onChange={handleAdminPasswordInput}
                                                    isForEdit={true}
                                                    type={"password"}
                                                    isValid={isValidMap["adminPassword"]}
                                                />
                                            </Col>
                                            <Col
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.passwordVerify")}
                                                    value={passwordVerify}
                                                    onChange={handleAdminPasswordVerifyInput}
                                                    isForEdit={true}
                                                    type={"password"}
                                                    isValid={isPasswordVerify}
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '15px'
                                            }}
                                        >
                                            <Col
                                                style={{ padding: '0px' }}
                                            >
                                                <TitleAndSelectBox
                                                    title={t("common.group")}
                                                    innerValue={requestBody["adminGroupId"]}
                                                    valueList={groupList}
                                                    valueSelector={"groupId"}
                                                    viewSelector={"groupName"}
                                                    onChange={handleAdminGroupIdChange}
                                                />
                                            </Col>
                                            <Col
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndSelectBox
                                                    title={t("common.company")}
                                                    innerValue={requestBody["adminCompanyId"]}
                                                    valueList={companyList}
                                                    valueSelector={"companyId"}
                                                    viewSelector={"companyName"}
                                                    onChange={handleAdminCompanyIdChange}
                                                />
                                            </Col>
                                            <Col
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndSelectBox
                                                    title={t("common.department")}
                                                    innerValue={requestBody["adminDepartmentId"]}
                                                    valueList={departmentList}
                                                    valueSelector={"adminDepartmentId"}
                                                    viewSelector={"adminDepartmentName"}
                                                    onChange={handleAdminDepartmentIdChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50px'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '50px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'right'
                                        }}
                                    >
                                        <div
                                            style={{ marginRight: '10px' }}
                                        >
                                            <Link
                                                id={"edit-btn"}
                                                to={`/staff`}
                                            >
                                                {t("button.cancel")}
                                            </Link>
                                        </div>
                                        <div>
                                            {
                                                isValid && isPasswordVerify
                                                    ?
                                                    <Link
                                                        id={"save-btn"}
                                                        onClick={() => postAdmin()}
                                                    >
                                                        {t("button.save")}
                                                    </Link>
                                                    :
                                                    <Link
                                                        id={"save-btn-disabled"}
                                                    >
                                                        {t("button.save")}
                                                    </Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default StaffAdd;