import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {Col, Container, Row} from "react-bootstrap";
import CalendarView from "./components/CalendarView";
import DateUtils from "./utils/DateUtils";
import axios from "axios";
import UserService from "../../services/UserService";
import { LuCalendarDays, LuCalendarClock, LuCalendarCheck2, LuCalendarPlus, LuCalendarX2 } from "react-icons/lu";
import styled from "styled-components";
import ScheduleItemView from "./components/item/ScheduleItemView";
import OverlayToolTip from "../menu/common/toolTip/OverlayToolTip";
import ScheduleAddModal from "./components/modals/ScheduleAddModal";
import DataUtils from "../menu/common/utils/DataUtils";

const ScheduleBlockTitle = styled.div`
  width: 100%;
  padding-left: 15px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${(props) => props.color ? props.color : "#757575"};
  font-size: 14px;
  margin-top: 10px;
`;

const MessageView = styled.div`
  width: 100%;
  padding: 30px;
  text-align: center;
  color: #757575;
  font-size: 14px;
`;

const ButtonMini = styled.button`
  background: ${props => props.color};
  padding: 7px;
  border-radius: 8px;
  border: none;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
`;

const colorMap = {
    0 : "#c2c2c2", /* canceled */
    1 : "#f87171", /* planned */
    2 : "#3399ff", /* done */
};

const ScheduleManagement = () => {

    /* translation */
    const { t, i18n } = useTranslation();

    /* states */
    const [selectedDate, setSelectedDate] = useState(DateUtils.formatDate(new Date(), '-'));
    const [scheduleList, setScheduleList] = useState([]);
    const [formattedScheduleData, setFormattedScheduleData] = useState({});
    const [currentViewYear, setCurrentViewYear] = useState(DateUtils.getYearAndMonth(new Date())['year']);
    const [currentViewMonth, setCurrentViewMonth] = useState(DateUtils.getYearAndMonth(new Date())['month']);

    /* add modal */
    const [isScheduleAddModalOpen, setIsScheduleAddModalOpen] = useState(false);

    /* fetch schedule */
    const fetchScheduleList = async (year, month) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/schedule`
                + `?year=${year}&month=${month}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            let newScheduleList = res.data.item['scheduleList'];
            newScheduleList.sort((ol, or) => {
                return DataUtils.objectSortHelper('scheduleTitle', ol, or);
            });
            await setScheduleList(newScheduleList);
        } catch (e) {
            console.log(e);
            await setScheduleList([]);
        }
    };

    /* effects */
    useEffect(() => {
        /* fetch schedule */
        fetchScheduleList(currentViewYear, currentViewMonth);
    }, [currentViewYear, currentViewMonth]);

    useEffect(() => {
        setFormattedScheduleData(DateUtils.scheduleListToObj(scheduleList));
    }, [scheduleList]);

    return (
        <>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.schedule.title")]}
                    depth={1}
                />
            </div>
            <div
                className={"contentContainer"}
            >
                <Container fluid>
                    <Row>
                        <Col
                            md={"8"}
                            style={{
                                padding: '0px'
                            }}
                        >
                            {/* calendar view */}
                            <div
                                className={"contentInner"}
                                style={{
                                    height: '802px'
                                }}
                            >
                                <CalendarView
                                    calWidth={"100%"}
                                    calHeight={"500px"}
                                    isForSmall={false}
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                    onMonthChange={async (newDate) => {
                                        await setCurrentViewYear(DateUtils.getYearAndMonth(newDate)['year']);
                                        await setCurrentViewMonth(DateUtils.getYearAndMonth(newDate)['month']);
                                        await setSelectedDate(DateUtils.formatDate(newDate, '-'));
                                    }}
                                    scheduleData={formattedScheduleData}
                                />
                            </div>
                        </Col>
                        <Col
                            md={"4"}
                            style={{
                                padding: '0px',
                                paddingLeft: '10px'
                            }}
                        >
                            {/* selected date */}
                            <div
                                className={"contentInner"}
                                style={{
                                    height: '50px',
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    color: '#757575',
                                    fontSize: '15px'
                                }}
                            >
                                {DateUtils.craftLocaleString(selectedDate, i18n.language)}
                                <OverlayToolTip direction={"bottom"} text={t("menu.schedule.button.add")}>
                                    <ButtonMini
                                        color={"#fc7242"}
                                        style={{
                                            marginLeft: 'auto'
                                        }}
                                        onClick={() => setIsScheduleAddModalOpen(true)}
                                    >
                                        <LuCalendarPlus
                                            size={"20"}
                                            color={"white"}
                                            style={{
                                                position: 'relative',
                                                top: '-1'
                                            }}
                                        />
                                    </ButtonMini>
                                </OverlayToolTip>
                            </div>
                            {/* planned items */}
                            <ScheduleBlockTitle
                                color={colorMap[1]}
                            >
                                <LuCalendarClock
                                    size={"20"}
                                    style={{
                                        marginRight: '15px'
                                    }}
                                />
                                {t("language.enums.scheduleStatusStr.1") + ((formattedScheduleData[selectedDate] != null) && (formattedScheduleData[selectedDate]['countOfPlanned'] > 0) ? ` (${formattedScheduleData[selectedDate]['countOfPlanned']})` : ``)}
                            </ScheduleBlockTitle>
                            <div
                                className={"contentInner"}
                                style={{
                                    marginTop: '5px',
                                    height: '230px',
                                    overflow: 'auto',
                                    padding: '10px'
                                }}
                            >
                                {
                                    ((formattedScheduleData[selectedDate] == null)
                                        || (formattedScheduleData[selectedDate]['countOfPlanned'] === 0)) &&
                                    <MessageView>
                                        {t("menu.schedule.messages.noSchedule")}
                                    </MessageView>
                                }
                                {
                                    (formattedScheduleData[selectedDate] != null) &&
                                    (formattedScheduleData[selectedDate]['countOfPlanned'] > 0) &&
                                    formattedScheduleData[selectedDate]['plannedSchedules'].map(scheduleInfo => {
                                        return (
                                            <ScheduleItemView
                                                key={scheduleInfo['scheduleId']}
                                                scheduleId={scheduleInfo['scheduleId']}
                                                title={scheduleInfo['scheduleTitle']}
                                                content={scheduleInfo['scheduleContent']}
                                                status={1}
                                                refreshData={() => fetchScheduleList(currentViewYear, currentViewMonth)}
                                                date={selectedDate}
                                            />
                                        );
                                    })
                                }
                            </div>
                            {/* done items */}
                            <ScheduleBlockTitle
                                color={colorMap[2]}
                            >
                                <LuCalendarCheck2
                                    size={"20"}
                                    style={{
                                        marginRight: '15px'
                                    }}
                                />
                                {t("language.enums.scheduleStatusStr.2") + ((formattedScheduleData[selectedDate] != null) && (formattedScheduleData[selectedDate]['countOfDone'] > 0) ? ` (${formattedScheduleData[selectedDate]['countOfDone']})` : ``)}
                            </ScheduleBlockTitle>
                            <div
                                className={"contentInner"}
                                style={{
                                    marginTop: '5px',
                                    height: '200px',
                                    overflow: 'auto',
                                    padding: '10px'
                                }}
                            >
                                {
                                    ((formattedScheduleData[selectedDate] == null)
                                        || (formattedScheduleData[selectedDate]['countOfDone'] === 0)) &&
                                    <MessageView>
                                        {t("menu.schedule.messages.noSchedule")}
                                    </MessageView>
                                }
                                {
                                    (formattedScheduleData[selectedDate] != null) &&
                                    (formattedScheduleData[selectedDate]['countOfDone'] > 0) &&
                                    formattedScheduleData[selectedDate]['doneSchedules'].map(scheduleInfo => {
                                        return (
                                            <ScheduleItemView
                                                key={scheduleInfo['scheduleId']}
                                                scheduleId={scheduleInfo['scheduleId']}
                                                title={scheduleInfo['scheduleTitle']}
                                                content={scheduleInfo['scheduleContent']}
                                                status={2}
                                                refreshData={() => fetchScheduleList(currentViewYear, currentViewMonth)}
                                                date={selectedDate}
                                            />
                                        );
                                    })
                                }
                            </div>
                            {/* canceled items */}
                            <ScheduleBlockTitle
                                color={colorMap[0]}
                            >
                                <LuCalendarX2
                                    size={"20"}
                                    style={{
                                        marginRight: '15px'
                                    }}
                                />
                                {t("language.enums.scheduleStatusStr.0") + ((formattedScheduleData[selectedDate] != null) && (formattedScheduleData[selectedDate]['countOfCanceled'] > 0) ? ` (${formattedScheduleData[selectedDate]['countOfCanceled']})` : ``)}
                            </ScheduleBlockTitle>
                            <div
                                className={"contentInner"}
                                style={{
                                    marginTop: '5px',
                                    height: '200px',
                                    overflow: 'auto',
                                    padding: '10px'
                                }}
                            >
                                {
                                    ((formattedScheduleData[selectedDate] == null)
                                        || (formattedScheduleData[selectedDate]['countOfCanceled'] === 0)) &&
                                    <MessageView>
                                        {t("menu.schedule.messages.noSchedule")}
                                    </MessageView>
                                }
                                {
                                    (formattedScheduleData[selectedDate] != null) &&
                                    (formattedScheduleData[selectedDate]['countOfCanceled'] > 0) &&
                                    formattedScheduleData[selectedDate]['canceledSchedules'].map(scheduleInfo => {
                                        return (
                                            <ScheduleItemView
                                                key={scheduleInfo.toString()}
                                                scheduleId={scheduleInfo['scheduleId']}
                                                title={scheduleInfo['scheduleTitle']}
                                                content={scheduleInfo['scheduleContent']}
                                                status={0}
                                                refreshData={() => fetchScheduleList(currentViewYear, currentViewMonth)}
                                                date={selectedDate}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ScheduleAddModal
                isOpen={isScheduleAddModalOpen}
                setIsOpen={setIsScheduleAddModalOpen}
                dateStr={selectedDate}
                onRefresh={() => fetchScheduleList(currentViewYear, currentViewMonth)}
            />
        </>
    );
};

export default ScheduleManagement;