import React from 'react';
import {useTranslation} from "react-i18next";

const AbnormalApproach = () => {
    const { t } = useTranslation();

    return (
        <div
            style={{
                width: '100%',
                height: '70%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
        >
            <div
                style={{
                    width: '100%',
                    textAlign: 'center'
                }}
            >
                <span
                    style={{
                        fontSize: '20px',
                        fontWeight: 'bold'
                    }}
                >
                    {t("menu.boothControl.infoMessage.serviceUnavailable")}
                </span>
            </div>
            <div
                style={{
                    marginTop: '15px',
                    width: '100%',
                    textAlign: 'center'
                }}
            >
                <span
                    style={{
                        color: '#757575'
                    }}
                >
                    {t("menu.boothControl.infoMessage.abnormalApproachMessage")}
                </span>
            </div>
        </div>
    );
};

export default AbnormalApproach;