import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import DivWithTitle from "./DivWithTitle";
import {Container, Row} from "react-bootstrap";
import styled from "styled-components";
import BoothVersionItemView from "./boothVersion/BoothVersionItemView";
import FranchiseBoothVersionEditModal from "../modals/FranchiseBoothVersionEditModal";

const EditButton = styled.button`
  border: 1px solid #fc7242;
  color: #fc7242;
  background-color: white;
  font-size: 14px;
  padding: 5px 15px;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  
  &:hover {
    background-color: #FFF1EC;
  }
  
  &:focus {
    outline: none;
  }
`;

const FranchiseBoothVersions = ({ onRefresh, dataList }) => {

    const { t } = useTranslation();
    const location = useLocation();

    /* states */
    const [isFranchiseBoothVersionEditModalOpen, setIsFranchiseBoothVersionEditModalOpen] = useState(false);

    return (
        <DivWithTitle
            title={t("menu.franchise.detail.boothVersionInfo.title")}
        >
            <Container fluid>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            height: '195px',
                            overflow: 'auto',
                            border: '1px solid #ebebeb',
                            borderRadius: '5px',
                            padding: '10px'
                        }}
                    >
                        {
                            (dataList.length > 0) &&
                            dataList.map(boothVersionMapping => {
                                return (
                                    <BoothVersionItemView
                                        key={boothVersionMapping['franchiseBoothVersionId']}
                                        boothName={boothVersionMapping['boothName']}
                                        boothVersion={boothVersionMapping['boothVersion']}
                                        mappedAt={boothVersionMapping['mappedAt']}
                                    />
                                );
                            })
                        }
                        {
                            (dataList.length === 0) &&
                            <div
                                style={{
                                    width: '100%',
                                    paddingTop: '40px',
                                    color: '#757575',
                                    fontSize: '14px'
                                }}
                            >
                                {t("menu.franchise.detail.boothVersionInfo.noBoothVersion")}
                            </div>
                        }
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'right'
                        }}
                    >
                        <EditButton
                            onClick={() => setIsFranchiseBoothVersionEditModalOpen(true)}
                        >
                            {t("menu.franchise.detail.boothVersionInfo.edit")}
                        </EditButton>
                    </div>
                </Row>
            </Container>
            {/* modal */}
            <FranchiseBoothVersionEditModal
                isOpen={isFranchiseBoothVersionEditModalOpen}
                setIsOpen={setIsFranchiseBoothVersionEditModalOpen}
                dataList={dataList}
                onRefresh={onRefresh}
            />
        </DivWithTitle>
    );
};

export default FranchiseBoothVersions;