import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import RecipeUtils from "../../../../../../common/jsonDynamic/util/RecipeUtils";
import _ from "lodash";
import DataCompareService from "../../../../../../../../services/DataCompareService";
import {Container, Row, Spinner} from "react-bootstrap";
import RecipeDataView from "../../../../../../common/jsonDynamic/component/RecipeDataView";
import DataUtils from "../../../../../../common/utils/DataUtils";
import TitleAndSwitchBox from "../../../../../../../common/dataView/TitleAndSwitchBox";
import styled from "styled-components";
import TitleAndSelectBox from "../../../../../../../common/dataView/TitleAndSelectBox";
import {toast} from "react-toastify";
import ProductJsonAxiosService from "../../../../../../common/jsonDynamic/service/ProductJsonAxiosService";

const SimpleButton = styled.button`
  font-size: 14px;
  padding: 7px 10px;
  color: ${(props) => props.enabled ? "#fc7242" : "#c2c2c2"};
  border: 1px solid ${(props) => props.enabled ? "#fc7242" : "#c2c2c2"};
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  background-color: white;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.enabled ? "#ffeee6" : "white"};
  }
`;

const SimpleGreyButton = styled.button`
  font-size: 14px;
  padding: 7px 10px;
  color: ${(props) => props.enabled ? "#757575" : "#c2c2c2"};
  border: 1px solid ${(props) => props.enabled ? "#757575" : "#c2c2c2"};
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  background-color: white;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${(props) => props.enabled ? "#ebebeb" : "white"};
  }
`;

/** views : option detail (recipe, optionJsonVersion), save or init button */
const OptionDetailView = ({ selectedBoothVersionId,
                              selectedOptionJsonVersion,
                              updateSelectedOptionJsonVersion,
                              optionJsonList,
                              optionInfoList,
                              selectedOptionId,
                              initializeParentState }) => {

    const { t } = useTranslation();
    const { franchiseId, menuId } = useParams();

    /* states */
    const [requestBody, setRequestBody] = useState({});
    const [fixedRequestBody, setFixedRequestBody] = useState({});
    const [isValidMap, setIsValidMap] = useState({});
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);
    const [optionJsonSchema, setOptionJsonSchema] = useState({});
    const [tempOptionJsonVersion, setTempOptionJsonVersion] = useState("");

    /* isLoading */
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    /* alert */
    const AlertInner = (message) => {
        return (
            <div
                style={{
                    width: '100%',
                    textAlign: 'left'
                }}
            >
                <span
                    style={{
                        color: '#757575',
                        fontSize: '14px'
                    }}
                >
                    {message}
                </span>
            </div>
        );
    };

    /* initialize */
    const initializeStates = () => {
        /* get target json schema */
        let targetOptionJsonSchema = RecipeUtils.extractJsonSchemaFromList(selectedOptionJsonVersion, optionJsonList);

        /* extract the target option detail */
        let targetOptionDetail = {};
        optionInfoList.map((optionInfo) => {
            if (optionInfo['optionInfo']['franchiseMenuOptionId'] === selectedOptionId) {
                optionInfo['franchiseMenuOptionDetailList'].map(optionDetail => {
                    if ((optionDetail['franchiseMenuOptionDetailBoothVersionId'] === selectedBoothVersionId)
                        && (optionDetail['franchiseMenuOptionDetailOptionJsonVersion'] === selectedOptionJsonVersion)) {
                        targetOptionDetail = optionDetail;
                    }
                });
            }
        });

        /* craft a new requestBody without recipe */
        let newRequestBody = {};
        newRequestBody["franchiseMenuOptionDetailBoothVersionId"] = targetOptionDetail["franchiseMenuOptionDetailBoothVersionId"];
        newRequestBody["franchiseMenuOptionDetailOptionJsonVersion"] = targetOptionDetail["franchiseMenuOptionDetailOptionJsonVersion"];

        /* craft a new isValidMap without recipe */
        let newIsValidMap = {};
        newIsValidMap["franchiseMenuOptionDetailBoothVersionId"] = true;
        newIsValidMap["franchiseMenuOptionDetailOptionJsonVersion"] = true;

        /* recipe fields */
        let optionRecipeStr = targetOptionDetail["franchiseMenuOptionDetailRecipe"];
        let optionRecipe = JSON.parse(optionRecipeStr);
        let objectKeys = Object.keys(targetOptionJsonSchema['spec']['attributes']);
        objectKeys.map(objectKey => {
            newRequestBody[objectKey] = optionRecipe['attributes'][objectKey];
            newIsValidMap[objectKey] = true;
        });

        /* setup states */
        setOptionJsonSchema(targetOptionJsonSchema);
        setRequestBody(newRequestBody);
        setFixedRequestBody(_.cloneDeep(newRequestBody));
        setIsValidMap(newIsValidMap);
        setIsValid(true);
        setIsEdited(false);
    };

    const formatRequestBody = (requestBody) => {
        let prevRequestBody = _.cloneDeep(requestBody);
        let newRequestBody = {};
        let newRecipe = {};

        newRequestBody['franchiseMenuOptionDetailBoothVersionId'] = prevRequestBody['franchiseMenuOptionDetailBoothVersionId'];
        delete prevRequestBody['franchiseMenuOptionDetailBoothVersionId'];

        newRequestBody['franchiseMenuOptionDetailOptionJsonVersion'] = prevRequestBody['franchiseMenuOptionDetailOptionJsonVersion'];
        delete prevRequestBody['franchiseMenuOptionDetailOptionJsonVersion'];

        newRecipe['attributes'] = prevRequestBody;
        newRequestBody['franchiseMenuOptionDetailRecipe'] = JSON.stringify(newRecipe);

        return newRequestBody;
    };

    const putOptionDetail = async () => {
        await setIsSaveLoading(true);
        try {
            let formattedRequestBody = formatRequestBody(requestBody);
            const onSuccessCallback = async (res) => {
                toast.success(AlertInner(t("message.saved")));
                /* refresh all */
                await initializeParentState();
                await setIsSaveLoading(false);
            };
            const onFailureCallback = async (e) => {
                console.log(e);
                throw { message: "Failed to save a new default option detail.", alertMsgKey: "saveError" };
            };
            await ProductJsonAxiosService.saveOrGenerateMenuOptionDetail(franchiseId, menuId, selectedOptionId, formattedRequestBody, onSuccessCallback, onFailureCallback);
        } catch (err) {
            console.log(err);
            /* alert */
            let message = t(`menu.franchiseMenu.recipeInfo.optionRecipe.message.error.${err['alertMsgKey']}`);
            toast.error(AlertInner(message));
            /* roll-back */
            setIsSaveLoading(false);
        }
    };

    /* effects */
    useEffect(() => {
        initializeStates();
    }, [selectedBoothVersionId, selectedOptionJsonVersion]);

    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Container fluid>
            <Row>
                {/* option JSON schema selection */}
                <TitleAndSelectBox
                    title={t("menu.franchiseMenu.recipeInfo.optionRecipe.field.optionSchema")}
                    innerValue={selectedOptionJsonVersion}
                    valueList={RecipeUtils.extractMetadataFromProductJsonList(optionJsonList)}
                    valueSelector={"id"}
                    viewSelector={t("language.dataKey.boothControl.json.label")}
                    onChange={(value) => updateSelectedOptionJsonVersion(value)}
                />
            </Row>
            <Row
                style={{
                    marginTop : '10px'
                }}
            >
                {/* json recipe view */}
                {/* fixed height */}
                <div
                    style={{
                        width: '100%',
                        height: '420px',
                        overflow: 'auto',
                        border: '1px solid #ebebeb',
                        borderRadius: '10px',
                        padding: '10px'
                    }}
                >
                    {
                        (!_.isEqual(optionJsonSchema, {})) &&
                        Object.keys(optionJsonSchema['spec']['attributes']).map(fieldKey => {
                            return (
                                <RecipeDataView
                                    key={optionJsonSchema['metadata']['id'] + optionJsonSchema['spec']['attributes'][fieldKey][t("language.dataKey.boothControl.json.label")]}
                                    title={optionJsonSchema['spec']['attributes'][fieldKey][t("language.dataKey.boothControl.json.label")]}
                                    fieldKey={fieldKey}
                                    validator={optionJsonSchema['spec']['attributes'][fieldKey]['validator']}
                                    isCategorical={optionJsonSchema['spec']['attributes'][fieldKey]['isCategorical']}
                                    data={requestBody[fieldKey]}
                                    updateData={(value) => DataUtils.updateHelper(setRequestBody, fieldKey, value)}
                                    isValidMap={isValidMap}
                                    updateIsValidMap={(value) => DataUtils.updateHelper(setIsValidMap, fieldKey, value)}
                                    isForEdit={true}
                                    unitOfValue={optionJsonSchema['spec']['attributes'][fieldKey]['unitOfValue']}
                                />
                            );
                        })
                    }
                </div>
            </Row>
            <Row>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '15px'
                    }}
                >
                    <div
                        style={{
                            marginLeft : 'auto',
                            display: 'inline-flex',
                            alignItems: 'center'
                        }}
                    >
                        {/* initialize button */}
                        <SimpleGreyButton
                            enabled={isEdited}
                            onClick={() => {
                                if (isEdited) {
                                    initializeStates();
                                }
                            }}
                        >
                            {t("menu.franchiseMenu.recipeInfo.menuRecipe.button.init")}
                        </SimpleGreyButton>
                    </div>
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            marginLeft: '10px'
                        }}>
                        {/* save button */}
                        <SimpleButton
                            enabled={isEdited && isValid && !isSaveLoading}
                            onClick={() => {
                                if (isEdited && isValid && !isSaveLoading) {
                                    putOptionDetail();
                                }
                            }}
                        >
                            {
                                isSaveLoading ?
                                    <Spinner size={"sm"} />
                                    :
                                    <>
                                        {t("button.save")}
                                    </>
                            }
                        </SimpleButton>
                    </div>
                </div>
            </Row>
        </Container>
    );
};

export default OptionDetailView;