import React, {useEffect, useState} from 'react';
import {Container, Row} from "react-bootstrap";
import Modal from "react-modal";
import {useTranslation} from "react-i18next";
import axios from "axios";
import uuid from "react-uuid";
import styled from "styled-components";
import DataCompareService from "../../../services/DataCompareService";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../common/dataView/TitleAndTextArea";
import UserService from '../../../services/UserService';
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import LanguageInput from "../../../language/component/LanguageInput";
import LanguageSelectBox from "../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";
import DeleteConfirmModal from "../../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../../services/DeleteService";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '800px',
        height: '600px',
        margin: 'auto'
    }
}

const DeleteButtonDisabled = styled.button`
  width: 100%;
  border: none;
  color: #c2c2c2;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #c2c2c2;
  font-size: 14px;
  transition: all ease-in-out 0.2s;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled.button`
  width: 100%;
  border: none;
  color: red;
  background-color: white;
  border-radius: 5px;
  border: 1px solid red;
  font-size: 14px;
  transition: all ease-in-out 0.2s;
  outline: none;

  &:hover {
    background-color: red;
    color: white;
  }

  &:focus {
    outline: none;
  }
`;

const requestBodyInit = {
    categoryName: "",
    categoryName1: null,
    categoryName2: null,
    categoryName3: null,
    categoryName4: null,
    categoryName5: null,
    description: "",
    description1: null,
    description2: null,
    description3: null,
    description4: null,
    description5: null,
    productCategoryGroupId: ""
};

const isValidMapInit = {
    categoryName: true,
    categoryName1: true,
    categoryName2: true,
    categoryName3: true,
    categoryName4: true,
    categoryName5: true,
    description: true,
    description1: true,
    description2: true,
    description3: true,
    description4: true,
    description5: true,
    productCategoryGroupId: true
};

const ProductCategoryEdit = ({ isOpen, setIsOpen, onRefresh }) => {

    const { t, i18n } = useTranslation();
    /* productCategoryList */
    const [productCategoryList, setProductCategoryList] = useState([]);
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1);
    const [isEdited, setIsEdited] = useState(false);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);

    /* delete confirmation */
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    /* state for language */
    const targetFieldsString = `"${t("common.name")}", "${t("common.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* initialize */
    const initialize = () => {
        fetchProductCategoryList();
        setSelectedCategoryIndex(-1);
    };

    /* fetch productCategoryList */
    const fetchProductCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product-category`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setProductCategoryList(res.data.item.productCategoryList);
            } else {
                setProductCategoryList([]);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const extractFields = (obj) => {
        return {
            categoryName: obj.categoryName,
            categoryName1: obj.categoryName1,
            categoryName2: obj.categoryName2,
            categoryName3: obj.categoryName3,
            categoryName4: obj.categoryName4,
            categoryName5: obj.categoryName5,
            description: obj.description,
            description1: obj.description1,
            description2: obj.description2,
            description3: obj.description3,
            description4: obj.description4,
            description5: obj.description5,
            productCategoryGroupId: obj.productCategoryGroupId
        };
    };

    /* put category */
    const putCategory = async () => {
        try {
            let requestBodyJsonObj = extractFields(requestBody);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product-category/${productCategoryList[selectedCategoryIndex].productCategoryId}`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBodyJsonObj),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await onRefresh();
            await initialize();
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    /* delete category */
    const deleteCategory = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product-category/${productCategoryList[selectedCategoryIndex].productCategoryId}`;
            await UserService.updateToken(() => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.deleted")} />);
            await onRefresh();
            await initialize();
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
            console.log(e);
        }
    }

    /* effects */
    useEffect(() => {
        setCurrentLang("en-US");
        if (selectedCategoryIndex === -1) {
            setIsEdited(false);
            setIsValid(false);
        } else {
            setRequestBody(DataCompareService.deepCopyObj(productCategoryList[selectedCategoryIndex]));
            setIsEdited(false);
        }
    }, [selectedCategoryIndex]);

    useEffect(() => {
        if (selectedCategoryIndex === -1) {
            setIsEdited(false);
        } else {
            setIsEdited(!DataCompareService.isSameObj(requestBody, productCategoryList[selectedCategoryIndex]));
        }
    }, [requestBody]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        initialize();
    }, [isOpen]);

    /* renderer */
    const renderProductCategory = (categoryInfo, index) => {
        return (
            <div
                key={uuid()}
                style={{
                    width: '100%',
                    height: '50px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    border: '1px solid',
                    borderColor: (selectedCategoryIndex === index) ? "#fc7242" : "#757575",
                    backgroundColor: (selectedCategoryIndex === index) ? "#fc7242" : "white",
                    display: 'flex',
                    flexDirection: 'column'
                }}
                onClick={() => {
                    if (selectedCategoryIndex === index) {
                        setSelectedCategoryIndex(-1);
                    } else {
                        setSelectedCategoryIndex(index);
                    }
                }}
            >
                <div
                    style={{
                        width: '100%',
                        paddingLeft: '20px'
                    }}
                >
                    <span
                        style={{
                            color: (selectedCategoryIndex === index) ? "white" : "#757575",
                            fontSize: '13px',
                            fontWeight: 'bold'
                        }}
                    >
                        {categoryInfo[t("language.dataKey.product.category.name")]}
                    </span>
                </div>
                <div
                    style={{
                        width: '100%',
                        paddingLeft: '30px'
                    }}
                >
                    <span
                        style={{
                            color: (selectedCategoryIndex === index) ?
                                    "white"
                                    :
                                    (categoryInfo.productCount === 0) ? "red" : "blue",
                            fontSize: '13px'
                        }}
                    >
                        {categoryInfo.productCount}
                    </span>
                    <span
                        style={{
                            color: (selectedCategoryIndex === index) ?
                                "white"
                                :
                                "#757575",
                            fontSize: '13px'
                        }}
                    >
                        {"  " + t("menu.product.category.edit.productNumber")}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                onRefresh();
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.product.category.edit.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex'
                        }}
                    >
                        <div
                            style={{
                                width: '49%',
                                height: '500px',
                                overflowY: 'auto',
                                padding: '5px',
                                marginRight: '1%'
                            }}
                        >
                            {
                                productCategoryList.map((category, index) => {
                                    return renderProductCategory(category, index);
                                })
                            }
                        </div>
                        <div
                            style={{
                                width: '50%',
                                height: '500px',
                                padding: '5px',
                                border: '1px solid #ebebeb',
                                borderRadius: '10px'
                            }}
                        >
                            {
                                (selectedCategoryIndex === -1) ?
                                    <div
                                        style={{
                                            width: '100%',
                                            padding: '10px'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: '#757575',
                                                fontSize: '13px'
                                            }}
                                        >
                                            {t("message.chooseProductCategory")}
                                        </span>
                                    </div>
                                    :
                                    <Container fluid>
                                        <Row>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'right'
                                                }}
                                            >
                                                {
                                                    productCategoryList[selectedCategoryIndex].productCount !== 0 ?
                                                        <DeleteButtonDisabled
                                                            onClick = {() => toast.error(<ToastAlertView message={t("message.cannotDeleteCategory")} />)}
                                                        >
                                                            {t("button.deleteCategory")}
                                                        </DeleteButtonDisabled>
                                                        :
                                                        <DeleteButton
                                                            onClick = {() => setIsDeleteConfirmOpen(true)}
                                                        >
                                                            {t("button.deleteCategory")}
                                                        </DeleteButton>
                                                }
                                            </div>
                                        </Row>
                                        <Row>
                                            <TitleAndInputBox
                                                title={t("menu.groupCompany.group.detail.groupName")}
                                                value={productCategoryList[selectedCategoryIndex]['productGroupName']}
                                                onChange={() => {}}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                isEnabled={true}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <LanguageSelectBox
                                                title={t("language.selectBox.title.productCategory")}
                                                languageMetadataList={languageMetadataList}
                                                currentLang={currentLang}
                                                setCurrentLang={setCurrentLang}
                                                setIsHighlightOn={setIsHighlightOn}
                                                targetFieldString={targetFieldsString}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <LanguageInput
                                                type={"text"}
                                                title={t("common.name") + ` (${t("language.callName." + currentLang)})`}
                                                dataKeyTranslationPath={"language.dataKey.product.category.name"}
                                                valueMap={requestBody}
                                                updateValueMap={updateRequestBody}
                                                isValidMap={isValidMap}
                                                updateIsValidMap={updateIsValidMap}
                                                isForEdit={true}
                                                currentLang={currentLang}
                                                isHighlightOn={isHighlightOn}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <LanguageInput
                                                type={"textarea"}
                                                title={t("common.description") + ` (${t("language.callName." + currentLang)})`}
                                                dataKeyTranslationPath={"language.dataKey.product.category.description"}
                                                valueMap={requestBody}
                                                updateValueMap={updateRequestBody}
                                                isValidMap={isValidMap}
                                                updateIsValidMap={updateIsValidMap}
                                                isForEdit={true}
                                                height={"140"}
                                                currentLang={currentLang}
                                                isHighlightOn={isHighlightOn}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '15px'
                                            }}
                                        >
                                            {
                                                (isEdited && isValid)
                                                ?
                                                    <button
                                                        id={"long-save-btn"}
                                                        onClick={() => putCategory()}
                                                    >
                                                        {t("button.save")}
                                                    </button>
                                                    :
                                                    <button
                                                        id={"long-save-btn-disabled"}
                                                    >
                                                        {t("button.save")}
                                                    </button>
                                            }
                                        </Row>
                                    </Container>
                            }
                        </div>
                    </div>
                </Row>
                {/* delete confirm */}
                {
                    (selectedCategoryIndex !== -1) &&
                    <DeleteConfirmModal
                        isOpen={isDeleteConfirmOpen}
                        setIsOpen={setIsDeleteConfirmOpen}
                        isDelayed={false}
                        confirmMessage={t("language.message.deletion.confirmation", { entityName : productCategoryList[selectedCategoryIndex][t("language.dataKey.product.category.name")] })}
                        entity={DeleteService.ValidEntities.PRODUCT_CATEGORY}
                        entityName={productCategoryList[selectedCategoryIndex][t("language.dataKey.product.category.name")]}
                        id={`product-category/${productCategoryList[selectedCategoryIndex].productCategoryId}`}
                        onDeleteSuccess={async () => {
                            await onRefresh();
                            await initialize();
                        }}
                    />
                }
            </Container>
        </Modal>
    );
};

export default ProductCategoryEdit;