import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import BoothService from "../axiosServices/BoothService";
import CommonUtil from "../utils/CommonUtil";
import styled from "styled-components";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import ServerFailed from "../viewArea/info/ServerFailed";
import AbnormalApproach from "../viewArea/info/AbnormalApproach";
import {useTranslation} from "react-i18next";
import SchemaParser from "../utils/SchemaParser";
import Switch from "react-switch";
import { RiRefreshFill } from "react-icons/ri";

/* develop dummy data */
const smallList = [
    {
        level : "Fatal",
        reason : "This my reason. hello.",
        alarmTime : "2023-05-44T00:00:00"
    },
    {
        level : "Error",
        reason : "",
        alarmTime : "2023-05-44T00:00:00"
    },
    {
        level : "Warning",
        reason : "This my long reason. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello.This my long reason. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello. hello.",
        alarmTime : "2023-05-44T00:00:00"
    },
    {
        level : "Info",
        reason : "This my reason. hello.",
        alarmTime : "2023-05-44T00:00:00"
    },
    {
        level : "Debug",
        reason : "This my reason. hello.",
        alarmTime : "2023-05-44T00:00:00"
    }
];

const TransButton = styled.button`
  border: none;
  background: none;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  margin-right: 15px;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: red;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 17px;
`;

const MainContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

const LogLevelContainer = styled.div`
  margin: auto;
  width: 50%;
  padding: 5px 5px;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  text-align: center;
`;

const LogReason = styled.textarea`
  resize: none;
  width: 100%;
  height: 40px;
  border: none;
  color: #757575;
  
  &:focus {
    outline: none;
  }
`

const BoothAlarmView = () => {

    /* ------------------------------------------ */
    /* ------------------ NOTE ------------------ */
    /* 1.  ONLY SUPPORT "GET"                     */
    /* 2.  actual schema path                     */
    /*     booth                                  */
    /*       => device(operator)                  */
    /*           => sensor(operator_controller)   */
    /*               => capabilities (alarms)     */
    /* 3.  Below values are "HARD-CODED"          */
    /* ------------------------------------------ */
    /* ------------------------------------------ */
    let DEVICE_NAME = "operator";
    let SENSOR_NAME = "operator_controller";
    /* Belows can be not present.                 */
    let CAPABILITY_NAME = "alarms";
    let LOG_FIELD_NAME_LEVEL = "level";
    let LOG_FIELD_NAME_REASON = "reason";
    let LOG_FIELD_ALARM_TIME = "alarmTime";
    let LOG_LEVEL_FATAL = "Fatal";
    let LOG_LEVEL_ERROR = "Error";
    let LOG_LEVEL_WARNING = "Warning";
    let LOG_LEVEL_INFO = "Info";
    let LOG_LEVEL_DEBUG = "Debug";

    /* json schema */
    let boothJsonSchema = JSON.parse(window.localStorage.getItem("ims_json_schema"));

    /* path param : boothId */
    const { boothId } = useParams();

    /* translation */
    const { t, i18n } = useTranslation();

    /* -------------------------------------------------- */
    /* --------------------- STATES --------------------- */
    /* actual device and sensor id : dependent on schema  */
    const [boothSchemaId, setBoothSchemaId] = useState("");
    const [deviceId, setDeviceId] = useState("");
    const [sensorId, setSensorId] = useState("");

    /* isLoading */
    const [isLoading, setIsLoading] = useState(true);

    /* data : booth data */
    const [boothData, setBoothData] = useState({});

    /* data : alarm list */
    const [alarmList, setAlarmList] = useState([]);

    /* log level list */
    const [viewLogLevels, setViewLogLevels] = useState([]);
    const [selectedLevelList, setSelectedLevelList] = useState([]);

    /* -------------------------------------------------- */
    /* -------------------------------------------------- */

    /* fetch booth data */
    const fetchBoothData = async () => {
        const onSuccessCallBack = (data) => {
            setBoothData(data);
            /* extract alarm list */
            let alarmListData = data[boothSchemaId][deviceId]['sensors'][sensorId]['capabilities'][CAPABILITY_NAME];
            /* ------------------------- */
            /* for develop : DELETE TODO */
            // for (let i=0 ; i<10 ; i++) {
            //     alarmListData = [...alarmListData, ...smallList];
            // }
            /* ------------------------- */
            setAlarmList(alarmListData);
            setIsLoading(false);
        };
        const onFailureCallBack = () => {
            setBoothData({});
            setIsLoading(false);
        };
        BoothService.fetchBoothData(boothId, onSuccessCallBack, onFailureCallBack);
    };

    /* utils */
    const getColorCodeFromLevel = (level) => {
        if (level === LOG_LEVEL_FATAL) {
            return "#cc3300";
        } else if (level === LOG_LEVEL_ERROR) {
            return "#ff9966";
        } else if (level === LOG_LEVEL_WARNING) {
            return "#ffcc00";
        } else if (level === LOG_LEVEL_INFO) {
            return "#99cc33";
        } else { /* debug */
            return "#339900";
        }
    };

    const getRatioFromFieldName = (name) => {
        if (name === LOG_FIELD_NAME_LEVEL) {
            return "15";
        } else if (name === LOG_FIELD_NAME_REASON) {
            return "65";
        } else if (name === LOG_FIELD_ALARM_TIME) {
            return "20";
        } else {
            return "0";
        }
    };

    const getLabelFromFieldName = (name) => {
        let lang = i18n.language;
        if (name === LOG_FIELD_NAME_LEVEL) {
            return t("language.enums.boothAlarm.LOG_FIELD_NAME_LEVEL");
        } else if (name === LOG_FIELD_NAME_REASON) {
            return t("language.enums.boothAlarm.LOG_FIELD_NAME_REASON");
        } else if (name === LOG_FIELD_ALARM_TIME) {
            return t("language.enums.boothAlarm.LOG_FIELD_ALARM_TIME");
        } else {
            return t("language.enums.boothAlarm.UNKNOWN");
        }
    };

    const isSelectedLevel = (level) => {
        for (let i=0 ; i<viewLogLevels.length ; i++) {
            if (level === viewLogLevels[i]) {
                return selectedLevelList[i];
            }
        }
    };

    const handleLogLevelFilterClicked = (index) => {
        setSelectedLevelList(prevList => {
           let newList = [...prevList];
           newList[index] = !newList[index];
           return newList;
        });
    }

    /* effects */
    /* initialize booth data */
    useEffect(() => {
        if (sensorId === "" || deviceId === "" || boothSchemaId === "") {
            return;
        } else {
            fetchBoothData();
        }
    }, [boothId, sensorId, deviceId, boothSchemaId]);

    /* initialize device and sensor id from json schema */
    useEffect(() => {
        if (typeof boothJsonSchema === "undefined" || boothJsonSchema == null) {
            return;
        } else {
            /* get device */
            let deviceSchemaList = boothJsonSchema['spec']['devices'];
            let targetDeviceSchema = CommonUtil.getObjectInListByName(DEVICE_NAME, deviceSchemaList);
            /* get sensor */
            let sensorSchemaList = targetDeviceSchema['spec']['sensors'];
            let targetSensorSchema = CommonUtil.getObjectInListByName(SENSOR_NAME, sensorSchemaList);
            /* update level list */
            let logLevelPattern = targetSensorSchema['spec']['capabilities'][CAPABILITY_NAME]['validator']['items']['properties'][LOG_FIELD_NAME_LEVEL]['pattern'];
            let logLevels = SchemaParser.extractListFromPattern(logLevelPattern)
            setViewLogLevels(logLevels);
            setSelectedLevelList(Array(logLevels.length).fill(true));
            /* set ids */
            setBoothSchemaId(boothJsonSchema['metadata']['id']);
            setDeviceId(targetDeviceSchema['metadata']['id']);
            setSensorId(targetSensorSchema['metadata']['id']);
        }
    }, []);

    const renderHeader = () => {
        /* get device */
        let deviceSchemaList = boothJsonSchema['spec']['devices'];
        let targetDeviceSchema = CommonUtil.getObjectInListByName(DEVICE_NAME, deviceSchemaList);
        /* get sensor */
        let sensorSchemaList = targetDeviceSchema['spec']['sensors'];
        let targetSensorSchema = CommonUtil.getObjectInListByName(SENSOR_NAME, sensorSchemaList);
        /* get alarm schema property */
        let alarmSchemaProperty = targetSensorSchema['spec']['capabilities'][CAPABILITY_NAME]['items'];
        return (
            <div
                style={{
                    width: '100%',
                    height: '50px',
                    backgroundColor: '#ebebeb',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                {
                    Object.keys(alarmSchemaProperty).map((keyName) => {
                        return (
                            <div
                                key={keyName + " field"}
                                style={{
                                   display: 'inline-block',
                                   width: getRatioFromFieldName(keyName) + "%"
                                }}
                            >
                                <span
                                   style={{
                                       fontWeight: 'bold',
                                       color: '#757575'
                                   }}
                                >
                                   {getLabelFromFieldName(keyName)}
                                </span>
                            </div>
                        );
                    })
                }
            </div>
        );
    };


    const renderAlarmContent = () => {
        let alarmElements = [];
        alarmList.map(dataObj => {
            let dataObjAlarmLevel = dataObj[LOG_FIELD_NAME_LEVEL];
            if (isSelectedLevel(dataObjAlarmLevel)) {
                alarmElements.push(
                    <div
                        style={{
                            width: '100%',
                            height: '50px',
                            padding: '0px',
                            borderBottom: '1px solid #ebebeb',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        {/* level */}
                        <div
                            style={{
                                display: 'flex',
                                height: '100%',
                                width: getRatioFromFieldName(LOG_FIELD_NAME_LEVEL) + "%",
                                borderRight: '1px solid #ebebeb',
                                flexDirection: 'row'
                            }}
                        >
                            <LogLevelContainer
                                style={{
                                    backgroundColor: getColorCodeFromLevel(dataObj[LOG_FIELD_NAME_LEVEL])
                                }}
                            >
                                {dataObj[LOG_FIELD_NAME_LEVEL]}
                            </LogLevelContainer>
                        </div>
                        {/* reason */}
                        <div
                            style={{
                                display: 'flex',
                                height: '100%',
                                width: getRatioFromFieldName(LOG_FIELD_NAME_REASON) + "%",
                                borderRight: '1px solid #ebebeb',
                                flexDirection: 'row',
                                verticalAlign: 'center',
                                alignItems: 'center',
                                overflow: 'auto'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    padding: '0px 10px'
                                }}
                            >
                                <LogReason
                                    readOnly={true}
                                >
                                    {dataObj[LOG_FIELD_NAME_REASON]}
                                </LogReason>
                            </div>
                        </div>
                        {/* time */}
                        <div
                            style={{
                                display: 'flex',
                                height: '100%',
                                width: getRatioFromFieldName(LOG_FIELD_ALARM_TIME) + "%",
                                borderRight: '1px solid #ebebeb',
                                flexDirection: 'row',
                                verticalAlign: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    padding: '0px 10px'
                                }}
                            >
                                <input
                                    style={{
                                        resize: 'none',
                                        width: '100%',
                                        height: '40px',
                                        border: 'none',
                                        color: '#757575'
                                    }}
                                    value={dataObj[LOG_FIELD_ALARM_TIME]}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                );
            }
        });
        if (alarmElements.length === 0) {
            return (
                <div
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        padding: '50px'
                    }}
                >
                    <span
                        style={{
                            fontSize: '15px',
                            color: '#757575'
                        }}
                    >
                        {t("menu.boothControl.infoMessage.noAlarmMessage")}
                    </span>
                </div>
            );
        } else {
            return alarmElements;
        }
    };

    /* rendering */
    if (isLoading) {
        /* loading */
        return (
            <MainContainer
                style={{
                    height: '50%',
                    paddingTop: '10%'
                }}
            >
                <Spinner />
            </MainContainer>
        );
    } else if (Object.keys(boothData).length === 0) {
        /* data receiving failed */
        /* server error */
        return (
            <MainContainer
                style={{
                    paddingTop: '50px'
                }}
            >
                <ServerFailed />
            </MainContainer>
        );
    } else if (typeof boothJsonSchema === "undefined" || boothJsonSchema == null) {
        /* json schema not exist */
        /* an abnormal approach. */
        return (
            <MainContainer
                style={{
                    height: '100%',
                    paddingTop: '10%'
                }}
            >
                <AbnormalApproach />
            </MainContainer>
        );
    } else {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        {/* title */}
                        <TitleContainer>
                            <div>
                                <Title>
                                    {t("menu.boothControl.sideMenus.boothAlarms.title")}
                                </Title>
                            </div>
                            <div
                                style={{
                                    marginLeft: 'auto'
                                }}
                            >
                                <TransButton
                                    onClick={() => {
                                        setIsLoading(true);
                                        fetchBoothData();
                                    }}
                                >
                                    <RiRefreshFill
                                        size={"35"}
                                    />
                                </TransButton>
                            </div>
                        </TitleContainer>
                        {/* filter line */}
                        <div
                            style={{
                                width: '100%',
                                marginTop: '20px',
                                textAlign: 'left',
                                padding: '10px',
                                backgroundColor: 'white',
                                borderRadius: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginLeft: '10px',
                                    marginBottom: '10px'
                                }}
                            >
                                <span
                                    style={{
                                        color: '#757575'
                                    }}
                                >
                                    {t("menu.boothControl.sideMenus.boothAlarms.filter")}
                                </span>
                            </div>
                            {
                                viewLogLevels.map((logLevel, index) => {
                                    return (
                                        <div
                                            key={logLevel + " level"}
                                            style={{
                                                display: 'inline-block',
                                                marginRight: '5px',
                                                padding: '0px 10px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: '500',
                                                        fontSize: '14px',
                                                        color: '#757575'
                                                    }}
                                                >
                                                    {logLevel}
                                                </span>
                                            </div>
                                            <Switch
                                                checked={isSelectedLevel(logLevel)}
                                                onChange={() => handleLogLevelFilterClicked(index)}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                onColor={getColorCodeFromLevel(logLevel)}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                        {/* actual view */}
                        <div
                            style={{
                                marginTop: '10px',
                                backgroundColor: 'white',
                                height: '650px',
                                borderRadius: '10px',
                                padding: '10px'
                            }}
                        >
                            {/* header */}
                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                {renderHeader()}
                            </div>
                            {/* rows */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '570px',
                                    overflow: 'auto'
                                }}
                            >
                                {renderAlarmContent()}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
};

export default BoothAlarmView;