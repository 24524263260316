import React, {useEffect, useState} from 'react';
import RenderOnRole from "../../auth/RenderOnRole";
import {Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import axios from "axios";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import PaginationHandler from "../common/pagination/PaginationHandler";
import ProductView from "./productView/productView";
import {useNavigate} from "react-router-dom";
import uuid from "react-uuid";
import AuthService from "../../services/AuthService";
import ProductCategoryAdd from "./productCategory/ProductCategoryAdd";
import ProductCategoryEdit from "./productCategory/ProductCategoryEdit";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";


const ProductManagement = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const productStatusList = [
        {
            value : "all",
            statusName: t("menu.product.status.all")
        },
        {
            value : "inactive",
            statusName: t("menu.product.status.inactive")
        },
        {
            value : "active",
            statusName: t("menu.product.status.active")
        },
        {
            value : "out_of_order",
            statusName: t("menu.product.status.out_of_order")
        }
    ];

    /* product categories */
    const [productCategoryList, setProductCategoryList] = useState([{
        productCategoryId : "all",
        categoryName : t("menu.product.status.all"),
        categoryName1 : t("menu.product.status.all"),
        categoryName2 : t("menu.product.status.all"),
        categoryName3 : t("menu.product.status.all"),
        categoryName4 : t("menu.product.status.all"),
        categoryName5 : t("menu.product.status.all")
    }]);
    const [selectedCategoryId, setSelectedCategoryId] = useState("all");

    /* for category add & edit */
    const [isCategoryAddModalOpen, setIsCategoryAddModalOpen] = useState(false);
    const [isCategoryEditModalOpen, setIsCategoryEditModalOpen] = useState(false);

    /* product status */
    const [productStatus, setProductStatus] = useState("all");

    /* pagination variables */
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);

    /* sort target */
    const sortTarget = t("language.dataKey.product.name");

    /* productList */
    const [productList, setProductList] = useState([]);

    /* fetch productCategoryList */
    const fetchProductCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product-category`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                let newProductCategoryList = res.data.item.productCategoryList;
                newProductCategoryList.unshift({
                    productCategoryId : "all",
                    categoryName : t("menu.product.status.all"),
                    categoryName1 : t("menu.product.status.all"),
                    categoryName2 : t("menu.product.status.all"),
                    categoryName3 : t("menu.product.status.all"),
                    categoryName4 : t("menu.product.status.all"),
                    categoryName5 : t("menu.product.status.all")
                })
                setProductCategoryList(newProductCategoryList);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* fetch productList */
    const fetchProductList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product?`
                + `sort_target=${sortTarget}`
                + `&product_status=${productStatus}`
                + `&category=${selectedCategoryId}`
                + `&page=${currentPageIndex}`
                + `&size=10`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setProductList(res.data.item.productList);
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            } else {
                setProductList([]);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            }
        } catch (e) {
            console.log(e);
        }
    }

    /* handlers */
    const handleCategoryChange = (value) => {
        setSelectedCategoryId(value);
    }

    const handleStatusChange = (value) => {
        setProductStatus(value);
    }

    /* pagination */
    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    }

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    }

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    }

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    }

    /* initialize */
    const initializeAll = () => {
        fetchProductCategoryList();
        fetchProductList();
    }

    /* effects */
    useEffect(() => {
        initializeAll();
    }, []);

    useEffect(() => {
        fetchProductList();
    }, [currentPageIndex, selectedCategoryId, productStatus]);

    /* renderers */
    const firstRowRender = () => {
        if (productList.length === 0) {
            return (<></>);
        }
        if (productList.length < 5) {
            return productList.map((product, index) => {
                return (
                    <ProductView
                        key={uuid()}
                        productInfo={product}
                        onClick={() => navigate(`/product/detail/${product.productId}`)}
                    />
                );
            })
        } else {
            let result = [];
            for (let i=0 ; i<5 ; i++) {
                result.push(
                    <ProductView
                        key={uuid()}
                        productInfo={productList[i]}
                        onClick={() => navigate(`/product/detail/${productList[i].productId}`)}
                    />
                );
            }
            return result;
        }
    }

    const secondRowRender = () => {
        if (productList.length === 0) {
            return (<></>);
        }
        if (productList.length <= 5) {
            return (<></>);
        } else {
            let result = [];
            for (let i=5 ; i<productList.length ; i++) {
                result.push(
                    <ProductView
                        key={uuid()}
                        productInfo={productList[i]}
                        onClick={() => navigate(`/product/detail/${productList[i].productId}`)}
                    />
                );
            }
            return result;
        }
    }

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.PRODUCT_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.product.title")]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    {/* buttons */}
                    <Row>
                        <div
                            className={"contentInner"}
                            style={{
                                width: '100%',
                                display: 'flex'
                            }}
                        >
                            <div
                                style={{
                                    width: '150px',
                                    textAlign: 'center'
                                }}
                            >
                                <RenderOnRole type={"POST"} roleName={ImsSystemRole.PRODUCT_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                    <button
                                        id={"category-add-btn"}
                                        onClick={() => navigate('/product/add')}
                                    >
                                        {t("button.addProduct")}
                                    </button>
                                </RenderOnRole>
                            </div>
                            <div
                                style={{
                                    width: 'auto',
                                    textAlign: 'center',
                                    marginLeft: 'auto'
                                }}
                            >
                                <RenderOnRole type={"POST"} roleName={ImsSystemRole.PRODUCT_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                    <button
                                        id={"category-add-btn"}
                                        onClick={() => setIsCategoryAddModalOpen(true)}
                                    >
                                        {t("button.addProductCategory")}
                                    </button>
                                </RenderOnRole>
                            </div>
                            <div
                                style={{
                                    width: 'auto',
                                    textAlign: 'center',
                                    marginLeft: '10px'
                                }}
                            >
                                <RenderOnRole type={"PUT"} roleName={ImsSystemRole.PRODUCT_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                    <button
                                        id={"category-edit-btn"}
                                        onClick={() => setIsCategoryEditModalOpen(true)}
                                    >
                                        {t("button.editProductCategory")}
                                    </button>
                                </RenderOnRole>
                            </div>
                        </div>
                    </Row>
                    {/* option panel */}
                    <Row>
                        <div
                            className={"contentInner"}
                            style={{
                                marginTop: '10px',
                                width: '100%',
                                display: 'flex'
                            }}
                        >
                            <div
                                style={{
                                    width: '35%'
                                }}
                            >
                                {/*title, innerValue, valueList, valueSelector, viewSelector, onChange*/}
                                <TitleAndSelectBox
                                    title={t("menu.product.options.category")}
                                    innerValue={selectedCategoryId}
                                    valueList={productCategoryList}
                                    valueSelector={"productCategoryId"}
                                    viewSelector={t("language.dataKey.product.category.name")}
                                    onChange={handleCategoryChange}
                                />
                            </div>
                            <div
                                style={{
                                    width: '35%',
                                    marginLeft: '15px'
                                }}
                            >
                                {/*title, innerValue, valueList, valueSelector, viewSelector, onChange*/}
                                <TitleAndSelectBox
                                    title={t("menu.product.options.status")}
                                    innerValue={productStatus}
                                    valueList={productStatusList}
                                    valueSelector={"value"}
                                    viewSelector={"statusName"}
                                    onChange={handleStatusChange}
                                />
                            </div>
                        </div>
                    </Row>
                    {/* products view */}
                    <Row>
                        <div
                            className={"contentInner"}
                            style={{
                                width: '100%',
                                marginTop: '10px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {/* first row */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '275px',
                                    display: 'flex'
                                }}
                            >
                                {firstRowRender()}
                            </div>
                            {/* second row */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '275px',
                                    display: 'flex'
                                }}
                            >
                                {secondRowRender()}
                            </div>
                            {/* pagination */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    marginTop: '15px',
                                    display: 'flex',
                                    flexDirection: 'row-reverse'
                                }}
                            >
                                <div
                                    style={{
                                        width: '500px',
                                        height: '100%'
                                    }}
                                >
                                    <PaginationHandler
                                        goBefore={handleGoBefore}
                                        goAfter={handleGoAfter}
                                        goFirst={handleGoFirst}
                                        goLast={handleGoLast}
                                        pageSize={10}
                                        currentIndex={currentPageIndex}
                                        maxIndex={maxPageIndex}
                                        totalItemNumber={totalElements}
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
            <ProductCategoryAdd
                isOpen={isCategoryAddModalOpen}
                setIsOpen={setIsCategoryAddModalOpen}
                onRefresh={initializeAll}
            />
            <ProductCategoryEdit
                isOpen={isCategoryEditModalOpen}
                setIsOpen={setIsCategoryEditModalOpen}
                onRefresh={initializeAll}
            />
        </RenderOnRole>
    );
};

export default ProductManagement;