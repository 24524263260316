import React from 'react';
import styled from "styled-components";

const Title = styled.span`
  font-size: 15px;
  color: #212529;
`;

const RangeInput = styled.input`
  width: 73%;
  border: 1px solid #e4e7ea;
  border-radius: 5px;
  padding: 7px 10px;
  color: #212529;
  font-size: 15px;
`;

const PreviewInput = styled.input`
  margin-left: 2%;
  width: 25%;
  border: 1px solid #e4e7ea;
  border-radius: 5px;
  padding: 7px 10px;
  color: #212529;
  font-size: 15px;
`;

const TitleAndIntegerInput = ({ title, value, isForEdit, isValid, updateValue, updateIsValid, objectKey, validator, unitOfValue, property }) => {

    const handleInputChange = (newValue) => {
        if (isForEdit) {
            let newIntValue = parseInt(newValue);
            /* update value */
            updateValue(objectKey, newIntValue);
            /* type and empty check */
            if (typeof newIntValue === "NaN" || newValue == null) {
                updateIsValid(objectKey, false);
                return;
            } else {
                if (validator != null) {
                    /* integer validating code */
                    let sizeValidity = true;
                    let multipleValidity = true;

                    /* min,max check */
                    if (property['hasRangeLimit']) {
                        sizeValidity = (newIntValue >= validator['minimum']) && ((newIntValue <= validator['maximum']));
                    } else {
                        sizeValidity = true;
                    }

                    /* multipleOf check */
                    if ((typeof validator['multipleOf'] !== "undefined") && (validator['multipleOf'] != null)) {
                        if (validator['multipleOf'] !== 0) {
                            multipleValidity = ((newIntValue % validator['multipleOf']) === 0);
                        } else {
                            multipleValidity = true;
                        }
                    } else {
                        multipleValidity = true;
                    }
                    updateIsValid(objectKey, sizeValidity && multipleValidity);
                } else {
                    updateIsValid(objectKey, true);
                }
            }
        } else {
            return;
        }
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* title */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '5px',
                    textAlign: 'left'
                }}
            >
                <Title>
                    {title}
                </Title>
            </div>
            {/* input box */}
            <div
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                <RangeInput
                    type={"range"}
                    readOnly={!isForEdit}
                    value={value}
                    min={validator['minimum']}
                    max={validator['maximum']}
                    step={
                        validator['multipleOf'] === 0 ?
                            1
                            :
                            validator['multipleOf']
                    }
                    onChange={(e) => handleInputChange(e.target.value)}
                    style={
                        isValid ?
                            {}
                            :
                            {
                                borderColor : 'red'
                            }
                    }
                />
                <PreviewInput
                    type={"text"}
                    readOnly={true}
                    value={
                        value == null ?
                            ""
                            :
                            value + " " + unitOfValue
                    }
                />
            </div>
        </div>
    );
};

export default TitleAndIntegerInput;