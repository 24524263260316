import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import UserStatusCode from "../../enums/UserStatusCode";
import axios from "axios";
import RenderOnRole from "../../auth/RenderOnRole";
import {Container, Row, Tooltip} from "react-bootstrap";
import Table from "../common/table/Table";
import PaginationHandler from "../common/pagination/PaginationHandler";
import { ReactComponent as MagnifierIcon } from '../../icons/ic_search.svg';
import { ReactComponent as StaffAddIcon } from '../../icons/add_staff.svg';
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const CustomerManagement = () => {

    const { t } = useTranslation();

    /* search variables */
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);
    const [searchField, setSearchField] = useState('all');
    const [searchLike, setSearchLike] = useState('');
    /* customer list */
    const [customerList, setCustomerList] = useState([]);

    /* navigation */
    const navigate = useNavigate();

    /* data fetch */
    const fetchCustomerList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user?size=9&page=${currentPageIndex}`;
            if (searchLike !== '') {
                fetchUrl = fetchUrl + `&field=${searchField}&like=${searchLike}`;
            }
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                let dataList = [];
                for (let i=0; i<8; i++) {
                    dataList.push({
                        "id" : null
                    });
                }
                setCustomerList(dataList);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            } else {
                let dataList = [...res.data.item.userList];
                setCustomerList(renameUserIdToIdAndFillEmptyAndStatusCodeRevision(dataList));
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            }
        } catch (err) {
            console.log(err);
        }
    }

    /* data re-formatting */
    const renameUserIdToIdAndFillEmptyAndStatusCodeRevision = (objectList) => {
        let newObjList = [...objectList];
        const iter = 9 - objectList.length;
        if (newObjList.length < 9) {
            for (let i=0; i < iter; i++) {
                newObjList.push({
                    "userId" : null
                });
            }
        }
        return newObjList.map(obj => {
            if ('userId' in obj) {
                obj['id'] = obj['userId'];
                delete obj['userId'];
            }
            if ('userStatusCode' in obj) {
                obj['userStatusCode'] = UserStatusCode.parseToString(obj['userStatusCode'])
            }
            return obj;
        });
    }

    /* handlers */
    const handleSearchFieldChange = (value) => {
        setSearchField(value);
    }

    const handleSearchLikeChange = (value) => {
        setSearchLike(value);
    }

    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    }

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    }

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    }

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    }

    const enterEventHandler = (e) => {
        if (e.key === 'Enter') {
            fetchCustomerList();
        }
    }

    /* table column data */
    const columnData = [
        {
            accessor: 'userName',
            Header: t('common.name'),
        },
        {
            accessor: 'id',
            Header: t('common.id'),
        },
        {
            accessor: 'userEmail',
            Header: t('common.email'),
        },
        {
            accessor: 'userPhone',
            Header: t('common.phone'),
        },
        {
            accessor: 'userStatusCode',
            Header: t('common.status'),
        }
    ];

    /* effects */
    useEffect(() => {
        fetchCustomerList();
    }, [currentPageIndex]);

    /* tooltips */
    const renderToolTip = (name) => {
        return (
            <Tooltip id="tooltip">
                {name}
            </Tooltip>
        );
    }

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.CUSTOMER_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.customer.title")]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    {/* main area */}
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                height: '800px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {/* search bar */}
                            <div className={"contentInner"}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        display: 'flex'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '270px',
                                            height: '50px',
                                        }}
                                    >
                                        <select
                                            id={"search-field-select-box"}
                                            defaultValue={"all"}
                                            onChange={(e) => handleSearchFieldChange(e.target.value)}
                                        >
                                            <option value={"all"}>{t("common.search.fields.all")}</option>
                                            <option value={"id"}>{t("common.search.fields.id")}</option>
                                            <option value={"name"}>{t("common.search.fields.name")}</option>
                                            <option value={"phone"}>{t("common.search.fields.phone")}</option>
                                            <option value={"email"}>{t("common.search.fields.email")}</option>
                                        </select>
                                    </div>
                                    <div
                                        style={{
                                            width: '270px',
                                            height: '50px',
                                            marginLeft: '10px'
                                        }}
                                    >
                                        <input
                                            id={"search-like-input"}
                                            type={"text"}
                                            placeholder={t("common.search.placeholder")}
                                            value={searchLike}
                                            onChange={(e) => handleSearchLikeChange(e.target.value)}
                                            onKeyDown={(e) => enterEventHandler(e)}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            marginLeft: '10px'
                                        }}
                                    >
                                        <button
                                            id={"box-btn"}
                                            onClick={() => fetchCustomerList()}
                                        >
                                            <MagnifierIcon />
                                        </button>
                                    </div>
                                    <div
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        <RenderOnRole type={"POST"} roleName={ImsSystemRole.CUSTOMER_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                            <OverlayTrigger placement={"bottom"} overlay={renderToolTip(t("menu.customer.add.title"))}>
                                                <button
                                                    id={"box-btn"}
                                                    onClick={() => navigate(`/customer/add`)}
                                                >
                                                    <StaffAddIcon />
                                                </button>
                                            </OverlayTrigger>
                                        </RenderOnRole>
                                    </div>
                                </div>
                            </div>
                            {/* table */}
                            <div
                                className={"contentInner"}
                                style={{
                                    marginTop: '10px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '600px',
                                        marginTop: '15px'
                                    }}
                                >
                                    <Table columns={columnData} data={customerList} baseUrl={'detail'} />
                                </div>
                                {/* pagination */}
                                <div
                                    style={{
                                        width: '400px',
                                        height: '50px',
                                        marginTop: '20px',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    {/*goBefore, goAfter, goFirst, goLast, pageSize, currentIndex, maxIndex, totalItemNumber*/}
                                    <PaginationHandler
                                        goBefore={handleGoBefore}
                                        goAfter={handleGoAfter}
                                        goFirst={handleGoFirst}
                                        goLast={handleGoLast}
                                        pageSize={9}
                                        currentIndex={currentPageIndex}
                                        maxIndex={maxPageIndex}
                                        totalItemNumber={totalElements}
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default CustomerManagement;