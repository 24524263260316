import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import DataCompareService from "../../services/DataCompareService";
import RenderOnRole from "../../auth/RenderOnRole";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import DataParseService from "../../services/DataParseService";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import _ from "lodash";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../services/DeleteService";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import TitleAndSearchSelectBox from "../common/dataView/TitleAndSearchSelectBox";

/** for the USA, the locationPostalCode and locationDetail field does not be used */
/** plus, locationAddress fields are split 5 fields */
const requestBodyInit = {
    branchName : "",
    branchDescription : "",
    locationPostalCode : "",
    locationAddressNumber : "",
    locationAddressStreet : "",
    locationAddressCityOrTown : "",
    locationAddressState : "",
    locationAddressZipCode : "",
    locationAddressDetail : "",
    locationLatitude : "",
    locationLongitude : "",
    branchMasterId : "",
    businessRegistrationNumber : "",
    branchGroupId : ""
};

/** for the USA, the locationPostalCode and locationDetail field does not be used */
/** plus, locationAddress fields are split 5 fields */
const isValidMapInit = {
    branchName : true,
    branchDescription : true,
    locationPostalCode : true,
    locationAddressNumber : true,
    locationAddressStreet : true,
    locationAddressCityOrTown : true,
    locationAddressState : true,
    locationAddressZipCode : true,
    locationAddressDetail : true,
    locationLatitude : true,
    locationLongitude : true,
    branchMasterId : true,
    businessRegistrationNumber : true,
    branchGroupId : true
};

const BranchEditUSA = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { branchId } = useParams("branchId");
    const location = useLocation();
    /* branch information */
    const [branchInfo, setBranchInfo] = useState({});
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    /* isValid, isEdited */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    /** New: franchise */
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [franchiseList, setFranchiseList] = useState([]);
    const [prevFranchiseId, setPrevFranchiseId] = useState("");
    const [afterFranchiseId, setAfterFranchiseId] = useState("");
    const [isFranchiseEdited, setIsFranchiseEdited] = useState(false);

    /* fetch franchise list */
    const fetchFranchiseList = async (targetGroupId) => {
        const initialFranchiseList = [
            {
                franchiseName : t("menu.branch.franchise.search.none"),
                franchiseId : "none"
            }
        ];
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            let newFranchiseList = _.cloneDeep(initialFranchiseList);
            const dataList = res.data.item.franchiseList;
            dataList.map(groupAndFranchiseObj => {
                let franchiseList = groupAndFranchiseObj[targetGroupId];
                for (let i=0 ; i<franchiseList.length ; i++) {
                    newFranchiseList.push(franchiseList[i]);
                }
            });
            await setFranchiseList(newFranchiseList);
        } catch (e) {
            console.log(e);
            await setFranchiseList(initialFranchiseList);
        }
    };

    /* fetch branch information */
    const fetchBranchDetail = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/branch/${branchId}`;
            const axiosCall = () => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            await setBranchInfo(res.data.item.branchInfo);
            let requestDataObj = {
                branchName : res.data.item.branchInfo.branchName,
                branchDescription : res.data.item.branchInfo.branchDescription,
                locationPostalCode : res.data.item.branchInfo.locationPostalCode,
                locationAddressNumber : res.data.item.branchInfo.locationAddress.split(', ')[0] ? res.data.item.branchInfo.locationAddress.split(', ')[0] : "",
                locationAddressStreet : res.data.item.branchInfo.locationAddress.split(', ')[1] ? res.data.item.branchInfo.locationAddress.split(', ')[1] : "",
                locationAddressCityOrTown : res.data.item.branchInfo.locationAddress.split(', ')[2] ? res.data.item.branchInfo.locationAddress.split(', ')[2] : "",
                locationAddressState : res.data.item.branchInfo.locationAddress.split(', ')[3] ? res.data.item.branchInfo.locationAddress.split(', ')[3] : "",
                locationAddressZipCode : res.data.item.branchInfo.locationAddress.split(', ')[4] ? res.data.item.branchInfo.locationAddress.split(', ')[4] : "",
                locationAddressDetail : res.data.item.branchInfo.locationAddressDetail,
                locationLatitude : res.data.item.branchInfo.locationLatitude,
                locationLongitude : res.data.item.branchInfo.locationLongitude,
                branchMasterId : res.data.item.branchInfo.branchMasterId,
                businessRegistrationNumber : res.data.item.branchInfo.businessRegistrationNumber,
                branchGroupId : res.data.item.branchInfo.branchGroupId
            };
            await setRequestBody(DataCompareService.deepCopyObj(requestDataObj));
            await setFixedRequestBody(DataCompareService.deepCopyObj(requestDataObj));
            /** New: franchise */
            await setIsFranchiseEdited(false);
            if (res.data.item.branchInfo['franchiseInformation'] == null) {
                await setPrevFranchiseId("none");
                await setAfterFranchiseId("none");
            } else {
                await setPrevFranchiseId(res.data.item.branchInfo['franchiseInformation']['franchiseId']);
                await setAfterFranchiseId(res.data.item.branchInfo['franchiseInformation']['franchiseId']);
            }
            /* fetch franchise list */
            await fetchFranchiseList(res.data.item.branchInfo.branchGroupId);
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    const putBranchInfo = async () => {
        await setIsSaveLoading(true);

        if (isEdited) {
            try {
                let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/branch/${branchId}`;
                let newRequestBody = _.cloneDeep(requestBody);
                newRequestBody["locationAddress"] = requestBody["locationAddressNumber"]
                    + ', ' + requestBody["locationAddressStreet"]
                    + ', ' + requestBody["locationAddressCityOrTown"]
                    + ', ' + requestBody["locationAddressState"]
                    + ', ' + requestBody["locationAddressZipCode"];
                delete newRequestBody["locationAddressNumber"];
                delete newRequestBody["locationAddressStreet"];
                delete newRequestBody["locationAddressCityOrTown"];
                delete newRequestBody["locationAddressState"];
                delete newRequestBody["locationAddressZipCode"];
                const axiosCall = () => axios.put(
                    fetchUrl ,
                    JSON.stringify(newRequestBody),
                    {
                        headers: {
                            Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                            "Content-Type" : "application/json"
                        }
                    }
                );
                const res = await UserService.updateToken(axiosCall);
                if (isEdited && (!isFranchiseEdited)) {
                    toast.success(<ToastAlertView message={t("message.saved")} />);
                    navigate(`/branch/detail/${branchId}`);
                }
            } catch (e) {
                toast.error(<ToastAlertView message={t("message.failed")} />);
                console.log(e);
                return;
            }
        }

        if (isFranchiseEdited) {
            if (afterFranchiseId === "none") {
                /* delete mapping */
                /** register to the franchise */
                let newFetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                    + `/api/v1/franchise/${prevFranchiseId}/branch/${branchId}`;
                try {
                    await UserService.updateToken(() => axios.delete(
                        newFetchUrl,
                        {
                            headers: {
                                Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                            }
                        }
                    ));
                    toast.success(<ToastAlertView message={t("message.saved")} />);
                    await setIsSaveLoading(false);
                    navigate(`/branch/detail/${branchId}`);
                } catch (e) {
                    console.log(e);
                    if (isEdited) {
                        toast.error(<ToastAlertView message={t("menu.branch.franchise.message.failed")} />);
                    } else {
                        toast.error(<ToastAlertView message={t("message.failed")} />);
                    }
                    await setIsSaveLoading(false);
                    return;
                }
            } else {
                /** register to the franchise */
                let newFetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                    + `/api/v1/franchise/${afterFranchiseId}/branch`;
                let newRequestBody = {
                    franchiseBranchBranchId : branchId
                };
                try {
                    await UserService.updateToken(() => axios.post(
                        newFetchUrl,
                        JSON.stringify(newRequestBody),
                        {
                            headers: {
                                Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                                "Content-Type" : "application/json"
                            }
                        }
                    ));
                    toast.success(<ToastAlertView message={t("message.saved")} />);
                    await setIsSaveLoading(false);
                    navigate(`/branch/detail/${branchId}`);
                } catch (e) {
                    console.log(e);
                    if (isEdited) {
                        toast.error(<ToastAlertView message={t("menu.branch.franchise.message.failed")} />);
                    } else {
                        toast.error(<ToastAlertView message={t("message.failed")} />);
                    }
                    await setIsSaveLoading(false);
                    return;
                }
            }
        }

    };

    /* utils */
    /** ----------------- */
    /** for the USA utils */
    const handleLocationNumberInput = async (value) => {
        await updateHelper(setIsValidMap, "locationAddressNumber", checkValidityOfInput(value));
        await updateHelper(setRequestBody, "locationAddressNumber", value);
    };

    const handleLocationStreetInput = async (value) => {
        await updateHelper(setIsValidMap, "locationAddressStreet", checkValidityOfInput(value));
        await updateHelper(setRequestBody, "locationAddressStreet", value);
    };

    const handleLocationCityInput = async (value) => {
        await updateHelper(setIsValidMap, "locationAddressCityOrTown", checkValidityOfInput(value));
        await updateHelper(setRequestBody, "locationAddressCityOrTown", value);
    };

    const handleLocationStateInput = async (value) => {
        await updateHelper(setIsValidMap, "locationAddressState", checkValidityOfInput(value));
        await updateHelper(setRequestBody, "locationAddressState", value);
    };

    const handleLocationZipCodeInput = async (value) => {
        await updateHelper(setIsValidMap, "locationAddressZipCode", checkValidityOfInput(value));
        await updateHelper(setRequestBody, "locationAddressZipCode", value);
    };

    const checkValidityOfInput = (value) => {
        if (value.includes(',')) {
            return false;
        }

        if (/^\s+$/.test(value)) {
            return false;
        }

        if (value === "") {
            return false;
        }

        return true;
    }

    const getRandomDigit = () => {
        return Math.floor(Math.random() * 10);
    }

    const generateRandomBizCode = () => {
        let result = '';

        for (let i = 0; i < 3; i++) {
            result += getRandomDigit();
        }
        result += '-';

        for (let i = 0; i < 2; i++) {
            result += getRandomDigit();
        }
        result += '-';

        for (let i = 0; i < 5; i++) {
            result += getRandomDigit();
        }

        return result;
    }


    const updateHelper = (updateFunction, key, value) => {
        updateFunction(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /** ----------------- */

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* handlers */
    const handleBranchNameInput = (value) => {
        updateRequestBody("branchName", value);
    };

    const handleBusinessNumberInput = (value) => {
        updateRequestBody("businessRegistrationNumber", value);
    };

    const handlePostalCodeInput = (value) => {
        updateRequestBody("locationPostalCode", value);
    };

    const handleAddressInput = (value) => {
        updateRequestBody("locationAddress", value);
    };

    const handleAddressDetailInput = (value) => {
        updateRequestBody("locationAddressDetail", value);
    };

    const handleDescriptionInput = (value) => {
        updateRequestBody("branchDescription", value);
    };

    /* validators */
    const validateBranchNameInput = () => {
        if (requestBody["branchName"].length < 3 || requestBody["branchName"].length > 20) {
            updateIsValidMap("branchName", false);
        } else {
            updateIsValidMap("branchName", true);
        }
    };

    const validateBranchDescriptionInput = () => {
        if (requestBody["branchDescription"] === "") {
            updateIsValidMap("branchDescription", false);
        } else {
            updateIsValidMap("branchDescription", true);
        }
    };

    const validateLocationPostalCodeInput = () => {
        if (requestBody["locationPostalCode"] === "") {
            updateIsValidMap("locationPostalCode", false);
        } else {
            updateIsValidMap("locationPostalCode", true);
        }
    };

    const validateLocationAddressInput = () => {
        if (requestBody["locationAddress"] === "") {
            updateIsValidMap("locationAddress", false);
        } else {
            updateIsValidMap("locationAddress", true);
        }
    };

    const validateLocationAddressDetailInput = () => {
        if (requestBody["locationAddressDetail"] === "") {
            updateIsValidMap("locationAddressDetail", false);
        } else {
            updateIsValidMap("locationAddressDetail", true);
        }
    };

    const validateBusinessNumberInput = () => {
        const isValidForm = DataCompareService.regexValidator(requestBody["businessRegistrationNumber"], "^(\\d{3}-\\d{2}-\\d{5})$");
        if (isValidForm) {
            updateIsValidMap("businessRegistrationNumber", true);
        } else {
            updateIsValidMap("businessRegistrationNumber", false);
        }
    };

    /* effects */
    useEffect(() => {
        fetchBranchDetail();
    }, []);

    /* input variables */
    useEffect(() => {
        validateBranchNameInput();
    }, [requestBody["branchName"]]);

    useEffect(() => {
        validateBranchDescriptionInput();
    }, [requestBody["branchDescription"]]);

    useEffect(() => {
        validateLocationPostalCodeInput();
    }, [requestBody["locationPostalCode"]]);

    useEffect(() => {
        validateLocationAddressInput();
    }, [requestBody["locationAddress"]]);

    useEffect(() => {
        validateLocationAddressDetailInput();
    }, [requestBody["locationAddressDetail"]]);

    useEffect(() => {
        validateBusinessNumberInput();
    }, [requestBody["businessRegistrationNumber"]]);

    /* isEdited, isValid */
    useEffect(() => {
        setIsEdited(!DataCompareService.isSameObj(requestBody, fixedRequestBody));
    }, [requestBody]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        setIsFranchiseEdited(afterFranchiseId !== prevFranchiseId);
    }, [afterFranchiseId]);

    return (
        <RenderOnRole type={"PUT"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={`/branch/detail/${branchId}`}
                />
                <PageTitleWithDepth
                    titles={[t("menu.branch.title"), t("menu.branch.detail.title"), t("menu.branch.edit.title")]}
                    depth={3}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                    <Container fluid>
                        <Row>
                            {/* main area */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '700px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '650px',
                                        display: 'flex',
                                        overflow: 'auto',
                                        padding: '10px 0px 20px 0px'
                                    }}
                                >
                                    <Container fluid>
                                        <Row>
                                            <TitleAndInputBox
                                                title={t("common.name")}
                                                value={requestBody["branchName"]}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["branchName"]}
                                                onChange={handleBranchNameInput}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <Col
                                                style={{
                                                    padding: '0px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.customer")}
                                                    value={branchInfo.branchMasterName}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    onChange={() => {}}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.group")}
                                                    value={branchInfo.branchGroupName}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    onChange={() => {}}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                {/* select franchise */}
                                                <TitleAndSearchSelectBox
                                                    title={t("menu.branch.franchise.title")}
                                                    innerValue={afterFranchiseId}
                                                    valueList={franchiseList}
                                                    valueSelector={"franchiseId"}
                                                    viewSelector={"franchiseName"}
                                                    onChange={async (value) => await setAfterFranchiseId(value)}
                                                    placeHolderStr={t("menu.franchise.selectFranchise")}
                                                />
                                            </Col>
                                        </Row>
                                        {/*<Row*/}
                                        {/*    style={{*/}
                                        {/*        marginTop: '10px'*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    <TitleAndInputBox*/}
                                        {/*        title={t("common.businessNumber")}*/}
                                        {/*        value={requestBody["businessRegistrationNumber"]}*/}
                                        {/*        isForEdit={true}*/}
                                        {/*        type={"text"}*/}
                                        {/*        isValid={isValidMap["businessRegistrationNumber"]}*/}
                                        {/*        onChange={handleBusinessNumberInput}*/}
                                        {/*    />*/}
                                        {/*</Row>*/}
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <Col
                                                md={"2"}
                                                style={{
                                                    padding: '0px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("menu.branch.detail.us.locationNumber")}
                                                    value={requestBody['locationAddressNumber']}
                                                    onChange={(value) => handleLocationNumberInput(value)}
                                                    isForEdit={true}
                                                    type={"text"}
                                                    isValid={isValidMap["locationAddressNumber"]}
                                                    isEnabled={true}
                                                    placeHolder={"ex) 300"}
                                                />
                                            </Col>
                                            <Col
                                                md={"3"}
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("menu.branch.detail.us.locationStreet")}
                                                    value={requestBody['locationAddressStreet']}
                                                    onChange={(value) => handleLocationStreetInput(value)}
                                                    isForEdit={true}
                                                    type={"text"}
                                                    isValid={isValidMap["locationAddressStreet"]}
                                                    isEnabled={true}
                                                    placeHolder={"ex) Washington St."}
                                                />
                                            </Col>
                                            <Col
                                                md={"3"}
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("menu.branch.detail.us.locationCityOrTown")}
                                                    value={requestBody['locationAddressCityOrTown']}
                                                    onChange={(value) => handleLocationCityInput(value)}
                                                    isForEdit={true}
                                                    type={"text"}
                                                    isValid={isValidMap["locationAddressCityOrTown"]}
                                                    isEnabled={true}
                                                    placeHolder={"ex) Newton"}
                                                />
                                            </Col>
                                            <Col
                                                md={"2"}
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("menu.branch.detail.us.locationState")}
                                                    value={requestBody['locationAddressState']}
                                                    onChange={(value) => handleLocationStateInput(value)}
                                                    isForEdit={true}
                                                    type={"text"}
                                                    isValid={isValidMap["locationAddressState"]}
                                                    isEnabled={true}
                                                    placeHolder={"ex) MA(Massachusetts)"}
                                                />
                                            </Col>
                                            <Col
                                                md={"2"}
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("menu.branch.detail.us.locationZipCode")}
                                                    value={requestBody['locationAddressZipCode']}
                                                    onChange={(value) => handleLocationZipCodeInput(value)}
                                                    isForEdit={true}
                                                    type={"text"}
                                                    isValid={isValidMap["locationAddressZipCode"]}
                                                    isEnabled={true}
                                                    placeHolder={"ex) 02458"}
                                                />
                                            </Col>
                                            {/*<Col*/}
                                            {/*    style={{*/}
                                            {/*        padding: '0px'*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    <TitleAndInputBox*/}
                                            {/*        title={t("menu.order.detail.content.shipInfo.postalCode")}*/}
                                            {/*        value={requestBody["locationPostalCode"]}*/}
                                            {/*        isForEdit={true}*/}
                                            {/*        type={"text"}*/}
                                            {/*        isValid={isValidMap["locationPostalCode"]}*/}
                                            {/*        onChange={handlePostalCodeInput}*/}
                                            {/*    />*/}
                                            {/*</Col>*/}
                                            {/*<Col*/}
                                            {/*    style={{*/}
                                            {/*        padding: '0px',*/}
                                            {/*        paddingLeft: '10px'*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    <TitleAndInputBox*/}
                                            {/*        title={t("menu.order.detail.content.shipInfo.address")}*/}
                                            {/*        value={requestBody["locationAddress"]}*/}
                                            {/*        isForEdit={true}*/}
                                            {/*        type={"text"}*/}
                                            {/*        isValid={isValidMap["locationAddress"]}*/}
                                            {/*        onChange={handleAddressInput}*/}
                                            {/*    />*/}
                                            {/*</Col>*/}
                                            {/*<Col*/}
                                            {/*    style={{*/}
                                            {/*        padding: '0px',*/}
                                            {/*        paddingLeft: '10px'*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    <TitleAndInputBox*/}
                                            {/*        title={t("menu.order.detail.content.shipInfo.detail")}*/}
                                            {/*        value={requestBody["locationAddressDetail"]}*/}
                                            {/*        isForEdit={true}*/}
                                            {/*        type={"text"}*/}
                                            {/*        isValid={isValidMap["locationAddressDetail"]}*/}
                                            {/*        onChange={handleAddressDetailInput}*/}
                                            {/*    />*/}
                                            {/*</Col>*/}
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <TitleAndTextArea
                                                title={t("common.description")}
                                                value={requestBody["branchDescription"]}
                                                onChange={handleDescriptionInput}
                                                isForEdit={true}
                                                isValid={isValidMap["branchDescription"]}
                                                height={"250"}
                                            />
                                        </Row>
                                    </Container>
                                </div>
                                {/* bottom btn */}
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'right'
                                    }}
                                >
                                    {/* deletion */}
                                    <div>
                                        <RenderOnRole type={"DELETE"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                            <Link
                                                id={"delete-btn"}
                                                onClick={async () => {
                                                    await setIsDeleteConfirmOpen(true);
                                                }}
                                            >
                                                {t("button.delete")}
                                            </Link>
                                            {/* deletion modal */}
                                            <DeleteConfirmModal
                                                isOpen={isDeleteConfirmOpen}
                                                setIsOpen={setIsDeleteConfirmOpen}
                                                isDelayed={true}
                                                confirmMessage={t("language.message.deletion.confirmation", { entityName : branchInfo['branchName'] })}
                                                entity={DeleteService.ValidEntities.BRANCH}
                                                entityName={branchInfo['branchName']}
                                                id={`branch/${branchId}`}
                                                onDeleteSuccess={async () => {
                                                    await navigate('/branch');
                                                }}
                                                depsToShow={[
                                                    t("language.message.deletion.depsList.branch.couponHistory"),
                                                    t("language.message.deletion.depsList.branch.sales"),
                                                    t("language.message.deletion.depsList.branch.branchMenuDiff"),
                                                    t("language.message.deletion.depsList.branch.review")
                                                ]}
                                                validateMessage={t("language.message.deletion.verificationMessage", { entityName : branchInfo['branchName'] })}
                                                delayToUndo={DeleteService.getMediumDelay()}
                                                onUndoSuccess={async () => {
                                                    const currLocation = location.pathname;
                                                    await navigate(currLocation);
                                                }}
                                            />
                                        </RenderOnRole>
                                    </div>
                                    <div
                                        style={{
                                            marginRight: '10px',
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        <Link
                                            id={"edit-btn"}
                                            to={`/branch/detail/${branchId}`}
                                        >
                                            {t("button.cancel")}
                                        </Link>
                                    </div>
                                    <div>
                                        {
                                            (isFranchiseEdited || isEdited) && isValid
                                                ?
                                                <Link
                                                    id={"save-btn"}
                                                    onClick={() => putBranchInfo()}
                                                >
                                                    {
                                                        isSaveLoading ?
                                                            <Spinner size={"sm"} />
                                                            :
                                                            t("button.save")
                                                    }
                                                </Link>
                                                :
                                                <Link
                                                    id={"save-btn-disabled"}
                                                >
                                                    {t("button.save")}
                                                </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default BranchEditUSA;