import React, {useEffect, useState} from 'react';
import {Container, Row} from "react-bootstrap";
import Modal from "react-modal";
import {useTranslation} from "react-i18next";
import axios from "axios";
import uuid from "react-uuid";
import styled from "styled-components";
import DataCompareService from "../../../services/DataCompareService";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../common/dataView/TitleAndTextArea";
import UserService from '../../../services/UserService';
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";
import ModalCloseButton from "../../common/modal/ModalCloseButton";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '800px',
        height: '600px',
        margin: 'auto'
    }
}

const DeleteButtonDisabled = styled.button`
  width: 100%;
  border: none;
  color: #757575;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #757575;
  font-size: 12px;
  transition: all ease-in-out 0.2s;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled.button`
  width: 100%;
  border: none;
  color: red;
  background-color: white;
  border-radius: 5px;
  border: 1px solid red;
  font-size: 12px;
  transition: all ease-in-out 0.2s;
  outline: none;

  &:hover {
    background-color: red;
    color: white;
  }

  &:focus {
    outline: none;
  }
`;

const requestBodyInit = {
    groupName: "",
    description: "",
};

const isValidMapInit = {
    groupName: true,
    description: true,
};

const GroupEdit = ({ isOpen, setIsOpen, onRefresh }) => {

    const { t, i18n } = useTranslation();
    /* groupList */
    const [groupList, setGroupList] = useState([]);
    const [selectedGroupIndex, setSelectedGroupIndex] = useState(-1);
    const [isEdited, setIsEdited] = useState(false);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);

    /* initialize */
    const initialize = () => {
        fetchGroupList();
        setSelectedGroupIndex(-1);
    };

    /* fetch groupList */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setGroupList(res.data.item.groupList);
                console.log(groupList);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const extractFields = (obj) => {
        return {
            groupName: obj.groupName,
            description: obj.description,
        };
    };


    /* handlers */
    const handleGroupNameInput = (value) => {
        updateRequestBody("groupName", value);
    };


    const handleDescriptionInput = (value) => {
        updateRequestBody("description", value);
    };


    /* validators */
    const checkGroupNameValidity = () => {
        console.log(isValidMap);
        if (requestBody["groupName"] === "") {
            updateIsValidMap("groupName", false);
        } else {
            updateIsValidMap("groupName", true);
        }
    };

    /* put group */
    const putGroup = async () => {
        try {
            let requestBodyJsonObj = extractFields(requestBody);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group/${groupList[selectedGroupIndex].groupId}`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBodyJsonObj),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await onRefresh();
            await initialize();
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* effects */
    useEffect(() => {
        if (selectedGroupIndex === -1) {
            setIsEdited(false);
            setIsValid(false);
        } else {
            setRequestBody(DataCompareService.deepCopyObj(groupList[selectedGroupIndex]));
            setIsEdited(false);
        }
    }, [selectedGroupIndex]);

    useEffect(() => {
        if (selectedGroupIndex === -1) {
            setIsEdited(false);
        } else {
            setIsEdited(!DataCompareService.isSameObj(requestBody, groupList[selectedGroupIndex]));
        }
    }, [requestBody]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        checkGroupNameValidity();
    }, [requestBody["groupName"]]);


    useEffect(() => {
        initialize();
    }, [isOpen]);

    /* renderer */
    const renderGroup = (groupInfo, index) => {
        return (
            <div
                key={uuid()}
                style={{
                    width: '100%',
                    height: '50px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    border: '1px solid',
                    borderColor: (selectedGroupIndex === index) ? "#fc7242" : "#757575",
                    backgroundColor: (selectedGroupIndex === index) ? "#fc7242" : "white",
                    display: 'flex',
                    flexDirection: 'column'
                }}
                onClick={() => {
                    if (selectedGroupIndex === index) {
                        setSelectedGroupIndex(-1);
                    } else {
                        setSelectedGroupIndex(index);
                    }
                }}
            >
                <div
                    style={{
                        width: '100%',
                        paddingLeft: '20px'
                    }}
                >
                    <span
                        style={{
                            color: (groupInfo.masterGroup === true) ? "blue" : (selectedGroupIndex === index) ? "white" : "#757575",
                            fontSize: '15px',
                            fontWeight: 'bold'
                        }}
                    >
                        {groupInfo.groupName}
                    </span>
                </div>
                <div
                    style={{
                        width: '100%',
                        paddingLeft: '20px'
                    }}
                >
                    <span
                        style={{
                            color: (selectedGroupIndex === index) ? "white" : "#757575",
                            fontSize: '13px'
                        }}
                    >
                        {groupInfo.description}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                onRefresh();
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.groupCompany.group.edit.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex'
                        }}
                    >
                        <div
                            style={{
                                width: '49%',
                                height: '500px',
                                overflowY: 'auto',
                                padding: '5px',
                                marginRight: '1%'
                            }}
                        >
                            {
                                groupList.map((group, index) => {
                                    return renderGroup(group, index);
                                })
                            }
                        </div>
                        <div
                            style={{
                                width: '50%',
                                height: '500px',
                                padding: '5px',
                                border: '1px solid #ebebeb',
                                borderRadius: '10px'
                            }}
                        >
                            {
                                (selectedGroupIndex === -1) ?
                                    <div
                                        style={{
                                            width: '100%',
                                            padding: '10px'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: '#757575',
                                                fontSize: '13px'
                                            }}
                                        >
                                            {t("message.chooseGroup")}
                                        </span>
                                    </div>
                                    :
                                    <Container fluid>
                                        <Row
                                            style={{
                                                marginTop: '5px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.name")}
                                                value={requestBody["groupName"]}
                                                onChange={handleGroupNameInput}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["groupName"]}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '5px'
                                            }}
                                        >
                                            <TitleAndTextArea
                                                title={t("common.description")}
                                                value={requestBody["description"]}
                                                onChange={handleDescriptionInput}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["description"]}
                                                height={"80"}
                                            />
                                        </Row>

                                        <Row
                                            style={{
                                                marginTop: '20px'
                                            }}
                                        >
                                            {
                                                (isEdited && isValid)
                                                ?
                                                    <button
                                                        id={"long-save-btn"}
                                                        onClick={() => putGroup()}
                                                    >
                                                        {t("button.save")}
                                                    </button>
                                                    :
                                                    <button
                                                        id={"long-save-btn-disabled"}
                                                    >
                                                        {t("button.save")}
                                                    </button>
                                            }
                                        </Row>
                                    </Container>
                            }
                        </div>
                    </div>
                </Row>
            </Container>
        </Modal>
    );
};

export default GroupEdit;