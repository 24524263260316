import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import UserService from "../../services/UserService";
import axios from "axios";
import _ from "lodash";
import {Col, Container, Row} from "react-bootstrap";
import styled from "styled-components";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import {value} from "lodash/seq";
import DataCompareService from "../../services/DataCompareService";
import {useTranslation} from "react-i18next";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../services/DeleteService";

const requestBodyInit = {
    adminDepartmentName: "",
    description: ""
};

const isValidMapInit = {
    adminDepartmentName: true,
    description: true
};


const DeleteButton = styled.button`
  width: 150px;
  border: 1px solid #e64545;
  border-radius: 10px;
  color: #e64545;
  padding: 7px;
  font-size: 14px;
  background-color: white;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #e6b8b8;
  }
`;

const DeleteButtonDisabled = styled.button`
  width: 150px;
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  color: #c2c2c2;
  padding: 7px;
  font-size: 14px;
  background-color: white;

  &:focus {
    outline: none;
  }
`;

const SaveButton = styled.button`
  width: 150px;
  border: 1px solid #fc7242;
  border-radius: 10px;
  color: #fc7242;
  padding: 7px;
  font-size: 14px;
  background-color: white;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #fcf9ed;
  }
`;

const SaveButtonDisabled = styled.button`
  width: 150px;
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  color: #c2c2c2;
  padding: 7px;
  font-size: 14px;
  background-color: white;

  &:focus {
    outline: none;
  }
`;

const DepartmentInner = () => {

    const { companyId } = useParams();
    const { t } = useTranslation();

    /* states */
    /* data */
    const [departmentList, setDepartmentList] = useState([]);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    /* isEdited, isValid */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);
    /* select */
    const [selectedIndex, setSelectedIndex] = useState(-1);

    /* deletion */
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    /* fetch department list */
    const fetchDepartmentList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company/${companyId}/department`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if ((res.data["error_code"] !== "GET_EMPTY_DATA") && (res.data.item.departmentList.length > 0)) {
                let newDepartmentList = res.data.item.departmentList.sort((ol, or) => sortHelper("adminDepartmentId", ol, or));
                await setDepartmentList(newDepartmentList);
                setSelectedIndex(0);
            } else {
                setDepartmentList([]);
                setSelectedIndex(-1);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchDepartmentListWithOutChange = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company/${companyId}/department`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if ((res.data["error_code"] !== "GET_EMPTY_DATA") && (res.data.item.departmentList.length > 0)) {
                let newDepartmentList = res.data.item.departmentList.sort((ol, or) => sortHelper("adminDepartmentId", ol, or));
                await setDepartmentList(newDepartmentList);
            } else {
                await setDepartmentList([]);
            }
            await setIsEdited(false);
        } catch (e) {
            console.log(e);
        }
    };

    /* put department */
    const putDepartment = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company/${companyId}/department/${departmentList[selectedIndex]['adminDepartmentId']}`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await fetchDepartmentListWithOutChange();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
        }
    };

    /** deprecated */
    /* delete department */
    // const deleteDepartment = async () => {
    //     try {
    //         let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company/${companyId}/department/${departmentList[selectedIndex]['adminDepartmentId']}`;
    //         await UserService.updateToken(() => axios.delete(
    //             fetchUrl,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
    //                 }
    //             }
    //         ));
    //         toast.success(<ToastAlertView message={t("message.saved")} />);
    //         fetchDepartmentList();
    //     } catch (e) {
    //         console.log(e);
    //         toast.error(<ToastAlertView message={t("message.saveFailed")} />);
    //     }
    // };

    /* utils */
    const sortHelper = (key, objL, objR) => {
        let nameA = objL[key].toUpperCase();
        let nameB = objR[key].toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    };
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    const handleDepartmentNameInput = (value) => {
        updateRequestBody('adminDepartmentName', value);
        if (value === "") {
            updateIsValidMap('adminDepartmentName', false);
        } else {
            updateIsValidMap('adminDepartmentName', true);
        }
    };

    const handleDepartmentDescriptionInput = (value) => {
        updateRequestBody('description', value);
        if (value === "") {
            updateIsValidMap('description', false);
        } else {
            updateIsValidMap('description', true);
        }
    }

    /* effects */
    useEffect(() => {
        fetchDepartmentList();
    }, [companyId]);

    useEffect(() => {
        if (selectedIndex === -1) {
            setRequestBody(requestBodyInit);
            setFixedRequestBody(requestBodyInit);
            setIsValidMap(isValidMapInit);
            setIsValid(true);
            setIsEdited(false);
        } else {
            let newRequestBody = {};
            newRequestBody['adminDepartmentName'] = departmentList[selectedIndex]['adminDepartmentName'];
            newRequestBody['description'] = departmentList[selectedIndex]['description'];
            setRequestBody(newRequestBody);
            setFixedRequestBody(_.cloneDeep(newRequestBody));
            setIsValidMap(isValidMapInit);
            setIsValid(true);
            setIsEdited(false);
        }
    }, [selectedIndex]);

    /* track isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    /* track isEdited */
    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);

    /* renderers */
    const renderDepartmentList = () => {
        if (departmentList.length === 0) {
            return (
                <div
                    style={{
                        padding: '30px'
                    }}
                >
                    <span
                        style={{
                            fontSize: '14px',
                            color: '#757575'
                        }}
                    >
                        {t("menu.companyDepartment.messages.noDept")}
                    </span>
                </div>
            );
        }
        let departmentElements = [];
        departmentList.map((deptInfo, index) => {
            departmentElements.push(
                <div
                    key={deptInfo['adminDepartmentId']}
                    style={{
                        width: '100%'
                    }}
                >
                    <button
                        className={"groupListButton" + ((selectedIndex === index) ? "-selected" : "")}
                        onClick={() => setSelectedIndex(index)}
                    >
                        {deptInfo['adminDepartmentName']}
                    </button>
                </div>
            );
        });
        return departmentElements;
    };

    const renderDepartmentSpec = () => {

        if (departmentList.length === 0) {
            return (
                <div
                    style={{
                        padding: '30px'
                    }}
                >
                    <span
                        style={{
                            fontSize: '14px',
                            color: '#757575'
                        }}
                    >
                        {t("menu.companyDepartment.messages.selectDept")}
                    </span>
                </div>
            );
        }

        return (
            <Container fluid>
                <Row>
                    <TitleAndInputBox
                        title={t("menu.companyDepartment.detail.departmentName")}
                        value={requestBody['adminDepartmentName']}
                        onChange={handleDepartmentNameInput}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap['adminDepartmentName']}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndTextArea
                        title={t("menu.companyDepartment.detail.description")}
                        value={requestBody['description']}
                        onChange={handleDepartmentDescriptionInput}
                        isForEdit={true}
                        isValid={isValidMap['description']}
                        height={"200"}
                    />
                    <span
                        style={{
                            color: '#757575',
                            fontSize: '14px',
                            marginLeft: '10px'
                        }}
                    >
                        {departmentList[selectedIndex]['adminCount'] + t("menu.companyDepartment.detail.adminCount")}
                    </span>
                </Row>
                <Row
                    style={{
                        marginTop: '70px'
                    }}
                >
                    {/* buttons */}
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center'
                        }}
                    >
                        {
                            (departmentList[selectedIndex]['adminCount'] === 0) ?
                                <>
                                    <DeleteButton
                                        onClick={() => setIsDeletionModalOpen(true)}
                                    >
                                        {t("button.delete")}
                                    </DeleteButton>
                                    <DeleteConfirmModal
                                        isOpen={isDeletionModalOpen}
                                        setIsOpen={setIsDeletionModalOpen}
                                        isDelayed={false}
                                        confirmMessage={t("language.message.deletion.confirmation", { entityName : departmentList[selectedIndex]['adminDepartmentName'] })}
                                        entity={DeleteService.ValidEntities.ADMIN_DEPARTMENT}
                                        entityName={departmentList[selectedIndex]['adminDepartmentName']}
                                        id={`company/${companyId}/department/${departmentList[selectedIndex]['adminDepartmentId']}`}
                                        onDeleteSuccess={async () => {
                                            await fetchDepartmentList();
                                        }}
                                    />
                                </>
                                :
                                <DeleteButtonDisabled>
                                    {t("button.delete")}
                                </DeleteButtonDisabled>
                        }
                        {
                            isEdited && isValid ?
                                <SaveButton
                                    style={{
                                        marginLeft: 'auto'
                                    }}
                                    onClick={putDepartment}
                                >
                                    {t("button.save")}
                                </SaveButton>
                                :
                                <SaveButtonDisabled
                                    style={{
                                        marginLeft: 'auto'
                                    }}
                                >
                                    {t("button.save")}
                                </SaveButtonDisabled>
                        }
                    </div>
                </Row>
            </Container>
        );
    };

    return (
        <Container fluid>
            <Row>
                <Col
                    md={"3"}
                    style={{
                        padding: '0px'
                    }}
                >
                    <div
                        className={"contentInner"}
                        style={{
                            width: '100%',
                            height: '475px',
                            overflow: 'auto'
                        }}
                    >
                        {/* department list */}
                        {renderDepartmentList()}
                    </div>
                </Col>
                <Col
                    md={"9"}
                    style={{
                        padding: '0px',
                        paddingLeft: '10px'
                    }}
                >
                    <div
                        className={"contentInner"}
                        style={{
                            width: '100%',
                            height: '475px'
                        }}
                    >
                        {/* department spec */}
                        {renderDepartmentSpec()}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default DepartmentInner;