import React, {useEffect, useState} from 'react';
import './FranchiseAppApiKeyManagementStyle.css';
import UserService from "../../services/UserService";
import NotAllowed from "../../auth/NotAllowed";
import {useTranslation} from "react-i18next";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {Col, Container, Row} from "react-bootstrap";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import _ from "lodash";
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import {CiSquareMinus, CiSquarePlus} from "react-icons/ci";
import IssueApiKeyModal from "./modals/IssueApiKeyModal";
import NoFranchise from "../franchise/NoFranchise";
import RenderOnRole from "../../auth/RenderOnRole";
import AuthService from "../../services/AuthService";

const IssueApiKeyButton = styled.button`
  width: 100%;
  border: 1px solid #fc7242;
  border-radius: 10px;
  font-size: 14px;
  padding: 7px;
  background-color: white;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #fcf9ed;
  }
`;

const IssueApiKeyButtonDisabled = styled.button`
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  font-size: 14px;
  padding: 7px;
  background-color: white;
  color: #ebebeb;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
`;

const FranchiseAppApiKeyManagement = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    /* states */
    const [groupList, setGroupList] = useState([]); /* for group labeling */
    const [franchiseList, setFranchiseList] = useState([]);

    /* modals */
    const [isKeyIssueModalOpen, setIsKeyIssueModalOpen] = useState(false);

    /* utils */
    const hasIdInPath = (id) => {
        return location.pathname.includes(id);
    };

    const sortHelper = (key, objL, objR) => {
        let nameA = objL[key].toUpperCase();
        let nameB = objR[key].toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    };

    /* fetch group list */
    const fetchGroupList = async (successCallBack) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setGroupList(res.data.item.groupList);
            successCallBack();
        } catch (e) {
            console.log(e);
        }
    };

    /* fetch franchise list */
    const fetchFranchiseList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newFranchiseList = res.data.item.franchiseList;
            await setFranchiseList(newFranchiseList);
            /* default redirection */
            if ((newFranchiseList.length > 0) && (location.pathname.split('/').length < 4)) {
                let defaultGroupId = Object.keys(newFranchiseList[0])[0];
                let defaultFranchiseId = newFranchiseList[0][defaultGroupId][0]['franchiseId'];
                navigate(`/franchise-app-api/group/${defaultGroupId}/franchise/${defaultFranchiseId}`);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* craft Accordion data from groupList and franchiseList */
    const craftAccordionData = () => {
        let newAccordionData = [];
        for (let i=0 ; i<groupList.length ; i++) {
            let newGroupData = {};
            newGroupData['groupInfo'] = groupList[i];
            newGroupData['franchiseList'] = [];
            franchiseList.map(groupFranchiseObj => {
                let tempList = Object.keys(groupFranchiseObj);
                let tempGroupId = tempList[0];
                if (tempGroupId === groupList[i]['groupId']) {
                    newGroupData['franchiseList'] = groupFranchiseObj[tempGroupId];
                }
            });
            newAccordionData.push(newGroupData);
        }
        return newAccordionData;
    };

    /* refresh function */
    const refreshAll = async (redirectUrl) => {
        await fetchGroupList(fetchFranchiseList);
        if (!(typeof redirectUrl === "undefined") && !(typeof redirectUrl == null)) {
            navigate(redirectUrl);
        }
    };

    /* effect for initialization */
    useEffect(() => {
        fetchGroupList(fetchFranchiseList);
    }, []);

    /* render AccordionBody */
    const renderAccordionBody = (groupId, franchiseList) => {
        let franchiseElement = [];
        let franchiseListSorted = _.cloneDeep(franchiseList);
        franchiseListSorted.sort((ol, or) => sortHelper('franchiseName', ol, or));
        franchiseListSorted.map(franchiseInfo => {
            franchiseElement.push(
                <div
                    key={franchiseInfo['franchiseId']}
                    style={{
                        width: '100%',
                        marginBottom: '5px'
                    }}
                >
                    <button
                        className={"groupListButton" + (hasIdInPath(franchiseInfo['franchiseId']) ? "-selected" : "")}
                        onClick={() => navigate(`/franchise-app-api/group/${groupId}/franchise/${franchiseInfo['franchiseId']}`)}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            {franchiseInfo['franchiseName']}
                        </div>
                    </button>
                </div>
            );
        });
        return franchiseElement;
    };

    /* render Accordion */
    const renderAccordion = () => {
        if ((groupList.length === 0) || (franchiseList.length === 0)) {
            return (<></>);
        } else {
            let totalFranchiseNum = 0;
            franchiseList.map(groupFranchise => {
                let tempGroupId = Object.keys(groupFranchise)[0];
                totalFranchiseNum += groupFranchise[tempGroupId].length;
            });
            let newAccordionData = craftAccordionData();
            return (
                <Accordion>
                    {/* per group */}
                    {
                        newAccordionData.map(groupData => {
                            if (groupData['franchiseList'].length === 0) {
                                return (<></>);
                            }
                            return (
                                <AccordionItem
                                    isActive={hasIdInPath(groupData['groupInfo']['groupId'])}
                                    key={groupData['groupInfo']['groupId'] + totalFranchiseNum}
                                >
                                    {
                                        ({open}) => (
                                            <>
                                                <AccordionHeader
                                                    className={"groupAccordionHeaderBtn" + (open ? "-selected" : "")}
                                                    onClick={() => navigate(`/franchise-app-api/group/${groupData['groupInfo']['groupId']}/franchise/${groupData['franchiseList'][0]['franchiseId']}`)}
                                                >
                                                    {/* group name and expand or shrink button */}
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            borderBottom: '1px solid #ebebeb',
                                                            paddingLeft: '5px',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            paddingRight: '5px',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: '14px',
                                                                marginLeft: '5px'
                                                            }}
                                                        >
                                                            {groupData['groupInfo']['groupName']}
                                                        </span>
                                                        <span
                                                            style={{
                                                                marginLeft: 'auto'
                                                            }}
                                                        >
                                                           {
                                                               open ?
                                                                   <CiSquareMinus
                                                                       size={"25"}
                                                                   />
                                                                   :
                                                                   <CiSquarePlus
                                                                       size={"25"}
                                                                   />
                                                           }
                                                       </span>
                                                    </div>
                                                </AccordionHeader>
                                                <AccordionBody>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            padding: '0px',
                                                            paddingTop: '5px'
                                                        }}
                                                    >
                                                        {renderAccordionBody(groupData['groupInfo']['groupId'], groupData['franchiseList'])}
                                                    </div>
                                                </AccordionBody>
                                            </>
                                        )
                                    }
                                </AccordionItem>
                            );
                        })
                    }
                </Accordion>
            );
        }
    };

    const renderNoFranchise = () => {
        return (<NoFranchise />);
    }

    return (
        <RenderOnRole roleName={""} type={""} allowedRing={1}>
            <div className={"headingTitle"}>
                <BackwardButton isShow={false} />
                <PageTitleWithDepth
                    titles={[ t("menu.franchiseApiKey.title") ]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    <Row>
                        {/* accordion */}
                        <Col
                            md={"2"}
                            style={{
                                padding: '0px'
                            }}
                        >
                            {/* issue a new key button */}
                            <div
                                className={"contentInner"}
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                {
                                    AuthService.hasAuthForOverRingOne() ?
                                        <IssueApiKeyButton
                                            onClick={() => setIsKeyIssueModalOpen(true)}
                                        >
                                            {t("menu.franchiseApiKey.issueKey.title")}
                                        </IssueApiKeyButton>
                                        :
                                        <IssueApiKeyButtonDisabled>
                                            {t("menu.franchiseApiKey.issueKey.title")}
                                        </IssueApiKeyButtonDisabled>
                                }
                            </div>
                            {/* actual accordion */}
                            <div
                                className={"contentInner"}
                                style={{
                                    marginTop: '10px',
                                    height: '720px',
                                    overflow: 'auto'
                                }}
                            >
                                <div>
                                    {
                                        (groupList.length > 0) && (franchiseList.length > 0) &&
                                        renderAccordion()
                                    }
                                    {
                                        ((groupList.length === 0) || (franchiseList.length === 0)) &&
                                        renderNoFranchise()
                                    }
                                </div>
                            </div>
                        </Col>
                        {/* spec */}
                        <Col
                            md={"10"}
                            style={{
                                padding: '0px',
                                paddingLeft: '10px'
                            }}
                        >
                            {/* franchise spec */}
                            <div
                                style={{
                                    width: '100%',
                                    padding: '0px'
                                }}
                            >
                                <Outlet
                                    context={{
                                        refreshAll
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* modals */}
            <IssueApiKeyModal
                isOpen={isKeyIssueModalOpen}
                setIsOpen={setIsKeyIssueModalOpen}
                onRefresh={async (redirectUrl) => {
                    await fetchGroupList(fetchFranchiseList);
                    if (redirectUrl != null) {
                        navigate(redirectUrl);
                    }
                }}
            />
        </RenderOnRole>
    );

};

export default FranchiseAppApiKeyManagement;