import React from 'react';
import {useTranslation} from "react-i18next";
import DateUtils from "../../../../../../schedule/utils/DateUtils";

const DateTimeCell = ({ value }) => {

    const { i18n } = useTranslation();

    return (
        <span
            style={{
                fontSize: '14px',
                color: '#757575'
            }}
        >
            {DateUtils.craftLocaleDateTimeString(value, i18n.language)}
        </span>
    );
};

export default DateTimeCell;