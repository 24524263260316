import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import DataCompareService from "../../../services/DataCompareService";
import UserService from "../../../services/UserService";
import Modal from "react-modal";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import LanguageInput from "../../../language/component/LanguageInput";
import LanguageSelectBox from "../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '500px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    qnaCategoryName: "",
    qnaCategoryName1: null,
    qnaCategoryName2: null,
    qnaCategoryName3: null,
    qnaCategoryName4: null,
    qnaCategoryName5: null,
    qnaCategoryDescription: "",
    qnaCategoryDescription1: null,
    qnaCategoryDescription2: null,
    qnaCategoryDescription3: null,
    qnaCategoryDescription4: null,
    qnaCategoryDescription5: null
};

const isValidMapInit = {
    qnaCategoryName: false,
    qnaCategoryName1: true,
    qnaCategoryName2: true,
    qnaCategoryName3: true,
    qnaCategoryName4: true,
    qnaCategoryName5: true,
    qnaCategoryDescription: false,
    qnaCategoryDescription1: true,
    qnaCategoryDescription2: true,
    qnaCategoryDescription3: true,
    qnaCategoryDescription4: true,
    qnaCategoryDescription5: true
};

const QnaCategoryAdd = ({ isOpen, setIsOpen, onRefresh }) => {

    const { t, i18n } = useTranslation();

    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);

    /* state for language */
    const targetFieldsString = `"${t("common.name")}", "${t("common.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const postQnaCategory = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/qna-category`;
            await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await onRefresh();
            await initialize();
            await setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.error(e);
        }
    }

    const initialize = () => {
        setRequestBody(requestBodyInit);
        setIsValidMap(isValidMapInit);
    };

    useEffect(() => {
        initialize();
    }, [isOpen]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                onRefresh();
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.qna.category.add.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <LanguageSelectBox
                        title={t("language.selectBox.title.qnaCategory")}
                        languageMetadataList={languageMetadataList}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        setIsHighlightOn={setIsHighlightOn}
                        targetFieldString={targetFieldsString}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <LanguageInput
                        type={"text"}
                        title={t("common.name") + ` (${t("language.callName." + currentLang)})`}
                        dataKeyTranslationPath={"language.dataKey.qna.category.name"}
                        valueMap={requestBody}
                        updateValueMap={updateRequestBody}
                        isValidMap={isValidMap}
                        updateIsValidMap={updateIsValidMap}
                        isForEdit={true}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <LanguageInput
                        type={"textarea"}
                        title={t("common.description") + ` (${t("language.callName." + currentLang)})`}
                        dataKeyTranslationPath={"language.dataKey.qna.category.description"}
                        valueMap={requestBody}
                        updateValueMap={updateRequestBody}
                        isValidMap={isValidMap}
                        updateIsValidMap={updateIsValidMap}
                        isForEdit={true}
                        height={"170"}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {
                        (isValid)
                            ?
                            <button
                                id={"long-save-btn"}
                                onClick={() => postQnaCategory()}
                            >
                                {t("button.save")}
                            </button>
                            :
                            <button
                                id={"long-save-btn-disabled"}
                            >
                                {t("button.save")}
                            </button>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default QnaCategoryAdd;
