import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import UserService from "../../../services/UserService";
import axios from "axios";
import uuid from "react-uuid";
import {Col, Container, Image, Row} from "react-bootstrap";
import defaultBoxImage from "../../../icons/productDefaultPicture.png";
import DataParseService from "../../../services/DataParseService";
import PaginationHandler from "../../common/pagination/PaginationHandler";
import styled from "styled-components";

const ProductDeleteButton = styled.button`
  border: 1px solid red;
  background-color: white;
  border-radius: 5px;
  color: red;
  margin-right: 10px;
  background-color: #ffe6e6;
  
  &:focus {
    outline: none;
  }
`;

const SmallTitle = styled.span`
  color: black;
  font-size: 14px;
  font-weight: bold;
`;

const SmallContent = styled.span`
  color: #757575;
  font-size: 14px;
`;

const OrderProductAdd = ({ groupId, deliveryCharge, setIsValid, updateOrderProductList }) => {

    const { t, i18n } = useTranslation();
    /* all product list */
    const [allProductList, setAllProductList] = useState([]);
    const [currentProductList, setCurrentProductList] = useState([]);
    /* for pagination */
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);
    /* price */
    const [totalPrice, setTotalPrice] = useState(0);


    /* fetch all product list */
    const fetchProductList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product?`
                + `&page=${currentPageIndex}`
                + `&size=6`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setAllProductList(res.data.item.productList);
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            } else {
                setAllProductList([]);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const addProductIntoProductList = (productInfo) => {
        let newProductInfo = {
            productId : productInfo.productId,
            amount : 1.0,
            price : productInfo.productPrice,
            priceUnit : productInfo.productPriceUnit,
            productName : productInfo.productName,
            productName1 : productInfo.productName1,
            productName2 : productInfo.productName2,
            productName3 : productInfo.productName3,
            productName4 : productInfo.productName4,
            productName5 : productInfo.productName5,
            productUnit : productInfo.productUnit,
            productUnitAmount : productInfo.productUnitAmount,
        }
        setCurrentProductList(prevList => {
            let newList = [...prevList];
            newList.push(newProductInfo);
            return newList;
        })
    };

    const deleteFromAllProductList = (index) => {
        setCurrentProductList(prevList => {
            let newList = [...prevList];
            newList.splice(index, 1);
            return newList;
        });
    };

    /* utils */
    const isInProductList = (productId) => {
        for (let i=0 ; i<currentProductList.length ; i++) {
            if (currentProductList[i].productId === productId) {
                return true;
            }
        }
        return false;
    };

    const updateProductList = (index, key, value) => {
        setCurrentProductList(prevList => {
            let newList = [...prevList];
            newList[index][key] = value;
            return newList;
        });
    };

    /* handlers */
    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    };

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    };

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    };

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    };

    /* effects */
    useEffect(() => {
        fetchProductList();
    }, [currentPageIndex]);

    useEffect(() => {
        fetchProductList();
        setAllProductList([]);
        setCurrentProductList([]);
    }, [groupId]);

    useEffect(() => {
        let newPrice = 0;
        currentProductList.map(product => {
           newPrice = newPrice + (product['amount'] * product['price']);
        });
        setTotalPrice(newPrice);
        setIsValid(currentProductList.length > 0);
        updateOrderProductList(currentProductList);
    }, [currentProductList]);

    /* renderer */
    const firstRowRender = () => {
        if (allProductList.length === 0) {
            return (<></>);
        }
        if (allProductList.length < 3) {
            return allProductList.map((product, index) => {
                return (
                    <div
                        key={uuid()}
                        style={{
                            width: '30%',
                            height: '100%',
                            border: '1px solid',
                            borderColor: isInProductList(product.productId) ? "#fc7242" : "#757575",
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '15px'
                            }}
                        >
                            <Image
                                src={
                                    (product.productImageUrl == null) ?
                                        defaultBoxImage
                                        :
                                        `${product.productImageUrl}`
                                }
                                alt="Profile Image"
                                style={{ width: '80px', height: '80px', display: 'inline-block', border: '0'}}
                            />
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontSize: '10px'
                                }}
                            >
                                {product[t("language.dataKey.product.name")]}
                            </span>
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontWeight: 'bold'
                                }}
                            >
                                {
                                    product.productUnitAmount + " " + product.productUnit
                                }
                            </span>
                        </div>
                        {
                            (groupId === product.productGroupId) && (!isInProductList(product.productId)) ?
                                <button
                                    className={"smallAddBtn"}
                                    onClick={() => addProductIntoProductList(product)}
                                >
                                    {t("button.add")}
                                </button>
                                :
                                <button
                                    className={"smallAddBtn-disabled"}
                                >
                                    {t("button.add")}
                                </button>
                        }
                    </div>
                );
            })
        } else {
            let result = [];
            for (let i=0 ; i<3 ; i++) {
                result.push(
                    <div
                        key={uuid()}
                        style={{
                            width: '30%',
                            height: '100%',
                            border: '1px solid',
                            borderColor: isInProductList(allProductList[i].productId) ? "#fc7242" : "#757575",
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px 0px'
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <Image
                                src={
                                    (allProductList[i].productImageUrl == null) ?
                                        defaultBoxImage
                                        :
                                        `${allProductList[i].productImageUrl}`
                                }
                                alt="Profile Image"
                                style={{ width: '80px', height: '80px', display: 'inline-block', border: '0'}}
                            />
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontSize: '14px'
                                }}
                            >
                                {allProductList[i][t("language.dataKey.product.name")]}
                            </span>
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontWeight: 'bold'
                                }}
                            >
                                {
                                    allProductList[i].productUnitAmount + " " + allProductList[i].productUnit
                                }
                            </span>
                        </div>
                        {
                            (groupId === allProductList[i].productGroupId) && (!isInProductList(allProductList[i].productId)) ?
                                <button
                                    className={"smallAddBtn"}
                                    onClick={() => addProductIntoProductList(allProductList[i])}
                                >
                                    {t("button.add")}
                                </button>
                                :
                                <button
                                    className={"smallAddBtn-disabled"}
                                >
                                    {t("button.add")}
                                </button>
                        }
                    </div>
                );
            }
            return result;
        }
    };

    const secondRowRender = () => {
        if (allProductList.length === 0) {
            return (<></>);
        }
        if (allProductList.length <= 3) {
            return (<></>);
        } else {
            let result = [];
            for (let i=3 ; i<allProductList.length ; i++) {
                result.push(
                    <div
                        key={uuid()}
                        style={{
                            width: '30%',
                            height: '100%',
                            border: '1px solid',
                            borderColor: isInProductList(allProductList[i].productId) ? "#fc7242" : "#757575",
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px 0px'
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <Image
                                src={
                                    (allProductList[i].productImageUrl == null) ?
                                        defaultBoxImage
                                        :
                                        `${allProductList[i].productImageUrl}`
                                }
                                alt="Profile Image"
                                style={{ width: '80px', height: '80px', display: 'inline-block', border: '0'}}
                            />
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontSize: '14px'
                                }}
                            >
                                {allProductList[i][t("language.dataKey.product.name")]}
                            </span>
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontWeight: 'bold'
                                }}
                            >
                                {
                                    allProductList[i].productUnitAmount + " " + allProductList[i].productUnit
                                }
                            </span>
                        </div>
                        <div
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            {
                                (groupId === allProductList[i].productGroupId) && (!isInProductList(allProductList[i].productId)) ?
                                    <button
                                        className={"smallAddBtn"}
                                        onClick={() => addProductIntoProductList(allProductList[i])}
                                    >
                                        {t("button.add")}
                                    </button>
                                    :
                                    <button
                                        className={"smallAddBtn-disabled"}
                                    >
                                        {t("button.add")}
                                    </button>
                            }
                        </div>
                    </div>
                );
            }
            return result;
        }
    };

    const currentProductListRender = () => {
        if (currentProductList.length === 0) {
            return (
                <div
                    style={{
                        width: '100%',
                        padding: '30px',
                        textAlign: 'center'
                    }}
                >
                    <span
                        style={{
                            fontSize: '14px',
                            color: '#757575'
                        }}
                    >
                        {t("menu.order.add.noProduct")}
                    </span>
                </div>
            );
        }
        return currentProductList.map((product, index) => {
            return (
                <div
                    key={uuid()}
                    style={{
                        width: '100%',
                        height: '50px',
                        marginTop: '5px',
                        borderRadius: '10px',
                        display: 'flex',
                        border: '1px solid #c2c2c2',
                        backgroundColor: '#fafafa'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '40%',
                            height: '100%',
                            padding: '5px'
                        }}
                    >
                        <ProductDeleteButton
                            onClick={() => deleteFromAllProductList(index)}
                        >
                            {t("button.delete")}
                        </ProductDeleteButton>
                        <span
                            style={{
                                fontSize: '14px',
                                color: '#757575',
                                fontWeight: 'bold'
                            }}
                        >
                            {product[t("language.dataKey.product.name")]}
                        </span>
                        <span
                            style={{
                                fontSize: '14px',
                                color: '#c2c2c2',
                                marginLeft: '10px',
                                fontWeight: 'bold'
                            }}
                        >
                            {product.productUnitAmount + " " + product.productUnit}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '30%',
                            height: '100%',
                            padding: '5px'
                        }}
                    >
                        <input
                            className={"amountInput"}
                            type={"number"}
                            value={product.amount}
                            isForEdit={true}
                            onChange={(e) => {
                                if (parseFloat(e.target.value) < 0) {
                                    updateProductList(index, "amount", 1);
                                } else if (parseFloat(e.target.value) === 0) {
                                    deleteFromAllProductList(index);
                                } else {
                                    updateProductList(index, "amount", parseFloat(e.target.value));
                                }
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '30%',
                            height: '100%',
                            fontSize: '14px'
                        }}
                    >
                        {DataParseService.formatNumberWithCommas(product.amount * product.price) + " " + (product.priceUnit)}
                    </div>
                </div>
            );
        });
    };

    return (
        <div
            style={{
                width: '100%',
                height: '500px',
                display: 'flex'
            }}
        >
            {/* products */}
            <div
                style={{
                    width: '50%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '89%',
                        padding: '5px',
                        border: '1px solid #ebebeb',
                        borderRadius: '10px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '50%',
                            padding: '10px',
                            justifyContent: 'space-between'
                        }}
                    >
                        {
                            firstRowRender()
                        }
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '50%',
                            padding: '10px',
                            justifyContent: 'space-between'
                        }}
                    >
                        {
                            secondRowRender()
                        }
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        height: '1%'
                    }}
                />
                {/* pagination */}
                <div
                    style={{
                        marginTop: '10px',
                        width: '100%',
                        height: '50px'
                    }}
                >
                    <PaginationHandler
                        goBefore={handleGoBefore}
                        goAfter={handleGoAfter}
                        goFirst={handleGoFirst}
                        goLast={handleGoLast}
                        pageSize={6}
                        currentIndex={currentPageIndex}
                        maxIndex={maxPageIndex}
                        totalItemNumber={totalElements}
                    />
                </div>
            </div>
            <div
                style={{
                    width: '1%',
                    height: '100%',
                }}
            />
            {/* current product list */}
            <div
                style={{
                    width: '49%'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '300px',
                        border: '1px solid #ebebeb',
                        borderRadius: '10px',
                        padding: '5px',
                        overflow: 'auto'
                    }}
                >
                    {
                        currentProductListRender()
                    }
                </div>
                <div
                    style={{
                        width: '70%',
                        height: '150px',
                        padding: '5px',
                        marginTop: '10px',
                        marginLeft: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Container fluid>
                        {/* total product price */}
                        <Row>
                            <Col md={"4"}>
                                <div
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >
                                    <SmallTitle>
                                        {t("menu.order.detail.content.totalCost")}
                                    </SmallTitle>
                                </div>
                            </Col>
                            <Col md={"8"}>
                                <div
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >
                                    <SmallContent>
                                        {DataParseService.formatNumberWithCommas(totalPrice)}
                                    </SmallContent>
                                </div>
                            </Col>
                        </Row>
                        {/* delivery charge */}
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <Col md={"4"}>
                                <div
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >
                                    <SmallTitle>
                                        {t("menu.order.detail.content.deliveryCharge")}
                                    </SmallTitle>
                                </div>
                            </Col>
                            <Col md={"8"}>
                                <div
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >
                                    <SmallContent>
                                        {
                                            deliveryCharge === "" ?
                                                t("menu.order.add.deliveryChargeNotInserted")
                                                :
                                                DataParseService.formatNumberWithCommas(deliveryCharge)
                                        }
                                    </SmallContent>
                                </div>
                            </Col>
                        </Row>
                        {/* total cost */}
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <Col md={"4"}>
                                <div
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >
                                    <SmallTitle>
                                        {t("menu.order.detail.content.totalPrice")}
                                    </SmallTitle>
                                </div>
                            </Col>
                            <Col md={"8"}>
                                <div
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >
                                    <SmallContent>
                                        {
                                            (deliveryCharge === "" || deliveryCharge == null) ?
                                                DataParseService.formatNumberWithCommas(totalPrice)
                                                :
                                                DataParseService.formatNumberWithCommas(totalPrice + deliveryCharge)
                                        }
                                    </SmallContent>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default OrderProductAdd;