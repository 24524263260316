import React, {useEffect, useState} from 'react';
import uuid from "react-uuid";
import SchemaParser from "../../../utils/SchemaParser";
import styled from "styled-components";

const CategoryContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const Category = styled.div`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
`;

const CategoryButton = styled.button`
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid #e4e7ea;
  color: #212529;
  background-color: white;
  font-size: 13px;
  
  &:focus {
    outline: none;
  }
`;

const CategoryButtonSelected = styled.button`
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid #1a83ff;
  color: white;
  background-color: #1a83ff;
  font-size: 13px;
  
  &:focus {
    outline: none;
  }
`;

const CatStringField = ({ fieldKey, value, updateValue, isForEdit, pattern }) => {

    /* category list */
    const [categoryList, setCategoryList] = useState([]);

    const handleValueChange = (newValue) => {
        if (!isForEdit) {
            return;
        } else {
            updateValue(fieldKey, newValue);
        }
    };

    const renderCategories = () => {
        let categoryElements = [];
        categoryList.map(category => {
            if (category === value) {
                categoryElements.push(
                    <Category
                        key={uuid()}
                    >
                        <CategoryButtonSelected>
                            {category}
                        </CategoryButtonSelected>
                    </Category>
                );
            } else {
                categoryElements.push(
                    <Category
                        key={uuid()}
                    >
                        <CategoryButton
                            onClick={()=>handleValueChange(category)}
                        >
                            {category}
                        </CategoryButton>
                    </Category>
                );
            }
        });
        return categoryElements;
    };

    /* effects */
    /* initialization */
    useEffect(() => {
        setCategoryList(SchemaParser.extractListFromPattern(pattern));
    }, [pattern]);


    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* categories */}
            <div
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                <CategoryContainer>
                    {renderCategories()}
                </CategoryContainer>
            </div>
        </div>
    );
};

export default CatStringField;