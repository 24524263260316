import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import DataCompareService from "../../services/DataCompareService";
import {Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import Modal from "react-modal";
import UserService from '../../services/UserService';
import ModalCloseButton from "../common/modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";

/* backend endpoint : (PUT) /order/{orderId}/complete/register */

const requestBodyInit = {
    onShipCompleteDate : "",
    comment : ""
};

const isValidMapInit = {
    onShipCompleteDate : false,
    comment : false
};

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '400px',
        height: '330px',
        margin: 'auto'
    }
};

const RegisterComplete = ({ orderId, onRefresh, isOpen, setIsOpen }) => {

    const { t } = useTranslation();
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    /* handlers */
    const handleOnShipCompleteDateInput = (value) => {
        updateRequestBody("onShipCompleteDate", value);
    }

    const handleCommentInput = (value) => {
        updateRequestBody("comment", value);
    }

    /* validators */
    const validateOnShipCompleteDate = () => {
        if (requestBody["onShipCompleteDate"] === "") {
            updateIsValidMap("onShipCompleteDate", false);
        } else {
            updateIsValidMap("onShipCompleteDate", true);
        }
    }

    const validateComment = () => {
        if (requestBody["comment"] === "") {
            updateIsValidMap("comment", false);
        } else {
            updateIsValidMap("comment", true);
        }
    };

    /* register onShip information (PUT) */
    const putOnShipInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/order/${orderId}/complete/register`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            onRefresh();
            setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* effects */
    /* validate */
    useEffect(() => {
        validateOnShipCompleteDate();
    }, [requestBody["onShipCompleteDate"]]);

    useEffect(() => {
        validateComment();
    }, [requestBody["comment"]]);

    /* isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                onRefresh();
                setIsOpen(false);
            }}
        >
            <ModalCloseButton
                title={t("menu.order.detail.content.registerComplete")}
                setIsOpen={setIsOpen}
            />
            <div
                style={{
                    width: '100%',
                    height: '220px'
                }}
            >
                <Container fluid>
                    <Row>
                        <TitleAndInputBox
                            title={t("menu.order.detail.content.deliveryHistory.OnShipCompleteDate")}
                            value={requestBody["onShipCompleteDate"]}
                            onChange={handleOnShipCompleteDateInput}
                            isForEdit={true}
                            type={"date"}
                            isValid={isValidMap["onShipCompleteDate"]}
                            isEnabled={true}
                        />
                    </Row>
                    <Row
                        style={{ marginTop: '5px' }}
                    >
                        <TitleAndInputBox
                            title={t("menu.order.detail.content.deliveryHistory.comment")}
                            value={requestBody["comment"]}
                            onChange={handleCommentInput}
                            isForEdit={true}
                            type={"text"}
                            isValid={isValidMap["comment"]}
                            isEnabled={true}
                        />
                    </Row>
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                marginTop: '25px'
                            }}
                        >
                            {
                                isValid ?
                                    <button
                                        id={"long-save-btn"}
                                        onClick={() => putOnShipInfo()}
                                    >
                                        {t("button.save")}
                                    </button>
                                    :
                                    <button
                                        id={"long-save-btn-disabled"}
                                    >
                                        {t("button.save")}
                                    </button>
                            }
                        </div>
                    </Row>
                </Container>
            </div>
        </Modal>
    );
};

export default RegisterComplete;