import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import TitleAndColDiv from "../components/TitleAndColDiv";
import styled from "styled-components";
import UserService from "../../../../../../../services/UserService";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import RecipeUtils from "../../../../../common/jsonDynamic/util/RecipeUtils";
import TitleAndSelectBox from "../../../../../../common/dataView/TitleAndSelectBox";
import _ from "lodash";
import OptionRecipeInnerView from "./dynamic/OptionRecipeInnerView";

const MessageView = styled.div`
  text-align: center;
  padding: 10px 20px;
  color: #757575;
  font-size: 14px;
`;


/** props                                                                 */
/** 1.  selectedBoothVersionId : Indicate the selected booth version id.  */
/** 2.  menuRecipeList : A list of menuDetails, actually.                 */
/** 3.  boothVersionList : booth version list for confirmation modal.     */
/** 4.  menuInfo : menu information                                       */
/** 5.  refreshMenuList : refresh menu list                               */
const OptionRecipeView = ({ menuInfo, selectedBoothVersionId, boothVersionList, menuRecipeList, refreshMenuList }) => {

    const { t } = useTranslation();
    const { franchiseId, menuId } = useParams();

    /* states */
    const [optionList, setOptionList] = useState([]);
    const [selectedOptionId, setSelectedOptionId] = useState("");
    const [productJsonVersion, setProductJsonVersion] = useState("");

    /* loading */
    const [isLoading, setIsLoading] = useState(false);

    /* utils */
    const isDetailExist = (menuInfo, selectedBoothVersionId) => {
        if ((typeof menuInfo === "undefined") || (menuInfo == null)) {
            return false;
        }
        let isExist = false;
        menuInfo['franchiseMenuDetailList'].map(menuDetail => {
            if (selectedBoothVersionId === menuDetail['franchiseMenuDetailBoothVersionId']) {
                isExist = true;
            }
        });
        return isExist;
    };

    const extractProductJsonVersion = (menuInfo, selectedBoothVersionId) => {
        if ((typeof menuInfo === "undefined") || (menuInfo == null)) {
            return "None";
        }
        let newProductJsonVersion = "None";
        menuInfo['franchiseMenuDetailList'].map(menuDetail => {
            if (selectedBoothVersionId === menuDetail['franchiseMenuDetailBoothVersionId']) {
                newProductJsonVersion = menuDetail['franchiseMenuDetailProductJsonVersion'];
            }
        });
        return newProductJsonVersion;
    };

    const extractOptionMetadata = (optionInfoList) => {
        let metadataList = [];
        optionInfoList.map(optionInfo => {
            let newOptionInfo = _.cloneDeep(optionInfo['optionInfo']);
            newOptionInfo[t("language.dataKey.menu.option.name")] = `(${newOptionInfo['categoryInfo'][t("language.dataKey.menu.option.category.name")]}) ` + newOptionInfo[t("language.dataKey.menu.option.name")];
            metadataList.push(newOptionInfo);
        });
        return metadataList;
    };

    const handleOptionSelect = (newId) => {
        if (newId === selectedOptionId) {
            return;
        } else {
            setSelectedOptionId(newId);
        }
    };

    /* fetch menu option info */
    const fetchMenuOptionInfo = async (callback) => {
        await setIsLoading(true);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-menu`
                + `/franchise/${franchiseId}/menu/${menuId}/option`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newOptionList = res.data.item['franchiseMenuOptionList'];
            setOptionList(newOptionList);
            /* default */
            if ((selectedOptionId === "") && (newOptionList.length > 0)) {
                setSelectedOptionId(newOptionList[0]['optionInfo']['franchiseMenuOptionId']);
            }
            setIsLoading(false);
            callback();
        } catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    };

    const initializeStates = async () => {
        let newProductJson = extractProductJsonVersion(menuInfo, selectedBoothVersionId);
        await setProductJsonVersion(newProductJson);
        fetchMenuOptionInfo(() => {});
    };

    /* effects */
    useEffect(() => {
        initializeStates();
    }, [selectedBoothVersionId, menuId, menuInfo]);

    if (!isDetailExist(menuInfo, selectedBoothVersionId)) {
        /* The given menu has no recipe for the given boothVersion */
        return (
            <TitleAndColDiv
                title={t("menu.franchiseMenu.recipeInfo.optionRecipe.title")}
                marginBtw={"12"}
                marginTop={"3"}
                height={"700"}
            >
                <MessageView>
                    {t("menu.franchiseMenu.recipeInfo.optionRecipe.message.noMenuRecipe")}
                </MessageView>
            </TitleAndColDiv>
        );
    } else if (isLoading) {
        return (
            <TitleAndColDiv
                title={t("menu.franchiseMenu.recipeInfo.optionRecipe.title")}
                marginBtw={"12"}
                marginTop={"3"}
                height={"700"}
            >
                <Spinner size={"sm"} />
            </TitleAndColDiv>
        );
    } else if (!isLoading && (optionList.length === 0)) {
        return (
            <TitleAndColDiv
                title={t("menu.franchiseMenu.recipeInfo.optionRecipe.title")}
                marginBtw={"12"}
                marginTop={"3"}
                height={"700"}
            >
                <MessageView>
                    {t("menu.franchiseMenu.recipeInfo.optionRecipe.message.noOption")}
                </MessageView>
            </TitleAndColDiv>
        );
    } else {
        return (
            <>
                <TitleAndColDiv
                    title={t("menu.franchiseMenu.recipeInfo.optionRecipe.title")}
                    marginBtw={"12"}
                    marginTop={"3"}
                    height={"100"}
                >
                    {/* selection for the option */}
                    <TitleAndSelectBox
                        title={t("menu.franchiseMenu.recipeInfo.optionRecipe.field.optionSelect")}
                        innerValue={selectedOptionId}
                        valueList={extractOptionMetadata(optionList)}
                        valueSelector={"franchiseMenuOptionId"}
                        viewSelector={t("language.dataKey.menu.option.name")}
                        onChange={(value) => handleOptionSelect(value)}
                    />
                </TitleAndColDiv>
                <div
                    className={"contentInner"}
                    style={{
                        marginTop : '10px',
                        height: "590px"
                    }}
                >
                    <OptionRecipeInnerView
                        key={selectedOptionId}
                        selectedBoothVersionId={selectedBoothVersionId}
                        selectedOptionId={selectedOptionId}
                        optionInfoList={optionList}
                        productJsonVersion={productJsonVersion}
                        boothVersionList={boothVersionList}
                        refreshMenuList={refreshMenuList}
                        initializeParentState={initializeStates}
                    />
                </div>
            </>
        );
    }
};

export default OptionRecipeView;