import React from 'react';
import uuid from 'react-uuid'
import {useTranslation} from "react-i18next";

const TitleAndSelectBox = ({ title, innerValue, valueList, valueSelector, viewSelector, onChange }) => {

    const { t } = useTranslation();

    if (valueList == null || typeof valueList === "undefined" || valueList.length === 0) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            paddingLeft: '10px',
                            marginBottom: '5px'
                        }}
                    >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px'
                        }}
                    >
                        {title}
                    </span>
                    </div>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <select
                            id={"data-view-input"}
                            value={""}
                        >
                            <option>{t("common.noData")}</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            paddingLeft: '10px',
                            marginBottom: '5px'
                        }}
                    >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px'
                        }}
                    >
                        {title}
                    </span>
                    </div>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <select
                            id={"data-view-input"}
                            onChange={(e) => onChange(e.target.value)}
                            value={innerValue}
                        >
                            {
                                valueList.map(value => {
                                    return (
                                        <option
                                            value={value[valueSelector]}
                                            key={uuid()}
                                        >
                                            {value[viewSelector]}
                                        </option>
                                    );
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
        );
    }
};

export default TitleAndSelectBox;