import React from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import { ReactComponent as BackWardIcon } from "../../../icons/backWard.svg";
import { RxDividerVertical } from "react-icons/rx";
import { HiMiniEllipsisVertical } from "react-icons/hi2";

const TransButton = styled.button`
  border: none;
  background: none;
  padding-bottom: 5px;
  
  &:focus {
    outline: none;
  }
`;

const BackwardButton = ({ isShow, link }) => {

    const navigate = useNavigate();

    if (!isShow) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '15px'
                }}
            >
                <TransButton>
                    <HiMiniEllipsisVertical
                        size={"20"}
                    />
                </TransButton>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '20px'
                }}
            >
                <TransButton
                    onClick={() => navigate(link)}
                >
                    <BackWardIcon />
                </TransButton>
            </div>
        );
    }
};

export default BackwardButton;