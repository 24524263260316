import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {HiOutlineDocumentSearch} from "react-icons/hi";
import {Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import BoothTable from "../../branch/booth/BoothTable";
import Modal from "react-modal";


const colorMap = {
    "None" : "#c2c2c2",
    "Fatal" : "#cc3300",
    "Critical" : "#ff5c33",
    "Error" : "#ff9966",
    "Warning" : "#ffcc00",
    "Info" : "#99cc33",
    "Debug" : "#339900",
    "Unknown" : "#757575",
    "background" : {
        "Fatal" : "#ffcccc",
        "Critical" : "#ffd6cc",
        "Error" : "#fff2e6",
        "Warning" : "#fff5cc",
        "Info" : "#ecffe6",
        "Debug" : "#d6f5d6",
        "Unknown" : "#F0F0F0",
    }
};

const BoothVersion = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #24a0ed;
  background-color: #f3ffff;
  color: #24a0ed;
  padding: 5px 0px;
  width: 70%;
  font-size: 14px;
  text-align: center;
`;

const DetailButton = styled.button`
  border: none;
  background: none;
  color: #757575;
  padding: 0px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    color: #fc7242;
  }
  
  &:focus {
    outline: none;
  }
`;

const LevelButton = styled.button`
  color: ${props => props.color ? props.color : colorMap['None']};
  border: 1px solid ${props => props.color ? props.color : colorMap['None']};
  font-size: 14px;
  padding: 5px 10px;
  transition: all ease-in-out 0.2s;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : "white"};
  border-radius: 10px;
  margin-right: 10px;
  
  &:focus {
    outline: none;
  }
`;


const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '750px',
        height: '550px',
        margin: 'auto'
    }
};

const ERR_LEVELS = [
    "Debug",
    "Info",
    "Warning",
    "Error",
    "Critical",
    "Fatal",
    "Unknown"
];


const ErrorBoothListViewModal = ({ isOpen, setIsOpen, boothErrorInfoObj, title }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    /* states */
    const [boothList, setBoothList] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState("Fatal");

    const BoothVersionCell = (value) => {
        return (
            <BoothVersion>
                {value.value}
            </BoothVersion>
        );
    };

    const BoothDetailCell = (value) => {
        return (
            <DetailButton
                onClick={() => {
                    setIsOpen(false);
                    navigate(`/booth/detail/${value.value}/info`);
                }}
            >
                <HiOutlineDocumentSearch
                    size={"30"}
                />
            </DetailButton>
        );
    };

    const renameBoothIdToId = (boothList) => {
        boothList.map(boothInfo => {
            boothInfo['id'] = boothInfo['boothId'];
            return boothInfo;
        });
        return boothList;
    };

    /* table column data */
    const columnData = [
        {
            accessor: "serialNumber",
            Header: t("menu.branch.detail.booth.table.serial")
        },
        {
            accessor: "boothName",
            Header: t("menu.branch.detail.booth.table.boothName")
        },
        {
            accessor: 'boothVersion',
            Header: t("menu.branch.detail.booth.table.boothVersion"),
            Cell: ({ cell: { value } }) => <BoothVersionCell value={value} />
        },
        {
            accessor: 'boothId',
            Header: t("menu.branch.detail.booth.table.detail"),
            Cell: ({ cell: { value } }) => <BoothDetailCell value={value} />
        }
    ];

    /* effects */
    useEffect(() => {
        if (boothErrorInfoObj != null) {
            setBoothList(boothErrorInfoObj[selectedLevel.toLowerCase()]['boothList']);
        }
    }, [selectedLevel]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={title}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    {/* level selection */}
                    <div
                        style={{
                            width: '100%',
                            display: 'inline-flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }}
                    >
                        {
                            ERR_LEVELS.map(level => {
                                return (
                                    <LevelButton
                                        key={level}
                                        color={(selectedLevel === level) ? colorMap[level] : null}
                                        backgroundColor={(selectedLevel === level) ? colorMap['background'][level] : null}
                                        onClick={() => setSelectedLevel(level)}
                                    >
                                        {level}
                                    </LevelButton>
                                );
                            })
                        }
                    </div>
                </Row>
                {
                    boothList && (boothList.length > 0) ?
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            {/* table */}
                            <div
                                style={{
                                    height: '400px',
                                    width: '100%',
                                    overflow: 'auto'
                                }}
                            >
                                {
                                    boothList &&
                                    <BoothTable
                                        columns={columnData}
                                        data={renameBoothIdToId(boothList)}
                                    />
                                }
                            </div>
                        </Row>
                        :
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    padding: '50px'
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '14px',
                                        color: '#757575'
                                    }}
                                >
                                    {t("menu.dashboard.boothInfo.messages.noBooths")}
                                </span>
                            </div>
                        </Row>
                }
            </Container>
        </Modal>
    );
};

export default ErrorBoothListViewModal;