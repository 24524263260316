import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";


const InfoMessage = styled.span`
  font-size: 14px;
  color: #757575;
`;

const TargetFranchiseNotSelected = () => {

    const { t } = useTranslation();

    return (
        <div
            style={{
                width: '100%',
                height: '750px',
                display: 'inline-flex',
                paddingTop: '200px',
                justifyContent: 'center'
            }}
        >
            <InfoMessage>
                {t("menu.franchiseConsoleCred.message.noAccount")}
            </InfoMessage>
        </div>
    );
};

export default TargetFranchiseNotSelected;