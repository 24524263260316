import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import DataUtils from "../../common/utils/DataUtils";
import {CiSquarePlus} from "react-icons/ci";
import ExtraInfoItemView from "../../common/extrainfo/ExtraInfoItemView";
import styled from "styled-components";
import OverlayToolTip from "../../common/toolTip/OverlayToolTip";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import {FiLock, FiUnlock} from "react-icons/fi";

const AddButton = styled.button`
  border: none;
  background: none;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: #ffcccb;
  }
`;

const TransButton = styled.button`
  border: none;
  background: none;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  color: #757575;

  &:hover {
    color: ${(props) => props.enabled ? "black" : ""};
  }

  &:focus {
    outline: none;
  }
`;

const colorMap = {
    backgroundGrey : "#F8F8F8",
    defaultButtonColor : "#c2c2c2",
    defaultButtonHoverColor : "#F5F5F5",
    defaultButtonColorOnSelected : "#A9A9A9",
    diffUseButtonColor : "#F0B3FF",
    diffUseButtonHoverColor : "#FAE6FF",
    diffUseButtonColorOnSelected : "#CC00FF"
};

const OptionButton = styled.button`
  width: auto;
  padding: 3px 7px;
  color: ${(props) => props.color ? props.color : "#757575"};
  font-size: 14px;
  border: 1px solid ${(props) => props.color ? props.color : "#757575"};
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "white"};
  transition: all ease-in-out 0.2s;
  margin-left: ${(props) => props.marginLeft ? props.marginLeft : "0px"};
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.hoverColor ? props.hoverColor : "white"};
  }
`;

const ExtraInfoDiffView = ({ title,
                               originalDataObjList,
                               diffDataObjList,
                               updateDiffObjList,
                               updateIsValidMap,
                               diffObjKey,
                               height,
                               isForEdit,
                               hoverMessageForUseDiffButton }) => {

    const { t } = useTranslation();
    const { menuId } = useParams();

    /* states */
    const [genCount, setGenCount] = useState(1);

    /* handler */
    const handleAddInfo = async () => {
        if ((!isForEdit) || (diffDataObjList == null)) {
            return;
        }
        let generatedInfoKey = t("menu.franchiseMenu.menuInfo.detail.extraInfo.default.key") + " " + genCount;
        let generatedInfoValue = t("menu.franchiseMenu.menuInfo.detail.extraInfo.default.value") + " " + genCount;
        let genCountTemp = genCount;
        while (DataUtils.isObjKeyExistInObjArray(diffDataObjList, generatedInfoKey)) {
            generatedInfoKey = t("menu.franchiseMenu.menuInfo.detail.extraInfo.default.key") + " " + (genCountTemp + 1);
            generatedInfoValue = t("menu.franchiseMenu.menuInfo.detail.extraInfo.default.value") + " " + (genCountTemp + 1);
            genCountTemp = genCountTemp + 1;
        }
        setGenCount(genCountTemp + 1);
        let newDataObjList = [...diffDataObjList];
        let newObject = {};
        newObject[generatedInfoKey] = generatedInfoValue;
        newDataObjList.push(newObject);
        updateDiffObjList(newDataObjList);
        /* check validity */
        let newIsValid = true;
        for (let i=0 ; i < newDataObjList.length ; i++) {
            if ((Object.keys(newDataObjList[i])[0] === "") || (Object.values(newDataObjList[i])[0] === "")) {
                newIsValid = false;
            }
        }
        updateIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[diffObjKey] = newIsValid;
            return newObj;
        });
    };

    const handleKeyChange = (index, prevKey, newKey) => {
        let newDataObjList = [...diffDataObjList];
        newDataObjList[index][newKey] = newDataObjList[index][prevKey];
        delete newDataObjList[index][prevKey];
        updateDiffObjList(newDataObjList);
        /* check validity */
        let newIsValid = true;
        for (let i=0 ; i < newDataObjList.length ; i++) {
            if ((Object.keys(newDataObjList[i])[0] === "") || (Object.values(newDataObjList[i])[0] === "")) {
                newIsValid = false;
            }
        }
        updateIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[diffObjKey] = newIsValid;
            return newObj;
        });
    };

    const handleValueChange = (index, key, newValue) => {
        let newDataObjList = [...diffDataObjList];
        newDataObjList[index][key] = newValue;
        updateDiffObjList(newDataObjList);
        /* check validity */
        let newIsValid = true;
        for (let i=0 ; i < newDataObjList.length ; i++) {
            if ((Object.keys(newDataObjList[i])[0] === "") || (Object.values(newDataObjList[i])[0] === "")) {
                newIsValid = false;
            }
        }
        updateIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[diffObjKey] = newIsValid;
            return newObj;
        });
    };

    const handleDeleteObj = (index) => {
        let newDataObjList = [...diffDataObjList];
        newDataObjList.splice(index, 1);
        updateDiffObjList(newDataObjList);
        /* check validity */
        if (newDataObjList.length === 0) {
            updateIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj[diffObjKey] = true;
                return newObj;
            });
        } else {
            let newIsValid = true;
            for (let i=0 ; i < newDataObjList.length ; i++) {
                if ((Object.keys(newDataObjList[i])[0] === "") || (Object.values(newDataObjList[i])[0] === "")) {
                    newIsValid = false;
                }
            }
            updateIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj[diffObjKey] = newIsValid;
                return newObj;
            });
        }
    };

    const handleNoUseDiff = () => {
        if (!isForEdit) {
            return;
        }
        if (diffDataObjList == null) {
            return;
        }
        /* set null */
        updateDiffObjList(null);
        /* empty is valid one */
        updateIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[diffObjKey] = true;
            return newObj;
        });
    };

    const handleUseDiff = () => {
        if (!isForEdit) {
            return;
        }
        if (diffDataObjList != null) {
            return;
        }
        /* initial value set */
        updateDiffObjList(_.cloneDeep(originalDataObjList));
        /* valid one */
        updateIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[diffObjKey] = true;
            return newObj;
        });
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <div
                style={{
                    textAlign: 'left',
                    paddingLeft: '10px',
                    marginBottom: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {/* title */}
                <span
                    style={{
                        color: '#8f8f8f',
                        fontSize: '14px'
                    }}
                >
                    {title}
                </span>
                {/* diff use or not buttons */}
                <div
                    style={{
                        height: '100%',
                        display: 'inline-block',
                        marginLeft: "10px"
                    }}
                >
                    {
                        (diffDataObjList != null) &&
                        <OverlayToolTip
                            direction={"top"}
                            text={hoverMessageForUseDiffButton}
                        >
                            <TransButton
                                onClick={() => {
                                    if (diffDataObjList != null) {
                                        handleNoUseDiff();
                                    } else {
                                        handleUseDiff();
                                    }
                                }}
                            >
                                <FiUnlock
                                    size={"14"}
                                    color={"#fc7242"}
                                />
                            </TransButton>
                        </OverlayToolTip>
                    }
                    {
                        (diffDataObjList == null) &&
                        <TransButton
                            onClick={() => {
                                if (diffDataObjList != null) {
                                    handleNoUseDiff();
                                } else {
                                    handleUseDiff();
                                }
                            }}
                            enabled={true}
                        >
                            <FiLock
                                size={"14"}
                            />
                        </TransButton>
                    }
                </div>
                {/* add button */}
                <AddButton
                    style={{
                        marginLeft: 'auto'
                    }}
                    onClick={handleAddInfo}
                >
                    {
                        (diffDataObjList == null) ?
                            <CiSquarePlus
                                size={"25"}
                                color={"#ebebeb"}
                            />
                            :
                            <CiSquarePlus
                                size={"25"}
                            />
                    }
                </AddButton>
            </div>
            <div
                style={{
                    width: '100%',
                    height: height + 'px',
                    overflow: 'auto',
                    border: '1px solid #ebebeb',
                    color: '#696969',
                    borderRadius: '5px',
                    padding: '10px',
                    textAlign: 'center',
                    backgroundColor: (diffDataObjList == null) ? colorMap["backgroundGrey"] : "white"
                }}
            >
                {
                    (diffDataObjList == null) &&
                    (
                    (originalDataObjList.length > 0) ?
                        originalDataObjList.map((object, index) => {
                            return (
                                <ExtraInfoItemView
                                    key={index}
                                    item={object}
                                    index={index}
                                    handleKeyChange={() => {}}
                                    handleValueChange={() => {}}
                                    isForEdit={false}
                                    onDelete={() => {}}
                                    isDeletePossible={false}
                                />
                            );
                        })
                        :
                        <span
                            style={{
                                fontSize: '14px'
                            }}
                        >
                            {t("menu.franchiseMenu.message.noExtraInfo")}
                        </span>
                    )
                }
                {
                    (diffDataObjList != null) &&
                    (
                    (diffDataObjList.length > 0) ?
                        diffDataObjList.map((object, index) => {
                            return (
                                <ExtraInfoItemView
                                    key={index}
                                    item={object}
                                    index={index}
                                    handleKeyChange={handleKeyChange}
                                    handleValueChange={handleValueChange}
                                    isForEdit={isForEdit}
                                    onDelete={() => handleDeleteObj(index)}
                                />
                            );
                        })
                        :
                        <span
                            style={{
                                fontSize: '14px'
                            }}
                        >
                            {t("menu.franchiseMenu.message.noExtraInfo")}
                        </span>
                    )
                }

            </div>
        </div>
    );
};

export default ExtraInfoDiffView;