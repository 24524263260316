import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import RenderOnRole from "../../auth/RenderOnRole";
import AuthService from "../../services/AuthService";
import NotAllowed from "../../auth/NotAllowed";
import axios from "axios";
import "./CompanyRoleEditStyle.css";
import uuid from "react-uuid";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import UserService from '../../services/UserService';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import {MdKeyboardArrowDown} from "react-icons/md";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";

/* Not allowed for "company_user" (Keycloak role) */
const CompanyRoleEdit = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [groupAndCompanyList, setGroupAndCompanyList] = useState([]);


    /* utils */
    const hasIdInPath = (id) => {
        return location.pathname.includes(id);
    };

    const hasAnyCompanyInPath = (companyList) => {
        let isIn = false;
        companyList.map(company => {
           if (location.pathname.includes(company['companyId'])) {
               isIn = true;
           }
        });
        return isIn;
    }

    const findObjById = (id, list) => {
        for (let i=0 ; i<list.length ; i++) {
            if (list[i]['groupId'] === id) {
                return list[i];
            }
        }
    }

    const fetchCompanyList = async () => {
        try {
            let fetchUrl =
                `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            let newCompanyList = res.data.item.companyList;
            let newGroupList = [];
            let newGroupIdList = [];
            /* extract groups */
            newCompanyList.map(companyInfo => {
                if ((!companyInfo['masterCompany']) && (!newGroupIdList.includes(companyInfo['companyGroupId']))) {
                    newGroupIdList.push(companyInfo['companyGroupId']);
                    newGroupList.push(
                        {
                            groupId : companyInfo['companyGroupId'],
                            groupName : companyInfo['companyGroupName'],
                            companyList : []
                        }
                    );
                }
            });
            /* merging */
            newCompanyList.map(companyInfo => {
                if (!companyInfo['masterCompany']) {
                    let targetGroup = findObjById(companyInfo['companyGroupId'], newGroupList);
                    targetGroup['companyList'].push(companyInfo);
                }
            });
            /* default navigate : only for root */
            if (newGroupList.length > 0) {
                if (!location.pathname.split("/").includes("company")) {
                    navigate(`company/${newGroupList[0]['companyList'][0]['companyId']}`);
                }
            }
            setGroupAndCompanyList(newGroupList);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchCompanyList();
    }, []);

    /* renderers */
    const renderAccordion = () => {
        let groupAccordElements = [];
        groupAndCompanyList.map(groupAndCompany => {
            groupAccordElements.push(craftGroupAccordion(groupAndCompany, hasAnyCompanyInPath(groupAndCompany['companyList'])));
        });
        return groupAccordElements;
    };

    const craftGroupAccordion = (groupAndCompany, isActive) => {
        return (
            <Accordion
                key={groupAndCompany['groupId']}
            >
                <AccordionItem
                    isActive={isActive}
                >
                    {
                        ({open}) => (
                            <>
                            <AccordionHeader
                                className={"groupAccordionHeaderBtn"}
                            >
                                {/* group name and expand or shrink button */}
                                <div
                                    style={{
                                        width: '100%',
                                        marginTop: '10px',
                                        borderBottom: '1px solid #ebebeb',
                                        paddingLeft: '5px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        paddingRight: '5px',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '14px',
                                            marginLeft: '10px'
                                        }}
                                    >
                                            {groupAndCompany['groupName']}
                                    </span>
                                    <span
                                        style={{
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        <MdKeyboardArrowDown
                                            size={"20"}
                                            style={
                                                open ?
                                                    {
                                                        transform: 'rotate( 180deg )'
                                                    }
                                                    :
                                                    {}
                                            }
                                        />
                                    </span>
                                </div>
                            </AccordionHeader>
                            <AccordionBody>
                                <div
                                    style={{
                                        width: '100%',
                                        padding: '0px',
                                        paddingTop: '5px'
                                    }}
                                >
                                    {craftCompanyListItem(groupAndCompany['companyList'])}
                                </div>
                            </AccordionBody>
                            </>
                        )
                    }
                </AccordionItem>
            </Accordion>
        );
    };

    const craftCompanyListItem = (companyList) => {
        let companyElements = [];
        companyList.map(companyInfo => {
            companyElements.push(
                <div
                    key={companyInfo['companyId']}
                    style={{
                        width: '100%',
                        marginBottom: '5px'
                    }}
                >
                    <button
                        className={"groupListButton" + (hasIdInPath(companyInfo['companyId']) ? "-selected" : "")}
                        onClick={() => navigate(`company/${companyInfo['companyId']}`)}
                    >
                        {companyInfo['companyName']}
                    </button>
                </div>
            );
        });
        return companyElements;
    }

    return (
        <RenderOnRole roleName={""} type={""} allowedRing={1} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.companyRole.title")]}
                    depth={1}
                />
            </div>
            <div className={"contentContainer"}>
                <Container fluid>
                    <Row>
                        <Col
                            md={"2"}
                            style={{
                                padding: '0px'
                            }}
                        >
                            <div
                                className={"contentInner"}
                                style={{
                                    height: '780px',
                                    overflowX: 'auto'
                                }}
                            >
                                {
                                    (groupAndCompanyList.length > 0) &&
                                    renderAccordion()
                                }
                            </div>
                        </Col>
                        <Col md={"10"}>
                            <div
                                className={"contentInner"}
                                style={{
                                    width: '100%',
                                    height: '780px'
                                }}
                            >
                                {
                                    (location.pathname.split("/").length < 3)
                                        ?
                                        <span
                                            style={{
                                                fontSize : '14px',
                                                color: '#b8b8b8'
                                            }}
                                        >
                                            {t("message.chooseCompany")}
                                        </span>
                                        :
                                        <Outlet />
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default CompanyRoleEdit;