import React from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import DataParseService from "../../../../services/DataParseService";
import TitleAndInputBox from "../../../common/dataView/TitleAndInputBox";
import UserService from "../../../../services/UserService";
import axios from "axios";
import {toast} from "react-toastify";
import ToastAlertView from "../../../common/alert/ToastAlertView";

const HistoryContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  padding: 10px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
`;

const DaysView = styled.div`
  width: 100%;
  font-size: 14px;
  color: #fc7242;
  text-align: left;
`;

const DateView = styled.div`
  width: 100%;
  font-size: 14px;
  color: #757575;
  text-align: right;
`;

const MessageView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #757575;
`;

const RedButton = styled.button`
  border: 1px solid #ff3333;
  color: #ff3333;
  background-color: white;
  transition: all ease-in-out 0.2s;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  margin-left: 15px;
  
  &:hover {
    background-color: #ffe6e6;
  }
  
  &:focus {
    outline: none;
  }
`;

const ApiKeyExtensionHistoryItemView = ({ extensionHistoryList, onRefresh, franchiseId }) => {

    const { t } = useTranslation();

    /* cancel extension */
    const cancelExtension = async (keyId, extensionId) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-app-api-key`
                + `/franchise/${franchiseId}/key/${keyId}/extension/${extensionId}`;
            await UserService.updateToken(() => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            onRefresh();
            toast.success(<ToastAlertView message={t("message.saved")} />);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                padding: '10px'
            }}
        >
            {
                (extensionHistoryList.length === 0) &&
                <MessageView>
                    {t("menu.franchiseApiKey.detail.noExtended")}
                </MessageView>
            }
            {
                (extensionHistoryList.length > 0) &&
                extensionHistoryList.map((historyObj, index) => {
                    return (
                        <HistoryContainer
                            key={historyObj['apiKeyExtensionHistoryId']}
                        >
                            <Container fluid>
                                <Row>
                                    {/* extend info */}
                                    <Col
                                        style={{
                                            padding: '0px'
                                        }}
                                    >
                                        {/* how many days */}
                                        <DaysView>
                                            {historyObj['extendDays'] + t("menu.franchiseApiKey.detail.extend")}
                                            {/* cancel button */}
                                            {
                                                (index === (extensionHistoryList.length - 1)) &&
                                                <RedButton
                                                    onClick={() => cancelExtension(historyObj['apiKeyExtensionHistoryFranchiseAppApikeyId'], historyObj['apiKeyExtensionHistoryId'])}
                                                >
                                                    {t("menu.franchiseApiKey.detail.button.cancel")}
                                                </RedButton>
                                            }
                                        </DaysView>
                                    </Col>
                                    <Col
                                        style={{
                                            padding: '0px'
                                        }}
                                    >
                                        {/* when */}
                                        <DateView>
                                            {DataParseService.dateTimeToDate(historyObj['generateDate'])}
                                        </DateView>
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    {/* prev exp date */}
                                    <TitleAndInputBox
                                        title={t("menu.franchiseApiKey.detail.prevExpDate")}
                                        value={DataParseService.dateTimeToDate(historyObj['previousExpireDate'])}
                                        onChange={()=>{}}
                                        isForEdit={false}
                                        type={"date"}
                                        isEnabled={true}
                                        isValid={true}
                                    />
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '5px'
                                    }}
                                >
                                    {/* after exp date */}
                                    <TitleAndInputBox
                                        title={t("menu.franchiseApiKey.detail.afterExpDate")}
                                        value={DataParseService.dateTimeToDate(historyObj['newExpireDate'])}
                                        onChange={()=>{}}
                                        isForEdit={false}
                                        type={"date"}
                                        isEnabled={true}
                                        isValid={true}
                                    />
                                </Row>
                            </Container>
                        </HistoryContainer>
                    );
                })
            }
        </div>
    );
};

export default ApiKeyExtensionHistoryItemView;