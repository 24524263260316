import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import RenderOnRole from "../../auth/RenderOnRole";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import DataParseService from "../../services/DataParseService";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../services/DeleteService";

const BoardDetail = () => {

    const { t } = useTranslation();
    const { boardId } = useParams("boardId");
    /* board information */
    const [boardInfo, setBoardInfo] = useState({});
    const [isDeletionConfirmOpen, setIsDeletionConfirmOpen] = useState(false);

    const navigate = useNavigate();

    /* fetch data */
    const fetchBoardDetail = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/board/${boardId}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            setBoardInfo(res.data.item.boardDetail);
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    /* effects */
    useEffect(() => {
        fetchBoardDetail();
    }, []);

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.BOARD_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/board"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.board.title"), t("menu.board.detail.title")]}
                    depth={2}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                <Container fluid>
                    <Row>
                        {/* main area */}
                        <div
                            style={{
                                width: '100%',
                                height: '700px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '650px',
                                    display: 'flex',
                                    overflow: 'auto',
                                    padding: '10px 0px 20px 0px'
                                }}
                            >
                                <Container fluid>
                                    <Row>
                                        <TitleAndInputBox
                                            title={t("common.title")}
                                            value={boardInfo.boardTitle}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            onChange={() => {}}
                                        />
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <Col
                                            style={{
                                                padding: '0px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.group")}
                                                value={boardInfo.boardGroupName}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                        <Col
                                            style={{
                                                padding: '0px',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.adminId")}
                                                value={boardInfo.boardAdminId ? boardInfo.boardAdminId : t("common.noInfo")}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <TitleAndTextArea
                                            title={t("common.boardContent")}
                                            value={boardInfo.boardContent}
                                            onChange={() => {}}
                                            isForEdit={false}
                                            isValid={true}
                                            height={"390"}
                                        />
                                    </Row>
                                    <Row>
                                        <div
                                            style={{
                                                textAlign: 'right',
                                                width: '100%'
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: '#757575',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {
                                                    t("common.generatedAt")
                                                    + " "
                                                    + DataParseService.dateTimeToDate(boardInfo.generateDate)
                                                    + " , "
                                                    + t("common.lastEditedAt")
                                                    + " "
                                                    + DataParseService.dateTimeToDate(boardInfo.lastEditDate)
                                                }
                                            </span>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                            {/* bottom btn */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'left'
                                }}
                            >
                                <div>
                                    <RenderOnRole type={"DELETE"} roleName={ImsSystemRole.BOARD_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        <>
                                            <Link
                                                id={"delete-btn"}
                                                onClick={() => setIsDeletionConfirmOpen(true)}
                                            >
                                                {t("button.delete")}
                                            </Link>
                                            {/* deletion modal */}
                                            <DeleteConfirmModal
                                                isOpen={isDeletionConfirmOpen}
                                                setIsOpen={setIsDeletionConfirmOpen}
                                                isDelayed={false}
                                                confirmMessage={t("language.message.deletion.confirmation", { entityName: t("language.message.deletion.depsList.board.self") })}
                                                entity={DeleteService.ValidEntities.BOARD}
                                                entityName={t("language.message.deletion.depsList.board.self")}
                                                id={`board/${boardId}`}
                                                onDeleteSuccess={() => {
                                                    navigate('/board');
                                                }}
                                            />
                                        </>
                                    </RenderOnRole>
                                </div>
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: '10px'
                                    }}
                                >
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.BOARD_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        <Link
                                            id={"edit-btn"}
                                            to={`/board/edit/${boardId}`}
                                        >
                                            {t("button.edit")}
                                        </Link>
                                    </RenderOnRole>
                                </div>
                                <div>
                                    <Link
                                        id={"save-btn"}
                                        to={"/board"}
                                    >
                                        {t("button.complete")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default BoardDetail;