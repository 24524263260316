import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import UserStatusCode from "../../enums/UserStatusCode";
import AuthService from "../../services/AuthService";
import DataParseService from "../../services/DataParseService";
import RenderOnRole from "../../auth/RenderOnRole";
import {Container, Row, Tooltip} from "react-bootstrap";
import { ReactComponent as BoardAddIcon } from "../../icons/ic_board_add.svg";
import { ReactComponent as MagnifierIcon } from '../../icons/ic_search.svg';
import Table from "../common/table/Table";
import PaginationHandler from "../common/pagination/PaginationHandler";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const BoardManagement = () => {

    const { t } = useTranslation();

    /* boardList */
    const [boardList, setBoardList] = useState([]);
    /* pagination */
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);
    /* search */
    const [searchField, setSearchField] = useState('all');
    const [searchLike, setSearchLike] = useState('');

    const navigate = useNavigate();

    /* data re-formatting */
    const renameBoardIdToId = (objectList) => {
        let newObjList = [...objectList];
        const iter = 9 - objectList.length;
        if (newObjList.length < 9) {
            for (let i=0; i < iter; i++) {
                newObjList.push({
                    "boardId" : null
                });
            }
        }
        return newObjList.map(obj => {
            if ('boardId' in obj) {
                obj['id'] = obj['boardId'];
                delete obj['boardId'];
            }
            if ('generateDate' in obj) {
                obj['generateDate'] = DataParseService.dateTimeToDate(obj['generateDate']);
            }
            if (obj['boardAdminId'] == null) {
                obj['boardAdminId'] = t("common.noInfo");
            }
            return obj;
        });
    }

    /* data fetch */
    const fetchBoardList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/board?size=9&page=${currentPageIndex}`;
            if (searchLike !== '') {
                fetchUrl = fetchUrl + `&field=${searchField}&like=${searchLike}`;
            }
            const axiosCall = () => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                let dataList = [];
                for (let i=0; i<8; i++) {
                    dataList.push({
                        "id" : null
                    });
                }
                setBoardList(dataList);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            } else {
                let dataList = [...res.data.item.boardList];
                setBoardList(renameBoardIdToId(dataList));
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            }
        } catch (err) {
            console.log(err);
        }
    };

    /* handlers */
    const handleSearchFieldChange = (value) => {
        setSearchField(value);
    };

    const handleSearchLikeChange = (value) => {
        setSearchLike(value);
    };

    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    };

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    };

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    };

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    };

    const enterEventHandler = (e) => {
        if (e.key === 'Enter') {
            fetchBoardList();
        }
    };

    /* table column data */
    const columnData = [
        {
            accessor: 'generateDate',
            Header: t('common.generateDate'),
        },
        {
            accessor: 'boardTitle',
            Header: t('common.title'),
        },
        {
            accessor: 'boardGroupName',
            Header: t('common.group'),
        },
        {
            accessor: 'boardAdminId',
            Header: t('common.adminId'),
        },
        {
            accessor: 'viewsCount',
            Header: t('common.viewsCount'),
        }
    ];

    /* effects */
    useEffect(() => {
        fetchBoardList();
    }, [currentPageIndex]);

    /* tooltips */
    const renderToolTip = (name) => {
        return (
            <Tooltip id="tooltip">
                {name}
            </Tooltip>
        );
    };

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.BOARD_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.board.title")]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    {/* main area */}
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                height: '800px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {/* search bar */}
                            <div
                                className={"contentInner"}
                                style={{
                                    width: '100%',
                                    display: 'flex'
                                }}
                            >
                                <div
                                    style={{
                                        width: '270px',
                                        height: '50px',
                                    }}
                                >
                                    <select
                                        id={"search-field-select-box"}
                                        defaultValue={"all"}
                                        onChange={(e) => handleSearchFieldChange(e.target.value)}
                                    >
                                        <option value={"all"}>{t("common.search.fields.all")}</option>
                                        <option value={"title"}>{t("common.search.fields.title")}</option>
                                        <option value={"content"}>{t("common.search.fields.content")}</option>
                                    </select>
                                </div>
                                <div
                                    style={{
                                        width: '270px',
                                        height: '50px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <input
                                        id={"search-like-input"}
                                        type={"text"}
                                        placeholder={t("common.search.placeholder")}
                                        value={searchLike}
                                        onChange={(e) => handleSearchLikeChange(e.target.value)}
                                        onKeyDown={(e) => enterEventHandler(e)}
                                    />
                                </div>
                                <div
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <button
                                        id={"box-btn"}
                                        onClick={() => fetchBoardList()}
                                    >
                                        <MagnifierIcon />
                                    </button>
                                </div>
                                <div
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    <RenderOnRole type={"POST"} roleName={ImsSystemRole.BOARD_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={false}>
                                        <OverlayTrigger placement={"bottom"} overlay={renderToolTip(t("menu.board.add.title"))}>
                                            <button
                                                id={"box-btn"}
                                                onClick={() => navigate(`/board/add`)}
                                            >
                                                <BoardAddIcon />
                                            </button>
                                        </OverlayTrigger>
                                    </RenderOnRole>
                                </div>
                            </div>
                            <div
                                className={"contentInner"}
                                style={{
                                    marginTop: '10px'
                                }}
                            >
                                {/* table */}
                                <div
                                    style={{
                                        width: '100%',
                                        height: '600px',
                                        marginTop: '15px'
                                    }}
                                >
                                    <Table columns={columnData} data={boardList} baseUrl={'detail'} />
                                </div>
                                {/* pagination */}
                                <div
                                    style={{
                                        width: '400px',
                                        height: '50px',
                                        marginTop: '10px',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    {/*goBefore, goAfter, goFirst, goLast, pageSize, currentIndex, maxIndex, totalItemNumber*/}
                                    <PaginationHandler
                                        goBefore={handleGoBefore}
                                        goAfter={handleGoAfter}
                                        goFirst={handleGoFirst}
                                        goLast={handleGoLast}
                                        pageSize={9}
                                        currentIndex={currentPageIndex}
                                        maxIndex={maxPageIndex}
                                        totalItemNumber={totalElements}
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default BoardManagement;