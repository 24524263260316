import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Container, Row, Spinner} from "react-bootstrap";
import OrangeButton from "../../../common/buttons/OrangeButton";
import {useNavigate} from "react-router-dom";
import { CiWarning } from "react-icons/ci";
import { GoCheckCircle } from "react-icons/go";
import TitleAndInputBox from "../../../../common/dataView/TitleAndInputBox";
import DataParseService from "../../../../../services/DataParseService";
import OverlayToolTip from "../../../common/toolTip/OverlayToolTip";
import { CiCircleInfo } from "react-icons/ci";
import ButtonWithSpinner from "../../../common/buttons/ButtonWithSpinner";
import UserService from "../../../../../services/UserService";
import axios from "axios";
import AuthService from "../../../../../services/AuthService";
import {toast} from "react-toastify";
import ToastAlertView from "../../../../common/alert/ToastAlertView";
import ImsSystemRole from "../../../../../auth/roles/ImsSystemRole";

const BoothSyncContainer = styled.div`
  padding: 5px 10px 5px 15px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
`;

const BoothSerial = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #757575;
`;

const SmallTitle = styled.span`
  font-size: 14px;
  color: #757575;
`;

const DateTimeView = styled.span`
  font-size: 14px;
  color: #1b1b1b;
  margin-left: auto;
`;

const BoothInfoButton = styled.button`
  background: none;
  border: none;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: #ffcccb;
  }
`;

const MAX_TRIAL = 3;


const BoothSyncItemView = ({ boothInfo, syncInfo, setIsOpen, onRefresh, franchiseId, isUpdatePossible }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    /* loading */
    const [isLoading, setIsLoading] = useState(false);

    /* retry : only for franchiseMenu */
    const retrySync = async () => {
        await setIsLoading(true);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/franchise-menu/franchise/${franchiseId}`
                + `/menu/${syncInfo['menuBoothSyncFranchiseMenuId']}/booth-sync-status`
                + `?boothId=${boothInfo['boothId']}`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify({}),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            await setIsLoading(false);
            onRefresh();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
            setIsLoading(false);
        }
    };

    const hasBoothGetAuth = () => {
        return AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "GET");
    };

    /* ONGOING, FAILED, SUCCESS */
    const renderStatus = (status) => {
        if (status === "ONGOING") {
            return (
                <OverlayToolTip
                    text={t("menu.franchiseMenu.boothSync.detail.ongoing")}
                    direction={"top"}
                >
                    <div
                        style={{
                            width: '30px',
                            height: '50px',
                            marginLeft: 'auto',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Spinner
                            style={{
                                color: "#c2c2c2"
                            }}
                            size={"sm"}
                        />
                    </div>
                </OverlayToolTip>
            );
        } else if (status === "FAILED") {
            return (
                <OverlayToolTip
                    text={t("menu.franchiseMenu.boothSync.detail.failed")}
                    direction={"top"}
                >
                    <div
                        style={{
                            width: '30px',
                            height: '50px',
                            marginLeft: 'auto',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <CiWarning
                            size={"25"}
                            color={"red"}
                        />
                    </div>
                </OverlayToolTip>
            );
        } else { /* SUCCESS */
            return (
                <OverlayToolTip
                    text={t("menu.franchiseMenu.boothSync.detail.success")}
                    direction={"top"}
                >
                    <div
                        style={{
                            width: '30px',
                            height: '50px',
                            marginLeft: 'auto',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <GoCheckCircle
                            size={"25"}
                            color={"green"}
                        />
                    </div>
                </OverlayToolTip>
            );
        }
    };

    return (
        <BoothSyncContainer>
            <Container fluid>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <BoothSerial>
                            {boothInfo['serialNumber']}
                        </BoothSerial>
                        {
                            hasBoothGetAuth() &&
                            <div
                                style={{
                                    width: '200px',
                                    marginLeft: '10px'
                                }}
                            >
                                <OverlayToolTip
                                    text={t("menu.franchiseMenu.boothSync.button.boothInfo")}
                                    direction={"top"}
                                >
                                    <BoothInfoButton
                                        onClick={async () => {
                                            await setIsOpen(false);
                                            navigate(`/booth/detail/${boothInfo['boothId']}/detailed-info`);
                                        }}
                                    >
                                        <CiCircleInfo
                                            size={"25"}
                                        />
                                    </BoothInfoButton>
                                </OverlayToolTip>
                            </div>
                        }
                        {
                            (!syncInfo['synchronized']) && (syncInfo['toBoothSynchronizationTrialCount'] < MAX_TRIAL) &&
                            renderStatus("ONGOING")
                        }
                        {
                            (!syncInfo['synchronized']) && (syncInfo['toBoothSynchronizationTrialCount'] >= MAX_TRIAL) &&
                            renderStatus("FAILED")
                        }
                        {
                            syncInfo['synchronized'] &&
                            renderStatus("SUCCESS")
                        }
                    </div>
                </Row>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div
                            style={{
                                width: '64%',
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            {/* title */}
                            <SmallTitle>
                                {t("menu.franchiseMenu.boothSync.detail.lastSync")}
                            </SmallTitle>

                            {/* datetime */}
                            <DateTimeView>
                                {
                                    syncInfo['lastSynchronizedAt'] ?
                                        DataParseService.dateTimeFormat(syncInfo['lastSynchronizedAt'])
                                        :
                                        t("menu.franchiseMenu.boothSync.message.noInfo")
                                }
                            </DateTimeView>
                        </div>
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div
                            style={{
                                width: '64%',
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            {/* title */}
                            <SmallTitle>
                                {t("menu.franchiseMenu.boothSync.detail.lastEdit")}
                            </SmallTitle>
                            {/* datetime */}
                            <DateTimeView>
                                {
                                    syncInfo['lastEditedAt'] ?
                                        DataParseService.dateTimeFormat(syncInfo['lastEditedAt'])
                                        :
                                        t("menu.franchiseMenu.boothSync.message.noInfo")
                                }
                            </DateTimeView>
                        </div>
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            paddingBottom: '5px'
                        }}
                    >
                        <div
                            style={{
                                width: '150px'
                            }}
                        >
                            {
                                ((isUpdatePossible == null) || (isUpdatePossible)) ?
                                    (isLoading ?
                                        <ButtonWithSpinner
                                            buttonColor={"#fc7242"}
                                        />
                                        :
                                        <OrangeButton
                                            onClickCallback={() => retrySync()}
                                            isEnabled={(!syncInfo['synchronized']) && (syncInfo['toBoothSynchronizationTrialCount'] >= MAX_TRIAL)}
                                            label={t("menu.franchiseMenu.boothSync.button.retry")}
                                        />)
                                    :
                                        <></>
                            }
                        </div>
                    </div>
                </Row>
            </Container>
        </BoothSyncContainer>
    );
};

export default BoothSyncItemView;