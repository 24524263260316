import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import UserService from "../../services/UserService";
import axios from "axios";
import DataCompareService from "../../services/DataCompareService";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import RenderOnRole from "../../auth/RenderOnRole";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const requestBodyInit = {
    faqQnaCategoryId: "",
    faqTitle: "",
    questionContent: "",
    answerContent: "",
    faqGroupId: "",
}

const isValidMapInit = {
    faqQnaCategoryId: true,
    faqTitle: false,
    questionContent: false,
    answerContent: false,
    faqGroupId: true,
}

const FaqAdd = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [groupList, setGroupList] = useState([]);
    const [qnaCategoryList, setQnaCategoryList] = useState([]);

    const [requestBody, setRequestBody] = useState(requestBodyInit);

    const [isValid, setIsValid] = useState(true);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);

    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    }

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    }

    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                setGroupList([]);
                updateRequestBody("faqGroupId", "");
            } else {
                setGroupList(res.data.item.groupList);
                updateRequestBody("faqGroupId", res.data.item.groupList[0].groupId);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const fetchQnaCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/qna-category`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setQnaCategoryList(res.data.item.qnaCategoryList);
                updateRequestBody("faqQnaCategoryId", res.data.item.qnaCategoryList[0].qnaCategoryId);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleFaqTitleInput = (value) => {
        updateRequestBody("faqTitle", value);
    };

    const handleQuestionContentInput = (value) => {
        updateRequestBody("questionContent", value);
    };

    const handleAnswerContentInput = (value) => {
        updateRequestBody("answerContent", value);
    };

    const handleGroupIdChange = (value) => {
        updateRequestBody("faqGroupId", value);
    };

    const handleFaqQnaCategoryChange = (value) => {
        updateRequestBody("faqQnaCategoryId", value);
    };

    const checkFaqTitleValidity = () => {
        if (requestBody["faqTitle"] === "") {
            updateIsValidMap("faqTitle", false);
            return;
        }
        const isValidForm = (requestBody["faqTitle"].length <= 40) && (requestBody["faqTitle"].length >= 3);
        updateIsValidMap("faqTitle", isValidForm);
    };

    const checkQuestionContentValidity = () => {
        if (requestBody["questionContent"] === "") {
            updateIsValidMap("questionContent", false);
        } else {
            updateIsValidMap("questionContent", true);
        }
    }

    const checkAnswerContentValidity = () => {
        if (requestBody["answerContent"] === "") {
            updateIsValidMap("answerContent", false);
        } else {
            updateIsValidMap("answerContent", true);
        }
    }

    const postFaqInformation = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/faq`;
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate('/faq');
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.error(e);
        }
    }


    useEffect(() => {
        fetchGroupList();
        fetchQnaCategoryList();
    }, []);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        checkFaqTitleValidity();
    }, [requestBody["faqTitle"]]);

    useEffect(() => {
        checkQuestionContentValidity();
    }, [requestBody["questionContent"]]);

    useEffect(() => {
        checkAnswerContentValidity();
    }, [requestBody["answerContent"]]);




    return (
        <RenderOnRole type={"POST"} roleName={ImsSystemRole.QNA_MANAGEMENT} allowedRing={2}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/faq"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.faq.title"), t("menu.faq.add.title")]}
                    depth={2}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                <Container fluid>
                    {/* main area */}
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                height: '650px'
                            }}
                        >
                            <Container fluid>
                                <Row>
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.title")}
                                            value={requestBody["faqTitle"]}
                                            onChange={handleFaqTitleInput}
                                            isForEdit={true}
                                            type={"text"}
                                            isValid={isValidMap["faqTitle"]}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <TitleAndTextArea
                                        title={t("common.questionContent")}
                                        value={requestBody["questionContent"]}
                                        onChange={handleQuestionContentInput}
                                        isForEdit={true}
                                        isValid={isValidMap["questionContent"]}
                                        height={"180"}
                                    />
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <TitleAndTextArea
                                        title={t("common.answerContent")}
                                        value={requestBody["answerContent"]}
                                        onChange={handleAnswerContentInput}
                                        isForEdit={true}
                                        isValid={isValidMap["answerContent"]}
                                        height={"180"}
                                    />
                                </Row>

                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndSelectBox
                                            title={t("common.group")}
                                            innerValue={requestBody["faqGroupId"]}
                                            onChange={handleGroupIdChange}
                                            valueList={groupList}
                                            valueSelector={"groupId"}
                                            viewSelector={"groupName"}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndSelectBox
                                            title={t("common.category")}
                                            innerValue={requestBody["faqQnaCategoryId"]}
                                            onChange={handleFaqQnaCategoryChange}
                                            valueList={qnaCategoryList}
                                            valueSelector={"qnaCategoryId"}
                                            viewSelector={t("language.dataKey.qna.category.name")}
                                        />
                                    </Col>

                                </Row>
                            </Container>
                        </div>
                    </Row>
                    {/* buttons */}
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                height: '50px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'right'
                            }}
                        >
                            <div
                                style={{ marginRight: '10px' }}
                            >
                                <Link
                                    id={"edit-btn"}
                                    to={`/faq`}
                                >
                                    {t("button.cancel")}
                                </Link>
                            </div>
                            <div>
                                {
                                    isValid
                                        ?
                                        <Link
                                            id={"save-btn"}
                                            onClick={() => postFaqInformation()}
                                        >
                                            {t("button.save")}
                                        </Link>
                                        :
                                        <Link
                                            id={"save-btn-disabled"}
                                        >
                                            {t("button.save")}
                                        </Link>
                                }
                            </div>
                        </div>
                    </Row>
                </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default FaqAdd;
