import React, {useState} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import _ from "lodash";
import ServerFailed from "./info/ServerFailed";
import styled from "styled-components";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import WarrantyViewModal from "./modals/WarrantyViewModal";
import {useTranslation} from "react-i18next";
import CommonUtil from "../utils/CommonUtil";
import NotFound from "../../../../auth/NotFound";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 17px;
`;

const MenuContainer = styled.div`
  width: 100%;
  display: flex;
`;

const MenuButton = styled.button`
  border: none;
  padding: 20px 0px;
  margin: 0px 15px;
  background: none;
  color: #848487;
  border-bottom: 4px solid #F5F5F5;
  
  &:focus {
    outline: none;
  }
`;

const MenuButtonSelected = styled.button`
  border: none;
  padding: 20px 0px;
  margin: 0px 15px;
  background: none;
  color: #fc7242;
  border-bottom: 4px solid #fc7242;
  
  &:focus {
    outline: none;
  }
`;

const ViewContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;
  margin-top: 20px;
`;

const DeviceView = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    /* path params */
    const { boothId, deviceId } = useParams();
    const baseLocation = `/booth/detail/${boothId}/device/${deviceId}`;

    /* json schema : local storage */
    let jsonSchema = JSON.parse(window.localStorage.getItem("ims_json_schema"));
    let isOnline = JSON.parse(window.localStorage.getItem("is_booth_online"));

    /* schema parsing */
    const deviceList = (jsonSchema == null) ? [] : jsonSchema['spec']['devices'];

    const device = CommonUtil.getObjectInListById(deviceId, deviceList);

    const deviceName = _.isEqual(device, {}) ? null : device['metadata'][t("language.dataKey.boothControl.json.label")];

    /* render only jsonSchema and boothData exists */
    if ((isOnline == null) || (jsonSchema == null) || (!isOnline) || (deviceName == null)) {
        return (<NotFound />);
    } else {
        return (
            <MainContainer>
                <Container fluid>
                    <Row>
                        <Col>
                            <TitleContainer>
                                <Title>
                                    {deviceName}
                                </Title>
                            </TitleContainer>
                            {/* choose the menu */}
                            <MenuContainer>
                                {/* status & connection */}
                                {
                                    CommonUtil.hasRelativePath(location.pathname, 6, "status") ?
                                        <MenuButtonSelected>
                                            {t("menu.boothControl.device.status")}
                                        </MenuButtonSelected>
                                        :
                                        <MenuButton
                                            onClick={() => navigate(`${baseLocation}/status`)}
                                        >
                                            {t("menu.boothControl.device.status")}
                                        </MenuButton>
                                }
                                {/* attributes */}
                                {
                                    CommonUtil.hasRelativePath(location.pathname, 6, "attributes") ?
                                        <MenuButtonSelected>
                                            {t("menu.boothControl.device.attributes")}
                                        </MenuButtonSelected>
                                        :
                                        <MenuButton
                                            onClick={() => navigate(`${baseLocation}/attributes`)}
                                        >
                                            {t("menu.boothControl.device.attributes")}
                                        </MenuButton>
                                }
                                {/* sensors */}
                                {
                                    CommonUtil.hasRelativePath(location.pathname, 6, "sensors") ?
                                        <MenuButtonSelected>
                                            {t("menu.boothControl.device.sensors")}
                                        </MenuButtonSelected>
                                        :
                                        <MenuButton
                                            onClick={() => navigate(`${baseLocation}/sensors`)}
                                        >
                                            {t("menu.boothControl.device.sensors")}
                                        </MenuButton>
                                }
                            </MenuContainer>
                            {/* device inner view area */}
                            <ViewContainer>
                                <Outlet />
                            </ViewContainer>
                        </Col>
                    </Row>
                </Container>
            </MainContainer>
        );
    }
};

export default DeviceView;