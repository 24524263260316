import React, {useEffect, useState} from 'react';
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import styled from "styled-components";
import OverlayToolTip from "../../../common/toolTip/OverlayToolTip";
import {HiOutlineStatusOnline} from "react-icons/hi";
import BoothMenuSyncStatusView from "../../modals/BoothMenuSyncStatusView";
import MenuInfoView from "./info/MenuInfoView";
import OptionInfoView from "./info/OptionInfoView";
import UserService from "../../../../../services/UserService";
import axios from "axios";
import OptionAddModal from "../../modals/OptionAddModal";
import {SlRefresh} from "react-icons/sl";

const AreaTitle = styled.div`
  font-size: 15px;
  color: #757575;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
`;

const BoothSyncButton = styled.button`
  border: none;
  border-radius: 5px;
  background: none;
  transition: all ease-in-out 0.2s;
  color: #fc7242;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: red;
  }
`;

const OptionAddButton = styled.button`
  border: 1px solid #fc7242;
  padding: 3px 15px;
  border-radius: 10px;
  background-color: white;
  transition: all ease-in-out 0.2s;
  color: #fc7242;
  font-size: 14px;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #fff1ec;
  }
`;

const FranchiseMenuInfoManagement = () => {

    const { t } = useTranslation();
    const { franchiseId, menuId } = useParams();
    const navigate = useNavigate();

    const { refreshMenuList } = useOutletContext();

    /* states */
    const [menuOptionInfoList, setMenuOptionInfoList] = useState([]);

    /* modals */
    const [isBoothSyncViewModalOpen, setIsBoothSyncViewModalOpen] = useState(false);
    const [isOptionAddModalOpen, setIsOptionAddModalOpen] = useState(false);

    /* fetch menu option list */
    const fetchMenuOptionList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/franchise-menu/franchise/${franchiseId}`
                + `/menu/${menuId}/option`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newOptionList = res.data.item['franchiseMenuOptionList'];
            let newOptionInfoList = [];
            /* extract information */
            newOptionList.map(optionObj => {
                newOptionInfoList.push(optionObj['optionInfo']);
            });
            setMenuOptionInfoList(newOptionInfoList);
        } catch (e) {
            setMenuOptionInfoList([]);
            console.log(e);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    /* effects */
    useEffect(() => {
        fetchMenuOptionList();
    }, [menuId]);

    return (
        <Container fluid>
            <Row>
                <Col
                    style={{
                        padding: '0px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: '10px'
                        }}
                    >
                        <AreaTitle>
                            {t("menu.franchiseMenu.menuInfo.title")}
                        </AreaTitle>
                        <div
                            style={{
                                marginLeft: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <OverlayToolTip
                                text={t("menu.franchiseMenu.boothSync.message.hoverMessage")}
                                direction={"top"}
                            >
                                <BoothSyncButton
                                    onClick={() => setIsBoothSyncViewModalOpen(true)}
                                >
                                    <SlRefresh
                                        size={"25"}
                                    />
                                </BoothSyncButton>
                            </OverlayToolTip>
                        </div>
                    </div>
                    <div
                        className={"contentInner"}
                        style={{
                            height: '698px'
                        }}
                    >
                        <MenuInfoView
                            franchiseId={franchiseId}
                            menuId={menuId}
                            refreshMenuList={refreshMenuList}
                        />
                    </div>
                </Col>
                <Col
                    style={{
                        padding: '0px',
                        paddingLeft: '10px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: '10px'
                        }}
                    >
                        <AreaTitle>
                            {t("menu.franchiseMenu.menuInfo.optionInfo.title")}
                        </AreaTitle>
                        <div
                            style={{
                                marginLeft: 'auto'
                            }}
                        >
                            <OptionAddButton
                                onClick={() => setIsOptionAddModalOpen(true)}
                            >
                                {t("menu.franchiseMenu.menuInfo.optionInfo.button.addOption")}
                            </OptionAddButton>
                        </div>
                    </div>
                    <div
                        className={"contentInner"}
                        style={{
                            height: '698px'
                        }}
                    >
                        <OptionInfoView
                            key={menuId + menuOptionInfoList.length}
                            optionInfoList={menuOptionInfoList}
                            refreshOptionList={fetchMenuOptionList}
                        />
                    </div>
                </Col>
            </Row>
            {/* booth menu sync view modal */}
            <BoothMenuSyncStatusView
                isOpen={isBoothSyncViewModalOpen}
                setIsOpen={setIsBoothSyncViewModalOpen}
                franchiseId={franchiseId}
                menuId={menuId}
            />
            {/* menu option add modal */}
            <OptionAddModal
                isOpen={isOptionAddModalOpen}
                setIsOpen={setIsOptionAddModalOpen}
                onRefresh={fetchMenuOptionList}
            />
        </Container>
    );
};

export default FranchiseMenuInfoManagement;