import React from 'react';
import styled from "styled-components";
import Switch from "react-switch";

const BooleanContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const BooleanField = ({ fieldKey, value, updateValue, isForEdit }) => {

    const handleValueChange = (newValue) => {
        if (isForEdit) {
            updateValue(fieldKey, newValue);
        } else {
            return;
        }
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* categories */}
            <div
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                <BooleanContainer>
                    <Switch
                        checked={value}
                        onChange={handleValueChange}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor={"#52b640"}
                    />
                </BooleanContainer>
            </div>
        </div>
    );
};

export default BooleanField;