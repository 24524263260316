import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import UserService from "../../../services/UserService";
import axios from "axios";
import DataCompareService from "../../../services/DataCompareService";
import uuid from "react-uuid";
import {Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../common/dataView/TitleAndTextArea";
import Modal from "react-modal";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import LanguageInput from "../../../language/component/LanguageInput";
import LanguageSelectBox from "../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";
import DeleteConfirmModal from "../../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../../services/DeleteService";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '800px',
        height: '600px',
        margin: 'auto'
    }
}

const DeleteButtonDisabled = styled.button`
  width: 100%;
  border: none;
  color: #c2c2c2;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #c2c2c2;
  font-size: 14px;
  transition: all ease-in-out 0.2s;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled.button`
  width: 100%;
  border: none;
  color: red;
  background-color: white;
  border-radius: 5px;
  border: 1px solid red;
  font-size: 14px;
  transition: all ease-in-out 0.2s;
  outline: none;

  &:hover {
    background-color: red;
    color: white;
  }

  &:focus {
    outline: none;
  }
`;

const requestBodyInit = {
    qnaCategoryName: "",
    qnaCategoryName1: null,
    qnaCategoryName2: null,
    qnaCategoryName3: null,
    qnaCategoryName4: null,
    qnaCategoryName5: null,
    qnaCategoryDescription: "",
    qnaCategoryDescription1: null,
    qnaCategoryDescription2: null,
    qnaCategoryDescription3: null,
    qnaCategoryDescription4: null,
    qnaCategoryDescription5: null,
}

const isValidMapInit = {
    qnaCategoryName: true,
    qnaCategoryName1: true,
    qnaCategoryName2: true,
    qnaCategoryName3: true,
    qnaCategoryName4: true,
    qnaCategoryName5: true,
    qnaCategoryDescription: true,
    qnaCategoryDescription1: true,
    qnaCategoryDescription2: true,
    qnaCategoryDescription3: true,
    qnaCategoryDescription4: true,
    qnaCategoryDescription5: true
};

const QnaCategoryEdit = ({ isOpen, setIsOpen, onRefresh }) => {

    const { t, i18n } = useTranslation();

    const [qnaCategoryList, setQnaCategoryList] = useState([]);
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1);
    const [isEdited, setIsEdited] = useState(false);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);

    /* delete */
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

    /* state for language */
    const targetFieldsString = `"${t("common.name")}", "${t("common.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    const initialize = () => {
        fetchQnaCategoryList();
        setSelectedCategoryIndex(-1);
    };

    const fetchQnaCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/qna-category`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setQnaCategoryList(res.data.item.qnaCategoryList);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const extractFields = (obj) => {
        return {
            qnaCategoryName: obj.qnaCategoryName,
            qnaCategoryName1: obj.qnaCategoryName1,
            qnaCategoryName2: obj.qnaCategoryName2,
            qnaCategoryName3: obj.qnaCategoryName3,
            qnaCategoryName4: obj.qnaCategoryName4,
            qnaCategoryName5: obj.qnaCategoryName5,
            qnaCategoryDescription: obj.qnaCategoryDescription,
            qnaCategoryDescription1: obj.qnaCategoryDescription1,
            qnaCategoryDescription2: obj.qnaCategoryDescription2,
            qnaCategoryDescription3: obj.qnaCategoryDescription3,
            qnaCategoryDescription4: obj.qnaCategoryDescription4,
            qnaCategoryDescription5: obj.qnaCategoryDescription5
        };
    };

    const putCategory = async () => {
        try {
            let requestBodyJsonObj = extractFields(requestBody);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/qna-category/${qnaCategoryList[selectedCategoryIndex].qnaCategoryId}`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBodyJsonObj),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await onRefresh();
            await initialize();
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
            console.error(e);
        }
    }
    //
    // const deleteCategory = async () => {
    //     try {
    //         let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/qna-category/${qnaCategoryList[selectedCategoryIndex].qnaCategoryId}`;
    //         const res = await UserService.updateToken(() => axios.delete(
    //             fetchUrl,
    //             {
    //                 headers: {
    //                     Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
    //                 }
    //             }
    //         ));
    //         toast.success(<ToastAlertView message={t("message.deleted")} />);
    //         await onRefresh();
    //         await initialize();
    //     } catch (e) {
    //         toast.error(<ToastAlertView message={t("message.failed")} />);
    //         console.error(e);
    //     }
    // }

    useEffect(() => {
        if (selectedCategoryIndex === -1) {
            setIsEdited(false);
            setIsValid(false);
        } else {
            setRequestBody(DataCompareService.deepCopyObj(qnaCategoryList[selectedCategoryIndex]));
            setIsEdited(false);
        }
    }, [selectedCategoryIndex]);

    useEffect(() => {
        if (selectedCategoryIndex === -1) {
            setIsEdited(false);
        } else {
            setIsEdited(!DataCompareService.isSameObj(requestBody, qnaCategoryList[selectedCategoryIndex]));
        }
    }, [requestBody]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        initialize();
    }, [isOpen]);

    const renderQnaCategory = (categoryInfo, index) => {
        return (
            <div
                key={uuid()}
                style={{
                    width: '100%',
                    height: '80px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    border: '1px solid',
                    borderColor: (selectedCategoryIndex === index) ? "#fc7242" : "#757575",
                    backgroundColor: (selectedCategoryIndex === index) ? "#fc7242" : "white",
                    display: 'flex',
                    flexDirection: 'column'
                }}
                onClick={() => {
                    if (selectedCategoryIndex === index) {
                        setSelectedCategoryIndex(-1);
                    } else {
                        setSelectedCategoryIndex(index);
                    }
                }}
            >
                <div
                    style={{
                        width: '100%',
                        paddingLeft: '20px'
                    }}
                >
                    <span
                        style={{
                            color: (selectedCategoryIndex === index) ? "white" : "#757575",
                            fontSize: '13px',
                            fontWeight: 'bold'
                        }}
                    >
                        {categoryInfo[t("language.dataKey.qna.category.name")]}
                    </span>
                </div>
                <div
                    style={{
                        width: '100%',
                        paddingLeft: '30px'
                    }}
                >
                    <span
                        style={{
                            color: (selectedCategoryIndex === index) ?
                                "white"
                                :
                                (categoryInfo.qnaCount === 0) ? "red" : "blue",
                            fontSize: '13px'
                        }}
                    >
                        {categoryInfo.qnaCount}
                    </span>
                    <span
                        style={{
                            color: (selectedCategoryIndex === index) ?
                                "white"
                                :
                                "#757575",
                            fontSize: '13px'
                        }}
                    >
                        {"  " + t("menu.qna.category.edit.qnaNumber")}
                    </span>
                </div>
                <div
                    style={{
                        width: '100%',
                        paddingLeft: '30px'
                    }}
                >
                    <span
                        style={{
                            color: (selectedCategoryIndex === index) ?
                                "white"
                                :
                                (categoryInfo.faqCount === 0) ? "red" : "blue",
                            fontSize: '13px'
                        }}
                    >
                        {categoryInfo.faqCount}
                    </span>
                    <span
                        style={{
                            color: (selectedCategoryIndex === index) ?
                                "white"
                                :
                                "#757575",
                            fontSize: '13px'
                        }}
                    >
                        {"  " + t("menu.qna.category.edit.faqNumber")}
                    </span>
                </div>
            </div>
        )
    }

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.qna.category.edit.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex'
                        }}
                    >
                        <div
                            style={{
                                width: '49%',
                                height: '500px',
                                overflowY: 'auto',
                                padding: '5px',
                                marginRight: '1%'
                            }}
                        >
                            {
                                qnaCategoryList.map((category, index) => {
                                    return renderQnaCategory(category, index);
                                })
                            }
                        </div>
                        <div
                            style={{
                                width: '50%',
                                height: '500px',
                                padding: '5px',
                                border: '1px solid #ebebeb',
                                borderRadius: '10px'
                            }}
                        >
                            {
                                (selectedCategoryIndex === -1) ?
                                    <div
                                        style={{
                                            width: '100%',
                                            padding: '10px'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: '#757575',
                                                fontSize: '13px'
                                            }}
                                        >
                                            {t("message.chooseQnaCategory")}
                                        </span>
                                    </div>
                                    :
                                    <Container fluid>
                                        <Row>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'right',
                                                    marginTop: '10px'
                                                }}
                                            >
                                                {
                                                    ((qnaCategoryList[selectedCategoryIndex].qnaCount !== 0)
                                                        || (qnaCategoryList[selectedCategoryIndex].faqCount !== 0))
                                                        ?
                                                        <DeleteButtonDisabled
                                                            onClick = {() => toast.error(<ToastAlertView message={t("message.cannotDeleteQnaCategory")} />)}
                                                        >
                                                            {t("button.deleteCategory")}
                                                        </DeleteButtonDisabled>
                                                        :
                                                        <>
                                                            <DeleteButton
                                                                onClick = {() => setIsDeleteConfirmationOpen(true)}
                                                            >
                                                                {t("button.deleteCategory")}
                                                            </DeleteButton>
                                                            {/* delete confirmation */}
                                                            <DeleteConfirmModal
                                                                isOpen={isDeleteConfirmationOpen}
                                                                setIsOpen={setIsDeleteConfirmationOpen}
                                                                isDelayed={false}
                                                                confirmMessage={t("language.message.deletion.confirmation", { entityName : qnaCategoryList[selectedCategoryIndex][t("language.dataKey.qna.category.name")] })}
                                                                entity={DeleteService.ValidEntities.QNA_CATEGORY}
                                                                entityName={qnaCategoryList[selectedCategoryIndex][t("language.dataKey.qna.category.name")]}
                                                                id={`qna-category/${qnaCategoryList[selectedCategoryIndex].qnaCategoryId}`}
                                                                onDeleteSuccess={async () => {
                                                                    await onRefresh();
                                                                    await initialize();
                                                                }}
                                                            />
                                                        </>
                                                }
                                            </div>
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <LanguageSelectBox
                                                title={t("language.selectBox.title.qnaCategory")}
                                                languageMetadataList={languageMetadataList}
                                                currentLang={currentLang}
                                                setCurrentLang={setCurrentLang}
                                                setIsHighlightOn={setIsHighlightOn}
                                                targetFieldString={targetFieldsString}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <LanguageInput
                                                type={"text"}
                                                title={t("common.name") + ` (${t("language.callName." + currentLang)})`}
                                                dataKeyTranslationPath={"language.dataKey.qna.category.name"}
                                                valueMap={requestBody}
                                                updateValueMap={updateRequestBody}
                                                isValidMap={isValidMap}
                                                updateIsValidMap={updateIsValidMap}
                                                isForEdit={true}
                                                currentLang={currentLang}
                                                isHighlightOn={isHighlightOn}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <LanguageInput
                                                type={"textarea"}
                                                title={t("common.description") + ` (${t("language.callName." + currentLang)})`}
                                                dataKeyTranslationPath={"language.dataKey.qna.category.description"}
                                                valueMap={requestBody}
                                                updateValueMap={updateRequestBody}
                                                isValidMap={isValidMap}
                                                updateIsValidMap={updateIsValidMap}
                                                isForEdit={true}
                                                height={"200"}
                                                currentLang={currentLang}
                                                isHighlightOn={isHighlightOn}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '20px'
                                            }}
                                        >
                                            {
                                                (isEdited && isValid)
                                                    ?
                                                    <button
                                                        id={"long-save-btn"}
                                                        onClick={() => putCategory()}
                                                    >
                                                        {t("button.save")}
                                                    </button>
                                                    :
                                                    <button
                                                        id={"long-save-btn-disabled"}
                                                    >
                                                        {t("button.save")}
                                                    </button>
                                            }
                                        </Row>
                                    </Container>
                            }
                        </div>
                    </div>
                </Row>
            </Container>
        </Modal>
    );
};

export default QnaCategoryEdit;
