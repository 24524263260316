import React from 'react';
import styled from "styled-components";

const LevelButton = styled.button`
  color: ${props => props.color ? props.color : colorMap['None']};
  border: 1px solid ${props => props.color ? props.color : colorMap['None']};
  font-size: 14px;
  padding: 3px 7px;
  transition: all ease-in-out 0.2s;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : "white"};
  border-radius: 10px;
  margin-right: 10px;
  
  &:focus {
    outline: none;
  }
`;

const colorMap = {
    "None" : "#c2c2c2",
    "Fatal" : "#cc3300",
    "Critical" : "#ff5c33",
    "Error" : "#ff9966",
    "Warning" : "#ffcc00",
    "Info" : "#99cc33",
    "Debug" : "#339900",
    "Unknown" : "#757575",
    "background" : {
        "Fatal" : "#ffcccc",
        "Critical" : "#ffd6cc",
        "Error" : "#fff2e6",
        "Warning" : "#fff5cc",
        "Info" : "#ecffe6",
        "Debug" : "#d6f5d6",
        "Unknown" : "#F0F0F0",
    }
};

const ErrorLevelCell = ({ value }) => {
    return (
        <LevelButton
            color={colorMap[value]}
            backgroundColor={colorMap['background'][value]}
        >
            {value}
        </LevelButton>
    );
};

export default ErrorLevelCell;