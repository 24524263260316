import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DashboardAxiosService from "../service/DashBoardAxiosService";
import styled from "styled-components";
import _ from "lodash";
import useInterval from "../../../customHooks/useInterval";
import { CiCircleQuestion } from "react-icons/ci";
import OverlayToolTip from "../../menu/common/toolTip/OverlayToolTip";
import BoothListViewModal from "../modals/BoothListViewModal";
import ErrorBoothListViewModal from "../modals/ErrorBoothListViewModal";

const BlockContainer = styled.div`
  margin-right: ${props => props.isLast ? "0px" : "10px"};
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  position: relative;
`;

const BlockTitle = styled.span`
  color: #757575;
  font-size: 14px;
  font-weight: bold;
`;

const SmallMessage = styled.span`
  color: #c2c2c2;
  font-size: 14px;
`;

const NumberView = styled.span`
  margin-top: 5px;
  color: ${props => props.color ? props.color : "#757575"};
  font-size: 27px;
  font-weight: bold;
`;

const colorMap = {
    "error" : "#ff5c33",
    "normal" : "#3399ff",
    "info" : "#fc7242"
};

const PlusButton = styled.button`
  position: absolute;
  right: 5px;
  top: 1%;
  background: none;
  color: #c2c2c2;
  border: none;
  transition: all ease-in-out 0.2s;
  padding: 0px;
  
  &:hover {
    color: #757575;
  }
  
  &:focus {
    outline: none;
  }
`;

const BoothInfoPart = () => {

    const { t } = useTranslation();

    /* states */
    const [boothInfo, setBoothInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    /* booth list modals */
    const [isErrorBoothListModalOpen, setIsErrorBoothListModalOpen] = useState(false);
    const [isManufacturedBoothListModalOpen, setIsManufacturedBoothListModalOpen] = useState(false);
    const [isSoldBoothListModalOpen, setIsSoldBoothListModalOpen] = useState(false);
    const [isInstalledBoothListModalOpen, setIsInstalledBoothListModalOpen] = useState(false);

    const getDashboardData = async () => {
        await setIsLoading(true);
        const onSuccessCallback = async (data) => {
            await setBoothInfo(data);
            await setIsLoading(false);
        };
        const onFailureCallback = async (e) => {
            await setBoothInfo({});
            await setIsLoading(false);
            console.log(e);
        };
        await DashboardAxiosService.fetchBoothInfo(onSuccessCallback, onFailureCallback);
    };

    /* effects */
    useEffect(() => {
        getDashboardData();
    }, []);

    /* update */
    /* TIMER : update date */
    /* every 300 sec */
    useInterval(() => {
        getDashboardData();
    }, 300000);

    return (
       <div
           style={{
               width: '100%',
               height: '100%',
               display: 'flex',
               flexDirection: 'row'
           }}
       >
           {/* current non-error booth */}
           <BlockContainer>
               <BlockTitle>
                   {t("menu.dashboard.boothInfo.currentGood")}
               </BlockTitle>
               {
                   isLoading &&
                   <Spinner size={"sm"} style={{ marginTop: '5px' }} />
               }
               {
                   (!isLoading) &&
                   (_.isEqual(boothInfo, {})) &&
                   <SmallMessage>
                       {t("message.serverFailed")}
                   </SmallMessage>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (!boothInfo['boothErrorInfo']['isEssDataFetched']) &&
                   <SmallMessage>
                       {t("message.serverFailed")}
                   </SmallMessage>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (boothInfo['boothErrorInfo']['isEssDataFetched']) &&
                   <NumberView
                       color={boothInfo['boothErrorInfo']['errorNotExistingBoothCount'] > 0 ? colorMap["normal"] : null}
                   >
                       {boothInfo['boothErrorInfo']['errorNotExistingBoothCount']}
                   </NumberView>
               }
           </BlockContainer>

           {/* current error booth */}
           <BlockContainer>
               <BlockTitle>
                   {t("menu.dashboard.boothInfo.currentError")}
               </BlockTitle>
               {
                   isLoading &&
                   <Spinner size={"sm"} style={{ marginTop: '5px' }} />
               }
               {
                   (!isLoading) &&
                   (_.isEqual(boothInfo, {})) &&
                   <SmallMessage>
                       {t("message.serverFailed")}
                   </SmallMessage>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (!boothInfo['boothErrorInfo']['isEssDataFetched']) &&
                   <SmallMessage>
                       {t("message.serverFailed")}
                   </SmallMessage>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (boothInfo['boothErrorInfo']['isEssDataFetched']) &&
                   <NumberView
                       color={boothInfo['boothErrorInfo']['errorExistingBoothCount'] > 0 ? colorMap["error"] : null}
                   >
                       {boothInfo['boothErrorInfo']['errorExistingBoothCount']}
                   </NumberView>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (boothInfo['boothErrorInfo']['isEssDataFetched']) &&
                   <OverlayToolTip
                       direction={"top"}
                       text={t("menu.dashboard.boothInfo.messages.viewList")}
                   >
                       <PlusButton
                           onClick={() => setIsErrorBoothListModalOpen(true)}
                       >
                           <CiCircleQuestion size={"25"} />
                       </PlusButton>
                   </OverlayToolTip>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (boothInfo['boothErrorInfo']['isEssDataFetched']) &&
                   <ErrorBoothListViewModal
                       isOpen={isErrorBoothListModalOpen}
                       setIsOpen={setIsErrorBoothListModalOpen}
                       title={t("menu.dashboard.boothInfo.currentError")}
                       boothErrorInfoObj={boothInfo['boothErrorInfo']['errorInfoObject']}
                   />
               }
           </BlockContainer>

           {/* today manufactured booth */}
           <BlockContainer>
               <BlockTitle>
                   {t("menu.dashboard.boothInfo.todayManufactured")}
               </BlockTitle>
               {
                   isLoading &&
                   <Spinner size={"sm"} style={{ marginTop: '5px' }} />
               }
               {
                   (!isLoading) &&
                   (_.isEqual(boothInfo, {})) &&
                   <SmallMessage>
                       {t("message.serverFailed")}
                   </SmallMessage>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   <NumberView
                       color={boothInfo['manufacturedBoothInfo']['count'] > 0 ? colorMap["info"] : null}
                   >
                       {boothInfo['manufacturedBoothInfo']['count']}
                   </NumberView>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (boothInfo['manufacturedBoothInfo']['count'] > 0) &&
                   <OverlayToolTip
                       direction={"top"}
                       text={t("menu.dashboard.boothInfo.messages.viewList")}
                   >
                       <PlusButton
                           onClick={() => setIsManufacturedBoothListModalOpen(true)}
                       >
                           <CiCircleQuestion size={"25"} />
                       </PlusButton>
                   </OverlayToolTip>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (boothInfo['manufacturedBoothInfo']['count'] > 0) &&
                   <BoothListViewModal
                       isOpen={isManufacturedBoothListModalOpen}
                       setIsOpen={setIsManufacturedBoothListModalOpen}
                       title={t("menu.dashboard.boothInfo.todayManufactured")}
                       boothList={boothInfo['manufacturedBoothInfo']['boothList']}
                   />
               }
           </BlockContainer>

           {/* today sold booth */}
           <BlockContainer>
               <BlockTitle>
                   {t("menu.dashboard.boothInfo.todaySold")}
               </BlockTitle>
               {
                   isLoading &&
                   <Spinner size={"sm"} style={{ marginTop: '5px' }} />
               }
               {
                   (!isLoading) &&
                   (_.isEqual(boothInfo, {})) &&
                   <SmallMessage>
                       {t("message.serverFailed")}
                   </SmallMessage>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   <NumberView
                       color={boothInfo['soldBoothInfo']['count'] > 0 ? colorMap["info"] : null}
                   >
                       {boothInfo['soldBoothInfo']['count']}
                   </NumberView>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (boothInfo['soldBoothInfo']['count'] > 0) &&
                   <OverlayToolTip
                       direction={"top"}
                       text={t("menu.dashboard.boothInfo.messages.viewList")}
                   >
                       <PlusButton
                           onClick={() => setIsSoldBoothListModalOpen(true)}
                       >
                           <CiCircleQuestion size={"25"} />
                       </PlusButton>
                   </OverlayToolTip>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (boothInfo['soldBoothInfo']['count'] > 0) &&
                   <BoothListViewModal
                       isOpen={isSoldBoothListModalOpen}
                       setIsOpen={setIsSoldBoothListModalOpen}
                       title={t("menu.dashboard.boothInfo.todaySold")}
                       boothList={boothInfo['soldBoothInfo']['boothList']}
                   />
               }
           </BlockContainer>

           {/* today installed booth */}
           <BlockContainer
               isLast={true}
           >
               <BlockTitle>
                   {t("menu.dashboard.boothInfo.todayInstalled")}
               </BlockTitle>
               {
                   isLoading &&
                   <Spinner size={"sm"} style={{ marginTop: '5px' }} />
               }
               {
                   (!isLoading) &&
                   (_.isEqual(boothInfo, {})) &&
                   <SmallMessage>
                       {t("message.serverFailed")}
                   </SmallMessage>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   <NumberView
                       color={boothInfo['installedBoothInfo']['count'] > 0 ? colorMap["info"] : null}
                   >
                       {boothInfo['installedBoothInfo']['count']}
                   </NumberView>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (boothInfo['installedBoothInfo']['count'] > 0) &&
                   <OverlayToolTip
                       direction={"top"}
                       text={t("menu.dashboard.boothInfo.messages.viewList")}
                   >
                       <PlusButton
                           onClick={() => setIsInstalledBoothListModalOpen(true)}
                       >
                           <CiCircleQuestion size={"25"} />
                       </PlusButton>
                   </OverlayToolTip>
               }
               {
                   (!isLoading) &&
                   (!_.isEqual(boothInfo, {})) &&
                   (boothInfo['installedBoothInfo']['count'] > 0) &&
                   <BoothListViewModal
                       isOpen={isInstalledBoothListModalOpen}
                       setIsOpen={setIsInstalledBoothListModalOpen}
                       title={t("menu.dashboard.boothInfo.todayInstalled")}
                       boothList={boothInfo['installedBoothInfo']['boothList']}
                   />
               }
           </BlockContainer>
       </div>
    );
};

export default BoothInfoPart;