import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import DataCompareService from "../../../services/DataCompareService";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../common/dataView/TitleAndTextArea";
import TitleAndSelectBox from "../../common/dataView/TitleAndSelectBox";
import axios from "axios";
import UserService from "../../../services/UserService";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import {RxCross1} from "react-icons/rx";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '580px',
        margin: 'auto'
    }
};

const boothVersionRequestBodyInit = {
    boothVersion : "",
    boothJsonVersion : "",
    description : "",
    generateDate : ""
};

const boothVersionIsValidMapInit = {
    boothVersion : false,
    boothJsonVersion : true,
    description : false,
    generateDate : false
};

const SaveButton = styled.button`
  background: none;
  border-radius: 5px;
  font-size: 15px;
  width: 100%;
  color: #fc7242;
  border: 1px solid #fc7242;
  transition: all ease-in-out 0.2s;
  padding: 10px 15px;

  &:hover {
    background-color: #fc7242;
    color: white;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  background: none;
  border-radius: 5px;
  font-size: 15px;
  width: 100%;
  color: #c2c2c2;
  border: 1px solid #c2c2c2;
  padding: 10px 15px;
  
  &:focus {
    outline: none;
  }
`;

const SmallMessage = styled.span`
  color: #c2c2c2;
  font-size: 13px;
`;

const AddBoothVersion = ({ isOpen, setIsOpen, onRefresh, boothNameId, boothName, jsonSchemaList }) => {

    const { t } = useTranslation();

    const [boothVersionRequestBody, setBoothVersionRequestBody] = useState(boothVersionRequestBodyInit);
    const [boothVersionValidMap, setBoothVersionValidMap] = useState(boothVersionIsValidMapInit);
    const [isBoothVersionValid, setIsBoothVersionValid] = useState(true);

    /* post booth version */
    const postBoothVersion = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/booth-version/name/${boothNameId}/version`;
            const axiosCall = () => axios.post(
                fetchUrl,
                JSON.stringify(boothVersionRequestBody),
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            onRefresh();
            setIsOpen(false);
        } catch (err) {
            console.log(err);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* utils */
    const updateBoothVersionRequestBody = (key, value) => {
        setBoothVersionRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateBoothVersionValidMap = (key, value) => {
        setBoothVersionValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* input handlers */
    const handleBoothVersionInput = (value) => {
        updateBoothVersionRequestBody('boothVersion', value);
        if (value === "" || value == null) {
            updateBoothVersionValidMap('boothVersion', false);
        } else {
            updateBoothVersionValidMap('boothVersion', true);
        }
    };

    const handleBoothVersionDescriptionInput = (value) => {
        updateBoothVersionRequestBody('description', value);
        if (value === "" || value == null) {
            updateBoothVersionValidMap('description', false);
        } else {
            updateBoothVersionValidMap('description', true);
        }
    };

    const handleBoothJsonVersionInput = (value) => {
        updateBoothVersionRequestBody('boothJsonVersion', value);
    };

    const handleBoothVersionGenerateDateInput = (value) => {
        updateBoothVersionRequestBody('generateDate', value);
        if (value === "" || value == null) {
            updateBoothVersionValidMap('generateDate', false);
        } else {
            updateBoothVersionValidMap('generateDate', true);
        }
    };

    /* effects */
    useEffect(() => {
        setIsBoothVersionValid(DataCompareService.checkIsAllTrue(boothVersionValidMap));
    }, [boothVersionValidMap]);

    useEffect(() => {
        let newRequestBody = {...boothVersionRequestBodyInit};
        if (jsonSchemaList.length > 0) {
            newRequestBody['boothJsonVersion'] = jsonSchemaList[0]['schemaVersion'];
        }
        setBoothVersionRequestBody(newRequestBody);
        setBoothVersionValidMap(boothVersionIsValidMapInit);
    }, [isOpen]);

    if (jsonSchemaList == null || typeof jsonSchemaList === "undefined" || jsonSchemaList.length === 0) {
        return (
            <Modal
                style={modalStyle}
                isOpen={isOpen}
                onRequestClose={() => {
                    setIsOpen(false);
                }}
            >
                ERROR
            </Modal>
        );
    } else {
        return (
            <Modal
                style={modalStyle}
                isOpen={isOpen}
                onRequestClose={() => {
                    setIsOpen(false);
                }}
            >
                <Container fluid>
                    <Row>
                        <ModalCloseButton
                            title={boothName + " " + t("menu.booth.version.boothVersion.add.title")}
                            setIsOpen={setIsOpen}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <TitleAndInputBox
                            title={t("menu.booth.version.boothVersion.detail.boothVersion")}
                            value={boothVersionRequestBody['boothVersion']}
                            onChange={handleBoothVersionInput}
                            isForEdit={true}
                            type={"text"}
                            isValid={boothVersionValidMap['boothVersion']}
                            isEnabled={true}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <TitleAndTextArea
                            title={t("menu.booth.version.boothVersion.detail.description")}
                            value={boothVersionRequestBody['description']}
                            onChange={handleBoothVersionDescriptionInput}
                            isForEdit={true}
                            height={"100"}
                            isValid={boothVersionValidMap['description']}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <TitleAndInputBox
                            title={t("menu.booth.version.boothVersion.detail.generateDate")}
                            value={boothVersionRequestBody['generateDate']}
                            onChange={handleBoothVersionGenerateDateInput}
                            isForEdit={true}
                            type={"date"}
                            isValid={boothVersionValidMap['generateDate']}
                            isEnabled={true}
                        />
                    </Row>
                    <Row>
                        <div
                            style={{
                                paddingRight: '10px',
                                width: '100%',
                                textAlign: 'right'
                            }}
                        >
                            <SmallMessage>
                                {t("menu.booth.version.boothVersion.detail.sortMessage")}
                            </SmallMessage>
                        </div>
                    </Row>
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <TitleAndSelectBox
                            title={t("menu.booth.version.boothVersion.detail.boothJsonVersion")}
                            innerValue={boothVersionRequestBody['boothJsonVersion']}
                            valueList={jsonSchemaList}
                            valueSelector={"schemaVersion"}
                            viewSelector={"schemaName"}
                            onChange={handleBoothJsonVersionInput}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '30px'
                        }}
                    >
                        {
                            isBoothVersionValid ?
                                <SaveButton
                                    onClick = {() => postBoothVersion()}
                                >
                                    {t("button.save")}
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("button.save")}
                                </SaveButtonDisabled>
                        }
                    </Row>
                </Container>
            </Modal>
        );
    }
};

export default AddBoothVersion;