import React from 'react';
import {useTranslation} from "react-i18next";

const SelectErrorType = () => {

    const { t } = useTranslation();

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                padding: '50px',
                textAlign: 'center',
                fontSize: '14px',
                color: '#757575'
            }}
        >
            {t("menu.boothControl.sideMenus.boothErrors.messages.chooseMenu")}
        </div>
    );
};

export default SelectErrorType;