import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import UserService from "../../../../services/UserService";
import axios from "axios";
import DataUtils from "../../common/utils/DataUtils";
import DataCompareService from "../../../../services/DataCompareService";
import {Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../../common/modal/ModalCloseButton";
import TitleAndInputBox from "../../../common/dataView/TitleAndInputBox";
import ButtonWithSpinner from "../../common/buttons/ButtonWithSpinner";
import SimpleButton from "../../common/buttons/SimpleButton";
import Modal from "react-modal";
import LanguageInput from "../../../../language/component/LanguageInput";
import LanguageSelectBox from "../../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '290px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    franchiseMenuOptionCategoryName: "",
    franchiseMenuOptionCategoryName1: null,
    franchiseMenuOptionCategoryName2: null,
    franchiseMenuOptionCategoryName3: null,
    franchiseMenuOptionCategoryName4: null,
    franchiseMenuOptionCategoryName5: null
};

const isValidMapInit = {
    franchiseMenuOptionCategoryName: false,
    franchiseMenuOptionCategoryName1: true,
    franchiseMenuOptionCategoryName2: true,
    franchiseMenuOptionCategoryName3: true,
    franchiseMenuOptionCategoryName4: true,
    franchiseMenuOptionCategoryName5: true
};

const OptionCategoryAddModal = ({ isOpen, setIsOpen, onRefresh, franchiseId }) => {

    const { t } = useTranslation();

    /* states */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);

    /* state for language */
    const targetFieldsString = `"${t("menu.franchiseMenu.optionCategoryEdit.detail.categoryName")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* loading */
    const [isLoading, setIsLoading] = useState(false);

    /* save */
    const postOptionCategory = async () => {
        try {
            await setIsLoading(true);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-option-category`
                + `/franchise/${franchiseId}`;
            await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            await setIsLoading(false);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await onRefresh();
            setIsOpen(false);
        } catch (e) {
            console.log(e);
            await setIsLoading(false);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* effects */
    useEffect(() => {
        /* initialize */
        setRequestBody(requestBodyInit);
        setIsValidMap(isValidMapInit);
        setIsValid(false);
        setIsLoading(false);
    }, [isOpen]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchiseMenu.optionCategoryEdit.button.addOptionCategory")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    {/* language selection */}
                    <LanguageSelectBox
                        title={t("language.selectBox.title.menuOptionCategory")}
                        languageMetadataList={languageMetadataList}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        setIsHighlightOn={setIsHighlightOn}
                        targetFieldString={targetFieldsString}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <LanguageInput
                        type={"text"}
                        title={t("menu.franchiseMenu.optionCategoryEdit.detail.categoryName") + ` (${t("language.callName." + currentLang)})`}
                        dataKeyTranslationPath={"language.dataKey.menu.option.category.name"}
                        valueMap={requestBody}
                        updateValueMap={(key, value) => DataUtils.updateHelper(setRequestBody, key, value)}
                        isValidMap={isValidMap}
                        updateIsValidMap={(key, value) => DataUtils.updateHelper(setIsValidMap, key, value)}
                        isForEdit={true}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {
                        isLoading ?
                            <ButtonWithSpinner
                                buttonColor={"#fc7242"}
                            />
                            :
                            <SimpleButton
                                onClickCallback={() => postOptionCategory()}
                                isEnabled={isValid}
                                label={t("button.save")}
                                borderColor={"#fc7242"}
                                buttonColor={"#fc7242"}
                                buttonHoverColor={"#fff1ec"}
                                buttonDisabledColor={"#c2c2c2"}
                            />
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default OptionCategoryAddModal;