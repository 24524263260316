import DataParseService from "../../../services/DataParseService";

const _leftPad = (value) => {
    if (value >= 10) {
        return value;
    } else {
        return `0${value}`;
    }
};

/** yyyy-MM-dd format */
const formatDate = (givenDate, delimiter) => {
    const year = givenDate.getFullYear();
    const month = _leftPad(givenDate.getMonth() + 1);
    const day = _leftPad(givenDate.getDate());

    return [year, month, day].join(delimiter);
};

const craftLocaleString = (str, locale) => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    let newDate = new Date(str + 'T01:00:00');
    return newDate.toLocaleDateString(locale, options);
};

const craftLocaleDateTimeString = (str, locale) => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    let newDate = new Date(str);
    return newDate.toLocaleTimeString(locale, options);
};

const getYearAndMonth = (givenDate) => {
    const res = {
        "year" : givenDate.getFullYear(),
        "month" : givenDate.getMonth() + 1
    };
    return res;
};

/** format : { "yyyy-MM-dd" : { "count" : 0, "schedules" : [] }, { }, ... } */
const scheduleListToObj = (prevArray) => {
    if (prevArray.length === 0) {
        return {};
    }
    let newObj = {};
    prevArray.map((scheduleItem) => {
        let targetDate = DataParseService.dateTimeToDate(scheduleItem['scheduleDate']);
        if (Object.keys(newObj).includes(targetDate)) {
            /* existing */
            if (scheduleItem['scheduleStatusCode'] === 0) {
                /* Canceled */
                newObj[targetDate]['countOfCanceled'] = newObj[targetDate]['countOfCanceled'] + 1;
                newObj[targetDate]['canceledSchedules'].push(scheduleItem);
            } else if (scheduleItem['scheduleStatusCode'] === 1) {
                /* Planned */
                newObj[targetDate]['countOfPlanned'] = newObj[targetDate]['countOfPlanned'] + 1;
                newObj[targetDate]['plannedSchedules'].push(scheduleItem);
            } else {
                /* Done */
                newObj[targetDate]['countOfDone'] = newObj[targetDate]['countOfDone'] + 1;
                newObj[targetDate]['doneSchedules'].push(scheduleItem);
            }
        } else {
            let newObjItem = {
                countOfCanceled : 0,
                countOfPlanned : 0,
                countOfDone : 0,
                canceledSchedules : [],
                plannedSchedules : [],
                doneSchedules : []
            };
            if (scheduleItem['scheduleStatusCode'] === 0) {
                /* Canceled */
                newObjItem['countOfCanceled'] = newObjItem['countOfCanceled'] + 1;
                newObjItem['canceledSchedules'].push(scheduleItem);
            } else if (scheduleItem['scheduleStatusCode'] === 1) {
                /* Planned */
                newObjItem['countOfPlanned'] = newObjItem['countOfPlanned'] + 1;
                newObjItem['plannedSchedules'].push(scheduleItem);
            } else {
                /* Done */
                newObjItem['countOfDone'] = newObjItem['countOfDone'] + 1;
                newObjItem['doneSchedules'].push(scheduleItem);
            }
            newObj[targetDate] = newObjItem;
        }
    });
    return newObj;
};

const DateUtils = {
    formatDate,
    craftLocaleString,
    getYearAndMonth,
    scheduleListToObj,
    craftLocaleDateTimeString
};

export default DateUtils;