import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Container, Row, Spinner} from "react-bootstrap";
import ModalCloseButton from "../../../common/modal/ModalCloseButton";
import UserService from "../../../../services/UserService";
import axios from "axios";
import styled from "styled-components";
import BoothSyncItemView from "./modalInner/BoothSyncItemView";
import OverlayToolTip from "../../common/toolTip/OverlayToolTip";
import { SlRefresh } from "react-icons/sl";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '650px',
        height: '650px',
        margin: 'auto'
    }
};

const MenuName = styled.div`
  height: 40px;
  font-size: 15px;
  color: #757575;
  text-align: left;
`;

const FilterButton = styled.button`
  border: 1px solid ${(props) => props.color ? props.color : "#c2c2c2"};
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "white"};
  color: ${(props) => props.color ? props.color : "#c2c2c2"};
  transition: all ease-in-out 0.2s;
  margin-left: ${(props) => props.marginLeft ? props.marginLeft : "0px"};
  padding: 3px 15px;
  font-size: 14px;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.hoverColor ? props.hoverColor : "#eeeeee"};
  }
`;

const RefreshButton = styled.button`
  border: none;
  background: none;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  margin-left: auto;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: #ffcccb;
  }
`;

const BoothMenuSyncStatusView = ({ isOpen, setIsOpen, franchiseId, menuId }) => {

    const { t, i18n } = useTranslation();

    /* states */
    const [menuInfo, setMenuInfo] = useState({});
    const [boothSyncInfoList, setBoothSyncInfoList] = useState([]);
    const [targetBoothSync, setTargetBoothSync] = useState("NOT_DONE");

    /* loading */
    const [isLoading, setIsLoading] = useState(false);
    /* refresh loading */
    const [isRefreshLoading, setIsRefreshLoading] = useState(false);

    /* fetch data */
    const fetchMenuInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-menu`
                + `/franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newMenuList = res.data.item['franchiseMenuList'];
            const targetMenuInfo = extractTargetMenu(newMenuList);
            await setMenuInfo(targetMenuInfo);
        } catch (e) {
            console.log(e);
            setMenuInfo({});
        }
    };

    /* get sync list */
    const fetchBoothSyncList = async () => {
        try {
            await setIsRefreshLoading(true);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-menu`
                + `/franchise/${franchiseId}`
                + `/menu/${menuId}`
                + `/booth-sync-status`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newSyncList = res.data.item['boothSyncList'];
            await setBoothSyncInfoList(newSyncList);
            await setIsRefreshLoading(false);
        } catch (e) {
            console.log(e);
            await setIsRefreshLoading(false);
            setBoothSyncInfoList([]);
        }
    };

    /* extract target menu from menu list */
    const extractTargetMenu = (menuList) => {
        for (let i=0 ; i<menuList.length ; i++) {
            if (menuList[i]['menuInfo']['franchiseMenuId'] === menuId) {
                return menuList[i]['menuInfo'];
            }
        }
        return {};
    };

    /* filter function */
    const filterSyncList = (syncList) => {
        return syncList.filter((syncInfo) => {
            if (targetBoothSync === "DONE") {
                return syncInfo['boothMenuSyncInformation']['synchronized'];
            } else {
                return !syncInfo['boothMenuSyncInformation']['synchronized'];
            }
        });
    };

    /* effects */
    useEffect(() => {
        fetchMenuInfo()
            .then(() => fetchBoothSyncList());
        setTargetBoothSync("NOT_DONE");
    }, [isOpen]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchiseMenu.boothSync.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                {
                    (Object.keys(menuInfo).length !== 0) &&
                    <Row>
                        <MenuName>
                            {menuInfo[t("language.dataKey.menu.franchise.info.name")]}
                        </MenuName>
                    </Row>
                }
                {
                    (Object.keys(menuInfo).length === 0) &&
                    <Row>
                        <MenuName>
                            <Spinner size={"sm"} />
                        </MenuName>
                    </Row>
                }
                <Row>
                    {/* filter buttons */}
                    <div
                        style={{
                            width: '100%',
                            display: 'inline-flex'
                        }}
                    >
                        {/* not-done */}
                        <FilterButton
                            color={(targetBoothSync === "DONE") ? "#fc7242" : null}
                            backgroundColor={(targetBoothSync === "DONE") ? "#fff1ec" : null}
                            hoverColor={(targetBoothSync === "DONE") ? "#fff1ec" : null}
                            onClick={() => setTargetBoothSync("DONE")}
                        >
                            {t("menu.franchiseMenu.boothSync.detail.syncDone")}
                        </FilterButton>
                        {/* done */}
                        <FilterButton
                            color={(targetBoothSync === "NOT_DONE") ? "#fc7242" : null}
                            backgroundColor={(targetBoothSync === "NOT_DONE") ? "#fff1ec" : null}
                            hoverColor={(targetBoothSync === "NOT_DONE") ? "#fff1ec" : null}
                            marginLeft={"10px"}
                            onClick={() => setTargetBoothSync("NOT_DONE")}
                        >
                            {t("menu.franchiseMenu.boothSync.detail.syncNotDone")}
                        </FilterButton>
                        <OverlayToolTip
                            text={t("menu.franchiseMenu.boothSync.button.refresh")}
                            direction={"top"}
                        >
                            <RefreshButton
                                onClick={() => fetchBoothSyncList()}
                            >
                                <SlRefresh
                                    size={"25"}
                                />
                            </RefreshButton>
                        </OverlayToolTip>
                    </div>
                </Row>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            height: '420px',
                            overflow: 'auto',
                            padding: '5px',
                            marginTop: '10px'
                        }}
                    >
                        {
                            isRefreshLoading &&
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    paddingTop: '30px'
                                }}
                            >
                                <Spinner
                                    size={"sm"}
                                    style={{
                                        color: "#757575"
                                    }}
                                />
                            </div>
                        }
                        {
                            (filterSyncList(boothSyncInfoList).length === 0) && (!isRefreshLoading) &&
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    paddingTop: '30px'
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '14px',
                                        color: '#757575'
                                    }}
                                >
                                    {t("menu.franchiseMenu.boothSync.message.noSyncInfo")}
                                </span>
                            </div>
                        }
                        {
                            (!isRefreshLoading) &&
                            (filterSyncList(boothSyncInfoList).length > 0) &&
                            filterSyncList(boothSyncInfoList).map((boothSyncInfo) => {
                                return (
                                    <BoothSyncItemView
                                        key={boothSyncInfo['boothMenuSyncInformation']['menuBoothSyncId']}
                                        boothInfo={boothSyncInfo['boothInformation']}
                                        syncInfo={boothSyncInfo['boothMenuSyncInformation']}
                                        setIsOpen={setIsOpen}
                                        onRefresh={fetchBoothSyncList}
                                        franchiseId={franchiseId}
                                    />
                                );
                            })
                        }
                    </div>
                </Row>
            </Container>
        </Modal>
    );
};

export default BoothMenuSyncStatusView;