import React, {useEffect, useMemo, useState} from 'react';
import {Container, Row, Col, Figure} from "react-bootstrap";
import TopMenu from "./components/layout/TopMenu";
import UserService from "./services/UserService";
import {useTranslation} from "react-i18next";
import './HomeStyle.css';
import axios from "axios";
import SideNavigation from "./components/layout/SideNavigation";
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import RoleManagement from "./components/role/RoleManagement";
import GroupRoleEdit from "./components/role/groupRole/GroupRoleEdit";
import StaffManagement from "./components/staff/StaffManagement";
import GroupRoleDefault from "./components/role/groupRole/GroupRoleDefault";
import StaffDetail from "./components/staff/StaffDetail";
import StaffEdit from "./components/staff/StaffEdit";
import StaffAdd from "./components/staff/StaffAdd";
import CompanyRoleEdit from "./components/companyRole/CompanyRoleEdit";
import CompanySelected from "./components/companyRole/CompanySelected";
import CustomerManagement from "./components/customer/CustomerManagement";
import CustomerDetail from "./components/customer/CustomerDetail";
import CustomerEdit from "./components/customer/CustomerEdit";
import CustomerAdd from "./components/customer/CustomerAdd";
import ProductManagement from "./components/product/ProductManagement";
import ProductDetail from "./components/product/ProductDetail";
import ProductEdit from "./components/product/ProductEdit";
import ProductAdd from "./components/product/ProductAdd";
import OrderManagement from "./components/order/OrderManagement";
import OrderDetail from "./components/order/OrderDetail";
import OrderAdd from "./components/order/OrderAdd";
import MyPage from "./components/myPage/MyPage";
import BoardManagement from "./components/board/BoardManagement";
import BoardDetail from "./components/board/BoardDetail";
import BoardEdit from "./components/board/BoardEdit";
import BoardAdd from "./components/board/BoardAdd";
import BranchManagement from "./components/branch/BranchManagement";
import BranchDetail from "./components/branch/BranchDetail";
import BranchEdit from "./components/branch/BranchEdit";
import BranchAdd from "./components/branch/BranchAdd";
import GroupAppRole from "./components/groupAppRole/GroupAppRole";
import GroupAppRoleSelected from "./components/groupAppRole/GroupAppRoleSelected";
import FaqManagement from "./components/faq/FaqManagement";
import FaqDetail from "./components/faq/FaqDetail";
import FaqEdit from "./components/faq/FaqEdit";
import FaqAdd from "./components/faq/FaqAdd";
import CompanyManagement from "./components/groupCompany/CompanyManagement";
import DepartmentManagement from "./components/companyDepartment/DepartmentManagement";
import BoothManagement from "./components/booth/BoothManagement";
import BoothWarrantyManagement from "./components/booth/warranty/BoothWarrantyManagement";
import BoothVersionManagement from "./components/booth/version/BoothVersionManagement";
import BoothDetail from "./components/booth/detail/BoothDetail";
import BoothInfo from "./components/booth/detail/viewArea/BoothInfo";
import BoothDetailedInfo from "./components/booth/detail/viewArea/BoothDetailedInfo";
import DeviceView from "./components/booth/detail/viewArea/DeviceView";
import BoothAdd from "./components/booth/BoothAdd";
import Status from "./components/booth/detail/viewArea/innerDevice/Status";
import Attribute from "./components/booth/detail/viewArea/innerDevice/Attribute";
import SensorView from "./components/booth/detail/viewArea/innerDevice/SensorView";
import SensorCapacityView from "./components/booth/detail/viewArea/innerDevice/SensorCapacityView";
import BoothAlarmView from "./components/booth/detail/additional/BoothAlarmView";
import NotFound from "./auth/NotFound";
import QnaManagement from "./components/qna/QnaManagement";
import QnaDetail from "./components/qna/QnaDetail";
import GroupSelected from "./components/groupCompany/GroupSelected";
import GroupCompanySelected from "./components/companyDepartment/GroupCompanySelected";
import AlertView from "./components/alert/AlertView";
import 'react-toastify/dist/ReactToastify.css';
import FranchiseManagement from "./components/franchise/FranchiseManagement";
import FranchiseDetail from "./components/franchise/FranchiseDetail";
import FranchiseAppApiKeyManagement from "./components/franchiseAppApiKey/FranchiseAppApiKeyManagement";
import FranchiseAppKeyDetail from "./components/franchiseAppApiKey/FranchiseAppKeyDetail";
import ApiKeyDetailView from "./components/franchiseAppApiKey/innerComponents/ApiKeyDetailView";
import NoApiKeySelected from "./components/franchiseAppApiKey/innerComponents/NoApiKeySelected";
import NoFranchiseSelected from "./components/franchise/NoFranchiseSelected";
import FranchiseMenuManagement from "./components/menu/franchiseMenu/components/FranchiseMenuManagement";
import FranchiseInnerMenuManagement
    from "./components/menu/franchiseMenu/components/innerRoute/FranchiseInnerMenuManagement";
import FranchiseMenuInfoManagement
    from "./components/menu/franchiseMenu/components/innerRoute/FranchiseMenuInfoManagement";
import FranchiseMenuRecipeManagement
    from "./components/menu/franchiseMenu/components/innerRoute/FranchiseMenuRecipeManagement";
import FranchiseNotSelected from "./components/menu/franchiseMenu/components/message/FranchiseNotSelected";
import MenuNotSelected from "./components/menu/franchiseMenu/components/message/MenuNotSelected";
import BoothMessageHistoryView from "./components/booth/detail/messageLog/BoothMessageHistoryView";
import BranchMenuDiffManagement from "./components/menu/branchMenuDiff/components/BranchMenuDiffManagement";
import BranchMenuInfoDiffView from "./components/menu/branchMenuDiff/components/menuInfo/BranchMenuInfoDiffView";
import TopMenuDropDown from "./components/layout/newTopMenu/TopMenuDropDown";
import BoothMenuDiffManagement from "./components/booth/menuDiff/BoothMenuDiffManagement";
import BoothMenuDiffView from "./components/booth/menuDiff/layout/BoothMenuDiffView";
import MenuRecipeDiffView from "./components/booth/menuDiff/layout/innerDiffView/MenuRecipeDiffView";
import NoMenuSelected from "./components/booth/menuDiff/layout/default/NoMenuSelected";
import ScheduleManagement from "./components/schedule/ScheduleManagement";
import BoothErrorView from "./components/booth/detail/additional/BoothErrorView";
import UnresolvedErrorView from "./components/booth/detail/additional/errorView/UnresolvedErrorView";
import ResolvedErrorView from "./components/booth/detail/additional/errorView/ResolvedErrorView";
import SelectErrorType from "./components/booth/detail/additional/errorView/SelectErrorType";
import BranchDetailUSA from "./components/branch/BranchDetailUSA";
import BranchEditUSA from "./components/branch/BranchEditUSA";
import BranchAddUSA from "./components/branch/BranchAddUSA";
import FranchiseConsoleAccountManagement from "./components/consoleAccount/FranchiseConsoleAccountManagement";
import TargetFranchiseNotSelected from "./components/consoleAccount/etc/TargetFranchiseNotSelected";
import AccountInFranchiseView from "./components/consoleAccount/AccountInFranchiseView";
import TargetCredentialNotSelected from "./components/consoleAccount/etc/TargetCredentialNotSelected";
import AccountDetailView from "./components/consoleAccount/AccountDetailView";

const Home = () => {

    const [lang, setLang] = useState(sessionStorage.getItem("ims_language") ? sessionStorage.getItem("ims_language") : "en-US");

    const { t, i18n } = useTranslation();

    /** env : USA */
    //const isForUSA = Boolean(window.sessionStorage.getItem("service_region_ims"));
    const isForUSA = (window.sessionStorage.getItem("service_region_ims").toLowerCase() === "true");

    const handleLanguageChange = async (lang) => {
        if (lang === i18n.language) {
            return;
        }
        const axiosCall = () => axios.put(`${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${window.sessionStorage.getItem("ims_adminId")}/language`,
            JSON.stringify({
                adminLang : lang
            }),
            {
                headers: {
                    Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                    "Content-Type" : "application/json"
                }
            }
        );
        try {
            await UserService.updateToken(axiosCall);
            i18n.changeLanguage(lang);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        handleLanguageChange(lang);
    }, [lang]);

    const currentLang = i18n.language;

    return (
        <BrowserRouter>
            <div
                style={{
                    width: '100%',
                    height: '950px',
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                {/* side nav bar */}
                <div
                    style={{
                        width: '350px',
                        height: '100%'
                    }}
                >
                    <SideNavigation />
                </div>
                {/* main area */}
                <div
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    {/** -------------------- */}
                    {/** right top-most menus */}
                    {/** -------------------- */}

                    {/*<AlertMagic />*/}
                    <TopMenuDropDown />

                    {/** -------------------- */}
                    {/** -------------------- */}

                    {/* page routers */}
                    <Routes>
                        {/*<Route path="/" exact element={<Dashboard />}/>*/}
                        <Route path="/" exact element={<Dashboard />}/>
                        {/* dashboard */}
                        <Route path="/dashboard" element={<Dashboard />}/>
                        {/* my page */}
                        <Route path="/my-page" element={<MyPage />}/>
                        {/* roles */}
                        <Route path="/group-role" element={<RoleManagement />}>
                            <Route path={""} element={<GroupRoleDefault />} />
                            <Route path={":groupId"} element={<GroupRoleEdit />} />
                        </Route>
                        <Route path={"/company-role"} element={<CompanyRoleEdit />}>
                            <Route path={"company/:companyId"} element={<CompanySelected />} />
                        </Route>
                        {/* group app role */}
                        <Route path={"/gr-app-role"} element={<GroupAppRole />}>
                            <Route path={""} element={<GroupRoleDefault />} />
                            <Route path={"group/:groupId"} element={<GroupAppRoleSelected />} />
                        </Route>
                        {/* settings */}
                        {/*<Route path={"/settings"} element={<Settings />} />*/}
                        {/* staff */}
                        <Route path="/staff" element={<StaffManagement />} />
                        <Route path={"/staff/detail/:adminId"} element={<StaffDetail />} />
                        <Route path={"/staff/edit/:adminId"} element={<StaffEdit />} />
                        <Route path={"/staff/add"} element={<StaffAdd />} />
                        {/* customer */}
                        <Route path={"/customer"} element={<CustomerManagement />} />
                        <Route path={"/customer/detail/:customerId"} element={<CustomerDetail />} />
                        <Route path={"/customer/edit/:customerId"} element={<CustomerEdit />} />
                        <Route path={"/customer/add"} element={<CustomerAdd />} />

                        {/** for the USA, not provided */}
                        {
                            (!isForUSA) &&
                            <>
                                {/*product*/}
                                <Route path={"/product"} element={<ProductManagement />} />
                                <Route path={"/product/detail/:productId"} element={<ProductDetail />} />
                                <Route path={"/product/edit/:productId"} element={<ProductEdit />} />
                                <Route path={"/product/add"} element={<ProductAdd />} />
                                {/*order*/}
                                <Route path={"/order"} element={<OrderManagement />} />
                                <Route path={"/order/detail/:orderId"} element={<OrderDetail />} />
                                <Route path={"/order/add"} element={<OrderAdd />} />
                            </>
                        }

                        {/* board */}
                        <Route path={"/board"} element={<BoardManagement />} />
                        <Route path={"/board/detail/:boardId"} element={<BoardDetail />} />
                        <Route path={"/board/edit/:boardId"} element={<BoardEdit />} />
                        <Route path={"/board/add"} element={<BoardAdd />} />
                        {/* branch */}
                        <Route path={"/branch"} element={<BranchManagement />} />

                        {/** different routing for the USA */}
                        {
                            isForUSA &&
                            <Route path={"/branch/detail/:branchId"} element={<BranchDetailUSA />} />
                        }
                        {
                            (!isForUSA) &&
                            <Route path={"/branch/detail/:branchId"} element={<BranchDetail />} />
                        }

                        <Route path={"/branch/detail/:branchId/menu-diff"} element={<BranchMenuDiffManagement />}>
                            <Route path={""} element={<MenuNotSelected />} />
                            <Route path={"menu/:menuId"} element={<BranchMenuInfoDiffView />} />
                        </Route>

                        {/** different routing for the USA */}
                        {
                            isForUSA &&
                            <Route path={"/branch/edit/:branchId"} element={<BranchEditUSA />} />
                        }
                        {
                            (!isForUSA) &&
                            <Route path={"/branch/edit/:branchId"} element={<BranchEdit />} />
                        }


                        {/** different routing for the USA */}
                        {
                            isForUSA &&
                            <Route path={"/branch/add"} element={<BranchAddUSA />} />
                        }
                        {
                            (!isForUSA) &&
                            <Route path={"/branch/add"} element={<BranchAdd />} />
                        }

                        {/* faq */}
                        <Route path={"/faq"} element={<FaqManagement />} />
                        <Route path={"/faq/detail/:faqId"} element={<FaqDetail />} />
                        <Route path={"/faq/edit/:faqId"} element={<FaqEdit />} />
                        <Route path={"/faq/add"} element={<FaqAdd />} />
                        {/* qna */}
                        <Route path={"/qna"} element={<QnaManagement />} />
                        <Route path={"/qna/detail/:qnaId"} element={<QnaDetail />} />
                        {/* group and company management */}
                        <Route path={"/manage-gr-cm"} element={<CompanyManagement />}>
                            <Route path={"group/:groupId/company/:companyId"} element={<GroupSelected />} />
                        </Route>
                        {/* company and department management */}
                        <Route path={"/manage-cm-dp"} element={<DepartmentManagement />}>
                            <Route path={"group/:groupId/company/:companyId"} element={<GroupCompanySelected />} />
                        </Route>
                        {/* booth management */}
                        <Route path={"/booth"} element={<BoothManagement />} />
                        <Route path={"/booth/warranty"} element={<BoothWarrantyManagement />} />
                        <Route path={"/booth/version"} element={<BoothVersionManagement />} />
                        <Route path={"/booth/add"} element={<BoothAdd />} />
                        <Route path={"/booth/detail/:boothId"} element={<BoothDetail />}>
                            {/* booth info */}
                            <Route path={"info"} element={<BoothInfo />} />
                            {/* booth detailed info */}
                            <Route path={"detailed-info"} element={<BoothDetailedInfo />} />
                            {/* message history */}
                            <Route path={"message-log"} element={<BoothMessageHistoryView />} />
                            {/* error view */}
                            <Route path={"errors"} element={<BoothErrorView />}>
                                <Route path={""} element={<SelectErrorType />} />
                                <Route path={"unresolved"} element={<UnresolvedErrorView />} />
                                <Route path={"resolved"} element={<ResolvedErrorView />} />
                            </Route>
                            {/* booth control */}
                            <Route path={"device/:deviceId"} element={<DeviceView />}>
                                <Route path={"status"} element={<Status />} />
                                <Route path={"attributes"} element={<Attribute />} />
                                <Route path={"sensors"} element={<SensorView />}>
                                    <Route path={"not-found"} element={<NotFound />} />
                                    <Route path={":sensorId"} element={<SensorCapacityView />} />
                                </Route>
                            </Route>
                            <Route path={"booth-alarms"} element={<BoothAlarmView />} />
                            {/* menu-diff */}
                            <Route path={"menu-diff"} element={<BoothMenuDiffManagement />}>
                                <Route path={""} element={<NoMenuSelected />} />
                                <Route path={"menu/:menuId"} element={<BoothMenuDiffView />}>
                                    <Route path={"recipe"} element={<MenuRecipeDiffView />} />
                                </Route>
                            </Route>
                        </Route>
                        {/* alert */}
                        <Route path={"/alert"} element={<AlertView />} />
                        {/* franchise */}
                        <Route path={"/man-franchise"} element={<FranchiseManagement />}>
                            <Route path={""} element={<NoFranchiseSelected />} />
                            <Route path={"group/:groupId/franchise/:franchiseId"} element={<FranchiseDetail />} />
                        </Route>
                        {/* franchise console credential */}
                        <Route path={"/fr-console-credential"} element={<FranchiseConsoleAccountManagement />}>
                            <Route path={""} element={<TargetFranchiseNotSelected />} />
                            <Route path={"franchise/:franchiseId"} element={<AccountInFranchiseView />}>
                                <Route path={""} element={<TargetCredentialNotSelected />} />
                                <Route path={"credential/:credentialId"} element={<AccountDetailView />} />
                            </Route>
                        </Route>
                        {/* franchise app API key */}
                        <Route path={"/franchise-app-api"} element={<FranchiseAppApiKeyManagement />}>
                            <Route path={""} element={<NoFranchiseSelected />} />
                            <Route path={"group/:groupId/franchise/:franchiseId"} element={<FranchiseAppKeyDetail />}>
                                <Route path={""} element={<NoApiKeySelected />} />
                                <Route path={"app-api-code/:keyId"} element={<ApiKeyDetailView />} />
                            </Route>
                        </Route>
                        {/* franchise menu */}
                        <Route path={"/man-menu-franchise"} element={<FranchiseMenuManagement />}>
                            <Route path={""} element={<FranchiseNotSelected/>} />
                            <Route path={"franchise/:franchiseId"} element={<FranchiseInnerMenuManagement />}>
                                <Route path={""} element={<MenuNotSelected />} />
                                <Route path={"menu/:menuId/info"} element={<FranchiseMenuInfoManagement />} />
                                <Route path={"menu/:menuId/recipe"} element={<FranchiseMenuRecipeManagement />} />
                            </Route>
                        </Route>
                        {/* schedule */}
                        <Route path={"/schedule"} element={<ScheduleManagement />} />
                        {/* default router */}
                        <Route path={"/*"} element={<NotFound />} />
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
};

export default Home;