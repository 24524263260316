import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import OverlayToolTip from "../../menu/common/toolTip/OverlayToolTip";
import {CiSquareQuestion} from "react-icons/ci";

const LangSelectButton = styled.button`
  width: auto;
  padding: 3px 7px;
  color: ${(props) => props.color ? props.color : "#757575"};
  font-size: 14px;
  border: 1px solid ${(props) => props.color ? props.color : "#757575"};
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "white"};
  transition: all ease-in-out 0.2s;
  margin-left: ${(props) => props.marginLeft ? props.marginLeft : "0px"};
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.hoverColor ? props.hoverColor : "white"};
  }
`;

const LanguageSelectBox = ({ title, languageMetadataList, currentLang, setCurrentLang, setIsHighlightOn, targetFieldString }) => {

    const { t } = useTranslation();

    const onLanguageChange = (code) => {
        if (code === currentLang) {
            return;
        } else {
            setCurrentLang(code);
            /* twinkle */
            setIsHighlightOn(true);
            setTimeout(() => setIsHighlightOn(false), 300);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    marginBottom: '2px'
                }}
            >
                <span
                    style={{
                        color: '#8f8f8f',
                        fontSize: '14px'
                    }}
                >
                    {title}
                </span>
                <OverlayToolTip
                    direction={"right"}
                    text={t("language.message.multiLangTarget", { targets: targetFieldString })}
                >
                        <span
                            style={{
                                marginLeft: '10px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#8f8f8f'
                            }}
                        >
                            <CiSquareQuestion
                                size={"25"}
                            />
                        </span>
                </OverlayToolTip>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                {
                    languageMetadataList &&
                    languageMetadataList.map((metaData, index) => {
                        return (
                            <LangSelectButton
                                key={metaData['languageMetadataBrowserCode']}
                                color={(currentLang === metaData['languageMetadataBrowserCode']) ? "#fc7242" : "#c2c2c2"}
                                marginLeft={(index === 0) ? "0px" : "5px"}
                                backgroundColor={(currentLang === metaData['languageMetadataBrowserCode']) ? "#fff2e6" : "white"}
                                hoverColor={(currentLang === metaData['languageMetadataBrowserCode']) ? "#fff2e6" : "#F8F8F8"}
                                onClick={() => onLanguageChange(metaData['languageMetadataBrowserCode'])}
                            >
                                {t("language.callName." + metaData['languageMetadataBrowserCode'])}
                            </LangSelectButton>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default LanguageSelectBox;