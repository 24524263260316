import React from 'react';
import styled from "styled-components";
import Switch from "react-switch";

const Title = styled.span`
  font-size: 15px;
  color: #212529;
`;

const BooleanContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const TitleAndBoolean = ({ title, value, isForEdit, updateValue, updateIsValid, objectKey }) => {

    const handleValueChange = (newValue) => {
        if (isForEdit) {
            updateValue(objectKey, newValue);
            updateIsValid(objectKey, true);
        } else {
            return;
        }
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* title */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '5px',
                    textAlign: 'left'
                }}
            >
                <Title>
                    {title}
                </Title>
            </div>
            {/* categories */}
            <div
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                <BooleanContainer>
                    <Switch
                        checked={value}
                        onChange={handleValueChange}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor={"#52b640"}
                    />
                </BooleanContainer>
            </div>
        </div>
    );
};

export default TitleAndBoolean;