import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import UserService from "../../../../../services/UserService";
import axios from "axios";
import _ from "lodash";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import ServerFailed from "../../../../booth/detail/viewArea/info/ServerFailed";
import OverlayToolTip from "../../../common/toolTip/OverlayToolTip";
import {HiOutlineStatusOnline} from "react-icons/hi";
import styled from "styled-components";
import LockedTitleAndInput from "../../../common/lockComponent/LockedTitleAndInput";
import DataCompareService from "../../../../../services/DataCompareService";
import DataUtils from "../../../common/utils/DataUtils";
import DiffDataView from "../../dataView/DiffDataView";
import OnUploadModal from "../../../common/image/modal/OnUploadModal";
import ImageUploadBox from "../../../common/image/ImageUploadBox";
import ImageDiffUploadBox from "../../dataView/ImageDiffUploadBox";
import AuthService from "../../../../../services/AuthService";
import LanguageSelectBox from "../../../../common/lang/LanguageSelectBox";
import DiffDataViewWithLang from "../../dataView/DiffDataViewWithLang";
import ExtraInfoDiffView from "../../dataView/ExtraInfoDiffView";
import SimpleButton from "../../../common/buttons/SimpleButton";
import ButtonWithSpinner from "../../../common/buttons/ButtonWithSpinner";
import OrangeButton from "../../../common/buttons/OrangeButton";
import BoothMenuSyncStatusView from "../../../franchiseMenu/modals/BoothMenuSyncStatusView";
import BoothMenuSyncForBranchView from "../modal/BoothMenuSyncForBranchView";
import BranchOptionInfoDiffView from "../optionInfo/BranchOptionInfoDiffView";
import {SlRefresh} from "react-icons/sl";
import {toast} from "react-toastify";
import ToastAlertView from "../../../../common/alert/ToastAlertView";
import ImsSystemRole from "../../../../../auth/roles/ImsSystemRole";

const diffEntityInit = {
    isEnabled: null,
    branchMenuDiffAlias: null,
    branchMenuDiffAlias1: null,
    branchMenuDiffAlias2: null,
    branchMenuDiffAlias3: null,
    branchMenuDiffAlias4: null,
    branchMenuDiffAlias5: null,
    branchMenuDiffDescription: null,
    branchMenuDiffDescription1: null,
    branchMenuDiffDescription2: null,
    branchMenuDiffDescription3: null,
    branchMenuDiffDescription4: null,
    branchMenuDiffDescription5: null,
    branchMenuDiffPrice: null,
    branchMenuDiffPriceUnit: null,
    extraInformationList: null
};

const isValidMapInit = {
    isEnabled: true,
    branchMenuDiffAlias: true,
    branchMenuDiffAlias1: true,
    branchMenuDiffAlias2: true,
    branchMenuDiffAlias3: true,
    branchMenuDiffAlias4: true,
    branchMenuDiffAlias5: true,
    branchMenuDiffDescription: true,
    branchMenuDiffDescription1: true,
    branchMenuDiffDescription2: true,
    branchMenuDiffDescription3: true,
    branchMenuDiffDescription4: true,
    branchMenuDiffDescription5: true,
    branchMenuDiffPrice: true,
    branchMenuDiffPriceUnit: true,
    extraInformationList: true
};

const AreaTitle = styled.div`
  font-size: 15px;
  color: #757575;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
`;

const BoothSyncButton = styled.button`
  border: none;
  border-radius: 5px;
  background: none;
  transition: all ease-in-out 0.2s;
  color: #fc7242;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: red;
  }
`;

const BranchMenuInfoDiffView = () => {

    const { t } = useTranslation();
    const { branchId, menuId } = useParams();

    /* states */
    /* franchise id */
    const [menuFranchiseId, setMenuFranchiseId] = useState("");

    /* for editing */
    const [menuInfo, setMenuInfo] = useState({});
    const [diffEntity, setDiffEntity] = useState(diffEntityInit);
    const [fixedDiffEntity, setFixedDiffEntity] = useState(diffEntityInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);

    const [beforeImgFile, setBeforeImageFile] = useState("");
    const [afterImgFile, setAfterImageFile] = useState("");
    /** indicates image source */
    const [imgDiffExist, setImgDiffExist] = useState(false);

    /* isLoading */
    const [isLoading, setIsLoading] = useState(false);
    const [isImageUploading, setIsImageUploading] = useState(false);

    /* modal */
    const [isBoothSyncViewModalOpen, setIsBoothSyncViewModalOpen] = useState(false);

    /* state for language */
    const targetFieldsString = `"${t("menu.branch.branchMenu.detail.name")}", "${t("menu.branch.branchMenu.detail.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* fetch menu information */
    const fetchMenuInfo = async () => {
        await setIsLoading(true);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/branch-menu/branch/${branchId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newMenuList = res.data.item['branchMenuList'];
            /* extract target menu */
            let targetMenuInfo = {};
            newMenuList.map(menuInfoObj => {
                if (menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuId'] === menuId) {
                    targetMenuInfo = menuInfoObj;
                }
            });

            /* set data */
            await setMenuInfo(targetMenuInfo);
            await initializeData(targetMenuInfo);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setMenuInfo({});
            setIsLoading(false);
        }
    };

    const putMenuDiff = async () => {
        await setIsImageUploading(true);
        /* Transfer diffEntity into request body format */
        let newRequestBody = {};
        /* extra information setup */
        if (diffEntity['extraInformationList'] == null) {
            newRequestBody['extraInformation'] = null;
        } else {
            if (diffEntity['extraInformationList'].length > 0) {
                newRequestBody['extraInformation'] = DataUtils.formatObjArrayToObj(diffEntity['extraInformationList']);
            } else {
                newRequestBody['extraInformation'] = {};
            }
        }
        newRequestBody['isEnabled'] = diffEntity['isEnabled'];
        newRequestBody['franchiseMenuAlias'] = diffEntity['branchMenuDiffAlias'];
        newRequestBody['franchiseMenuAlias1'] = diffEntity['branchMenuDiffAlias1'];
        newRequestBody['franchiseMenuAlias2'] = diffEntity['branchMenuDiffAlias2'];
        newRequestBody['franchiseMenuAlias3'] = diffEntity['branchMenuDiffAlias3'];
        newRequestBody['franchiseMenuAlias4'] = diffEntity['branchMenuDiffAlias4'];
        newRequestBody['franchiseMenuAlias5'] = diffEntity['branchMenuDiffAlias5'];
        newRequestBody['franchiseMenuDescription'] = diffEntity['branchMenuDiffDescription'];
        newRequestBody['franchiseMenuDescription1'] = diffEntity['branchMenuDiffDescription1'];
        newRequestBody['franchiseMenuDescription2'] = diffEntity['branchMenuDiffDescription2'];
        newRequestBody['franchiseMenuDescription3'] = diffEntity['branchMenuDiffDescription3'];
        newRequestBody['franchiseMenuDescription4'] = diffEntity['branchMenuDiffDescription4'];
        newRequestBody['franchiseMenuDescription5'] = diffEntity['branchMenuDiffDescription5'];
        newRequestBody['franchiseMenuPrice'] = diffEntity['branchMenuDiffPrice'];
        newRequestBody['franchiseMenuPriceUnit'] = diffEntity['branchMenuDiffPriceUnit'];

        /* axios call */
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/`
                + `branch-menu/branch/${branchId}/menu/${menuId}/diff`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            /* reset */
            await fetchMenuInfo();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
        setIsImageUploading(false);
    };

    /* initialize */
    const initializeData = async (menuInfoObj) => {

        /* set franchiseId */
        await setMenuFranchiseId(menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuFranchiseId']);

        let newMenuInfo = {};

        /* default : original information */
        newMenuInfo['isEnabled'] = menuInfoObj['menuInfoBeforeMergeDiff']['isEnabled'];
        newMenuInfo['franchiseMenuAlias'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuAlias'];
        newMenuInfo['franchiseMenuAlias1'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuAlias1'];
        newMenuInfo['franchiseMenuAlias2'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuAlias2'];
        newMenuInfo['franchiseMenuAlias3'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuAlias3'];
        newMenuInfo['franchiseMenuAlias4'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuAlias4'];
        newMenuInfo['franchiseMenuAlias5'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuAlias5'];
        newMenuInfo['franchiseMenuDescription'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuDescription'];
        newMenuInfo['franchiseMenuDescription1'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuDescription1'];
        newMenuInfo['franchiseMenuDescription2'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuDescription2'];
        newMenuInfo['franchiseMenuDescription3'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuDescription3'];
        newMenuInfo['franchiseMenuDescription4'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuDescription4'];
        newMenuInfo['franchiseMenuDescription5'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuDescription5'];
        newMenuInfo['franchiseMenuPrice'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuPrice'];
        newMenuInfo['franchiseMenuPriceUnit'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuPriceUnit'];
        newMenuInfo['franchiseMenuImage'] = menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuImage'];
        /* make extra information list */
        newMenuInfo['extraInformationList'] = menuInfoObj['menuInfoBeforeMergeDiff']['extraInformation'] ?
            objectToArray(menuInfoObj['menuInfoBeforeMergeDiff']['extraInformation']) : [];

        /* set menuInfo */
        setMenuInfo(newMenuInfo);

        /* set image states */
        if (menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuImage']) {
            await setBeforeImageFile(menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuImage']);
        } else {
            await setBeforeImageFile("");
        }

        /* set other states */
        if (menuInfoObj['menuInfoDiffEntity'] == null) {
            /* menu diff entity not exist */
            await setImgDiffExist(false);
            await setAfterImageFile("");
            await setDiffEntity(diffEntityInit);
            await setFixedDiffEntity(_.cloneDeep(diffEntityInit));
            await setIsValidMap(isValidMapInit);
            await setIsValid(true);
            await setIsEdited(false);
        } else {
            /* generate a new diff entity */
            let newDiffEntity = {};
            newDiffEntity['branchMenuDiffAlias'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffAlias'];
            newDiffEntity['branchMenuDiffAlias1'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffAlias1'];
            newDiffEntity['branchMenuDiffAlias2'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffAlias2'];
            newDiffEntity['branchMenuDiffAlias3'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffAlias3'];
            newDiffEntity['branchMenuDiffAlias4'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffAlias4'];
            newDiffEntity['branchMenuDiffAlias5'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffAlias5'];
            newDiffEntity['branchMenuDiffDescription'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffDescription'];
            newDiffEntity['branchMenuDiffDescription1'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffDescription1'];
            newDiffEntity['branchMenuDiffDescription2'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffDescription2'];
            newDiffEntity['branchMenuDiffDescription3'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffDescription3'];
            newDiffEntity['branchMenuDiffDescription4'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffDescription4'];
            newDiffEntity['branchMenuDiffDescription5'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffDescription5'];
            newDiffEntity['branchMenuDiffPrice'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffPrice'];
            newDiffEntity['branchMenuDiffPriceUnit'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffPriceUnit'];
            newDiffEntity['isEnabled'] = menuInfoObj['menuInfoDiffEntity']['isEnabled'];
            newDiffEntity['extraInformationList'] = menuInfoObj['menuInfoDiffEntity']['branchMenuDiffExtraInfo'] ?
                objectToArray(JSON.parse(menuInfoObj['menuInfoDiffEntity']['branchMenuDiffExtraInfo'])) : null;
            if (menuInfoObj['menuInfoDiffEntity']['branchMenuDiffImage']) {
                await setImgDiffExist(true);
                await setAfterImageFile(menuInfoObj['menuInfoDiffEntity']['branchMenuDiffImage']);
            } else {
                await setImgDiffExist(false);
                await setAfterImageFile("");
            }
            await setDiffEntity(newDiffEntity);
            await setFixedDiffEntity(_.cloneDeep(newDiffEntity));
            await setIsValidMap(isValidMapInit);
            await setIsValid(true);
            await setIsEdited(false);
        }
    };


    /* upload image */
    const uploadImage = async (file) => {
        await setIsImageUploading(true);
        const formData = new FormData();
        formData.append("file", file);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/image/menu-diff/branch/${branchId}/menu/${menuId}`;
            const axiosCall = () => axios.post(
                fetchUrl,
                formData,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "multipart/form-data",
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            /* success */
            await setIsImageUploading(false);
            fetchMenuInfo();
        } catch (e) {
            console.log(e);
            await setIsImageUploading(false);
            toast.error(<ToastAlertView message={t("message.failedToSaveImage")} />);
            fetchMenuInfo();
        }
    };

    /* delete image when diff exists => non-exists */
    const deleteImage = async () => {
        await setIsImageUploading(true);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/image/menu-diff/branch/${branchId}/menu/${menuId}`;
            const axiosCall = () => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            /* success */
            await setIsImageUploading(false);
            fetchMenuInfo();
        } catch (e) {
            console.log(e);
            await setIsImageUploading(false);
            toast.error(<ToastAlertView message={t("message.failed")} />);
            fetchMenuInfo();
        }
    };

    /* utils */
    const updateDiffEntity = (key, value) => {
        DataUtils.updateHelper(setDiffEntity, key, value);
    };

    const updateIsValidMap = (key, value) => {
        DataUtils.updateHelper(setIsValidMap, key, value);
    };

    /* for extra information : object to array */
    const objectToArray = (object) => {
        let newArray = [];
        Object.keys(object).map(objectKey => {
            let newElement = {};
            newElement[objectKey] = object[objectKey];
            newArray.push(newElement);
        });
        return newArray;
    };

    /* effects */
    useEffect(() => {
        fetchMenuInfo();
    }, [menuId]);

    /* track edited */
    useEffect(() => {
        setIsEdited(!_.isEqual(diffEntity, fixedDiffEntity));
    }, [diffEntity]);

    /* track validity */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    /* auth */
    const hasPutAuth = () => {
        return AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BRANCH_MANAGEMENT, "PUT");
    };

    if (isLoading) {
        return (
            <div
                style={{
                    width: '100%',
                    paddingTop: '100px',
                    textAlign: 'center'
                }}
            >
                <Spinner />
            </div>
        );
    } else if ((!isLoading) && (_.isEqual(menuInfo, {})) && (_.isEqual(diffEntity, {}))) {
        return (
            <div
                style={{
                    width: '100%',
                    paddingTop: '50px'
                }}
            >
                <ServerFailed />
            </div>
        );
    } else {
        return (
            <Container fluid>
                <Row>
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '10px'
                            }}
                        >
                            <AreaTitle>
                                {t("menu.franchiseMenu.menuInfo.title")}
                            </AreaTitle>
                            <div
                                style={{
                                    marginLeft: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <OverlayToolTip
                                    text={t("menu.franchiseMenu.boothSync.message.hoverMessage")}
                                    direction={"bottom"}
                                >
                                    <BoothSyncButton
                                        onClick={() => setIsBoothSyncViewModalOpen(true)}
                                    >
                                        <SlRefresh
                                            size={"25"}
                                        />
                                    </BoothSyncButton>
                                </OverlayToolTip>
                            </div>
                        </div>
                        <div
                            className={"contentInner"}
                            style={{
                                height: '744px',
                                width: '100%'
                            }}
                        >
                            {/* menu information view */}
                            <Container fluid>
                                <Row>
                                    <LanguageSelectBox
                                        title={t("language.selectBox.title.menu")}
                                        languageMetadataList={languageMetadataList}
                                        currentLang={currentLang}
                                        setCurrentLang={setCurrentLang}
                                        setIsHighlightOn={setIsHighlightOn}
                                        targetFieldString={targetFieldsString}
                                    />
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <DiffDataView
                                        type={"switch"}
                                        hoverMessageForUseDiffButton={t("menu.branch.branchMenu.message.useDiffMessage", { fieldName : `"${t("menu.branch.branchMenu.detail.isEnabled")}"` })}
                                        title={t("menu.branch.branchMenu.detail.isEnabled")}
                                        originalDataKey={"isEnabled"}
                                        diffDataKey={"isEnabled"}
                                        originalData={menuInfo}
                                        diffEntity={diffEntity}
                                        isValidMap={isValidMap}
                                        updateDiffEntity={updateDiffEntity}
                                        updateIsValidMap={updateIsValidMap}
                                        isForEdit={hasPutAuth()}
                                        booleanReversed={true}
                                        booleanOnMessage={t("message.menuOnThisBranch")}
                                        booleanOffMessage={t("message.menuOffThisBranch")}
                                    />
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <Col
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start'
                                        }}
                                        md={"4"}
                                    >
                                        <div>
                                            <ImageDiffUploadBox
                                                boxKey={menuId}
                                                beforeImgFile={beforeImgFile}
                                                afterImgFile={afterImgFile}
                                                setAfterImgFile={setAfterImageFile}
                                                buttonText={t("menu.franchiseMenu.button.uploadImage")}
                                                setImageFileElement={async (file) => {
                                                    if (hasPutAuth) {
                                                        await uploadImage(file);
                                                        await fetchMenuInfo();
                                                    }
                                                }}
                                                useMode={
                                                    imgDiffExist ?
                                                        "diff"
                                                        :
                                                        "default"
                                                }
                                                onNotUseDiff={async () => {
                                                    if (hasPutAuth()) {
                                                        await deleteImage();
                                                        await setImgDiffExist(false);
                                                    }
                                                }}
                                                onUseDiff={async () => {
                                                    if (hasPutAuth()) {
                                                        await setImgDiffExist(true);
                                                    }
                                                }}
                                                hoverMessageForUseDiffButton={t("menu.branch.branchMenu.message.useDiffMessage", { fieldName : `"${t("menu.branch.branchMenu.detail.image")}"` })}
                                            />
                                        </div>
                                    </Col>
                                    <Col
                                        md={"8"}
                                    >
                                        <Row>
                                            <DiffDataViewWithLang
                                                type={"text"}
                                                hoverMessageForUseDiffButton={t("menu.branch.branchMenu.message.useDiffMessage", { fieldName : `"${t("menu.branch.branchMenu.detail.name")}"` })}
                                                title={t("menu.branch.branchMenu.detail.name") + ` (${t("language.callName." + currentLang)})`}
                                                originalData={menuInfo}
                                                diffEntity={diffEntity}
                                                isValidMap={isValidMap}
                                                updateDiffEntity={updateDiffEntity}
                                                updateIsValidMap={updateIsValidMap}
                                                isForEdit={hasPutAuth()}
                                                originalDataKeyTranslationPath={"language.dataKey.branchMenu.alias"}
                                                diffDataKeyTranslationPath={"language.dataKey.branchMenuDiff.alias"}
                                                currentLang={currentLang}
                                                isHighlightOn={isHighlightOn}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <DiffDataViewWithLang
                                                type={"textarea"}
                                                hoverMessageForUseDiffButton={t("menu.branch.branchMenu.message.useDiffMessage", { fieldName : `"${t("menu.branch.branchMenu.detail.description")}"` })}
                                                title={t("menu.branch.branchMenu.detail.description") + ` (${t("language.callName." + currentLang)})`}
                                                originalData={menuInfo}
                                                diffEntity={diffEntity}
                                                isValidMap={isValidMap}
                                                updateDiffEntity={updateDiffEntity}
                                                updateIsValidMap={updateIsValidMap}
                                                isForEdit={hasPutAuth()}
                                                originalDataKeyTranslationPath={"language.dataKey.branchMenu.description"}
                                                diffDataKeyTranslationPath={"language.dataKey.branchMenuDiff.description"}
                                                currentLang={currentLang}
                                                isHighlightOn={isHighlightOn}
                                                height={"100"}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <Col
                                        style={{
                                            padding: '0px'
                                        }}
                                    >
                                        <DiffDataView
                                            type={"number"}
                                            hoverMessageForUseDiffButton={t("menu.branch.branchMenu.message.useDiffMessage", { fieldName : `"${t("menu.branch.branchMenu.detail.price")}"` })}
                                            title={t("menu.branch.branchMenu.detail.price")}
                                            originalDataKey={"franchiseMenuPrice"}
                                            diffDataKey={"branchMenuDiffPrice"}
                                            originalData={menuInfo}
                                            diffEntity={diffEntity}
                                            isValidMap={isValidMap}
                                            updateDiffEntity={updateDiffEntity}
                                            updateIsValidMap={updateIsValidMap}
                                            isForEdit={hasPutAuth()}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            padding: '0px',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <DiffDataView
                                            type={"text"}
                                            hoverMessageForUseDiffButton={t("menu.branch.branchMenu.message.useDiffMessage", { fieldName : `"${t("menu.branch.branchMenu.detail.priceUnit")}"` })}
                                            title={t("menu.branch.branchMenu.detail.priceUnit")}
                                            originalDataKey={"franchiseMenuPriceUnit"}
                                            diffDataKey={"branchMenuDiffPriceUnit"}
                                            originalData={menuInfo}
                                            diffEntity={diffEntity}
                                            isValidMap={isValidMap}
                                            updateDiffEntity={updateDiffEntity}
                                            updateIsValidMap={updateIsValidMap}
                                            isForEdit={hasPutAuth()}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <ExtraInfoDiffView
                                        title={t("menu.branch.branchMenu.detail.extraInfo.title")}
                                        originalDataObjList={menuInfo["extraInformationList"] ? menuInfo["extraInformationList"] : []}
                                        diffDataObjList={diffEntity["extraInformationList"]}
                                        updateDiffObjList={(newList) => updateDiffEntity("extraInformationList", newList)}
                                        updateIsValidMap={setIsValidMap}
                                        diffObjKey={"extraInformationList"}
                                        height={"170"}
                                        isForEdit={hasPutAuth()}
                                        hoverMessageForUseDiffButton={t("menu.branch.branchMenu.message.useDiffMessage", { fieldName : `"${t("menu.branch.branchMenu.detail.extraInfo.title")}"` })}
                                    />
                                </Row>
                                {/* reset and save button */}
                                <Row
                                    style={{
                                        marginTop: '20px'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100px',
                                                marginLeft: 'auto'
                                            }}
                                        >
                                            <SimpleButton
                                                onClickCallback={fetchMenuInfo}
                                                isEnabled={isEdited}
                                                label={t("menu.franchiseMenu.menuInfo.button.reset")}
                                                borderColor={"#999999"}
                                                buttonColor={"#999999"}
                                                buttonHoverColor={"#ececec"}
                                                buttonDisabledColor={"#c2c2c2"}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: '100px',
                                                marginLeft: '10px'
                                            }}
                                        >
                                            <OrangeButton
                                                onClickCallback={() => putMenuDiff()}
                                                isEnabled={isValid && isEdited}
                                                label={t("button.save")}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                    <Col
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '16px'
                            }}
                        >
                            <AreaTitle>
                                {t("menu.franchiseMenu.menuInfo.optionInfo.title")}
                            </AreaTitle>
                        </div>
                        <div
                            className={"contentInner"}
                            style={{
                                height: '744px',
                                width: '100%'
                            }}
                        >
                            <BranchOptionInfoDiffView />
                        </div>
                    </Col>
                </Row>
                {/* image uploading modal */}
                <OnUploadModal
                    isOpen={isImageUploading}
                />
                {/* booth sync */}
                <BoothMenuSyncForBranchView
                    isOpen={isBoothSyncViewModalOpen}
                    setIsOpen={setIsBoothSyncViewModalOpen}
                    branchId={branchId}
                    menuId={menuId}
                />
            </Container>
        );
    }
};

export default BranchMenuInfoDiffView;