import React from 'react';
import {useTranslation} from "react-i18next";
import RecipeUtils from "../../util/RecipeUtils";
import OverlayToolTip from "../../../toolTip/OverlayToolTip";
import styled from "styled-components";

const Title = styled.span`
  font-size: 14px;
  color: #8f8f8f;
  padding-left: 5px;
`;

const TextInput = styled.input`
  width: 100%;
  border: 1px solid ${(props) => props.isValid ? "#e4e7ea" : "red"};
  border-radius: 5px;
  padding: 7px 10px;
  color: #212529;
  font-size: 15px;
  transition: all ease-in-out 0.2s;

  :focus {
    border-color: #fc7242;
  }
`;
const StringFieldView = ({ title,
                             data,
                             validator,
                             updateData,
                             updateIsValidMap,
                             isValid,
                             isForEdit }) => {

    const { t } = useTranslation();

    const handleInputChange = async (valueStr) => {
        if (isForEdit) {
            await updateData(valueStr);
            updateIsValidMap(RecipeUtils.validatePrimitiveData(validator, valueStr));
        } else {
            return;
        }
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* title */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '5px',
                    textAlign: 'left'
                }}
            >
                <Title>
                    {title}
                </Title>
            </div>
            {/* input box */}
            <div
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                {
                    (validator['minLength'] != null) &&
                    (validator['maxLength'] != null) &&
                    <OverlayToolTip
                        text={t("menu.franchiseMenu.recipeInfo.menuRecipe.message.inputHelpMessage.string", {
                            fieldName: title,
                            minLength: validator['minLength'],
                            maxLength: validator['maxLength']
                        })}
                        direction={"left"}
                    >
                        <TextInput
                            type={"text"}
                            readOnly={!isForEdit}
                            value={data}
                            onChange={(e) => handleInputChange(e.target.value)}
                            isValid={isValid}
                        />
                    </OverlayToolTip>
                }
                {
                    ((validator['minLength'] == null) ||
                    (validator['maxLength'] == null)) &&
                    <TextInput
                        type={"text"}
                        readOnly={!isForEdit}
                        value={data}
                        onChange={(e) => handleInputChange(e.target.value)}
                        isValid={isValid}
                    />
                }
            </div>
        </div>
    );
};

export default StringFieldView;