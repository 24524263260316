import React, {useRef, useState} from 'react';
import {Image} from "react-bootstrap";
import styled from "styled-components";
import OverlayToolTip from "../../common/toolTip/OverlayToolTip";
import {useTranslation} from "react-i18next";
import ConfirmModal from "../../../common/confirm/ConfirmModal";
import Form from "react-bootstrap/Form";
import {FiLock, FiUnlock} from "react-icons/fi";


const colorMap = {
    backgroundGrey : "#F8F8F8",
    defaultButtonColor : "#c2c2c2",
    defaultButtonHoverColor : "#F5F5F5",
    defaultButtonColorOnSelected : "#A9A9A9",
    diffUseButtonColor : "#F0B3FF",
    diffUseButtonHoverColor : "#FAE6FF",
    diffUseButtonColorOnSelected : "#CC00FF"
};

const OptionButton = styled.button`
  width: auto;
  padding: 3px 7px;
  color: ${(props) => props.color ? props.color : "#757575"};
  font-size: 14px;
  border: 1px solid ${(props) => props.color ? props.color : "#757575"};
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "white"};
  transition: all ease-in-out 0.2s;
  margin-left: ${(props) => props.marginLeft ? props.marginLeft : "0px"};
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.hoverColor ? props.hoverColor : "white"};
  }
`;

const TransButton = styled.button`
  border: none;
  background: none;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  color: #757575;

  &:hover {
    color: ${(props) => props.enabled ? "black" : ""};
  }

  &:focus {
    outline: none;
  }
`;

const ImageDiffUploadBox = ({ boxKey, beforeImgFile, afterImgFile, setAfterImgFile, buttonText, setImageFileElement, useMode, onNotUseDiff, onUseDiff, hoverMessageForUseDiffButton }) => {

    const { t } = useTranslation();

    const fileRef = useRef();

    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const handleFileChange = async () => {
        const file = fileRef.current.files[0];
        setImageFileElement(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setAfterImgFile(reader.result);
        };
    };

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    marginBottom: '5px'
                }}
            >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px'
                        }}
                    >
                        {t("menu.branch.branchMenu.detail.image")}
                    </span>
                <div
                    style={{
                        height: '100%',
                        display: 'inline-block',
                        marginLeft: "10px"
                    }}
                >
                    {
                        (useMode === "diff") &&
                        <OverlayToolTip
                            direction={"top"}
                            text={hoverMessageForUseDiffButton}
                        >
                            <TransButton
                                onClick={() => {
                                    if (useMode === "diff") {
                                        setConfirmationOpen(true);
                                    } else if (useMode === "default") {
                                        onUseDiff();
                                    }
                                }}
                            >
                                <FiUnlock
                                    size={"14"}
                                    color={"#fc7242"}
                                />
                            </TransButton>
                        </OverlayToolTip>
                    }
                    {
                        (useMode !== "diff") &&
                        <TransButton
                            onClick={() => {
                                if (useMode === "diff") {
                                    setConfirmationOpen(true);
                                } else if (useMode === "default") {
                                    onUseDiff();
                                }
                            }}
                            enabled={true}
                        >
                            <FiLock
                                size={"14"}
                            />
                        </TransButton>
                    }
                </div>
            </div>
            <div
                style={{
                    width: '100%',
                    textAlign: 'center'
                }}
            >
                {
                    (useMode === "diff") &&
                    <Image
                        src={
                            afterImgFile === "" ?
                                "https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png"
                                :
                                afterImgFile
                        }
                        alt=""
                        onError={(e) => e.currentTarget.src="https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png"}
                        roundedCircle={false}
                        style={{ width: '100px', height: '100px', display: 'inline-block', border: '0'}}
                    />
                }
                {
                    (useMode === "default") &&
                    <Image
                        src={
                            beforeImgFile === "" ?
                                "https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png"
                                :
                                beforeImgFile
                        }
                        alt=""
                        onError={(e) => e.currentTarget.src="https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png"}
                        roundedCircle={false}
                        style={{ width: '100px', height: '100px', display: 'inline-block', border: '0'}}
                    />
                }
            </div>
            <div
                style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '5px'
                }}
            >
                {
                    (useMode === "default") &&
                    <label
                        className="signup-profileImg-label"
                        htmlFor={"nothing"}
                        style={{
                            color: '#ebebeb'
                        }}
                    >
                        {buttonText}
                    </label>
                }
                {
                    (useMode === "diff") &&
                    <>
                        <label className="signup-profileImg-label" htmlFor={boxKey + "-profileImg"}>
                            {buttonText}
                        </label>
                        <input
                        type="file"
                        accept="image/*"
                        id={boxKey + "-profileImg"}
                        onChange={() => handleFileChange()}
                        ref={fileRef}
                        readOnly={(useMode === "default")}
                        />
                    </>
                }
            </div>
            {/* confirmation */}
            <ConfirmModal
                isOpen={confirmationOpen}
                setIsOpen={setConfirmationOpen}
                title={t("menu.branch.branchMenu.message.prevImageDeletedConfirm")}
                confirmLabel={t("button.yes")}
                rejectLabel={t("button.no")}
                onConfirm={onNotUseDiff}
                onReject={() => {}}
            />
        </div>
    );
};

export default ImageDiffUploadBox;