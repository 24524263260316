import logo from './logo.svg';
import './App.css';
import RenderOnAnonymous from "./auth/RenderOnAnonymous";
import RenderOnAuthenticated from "./auth/RenderOnAuthenticated";
import Login from "./auth/Login";
import Home from "./Home";
import Modal from 'react-modal';

Modal.setAppElement('#root');

function App() {
    return (
        <div className="App">
            <RenderOnAnonymous>
                <Login />
            </RenderOnAnonymous>
            <RenderOnAuthenticated>
                <Home />
            </RenderOnAuthenticated>
        </div>
    );
}

export default App;
