import React, {useEffect, useState} from 'react';
import axios from "axios";
import UserService from "../../services/UserService";
import BoothService from "./detail/axiosServices/BoothService";
import DataCompareService from "../../services/DataCompareService";
import {useTranslation} from "react-i18next";
import {Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import {Link, useNavigate} from "react-router-dom";
import RenderOnRole from "../../auth/RenderOnRole";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import BackwardButton from "../common/layoutSub/BackwardButton";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const requestBodyInit = {
    boothVersionId : "",
    serialNumber : "",
    manufactureDate : "",
    boothGroupId : ""
};

const isValidMapInit = {
    boothVersionId : false,
    serialNumber : false,
    manufactureDate : false,
    boothGroupId : false
};


const BoothAdd = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    /* groupList */
    const [groupList, setGroupList] = useState([]);
    /* boothVersionList */
    const [boothVersionList, setBoothVersionList] = useState([]);
    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    /* isValid */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    /* NONE | FAIL_TO_CHECK | ON_CHECK | DUPLICATED | OK */
    const [serialStatus, setSerialStatus] = useState("NONE");
    /* duplicate message */
    const [serialMessage, setSerialMessage] = useState("");

    /* fetch group list */
    const fetchGroupList = async () => {
        try {
            const fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data['error_code'] === "GET_EMPTY_DATA" ) {
                setGroupList([]);
            } else {
                setGroupList(res.data.item.groupList);
                handleBoothGroupIdChange(res.data.item.groupList[0]['groupId']);
            }
        } catch (e) {
            setGroupList([]);
            console.log(e);
        }
    };

    /* fetch and format booth version list */
    const fetchBoothVersions = async () => {
        const onSuccessCallBack = (data) => {
            let versionList = formatBoothNameList(data);
            setBoothVersionList(versionList);
            if (versionList.length !== 0) {
                handleBoothVersionIdChange(versionList[0]['boothVersionId']);
            }
        };
        const onFailureCallBack = (e) => {
            setBoothVersionList([]);
            console.log(e);
        }
        BoothService.getBoothVersionList("", onSuccessCallBack, onFailureCallBack);
    };

    const postBooth = async () => {
        const onSuccess = () => {
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate("/booth");
        };
        const onFailure = (e) => {
            console.log(e);
            if (e.response.data) {
                toast.error(<ToastAlertView message={t("message.saveFailed")} />);
            } else {
                toast.error(<ToastAlertView message={t("message.failed")} />);
            }
        };
        BoothService.postBooth(requestBody, onSuccess, onFailure);
    };

    /* utils */
    const formatBoothNameList = (boothNameList) => {
        let newList = [];
        boothNameList.map((boothName, index) => {
            if (boothName['versions'].length !== 0) {
                boothName['versions'].map((version) => {
                   newList.push(
                       {
                           boothVersionId : version['boothVersionId'],
                           boothVersionName : boothName['boothName'] + " " + version['boothVersion']
                       }
                   );
                });
            }
        });
        return newList;
    };

    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* input handlers */
    const handleBoothGroupIdChange = (value) => {
        updateRequestBody("boothGroupId", value);
        if (value === "" | value == null) {
            updateIsValidMap("boothGroupId", false);
        } else {
            updateIsValidMap("boothGroupId", true);
        }
    };

    const handleBoothVersionIdChange = (value) => {
        updateRequestBody("boothVersionId", value);
        if (value === "" | value == null) {
            updateIsValidMap("boothVersionId", false);
        } else {
            updateIsValidMap("boothVersionId", true);
        }
    };

    const handleBoothSerialInput = (value) => {
        /* NONE | FAIL_TO_CHECK | ON_CHECK | DUPLICATED | OK */
        updateRequestBody("serialNumber", value);
        if (value === "" | value == null) {
            updateIsValidMap("serialNumber", false);
            setSerialStatus("NONE");
            setSerialMessage(t("menu.booth.add.message.input"));
        } else {
            /* duplication check : for nonempty */
            const onSuccess = (result) => {
                if (result) {
                    /* duplicated! */
                    updateIsValidMap("serialNumber", false);
                    setSerialStatus("DUPLICATED");
                    setSerialMessage(t("menu.booth.add.message.duplicated"));
                } else {
                    /* okay */
                    updateIsValidMap("serialNumber", true);
                    setSerialStatus("OK");
                    setSerialMessage(t("menu.booth.add.message.ok"));
                }
            };
            const onFailure = (e) => {
                console.log(e);
                updateIsValidMap("serialNumber", false);
                setSerialStatus("FAIL_TO_CHECK");
                setSerialMessage(t("menu.booth.add.message.failed"));
            };
            updateIsValidMap("serialNumber", false);
            setSerialStatus("ON_CHECK");
            setSerialMessage(t("menu.booth.add.message.onCheck"));
            BoothService.checkDuplicateSerial(value, onSuccess, onFailure);
        }
    };

    const handleManufactureDate = (value) => {
        updateRequestBody("manufactureDate", value);
        if (value === "" | value == null) {
            updateIsValidMap("manufactureDate", false);
        } else {
            updateIsValidMap("manufactureDate", true);
        }
    };

    /* effects */
    /* effect : initialize */
    useEffect(() => {
        fetchGroupList();
        fetchBoothVersions();
    }, []);

    /* effect : isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    const renderSerialMessage = () => {
        let color = "";
        /* NONE | FAIL_TO_CHECK | ON_CHECK | DUPLICATED | OK */
        if (serialStatus === "FAIL_TO_CHECK") {
            color = "red";
        } else if (serialStatus === "ON_CHECK") {
            color = "#757575";
        } else if (serialStatus === "DUPLICATED") {
            color = "red";
        } else if (serialStatus === "OK") {
            color = "blue";
        } else {
            color = "red";
        }
        return (
            <span
                style={{
                    color: color,
                    fontSize: '13px'
                }}
            >
                {serialMessage}
            </span>
        );
    };

    return (
        <RenderOnRole type={"POST"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={'/booth'}
                />
                <PageTitleWithDepth
                    titles={[t("menu.booth.title"), t("menu.booth.add.title")]}
                    depth={2}
                />
            </div>
            <div className={"contentContainer"}>
                <Container fluid>
                    <Row>
                        {/* main area */}
                        <div
                            className={"contentInner"}
                            style={{
                                height: '800px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Container fluid>
                                {/* manufactured date */}
                                <Row>
                                    <TitleAndInputBox
                                        title={t("menu.booth.add.detail.manufactureDate")}
                                        value={requestBody['manufactureDate']}
                                        onChange={handleManufactureDate}
                                        isForEdit={true}
                                        type={"date"}
                                        isValid={isValidMap['manufactureDate']}
                                        isEnabled={true}
                                    />
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <TitleAndSelectBox
                                        title={t("menu.booth.add.detail.boothGroup")}
                                        innerValue={requestBody['boothGroupId']}
                                        valueList={groupList}
                                        valueSelector={"groupId"}
                                        viewSelector={"groupName"}
                                        onChange={handleBoothGroupIdChange}
                                    />
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <TitleAndSelectBox
                                        title={t("menu.booth.add.detail.boothVersion")}
                                        innerValue={requestBody['boothVersionId']}
                                        valueList={boothVersionList}
                                        valueSelector={"boothVersionId"}
                                        viewSelector={"boothVersionName"}
                                        onChange={handleBoothVersionIdChange}
                                    />
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <TitleAndInputBox
                                        title={t("menu.booth.add.detail.serialNumber")}
                                        value={requestBody['serialNumber']}
                                        onChange={handleBoothSerialInput}
                                        isForEdit={true}
                                        type={"text"}
                                        isValid={isValidMap['serialNumber']}
                                        isEnabled={true}
                                    />
                                </Row>
                                <Row>
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {
                                            renderSerialMessage()
                                        }
                                    </div>
                                </Row>
                            </Container>
                            {/* buttons */}
                            {/* bottom btn */}
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'right',
                                    marginTop: 'auto',
                                    paddingBottom: '30px'
                                }}
                            >
                                <div
                                    style={{ marginRight: '10px' }}
                                >
                                    <Link
                                        id={"edit-btn"}
                                        to={`/booth`}
                                    >
                                        {t("button.cancel")}
                                    </Link>
                                </div>
                                <div>
                                    {
                                        (isValid)
                                            ?
                                            <Link
                                                id={"save-btn"}
                                                onClick={() => postBooth()}
                                            >
                                                {t("button.save")}
                                            </Link>
                                            :
                                            <Link
                                                id={"save-btn-disabled"}
                                            >
                                                {t("button.save")}
                                            </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default BoothAdd;