import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import UserService from "../../../services/UserService";
import DataCompareService from "../../../services/DataCompareService";
import Modal from "react-modal";
import {FaCaretSquareLeft, FaCaretSquareRight} from "react-icons/fa";
import uuid from "react-uuid";
import AuthParseService from "../../../services/AuthParseService";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '1000px',
        height: '730px',
        margin: 'auto'
    }
}

const CustomerAppRoleEdit = ({ isOpen, setIsOpen, groupId, userId }) => {

    const { t, i18n } = useTranslation();

    /* role lists */
    const [availableRoleList, setAvailableRoleList] = useState([]);
    const [currentRoleList, setCurrentRoleList] = useState([]);
    const [prevRoleList, setPrevRoleList] = useState([]);
    const [totalRoleList, setTotalRoleList] = useState([]);

    /* selected index */
    const [selectedCurrentRoleIndex, setSelectedCurrentRoleIndex] = useState(-1);
    const [selectedAvailableRoleIndex, setSelectedAvailableRoleIndex] = useState(-1);

    /* IsReady */
    const [isAssignReady, setIsAssignReady] = useState(false);
    const [isRevokeReady, setIsRevokeReady] = useState(false);

    /* isEdit */
    const [isEdited, setIsEdited] = useState(false);

    /* utils */
    const getObjectByImsSystemRoleId = (list, id) => {
        return list.find(item => item.appSystemRoleId === id);
    };

    const initializeAll = () => {
        /* initialize logic */
        fetchAvailableRoleList();
        fetchCurrentRoleList();
        fetchTotalRoleList();
        setSelectedAvailableRoleIndex(-1);
        setSelectedCurrentRoleIndex(-1);
    };

    const filterCurrentRoleList = (roleList) => {
        return roleList.map(item => ({
            appSystemRoleId: item.appSystemRoleId,
            accessCode: item.accessCode
        }));
    };

    /* fetch role list */
    const fetchAvailableRoleList = async () => {
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
            + `/api/v1/franchise-app-role/user/${userId}/available`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers : {
                    Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        if (res.data["error_code"] === "GET_EMPTY_DATA") {
            setAvailableRoleList([]);
        } else {
            setAvailableRoleList(res.data.item.roleList);
        }
    };

    const fetchCurrentRoleList = async () => {
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
            + `/api/v1/franchise-app-role/user/${userId}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers : {
                    Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        if (res.data["error_code"] === "GET_EMPTY_DATA") {
            setCurrentRoleList([]);
            setPrevRoleList([]);
        } else {
            setCurrentRoleList(res.data.item.roleList);
            setPrevRoleList(DataCompareService.deepCopyList(res.data.item.roleList));
        }
    };

    // total roel <= group app role
    const fetchTotalRoleList = async () => {
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
            + `/api/v1/franchise-app-role/group/${groupId}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers : {
                    Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        if (res.data["error_code"] === "GET_EMPTY_DATA") {
            setTotalRoleList([]);
        } else {
            setTotalRoleList(res.data.item.roleList);
        }
    };

    const handleReset = () => {
        initializeAll();
    };

    const handleRoleAccessCodeChange = (roleIndex, code) => {
        setCurrentRoleList(prevRoleList => {
            let newRoleList = [...prevRoleList];
            newRoleList[roleIndex].accessCode = code;
            return newRoleList;
        });
    };

    const handleAssign = () => {
        if (!isAssignReady) {
            return;
        }
        const targetRole = availableRoleList[selectedAvailableRoleIndex];
        setAvailableRoleList(prevRoleList => {
            let newRoleList = [...prevRoleList];
            newRoleList.splice(selectedAvailableRoleIndex, 1);
            return newRoleList;
        });
        setCurrentRoleList(prevRoleList => {
            let newRoleList = [...prevRoleList];
            newRoleList.push(targetRole);
            return newRoleList;
        })
        setSelectedAvailableRoleIndex(-1);
        setSelectedCurrentRoleIndex(-1);
    };

    const handleRevoke = () => {
        if (!isRevokeReady) {
            return;
        }
        const targetRole = currentRoleList[selectedCurrentRoleIndex];
        setCurrentRoleList(prevRoleList => {
            let newRoleList = [...prevRoleList];
            newRoleList.splice(selectedCurrentRoleIndex, 1);
            return newRoleList;
        });
        setAvailableRoleList(prevRoleList => {
            let newRoleList = [...prevRoleList];
            newRoleList.push(targetRole);
            return newRoleList;
        });
        setSelectedAvailableRoleIndex(-1);
        setSelectedCurrentRoleIndex(-1);
    };

    const putUserAppRole = async () => {
        try {
            await setIsEdited(false);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                            + `/api/v1/franchise-app-role/user/${userId}`;
            let requestBody = filterCurrentRoleList(currentRoleList);
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        "Authorization" : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            initializeAll();
            setIsOpen(false);
        } catch (e) {
            await setIsEdited(true);
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
            initializeAll();
            setIsOpen(false);
        }
    };

    /* effects */
    useEffect(() => {
        initializeAll();
    }, []);

    /* select tracker */
    useEffect(() => {
        if (selectedCurrentRoleIndex >= 0 ) {
            setIsRevokeReady(true);
        } else {
            setIsRevokeReady(false);
        }
    }, [selectedCurrentRoleIndex]);

    useEffect(() => {
        if (selectedAvailableRoleIndex >= 0 ) {
            setIsAssignReady(true);
        } else {
            setIsAssignReady(false);
        }
    }, [selectedAvailableRoleIndex]);

    /* list edit tracker */
    useEffect(() => {
        setIsEdited(!DataCompareService.isSameList(currentRoleList, prevRoleList));
    }, [currentRoleList]);

    /* renderers */
    const renderAvailableRoles = () => {
        return availableRoleList.map((role, index) => {
           return (
               <div
                   style={{
                       marginTop: '10px',
                       textAlign: 'left'
                   }}
                   key={uuid()}
               >
                   <button
                       className={"roleButton"}
                       style={
                           (selectedAvailableRoleIndex === index) ?
                               {
                                   backgroundColor: '#fc7242',
                                   borderColor: '#fc7242',
                                   color: 'white'
                               }
                               :
                               {}
                       }
                       onClick={() => {
                           if (index !== selectedAvailableRoleIndex) {
                               setSelectedAvailableRoleIndex(index);
                           } else {
                               setSelectedAvailableRoleIndex(-1);
                           }
                       }}
                   >
                       {role[t("language.dataKey.appRole.label")]}
                   </button>
               </div>
           );
        });
    };

    const renderCurrentRoles = () => {
        return currentRoleList.map((role, index) => {
            return (
                <div
                    style={{
                        marginTop: '10px',
                        textAlign: 'left'
                    }}
                    key={uuid()}
                >
                    <button
                        className={"roleButton"}
                        style={
                            (selectedCurrentRoleIndex === index) ?
                                {
                                    backgroundColor: '#fc7242',
                                    borderColor: '#fc7242',
                                    color: 'white'
                                }
                                :
                                {}
                        }
                        onClick={() => {
                            if (index !== selectedCurrentRoleIndex) {
                                setSelectedCurrentRoleIndex(index);
                            } else {
                                setSelectedCurrentRoleIndex(-1);
                            }
                        }}
                    >
                        {role[t("language.dataKey.appRole.label")]}
                    </button>
                </div>
            );
        });
    };

    const renderRoleSpec = () => {
        if (selectedCurrentRoleIndex === -1) {
            return <></>
        } else {
            return (
                <div
                    style={{
                        padding: '0px 10px 0px 10px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            marginTop: '15px'
                        }}
                    >
                        <span
                            className={"roleSpec-title"}
                        >
                            {t("menu.companyRole.roleName")}
                        </span>
                    </div>
                    <div>
                        <input
                            className={"roleSpec-input"}
                            readOnly={true}
                            value={currentRoleList[selectedCurrentRoleIndex][t("language.dataKey.appRole.label")]}
                        />
                    </div>
                    <div
                        style={{
                            textAlign: 'left',
                            marginTop: '15px'
                        }}
                    >
                        <span
                            className={"roleSpec-title"}
                        >
                            {t("menu.companyRole.roleDescription")}
                        </span>
                    </div>
                    <div>
                        <textarea
                            className={"roleSpec-textarea"}
                            readOnly={true}
                            value={currentRoleList[selectedCurrentRoleIndex].description}
                        />
                    </div>
                    <div
                        style={{
                            textAlign: 'left',
                            marginTop: '15px'
                        }}
                    >
                        <span
                            className={"roleSpec-title"}
                        >
                            {t("menu.companyRole.accessCode")}
                        </span>
                    </div>
                    <div
                        style={{
                            textAlign: 'left',
                            padding: '10px',
                            border: '1px solid #757575',
                            borderRadius: '5px'
                        }}
                    >
                        {/* GET */}
                        <div>
                            {
                                AuthParseService.hasGetCode(getObjectByImsSystemRoleId(totalRoleList, currentRoleList[selectedCurrentRoleIndex].appSystemRoleId).accessCode)
                                    ?
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={AuthParseService.hasGetCode(currentRoleList[selectedCurrentRoleIndex].accessCode)}
                                            onChange={() => {
                                                if (AuthParseService.hasGetCode(currentRoleList[selectedCurrentRoleIndex].accessCode)) {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.deleteGetCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                } else {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.addGetCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                }
                                            }}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle"}
                                        >
                                            {t("menu.companyRole.sort.get")}
                                        </span>
                                    </>
                                    :
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={false}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle-disabled"}
                                        >
                                            {t("menu.companyRole.sort.get")}
                                        </span>
                                    </>
                            }
                        </div>
                        {/* PUT */}
                        <div>
                            {
                                AuthParseService.hasPutCode(getObjectByImsSystemRoleId(totalRoleList, currentRoleList[selectedCurrentRoleIndex].appSystemRoleId).accessCode)
                                    ?
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={AuthParseService.hasPutCode(currentRoleList[selectedCurrentRoleIndex].accessCode)}
                                            onChange={() => {
                                                if (AuthParseService.hasPutCode(currentRoleList[selectedCurrentRoleIndex].accessCode)) {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.deletePutCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                } else {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.addPutCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                }
                                            }}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle"}
                                        >
                                            {t("menu.companyRole.sort.put")}
                                        </span>
                                    </>
                                    :
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={false}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle-disabled"}
                                        >
                                            {t("menu.companyRole.sort.put")}
                                        </span>
                                    </>
                            }
                        </div>
                        {/* POST */}
                        <div>
                            {
                                AuthParseService.hasPostCode(getObjectByImsSystemRoleId(totalRoleList, currentRoleList[selectedCurrentRoleIndex].appSystemRoleId).accessCode)
                                    ?
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={AuthParseService.hasPostCode(currentRoleList[selectedCurrentRoleIndex].accessCode)}
                                            onChange={() => {
                                                if (AuthParseService.hasPostCode(currentRoleList[selectedCurrentRoleIndex].accessCode)) {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.deletePostCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                } else {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.addPostCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                }
                                            }}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle"}
                                        >
                                            {t("menu.companyRole.sort.post")}
                                        </span>
                                    </>
                                    :
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={false}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle-disabled"}
                                        >
                                            {t("menu.companyRole.sort.post")}
                                        </span>
                                    </>
                            }
                        </div>
                        {/* DELETE */}
                        <div>
                            {
                                AuthParseService.hasDeleteCode(getObjectByImsSystemRoleId(totalRoleList, currentRoleList[selectedCurrentRoleIndex].appSystemRoleId).accessCode)
                                    ?
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={AuthParseService.hasDeleteCode(currentRoleList[selectedCurrentRoleIndex].accessCode)}
                                            onChange={() => {
                                                if (AuthParseService.hasDeleteCode(currentRoleList[selectedCurrentRoleIndex].accessCode)) {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.deleteDeleteCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                } else {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.addDeleteCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                }
                                            }}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle"}
                                        >
                                            {t("menu.companyRole.sort.delete")}
                                        </span>
                                    </>
                                    :
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={false}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle-disabled"}
                                        >
                                            {t("menu.companyRole.sort.delete")}
                                        </span>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    padding: '15px',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* title */}
                <ModalCloseButton
                    title={t("menu.customer.roleEdit.title")}
                    setIsOpen={setIsOpen}
                />
                {/* initialize button */}
                <div
                    style={{
                        textAlign: 'right',
                        marginBottom: '10px'
                    }}
                >
                    {
                        isEdited ?
                            <button
                                id={"reset-btn"}
                                onClick={() => handleReset()}
                            >
                                {t("button.reset")}
                            </button>
                            :
                            <button
                                id={"reset-btn-disabled"}
                            >
                                {t("button.reset")}
                            </button>
                    }
                </div>
                {/* main */}
                <div
                    style={{
                        width: '100%',
                        height: '520px',
                        display: 'flex'
                    }}
                >
                    {/* available roles */}
                    <div
                        style={{
                            width: '30%',
                            height: '100%',
                            border: '1px solid #757575',
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '50px',
                                textAlign: 'left',
                                padding: '15px',
                                borderBottom: '1px solid #c2c2c2'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575'
                                }}
                            >
                                {t("menu.companyRole.text.unassignedRoles")}
                            </span>
                        </div>
                        <div
                            style={{
                                padding: '5px',
                                height: '450px',
                                overflowY: 'auto'
                            }}
                        >
                            {renderAvailableRoles()}
                        </div>
                    </div>

                    {/* assign & revoke buttons */}
                    <div
                        style={{
                            height: '100%',
                            width: '10%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <div
                            style={{
                                marginBottom: '10px',
                                textAlign: 'center'
                            }}
                        >
                            <button
                                style={{
                                    outline: 'none',
                                    background: 'none',
                                    border: 'none',
                                    padding: '0px',
                                    margin: '0px'
                                }}
                                onClick={() => handleAssign()}
                            >
                                <FaCaretSquareRight
                                    size={"40"}
                                    style={isAssignReady ? { color: '#fc7242' } : { color: '#ebebeb' }}
                                />
                            </button>
                        </div>
                        <div
                            style={{
                                marginTop: '10px',
                                textAlign: 'center'
                            }}
                        >
                            <button
                                style={{
                                    outline: 'none',
                                    background: 'none',
                                    border: 'none',
                                    padding: '0px',
                                    margin: '0px'
                                }}
                                onClick={() => handleRevoke()}
                            >
                                <FaCaretSquareLeft
                                    size={"40"}
                                    style={isRevokeReady ? { color: '#fc7242' } : { color: '#ebebeb' }}
                                />
                            </button>
                        </div>
                    </div>

                    {/* current roles */}
                    <div
                        style={{
                            width: '60%',
                            height: '100%',
                            border: '1px solid #757575',
                            display: 'flex',
                            borderRadius: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: '50%',
                                height: '100%',
                                borderRight: '1px dashed #757575'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    textAlign: 'left',
                                    padding: '15px',
                                    borderBottom: '1px solid #c2c2c2'
                                }}
                            >
                                <span
                                    style={{
                                        color: '#757575'
                                    }}
                                >
                                    {t("menu.companyRole.text.assignedRoles")}
                                </span>
                            </div>
                            <div
                                style={{
                                    padding: '5px',
                                    height: '450px',
                                    overflowY: 'auto'
                                }}
                            >
                                {renderCurrentRoles()}
                            </div>
                        </div>
                        <div
                            style={{
                                width: '50%',
                                height: '100%'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    textAlign: 'left',
                                    padding: '15px',
                                    borderBottom: '1px solid #c2c2c2'
                                }}
                            >
                                <span
                                    style={{
                                        color: '#757575'
                                    }}
                                >
                                    {t("menu.companyRole.text.roleSpecification")}
                                </span>
                            </div>
                            <div>
                                {renderRoleSpec()}
                            </div>
                        </div>
                    </div>
                </div>
                {/* save-btn */}
                <div
                    style={{
                        marginTop: '15px',
                        width: '100%',
                        height: '50px',
                    }}
                >
                    {
                        isEdited ?
                            <button
                                id={"long-save-btn"}
                                onClick={() => putUserAppRole()}
                            >
                                {t("button.save")}
                            </button>
                            :
                            <button
                                id={"long-save-btn-disabled"}
                            >
                                {t("button.save")}
                            </button>
                    }
                </div>
            </div>
        </Modal>
    );
};

export default CustomerAppRoleEdit;