import React from 'react';
import {useTranslation} from "react-i18next";

const GroupRoleDefault = () => {

    const {t} = useTranslation();

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start'
        }}
        >
            <span
                style={{
                    color: '#c2c2c2',
                    fontSize: '15px'
                }}
            >
                {t("message.chooseGroup")}
            </span>
        </div>
    );
};

export default GroupRoleDefault;