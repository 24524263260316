import React from 'react';
import {useTranslation} from "react-i18next";
import TitleAndTextInput from "../../dataView/TitleAndTextInput";
import styled from "styled-components";
import TitleAndCategoricalText from "../../dataView/TitleAndCategoricalText";

const DataContainer = styled.div`
  width: 100%;
  padding: 15px 15px 40px 15px;
  background-color: white;
  border-radius: 5px;
`;

const Data = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const StatusInner = ({ statusData }) => {

    const { t } = useTranslation();

    return (
        <DataContainer>
            <Data>
                <TitleAndCategoricalText
                    title={t("menu.boothControl.status.status")}
                    value={statusData['state']}
                    isForEdit={false}
                    updateValue={()=>{}}
                    updateIsValid={()=>{}}
                    objectKey={"none"}
                    validator={{
                        pattern : "^(Active|Pending|Failed|Unknown)$"
                    }}
                />
            </Data>
            <Data>
                <TitleAndTextInput
                    title={t("menu.boothControl.status.reason")}
                    value={statusData['reason']}
                    isForEdit={false}
                    isValid={true}
                    updateValue={()=>{}}
                    updateIsValid={()=>{}}
                    isEnabled={true}
                    objectKey={"none"}
                />
            </Data>
            <Data>
                <TitleAndTextInput
                    title={t("menu.boothControl.status.probeTime")}
                    value={statusData['lastProbeDate']}
                    isForEdit={false}
                    isValid={true}
                    updateValue={()=>{}}
                    updateIsValid={()=>{}}
                    isEnabled={true}
                    objectKey={"none"}
                />
            </Data>
        </DataContainer>
    );
};

export default StatusInner;