import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import axios from "axios";
import UserService from "../../../services/UserService";
import PaginationHandler from "../../common/pagination/PaginationHandler";
import uuid from "react-uuid";
import styled from "styled-components";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '800px',
        height: '500px',
        margin: 'auto'
    }
};

const DisabledCustomer = styled.div`
  width: 100%;
  height: 40px;
  padding: 5px;
  border: 1px solid #ebebeb;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: #ebebeb;
`;

const EnabledCustomer = styled.div`
  width: 100%;
  height: 40px;
  padding: 5px 20px;
  border: 1px solid #ebebeb;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: all ease-in-out 0.2s;
  color: #757575;
  display: flex;
  flex-direction: row;
`;

const DisabledButton = styled.button`
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: white;
  color: #ebebeb;
  font-size: 15px;
  padding: 2px 10px;

  &:focus {
    outline: none;
  }
`;

const EnabledButton = styled.button`
  border: 1px solid #fc7242;
  border-radius: 5px;
  background-color: white;
  color: #fc7242;
  font-size: 15px;
  padding: 2px 10px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #fc7242;
    color: white;
  }
  
  &:focus {
    outline: none;
  }
`;

const AddManager = ({ isOpen, setIsOpen, onRefresh, managerIdList, branchGroupId, branchId }) => {

    const { t } = useTranslation();

    /* manager list */
    const [customerList, setCustomerList] = useState([]);

    /* pagination */
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);

    /* fetch customers */
    const fetchCustomerList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user?size=7&`
                + `user_type=manager`
                + `&page=${currentPageIndex}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                let dataList = [];
                for (let i=0; i<6; i++) {
                    dataList.push({
                        "userId" : null
                    });
                }
                setCustomerList([]);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            } else {
                let dataList = [...res.data.item.userList];
                const iter = 7 - dataList.length;
                if (dataList.length < 7) {
                    for (let i=0; i<iter; i++) {
                        dataList.push({
                            "userId" : null
                        });
                    }
                }
                setCustomerList(dataList);
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            }
        } catch (e) {
            console.error(e);
        }
    };

    /* axios */
    const putManager = async (customerId) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/branch/`
                + `${branchId}/`
                + `manager/${customerId}`;
            const axiosCall = () => axios.post(
                fetchUrl,
                {},
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            onRefresh();
            toast.success(<ToastAlertView message={t("message.saved")} />);
            setIsOpen(false);
        } catch (e) {
            console.error(e);
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
        }
    };

    /* effects */
    useEffect(() => {
        fetchCustomerList();
    }, []);

    /* utils */
    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    }

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    }

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    }

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    }

    const renderCustomerList = (customer, index) => {
        if (customer['userId'] == null) {
            return (
                <DisabledCustomer
                    key={uuid()}
                />
            )
        } else {
            return (
                <EnabledCustomer
                    key={uuid()}
                >
                    <div>
                        <span
                            style={{
                                fontWeight: '500'
                            }}
                        >
                            {customer['userName']}
                        </span>
                        <span
                            style={{
                                marginLeft: '40px',
                                color: '#c2c2c2'
                            }}
                        >
                            {customer['userId']}
                        </span>
                    </div>
                    <div
                        style={{
                            marginLeft: 'auto'
                        }}
                    >
                        {
                            ((branchGroupId === customer['userGroupId']) && (!managerIdList.includes(customer['userId']))) ?
                                <EnabledButton
                                    onClick={() => putManager(customer['userId'])}
                                >
                                    {t("button.add")}
                                </EnabledButton>
                                :
                                <DisabledButton>
                                    {t("button.add")}
                                </DisabledButton>
                        }
                    </div>
                </EnabledCustomer>
            );
        }
    }

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                onRefresh();
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.branch.detail.manager.addManager")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            height: '350px'
                        }}
                    >
                        {
                            customerList.map((customer, index) => {
                                return renderCustomerList(customer, index);
                            })
                        }
                    </div>
                    <div
                        style={{
                            width: '50%',
                            height: '50px'
                        }}
                    >
                        <PaginationHandler
                            goBefore={handleGoBefore}
                            goAfter={handleGoAfter}
                            goFirst={handleGoFirst}
                            goLast={handleGoLast}
                            pageSize={7}
                            currentIndex={currentPageIndex}
                            maxIndex={maxPageIndex}
                            totalItemNumber={totalElements}
                        />
                    </div>
                </Row>
            </Container>
        </Modal>
    );
};

export default AddManager;