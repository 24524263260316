import React from 'react';
import {useTranslation} from "react-i18next";

const FranchiseNotSelected = () => {

    const { t } = useTranslation();

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '50px'
            }}
        >
                <span
                    style={{
                        fontSize: '14px',
                        color: '#757575'
                    }}
                >
                    {t("menu.franchiseMenu.message.pleaseSelectFranchise")}
                </span>
        </div>
    );
};

export default FranchiseNotSelected;