import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import DataCompareService from "../../../services/DataCompareService";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../common/dataView/TitleAndTextArea";
import TitleAndSelectBox from "../../common/dataView/TitleAndSelectBox";
import UserService from '../../../services/UserService';
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import LanguageInput from "../../../language/component/LanguageInput";
import LanguageSelectBox from "../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '540px',
        margin: 'auto'
    }
}

const requestBodyInit = {
    categoryName: "",
    categoryName1: null,
    categoryName2: null,
    categoryName3: null,
    categoryName4: null,
    categoryName5: null,
    description: "",
    description1: null,
    description2: null,
    description3: null,
    description4: null,
    description5: null,
    productCategoryGroupId: ""
};

const isValidMapInit = {
    categoryName: false,
    categoryName1: true,
    categoryName2: true,
    categoryName3: true,
    categoryName4: true,
    categoryName5: true,
    description: false,
    description1: true,
    description2: true,
    description3: true,
    description4: true,
    description5: true,
    productCategoryGroupId: true
};

const ProductCategoryAdd = ({ isOpen, setIsOpen, onRefresh }) => {

    const { t, i18n } = useTranslation();

    /* group list */
    const [groupList, setGroupList] = useState([]);
    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    /* validity */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);

    /* state for language */
    const targetFieldsString = `"${t("common.name")}", "${t("common.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* fetch group list */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setGroupList(res.data.item.groupList);
            updateRequestBody("productCategoryGroupId", res.data.item.groupList[0].groupId);
        } catch (e) {
            console.log(e);
        }
    };

    /* handlers */
    const handleCategoryGroupIdChange = (value) => {
        updateRequestBody("productCategoryGroupId", value);
    };

    /* post category */
    const postCategory = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product-category`;
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await onRefresh();
            await initialize();
            await setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    /* initializer */
    const initialize = () => {
        setRequestBody(requestBodyInit);
        setIsValidMap(isValidMapInit);
        fetchGroupList();
    };

    /* effects */
    useEffect(() => {
        initialize();
    }, [isOpen]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                onRefresh();
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.product.category.add.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <LanguageSelectBox
                        title={t("language.selectBox.title.productCategory")}
                        languageMetadataList={languageMetadataList}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        setIsHighlightOn={setIsHighlightOn}
                        targetFieldString={targetFieldsString}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <LanguageInput
                        type={"text"}
                        title={t("common.name") + ` (${t("language.callName." + currentLang)})`}
                        dataKeyTranslationPath={"language.dataKey.product.category.name"}
                        valueMap={requestBody}
                        updateValueMap={updateRequestBody}
                        isValidMap={isValidMap}
                        updateIsValidMap={updateIsValidMap}
                        isForEdit={true}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <LanguageInput
                        type={"textarea"}
                        title={t("common.description") + ` (${t("language.callName." + currentLang)})`}
                        dataKeyTranslationPath={"language.dataKey.product.category.description"}
                        valueMap={requestBody}
                        updateValueMap={updateRequestBody}
                        isValidMap={isValidMap}
                        updateIsValidMap={updateIsValidMap}
                        isForEdit={true}
                        height={"140"}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndSelectBox
                        title={t("common.group")}
                        innerValue={requestBody["productCategoryGroupId"]}
                        valueList={groupList}
                        valueSelector={"groupId"}
                        viewSelector={"groupName"}
                        onChange={handleCategoryGroupIdChange}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {
                        (isValid)
                            ?
                            <button
                                id={"long-save-btn"}
                                onClick={() => postCategory()}
                            >
                                {t("button.save")}
                            </button>
                            :
                            <button
                                id={"long-save-btn-disabled"}
                            >
                                {t("button.save")}
                            </button>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default ProductCategoryAdd;