import React, {useEffect, useState} from 'react';
import Modal from "react-modal";
import {useTranslation} from "react-i18next";
import {Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import UserService from "../../../services/UserService";
import axios from "axios";
import TitleAndSelectBox from "../../common/dataView/TitleAndSelectBox";
import DataCompareService from "../../../services/DataCompareService";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '400px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    startDate: "",
    availableDays: 0
};

const isValidMapInit = {
    startDate: false,
    availableDays: false
};

const IssueApiKeyModal = ({ isOpen, setIsOpen, onRefresh }) => { /* onRefresh :: redirectUrl needed */

    const { t } = useTranslation();

    /* states */
    const [franchiseList, setFranchiseList] = useState([]);
    const [selectedFranchiseId, setSelectedFranchiseId] = useState("none");
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);

    /* fetch franchiseList */
    const fetchFranchiseList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let data = res.data.item.franchiseList;
            let newFranchiseList = [];
            /* serialize depth of response */
            data.map(GroupFranchiseObj => {
                let newGroupFranchiseList = Object.values(GroupFranchiseObj)[0];
                newGroupFranchiseList.map(franchiseObj => {
                    newFranchiseList.push(franchiseObj);
                });
            });
            /* set state */
            if (newFranchiseList.length > 0) {
                setSelectedFranchiseId(newFranchiseList[0]['franchiseId']);
            } else {
                newFranchiseList.push({
                    franchiseId: "none",
                    franchiseName: "None"
                });
            }
            setFranchiseList(newFranchiseList);
        } catch (e) {
            console.log(e);
        }
    };

    /* issue key */
    const issueKey = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-app-api-key`
                + `/franchise/${selectedFranchiseId}`;
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            let newKeyId = res.data.item['generatedKey']['franchiseAppApiKeyId'];
            /* on success */
            /* refresh */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await onRefresh(`/franchise-app-api/group/${getGroupIdByFranchiseId(selectedFranchiseId)}/franchise/${selectedFranchiseId}/app-api-code/${newKeyId}`);
            await setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* utils */
    const getGroupIdByFranchiseId = (franchiseId) => {
        for (let i=0 ; i<franchiseList.length ; i++) {
            if (franchiseList[i]['franchiseId'] === franchiseId) {
                return franchiseList[i]['franchiseGroupId'];
            }
        }
        return "notFound";
    };

    /* update helper */
    const updateHelper = (setState, key, value) => {
        setState(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    /* handlers */
    const handleSelectedFranchiseInput = (value) => {
        setSelectedFranchiseId(value);
    };

    const handleStartDateInput = (value) => {
        updateHelper(setRequestBody, "startDate", value);
        /* validate */
        if (typeof value === "undefined" || value == null || value === "") {
            updateHelper(setIsValidMap, "startDate", false);
        } else {
            updateHelper(setIsValidMap, "startDate", true);
        }
    };

    const handleAvailableDaysInput = (value) => {
        updateHelper(setRequestBody, "availableDays", parseInt(value));
        /* validate */
        if (typeof value === "undefined" || value == null || parseInt(value) === NaN || parseInt(value) <= 0 || value === "") {
            updateHelper(setIsValidMap, "availableDays", false);
        } else {
            updateHelper(setIsValidMap, "availableDays", true);
        }
    };

    /* effects */
    useEffect(() => {
        fetchFranchiseList();
        setRequestBody(requestBodyInit);
        setIsValidMap(isValidMapInit);
    }, [isOpen]);

    useEffect(() => {
        if (franchiseList.length > 0) {
            setSelectedFranchiseId(franchiseList[0]['franchiseId']);
        }
    }, [franchiseList]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchiseApiKey.issueKey.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* select franchise */}
                    <TitleAndSelectBox
                        title={t("menu.franchiseApiKey.issueKey.detail.franchise")}
                        innerValue={(selectedFranchiseId === "none") ? "" : selectedFranchiseId}
                        valueList={franchiseList}
                        valueSelector={"franchiseId"}
                        viewSelector={"franchiseName"}
                        onChange={handleSelectedFranchiseInput}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    {/* start date */}
                    <TitleAndInputBox
                        title={t("menu.franchiseApiKey.issueKey.detail.startDate")}
                        value={requestBody["startDate"]}
                        onChange={handleStartDateInput}
                        isForEdit={true}
                        type={"date"}
                        isValid={isValidMap["startDate"]}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    {/* available days */}
                    <TitleAndInputBox
                        title={t("menu.franchiseApiKey.issueKey.detail.availableDays")}
                        value={requestBody["availableDays"]}
                        onChange={handleAvailableDaysInput}
                        isForEdit={true}
                        type={"number"}
                        isValid={isValidMap["availableDays"]}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '25px'
                    }}
                >
                    {
                        (isValid && (selectedFranchiseId !== "none"))
                            ?
                            <button
                                id={"long-save-btn"}
                                onClick={issueKey}
                            >
                                {t("menu.franchiseApiKey.issueKey.detail.issue")}
                            </button>
                            :
                            <button
                                id={"long-save-btn-disabled"}
                            >
                                {t("menu.franchiseApiKey.issueKey.detail.issue")}
                            </button>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default IssueApiKeyModal;