import React, {useEffect, useState} from 'react';
import RenderOnRole from "../../../auth/RenderOnRole";
import {useTranslation} from "react-i18next";
import axios from "axios";
import UserService from "../../../services/UserService";
import {Col, Container, Row} from "react-bootstrap";
import WarrantyAdd from "./WarrantyAdd";
import styled from "styled-components";
import AuthService from "../../../services/AuthService";
import { CgFileDocument } from "react-icons/cg";
import uuid from "react-uuid";
import DataCompareService from "../../../services/DataCompareService";
import TitleAndSelectBox from "../../common/dataView/TitleAndSelectBox";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../common/dataView/TitleAndTextArea";
import DataParseService from "../../../services/DataParseService";
import {useNavigate} from "react-router-dom";
import BackwardButton from "../../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../../common/layoutSub/PageTitleWithDepth";
import LanguageInput from "../../../language/component/LanguageInput";
import LanguageSelectBox from "../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";
import ImsSystemRole from "../../../auth/roles/ImsSystemRole";
import DeleteConfirmModal from "../../common/deleteComponents/DeleteConfirmModal";

const requestBodyInit = {
    warrantyName: "",
    warrantyName1: null,
    warrantyName2: null,
    warrantyName3: null,
    warrantyName4: null,
    warrantyName5: null,
    description:"",
    description1: null,
    description2: null,
    description3: null,
    description4: null,
    description5: null,
    warrantyGroupId: ""
};

const isValidMapInit = {
    warrantyName: true,
    warrantyName1: true,
    warrantyName2: true,
    warrantyName3: true,
    warrantyName4: true,
    warrantyName5: true,
    description: true,
    description1: true,
    description2: true,
    description3: true,
    description4: true,
    description5: true,
    warrantyGroupId: true
};

const WarrantyAddButton = styled.button`
  padding: 5px 15px;
  border: 1px solid #fc7242;
  color: #fc7242;
  border-radius: 5px;
  background-color: white;
  transition: all ease-in-out 0.2s;
  font-size: 14px;
  
  &:hover {
    background-color: #fc7242;
    color: white;
  }
  
  &:focus {
    outline: none;
  }
`;

const WarrantyAddButtonDisabled = styled.button`
  padding: 5px 15px;
  border: 1px solid #ebebeb;
  color: #ebebeb;
  border-radius: 5px;
  background-color: white;
  font-size: 14px;
  
  &:focus {
    outline: none;
  }
`;

const MessageView = styled.span`
  font-size: 14px;
  color: #757575;
`;

const BoothWarrantyListElement = styled.div`
  width : 100%;
  height: 50px;
  border: 1px solid #757575;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #757575;
  font-size: 14px;
`;

const SelectedBoothWarrantyListElement = styled.div`
  width : 100%;
  height: 50px;
  border: 1px solid #fc7242;
  background-color: #fc7242;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 10px;
  color: white;
  font-size: 14px;
`;

const DeleteButton = styled.button`
  background: none;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 10px;
  color: red;
  border: 1px solid red;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: red;
    color: white;
  }
  
  &:focus {
    outline: none;
  }
`;

const DeleteButtonDisabled = styled.button`
  background: none;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 10px;
  color: #c2c2c2;
  border: 1px solid #c2c2c2;

  &:focus {
    outline: none;
  }
`;

const SaveButton = styled.button`
  background: none;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  color: #fc7242;
  border: 1px solid #fc7242;
  transition: all ease-in-out 0.2s;
  padding: 5px 15px;

  &:hover {
    background-color: #fc7242;
    color: white;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  background: none;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  color: #c2c2c2;
  border: 1px solid #c2c2c2;
  padding: 5px 15px;
  
  &:focus {
    outline: none;
  }
`;

const BoothWarrantyManagement = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    /* modals */
    const [isWarrantyAddModalOpen, setIsWarrantyAddModalOpen] = useState(false);

    /* warranty list */
    const [boothWarrantyList, setBoothWarrantyList] = useState([]);
    const [selectedWarrantyIndex, setSelectedWarrantyIndex] = useState(-1);
    /* group list */
    const [groupList, setGroupList] = useState([]);
    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    /* isValid */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    /* isEdited */
    const [isEdited, setIsEdited] = useState(false);

    /* state for language */
    const targetFieldsString = `"${t("menu.booth.warranty.detail.name")}", "${t("menu.booth.warranty.detail.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* delete */
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    /* fetch warranty list */
    const fetchWarrantyList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/warranty`;
            const axiosCall = () => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                setBoothWarrantyList([]);
            } else {
                setBoothWarrantyList(res.data.item.warrantyList);
            }
        } catch (err) {
            console.log(err);
        }
    };

    /* fetch group list */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setGroupList(res.data.item.groupList);
            }
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.serverFailed")} />);
        }
    };

    /* delete warranty */
    const deleteWarranty = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/warranty/${boothWarrantyList[selectedWarrantyIndex]['warrantyId']}`;
            await UserService.updateToken(() => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            setSelectedWarrantyIndex(-1);
            fetchWarrantyList();
            toast.success(<ToastAlertView message={t("message.deleted")} />);
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    }

    /* put warranty */
    const putWarranty = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/warranty/${boothWarrantyList[selectedWarrantyIndex]['warrantyId']}`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            fetchWarrantyList();
            setIsValidMap(isValidMapInit);
            setIsEdited(false);
            toast.success(<ToastAlertView message={t("message.saved")} />);
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    }

    /* handle warranty select */
    const handleSelectWarranty = (index) => {
        if (selectedWarrantyIndex === index) {
            setSelectedWarrantyIndex(-1);
        } else {
            setSelectedWarrantyIndex(index);
        }
    }

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const getGroupNameByGroupId = (groupId) => {
        if (groupList.length === 0) {
            return "unknown";
        } else {
            for (let i=0 ; i<groupList.length ; i++) {
                if (groupList[i].groupId === groupId) {
                    return groupList[i].groupName;
                }
            }
            return "unknown";
        }
    }

    /* change handlers */
    const handleGroupIdChange = (value) => {
        updateRequestBody("warrantyGroupId", value);
    }

    /* effects */
    useEffect(() => {
        fetchGroupList();
        fetchWarrantyList();
    }, []);

    useEffect(() => {
        if ((boothWarrantyList.length > 0) && (selectedWarrantyIndex > -1)) {
            let newRequestBody = DataCompareService.deepCopyObj(boothWarrantyList[selectedWarrantyIndex]);
            delete newRequestBody["boothCount"];
            delete newRequestBody["generateDate"];
            delete newRequestBody["lastEditDate"];
            delete newRequestBody["warrantyId"];
            setRequestBody(newRequestBody);
            setFixedRequestBody(DataCompareService.deepCopyObj(newRequestBody));
            setIsValidMap(isValidMapInit);
            setIsEdited(false);
        }
    }, [selectedWarrantyIndex]);

    useEffect(() => {
        setIsEdited(!DataCompareService.isSameObj(requestBody, fixedRequestBody));
    }, [requestBody]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    /* renderers */
    const renderWarrantyList = () => {
        if (groupList.length === 0) {
            return (<></>);
        } else {
            if (boothWarrantyList.length === 0) {
                return (
                    <MessageView>
                        {t("menu.booth.warranty.detail.noWarranty")}
                    </MessageView>
                );
            } else {
                let warrantyElementList = [];
                boothWarrantyList.sort().map((boothWarranty, index) => {
                    if (selectedWarrantyIndex === index) {
                        warrantyElementList.push(
                            <SelectedBoothWarrantyListElement
                                key={uuid()}
                                onClick={() => handleSelectWarranty(index)}
                            >
                                <div
                                    style={{
                                        marginRight: '10px'
                                    }}
                                >
                                    <CgFileDocument
                                        size={"23"}
                                        color={"white"}
                                    />
                                </div>
                                <div
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >
                                    {boothWarranty[t("language.dataKey.warranty.name")]}
                                </div>
                            </SelectedBoothWarrantyListElement>
                        );
                    } else {
                        warrantyElementList.push(
                            <BoothWarrantyListElement
                                key={uuid()}
                                onClick={() => handleSelectWarranty(index)}
                            >
                                <div
                                    style={{
                                        marginRight: '10px'
                                    }}
                                >
                                    <CgFileDocument
                                        size={"23"}
                                        color={"#757575"}
                                    />
                                </div>
                                <div
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >
                                    {boothWarranty[t("language.dataKey.warranty.name")]}
                                </div>
                            </BoothWarrantyListElement>
                        );
                    }
                });
                return warrantyElementList;
            }
        }
    };

    const renderWarrantySpec = () => {
        if (selectedWarrantyIndex === -1) {
            return (
                <div
                    style={{
                        paddingTop: '30px'
                    }}
                >
                    <MessageView>
                        {t("menu.booth.warranty.detail.selectWarranty")}
                    </MessageView>
                </div>
            );
        } else {
            let warrantyInfo = boothWarrantyList[selectedWarrantyIndex];
            return (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        padding: '10px'
                    }}
                >
                    <Container fluid>
                        {/* delete button */}
                        <Row>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex'
                                }}
                            >
                                {/* booth count */}
                                <div
                                    style={{
                                        textAlign: 'left',
                                        paddingLeft: '10px'
                                    }}
                                >
                                    {
                                        (warrantyInfo.boothCount === 0) ?
                                            <span
                                                style={{
                                                    color: '#c2c2c2',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {
                                                    warrantyInfo.boothCount + t("menu.booth.warranty.detail.boothCountMessage")
                                                }
                                            </span>
                                            :
                                            <span
                                                style={{
                                                    color: '#757575'
                                                }}
                                            >
                                                {
                                                    warrantyInfo.boothCount + t("menu.booth.warranty.detail.boothCountMessage")
                                                }
                                            </span>
                                    }
                                </div>
                                {/* delete button */}
                                <div
                                    style={{
                                        marginLeft: 'auto'
                                    }}
                                >
                                    <RenderOnRole type={"DELETE"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        {
                                            (warrantyInfo.boothCount === 0) ?
                                                <>
                                                    <DeleteButton
                                                        onClick = {() => setIsDeleteConfirmOpen(true)}
                                                    >
                                                        {t("menu.booth.warranty.detail.deleteWarranty")}
                                                    </DeleteButton>
                                                    <DeleteConfirmModal
                                                        isOpen={isDeleteConfirmOpen}
                                                        setIsOpen={setIsDeleteConfirmOpen}
                                                        isDelayed={false}
                                                        confirmMessage={t("language.message.deletion.confirmation", { entityName : boothWarrantyList[selectedWarrantyIndex][t("language.dataKey.warranty.name")] })}
                                                        id={`warranty/${boothWarrantyList[selectedWarrantyIndex]['warrantyId']}`}
                                                        onDeleteSuccess={async () => {
                                                            await setSelectedWarrantyIndex(-1);
                                                            await fetchWarrantyList();
                                                        }}
                                                    />
                                                </>
                                                :
                                                <DeleteButtonDisabled>
                                                    {t("menu.booth.warranty.detail.deleteWarranty")}
                                                </DeleteButtonDisabled>
                                        }
                                    </RenderOnRole>
                                </div>
                            </div>
                        </Row>
                        {/* warranty information */}
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <LanguageSelectBox
                                title={t("language.selectBox.title.warranty")}
                                languageMetadataList={languageMetadataList}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}
                                setIsHighlightOn={setIsHighlightOn}
                                targetFieldString={targetFieldsString}
                            />
                        </Row>
                        {/* group */}
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <TitleAndInputBox
                                title={t("menu.booth.warranty.detail.group")}
                                value={getGroupNameByGroupId(requestBody["warrantyGroupId"])}
                                onChange={() => {}}
                                isForEdit={false}
                                type={"text"}
                                isValid={true}
                                isEnabled={true}
                            />
                        </Row>
                        {/* warranty names */}
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <LanguageInput
                                type={"text"}
                                title={t("menu.booth.warranty.detail.name")}
                                dataKeyTranslationPath={"language.dataKey.warranty.name"}
                                valueMap={requestBody}
                                updateValueMap={updateRequestBody}
                                isValidMap={isValidMap}
                                updateIsValidMap={updateIsValidMap}
                                isForEdit={AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "PUT")}
                                currentLang={currentLang}
                                isHighlightOn={isHighlightOn}
                            />
                        </Row>
                        {/* warranty content */}
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <LanguageInput
                                type={"textarea"}
                                title={t("menu.booth.warranty.detail.description")}
                                dataKeyTranslationPath={"language.dataKey.warranty.description"}
                                valueMap={requestBody}
                                updateValueMap={updateRequestBody}
                                isValidMap={isValidMap}
                                updateIsValidMap={updateIsValidMap}
                                isForEdit={AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "PUT")}
                                height={"200"}
                                currentLang={currentLang}
                                isHighlightOn={isHighlightOn}
                            />
                        </Row>
                        {/* save button */}
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    padding: '0px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div>
                                    {
                                        isEdited && isValid && AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "PUT") ?
                                            <SaveButton
                                                onClick={() => putWarranty()}
                                            >
                                                {t("button.save")}
                                            </SaveButton>
                                            :
                                            <SaveButtonDisabled>
                                                {t("button.save")}
                                            </SaveButtonDisabled>
                                    }
                                </div>
                                <div
                                    style={{
                                        textAlign: 'right'
                                    }}
                                >
                                    <span
                                        style={{
                                            color: '#c2c2c2',
                                            fontSize: '13px'
                                        }}
                                    >
                                        {
                                            t("menu.booth.warranty.detail.lastEditDate") + " " + DataParseService.dateTimeToDate(warrantyInfo["lastEditDate"])
                                        }
                                    </span>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            )
        }
    }

    /* component return */
    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/booth"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.booth.title"), t("menu.booth.warranty.title")]}
                    depth={2}
                />
            </div>
            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                    <Container fluid>
                        {/* add warranty : modal */}
                        <Row>
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginLeft: '15px'
                                }}
                            >
                                {
                                    AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "PUT") ?
                                        <WarrantyAddButton
                                            onClick={() => setIsWarrantyAddModalOpen(true)}
                                        >
                                            {t("menu.booth.warranty.add")}
                                        </WarrantyAddButton>
                                        :
                                        <WarrantyAddButtonDisabled>
                                            {t("menu.booth.warranty.add")}
                                        </WarrantyAddButtonDisabled>
                                }
                            </div>
                            {/* modal component */}
                            <WarrantyAdd
                                isOpen={isWarrantyAddModalOpen}
                                setIsOpen={setIsWarrantyAddModalOpen}
                                onRefresh={fetchWarrantyList}
                            />
                        </Row>
                        {/* warranty list view */}
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            {/* warranty list */}
                            <Col>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '600px',
                                        border: '1px solid #ebebeb',
                                        borderRadius: '5px',
                                        padding: '10px',
                                        overflow: 'auto',
                                        color: '#757575'
                                    }}
                                >
                                    {renderWarrantyList()}
                                </div>
                            </Col>
                            {/* warranty spec */}
                            <Col>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '600px',
                                        border: '1px solid #ebebeb',
                                        borderRadius: '5px'
                                    }}
                                >
                                    {renderWarrantySpec()}
                                </div>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: '15px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    paddingRight: '15px',
                                    textAlign: 'right'
                                }}
                            >
                                <button
                                    id={"save-btn"}
                                    onClick={() => navigate('/booth')}
                                >
                                    {t("button.complete")}
                                </button>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default BoothWarrantyManagement;