import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import CommonUtil from "../../utils/CommonUtil";
import BoothService from "../../axiosServices/BoothService";
import {Spinner} from "react-bootstrap";
import _ from "lodash";
import ServerFailed from "../info/ServerFailed";
import DataView from "../../dataView/DataView";
import styled from "styled-components";
import uuid from "react-uuid";
import BoothControlAuthService from "../../auth/BoothControlAuthService";
import NotFound from "../../../../../auth/NotFound";

const DataContainerRounded = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const DataContainerInnerTitleContainer = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  border-bottom: 1px solid #e4e7ea;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
`;

const InnerTitle = styled.div`
  color: #848487;
  text-align: left;
`;

const SensorCapacityView = () => {

    const { t, i18n } = useTranslation();

    /* params */
    const { boothId, deviceId, sensorId } = useParams();

    /* booth schema */
    const [boothSchema, setBoothSchema] = useState({});

    /* capability schemas */
    const [capabilitySchemas, setCapabilitySchemas] = useState({});

    /* booth data */
    const [boothData, setBoothData] = useState({});

    /* loading */
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    /* fetch booth data */
    const fetchBoothData = async () => {
        const onSuccessCallBack = (data) => {
            setBoothData(data);
            setIsLoading(false);
        };
        const onFailureCallBack = () => {
            setBoothData({});
            setIsLoading(false);
        };
        BoothService.fetchBoothData(boothId, onSuccessCallBack, onFailureCallBack);
    };

    /* effects */
    /* fetch booth data */
    useEffect(() => {
        setIsLoading(true);
        setBoothSchema(JSON.parse(window.localStorage.getItem("ims_json_schema")));
        fetchBoothData();
    }, [boothId, deviceId, sensorId]);

    useEffect(() => {
        if (_.isEqual(boothSchema, {})) {
            return;
        } else {
            /* sensor schema */
            let deviceSchema = CommonUtil.getObjectInListById(deviceId, boothSchema['spec']['devices']);
            let sensorListSchema = deviceSchema['spec']['sensors'];
            let sensorSchema = CommonUtil.getObjectInListById(sensorId, sensorListSchema);
            if (_.isEqual(sensorSchema, {})) {
                navigate(`/booth/detail/${boothId}/device/${deviceId}/sensors/not-found`);
            } else {
                setCapabilitySchemas(sensorSchema['spec']['capabilities']);
            }
        }
    }, [boothSchema, boothId, deviceId, sensorId]);

    const renderCapabilities = () => {
        let schemaVersion = boothSchema['metadata']['id'];

        /* sensor schema */
        let deviceSchema = CommonUtil.getObjectInListById(deviceId, boothSchema['spec']['devices']);
        let sensorListSchema = deviceSchema['spec']['sensors'];
        let sensorSchema = CommonUtil.getObjectInListById(sensorId, sensorListSchema);

        /* capacity schema */
        let capacitySchemas = sensorSchema['spec']['capabilities'];

        let capabilityElements = [];
        let capabilityKeyList = Object.keys(capacitySchemas);
        let capabilityData = boothData[schemaVersion][deviceId]['sensors'][sensorId]['capabilities']
        capabilityKeyList.map(capabilityKey => {
            if (BoothControlAuthService.hasGetAuth(capacitySchemas[capabilityKey]['superPermission']) ||
                BoothControlAuthService.hasPostAuth(capacitySchemas[capabilityKey]['superPermission'])) {
                /** only get or post exists */
                capabilityElements.push(
                    <DataContainerRounded
                        key={uuid()}
                    >
                        <DataContainerInnerTitleContainer>
                            <InnerTitle>
                                {capacitySchemas[capabilityKey][t("language.dataKey.boothControl.json.label")]}
                            </InnerTitle>
                        </DataContainerInnerTitleContainer>
                        {/* data */}
                        <div
                            style={{
                                padding: '5px 15px 15px 15px'
                            }}
                        >
                            <DataView
                                dataSchema={capacitySchemas[capabilityKey]}
                                mainKey={capabilityKey}
                                sort={"CAPABILITY"}
                                data={capabilityData[capabilityKey]}
                                requestPath={`/booth/${boothId}/device/${deviceId}/sensor/${sensorId}`}
                                onRefresh={fetchBoothData}
                            />
                        </div>
                    </DataContainerRounded>
                );
            }
        });
        return capabilityElements;
    };

    if (isLoading) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '50%',
                    paddingTop: '10%'
                }}
            >
                <Spinner />
            </div>
        );
    } else if (boothSchema == null
                || _.isEqual(boothSchema, {})
                || _.isEqual(boothData, {})) { // server failed
        return (
            <div
                style={{
                    width: '100%',
                    paddingTop: '50px'
                }}
            >
                <ServerFailed />
            </div>
        );
    } else if (Object.keys(capabilitySchemas).length === 0) {
        return (
            <div
                style={{
                    padding: '40px',
                    backgroundColor: 'white',
                    borderRadius: '5px'
                }}
            >
                <span
                    style={{
                        color: '#757575'
                    }}
                >
                    {t("menu.boothControl.schema.sensors.capabilities.noCapabilityMessage")}
                </span>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    borderRadius: '5px'
                }}
            >
                {
                    renderCapabilities()
                }
            </div>
        );
    }
};

export default SensorCapacityView;