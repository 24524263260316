import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import BoothService from "../axiosServices/BoothService";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../../../common/dataView/TitleAndInputBox";
import DataParseService from "../../../../services/DataParseService";
import _ from "lodash";
import { GoGear } from "react-icons/go";
import { FaRegPlusSquare, FaRegTimesCircle } from "react-icons/fa";
import BoothBasicInfoEditModal from "./modals/BoothBasicInfoEditModal";
import BoothSalesRegisterModal from "./modals/BoothSalesRegisterModal";
import BoothSalesEditModal from "./modals/BoothSalesEditModal";
import BoothInstallRegisterModal from "./modals/BoothInstallRegisterModal";
import BoothInstallEditModal from "./modals/BoothInstallEditModal";
import WarrantyViewModal from "./modals/WarrantyViewModal";
import ConfirmModal from "../../../common/confirm/ConfirmModal";
import {toast} from "react-toastify";
import ToastAlertView from "../../../common/alert/ToastAlertView";
import RenderOnRole from "../../../../auth/RenderOnRole";
import ImsSystemRole from "../../../../auth/roles/ImsSystemRole";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 17px;
`;

const IconButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  position: relative;
  margin-left: 10px;
  
  &:focus {
    outline: none;
  }
`;

const DataContainerRounded = styled.div`
  margin-top: 20px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
`;

const DataContainerInnerTitleContainer = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  border-bottom: 1px solid #e4e7ea;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
`;

const InnerTitle = styled.div`
  color: #848487;
  text-align: left;
`;

const DataContainer = styled.div`
  width: 100%;
  padding: 15px 15px 20px 15px;
  background-color: white;
  border-radius: 5px;
`;

const RegisterButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  position: relative;
  margin-left: 10px;
  color: #fc7242;

  &:focus {
    outline: none;
  }
`;

const CleanButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  position: relative;
  margin-left: 10px;
  color: red;

  &:focus {
    outline: none;
  }
`;

const RegisterButtonDisabled = styled.button`
  border: none;
  background: none;
  padding: 0;
  position: relative;
  margin-left: 10px;
  color: #ebebeb;

  &:focus {
    outline: none;
  }
`;

const WarrantyButton = styled.button`
  border: none;
  background-color: #fc7242;
  color: white;
  font-size: 15px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 20px;
  
  &:focus {
    outline: none;
  }
`;

const WarrantyButtonDisabled = styled.button`
  border: none;
  background-color: #ebebeb;
  color: white;
  font-size: 15px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 20px;
  
  &:focus {
    outline: none;
  }
`;


const BoothDetailedInfo = () => {

    const { t } = useTranslation();
    const { boothId } = useParams();

    /* loading */
    const [isLoading, setIsLoading] = useState(false);

    /* booth information */
    const [boothInfo, setBoothInfo] = useState({});

    /* modals */
    const [boothBasicInfoModalOpen, setBoothBasicInfoModalOpen] = useState(false);
    const [boothSalesRegisterModalOpen, setBoothSalesRegisterModalOpen] = useState(false);
    const [boothSalesEditModalOpen, setBoothSalesEditModalOpen] = useState(false);
    const [boothInstallRegisterModalOpen, setBoothInstallRegisterModalOpen] = useState(false);
    const [boothInstallEditModalOpen, setBoothInstallEditModalOpen] = useState(false);
    const [warrantyViewModalOpen, setWarrantyViewModalOpen] = useState(false);

    /* confirms */
    const [isSalesClearConfirmOpen, setIsSalesClearConfirmOpen] = useState(false);
    const [isInstallClearConfirmOpen, setIsInstallClearConfirmOpen] = useState(false);

    /* fetch booth basic info */
    const fetchBoothBasicInfo = () => {
        const onSuccessCallBack = (data) => {
            setBoothInfo(data);
        };
        const onFailureCallBack = (e) => {
            setBoothInfo({});
        };
        setIsLoading(true);
        BoothService.fetchBoothBasicInfo(boothId, onSuccessCallBack, onFailureCallBack);
    };

    const cleanSalesInfo = async () => {
        const onSuccess = () => {
            fetchBoothBasicInfo();
            toast.success(<ToastAlertView message={t("message.saved")} />);
        };
        const onFailure = (e) => {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        };
        BoothService.clearBoothSalesInfo(boothId, onSuccess, onFailure);
    };

    const cleanInstallInfo = async () => {
        const onSuccess = () => {
            fetchBoothBasicInfo();
            toast.success(<ToastAlertView message={t("message.saved")} />);
        };
        const onFailure = (e) => {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        };
        BoothService.clearBoothInstallInfo(boothId, onSuccess, onFailure);
    };

    /* status check */
    const isBoothRelease = () => {
        if (_.isEqual(boothInfo, {})) {
            return false;
        } else {
            return boothInfo['boothStatusCode'] === 0;
        }
    };

    const isBoothSold = () => {
        if (_.isEqual(boothInfo, {})) {
            return false;
        } else {
            return boothInfo['boothStatusCode'] === 1;
        }
    };

    const isBoothInstalled = () => {
        if (_.isEqual(boothInfo, {})) {
            return false;
        } else {
            return boothInfo['boothStatusCode'] === 2;
        }
    };

    useEffect(() => {
        fetchBoothBasicInfo();
    }, []);

    return (
        <MainContainer>
            <Container fluid>
                <Row>
                    <Col>
                        <CardContainer>
                            <TitleContainer>
                                <div>
                                    <Title>
                                        {t("menu.booth.detail.title")}
                                    </Title>
                                </div>
                                <div
                                    style={{
                                        marginLeft: 'auto'
                                    }}
                                >
                                    {
                                        boothInfo['boothWarrantyInfo'] == null ?
                                            <WarrantyButtonDisabled>
                                                {t("menu.booth.detail.warrantyView.title")}
                                            </WarrantyButtonDisabled>
                                            :
                                            <WarrantyButton
                                                onClick={() =>setWarrantyViewModalOpen(true)}
                                            >
                                                {t("menu.booth.detail.warrantyView.title")}
                                            </WarrantyButton>
                                    }
                                    {/* warranty modal */}
                                    {
                                        boothInfo['boothWarrantyInfo'] != null &&
                                        <WarrantyViewModal
                                            isOpen={warrantyViewModalOpen}
                                            setIsOpen={setWarrantyViewModalOpen}
                                            warrantyInfo={boothInfo['boothWarrantyInfo']}
                                        />
                                    }
                                </div>
                            </TitleContainer>
                            {/* basic information */}
                            <DataContainerRounded>
                                <DataContainerInnerTitleContainer>
                                    <InnerTitle>
                                        {t("menu.booth.detail.basic.title")}
                                    </InnerTitle>
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        <div>
                                            <IconButton
                                                onClick={() => setBoothBasicInfoModalOpen(true)}
                                            >
                                                <GoGear
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '-2px'
                                                    }}
                                                />
                                            </IconButton>
                                        </div>
                                    </RenderOnRole>
                                    <BoothBasicInfoEditModal
                                        isOpen={boothBasicInfoModalOpen}
                                        setIsOpen={setBoothBasicInfoModalOpen}
                                        onRefresh={fetchBoothBasicInfo}
                                        groupId={boothInfo['boothGroupId']}
                                        boothId={boothId}
                                        prevInfo={boothInfo}
                                    />
                                </DataContainerInnerTitleContainer>
                                <DataContainer>
                                    <Container fluid>
                                        <Row>
                                            <Col
                                                style={{
                                                    padding: '0px'
                                                }}
                                            >
                                                {/* serial number */}
                                                <TitleAndInputBox
                                                    title={t("menu.booth.detail.basic.serialNumber")}
                                                    value={boothInfo['serialNumber']}
                                                    isForEdit={false}
                                                    onChange={()=>{}}
                                                    type={"text"}
                                                    isValid={true}
                                                    isEnabled={true}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                {/* manufactured date */}
                                                <TitleAndInputBox
                                                    title={t("menu.booth.detail.basic.manufactureDate")}
                                                    value={DataParseService.dateTimeToDate(boothInfo['manufactureDate'])}
                                                    isForEdit={false}
                                                    onChange={()=>{}}
                                                    type={"text"}
                                                    isValid={true}
                                                    isEnabled={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <Col
                                                style={{
                                                    padding: '0px'
                                                }}
                                            >
                                                {/* booth group name */}
                                                <TitleAndInputBox
                                                    title={t("menu.booth.detail.basic.boothGroupName")}
                                                    value={boothInfo['boothGroupName']}
                                                    isForEdit={false}
                                                    onChange={()=>{}}
                                                    type={"text"}
                                                    isValid={true}
                                                    isEnabled={true}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                {/* booth group id */}
                                                <TitleAndInputBox
                                                    title={t("menu.booth.detail.basic.boothGroupId")}
                                                    value={boothInfo['boothGroupId']}
                                                    isForEdit={false}
                                                    onChange={()=>{}}
                                                    type={"text"}
                                                    isValid={true}
                                                    isEnabled={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </DataContainer>
                            </DataContainerRounded>
                            {/* sales information */}
                            <DataContainerRounded>
                                <DataContainerInnerTitleContainer>
                                    <InnerTitle>
                                        {t("menu.booth.detail.sales.title")}
                                    </InnerTitle>
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        {
                                            (isBoothSold() || isBoothInstalled()) ?
                                                <div>
                                                    <IconButton
                                                        onClick={() => setBoothSalesEditModalOpen(true)}
                                                    >
                                                        <GoGear
                                                            style={{
                                                                position: 'absolute',
                                                                bottom: '-2px'
                                                            }}
                                                        />
                                                    </IconButton>
                                                </div>
                                                :
                                                <></>
                                        }
                                    </RenderOnRole>
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        <>
                                            <div
                                                style={{
                                                    marginLeft: 'auto'
                                                }}
                                            >
                                                {
                                                    isBoothRelease() ?
                                                        <RegisterButton
                                                            onClick={() => setBoothSalesRegisterModalOpen(true)}
                                                        >
                                                            <FaRegPlusSquare
                                                                size={"22"}
                                                            />
                                                        </RegisterButton>
                                                        :
                                                        <RegisterButtonDisabled>
                                                            <FaRegPlusSquare
                                                                size={"22"}
                                                            />
                                                        </RegisterButtonDisabled>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    isBoothSold() ?
                                                        <CleanButton
                                                            onClick={() => setIsSalesClearConfirmOpen(true)}
                                                        >
                                                            <FaRegTimesCircle
                                                                size={"22"}
                                                            />
                                                        </CleanButton>
                                                        :
                                                        <RegisterButtonDisabled>
                                                            <FaRegTimesCircle
                                                                size={"22"}
                                                            />
                                                        </RegisterButtonDisabled>
                                                }
                                            </div>
                                        </>
                                    </RenderOnRole>
                                    {/* register sales information modal */}
                                    <BoothSalesRegisterModal
                                        isOpen={boothSalesRegisterModalOpen}
                                        setIsOpen={setBoothSalesRegisterModalOpen}
                                        onRefresh={fetchBoothBasicInfo}
                                        groupId={boothInfo['boothGroupId']}
                                        boothId={boothId}
                                    />
                                    {/* edit sales information modal */}
                                    <BoothSalesEditModal
                                        isOpen={boothSalesEditModalOpen}
                                        setIsOpen={setBoothSalesEditModalOpen}
                                        onRefresh={fetchBoothBasicInfo}
                                        groupId={boothInfo['boothGroupId']}
                                        boothId={boothId}
                                        prevInfo={boothInfo}
                                    />
                                </DataContainerInnerTitleContainer>
                                <DataContainer>
                                    <Container fluid>
                                        <Row>
                                            {/* purchase date */}
                                            <TitleAndInputBox
                                                title={t("menu.booth.detail.sales.purchaseDate")}
                                                value={
                                                    boothInfo['purchaseDate'] ?
                                                        DataParseService.dateTimeToDate(boothInfo['purchaseDate'])
                                                        :
                                                        ""
                                                }
                                                isForEdit={false}
                                                onChange={()=>{}}
                                                type={"text"}
                                                isValid={true}
                                                isEnabled={boothInfo['purchaseDate']}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <Col
                                                style={{
                                                    padding: '0px'
                                                }}
                                            >
                                                {/* booth user id */}
                                                <TitleAndInputBox
                                                    title={t("menu.booth.detail.sales.boothUserName")}
                                                    value={
                                                        boothInfo['boothUserId'] == null ?
                                                            ""
                                                            :
                                                            boothInfo['boothUserName']
                                                    }
                                                    isForEdit={false}
                                                    onChange={()=>{}}
                                                    type={"text"}
                                                    isValid={true}
                                                    isEnabled={boothInfo['boothUserId'] != null}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                {/* booth user id */}
                                                <TitleAndInputBox
                                                    title={t("menu.booth.detail.sales.boothUserId")}
                                                    value={
                                                        boothInfo['boothUserId'] == null ?
                                                            ""
                                                            :
                                                            boothInfo['boothUserId']
                                                    }
                                                    isForEdit={false}
                                                    onChange={()=>{}}
                                                    type={"text"}
                                                    isValid={true}
                                                    isEnabled={boothInfo['boothUserId'] != null}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </DataContainer>
                            </DataContainerRounded>
                            {/* install information */}
                            <DataContainerRounded>
                                <DataContainerInnerTitleContainer>
                                    <InnerTitle>
                                        {t("menu.booth.detail.install.title")}
                                    </InnerTitle>
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        {
                                            isBoothInstalled() ?
                                                <div>
                                                    <IconButton
                                                        onClick={() => setBoothInstallEditModalOpen(true)}
                                                    >
                                                        <GoGear
                                                            style={{
                                                                position: 'absolute',
                                                                bottom: '-2px'
                                                            }}
                                                        />
                                                    </IconButton>
                                                </div>
                                                :
                                                <></>
                                        }
                                    </RenderOnRole>
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        <>
                                            <div
                                                style={{
                                                    marginLeft: 'auto'
                                                }}
                                            >
                                                {
                                                    isBoothSold() ?
                                                        <RegisterButton
                                                            onClick={() => setBoothInstallRegisterModalOpen(true)}
                                                        >
                                                            <FaRegPlusSquare
                                                                size={"22"}
                                                            />
                                                        </RegisterButton>
                                                        :
                                                        <RegisterButtonDisabled>
                                                            <FaRegPlusSquare
                                                                size={"22"}
                                                            />
                                                        </RegisterButtonDisabled>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    isBoothInstalled() ?
                                                        <CleanButton
                                                            onClick={() => setIsInstallClearConfirmOpen(true)}
                                                        >
                                                            <FaRegTimesCircle
                                                                size={"22"}
                                                            />
                                                        </CleanButton>
                                                        :
                                                        <RegisterButtonDisabled>
                                                            <FaRegTimesCircle
                                                                size={"22"}
                                                            />
                                                        </RegisterButtonDisabled>
                                                }
                                            </div>
                                        </>
                                    </RenderOnRole>
                                    {/* booth install information register modal */}
                                    <BoothInstallRegisterModal
                                        isOpen={boothInstallRegisterModalOpen}
                                        setIsOpen={setBoothInstallRegisterModalOpen}
                                        onRefresh={fetchBoothBasicInfo}
                                        userId={boothInfo['boothUserId']}
                                        boothId={boothId}
                                    />
                                    {/* booth install information edit modal */}
                                    <BoothInstallEditModal
                                        isOpen={boothInstallEditModalOpen}
                                        setIsOpen={setBoothInstallEditModalOpen}
                                        onRefresh={fetchBoothBasicInfo}
                                        userId={boothInfo['boothUserId']}
                                        boothId={boothId}
                                        prevInfo={boothInfo}
                                    />
                                </DataContainerInnerTitleContainer>
                                <DataContainer>
                                    <Container fluid>
                                        <Row>
                                            {/* install date */}
                                            <TitleAndInputBox
                                                title={t("menu.booth.detail.install.installDate")}
                                                value={
                                                    boothInfo['installDate'] ?
                                                        DataParseService.dateTimeToDate(boothInfo['installDate'])
                                                        :
                                                        ""
                                                }
                                                isForEdit={false}
                                                onChange={()=>{}}
                                                type={"text"}
                                                isValid={true}
                                                isEnabled={boothInfo['installDate']}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <Col
                                                style={{
                                                    padding: '0px'
                                                }}
                                            >
                                                {/* booth branch name */}
                                                <TitleAndInputBox
                                                    title={t("menu.booth.detail.install.boothBranchName")}
                                                    value={
                                                        boothInfo['boothBranchId'] != null ?
                                                            boothInfo['boothBranchName']
                                                            :
                                                            ""
                                                    }
                                                    isForEdit={false}
                                                    onChange={()=>{}}
                                                    type={"text"}
                                                    isValid={true}
                                                    isEnabled={boothInfo['boothBranchId']}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                {/* booth branch id */}
                                                <TitleAndInputBox
                                                    title={t("menu.booth.detail.install.boothBranchId")}
                                                    value={
                                                        boothInfo['boothBranchId'] != null ?
                                                            boothInfo['boothBranchId']
                                                            :
                                                            ""
                                                    }
                                                    isForEdit={false}
                                                    onChange={()=>{}}
                                                    type={"text"}
                                                    isValid={true}
                                                    isEnabled={boothInfo['boothBranchId']}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </DataContainer>
                            </DataContainerRounded>
                        </CardContainer>
                    </Col>
                </Row>
                <ConfirmModal
                    isOpen={isSalesClearConfirmOpen}
                    setIsOpen={setIsSalesClearConfirmOpen}
                    title={t("menu.booth.detail.confirmMessage.cleanSales")}
                    confirmLabel={t("menu.booth.detail.confirmMessage.yes")}
                    rejectLabel={t("menu.booth.detail.confirmMessage.no")}
                    onConfirm={cleanSalesInfo}
                    onReject={()=>{}}
                />
                <ConfirmModal
                    isOpen={isInstallClearConfirmOpen}
                    setIsOpen={setIsInstallClearConfirmOpen}
                    title={t("menu.booth.detail.confirmMessage.cleanInstall")}
                    confirmLabel={t("menu.booth.detail.confirmMessage.yes")}
                    rejectLabel={t("menu.booth.detail.confirmMessage.no")}
                    onConfirm={cleanInstallInfo}
                    onReject={()=>{}}
                />
            </Container>
        </MainContainer>
    );
};

export default BoothDetailedInfo;