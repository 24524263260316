import React, {useEffect, useState} from 'react';
import './SideNavigationStyle.css';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { ReactComponent as DashboardIcon } from '../../icons/navIcon/dashboard_new.svg';
import { ReactComponent as StaffIcon } from '../../icons/navIcon/staff.svg';
import { ReactComponent as CustomerIcon } from '../../icons/navIcon/customer.svg';
import { ReactComponent as BoardIcon } from '../../icons/navIcon/board.svg';
import { ReactComponent as BoothIcon } from '../../icons/navIcon/booth.svg';
import { ReactComponent as BranchIcon } from '../../icons/navIcon/branch.svg';
import { ReactComponent as OrderIcon } from '../../icons/navIcon/order.svg';
import { ReactComponent as ProductIcon } from '../../icons/navIcon/product.svg';
import { ReactComponent as QnaIcon } from '../../icons/navIcon/qna.svg';
import { LiaBuilding, LiaUsersCogSolid, LiaClipboardListSolid } from "react-icons/lia";
import { MdPhonelinkSetup, MdOutlineOnlinePrediction, MdOutlineHomeWork, MdSupervisorAccount } from "react-icons/md";
import { GoGear } from "react-icons/go";
import { RiQuestionnaireLine } from "react-icons/ri";
import { BsCheck2All, BsCheck2 } from 'react-icons/bs';
import { PiCoffeeLight, PiCoffee, PiKey } from "react-icons/pi";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import {Figure} from "react-bootstrap";
import TitleAndSlideBox from "./TitleAndSlideBox";
import RenderOnRole from "../../auth/RenderOnRole";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import styled from "styled-components";

const iconStyle = {
    position : 'relative',
    top: '-2px',
    fill : '#757575',
    color: '#757575',
    marginRight: '5px',
    width: '30'
}

const selectedIconStyle = {
    position : 'relative',
    top: '-2px',
    fill : '#fc7242',
    color: '#fc7242',
    marginRight: '5px',
    width: '30'
}

const titleStyle = {
    color : '#757575',
    textDecoration : 'none',
    fontSize : '17px'
}

const selectedTitleStyle = {
    color : '#fc7242',
    textDecoration : 'none',
    fontSize : '17px'
}

const WrappedLink = styled.button`
  background: none;
  border: none;
  padding: 0px;
  
  &:focus {
    outline: none;
  }
`;

const SideNavigation = () => {

    /** env : USA */
    //const isForUSA = Boolean(window.sessionStorage.getItem("service_region_ims"));
    const isForUSA = (window.sessionStorage.getItem("service_region_ims").toLowerCase() === "true");

    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isActive = (path) => {
        if (path === '/') {
            return (location.pathname === path);
        } else {
            return location.pathname.startsWith(path);
        }
    }

    const dashboardRender = () => {
        return (
            <li
                style = {
                    (isActive('/dashboard') || isActive('/'))
                        ?
                        {
                            backgroundColor: '#FFF1EC'
                        }
                        :
                        {}
                }
            >
                <WrappedLink
                    onClick={() => {
                        if ((isActive('/dashboard') || isActive('/'))) {
                            return;
                        } else {
                            navigate('/dashboard')
                        }
                    }}
                >
                    <DashboardIcon
                        style = {
                            (isActive('/dashboard') || isActive('/'))
                                ?
                                selectedIconStyle
                                :
                                iconStyle
                        }
                    />
                    <span
                        style = {
                            (isActive('/dashboard') || isActive('/'))
                                ?
                                selectedTitleStyle
                                :
                                titleStyle
                        }
                    >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.dashboard.title")}
                        </span>
                    </span>
                </WrappedLink>
            </li>
        );
    }

    const groupRoleRender = () => {
        return (
            <RenderOnRole roleName={""} type={""} allowedRing={0} isForSuperMaster={false} nothingOnRejected={true}>
                <li
                    style = {
                        isActive('/group-role')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/group-role')) {
                                return;
                            } else {
                                navigate('/group-role')
                            }
                        }}
                    >
                        <BsCheck2All
                            size={25}
                            style = {
                                isActive('/group-role')
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style = {
                                isActive('/group-role')
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.role.group")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }

    const companyRoleRender = () => {
        return (
            <RenderOnRole roleName={""} type={""} allowedRing={1} isForSuperMaster={false} nothingOnRejected={true}>
                <li
                    style = {
                        isActive('/company-role')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/company-role')) {
                                return;
                            } else {
                                navigate('/company-role')
                            }
                        }}
                    >
                        <BsCheck2
                            size={25}
                            style = {
                                isActive('/company-role')
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style = {
                                isActive('/company-role')
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.role.company")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }

    const customerRender = () => {
        return (
            <RenderOnRole roleName={ImsSystemRole.CUSTOMER_MANAGEMENT} type={"GET"} allowedRing={2} nothingOnRejected={true} isForSuperMaster={false}>
                <li
                    style = {
                        isActive('/customer')
                            ?
                            {
                                backgroundColor: '#FFF1EC'
                            }
                            :
                            {}
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/customer')) {
                                return;
                            } else {
                                navigate('/customer')
                            }
                        }}
                    >
                        <CustomerIcon
                            style={
                                (isActive('/customer'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/customer'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.customer.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }

    const staffRender = () => {
        return (
            <RenderOnRole roleName={ImsSystemRole.STAFF_MANAGEMENT} type={"GET"} allowedRing={2} nothingOnRejected={true} isForSuperMaster={false}>
                <li
                    style = {
                        isActive('/staff')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/staff')) {
                                return;
                            } else {
                                navigate('/staff')
                            }
                        }}
                    >
                        <StaffIcon
                            style={
                                (isActive('/staff'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/staff'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.staff.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );

    }
    const boardRender = () => {
        return (
            <RenderOnRole roleName={ImsSystemRole.BOARD_MANAGEMENT} type={"GET"} allowedRing={2} nothingOnRejected={true} isForSuperMaster={false}>
                <li
                    style = {
                        isActive('/board')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/board')) {
                                return;
                            } else {
                                navigate('/board')
                            }
                        }}
                    >
                        <BoardIcon
                            style={
                                (isActive('/board'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/board'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                            <span
                                id={"side-nav-title"}
                            >
                                {t("menu.board.title")}
                            </span>
                        </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }

    const boothRender = () => {
        return (
            <RenderOnRole roleName={ImsSystemRole.BOOTH_MANAGEMENT} type={"GET"} allowedRing={2} nothingOnRejected={true} isForSuperMaster={false}>
                <li
                    style = {
                        isActive('/booth')
                            ?
                            {
                                backgroundColor: '#FFF1EC'
                            }
                            :
                            {}
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/booth')) {
                                return;
                            } else {
                                navigate('/booth')
                            }
                        }}
                    >
                        <BoothIcon
                            style={
                                isActive('/booth')
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                isActive('/booth')
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                            <span
                                id={"side-nav-title"}
                            >
                                {t("menu.booth.title")}
                            </span>
                        </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }


    const orderRender = () => {
        return (
            <RenderOnRole roleName={ImsSystemRole.ORDER_MANAGEMENT} type={"GET"} allowedRing={2} nothingOnRejected={true} isForSuperMaster={false}>
                <li
                    style = {
                        isActive('/order')
                            ?
                            {
                                backgroundColor: '#FFF1EC'
                            }
                            :
                            {}
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/order')) {
                                return;
                            } else {
                                navigate('/order')
                            }
                        }}
                    >
                        <OrderIcon
                            style={
                                (isActive('/order'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/order'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.order.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }

    const productRender = () => {
        return (
            <RenderOnRole roleName={ImsSystemRole.PRODUCT_MANAGEMENT} type={"GET"} allowedRing={2} nothingOnRejected={true} isForSuperMaster={false}>
                <li
                    style = {
                        isActive('/product')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/product')) {
                                return;
                            } else {
                                navigate('/product')
                            }
                        }}
                    >
                        <ProductIcon
                            style={
                                (isActive('/product'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/product'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.product.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }

    const branchRender = () => {
        return (
            <RenderOnRole roleName={ImsSystemRole.BRANCH_MANAGEMENT} type={"GET"} allowedRing={2} nothingOnRejected={true} isForSuperMaster={false}>
                <li
                    style = {
                        isActive('/branch')
                            ?
                            {
                                backgroundColor: '#FFF1EC'
                            }
                            :
                            {}
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/branch')) {
                                return;
                            } else {
                                navigate('/branch')
                            }
                        }}
                    >
                        <BranchIcon
                            style={
                                (isActive('/branch'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/branch'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.branch.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }

    const qnaRender = () => {
        return (
            <RenderOnRole roleName={ImsSystemRole.QNA_MANAGEMENT} type={"GET"} allowedRing={2} nothingOnRejected={true} isForSuperMaster={false}>
                <li
                    style = {
                        isActive('/qna')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/qna')) {
                                return;
                            } else {
                                navigate('/qna')
                            }
                        }}
                    >
                        <QnaIcon
                            style={
                                (isActive('/qna'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/qna'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.qna.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }

    const faqRender = () => {
        return (
            <RenderOnRole roleName={ImsSystemRole.QNA_MANAGEMENT} type={"GET"} allowedRing={2} nothingOnRejected={true} isForSuperMaster={false}>
                <li
                    style = {
                        isActive('/faq')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/faq')) {
                                return;
                            } else {
                                navigate('/faq')
                            }
                        }}
                    >
                        <RiQuestionnaireLine
                            size={20}
                            style={
                                (isActive('/faq'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/faq'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.faq.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }


    const groupCompanyRender = () => {
        return (
            <RenderOnRole roleName={""} type={""} allowedRing={0} isForSuperMaster={false} nothingOnRejected={true}>
                <li
                    style = {
                        isActive('/manage-gr-cm')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/manage-gr-cm')) {
                                return;
                            } else {
                                navigate('/manage-gr-cm')
                            }
                        }}
                    >
                        <LiaBuilding
                            size={"20"}
                            style={
                                (isActive('/manage-gr-cm'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/manage-gr-cm'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.groupCompany.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }

    const companyDepartmentRender = () => {
        return (
            <RenderOnRole roleName={""} type={""} allowedRing={1} isForSuperMaster={false} nothingOnRejected={true}>
                <li
                    style = {
                        isActive('/manage-cm-dp')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/manage-cm-dp')) {
                                return;
                            } else {
                                navigate('/manage-cm-dp')
                            }
                        }}
                    >
                        <LiaUsersCogSolid
                            size={"20"}
                            style={
                                (isActive('/manage-cm-dp'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/manage-cm-dp'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.companyDepartment.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    }

    const groupAppRoleRender = () => {
        return (
            <RenderOnRole roleName={""} type={""} allowedRing={0} isForSuperMaster={false} nothingOnRejected={true}>
                <li
                    style = {
                        isActive('/gr-app-role')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/gr-app-role')) {
                                return;
                            } else {
                                navigate('/gr-app-role')
                            }
                        }}
                    >
                        <MdPhonelinkSetup
                            size={"20"}
                            style={
                                (isActive('/gr-app-role'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/gr-app-role'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.groupAppRole.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    };

    const franchiseRender = () => {
        return (
            <RenderOnRole roleName={""} type={""} allowedRing={1} isForSuperMaster={false} nothingOnRejected={true}>
                <li
                    style = {
                        isActive('/man-franchise')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/man-franchise')) {
                                return;
                            } else {
                                navigate('/man-franchise')
                            }
                        }}
                    >
                        <PiCoffee
                            size={"20"}
                            style={
                                (isActive('/man-franchise'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/man-franchise'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.franchise.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    };

    const franchiseApiKeyRender = () => {
        return (
            <RenderOnRole roleName={""} type={""} allowedRing={1} isForSuperMaster={false} nothingOnRejected={true}>
                <li
                    style = {
                        isActive('/franchise-app-api')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/franchise-app-api')) {
                                return;
                            } else {
                                navigate('/franchise-app-api')
                            }
                        }}
                    >
                        <PiKey
                            size={"20"}
                            style={
                                (isActive('/franchise-app-api'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/franchise-app-api'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.franchiseApiKey.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    };

    const franchiseMenuRender = () => {
        return (
            <RenderOnRole roleName={""} type={""} allowedRing={1} isForSuperMaster={false} nothingOnRejected={true}>
                <li
                    style = {
                        isActive('/man-menu-franchise')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/man-menu-franchise')) {
                                return;
                            } else {
                                navigate('/man-menu-franchise')
                            }
                        }}
                    >
                        <LiaClipboardListSolid
                            size={"20"}
                            style={
                                (isActive('/man-menu-franchise'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/man-menu-franchise'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.franchiseMenu.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    };

    const franchiseConsoleCredentialRender = () => {
        return (
            <RenderOnRole roleName={""} type={""} allowedRing={1} isForSuperMaster={false} nothingOnRejected={true}>
                <li
                    style = {
                        isActive('/fr-console-credential')
                            ?
                            {
                                backgroundColor: '#FFF1EC',
                                paddingLeft: '15px'
                            }
                            :
                            {
                                paddingLeft: '15px'
                            }
                    }
                >
                    <WrappedLink
                        onClick={() => {
                            if (isActive('/fr-console-credential')) {
                                return;
                            } else {
                                navigate('/fr-console-credential')
                            }
                        }}
                    >
                        <MdSupervisorAccount
                            size={"20"}
                            style={
                                (isActive('/fr-console-credential'))
                                    ?
                                    selectedIconStyle
                                    :
                                    iconStyle
                            }
                        />
                        <span
                            style={
                                (isActive('/fr-console-credential'))
                                    ?
                                    selectedTitleStyle
                                    :
                                    titleStyle
                            }
                        >
                        <span
                            id={"side-nav-title"}
                        >
                            {t("menu.franchiseConsoleCred.title")}
                        </span>
                    </span>
                    </WrappedLink>
                </li>
            </RenderOnRole>
        );
    };

    const renderLogo = () => {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                    backgroundColor: 'white'
                }}
            >
                <Figure
                    style={{
                        margin: '0px'
                    }}
                >
                    <Figure.Image src={process.env.REACT_APP_CI_IMAGE_PATH} width={126} height={24}/>
                </Figure>
            </div>
        );
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                textAlign: 'left'
            }}
        >
            {/* logo */}
            {renderLogo()}

            {/* main menus */}
            <div
                style={{
                    width: '100%',
                    height: '790px',
                    overflow: 'auto',
                    backgroundColor: 'white'
                }}
            >
                <ul>
                    {/* Dashboard */}
                    {/* TODO */}
                    {dashboardRender()}

                    {/* Booth Management */}
                    {boothRender()}

                    {/* Branch Management */}
                    {branchRender()}

                    {/* Customer Management */}
                    {customerRender()}

                    {/* Order Management */}
                    {/** for the USA, not provided */}
                    {
                        (!isForUSA) &&
                        orderRender()
                    }

                    {/* franchise management */}
                    <TitleAndSlideBox
                        title={t("accordion.franchiseManagement")}
                        roleName={[]}
                        ring={1}
                        icon={
                            <MdOutlineHomeWork
                                size={"22"}
                            />
                        }
                        isActive={
                            isActive('/franchise-app-api')
                            || isActive('/fr-console-credential')
                            || isActive('/man-franchise')
                        }
                    >
                        <>
                            {/* Franchise Management */}
                            {franchiseRender()}

                            {/* Franchise Menu Management */}
                            {franchiseMenuRender()}

                            {/* Franchise Api Key Management */}
                            {franchiseApiKeyRender()}

                            {/* Franchise Console Account Management */}
                            {franchiseConsoleCredentialRender()}

                        </>
                    </TitleAndSlideBox>

                    <TitleAndSlideBox
                        title={t("accordion.franchiseAppManagement")}
                        roleName={[ImsSystemRole.PRODUCT_MANAGEMENT, ImsSystemRole.BOARD_MANAGEMENT, ImsSystemRole.QNA_MANAGEMENT]}
                        icon={
                            <MdOutlineOnlinePrediction
                                size={"22"}
                            />
                        }
                        isActive={
                            isActive('/gr-app-role')
                            || isActive('/product')
                            || isActive('/board')
                            || isActive('/qna')
                            || isActive('/faq')
                        }
                    >
                        <>
                            {/* Group App Role Management */}
                            {groupAppRoleRender()}

                            {/* Product Management */}
                            {/** for the USA, not provided */}
                            {
                                (!isForUSA) &&
                                productRender()
                            }

                            {/* Board Management */}
                            {boardRender()}

                            {/* QnA Management */}
                            {qnaRender()}

                            {/* FaQ Management */}
                            {faqRender()}
                        </>
                    </TitleAndSlideBox>
                    <TitleAndSlideBox
                        title={t("accordion.systemManagement")}
                        roleName={[ImsSystemRole.STAFF_MANAGEMENT]}
                        icon={
                            <GoGear
                                size={"20"}
                            />
                        }
                        isActive={
                            isActive('/manage-gr-cm')
                            || isActive('/group-role')
                            || isActive('/manage-cm-dp')
                            || isActive('/company-role')
                            || isActive('/staff')
                        }
                    >
                        {/* Company Management */}
                        {groupCompanyRender()}

                        {/* Group Role Management */}
                        {groupRoleRender()}

                        {/* Company Management */}
                        {companyDepartmentRender()}

                        {/* Company Role Management */}
                        {companyRoleRender()}

                        {/* Staff Management */}
                        {staffRender()}
                    </TitleAndSlideBox>
                </ul>
            </div>
        </div>
    );
};

export default SideNavigation;