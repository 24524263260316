import React from 'react';
import MessageView from "../../common/component/MessageView";
import {useTranslation} from "react-i18next";

const NoMenuSelected = () => {

    const { t } = useTranslation();

    return (
        <div
            style={{
                width: '100%',
                height: '100%'
            }}
        >
            <MessageView
                message={t("menu.boothMenuDiff.messages.noMenuSelected")}
            />
        </div>
    );
};

export default NoMenuSelected;