import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import UserService from "../../services/UserService";
import AuthService from "../../services/AuthService";
import NotAllowed from "../../auth/NotAllowed";
import {Col, Container, Row} from "react-bootstrap";
import './GroupAppRoleStyle.css';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import RenderOnRole from "../../auth/RenderOnRole";

/* -------------------------------------------------------- */
/* Keycloak Roles : IMS_SYSTEM_MANAGEMENT, GROUP_MANAGEMENT */
/* IMS Systems Roles : none                                 */
/* -------------------------------------------------------- */
const GroupAppRole = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [groupList, setGroupList] = useState([]);

    const hasIdInPath = (id) => {
        return location.pathname.includes(id);
    };

    const fetchGroupList = async () => {
        let fetchUrl =
            `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers : {
                    Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        let newGroupList = res.data.item.groupList;
        /* filter master group */
        newGroupList.map((group, index) => {
            if (group['masterGroup']) {
                newGroupList.splice(index, 1);
            }
        });
        setGroupList(newGroupList);
        if (newGroupList.length > 0) {
            navigate(`group/${newGroupList[0].groupId}`);
        }
    };

    /* renderers */
    const renderGroupList = (group) => {
        if (group["masterGroup"]) {
            return (<></>);
        } else {
            return (
                <button
                    className={"groupListButton" + (hasIdInPath(group.groupId) ? "-selected" : "")}
                    key={group.groupId}
                    onClick={() => navigate(`group/${group.groupId}`)}
                >
                    {group.groupName}
                </button>
            );
        }
    }

    useEffect(() => {
        fetchGroupList();
    }, []);


    return (
        <RenderOnRole roleName={""} type={""} allowedRing={0} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.groupAppRole.title")]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    <Row>
                        {/* group list view */}
                        <Col
                            md={"2"}
                            style={{
                                padding: '0px'
                            }}
                        >
                            <div className={"contentInner"}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '750px',
                                        overflow: 'auto'
                                    }}
                                >
                                    {
                                        groupList.map((group, idx) => {
                                            return renderGroupList(group);
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col
                            md={"10"}
                            style={{
                                padding: '0px',
                                paddingLeft: '10px'
                            }}
                        >
                            <div className={"contentInner"}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '750px',
                                        padding: '20px',
                                        borderRadius: '10px'
                                    }}
                                >
                                    <Outlet />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default GroupAppRole;