import React, {useState} from 'react';
import styled from "styled-components";
import AlertMagic from "../../alert/AlertMagic";
import './TopMenuDropDown.css';
import ProfileTop from "./components/ProfileTop";
import LangSelect from "./components/LangSelect";

const OutContainer = styled.div`
  position: absolute;
  right: 0;
  width: auto;
  height: 50px;
  display: flex;
  flex-direction: row;
`;

const InnerContainer = styled.div`
  padding: 0px;
  width: ${(props) => props.width ? props.width : '50px'};
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;


const TopMenuDropDown = () => {

    return (
        <OutContainer>
            <InnerContainer>
                {/* alert */}
                <AlertMagic />
            </InnerContainer>
            <InnerContainer>
                {/* language */}
                <LangSelect />
            </InnerContainer>
            <InnerContainer
                style={{
                    marginRight: '10px'
                }}
            >
                {/* profile */}
                <ProfileTop />
            </InnerContainer>
        </OutContainer>
    );
};

export default TopMenuDropDown;