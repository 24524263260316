import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import UserService from "../../../../../services/UserService";
import axios from "axios";
import MenuNotExists from "../message/MenuNotExists";
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import DataUtils from "../../../common/utils/DataUtils";
import MenuItem from "../../../common/accordionItems/MenuItem";
import {useTranslation} from "react-i18next";
import OrangeButton from "../../../common/buttons/OrangeButton";
import FranchiseMenuAddModal from "../../modals/FranchiseMenuAddModal";
import { IoReturnDownForwardSharp } from "react-icons/io5";
import SimpleButton from "../../../common/buttons/SimpleButton";
import MenuCategoryEditModal from "../../modals/MenuCategoryEditModal";
import OptionCategoryEditModal from "../../modals/OptionCategoryEditModal";


const FranchiseInnerMenuManagement = () => {

    const { t, i18n } = useTranslation();
    const { franchiseId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    /* states */
    const [franchiseMenuList, setFranchiseMenuList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    /* modals */
    const [isFranchiseMenuAddModalOpen, setIsFranchiseMenuAddModalOpen] = useState(false);
    const [isMenuCategoryEditModalOpen, setIsMenuCategoryEditModalOpen] = useState(false);
    const [isOptionCategoryEditModalOpen, setIsOptionCategoryEditModalOpen] = useState(false);

    /* refresh from bottom */
    const refreshMenuList = async (redirectUrl) => {
        if ((typeof redirectUrl === "undefined") || (redirectUrl == null)) {
            await fetchFranchiseMenuList();
        } else {
            await fetchFranchiseMenuList(redirectUrl);
            navigate(redirectUrl);
        }
    };

    /* fetch franchise menu list */
    const fetchFranchiseMenuList = async (redirectUrl) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-menu`
                + `/franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newFranchiseMenuList = res.data.item.franchiseMenuList;
            /* sort with menu alias */
            newFranchiseMenuList.sort((ol, or) => {
                return DataUtils.objectSortHelper('franchiseMenuCategoryId', ol['menuInfo'], or['menuInfo']);
            });
            newFranchiseMenuList.sort((ol, or) => {
                return DataUtils.objectSortHelper('franchiseMenuId', ol['menuInfo'], or['menuInfo']);
            });
            await setFranchiseMenuList(newFranchiseMenuList);
            if ((redirectUrl == null) && (location.pathname.split('/').length < 5) && (newFranchiseMenuList.length > 0)) {
                /* default redirection */
                navigate(`/man-menu-franchise/franchise/${franchiseId}/menu/${newFranchiseMenuList[0]['menuInfo']['franchiseMenuId']}/info`);
            }
            setIsLoading(false);
        } catch (e) {
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
            console.log(e);
            setIsLoading(false);
        }
    };

    const renderAccordion = () => {
        if (franchiseMenuList.length === 0) {
            return (<MenuNotExists />);
        } else {
            return (
                <Accordion>
                    {/* per menu */}
                    {
                        franchiseMenuList.map(menuInfoObj => {
                            return (
                                <AccordionItem
                                    key={menuInfoObj['menuInfo']['franchiseMenuId'] + franchiseMenuList.length}
                                    isActive={DataUtils.hasIdInPath(location.pathname, menuInfoObj['menuInfo']['franchiseMenuId'])}
                                >
                                    {
                                        ({open}) => (
                                            <>
                                                <AccordionHeader
                                                    className={"menuAccordionHeaderButton"}
                                                    onClick={() => {
                                                        navigate(`/man-menu-franchise/franchise/${franchiseId}/menu/${menuInfoObj['menuInfo']['franchiseMenuId']}/info`);
                                                    }}
                                                >
                                                    <MenuItem
                                                        menuName={
                                                            menuInfoObj['menuInfo'][t("language.dataKey.menu.detail.name")] ?
                                                                menuInfoObj['menuInfo'][t("language.dataKey.menu.detail.name")]
                                                                :
                                                                menuInfoObj['menuInfo'][t("language.dataKey.menu.detail.name", { lng: 'en-US' })]
                                                        }
                                                        isEnabled={true}
                                                        isOpen={open}
                                                        franchiseId={franchiseId}
                                                        menuId={menuInfoObj['menuInfo']['franchiseMenuId']}
                                                    />
                                                </AccordionHeader>
                                                <AccordionBody>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            padding: '0px',
                                                            paddingTop: '5px'
                                                        }}
                                                    >
                                                        {/* info */}
                                                        <div
                                                            key={menuInfoObj['menuInfo']['franchiseMenuId'] + "info"}
                                                            style={{
                                                                width: '100%',
                                                                marginBottom: '5px'
                                                            }}
                                                        >
                                                            <button
                                                                className={"groupListButton" + (DataUtils.hasIdInPath(location.pathname, "info") ? "-selected" : "")}
                                                                onClick={() => navigate(`/man-menu-franchise/franchise/${franchiseId}/menu/${menuInfoObj['menuInfo']['franchiseMenuId']}/info`)}
                                                                style={{
                                                                    paddingLeft: '10px'
                                                                }}
                                                            >
                                                                <IoReturnDownForwardSharp
                                                                    size={"20"}
                                                                />
                                                                <span
                                                                    style={{
                                                                        marginLeft: '15px'
                                                                    }}
                                                                >
                                                                    {t("menu.franchiseMenu.menuInfo.title")}
                                                                </span>
                                                            </button>
                                                        </div>
                                                        {/* recipe */}
                                                        {
                                                            menuInfoObj['menuInfo']['isBoothMenu'] &&
                                                            <div
                                                                key={menuInfoObj['menuInfo']['franchiseMenuId'] + "recipe"}
                                                                style={{
                                                                width: '100%',
                                                                marginBottom: '5px'
                                                            }}
                                                            >
                                                                <button
                                                                    className={"groupListButton" + (DataUtils.hasIdInPath(location.pathname, "recipe") ? "-selected" : "")}
                                                                    onClick={() => navigate(`/man-menu-franchise/franchise/${franchiseId}/menu/${menuInfoObj['menuInfo']['franchiseMenuId']}/recipe`)}
                                                                    style={{
                                                                        paddingLeft: '10px'
                                                                    }}
                                                                >
                                                                    <IoReturnDownForwardSharp
                                                                        size={"20"}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                        marginLeft: '15px'
                                                                    }}
                                                                    >
                                                                        {t("menu.franchiseMenu.recipeInfo.title")}
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                </AccordionBody>
                                            </>
                                        )
                                    }
                                </AccordionItem>
                            );
                        })
                    }
                </Accordion>
            );
        }
    };

    /* effects */
    useEffect(() => {
        fetchFranchiseMenuList();
    }, [franchiseId, location.pathname]);

    if (isLoading) {
        return (
            <Container fluid>
                <Row>
                    {/* menu accordion */}
                    <Col
                        md={"2"}
                        style={{
                            padding: '0px'
                        }}
                    >
                        <div
                            className={"contentInner"}
                            style={{
                                height: '797px',
                                paddingTop: '50px'
                            }}
                        >
                            <Spinner size={"sm"} />
                        </div>
                    </Col>
                    {/* outlet area */}
                    <Col
                        md={"10"}
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        {/* skip */}
                    </Col>
                </Row>
            </Container>
        );
    } else {
        return (
            <Container fluid>
                <Row>
                    {/* menu add button */}
                    <div
                        className={"contentInner"}
                        style={{
                            height: '50px',
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        {/* add button */}
                        <div
                            style={{
                                width: '150px',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                verticalAlign: 'center'
                            }}
                        >
                            <OrangeButton
                                onClickCallback={() => setIsFranchiseMenuAddModalOpen(true)}
                                isEnabled={true}
                                label={t("menu.franchiseMenu.button.addFranchiseMenu")}
                            />
                        </div>
                        {/* menu category edit button */}
                        <div
                            style={{
                                marginLeft: 'auto',
                                width: '200px',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                verticalAlign: 'center'
                            }}
                        >
                            <SimpleButton
                                onClickCallback={()=>setIsMenuCategoryEditModalOpen(true)}
                                isEnabled={true}
                                label={t("menu.franchiseMenu.button.menuCategoryEdit")}
                                borderColor={"#128cfb"}
                                buttonColor={"#128cfb"}
                                buttonHoverColor={"#e1f5fe"}
                            />
                        </div>
                        {/* menu option category edit button */}
                        <div
                            style={{
                                marginLeft: '10px',
                                width: '200px',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                verticalAlign: 'center'
                            }}
                        >
                            <SimpleButton
                                onClickCallback={()=>setIsOptionCategoryEditModalOpen(true)}
                                isEnabled={true}
                                label={t("menu.franchiseMenu.button.menuOptionCategoryEdit")}
                                borderColor={"#999999"}
                                buttonColor={"#999999"}
                                buttonHoverColor={"#ececec"}
                            />
                        </div>
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    {/* menu accordion */}
                    <Col
                        md={"2"}
                        style={{
                            padding: '0px'
                        }}
                    >
                        <div
                            className={"contentInner"}
                            style={{
                                height: '737px',
                                overflow: 'auto'
                            }}
                        >
                            {renderAccordion()}
                        </div>
                    </Col>
                    {/* outlet area */}
                    <Col
                        md={"10"}
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        <Outlet
                            context={{
                                refreshMenuList,
                                franchiseMenuList
                            }}
                        />
                    </Col>
                </Row>
                {/* modals */}
                <FranchiseMenuAddModal
                    isOpen={isFranchiseMenuAddModalOpen}
                    setIsOpen={setIsFranchiseMenuAddModalOpen}
                    onRefresh={refreshMenuList}
                />
                <MenuCategoryEditModal
                    isOpen={isMenuCategoryEditModalOpen}
                    setIsOpen={setIsMenuCategoryEditModalOpen}
                    franchiseId={franchiseId}
                    onRefresh={refreshMenuList}
                />
                <OptionCategoryEditModal
                    isOpen={isOptionCategoryEditModalOpen}
                    setIsOpen={setIsOptionCategoryEditModalOpen}
                    franchiseId={franchiseId}
                    onRefresh={refreshMenuList}
                />
            </Container>
        );
    }
};

export default FranchiseInnerMenuManagement;