import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import UserService from "../../../../../services/UserService";
import axios from "axios";
import {Col, Container, Row} from "react-bootstrap";
import LanguageSelectBox from "../../../../common/lang/LanguageSelectBox";
import TitleAndSelectBox from "../../../../common/dataView/TitleAndSelectBox";
import OptionInfoItem from "../../../franchiseMenu/components/innerRoute/info/inner/OptionInfoItem";
import OptionDiffItemView from "./inner/OptionDiffItemView";
import SimpleButton from "../../../common/buttons/SimpleButton";
import ButtonWithSpinner from "../../../common/buttons/ButtonWithSpinner";
import OrangeButton from "../../../common/buttons/OrangeButton";
import _ from "lodash";

const BranchOptionInfoDiffView = () => {

    const { t } = useTranslation();
    const { branchId, menuId } = useParams();

    /* states */
    const [optionInfoList, setOptionInfoList] = useState([]);
    const [optionCategoryList, setOptionCategoryList] = useState([]);
    const [filteredOptionList, setFilteredOptionList] = useState([]);
    const [selectedOptionCategoryId, setSelectedOptionCategoryId] = useState("");

    /* state for language */
    const targetFieldsString = `"${t("menu.franchiseMenu.menuInfo.optionInfo.detail.alias")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* loading */
    const [isLoading, setIsLoading] = useState(false);

    /* fetch data */
    const fetchMenuInfo = async () => {
        await setIsLoading(true);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/branch-menu/branch/${branchId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newOptionCategoryList = res.data.item['categoryInfo'];
            let newMenuList = res.data.item['branchMenuList'];
            /* extract target menu */
            let targetMenuInfo = {};
            newMenuList.map(menuInfoObj => {
                if (menuInfoObj['menuInfoBeforeMergeDiff']['franchiseMenuId'] === menuId) {
                    targetMenuInfo = menuInfoObj;
                }
            });
            if (!_.isEqual(targetMenuInfo, {})) {
                /* filter out useless categories */
                newOptionCategoryList = newOptionCategoryList.filter(categoryInfo => {
                    let count = 0;
                    let categoryId = categoryInfo['franchiseMenuOptionCategoryId'];
                    targetMenuInfo['optionList'].map(option => {
                        if ((option['optionInfoBeforeMergeDiff']['franchiseMenuOptionCategoryId'] === categoryId)) {
                            count++;
                        }
                    });
                    return (count > 0);
                });
            }
            /* set data */
            if (newOptionCategoryList.length > 0) {
                await setSelectedOptionCategoryId(newOptionCategoryList[0]['franchiseMenuOptionCategoryId']);
            } else {
                await setSelectedOptionCategoryId("");
            }
            await setOptionCategoryList(newOptionCategoryList);
            await setOptionInfoList(targetMenuInfo['optionList']);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            await setOptionInfoList([]);
            await setOptionCategoryList([]);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchMenuInfo();
    }, [menuId]);

    useEffect(() => {
        setFilteredOptionList(prevList => {
            /* filtering processing */
            let newFilteredList = optionInfoList.filter(optionInfo => {
                return (optionInfo['optionInfoBeforeMergeDiff']['franchiseMenuOptionCategoryId'] === selectedOptionCategoryId);
            });
            return newFilteredList;
        });
    }, [selectedOptionCategoryId, optionInfoList]);

    return (
        <Container fluid>
            {
                (optionInfoList.length > 0) &&
                <Row>
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        {/* language selection */}
                        <LanguageSelectBox
                            title={t("language.selectBox.title.menuOption")}
                            languageMetadataList={languageMetadataList}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}
                            setIsHighlightOn={setIsHighlightOn}
                            targetFieldString={targetFieldsString}
                        />
                    </Col>
                    <Col
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        {/* category selection box */}
                        <TitleAndSelectBox
                            title={t("menu.franchiseMenu.menuInfo.optionInfo.detail.category")}
                            innerValue={selectedOptionCategoryId}
                            valueList={optionCategoryList}
                            valueSelector={"franchiseMenuOptionCategoryId"}
                            viewSelector={t("language.dataKey.menu.option.category.name")}
                            onChange={(value) => setSelectedOptionCategoryId(value)}
                        />
                    </Col>
                </Row>
            }
            {
                (filteredOptionList.length === 0) ?
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                fontSize: '14px',
                                color: '#757575',
                                paddingTop: '50px'
                            }}
                        >
                            {t("menu.franchiseMenu.menuInfo.optionInfo.message.noOption")}
                        </div>
                    </Row>
                    :
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '620px',
                                border: 'none',
                                overflow: 'auto',
                                padding: '5px'
                            }}
                        >
                            {
                                filteredOptionList.map((optionInfo, index) => {
                                    return (
                                        <OptionDiffItemView
                                            key={optionInfo['optionInfoBeforeMergeDiff']['franchiseMenuOptionId']}
                                            optionInfo={optionInfo}
                                            refreshOptionList={fetchMenuInfo}
                                            currentLang={currentLang}
                                            isHighlightOn={isHighlightOn}
                                        />
                                    );
                                })
                            }
                        </div>
                    </Row>
            }
        </Container>
    );
};

export default BranchOptionInfoDiffView;