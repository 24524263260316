import _ from "lodash";

const filterOutDetailNotExistingMenus = (menuList, boothVersionId) => {
    let newList = [];
    menuList.map(menuInfoObj => {
        let detailBeforeMergeDiffList = menuInfoObj['menuDetailListBeforeMergeDiff'];
        detailBeforeMergeDiffList.map(menuDetailBeforeMergeDiff => {
            if (boothVersionId === menuDetailBeforeMergeDiff['franchiseMenuDetailBoothVersionId']) {
                newList.push(_.cloneDeep(menuInfoObj));
            }
        });
    });
    return newList;
};

const extractTargetDetailEntityByBoothVersionBeforeMerge = (menuDetailList, boothVersionId) => {
    let newDetail = {};
    menuDetailList.map(tempMenuDetail => {
        if (tempMenuDetail['franchiseMenuDetailBoothVersionId'] === boothVersionId) {
            newDetail = _.cloneDeep(tempMenuDetail);
        }
    });
    return newDetail;
};

const extractTargetDetailDiffEntityByBoothId = (menuDetailDiffList, boothId) => {
    let newDetail = {};
    menuDetailDiffList.map(tempMenuDetail => {
        if (tempMenuDetail['boothMenuDetailDiffBoothId'] === boothId) {
            newDetail = _.cloneDeep(tempMenuDetail);
        }
    });
    return newDetail;
};

const BoothMenuDiffUtils = {
    filterOutDetailNotExistingMenus,
    extractTargetDetailEntityByBoothVersionBeforeMerge,
    extractTargetDetailDiffEntityByBoothId
};

export default BoothMenuDiffUtils;