import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import RenderOnRole from "../../auth/RenderOnRole";
import {Container, Row} from "react-bootstrap";
import axios from "axios";
import UserService from "../../services/UserService";
import DateParseService from "../../services/DataParseService";
import styled from "styled-components";
import { ReactComponent as MagnifierIcon } from '../../icons/ic_search.svg';
import PaginationHandler from "../common/pagination/PaginationHandler";
import QnaTable from "./custom/QnaTable";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const FilterButton = styled.button`
  border: 1px solid #757575;
  background-color: white;
  color: #757575;
  border-radius: 10px;
  margin-right: 5px;
  transition: all ease-in-out 0.2s;
  font-size: 14px;
  
  &:focus {
    outline: none;
  }
`;

const Answered = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #24a0ed;
  background-color: #f3ffff;
  color: #24a0ed;
  padding: 5px 0px;
  width: 70%;
  font-size: 13px;
`;

const UnAnswered = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #ff3333;
  background-color: #ffe6e6;
  color: #ff3333;
  padding: 5px 0px;
  width: 70%;
  font-size: 13px;
`;

const Empty = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid transparent;
  background: none;
  padding: 5px 0px;
  width: 70%;
  font-size: 13px;
`;

const QnaManagement = () => {

    const { t, i18n } = useTranslation();

    const [qnaList, setQnaList] = useState([]);

    /* search variables */
    /* ^(all|answered|unanswered)$ */
    const [qnaStatus, setQnaStatus] = useState("unanswered");
    const [searchLike, setSearchLike] = useState("");
    /* ^(all|title|content|userId)$ */
    const [searchField, setSearchField] = useState("all");

    /* pagination */
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);

    /* table column data */
    const columnData = [
        {
            accessor: t("language.dataKey.qna.category.name"),
            Header: t('common.qnaCategory'),
        },
        {
            accessor: 'qnaTitle',
            Header: t('common.title'),
        },
        {
            accessor: 'qnaGroupName',
            Header: t('common.group'),
        },
        {
            accessor: 'qnaUserName',
            Header: t('common.customer'),
        },
        {
            accessor: 'answered',
            Header: t('common.answered'),
            Cell: ({ cell: { value } }) => <IsAnswered value={value} />
        },
        {
            accessor: 'generateDate',
            Header: t('common.generateDate'),
        }
    ];

    /* inner-table component */
    const IsAnswered = (value) => {
        if (value.value === "none") {
            return (
                <Empty>
                    {"　"}
                </Empty>
            );
        }
        if (value.value === true) {
            return (
                <Answered>
                    {t("menu.qna.status.answered")}
                </Answered>
            );
        } else {
            return (
                <UnAnswered>
                    {t("menu.qna.status.unanswered")}
                </UnAnswered>
            );
        }
    };

    /* utils */
    const renameQnaIdToId = (objectList) => {
        let newObjList = [...objectList];
        const iter = 7 - objectList.length;
        if (newObjList.length < 7) {
            for (let i=0; i < iter; i++) {
                newObjList.push({
                    "qnaId" : null,
                    "answered" : "none"
                });
            }
        }
        return newObjList.map(obj => {
            if ('qnaId' in obj) {
                obj['id'] = obj['qnaId'];
                delete obj['qnaId'];
            }
            if ('generateDate' in obj) {
                obj['generateDate'] = DateParseService.dateTimeToDate(obj['generateDate']);
            }
            return obj;
        })
    }

    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    };

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    };

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    };

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    };

    /* fetch data */
    const fetchQnaList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/qna`
                + `?page=${currentPageIndex}&size=${7}&qna_status=${qnaStatus}`;
            if (searchLike !== "") {
                fetchUrl = fetchUrl + `&field=${searchField}&like=${searchLike}`;
            }
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                let dataList = [];
                for (let i=0; i<6; i++) {
                    dataList.push({
                        "id" : null,
                        "answered" : "none"
                    });
                }
                setQnaList(dataList);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            } else {
                let dataList = [...res.data.item.qnaList];
                let newDataList = [];
                dataList.map(prevObj => {
                    let newObj = {...prevObj};
                    newObj['qnaCategoryName'] = prevObj['qnaCategoryInfo']['qnaCategoryName'];
                    newObj['qnaCategoryName1'] = prevObj['qnaCategoryInfo']['qnaCategoryName1'];
                    newObj['qnaCategoryName2'] = prevObj['qnaCategoryInfo']['qnaCategoryName2'];
                    newObj['qnaCategoryName3'] = prevObj['qnaCategoryInfo']['qnaCategoryName3'];
                    newObj['qnaCategoryName4'] = prevObj['qnaCategoryInfo']['qnaCategoryName4'];
                    newObj['qnaCategoryName5'] = prevObj['qnaCategoryInfo']['qnaCategoryName5'];
                    newDataList.push(newObj);
                });
                setQnaList(renameQnaIdToId(newDataList));
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            }
        } catch (e) {
            console.log(e);
        }
    };


    /* effects */
    useEffect(() => {
        fetchQnaList();
    }, [qnaStatus]);

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.QNA_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.qna.title")]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    {/* search field and like */}
                    <Row>
                        <div
                            className={"contentInner"}
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {/* search bar */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    display: 'flex'
                                }}
                            >
                                <div
                                    style={{
                                        width: '270px',
                                        height: '50px',
                                    }}
                                >
                                    <select
                                        id={"search-field-select-box"}
                                        defaultValue={"all"}
                                        onChange={(e) => setSearchField(e.target.value)}
                                    >
                                        <option value={"all"}>{t("common.search.fields.all")}</option>
                                        <option value={"title"}>{t("common.search.fields.title")}</option>
                                        <option value={"content"}>{t("common.search.fields.content")}</option>
                                        <option value={"userId"}>{t("common.search.fields.userId")}</option>
                                    </select>
                                </div>
                                <div
                                    style={{
                                        width: '270px',
                                        height: '50px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <input
                                        id={"search-like-input"}
                                        type={"text"}
                                        placeholder={t("common.search.placeholder")}
                                        value={searchLike}
                                        onChange={(e) => setSearchLike(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                fetchQnaList();
                                            }
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <button
                                        id={"box-btn"}
                                        onClick={() => fetchQnaList()}
                                    >
                                        <MagnifierIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Row>
                    {/* table view */}
                    <Row>
                        <div
                            className={"contentInner"}
                            style={{
                                marginTop: '10px',
                            }}
                        >
                            {/* filters */}
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginBottom: '10px'
                                }}
                            >
                                <FilterButton
                                    style={
                                        qnaStatus === "all" ?
                                            {
                                                borderColor: '#fc7242',
                                                color: '#fc7232',
                                                backgroundColor: '#fff0e6'
                                            }
                                            :
                                            {}
                                    }
                                    onClick={() => setQnaStatus("all")}
                                >
                                    {t("menu.qna.status.all")}
                                </FilterButton>
                                <FilterButton
                                    style={
                                        qnaStatus === "answered" ?
                                            {
                                                borderColor: '#fc7242',
                                                color: '#fc7232',
                                                backgroundColor: '#fff0e6'
                                            }
                                            :
                                            {}
                                    }
                                    onClick={() => setQnaStatus("answered")}
                                >
                                    {t("menu.qna.status.answered")}
                                </FilterButton>
                                <FilterButton
                                    style={
                                        qnaStatus === "unanswered" ?
                                            {
                                                borderColor: '#fc7242',
                                                color: '#fc7232',
                                                backgroundColor: '#fff0e6'
                                            }
                                            :
                                            {}
                                    }
                                    onClick={() => setQnaStatus("unanswered")}
                                >
                                    {t("menu.qna.status.unanswered")}
                                </FilterButton>
                            </div>
                            <QnaTable columns={columnData} data={qnaList} baseUrl={'detail'} />
                            {/* pagination */}
                            <div
                                style={{
                                    width: '400px',
                                    height: '50px',
                                    marginTop: '15px',
                                    marginLeft: 'auto'
                                }}
                            >
                                {/*goBefore, goAfter, goFirst, goLast, pageSize, currentIndex, maxIndex, totalItemNumber*/}
                                <PaginationHandler
                                    goBefore={handleGoBefore}
                                    goAfter={handleGoAfter}
                                    goFirst={handleGoFirst}
                                    goLast={handleGoLast}
                                    pageSize={7}
                                    currentIndex={currentPageIndex}
                                    maxIndex={maxPageIndex}
                                    totalItemNumber={totalElements}
                                />
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default QnaManagement;