import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Tooltip} from "react-bootstrap";

const OverlayToolTip = ({ text, direction, children }) => {
    const renderToolTip = (text) => {
        return (
            <Tooltip id="tooltip">
                {text}
            </Tooltip>
        );
    };

    return (
        <OverlayTrigger placement={direction} overlay={renderToolTip(text)}>
            {children}
        </OverlayTrigger>
    );
};

export default OverlayToolTip;