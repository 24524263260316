import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import RenderOnRole from "../../auth/RenderOnRole";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import axios from "axios";
import UserService from "../../services/UserService";
import DataCompareService from "../../services/DataCompareService";
import AuthService from "../../services/AuthService";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import DataParseService from "../../services/DataParseService";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import styled from "styled-components";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../services/DeleteService";

const SaveButton = styled.button`
  width: 150px;
  border: 1px solid #fc7242;
  background-color: white;
  color: #fc7242;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  font-size: 14px;
  
  &:hover {
    background-color: #fff1ec;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  width: 150px;
  border: 1px solid #ebebeb;
  background-color: white;
  color: #ebebeb;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  font-size: 14px;
  
  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled.button`
  width: 150px;
  border: 1px solid #ff4d4d;
  background-color: white;
  color: #ff4d4d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  font-size: 14px;
  
  &:hover {
    background-color: #ffcccc;
  }
  
  &:focus {
    outline: none;
  }
`;

const questionRequestBodyInit = {
  qnaTitle : "",
  qnaContent : "",
  qnaCategoryId : ""
};

const questionIsValidMapTrueInit = {
  qnaTitle : true,
  qnaContent : true,
  qnaCategoryId : true
};

const questionIsValidMapFalseInit = {
  qnaTitle : false,
  qnaContent : false,
  qnaCategoryId : false
};

const answerRequestBodyInit = {
  qnaAnswer : ""
};

const answerIsValidMapTrueInit = {
  qnaAnswer : true
};

const answerIsValidMapFalseInit = {
  qnaAnswer : false
};

const QnaDetail = () => {

    const { t, i18n } = useTranslation();
    const { qnaId } = useParams();
    const navigate = useNavigate();

    /* ------------------------------------------ */
    /* ----------------- States ----------------- */
    const [qnaData, setQnaData] = useState({});
    const [isAnswered, setIsAnswered] = useState(false);

    const [qnaCategoryList, setQnaCategoryList] = useState([]);

    const [questionRequestBody, setQuestionRequestBody] = useState({});
    const [fixedQuestionRequestBody, setFixedQuestionRequestBody] = useState({});
    const [questionIsValidMap, setQuestionIsValidMap] = useState({});
    const [questionIsValid, setQuestionIsValid] = useState(true);
    const [questionIsEdited, setQuestionIsEdited] = useState(false);

    const [answerRequestBody, setAnswerRequestBody] = useState({});
    const [fixedAnswerRequestBody, setFixedAnswerRequestBody] = useState({});
    const [answerIsValidMap, setAnswerIsValidMap] = useState({});
    const [answerIsValid, setAnswerIsValid] = useState(true);
    const [answerIsEdited, setAnswerIsEdited] = useState(false);

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [isAnswerDeleteConfirmOpen, setIsAnswerDeleteConfirmOpen] = useState(false);
    /* ------------------------------------------ */
    /* ------------------------------------------ */

    /* utils */
    /* update methods */
    const updateQuestionRequestBody = (key, value) => {
        setQuestionRequestBody(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    const updateQuestionIsValidMap = (key, value) => {
        setQuestionIsValidMap(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    const updateAnswerRequestBody = (key, value) => {
        setAnswerRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateAnswerIsValidMap = (key, value) => {
        setAnswerIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* input validation */
    const validateQnaTitle = (value) => {
        if (typeof value === "undefined" || value == null || value === "") {
            updateQuestionIsValidMap("qnaTitle", false);
        } else {
            updateQuestionIsValidMap("qnaTitle", true);
        }
    };

    const validateQnaContent = (value) => {
        if (typeof value === "undefined" || value == null || value === "") {
            updateQuestionIsValidMap("qnaContent", false);
        } else {
            updateQuestionIsValidMap("qnaContent", true);
        }
    };

    const validateQnaCategoryId = (value) => {
        if (typeof value === "undefined" || value == null || value === "") {
            updateQuestionIsValidMap("qnaCategoryId", false);
        } else {
            updateQuestionIsValidMap("qnaCategoryId", true);
        }
    };

    const validateAnswer = (value) => {
        if (typeof value === "undefined" || value == null || value === "") {
            updateAnswerIsValidMap("qnaAnswer", false);
        } else {
            updateAnswerIsValidMap("qnaAnswer", true);
        }
    };

    /* fetch data */
    const fetchQnaCategories = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/qna-category`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            setQnaCategoryList(res.data.item.qnaCategoryList);
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.serverFailed")} />);
            navigate(`/qna`);
        }
    };

    const fetchQnaDetail = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/qna/${qnaId}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            let newIsAnswered = res.data.item['qnaDetail']['isAnswered'];
            let newData = res.data.item['qnaDetail'];
            setQnaData(_.cloneDeep(newData));
            /* is answered */
            setIsAnswered(newIsAnswered);
            /* question body */
            let newQuestionRequestBody = {};
            newQuestionRequestBody['qnaTitle'] = newData['qnaTitle'];
            newQuestionRequestBody['qnaContent'] = newData['qnaContent'];
            newQuestionRequestBody['qnaCategoryId'] = newData['qnaCategoryId'];
            setQuestionRequestBody(_.cloneDeep(newQuestionRequestBody));
            setFixedQuestionRequestBody(_.cloneDeep(newQuestionRequestBody));
            setQuestionIsValidMap(questionIsValidMapTrueInit);
            /* answer body */
            if (newIsAnswered) {
                /* answered case */
                let newAnswerRequestBody = {};
                newAnswerRequestBody['qnaAnswer'] = newData['qnaAnswer'];
                setAnswerRequestBody(_.cloneDeep(newAnswerRequestBody));
                setFixedAnswerRequestBody(_.cloneDeep(newAnswerRequestBody));
                setAnswerIsValidMap(answerIsValidMapTrueInit);
            } else {
                /* un-answered case */
                setAnswerRequestBody(answerRequestBodyInit);
                setFixedAnswerRequestBody(answerRequestBodyInit);
                setAnswerIsValidMap(answerIsValidMapFalseInit);
            }
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("menu.qna.detail.message.notExist")} />);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    /* save data */
    const editQuestion = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/qna/${qnaId}`
                + `/question/edit`;
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(questionRequestBody),
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            fetchQnaDetail();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
        }
    };

    const addAnswer = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/qna/${qnaId}`
                + `/answer`;
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(answerRequestBody),
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            fetchQnaDetail();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
        }
    };

    const editAnswer = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/qna/${qnaId}`
                + `/answer/edit`;
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(answerRequestBody),
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            fetchQnaDetail();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
        }
    };

    const deleteAnswer = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/qna/${qnaId}`
                + `/answer`;
            const axiosCall = () => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.deleted")} />);
            fetchQnaDetail();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
        }
    };

    /** auth */
    const hasDeleteRole = () => {
        return AuthService.hasAuthByRoleNameAndType(ImsSystemRole.QNA_MANAGEMENT, "DELETE");
    };

    const hasPutRole = () => {
        return AuthService.hasAuthByRoleNameAndType(ImsSystemRole.QNA_MANAGEMENT, "PUT");
    };

    /* ----------------------------------------- */
    /* ---------------- Effects ---------------- */
    /* fetch */
    useEffect(() => {
        fetchQnaCategories();
        fetchQnaDetail();
    }, []);

    /* isValid tracking */
    useEffect(() => {
        setQuestionIsValid(DataCompareService.checkIsAllTrue(questionIsValidMap));
    }, [questionIsValidMap]);

    useEffect(() => {
        setAnswerIsValid(DataCompareService.checkIsAllTrue(answerIsValidMap));
    }, [answerIsValidMap]);

    /* isEdited tracking */
    useEffect(() => {
        setQuestionIsEdited(!_.isEqual(questionRequestBody, fixedQuestionRequestBody));
    }, [questionRequestBody]);

    useEffect(() => {
        setAnswerIsEdited(!_.isEqual(answerRequestBody, fixedAnswerRequestBody));
    }, [answerRequestBody]);

    /* input validation */
    useEffect(() => {
        validateQnaTitle(questionRequestBody["qnaTitle"]);
        validateQnaContent(questionRequestBody["qnaContent"]);
        validateQnaCategoryId(questionRequestBody["qnaCategoryId"]);
    }, [questionRequestBody]);

    useEffect(() => {
        validateAnswer(answerRequestBody["qnaAnswer"]);
    }, [answerRequestBody]);
    /* ----------------------------------------- */
    /* ----------------------------------------- */


    if (!(qnaCategoryList.length === 0
        || _.isEqual(qnaData, {})
        || _.isEqual(questionRequestBody, {})
        || _.isEqual(answerRequestBody, {}))) {
        return (
            <RenderOnRole type={"GET"} roleName={ImsSystemRole.QNA_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
                {/* title */}
                <div
                    className={"headingTitle"}
                >
                    <BackwardButton
                        isShow={true}
                        link={"/qna"}
                    />
                    <PageTitleWithDepth
                        titles={[t("menu.qna.title"), t("menu.qna.detail.title")]}
                        depth={2}
                    />
                </div>

                <div
                    className={"contentContainer"}
                    style={{
                        height: '1000px'
                    }}
                >
                    <Container fluid>
                        {/* question info */}
                        <Row>
                            <div className={"contentInner"}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        marginLeft: 'auto',
                                        textAlign: 'right'
                                    }}
                                >
                                    {
                                        hasDeleteRole() ?
                                            <>
                                                <DeleteButton
                                                    onClick={() => setIsDeleteConfirmOpen(true)}
                                                >
                                                    {t("button.deleteQuestion")}
                                                </DeleteButton>
                                                <DeleteConfirmModal
                                                    isOpen={isDeleteConfirmOpen}
                                                    setIsOpen={setIsDeleteConfirmOpen}
                                                    isDelayed={false}
                                                    confirmMessage={t("language.message.deletion.confirmation", { entityName : t("language.message.deletion.depsList.qna.self") })}
                                                    entity={DeleteService.ValidEntities.QNA}
                                                    entityName={t("language.message.deletion.depsList.qna.self")}
                                                    id={`qna/${qnaId}`}
                                                    onDeleteSuccess={async () => {
                                                        await navigate('/qna');
                                                    }}
                                                />
                                            </>
                                            :
                                            <SaveButtonDisabled>
                                                {t("button.deleteQuestion")}
                                            </SaveButtonDisabled>
                                    }

                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    borderRadius: '5px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%'
                                    }}
                                >
                                    <Container fluid>
                                        <Row>
                                            <Col
                                                style={{
                                                    padding: '0px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("menu.qna.detail.questionUserName")}
                                                    value={qnaData['qnaUserName']
                                                            + "  (" + qnaData['qnaUserId'] + ")"}
                                                    onChange={() => {}}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    isEnabled={true}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("menu.qna.detail.questionGroupName")}
                                                    value={qnaData['qnaGroupName']}
                                                    onChange={() => {}}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    isEnabled={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div
                                    style={{
                                        marginTop: '10px',
                                        width: '100%'
                                    }}
                                >
                                    <Container fluid>
                                        <Row>
                                            <Col
                                                md={"4"}
                                                style={{
                                                    padding: '0px'
                                                }}
                                            >
                                                {
                                                    (!isAnswered) && hasPutRole() ?
                                                        <TitleAndSelectBox
                                                            title={t("menu.qna.detail.questionCategory")}
                                                            innerValue={questionRequestBody['qnaCategoryId']}
                                                            valueList={qnaCategoryList}
                                                            valueSelector={"qnaCategoryId"}
                                                            viewSelector={t("language.dataKey.qna.category.name")}
                                                            onChange={(value) => updateQuestionRequestBody("qnaCategoryId", value)}
                                                        />
                                                        :
                                                        <TitleAndSelectBox
                                                            title={t("menu.qna.detail.questionCategory")}
                                                            innerValue={questionRequestBody['qnaCategoryId']}
                                                            valueList={qnaCategoryList}
                                                            valueSelector={"qnaCategoryId"}
                                                            viewSelector={t("language.dataKey.qna.category.name")}
                                                            onChange={() => {}}
                                                        />
                                                }
                                            </Col>
                                            <Col
                                                md={"8"}
                                                style={{
                                                    padding: '0px',
                                                    paddingLeft: '10px'
                                                }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("menu.qna.detail.questionTitle")}
                                                    value={questionRequestBody['qnaTitle']}
                                                    onChange={(value) => updateQuestionRequestBody("qnaTitle", value)}
                                                    isForEdit={(!isAnswered) && hasPutRole()}
                                                    type={"text"}
                                                    isValid={questionIsValidMap['qnaTitle']}
                                                    isEnabled={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        marginTop: '5px'
                                    }}
                                >
                                    <TitleAndTextArea
                                        title={t("menu.qna.detail.questionContent")}
                                        value={questionRequestBody['qnaContent']}
                                        onChange={(value) => updateQuestionRequestBody("qnaContent", value)}
                                        isForEdit={(!isAnswered) && hasPutRole()}
                                        isValid={questionIsValidMap['qnaContent']}
                                        height={"180"}
                                    />
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        margin: '0px',
                                        padding: '0px',
                                        paddingLeft: '10px'
                                    }}
                                >
                                    <span
                                        style={{
                                            color: '#c2c2c2',
                                            fontSize: '13px'
                                        }}
                                    >
                                        {
                                            qnaData['isQuestionEdited'] ?
                                                t("menu.qna.detail.questionEdited")
                                                + " "
                                                + t("menu.qna.detail.questionLastEditedAt")
                                                + DataParseService.dateTimeToDate(qnaData['questionLastEditDate'])
                                                :
                                                t("menu.qna.detail.questionLastEditedAt")
                                                + DataParseService.dateTimeToDate(qnaData['questionLastEditDate'])
                                        }
                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'right'
                                    }}
                                >
                                    {
                                        questionIsEdited && questionIsValid && hasPutRole() ?
                                            <SaveButton
                                                onClick={() => editQuestion()}
                                            >
                                                {t("button.saveDiff")}
                                            </SaveButton>
                                            :
                                            <SaveButtonDisabled>
                                                {t("button.saveDiff")}
                                            </SaveButtonDisabled>
                                    }
                                </div>
                            </div>
                            </div>
                        </Row>
                        {/* answer info */}
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <div
                                className={"contentInner"}
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px'
                                }}
                            >
                                <Container fluid>
                                    <Row>
                                        <Col
                                            style={{
                                                padding: '0px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("menu.qna.detail.answerAdminName")}
                                                value={qnaData["qnaAdminName"] ? qnaData["qnaAdminName"] : t("common.noInfo")}
                                                onChange={()=>{}}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                isEnabled={qnaData["isAnswered"]}
                                            />
                                        </Col>
                                        <Col
                                            style={{
                                                padding: '0px',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("menu.qna.detail.answerLastEditedAt")}
                                                value={
                                                    qnaData["isAnswered"] ?
                                                        DataParseService.dateTimeToDate(qnaData["answerLastEditDate"])
                                                        :
                                                        ""
                                                }
                                                onChange={()=>{}}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                isEnabled={qnaData["isAnswered"]}
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <TitleAndTextArea
                                            title={t("menu.qna.detail.answerContent")}
                                            value={answerRequestBody["qnaAnswer"]}
                                            onChange={(value) => updateAnswerRequestBody("qnaAnswer", value)}
                                            isForEdit={hasPutRole()}
                                            isValid={answerIsValidMap["qnaAnswer"]}
                                            height={"180"}
                                        />
                                    </Row>
                                    {/* save and delete */}
                                    <Row
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                {
                                                    hasDeleteRole() && isAnswered ?
                                                        <>
                                                            <DeleteButton
                                                                onClick={() => setIsAnswerDeleteConfirmOpen(true)}
                                                            >
                                                                {t("button.deleteAnswer")}
                                                            </DeleteButton>
                                                            <DeleteConfirmModal
                                                                isOpen={isAnswerDeleteConfirmOpen}
                                                                setIsOpen={setIsAnswerDeleteConfirmOpen}
                                                                isDelayed={false}
                                                                confirmMessage={t("language.message.deletion.confirmation", { entityName : t("language.message.deletion.depsList.qna.answer") })}
                                                                entity={DeleteService.ValidEntities.QNA_ANSWER}
                                                                entityName={t("language.message.deletion.depsList.qna.answer")}
                                                                id={`qna/${qnaId}/answer`}
                                                                onDeleteSuccess={async () => {
                                                                    await fetchQnaDetail();
                                                                }}
                                                            />
                                                        </>
                                                        :
                                                        <SaveButtonDisabled>
                                                            {t("button.deleteAnswer")}
                                                        </SaveButtonDisabled>
                                                }
                                            </div>
                                            {
                                                isAnswered ?
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            marginLeft: 'auto',
                                                            textAlign: 'right'
                                                        }}
                                                    >
                                                        {
                                                            hasPutRole() && answerIsEdited && answerIsValid ?
                                                                <SaveButton
                                                                    onClick={() => editAnswer()}
                                                                >
                                                                    {t("button.saveDiff")}
                                                                </SaveButton>
                                                                :
                                                                <SaveButtonDisabled>
                                                                    {t("button.saveDiff")}
                                                                </SaveButtonDisabled>
                                                        }
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                !isAnswered ?
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            marginLeft: 'auto',
                                                            textAlign: 'right'
                                                        }}
                                                    >
                                                        {
                                                            hasPutRole() && answerIsValid ?
                                                                <SaveButton
                                                                    onClick={() => addAnswer()}
                                                                >
                                                                    {t("button.addAnswer")}
                                                                </SaveButton>
                                                                :
                                                                <SaveButtonDisabled>
                                                                    {t("button.addAnswer")}
                                                                </SaveButtonDisabled>
                                                        }
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </Row>
                    </Container>
                </div>
            </RenderOnRole>
        );
    }
};

export default QnaDetail;