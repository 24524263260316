import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import UserService from "../../services/UserService";
import axios from "axios";
import DataCompareService from "../../services/DataCompareService";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import RenderOnRole from "../../auth/RenderOnRole";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const isValidMapInit = {
    faqQnaCategoryId: true,
    faqTitle: true,
    questionContent: true,
    answerContent: true,
    faqGroupId: true,
}

const FaqEdit = () => {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { faqId } = useParams();

    const [groupList, setGroupList] = useState([]);
    const [qnaCategoryList, setQnaCategoryList] = useState([]);

    const [prevFaqInfo, setPrevFaqInfo] = useState({});
    const [afterFaqInfo, setAfterFaqInfo] = useState({});

    const [isEdited, setIsEdited] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);

    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setGroupList(res.data.item.groupList);
        } catch (e) {
            console.error(e);
        }
    }

    const fetchQnaCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/qna-category`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setQnaCategoryList(res.data.item.qnaCategoryList);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const fetchFaqInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/faq/${faqId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let faqInfoObj = {};
            faqInfoObj["faqQnaCategoryId"] = res.data.item.faqDetail["faqQnaCategoryId"];
            faqInfoObj["faqTitle"] = res.data.item.faqDetail["faqTitle"];
            faqInfoObj["questionContent"] = res.data.item.faqDetail["questionContent"];
            faqInfoObj["answerContent"] = res.data.item.faqDetail["answerContent"];
            faqInfoObj["faqGroupId"] = res.data.item.faqDetail["faqGroupId"];
            setPrevFaqInfo(faqInfoObj);
            setAfterFaqInfo(DataCompareService.deepCopyObj(faqInfoObj));
        } catch (e) {
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
            console.error(e);
        }
    };

    const updateAfterFaqInfo = (key, value) => {
        setAfterFaqInfo(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    const handleFaqTitleInput = (value) => {
        updateAfterFaqInfo("faqTitle", value);
    };

    const handleQuestionContentInput = (value) => {
        updateAfterFaqInfo("questionContent", value);
    };

    const handleAnswerContentInput = (value) => {
        updateAfterFaqInfo("answerContent", value);
    };

    const handleGroupIdChange = (value) => {
        updateAfterFaqInfo("faqGroupId", value);
    };

    const handleFaqQnaCategoryChange = (value) => {
        updateAfterFaqInfo("faqQnaCategoryId", value);
    };

    const checkFaqTitleValidity = () => {
        if (afterFaqInfo["faqTitle"] === "") {
            updateIsValidMap("faqTitle", false);
            return;
        }
        const isValidForm = (afterFaqInfo["faqTitle"].length <= 40) && (afterFaqInfo["faqTitle"].length >= 3);
        updateIsValidMap("faqTitle", isValidForm);
    };

    const checkQuestionContentValidity = () => {
        if (afterFaqInfo["questionContent"] === "") {
            updateIsValidMap("questionContent", false);
        } else {
            updateIsValidMap("questionContent", true);
        }
    }

    const checkAnswerContentValidity = () => {
        if (afterFaqInfo["answerContent"] === "") {
            updateIsValidMap("answerContent", false);
        } else {
            updateIsValidMap("answerContent", true);
        }
    }

    const putFaqInformation = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/faq/${faqId}`;
            let requestBody = JSON.stringify(afterFaqInfo);
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                requestBody,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate(`/faq/detail/${faqId}`);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.error(e);
        }
    }

    useEffect(() => {
        fetchGroupList();
        fetchQnaCategoryList();
        fetchFaqInfo();
    }, []);

    useEffect(() => {
        setIsEdited(!DataCompareService.isSameObj(prevFaqInfo, afterFaqInfo));
    }, [afterFaqInfo]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        if (typeof afterFaqInfo["faqTitle"] === "undefined") {
            return;
        }
        checkFaqTitleValidity();
    }, [afterFaqInfo["faqTitle"]]);

    useEffect(() => {
        if (typeof afterFaqInfo["questionContent"] === "undefined") {
            return;
        }
        checkQuestionContentValidity();
    }, [afterFaqInfo["questionContent"]]);

    useEffect(() => {
        if (typeof afterFaqInfo["answerContent"] === "undefined") {
            return;
        }
        checkAnswerContentValidity();
    }, [afterFaqInfo["answerContent"]]);

    return (
        <RenderOnRole type={"PUT"} roleName={ImsSystemRole.QNA_MANAGEMENT} allowedRing={2}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={`/faq/detail/${faqId}`}
                />
                <PageTitleWithDepth
                    titles={[t("menu.faq.title"), t("menu.faq.detail.title"), t("menu.faq.edit.title")]}
                    depth={3}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                <Container fluid>
                    {/* main area */}
                    <Row>
                        <div
                            style={{
                                width: '100%'
                            }}
                        >
                            <Container fluid>
                                <Row>
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.title")}
                                            value={afterFaqInfo["faqTitle"]}
                                            onChange={handleFaqTitleInput}
                                            isForEdit={true}
                                            type={"text"}
                                            isValid={isValidMap["faqTitle"]}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndSelectBox
                                            title={t("common.group")}
                                            innerValue={afterFaqInfo["faqGroupId"]}
                                            onChange={handleGroupIdChange}
                                            valueList={groupList}
                                            valueSelector={"groupId"}
                                            viewSelector={"groupName"}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndSelectBox
                                            title={t("common.category")}
                                            innerValue={afterFaqInfo["faqQnaCategoryId"]}
                                            onChange={handleFaqQnaCategoryChange}
                                            valueList={qnaCategoryList}
                                            valueSelector={"qnaCategoryId"}
                                            viewSelector={t("language.dataKey.qna.category.name")}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <TitleAndTextArea
                                        title={t("common.questionContent")}
                                        value={afterFaqInfo["questionContent"]}
                                        onChange={handleQuestionContentInput}
                                        isForEdit={true}
                                        isValid={isValidMap["questionContent"]}
                                        height={"170"}
                                    />
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <TitleAndTextArea
                                        title={t("common.answerContent")}
                                        value={afterFaqInfo["answerContent"]}
                                        onChange={handleAnswerContentInput}
                                        isForEdit={true}
                                        isValid={isValidMap["answerContent"]}
                                        height={"170"}
                                    />
                                </Row>
                            </Container>
                        </div>
                    </Row>
                    {/* buttons */}
                    <Row
                        style={{
                            marginTop: '77px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '68px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'right'
                            }}
                        >
                            <div
                                style={{ marginRight: '10px' }}
                            >
                                <Link
                                    id={"edit-btn"}
                                    to={`/faq/detail/${faqId}`}
                                >
                                    {t("button.cancel")}
                                </Link>
                            </div>
                            <div>
                                {
                                    isEdited && isValid
                                        ?
                                        <Link
                                            id={"save-btn"}
                                            onClick={() => putFaqInformation()}
                                        >
                                            {t("button.save")}
                                        </Link>
                                        :
                                        <Link
                                            id={"save-btn-disabled"}
                                        >
                                            {t("button.save")}
                                        </Link>
                                }
                            </div>
                        </div>
                    </Row>
                </Container>
                </div>
            </div>
        </RenderOnRole>
    )


}

export default FaqEdit;
