/**
 * Truncate string longer than "n" characters.
 * @param {string} str - original
 * @param {number} n - maximum length
 * @return {string} - truncated string
 */
const truncateWithEllipsis = (str, n) => {
    if (str.length <= n) {
        return str;
    }
    return str.slice(0, n) + '...';
}

const StrUtils = {
    truncateWithEllipsis
};

export default StrUtils;

