import React from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../../common/modal/ModalCloseButton";
import styled from "styled-components";
import TitleAndInputBox from "../../../common/dataView/TitleAndInputBox";
import OverlayToolTip from "../../../menu/common/toolTip/OverlayToolTip";
import DataParseService from "../../../../services/DataParseService";
import JsonSchemaParserForLog from "./parser/JsonSchemaParserForLog";
import JsonView from "react18-json-view";
import 'react18-json-view/src/style.css';
import {CiWarning} from "react-icons/ci";
import {GoCheckCircle} from "react-icons/go";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '650px',
        height: '540px',
        margin: 'auto'
    }
};

const colorMap = {
    control : "#24a0ed", /* blue */
    controlBack : "#f3ffff",
    setting : "#52b640", /* green */
    settingBack : "#ffffe3",
    product : "#7f5a37", /* brown */
    productBack : "#ffffed",
    order : "#e73d70", /* pink */
    orderBack : "#fff3f5",
    admin: "#757575",
    customer: "#fc7242", /* orange */
    device: "#6a0dad", /* purple */
    sensor: "#a44ce6", /* lighter purple */
    endName: "#de82ff" /* lighter lighter purple */
};

const ViewBox = styled.div`
  padding: 5px 10px;
  border: 1px solid ${(props) => props.color};
  border-radius: 10px;
  color: ${(props) => props.color};
  font-size: 14px;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "white"};
`;

const MessageHistoryDetailModal = ({ isOpen, setIsOpen, data, jsonSchema }) => {
    /* available specs */
    /* 1.  attributes */
    /* 2.  capabilities */
    /* 3.  orders */
    /* 4.  products */

    const { t, i18n } = useTranslation();

    /* translation map */
    const translationMap = {
        "label" : t("language.dataKey.boothControl.json.label"),
        "dataUnknowns" : {
            "device" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.device"),
            "attribute" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.attribute"),
            "sensor" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.sensor"),
            "capability" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.capability"),
            "connection" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.connection"),
            "reqData" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.reqData"),
        }
    };

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.booth.detail.messageLog.item.detail")}
                        setIsOpen={setIsOpen}
                    />
                </Row>

                {/* type view */}
                <Row>
                    {
                        ((data['spec'] === "attributes") || (data['spec'] === "connection")) &&
                        <ViewBox
                            color={colorMap['setting']}
                            backgroundColor={colorMap['settingBack']}
                            style={{
                                marginRight: '10px'
                            }}
                        >
                            {t("menu.booth.detail.messageLog.item.type.setting")}
                        </ViewBox>
                    }
                    {
                        (data['spec'] === "capabilities") &&
                        <ViewBox
                            color={colorMap['control']}
                            backgroundColor={colorMap['controlBack']}
                            style={{
                                marginRight: '10px'
                            }}
                        >
                            {t("menu.booth.detail.messageLog.item.type.control")}
                        </ViewBox>
                    }
                    {
                        (data['spec'] === "products") &&
                        <ViewBox
                            color={colorMap['product']}
                            backgroundColor={colorMap['productBack']}
                            style={{
                                marginRight: '10px'
                            }}
                        >
                            {t("menu.booth.detail.messageLog.item.type.product")}
                        </ViewBox>
                    }
                    {
                        (data['spec'] === "orders") &&
                        <ViewBox
                            color={colorMap['order']}
                            backgroundColor={colorMap['orderBack']}
                            style={{
                                marginRight: '10px'
                            }}
                        >
                            {t("menu.booth.detail.messageLog.item.type.order")}
                        </ViewBox>
                    }
                    {/* source */}
                    {
                        (data['spec'] === "orders") &&
                        <OverlayToolTip
                            text={data['clientId'] ? data['clientId'] : t("menu.booth.detail.messageLog.item.unKnown")}
                            direction={"bottom"}
                        >
                            <ViewBox
                                color={colorMap['admin']}
                                style={{
                                    marginLeft: 'auto'
                                }}
                            >
                                {t("menu.booth.detail.messageLog.item.source.franchiseAppServer")}
                            </ViewBox>
                        </OverlayToolTip>
                    }
                    {
                        (data['spec'] !== "orders") &&
                        <OverlayToolTip
                            text={data['clientId'] ? data['clientId'] : t("menu.booth.detail.messageLog.item.unKnown")}
                            direction={"bottom"}
                        >
                            {
                                data['isAdmin'] ?
                                    <ViewBox
                                        color={colorMap['admin']}
                                        style={{
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        {t("menu.booth.detail.messageLog.item.source.admin")}
                                    </ViewBox>
                                    :
                                    <ViewBox
                                        color={colorMap['admin']}
                                        style={{
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        {t("menu.booth.detail.messageLog.item.source.customer")}
                                    </ViewBox>
                            }
                        </OverlayToolTip>
                    }
                    {/* result */}
                    {
                        data['error_code'] ?
                            <OverlayToolTip
                                text={t("menu.booth.detail.messageLog.item.result.fail")}
                                direction={"bottom"}
                            >
                                <div
                                    style={{
                                        width: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <CiWarning
                                        size={"25"}
                                        color={"red"}
                                    />
                                </div>
                            </OverlayToolTip>
                            :
                            <OverlayToolTip
                                text={t("menu.booth.detail.messageLog.item.result.success")}
                                direction={"bottom"}
                            >
                                <div
                                    style={{
                                        width: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <GoCheckCircle
                                        size={"25"}
                                        color={"green"}
                                    />
                                </div>
                            </OverlayToolTip>
                    }
                </Row>

                {/* target view */}
                {
                    ((data['spec'] === "capabilities") || (data['spec'] === "attributes") || (data['spec'] === "connection")) &&
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'inline-flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            {/* sensor target */}
                            {
                                (data['spec'] === "capabilities") &&
                                <>
                                    {
                                        JsonSchemaParserForLog.getNamesFromPathAndBody(data['path'], data['body'], jsonSchema, "sensor", translationMap).map((nameObj, index) => {
                                            if (index === 0) {
                                                return (
                                                    <ViewBox
                                                        color={colorMap['device']}
                                                        style={{
                                                            marginRight: '10px'
                                                        }}
                                                    >
                                                        {nameObj['deviceName']}
                                                    </ViewBox>
                                                );
                                            } else if (index === 1) {
                                                return (
                                                    <ViewBox
                                                        color={colorMap['sensor']}
                                                        style={{
                                                            marginRight: '10px'
                                                        }}
                                                    >
                                                        {nameObj['sensorName']}
                                                    </ViewBox>
                                                );
                                            } else {
                                                return (
                                                    <ViewBox
                                                        color={colorMap['endName']}
                                                    >
                                                        {nameObj['capabilityName']}
                                                    </ViewBox>
                                                );
                                            }
                                        })
                                    }
                                </>
                            }
                            {/* device target */}
                            {
                                (data['spec'] === "attributes") &&
                                <>
                                    {
                                        JsonSchemaParserForLog.getNamesFromPathAndBody(data['path'], data['body'], jsonSchema, "device", translationMap).map((nameObj, index) => {
                                            if (index === 0) {
                                                return (
                                                    <ViewBox
                                                        color={colorMap['device']}
                                                        style={{
                                                            marginRight: '10px'
                                                        }}
                                                    >
                                                        {nameObj['deviceName']}
                                                    </ViewBox>
                                                );
                                            } else {
                                                return (
                                                    <ViewBox
                                                        color={colorMap['endName']}
                                                        style={{
                                                            marginRight: '10px'
                                                        }}
                                                    >
                                                        {nameObj['attributeName']}
                                                    </ViewBox>
                                                );
                                            }
                                        })
                                    }
                                </>
                            }
                            {/* connection target */}
                            {
                                (data['spec'] === "connection") &&
                                <>
                                    {
                                        JsonSchemaParserForLog.getNamesFromPathAndBody(data['path'], data['body'], jsonSchema, "connection", translationMap).map((nameObj, index) => {
                                            if (index === 0) {
                                                return (
                                                    <ViewBox
                                                        color={colorMap['device']}
                                                        style={{
                                                            marginRight: '10px'
                                                        }}
                                                    >
                                                        {nameObj['deviceName']}
                                                    </ViewBox>
                                                );
                                            } else {
                                                return (
                                                    <ViewBox
                                                        color={colorMap['endName']}
                                                        style={{
                                                            marginRight: '10px'
                                                        }}
                                                    >
                                                        {nameObj['attributeName']}
                                                    </ViewBox>
                                                );
                                            }
                                        })
                                    }
                                </>
                            }
                        </div>
                    </Row>
                }

                {/* operation id */}
                {

                    ((data['spec'] === "attributes") || (data['spec'] === "connection") || (data['spec'] === "capabilities")) && (data['isAdmin']) &&
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <TitleAndInputBox
                            title={t("menu.booth.detail.messageLog.item.detailInfo.operationId")}
                            value={data['operationId']}
                            onChange={() => {}}
                            isForEdit={false}
                            type={"text"}
                            isValid={true}
                            isEnabled={true}
                        />
                    </Row>
                }
                {/* user uuid */}
                {
                    ((data['spec'] === "attributes") || (data['spec'] === "connection") || (data['spec'] === "capabilities")) && (!data['isAdmin']) &&
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <TitleAndInputBox
                            title={t("menu.booth.detail.messageLog.item.detailInfo.userUuid")}
                            value={data['userId']}
                            onChange={() => {}}
                            isForEdit={false}
                            type={"text"}
                            isValid={true}
                            isEnabled={true}
                        />
                    </Row>
                }

                {/* date time */}
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndInputBox
                        title={t("menu.booth.detail.messageLog.item.cratedAt")}
                        value={
                            data['createdAt'] ?
                                DataParseService.dateTimeFormat(data['createdAt'])
                                :
                                t("menu.boothControl.sideMenus.messageHistory.message.noDateInfo")
                        }
                        onChange={()=>{}}
                        isForEdit={false}
                        type={
                            data['createdAt'] ?
                                "datetime-local"
                                :
                                "text"
                        }
                        isValid={true}
                        isEnabled={true}
                    />
                </Row>

                {/* target franchise */}
                {
                    (data['spec'] === "orders") &&
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <TitleAndInputBox
                            title={t("menu.booth.detail.messageLog.item.detailInfo.franchise")}
                            value={data['franchiseId']}
                            onChange={() => {}}
                            isForEdit={false}
                            type={"text"}
                            isValid={true}
                            isEnabled={true}
                        />
                    </Row>
                }

                {/* adaptation type */}
                {
                    (data['spec'] === "products") &&
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <TitleAndInputBox
                            title={t("menu.booth.detail.messageLog.item.detailInfo.adaptTime")}
                            value={data['adaptTime']}
                            onChange={() => {}}
                            isForEdit={false}
                            type={"text"}
                            isValid={true}
                            isEnabled={true}
                        />
                    </Row>
                }

                {/* body view */}
                {
                    data['body'] &&
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'left',
                                paddingLeft: '10px',
                                marginBottom: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#8f8f8f',
                                    fontSize: '14px'
                                }}
                            >
                                {t("menu.booth.detail.messageLog.item.detailInfo.requestBody")}
                            </span>
                        </div>
                        <div
                            className={"withScrollDiv"}
                            style={{
                                width: '100%',
                                height:
                                    (
                                        (data['spec'] === "attributes")
                                        || (data['spec'] === "capabilities")
                                        || (data['spec'] === "connection") ?
                                            "170px"
                                            :
                                            "200px"
                                    ),
                                overflow: 'auto',
                                padding: '10px',
                                border: '1px solid #ebebeb',
                                borderRadius: '5px'
                            }}
                        >
                            <JsonView
                                src={data['body']}
                                theme={"winter-is-coming"}
                                enableClipboard={false}
                                collapseStringsAfterLength={40}
                                collapseStringMode={"directly"}
                                collapsed={false}
                                editable={false}
                                displaySize={false}
                            />
                        </div>
                    </Row>
                }

            </Container>
        </Modal>
    );
};

export default MessageHistoryDetailModal;