import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import BoothMenuDiffAxiosService from "../../../common/service/BoothMenuDiffAxiosService";
import _ from "lodash";
import DataCompareService from "../../../../../../services/DataCompareService";
import RecipeUtils from "../../../../../menu/common/jsonDynamic/util/RecipeUtils";
import {toast} from "react-toastify";
import AlertViewForBoothDiff from "../../../common/component/AlertViewForBoothDiff";
import {Container, Row, Spinner} from "react-bootstrap";
import MessageView from "../../../common/component/MessageView";
import OverlayToolTip from "../../../../../menu/common/toolTip/OverlayToolTip";
import RecipeDataView from "../../../../../menu/common/jsonDynamic/component/RecipeDataView";
import DataUtils from "../../../../../menu/common/utils/DataUtils";
import DiffDataView from "../../../../../menu/branchMenuDiff/dataView/DiffDataView";
import {FiLock, FiUnlock} from "react-icons/fi";
import AuthService from "../../../../../../services/AuthService";
import ImsSystemRole from "../../../../../../auth/roles/ImsSystemRole";

const SimpleButton = styled.button`
  font-size: 14px;
  padding: 7px 10px;
  color: ${(props) => props.enabled ? "#fc7242" : "#c2c2c2"};
  border: 1px solid ${(props) => props.enabled ? "#fc7242" : "#c2c2c2"};
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  background-color: white;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.enabled ? "#ffeee6" : "white"};
  }
`;

const SimpleGreyButton = styled.button`
  font-size: 14px;
  padding: 7px 10px;
  color: ${(props) => props.enabled ? "#757575" : "#c2c2c2"};
  border: 1px solid ${(props) => props.enabled ? "#757575" : "#c2c2c2"};
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  background-color: white;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${(props) => props.enabled ? "#ebebeb" : "white"};
  }
`;

const TransButton = styled.button`
  border: none;
  background: none;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  color: #757575;
  
  &:hover {
    color: ${(props) => props.enabled ? "black" : ""}; 
  }
  
  &:focus {
    outline: none;
  }
`;

const OptionRecipeDiffViewInner = ({ boothVersionId, selectedOptionId }) => {

    const { t } = useTranslation();
    const { boothId, menuId } = useParams();

    /* states */
    const [defaultDataEntity, setDefaultDataEntity] = useState({}); /** option detail entity before merge diff */
    const [optionSchema, setOptionSchema] = useState({});
    const [diffEntity, setDiffEntity] = useState({});
    const [fixedDiffEntity, setFixedDiffEntity] = useState({});
    const [isValidMap, setIsValidMap] = useState({});
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);
    const [isRecipeDiffUsed, setIsRecipeDiffUsed] = useState(false);
    const [isRecipeDiffValid, setIsRecipeDiffValid] = useState(false);

    /* loading */
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    /* fetch product schema */
    const getProductSchema = async (newOptionInfo, optionSchemaVersion, newDetailEntity) => {
        const onSuccessCallback = async (data) => {
            await setOptionSchema(data['optionSchema']);
            await initializeStates(newOptionInfo, data['optionSchema'], newDetailEntity);
            await setIsLoading(false);
        };
        const onFailureCallback = async (err) => {
            await setOptionSchema({});
            await initializeStates(newOptionInfo, {}, newDetailEntity);
            await setIsLoading(false);
            console.log(err);
        };
        await BoothMenuDiffAxiosService.fetchOptionSchema(boothVersionId, optionSchemaVersion, onSuccessCallback, onFailureCallback);
    };

    const getOptionDiff = async (callback) => {
        await setIsLoading(true);
        let isFound = false;
        const onSuccessCallback = async (data) => {
            let newDetailEntity = {};
            let newOptionInfo = {};
            /* extract the target detail */
            let boothMenuList = data['boothMenuList'];
            boothMenuList.map(boothMenuInfo => {
                if (boothMenuInfo['menuInfo']['franchiseMenuId'] === menuId) {
                    let optionList = boothMenuInfo['optionList'];
                    optionList.map(optionInfoEntity => {
                        if (optionInfoEntity['optionInfo']['franchiseMenuOptionId'] === selectedOptionId) {
                            let targetOptionInfo = optionInfoEntity;
                            newOptionInfo = optionInfoEntity;
                            targetOptionInfo['optionDetailBeforeMergeDiff'].map(optionInfoBeforeMergeDiff => {
                                if (optionInfoBeforeMergeDiff['franchiseMenuOptionDetailBoothVersionId'] === boothVersionId) {
                                    /* target detail */
                                    newDetailEntity = optionInfoBeforeMergeDiff;
                                    isFound = true;
                                }
                            });
                        }
                    });
                }
            });
            if (isFound) {
                await setDefaultDataEntity(newDetailEntity);
                await callback(newOptionInfo, newDetailEntity['franchiseMenuOptionDetailOptionJsonVersion'], newDetailEntity);
            } else {
                await onFailureCallback({"message": "Cannot find the target option detail."});
            }
        };
        const onFailureCallback = async (err) => {
            console.log(err);
            await setDefaultDataEntity({});
            await setOptionSchema({});
            await initializeStates({}, {}, {});
            await setIsLoading(false);
        };
        await BoothMenuDiffAxiosService.fetchBoothMenuList(boothId, onSuccessCallback, onFailureCallback);
    };

    const initializeStates = async (newOptionInfo, jsonSchema) => {
        /* error-init */
        if ((_.isEqual(newOptionInfo, {})) || (_.isEqual(jsonSchema, {}))) {
            await setDiffEntity({});
            await setFixedDiffEntity({});
            await setIsValidMap({});
            await setIsValid(true);
            await setIsEdited(false);
            await setIsRecipeDiffValid(false);
            await setIsRecipeDiffUsed(false);
            return;
        }
        /* extract the diff entity */
        let newDiffEntity = {};
        let isDiffExist = false;
        newOptionInfo['optionDetailDiffList'].map(optionDetailDiffInfo => {
            if (optionDetailDiffInfo['boothMenuOptionDetailDiffBoothId'] === boothId) {
                newDiffEntity = optionDetailDiffInfo;
                isDiffExist = true;
            }
        });
        /* craft a newRequestBody, isValidMap */
        let newIsValidMap = {};
        let newRequestBody = {};
        let isRecipeDiffValidTemp = true;
        let isRecipeDiffExist = false;
        /* isValidMap */
        newIsValidMap['isEnabled'] = true;
        Object.keys(jsonSchema['spec']['attributes']).map(fieldKey => {
            newIsValidMap[fieldKey] = true;
        });
        if (isDiffExist) {
            /* isEnabled */
            newRequestBody['isEnabled'] = (newDiffEntity['isEnabled'] != null) ? newDiffEntity['isEnabled'] : null;
            /* recipe validation */
            if (newDiffEntity['boothMenuOptionDetailDiffRecipe'] != null) {
                /* check diff validity */
                isRecipeDiffValidTemp = RecipeUtils.validateJsonDataWithSchema(jsonSchema, newDiffEntity['boothMenuOptionDetailDiffRecipe']);
                isRecipeDiffExist = true;
            }
        } else {
            /* isEnabled */
            newRequestBody['isEnabled'] = null;
        }
        await setIsRecipeDiffValid(isRecipeDiffValidTemp);
        /* recipe initialization */
        let recipeDiff = null;
        if (isRecipeDiffExist) {
            if (isRecipeDiffValidTemp) {
                recipeDiff = JSON.parse(newDiffEntity['boothMenuOptionDetailDiffRecipe'])['attributes'];
                Object.keys(jsonSchema['spec']['attributes']).map(fieldKey => {
                    newRequestBody[fieldKey] = isRecipeDiffExist ? recipeDiff[fieldKey] : null;
                });
            }
            await setIsRecipeDiffUsed(true);
        } else {
            Object.keys(jsonSchema['spec']['attributes']).map(fieldKey => {
                newRequestBody[fieldKey] = null;
            });
            await setIsRecipeDiffUsed(false);
        }
        await setDiffEntity(newRequestBody);
        await setFixedDiffEntity(_.cloneDeep(newRequestBody));
        await setIsValidMap(newIsValidMap);
        await setIsValid(true);
        await setIsEdited(false);
    };

    const formatRequestBody = (prevRequestBody) => {
        let newRequestBody = {};
        let prevRequestBodyCopy = _.cloneDeep(prevRequestBody);

        newRequestBody['isEnabled'] = prevRequestBodyCopy['isEnabled'];
        delete prevRequestBodyCopy['isEnabled'];

        if (isRecipeDiffUsed) {
            let diffRecipe = {
                "attributes" : prevRequestBodyCopy
            };
            newRequestBody['franchiseMenuOptionDetailRecipe'] = JSON.stringify(diffRecipe);
        } else {
            newRequestBody['franchiseMenuOptionDetailRecipe'] = null;
        }
        return newRequestBody;
    };

    const putOptionDetailDiff = async () => {
        await setIsSaveLoading(true);
        let requestBody = formatRequestBody(diffEntity);
        const onSuccessCallback = async () => {
            toast.success(<AlertViewForBoothDiff message={t("message.saved")} />);
            await getOptionDiff(getProductSchema);
            await setIsSaveLoading(false);
        };
        const onFailureCallback = async (err) => {
            toast.error(<AlertViewForBoothDiff message={t("menu.franchiseMenu.recipeInfo.menuRecipe.message.error.saveError")} />);
            await setIsSaveLoading(false);
            console.log(err);
        };
        await BoothMenuDiffAxiosService.putBoothMenuOptionDiff(boothId, menuId, selectedOptionId, defaultDataEntity['franchiseMenuOptionDetailId'], requestBody, onSuccessCallback, onFailureCallback);
    };

    const makeRecipeDiffNull = async () => {
        let newIsValidMap = {};
        newIsValidMap['isEnabled'] = true;
        Object.keys(optionSchema['spec']['attributes']).map(fieldKey => {
            newIsValidMap[fieldKey] = true;
        });
        await setIsValidMap(newIsValidMap);
        await setDiffEntity(prevEntity => {
            let newEntity = {...prevEntity};
            Object.keys(optionSchema['spec']['attributes']).map(fieldKey => {
                newEntity[fieldKey] = null;
            });
            return newEntity;
        });
    };

    const makeRecipeDiffFull = async () => {
        let newIsValidMap = {};
        newIsValidMap['isEnabled'] = true;
        Object.keys(optionSchema['spec']['attributes']).map(fieldKey => {
            newIsValidMap[fieldKey] = true;
        });
        await setIsValidMap(newIsValidMap);
        await setDiffEntity(prevEntity => {
            let newEntity = {...prevEntity};
            Object.keys(optionSchema['spec']['attributes']).map(fieldKey => {
                newEntity[fieldKey] = defaultDataEntity['franchiseMenuOptionDetailRecipe']['attributes'][fieldKey];
            });
            return newEntity;
        });
    };

    /* effects */
    useEffect(() => {
        getOptionDiff(getProductSchema);
    }, [menuId, boothVersionId, selectedOptionId]);

    useEffect(() => {
        setIsEdited(!_.isEqual(diffEntity, fixedDiffEntity));
    }, [diffEntity]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    if (isLoading) {
        /* loading */
        return (
            <div
                style={{
                    width: '100%',
                    padding: '30px 0px',
                    textAlign: 'center'
                }}
            >
                <Spinner size={"sm"} />
            </div>
        );
    } else if ((!isLoading) && _.isEqual(defaultDataEntity, {})) {
        /* the detail does not exist : MUST NOT BE REACHED */
        return (
            <div
                style={{
                    width: '100%',
                    padding: '30px 0px',
                    textAlign: 'center'
                }}
            >
                <MessageView
                    message={t("menu.boothMenuDiff.messages.error.detailNotFound")}
                />
            </div>
        );
    } else if ((!isLoading) && (_.isEqual(optionSchema, {}) || _.isEqual(defaultDataEntity, {}))) {
        /* the option schema does not exist : MUST NOT BE REACHED */
        return (
            <div
                style={{
                    width: '100%',
                    padding: '30px 0px',
                    textAlign: 'center'
                }}
            >
                <MessageView
                    message={t("menu.boothMenuDiff.messages.error.schemaNotFound")}
                />
            </div>
        );
    } else if ((!isLoading)
        && (!_.isEqual(optionSchema, {}))
        && (!_.isEqual(defaultDataEntity, {}))
        && (!RecipeUtils.validateJsonDataWithSchema(optionSchema, JSON.stringify(defaultDataEntity['franchiseMenuOptionDetailRecipe'])))) {
        /* the default data (before merge) validation fail */
        return (
            <div
                style={{
                    width: '100%',
                    padding: '30px 0px',
                    textAlign: 'center'
                }}
            >
                <MessageView
                    message={t("menu.boothMenuDiff.messages.error.schemaNotfound")}
                />
            </div>
        );
    } else {
        return (
            <Container fluid>
                <Row>
                    <DiffDataView
                        type={"switch"}
                        hoverMessageForUseDiffButton={t("menu.boothMenuDiff.messages.diff.hoverMessage", { fieldName : t("menu.boothMenuDiff.field.isEnabled") })}
                        title={t("menu.boothMenuDiff.field.isEnabled")}
                        originalDataKey={"isEnabled"}
                        diffDataKey={"isEnabled"}
                        diffEntity={diffEntity}
                        originalData={defaultDataEntity}
                        isValidMap={isValidMap}
                        updateDiffEntity={(key, value) => DataUtils.updateHelper(setDiffEntity, key, value)}
                        updateIsValidMap={(key, value) => DataUtils.updateHelper(setIsValidMap, key, value)}
                        isForEdit={AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "PUT")}
                        booleanReversed={true}
                        booleanOnMessage={t("message.menuOptionOnThisBooth")}
                        booleanOffMessage={t("message.menuOptionOffThisBooth")}
                    />
                </Row>
                {/* recipe dynamic */}
                {/* validate the recipe with the schema needed */}
                <Row>
                    <div
                        style={{
                            display: 'inline-flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: "flex-start",
                            paddingLeft: '10px',
                            marginBottom: '5px'
                        }}
                    >
                        <span
                            style={{
                                color: '#8f8f8f',
                                fontSize: '14px'
                            }}
                        >
                            {t("menu.boothMenuDiff.field.optionRecipe")}
                        </span>
                        <div
                            style={{
                                height: '100%',
                                display: 'inline-block',
                                marginLeft: "10px"
                            }}
                        >
                            {
                                (isRecipeDiffUsed) &&
                                <OverlayToolTip
                                    direction={"top"}
                                    text={t("menu.boothMenuDiff.messages.diff.hoverMessage", { fieldName: t("menu.boothMenuDiff.field.optionRecipe") })}
                                >
                                    <TransButton
                                        onClick={async () => {
                                            if (isRecipeDiffUsed) {
                                                await makeRecipeDiffNull();
                                                await setIsRecipeDiffUsed(false);
                                            } else {
                                                await makeRecipeDiffFull();
                                                await setIsRecipeDiffUsed(true);
                                            }
                                        }}
                                    >
                                        <FiUnlock
                                            size={"14"}
                                            color={"#fc7242"}
                                        />
                                    </TransButton>
                                </OverlayToolTip>
                            }
                            {
                                (!isRecipeDiffUsed) &&
                                <TransButton
                                    onClick={async () => {
                                        if (isRecipeDiffUsed) {
                                            await makeRecipeDiffNull();
                                            await setIsRecipeDiffUsed(false);
                                        } else {
                                            await makeRecipeDiffFull();
                                            await setIsRecipeDiffUsed(true);
                                        }
                                    }}
                                    enabled={true}
                                >
                                    <FiLock
                                        size={"14"}
                                    />
                                </TransButton>
                            }
                        </div>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '405px',
                            overflow: 'auto',
                            border: '1px solid #ebebeb',
                            borderRadius: '10px',
                            padding: '10px',
                            backgroundColor: isRecipeDiffUsed ? "white" : "#F8F8F8",
                            transition: 'all ease-in-out 0.2s'
                        }}
                    >
                        {/* recipe view */}
                        {
                            (!isRecipeDiffUsed) &&
                            Object.keys(optionSchema['spec']['attributes']).map(fieldKey => {
                                return (
                                    <RecipeDataView
                                        title={optionSchema['spec']['attributes'][fieldKey][t("language.dataKey.boothControl.json.label")]}
                                        fieldKey={fieldKey}
                                        validator={optionSchema['spec']['attributes'][fieldKey]['validator']}
                                        isCategorical={optionSchema['spec']['attributes'][fieldKey]['isCategorical']}
                                        data={defaultDataEntity['franchiseMenuOptionDetailRecipe']['attributes'][fieldKey]}
                                        updateData={() => {}}
                                        isValidMap={isValidMap}
                                        updateIsValidMap={() => {}}
                                        isForEdit={false}
                                        unitOfValue={optionSchema['spec']['attributes'][fieldKey]['unitOfValue']}
                                    />
                                );
                            })
                        }
                        {
                            isRecipeDiffUsed && isRecipeDiffValid &&
                            Object.keys(optionSchema['spec']['attributes']).map(fieldKey => {
                                return (
                                    <RecipeDataView
                                        title={optionSchema['spec']['attributes'][fieldKey][t("language.dataKey.boothControl.json.label")]}
                                        fieldKey={fieldKey}
                                        validator={optionSchema['spec']['attributes'][fieldKey]['validator']}
                                        isCategorical={optionSchema['spec']['attributes'][fieldKey]['isCategorical']}
                                        data={diffEntity[fieldKey]}
                                        updateData={(value) => DataUtils.updateHelper(setDiffEntity, fieldKey, value)}
                                        isValidMap={isValidMap}
                                        updateIsValidMap={(value) => DataUtils.updateHelper(setIsValidMap, fieldKey, value)}
                                        isForEdit={AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "PUT")}
                                        unitOfValue={optionSchema['spec']['attributes'][fieldKey]['unitOfValue']}
                                    />
                                );
                            })
                        }
                        {
                            isRecipeDiffUsed && (!isRecipeDiffValid) &&
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <MessageView
                                    message={t("menu.boothMenuDiff.messages.error.validationFail")}
                                />
                                <SimpleButton
                                    enabled={true}
                                    onClick={async () => {
                                        await makeRecipeDiffFull();
                                        await setIsRecipeDiffValid(true);
                                    }}
                                >
                                    {t("menu.boothMenuDiff.button.initDiff")}
                                </SimpleButton>
                            </div>
                        }
                    </div>
                </Row>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '10px'
                        }}
                    >
                        {/* init button */}
                        <div
                            style={{
                                marginLeft : 'auto',
                                display: 'inline-flex',
                                alignItems: 'center'
                            }}
                        >
                            {/* initialize button */}
                            <SimpleGreyButton
                                enabled={isEdited}
                                onClick={() => {
                                    if (isEdited) {
                                        getOptionDiff(getProductSchema);
                                    }
                                }}
                            >
                                {t("menu.franchiseMenu.recipeInfo.menuRecipe.button.init")}
                            </SimpleGreyButton>
                        </div>
                        {/* save button */}
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                marginLeft: '10px'
                            }}>
                            {/* save button */}
                            <SimpleButton
                                enabled={isEdited && isValid && !isSaveLoading}
                                onClick={() => {
                                    if (isEdited && isValid && !isSaveLoading) {
                                        putOptionDetailDiff();
                                    }
                                }}
                            >
                                {
                                    isSaveLoading ?
                                        <Spinner size={"sm"} />
                                        :
                                        <>
                                            {t("button.save")}
                                        </>
                                }
                            </SimpleButton>
                        </div>
                    </div>
                </Row>
            </Container>
        );
    }
};

export default OptionRecipeDiffViewInner;