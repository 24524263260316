import React, {useEffect, useState} from 'react';
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import styled from "styled-components";
import FranchiseBasicInfo from "./detailInner/FranchiseBasicInfo";
import UserService from "../../services/UserService";
import axios from "axios";
import FranchiseBoothVersions from "./detailInner/FranchiseBoothVersions";
import FranchiseBranch from "./detailInner/FranchiseBranch";

const ContentContainerTop = styled.div`
  width: 100%;
  height: 300px;
  background-color: white;
  border-radius: 5px;
`;

const ContentContainerBottom = styled.div`
  width: 100%;
  height: 488px;
  background-color: white;
  border-radius: 5px;
`;

const FranchiseDetail = () => {

    /* routing variables */
    const { refreshAll } = useOutletContext();
    const { groupId, franchiseId } = useParams();

    /* states */
    const [isLoading, setIsLoading] = useState(true);
    const [franchiseInfo, setFranchiseInfo] = useState({});

    const navigate = useNavigate();

    /* refresh */
    const onRefresh = (redirectUrl) => {
        if (!(typeof redirectUrl === "undefined" || redirectUrl == null || redirectUrl === "")) {
            refreshAll(redirectUrl);
        }
        /* get information */
        fetchFranchiseInfo();
    };

    /* fetch franchise information */
    const fetchFranchiseInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise/`
                + `${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setFranchiseInfo(res.data.item['franchiseDetail']);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    /* effects */
    useEffect(() => {
        fetchFranchiseInfo();
    }, [groupId, franchiseId]);

    /* loading */
    const renderSpinner = () => {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Spinner
                    style={{
                        color: '#757575'
                    }}
                />
            </div>
        );
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <Container fluid>
                <Row>
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        {/* basic information */}
                        <ContentContainerTop>
                            {
                                isLoading ?
                                    renderSpinner()
                                    :
                                    <FranchiseBasicInfo
                                        onRefresh={onRefresh}
                                        data={franchiseInfo}
                                    />
                            }
                        </ContentContainerTop>
                    </Col>
                    <Col
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        {/* booth version information */}
                        <ContentContainerTop>
                            {
                                isLoading ?
                                    renderSpinner()
                                    :
                                    <FranchiseBoothVersions
                                        onRefresh={onRefresh}
                                        dataList={franchiseInfo['franchiseBoothVersionList']}
                                    />
                            }
                        </ContentContainerTop>
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    {/* branch information */}
                    <ContentContainerBottom>
                        {
                            isLoading ?
                                renderSpinner()
                                :
                                <FranchiseBranch
                                    onRefresh={onRefresh}
                                    dataList={franchiseInfo['franchiseBranchList']}
                                />
                        }
                    </ContentContainerBottom>
                </Row>
            </Container>
        </div>
    );
};

export default FranchiseDetail;