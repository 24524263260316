import React, {useEffect, useRef} from 'react';

const UseInterval = (callback, delay) => {

    /* closure Ref. */
    const savedCallback = useRef();

    /* when callback function changes, change ref value. */
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    /* interval and clear setting. */
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            /* instantly clearing for no memory leak. */
            return () => clearInterval(id);
        }
    }, [delay]);

};

export default UseInterval;