import React, {useEffect, useState} from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DataCompareService from "../../services/DataCompareService";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import {Link, useParams, useNavigate} from "react-router-dom";
import axios from "axios";
import RenderOnRole from "../../auth/RenderOnRole";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import StaffRoleEdit from "./adminRole/StaffRoleEdit";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../services/DeleteService";

const requestBodySchema = {
    "adminName": {
        "type": "string",
        "minLength": 2,
        "maxLength": 100,
        "pattern": "^[가-힣]{1}[가-힣\\s]{0,15}[가-힣]{1}$|^[a-zA-Z]{1}[a-zA-Z\\s]{0,98}[a-zA-Z]{1}$"
    },
    "adminEmail": {
        "type": "string",
        "minLength": 6,
        "maxLength": 40,
        "pattern": "^[0-9a-zA-Z-_]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\\.[a-zA-Z]+$"
    },
    "adminPhone": {
        "type" : "string"
    },
    "adminDepartmentId": {
        "type" : "string"
    },
    "adminGroupId": {
        "type" : "string"
    },
    "adminCompanyId": {
        "type" : "string"
    }
}

const requestBodyInit = {
    adminName : "",
    adminEmail : "",
    adminPhone : "",
    adminDepartmentId : "",
    adminGroupId : "",
    adminCompanyId : ""
}

const isValidMapInit = {
    adminName : true,
    adminEmail : true,
    adminPhone : true,
    adminDepartmentId : true,
    adminGroupId : true,
    adminCompanyId : true
}

const StaffEdit = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { adminId } = useParams("adminId");

    const [adminTotalInfo, setAdminTotalInfo] = useState({});
    const [prevAdminInfo, setPrevAdminInfo] = useState({});
    const [afterAdminInfo, setAfterAdminInfo] = useState({});

    /* setting options */
    const [groupList, setGroupList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);

    /* for deps variables */
    const [groupId, setGroupId] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [departmentId, setDepartmentId] = useState("");

    /* trackers */
    const [isValidMap, setIsValidMap] = useState({...isValidMapInit});
    const [isEdited, setIsEdited] = useState(false);
    const [isValid, setIsValid] = useState(true);

    /* auth edit modal */
    const [isAuthEditModalOpen, setIsAuthEditModalOpen] = useState(false);

    /* delete confirmation */
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    const fetchAdminInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${adminId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setGroupId(res.data.item.adminInfo.adminGroupId);
            setCompanyId(res.data.item.adminInfo.adminCompanyId);
            setDepartmentId(res.data.item.adminInfo.adminDepartmentId);
            setAdminTotalInfo(res.data.item.adminInfo);
            let adminData = {};
            adminData["adminName"] = res.data.item.adminInfo.adminName;
            adminData["adminEmail"] = res.data.item.adminInfo.adminEmail;
            adminData["adminPhone"] = res.data.item.adminInfo.adminPhone;
            adminData["adminDepartmentId"] = res.data.item.adminInfo.adminDepartmentId;
            adminData["adminGroupId"] = res.data.item.adminInfo.adminGroupId;
            adminData["adminCompanyId"] = res.data.item.adminInfo.adminCompanyId;
            adminData["adminImageUrl"] = res.data.item.adminInfo.adminImageUrl;
            setPrevAdminInfo(DataCompareService.deepCopyObj(adminData));
            setAfterAdminInfo(DataCompareService.deepCopyObj(adminData));
        } catch (e) {
            console.log(e);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    }

    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setGroupList(res.data.item.groupList);
        } catch (e) {
            console.log(e);
        }
    }

    const fetchCompanyList = async () => {
        if (groupId === "") {
            return;
        }
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group/${groupId}/company`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setCompanyId(prevValue => {
                for (let i in res.data.item.companyList) {
                    if (res.data.item.companyList[i].companyId === prevValue) {
                        return prevValue;
                    }
                }
                return res.data.item.companyList[0].companyId;
            });
            setCompanyList(res.data.item.companyList);
        } catch (e) {
            console.log(e);
        }
    }

    const fetchDepartmentList = async () => {
        if (companyId === "") {
            return;
        }
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company/${companyId}/department`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setDepartmentId(prevValue => {
                for (let i in res.data.item.departmentList) {
                    if (res.data.item.departmentList[i].adminDepartmentId === prevValue) {
                        return prevValue;
                    }
                }
                return res.data.item.departmentList[0].adminDepartmentId;
            });
            setDepartmentList(res.data.item.departmentList);
        } catch (e) {
            console.log(e);
        }
    }

    /* check validation */
    const checkEmailValidity = async () => {
        try {
            const isValidForm = DataCompareService.regexValidator(afterAdminInfo["adminEmail"], "^[0-9a-zA-Z-_]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\\.[a-zA-Z]+$") &&
                (afterAdminInfo["adminEmail"].length <= 40) &&
                (afterAdminInfo["adminEmail"].length >= 6);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/check/email/duplicate?target=${afterAdminInfo["adminEmail"]}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            const isDuplicate = res.data.item.result;
            if (afterAdminInfo["adminEmail"] === prevAdminInfo["adminEmail"]) {
                setIsValidMap(prevObj => {
                    let newObj = {...prevObj};
                    newObj["adminEmail"] = isValidForm;
                    return newObj;
                });
            } else {
                setIsValidMap(prevObj => {
                    let newObj = {...prevObj};
                    newObj["adminEmail"] = isValidForm && (!isDuplicate);
                    return newObj;
                });
            }
        } catch (e) {
            console.log(e);
            setIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj["adminEmail"] = false;
                return newObj;
            });
        }
    }

    const checkPhoneValidity = async () => {
        try {
            const isValidForm = true; // no form
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/check/phone/duplicate?target=${afterAdminInfo["adminPhone"]}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            const isDuplicate = res.data.item.result;
            if (afterAdminInfo["adminPhone"] === prevAdminInfo["adminPhone"]) {
                setIsValidMap(prevObj => {
                    let newObj = {...prevObj};
                    newObj["adminPhone"] = isValidForm;
                    return newObj;
                });
            } else {
                setIsValidMap(prevObj => {
                    let newObj = {...prevObj};
                    newObj["adminPhone"] = isValidForm && (!isDuplicate);
                    return newObj;
                });
            }
        } catch (e) {
            console.log(e);
            setIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj["adminPhone"] = false;
                return newObj;
            });
        }
    }

    const checkNameValidity = async () => {
        try {
            const isValidForm = DataCompareService.regexValidator(afterAdminInfo["adminName"], "^[가-힣]{1}[가-힣\\s]{0,15}[가-힣]{1}$|^[a-zA-Z]{1}[a-zA-Z\\s]{0,98}[a-zA-Z]{1}$") &&
                (afterAdminInfo["adminName"].length <= 100) &&
                (afterAdminInfo["adminName"].length >= 2);;

            setIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj["adminName"] = isValidForm;
                return newObj;
            });
        } catch (e) {
            console.log(e);
            setIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj["adminName"] = false;
                return newObj;
            });
        }
    }

    /* handle value changes */
    const handleGroupIdChange = (value) => {
        setGroupId(value);
    }

    const handleCompanyIdChange = (value) => {
        setCompanyId(value);
    }

    const handleDepartmentIdChange = (value) => {
        setDepartmentId(value);
    }

    const handleEmailInput = (value) => {
        setAfterAdminInfo(prevObj => {
            let newObj = {...prevObj};
            newObj["adminEmail"] = value;
            return newObj;
        });
    }

    const handlePhoneInput = (value) => {
        setAfterAdminInfo(prevObj => {
            let newObj = {...prevObj};
            newObj["adminPhone"] = value;
            return newObj;
        });
    }

    const handleNameInput = (value) => {
        setAfterAdminInfo(prevObj => {
            let newObj = {...prevObj};
            newObj["adminName"] = value;
            return newObj;
        });
    }

    const putAdminInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${adminId}`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl ,
                JSON.stringify(afterAdminInfo),
                {
                    headers: {
                        "Authorization" : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate(`/staff/detail/${adminId}`);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    /* -------------------- */
    /* useEffects */
    /* -------------------- */

    useEffect(() => {
        fetchAdminInfo();
        fetchGroupList();
    }, []);

    useEffect(() => {
        if (groupId === "") {
            return;
        } else {
            setAfterAdminInfo(prevObj => {
                let newObj = {...prevObj};
                newObj["adminGroupId"] = groupId;
                return newObj;
            });
            /* refresh company list */
            fetchCompanyList();
        }
    }, [groupId]);

    useEffect(() => {
        if (companyId === "") {
            return;
        } else {
            setAfterAdminInfo(prevObj => {
                let newObj = {...prevObj};
                newObj["adminCompanyId"] = companyId;
                return newObj;
            });
            /* refresh department list */
            fetchDepartmentList();
        }
    }, [companyId]);

    useEffect(() => {
        if (departmentId === "") {
            return;
        } else {
            setAfterAdminInfo(prevObj => {
                let newObj = {...prevObj};
                newObj["adminDepartmentId"] = departmentId;
                return newObj;
            });
        }
    }, [departmentId]);

    useEffect(() => {
        setIsEdited(!DataCompareService.isSameObj(prevAdminInfo, afterAdminInfo));
    }, [afterAdminInfo]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        if (typeof afterAdminInfo["adminEmail"] === "undefined") {
            return;
        }
        checkEmailValidity();
    }, [afterAdminInfo["adminEmail"]]);

    useEffect(() => {
        if (typeof afterAdminInfo["adminPhone"] === "undefined") {
            return;
        }
        checkPhoneValidity();
    }, [afterAdminInfo["adminPhone"]]);

    useEffect(() => {
        if (typeof afterAdminInfo["adminName"] === "undefined") {
            return;
        }
        checkNameValidity();
    }, [afterAdminInfo["adminName"]]);

    return (
        <RenderOnRole type={"PUT"} roleName={ImsSystemRole.STAFF_MANAGEMENT} allowedRing={2} nothingOnRejected={false} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={`/staff/detail/${adminId}`}
                />
                <PageTitleWithDepth
                    titles={[t("menu.staff.title"), t("menu.staff.detail.title"), t("menu.staff.edit.title")]}
                    depth={3}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                    <Container fluid>
                        <Row>
                            {/* main area */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '700px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                {/* top info */}
                                <div
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'left'
                                        }}
                                    >
                                        <div>
                                            <Image
                                                src={
                                                    (prevAdminInfo.adminImageUrl == null) ?
                                                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                        :
                                                        `${prevAdminInfo.adminImageUrl}`
                                                }
                                                alt="Profile Image"
                                                roundedCircle
                                                style={{ width: '70px', height: '70px', display: 'inline-block', border: '0'}}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: '20px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <div
                                                style={{ textAlign: 'left' }}
                                            >
                                                <span
                                                    style={{ fontWeight: 'bold' }}
                                                >
                                                    {prevAdminInfo.adminName}
                                                </span>
                                            </div>
                                            <div
                                                style={{ textAlign: 'left' }}
                                            >
                                                <button
                                                    style={{
                                                        background: 'none',
                                                        border: 'none',
                                                        padding: '0px',
                                                        textDecoration: 'underline',
                                                        fontSize: '12px',
                                                        color: '#656565'
                                                    }}
                                                    onClick={() => setIsAuthEditModalOpen(true)}
                                                >
                                                    {t("button.editAuth")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* middle info */}
                                <div
                                    style={{
                                        borderTop: '1px solid #c2c2c2',
                                        width: '100%',
                                        height: '450px',
                                        display: 'flex',
                                        overflow: 'auto',
                                        padding: '20px 0px 20px 0px'
                                    }}
                                >
                                    <Container fluid>
                                        <Row>
                                            <TitleAndInputBox
                                                title={t("common.email")}
                                                value={afterAdminInfo.adminEmail}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["adminEmail"]}
                                                onChange={handleEmailInput}
                                            />
                                        </Row>
                                        <Row
                                            style={{ marginTop: '10px' }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.name")}
                                                value={afterAdminInfo.adminName}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["adminName"]}
                                                onChange={handleNameInput}
                                            />
                                        </Row>
                                        <Row
                                            style={{ marginTop: '10px' }}
                                        >
                                            <Col
                                                md={"1"}
                                                style={{ padding: '0px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.phone")}
                                                    value={"+82"}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    onChange={() => {}}
                                                />
                                            </Col>
                                            <Col
                                                md={"11"}
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={"　"}
                                                    value={afterAdminInfo.adminPhone}
                                                    isForEdit={true}
                                                    type={"text"}
                                                    isValid={isValidMap["adminPhone"]}
                                                    onChange={handlePhoneInput}
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{ marginTop: '10px' }}
                                        >
                                            <Col
                                                style={{ padding: '0px' }}
                                            >
                                                <TitleAndSelectBox
                                                    title={t("common.group")}
                                                    innerValue={afterAdminInfo.adminGroupId}
                                                    valueList={groupList}
                                                    valueSelector={"groupId"}
                                                    viewSelector={"groupName"}
                                                    onChange={handleGroupIdChange}
                                                />
                                            </Col>
                                            <Col
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndSelectBox
                                                    title={t("common.company")}
                                                    innerValue={afterAdminInfo.adminCompanyId}
                                                    valueList={companyList}
                                                    valueSelector={"companyId"}
                                                    viewSelector={"companyName"}
                                                    onChange={handleCompanyIdChange}
                                                />
                                            </Col>
                                            <Col
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndSelectBox
                                                    title={t("common.department")}
                                                    innerValue={afterAdminInfo.adminDepartmentId}
                                                    valueList={departmentList}
                                                    valueSelector={"adminDepartmentId"}
                                                    viewSelector={"adminDepartmentName"}
                                                    onChange={handleDepartmentIdChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                {/* bottom btn */}
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start'
                                    }}
                                >
                                    <div>
                                        <Link
                                            id={"reset-password-btn"}
                                            onClick={() => setIsDeleteConfirmOpen(true)}
                                        >
                                            {t("button.delete")}
                                        </Link>
                                        {/* delete confirmation */}
                                        <DeleteConfirmModal
                                            isOpen={isDeleteConfirmOpen}
                                            setIsOpen={setIsDeleteConfirmOpen}
                                            isDelayed={true}
                                            confirmMessage={t("language.message.deletion.confirmation", { entityName : prevAdminInfo.adminName })}
                                            entity={DeleteService.ValidEntities.ADMIN}
                                            entityName={prevAdminInfo.adminName}
                                            id={`admin/${adminId}`}
                                            onDeleteSuccess={async () => await navigate('/staff')}
                                            depsToShow={[t("language.message.deletion.depsList.admin.schedule")]}
                                            validateMessage={t("language.message.deletion.verificationMessage", { entityName : prevAdminInfo.adminName })}
                                            delayToUndo={DeleteService.getLongDelay()}
                                            onUndoSuccess={async () => {
                                                await navigate(`/staff/detail/${adminId}`);
                                            }}
                                        />
                                    </div>
                                    {/* delete button */}
                                    <div
                                        style={{
                                            marginLeft: 'auto',
                                            marginRight: '10px'
                                        }}
                                    >
                                        <Link
                                            id={"edit-btn"}
                                            to={`/staff/detail/${adminId}`}
                                        >
                                            {t("button.cancel")}
                                        </Link>
                                    </div>
                                    <div>
                                        {
                                            (isEdited && isValid)
                                            ?
                                                <Link
                                                    id={"save-btn"}
                                                    onClick={() => putAdminInfo()}
                                                >
                                                    {t("button.save")}
                                                </Link>
                                                :
                                                <Link
                                                    id={"save-btn-disabled"}
                                                >
                                                    {t("button.save")}
                                                </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
            {
                (typeof prevAdminInfo.adminCompanyId !== "undefined") ?
                    <StaffRoleEdit
                        isOpen={isAuthEditModalOpen}
                        setIsOpen={setIsAuthEditModalOpen}
                        adminId={adminId}
                        companyId={prevAdminInfo.adminCompanyId}
                    />
                :
                    <></>
            }
        </RenderOnRole>
    );
};

export default StaffEdit;