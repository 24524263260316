import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import AuthService from "../../services/AuthService";
import NotAllowed from "../../auth/NotAllowed";
import axios from "axios";
import {useTranslation} from "react-i18next";
import DataCompareService from "../../services/DataCompareService";
import AuthParseService from "../../services/AuthParseService";
import { FaCaretSquareRight, FaCaretSquareLeft } from "react-icons/fa";
import uuid from "react-uuid";
import UserService from '../../services/UserService';
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import RenderOnRole from "../../auth/RenderOnRole";

const CompanySelected = () => {

    const { t, i18n } = useTranslation();
    const { companyId } = useParams();

    /* roleLists */
    const [prevRoleList, setPrevRoleList] = useState([]);
    const [currentRoleList, setCurrentRoleList] = useState([]);
    const [availableRoleList, setAvailableRoleList] = useState([]);
    const [totalGroupRoleList, setTotalGroupRoleList] = useState([]);
    /* selected index */
    const [selectedCurrentRoleIndex, setSelectedCurrentRoleIndex] = useState(-1);
    const [selectedAvailableRoleIndex, setSelectedAvailableRoleIndex] = useState(-1);
    /* isReady */
    const [isAssignReady, setIsAssignReady] = useState(false);
    const [isRevokeReady, setIsRevokeReady] = useState(false);
    /* isEdited */
    const [isEdited, setIsEdited] = useState(false);

    const navigate = useNavigate();

    /* utils */
    const getObjectById = (list, id) => {
        return list.find(item => item.imsSystemRoleId === id);
    }

    /* fetch roleLists */
    const fetchCurrentRoleList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/role/company/${companyId}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data['error_code'] === "GET_EMPTY_DATA") {
                setCurrentRoleList([]);
                setPrevRoleList([]);
            } else {
                setCurrentRoleList(res.data.item.roleList);
                setPrevRoleList(DataCompareService.deepCopyList(res.data.item.roleList));
            }
        } catch (e) {
            console.log(e);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    }

    const fetchAvailableRoleList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/role/company/${companyId}/available`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            setAvailableRoleList(res.data.item.currentRoleList);
            setTotalGroupRoleList(res.data.item.totalRoleList);
        } catch (e) {
            console.log(e);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    }

    /* handlers */
    const handleRoleAccessCodeChange = (roleIndex, code) => {
        setCurrentRoleList(prevRoleList => {
            let newRoleList = [...prevRoleList];
            newRoleList[roleIndex].accessCode = code;
            return newRoleList;
        });
    }

    const handleReset = () => {
        fetchCurrentRoleList();
        fetchAvailableRoleList();
        setSelectedAvailableRoleIndex(-1);
        setSelectedCurrentRoleIndex(-1);
    }

    const handleAssign = () => {
        if (!isAssignReady) {
            return;
        }
        const targetRole = availableRoleList[selectedAvailableRoleIndex];
        setAvailableRoleList(prevRoleList => {
            let newRoleList = [...prevRoleList];
            newRoleList.splice(selectedAvailableRoleIndex, 1);
            return newRoleList;
        });
        setCurrentRoleList(prevRoleList => {
            let newRoleList = [...prevRoleList];
            newRoleList.push(targetRole);
            return newRoleList;
        })
        setSelectedAvailableRoleIndex(-1);
        setSelectedCurrentRoleIndex(-1);
    }

    const handleRevoke = () => {
        if (!isRevokeReady) {
            return;
        }
        const targetRole = currentRoleList[selectedCurrentRoleIndex];
        setCurrentRoleList(prevRoleList => {
            let newRoleList = [...prevRoleList];
            newRoleList.splice(selectedCurrentRoleIndex, 1);
            return newRoleList;
        });
        setAvailableRoleList(prevRoleList => {
            let newRoleList = [...prevRoleList];
            newRoleList.push(targetRole);
            return newRoleList;
        })
        setSelectedAvailableRoleIndex(-1);
        setSelectedCurrentRoleIndex(-1);
    }

    const filterCurrentRoleList = (roleList) => {
        return roleList.map(item => ({
            imsSystemRoleId: item.imsSystemRoleId,
            accessCode: item.accessCode
        }));
    }

    const putCompanyRole = async () => {
        try {
            await setIsEdited(false);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/role/company/${companyId}`;
            let requestBody = filterCurrentRoleList(currentRoleList);
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        "Authorization" : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            fetchCurrentRoleList();
            fetchAvailableRoleList();
            setSelectedAvailableRoleIndex(-1);
            setSelectedCurrentRoleIndex(-1);
        } catch (e) {
            await setIsEdited(true);
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
            fetchCurrentRoleList();
            fetchAvailableRoleList();
            setSelectedAvailableRoleIndex(-1);
            setSelectedCurrentRoleIndex(-1);
        }
    }

    /* effects */
    /* refresh when selected company changes */
    useEffect(() => {
        fetchCurrentRoleList();
        fetchAvailableRoleList();
        setSelectedAvailableRoleIndex(-1);
        setSelectedCurrentRoleIndex(-1);
    }, [companyId]);

    /* select tracker */
    useEffect(() => {
        if (selectedCurrentRoleIndex >= 0 ) {
            setIsRevokeReady(true);
        } else {
            setIsRevokeReady(false);
        }
    }, [selectedCurrentRoleIndex]);

    useEffect(() => {
        if (selectedAvailableRoleIndex >= 0 ) {
            setIsAssignReady(true);
        } else {
            setIsAssignReady(false);
        }
    }, [selectedAvailableRoleIndex]);

    /* list edit tracker */
    useEffect(() => {
       setIsEdited(!DataCompareService.isSameList(currentRoleList, prevRoleList));
    }, [currentRoleList]);

    /* Renderers */
    const renderAvailableRoles = () => {
        return availableRoleList.map((role, index) => {
            return (
                <div
                    style={{
                        marginTop: '10px',
                        textAlign: 'left'
                    }}
                    key={uuid()}
                >
                    <button
                        className={"roleButton"}
                        style={
                        (selectedAvailableRoleIndex === index) ?
                            {
                                backgroundColor: '#fcf9ed',
                                borderColor: '#fc7242',
                                color: '#fc7242'
                            }
                            :
                            {}
                        }
                        onClick={() => {
                            if (index !== selectedAvailableRoleIndex) {
                                setSelectedAvailableRoleIndex(index);
                            } else {
                                setSelectedAvailableRoleIndex(-1);
                            }
                        }}
                    >
                        {role[t("language.dataKey.role.label")]}
                    </button>
                </div>
            );
        });
    }

    const renderAssignedRoles = () => {
        return currentRoleList.map((role, index) => {
            return (
                <div
                    style={{
                        marginTop: '10px',
                        textAlign: 'left'
                    }}
                    key={uuid()}
                >
                    <button
                        className={"roleButton"}
                        style={
                            (selectedCurrentRoleIndex === index) ?
                                {
                                    backgroundColor: '#fcf9ed',
                                    borderColor: '#fc7242',
                                    color: '#fc7242'
                                }
                                :
                                {}
                        }
                        onClick={() => {
                            if (index !== selectedCurrentRoleIndex) {
                                setSelectedCurrentRoleIndex(index);
                            } else {
                                setSelectedCurrentRoleIndex(-1);
                            }
                        }}
                    >
                        {role[t("language.dataKey.role.label")]}
                    </button>
                </div>
            );
        });
    }

    const renderRoleSpec = () => {
        if (selectedCurrentRoleIndex === -1) {
            return <></>
        } else {
            return (
                <div
                    style={{
                        padding: '0px 10px 0px 10px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            marginTop: '15px'
                        }}
                    >
                        <span
                            className={"roleSpec-title"}
                        >
                            {t("menu.companyRole.roleName")}
                        </span>
                    </div>
                    <div>
                        <input
                            className={"roleSpec-input"}
                            readOnly={true}
                            value={currentRoleList[selectedCurrentRoleIndex][t("language.dataKey.role.label")]}
                        />
                    </div>
                    <div
                        style={{
                            textAlign: 'left',
                            marginTop: '15px'
                        }}
                    >
                        <span
                            className={"roleSpec-title"}
                        >
                            {t("menu.companyRole.roleDescription")}
                        </span>
                    </div>
                    <div>
                        <textarea
                            className={"roleSpec-textarea"}
                            readOnly={true}
                            value={currentRoleList[selectedCurrentRoleIndex].description}
                        />
                    </div>
                    <div
                        style={{
                            textAlign: 'left',
                            marginTop: '15px'
                        }}
                    >
                        <span
                            className={"roleSpec-title"}
                        >
                            {t("menu.companyRole.accessCode")}
                        </span>
                    </div>
                    <div
                        style={{
                            textAlign: 'left',
                            padding: '10px',
                            border: '1px solid #757575',
                            borderRadius: '5px'
                        }}
                    >
                        {/* GET */}
                        <div>
                            {
                                AuthParseService.hasGetCode(getObjectById(totalGroupRoleList, currentRoleList[selectedCurrentRoleIndex].imsSystemRoleId).accessCode)
                                    ?
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={AuthParseService.hasGetCode(currentRoleList[selectedCurrentRoleIndex].accessCode)}
                                            onChange={() => {
                                                if (AuthParseService.hasGetCode(currentRoleList[selectedCurrentRoleIndex].accessCode)) {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.deleteGetCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                } else {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.addGetCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                }
                                            }}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle"}
                                        >
                                            {t("menu.companyRole.sort.get")}
                                        </span>
                                    </>
                                    :
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={false}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle-disabled"}
                                        >
                                            {t("menu.companyRole.sort.get")}
                                        </span>
                                    </>
                            }
                        </div>
                        {/* PUT */}
                        <div>
                            {
                                AuthParseService.hasPutCode(getObjectById(totalGroupRoleList, currentRoleList[selectedCurrentRoleIndex].imsSystemRoleId).accessCode)
                                    ?
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={AuthParseService.hasPutCode(currentRoleList[selectedCurrentRoleIndex].accessCode)}
                                            onChange={() => {
                                                if (AuthParseService.hasPutCode(currentRoleList[selectedCurrentRoleIndex].accessCode)) {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.deletePutCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                } else {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.addPutCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                }
                                            }}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle"}
                                        >
                                            {t("menu.companyRole.sort.put")}
                                        </span>
                                    </>
                                    :
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={false}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle-disabled"}
                                        >
                                            {t("menu.companyRole.sort.put")}
                                        </span>
                                    </>
                            }
                        </div>
                        {/* POST */}
                        <div>
                            {
                                AuthParseService.hasPostCode(getObjectById(totalGroupRoleList, currentRoleList[selectedCurrentRoleIndex].imsSystemRoleId).accessCode)
                                    ?
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={AuthParseService.hasPostCode(currentRoleList[selectedCurrentRoleIndex].accessCode)}
                                            onChange={() => {
                                                if (AuthParseService.hasPostCode(currentRoleList[selectedCurrentRoleIndex].accessCode)) {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.deletePostCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                } else {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.addPostCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                }
                                            }}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle"}
                                        >
                                            {t("menu.companyRole.sort.post")}
                                        </span>
                                    </>
                                    :
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={false}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle-disabled"}
                                        >
                                            {t("menu.companyRole.sort.post")}
                                        </span>
                                    </>
                            }
                        </div>
                        {/* DELETE */}
                        <div>
                            {
                                AuthParseService.hasDeleteCode(getObjectById(totalGroupRoleList, currentRoleList[selectedCurrentRoleIndex].imsSystemRoleId).accessCode)
                                    ?
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={AuthParseService.hasDeleteCode(currentRoleList[selectedCurrentRoleIndex].accessCode)}
                                            onChange={() => {
                                                if (AuthParseService.hasDeleteCode(currentRoleList[selectedCurrentRoleIndex].accessCode)) {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.deleteDeleteCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                } else {
                                                    handleRoleAccessCodeChange(selectedCurrentRoleIndex, AuthParseService.addDeleteCode(currentRoleList[selectedCurrentRoleIndex].accessCode))
                                                }
                                            }}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle"}
                                        >
                                            {t("menu.companyRole.sort.delete")}
                                        </span>
                                    </>
                                    :
                                    <>
                                        <input
                                            type={"checkbox"}
                                            checked={false}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                        <span
                                            className={"roleSpec-checkBoxTitle-disabled"}
                                        >
                                            {t("menu.companyRole.sort.delete")}
                                        </span>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <RenderOnRole roleName={""} type={""} allowedRing={1} isForSuperMaster={false}>
            <div
                style={{
                    width: '100%',
                    height: '90%',
                    display: 'flex'
                }}
            >
                <div
                    style={{
                        height: '100%',
                        width: '30%',
                        borderRadius: '10px',
                        border: '1px solid #c2c2c2',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '50px',
                            textAlign: 'left',
                            padding: '15px',
                            borderBottom: '1px solid #c2c2c2'
                        }}
                    >
                        <span
                            style={{
                                color: '#757575'
                            }}
                        >
                            {t("menu.companyRole.text.unassignedRoles")}
                        </span>
                    </div>
                    <div
                        style={{
                            padding: '10px'
                        }}
                    >
                        {renderAvailableRoles()}
                    </div>
                </div>
                <div
                    style={{
                        height: '100%',
                        width: '10%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <div
                        style={{
                            marginBottom: '10px'
                        }}
                    >
                        <button
                            style={{
                                outline: 'none',
                                background: 'none',
                                border: 'none',
                                padding: '0px',
                                margin: '0px'
                            }}
                            onClick={() => handleAssign()}
                        >
                            <FaCaretSquareRight
                                size={"40"}
                                style={isAssignReady ? { color: '#fc7242' } : { color: '#ebebeb' }}
                            />
                        </button>
                    </div>
                    <div
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <button
                            style={{
                                outline: 'none',
                                background: 'none',
                                border: 'none',
                                padding: '0px',
                                margin: '0px'
                            }}
                            onClick={() => handleRevoke()}
                        >
                            <FaCaretSquareLeft
                                size={"40"}
                                style={isRevokeReady ? { color: '#fc7242' } : { color: '#ebebeb' }}
                            />
                        </button>
                    </div>
                </div>
                <div
                    style={{
                        height: '100%',
                        width: '60%',
                        borderRadius: '10px',
                        border: '1px solid #c2c2c2',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <div
                        style={{
                            width: '50%',
                            height: '100%',
                            borderRight: '1px dashed #c2c2c2'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '50px',
                                textAlign: 'left',
                                padding: '15px',
                                borderBottom: '1px solid #c2c2c2'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575'
                                }}
                            >
                                {t("menu.companyRole.text.assignedRoles")}
                            </span>
                        </div>
                        <div
                            style={{
                                padding: '10px'
                            }}
                        >
                            {renderAssignedRoles()}
                        </div>
                    </div>
                    <div
                        style={{
                            width: '50%',
                            height: '100%'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '50px',
                                textAlign: 'left',
                                padding: '15px',
                                borderBottom: '1px solid #c2c2c2'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575'
                                }}
                            >
                                {t("menu.companyRole.text.roleSpecification")}
                            </span>
                        </div>
                        <div>
                            {renderRoleSpec()}
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: '100%',
                    height: '10%',
                    textAlign: 'right',
                    padding: '10px'
                }}
            >
                {
                    isEdited ?
                        <button
                            id={"reset-btn"}
                            onClick={() => handleReset()}
                        >
                            {t("button.reset")}
                        </button>
                        :
                        <button
                            id={"reset-btn-disabled"}
                        >
                            {t("button.reset")}
                        </button>
                }
                {
                    isEdited ?
                        <button
                            style={{ marginLeft: '10px' }}
                            id={"save-btn"}
                            onClick={() => putCompanyRole()}
                        >
                            {t("button.save")}
                        </button>
                        :
                        <button
                            id={"save-btn-disabled"}
                            style={{ marginLeft: '10px' }}
                        >
                            {t("button.save")}
                        </button>
                }
            </div>
        </RenderOnRole>
    );
};

export default CompanySelected;