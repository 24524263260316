import React from 'react';
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import TitleAndInputBox from "../../../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../../../common/dataView/TitleAndTextArea";
import DataParseService from "../../../../../services/DataParseService";
import ModalCloseButton from "../../../../common/modal/ModalCloseButton";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '650px',
        margin: 'auto'
    }
};

const WarrantyViewModal = ({ isOpen, setIsOpen, warrantyInfo }) => {

    const { t, i18n } = useTranslation();

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.booth.detail.warrantyView.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <TitleAndInputBox
                        title={t("menu.booth.detail.warrantyView.name")}
                        value={warrantyInfo[t("language.dataKey.warranty.name")]}
                        onChange={() => {}}
                        isForEdit={false}
                        type={"text"}
                        isValid={true}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndTextArea
                        title={t("menu.booth.detail.warrantyView.content")}
                        value={warrantyInfo[t("language.dataKey.warranty.description")]}
                        onChange={() => {}}
                        isForEdit={false}
                        isValid={true}
                        height={"180"}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndInputBox
                        title={t("menu.booth.detail.warrantyView.group")}
                        value={warrantyInfo['providerGroupName']}
                        onChange={() => {}}
                        isForEdit={false}
                        type={"text"}
                        isValid={true}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndInputBox
                        title={t("menu.booth.detail.warrantyView.generateDate")}
                        value={DataParseService.dateTimeToDate(warrantyInfo['generateDate'])}
                        onChange={() => {}}
                        isForEdit={false}
                        type={"date"}
                        isValid={true}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndInputBox
                        title={t("menu.booth.detail.warrantyView.lastEditDate")}
                        value={DataParseService.dateTimeToDate(warrantyInfo['lastEditDate'])}
                        onChange={() => {}}
                        isForEdit={false}
                        type={"date"}
                        isValid={true}
                        isEnabled={true}
                    />
                </Row>
            </Container>
        </Modal>
    );
};

export default WarrantyViewModal;