import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import ModalCloseButton from "../common/modal/ModalCloseButton";
import {useTranslation} from "react-i18next";
import { FiAlertCircle } from "react-icons/fi";
import DataParseService from "../../services/DataParseService";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import UserService from "../../services/UserService";

const PreviewContainer = styled.div`
  position: absolute;
  right: -110px;
  top: 42px;
  width: 450px;
  height: 530px;
  padding: 10px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.38);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.38);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.38);
  z-index: 9998;
`;

const AlertContainer = styled.div`
  width: 100%;
  height: 430px;
  margin-top: 10px;
  overflow: auto;
  padding: 5px;
`;

const AllReadButton = styled.button`
  padding: 3px 15px;
  border: 1px solid #757575;
  color: #757575;
  font-size: 14px;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  background-color: white;
  
  &:hover {
    background-color: #ebebeb;
  }
  
  &:focus {
    outline: none;
  }
`;

const MoreButton = styled.button`
  border: none;
  color: #757575;
  font-size: 14px;
  transition: all ease-in-out 0.2s;
  text-decoration: underline;
  background-color: white;

  &:hover {
    color: #696969;
  }

  &:focus {
    outline: none;
  }
`;

const AlertElement = styled.div`
  width: 100%;
  height: 70px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  transition: all ease-in-out 0.2s;
  color: #fc7242;
  
  &:hover {
    -webkit-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    -moz-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    color: #fc7242;
  }
`;

const AlertIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const AlertTitleAndContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const AlertTitleAndContentInner = styled.div`
  text-align: left;
`;

const GenerateDate = styled.span`
  font-size: 13px;
  color: #757575;
`;

const AlertTitle = styled.span`
  font-size: 14px;
  color: #696969;
`;

const AlertContent = styled.div`
  font-size: 13px;
  color: #c2c2c2;
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 30%;
`;

const Message = styled.span`
  color: #757575;
  font-size: 14px;
`;

const AlertPreview = ({ setIsOpen, data, fetchData }) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const areaRef = useRef(null);

    /* states */
    const [isLoading, setIsLoading] = useState(false);

    /* utils */
    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + '...';
        } else {
            return str;
        }
    }

    /* mark as read */
    const markAsRead = async (id) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/web-alert`;
            let requestBody = [];
            requestBody.push(id);
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
        } catch (e) {
            console.log(e);
        }
    };

    const markAllAsRead = async () =>   {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/web-alert`;
            let requestBody = [];
            data.map(alert => {
               requestBody.push(alert['imsWebAlertId']);
            });
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            setIsLoading(false);
            fetchData();
        } catch (e) {
            setIsLoading(false);
            console.log(e);
        }
    };

    useEffect(() => {
        const handleFocus = (e) => {
            if (areaRef.current && !areaRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mouseup", handleFocus);
        return () => { document.removeEventListener("mouseup", handleFocus); }
    }, [areaRef]);


    return (
        <PreviewContainer>
            <Container
                ref={areaRef}
                fluid
            >
                <Row>
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'left'
                            }}
                        >
                            <AllReadButton
                                onClick={() => {
                                    if (data.length > 0) {
                                        setIsLoading(true);
                                        markAllAsRead();
                                    }
                                }}
                            >
                                {
                                    isLoading
                                        ?
                                        <Spinner size={"sm"}/>
                                        :
                                        <>{t("button.allRead")}</>
                                }
                            </AllReadButton>
                        </div>
                    </Col>
                    <Col
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'right'
                            }}
                        >
                            {/* cancel buttons */}
                            <ModalCloseButton
                                title={""}
                                setIsOpen={setIsOpen}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    {/* main view area */}
                    <AlertContainer>
                        {
                            data && (data.length > 0) &&
                            data.map(alert => {
                                return (
                                    <AlertElement
                                        key={alert['imsWebAlertId']}
                                        onClick={() => {
                                            setIsOpen(false);
                                            markAsRead(alert['imsWebAlertId']);
                                            navigate(alert['imsGuiPath']);
                                        }}
                                    >
                                        <AlertIconContainer>
                                            <FiAlertCircle
                                                size={"25"}
                                            />
                                        </AlertIconContainer>
                                        <AlertTitleAndContent>
                                            {/* generated at */}
                                            <AlertTitleAndContentInner>
                                                <GenerateDate>
                                                    {DataParseService.dateTimeFormat(alert['generatedAt'])}
                                                </GenerateDate>
                                            </AlertTitleAndContentInner>
                                            {/* title */}
                                            <AlertTitleAndContentInner>
                                                <AlertTitle>
                                                    {alert[t("language.dataKey.alert.title")]}
                                                </AlertTitle>
                                            </AlertTitleAndContentInner>
                                            {/* content */}
                                            <AlertTitleAndContentInner>
                                                <AlertContent>
                                                    {alert[t("language.dataKey.alert.content")]}
                                                </AlertContent>
                                            </AlertTitleAndContentInner>
                                        </AlertTitleAndContent>
                                    </AlertElement>
                                )
                            })
                        }
                        {
                            data && (data.length === 0) &&
                            <MessageContainer>
                                <Message>
                                    {t("message.noUnread")}
                                </Message>
                            </MessageContainer>
                        }
                    </AlertContainer>
                </Row>
                <Row>
                    {/* more button */}
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div
                            style={{
                                marginLeft: 'auto'
                            }}
                        >
                            <MoreButton
                                onClick={() => {
                                    window.localStorage.setItem("ims_alert_before_path", location.pathname);
                                    setIsOpen(false);
                                    navigate('/alert');
                                }}
                            >
                                More
                            </MoreButton>
                        </div>
                    </div>
                </Row>
            </Container>
        </PreviewContainer>
    );

};

export default AlertPreview;