import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../style/FadeTransition.css';

const FadeTransition = ({ children }) => (
    <TransitionGroup>
        <CSSTransition
            key={children.key}
            timeout={500}
            classNames="fade"
            unmountOnExit
        >
            <div className="fade-container">
                {children}
            </div>
        </CSSTransition>
    </TransitionGroup>
);

export default FadeTransition;
