import React, {useEffect, useState} from 'react';
import {Container, Row, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Table from "../common/table/Table";
import axios from "axios";
import { ReactComponent as MagnifierIcon } from '../../icons/ic_search.svg';
import { ReactComponent as StaffAddIcon } from '../../icons/add_staff.svg';
import PaginationHandler from "../common/pagination/PaginationHandler";
import UserStatusCode from "../../enums/UserStatusCode";
import RenderOnRole from "../../auth/RenderOnRole";
import {useNavigate} from "react-router-dom";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const StaffManagement = () => {

    const { t } = useTranslation();

    const [adminList, setAdminList] = useState([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);
    const [searchField, setSearchField] = useState('all');
    const [searchLike, setSearchLike] = useState('');

    const navigate = useNavigate();


    const renameAdminIdToIdAndFillEmptyAndStatusCodeRevision = (objectList) => {
        let newObjList = [...objectList];
        const iter = 9 - objectList.length;
        if (newObjList.length < 9) {
            for (let i=0; i < iter; i++) {
                newObjList.push({
                    "adminId" : null
                });
            }
        }
        return newObjList.map(obj => {
            if ('adminId' in obj) {
                obj['id'] = obj['adminId'];
                delete obj['adminId'];
            }
            if ('adminStatusCode' in obj) {
                obj['adminStatusCode'] = UserStatusCode.parseToString(obj['adminStatusCode'])
            }
            return obj;
        });
    }

    const handleSearchFieldChange = (value) => {
        setSearchField(value);
    }

    const handleSearchLikeChange = (value) => {
        setSearchLike(value);
    }

    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    }

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    }

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    }

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    }

    const enterEventHandler = (e) => {
        if (e.key === 'Enter') {
            fetchAdminList();
        }
    }

    const fetchAdminList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin?size=9&page=${currentPageIndex}`;
            if (searchLike !== '') {
                fetchUrl = fetchUrl + `&field=${searchField}&like=${searchLike}`;
            }
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                let dataList = [];
                for (let i=0; i<8; i++) {
                    dataList.push({
                        "id" : null
                    });
                }
                setAdminList(dataList);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            } else {
                let dataList = [...res.data.item.adminList];
                setAdminList(renameAdminIdToIdAndFillEmptyAndStatusCodeRevision(dataList));
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            }
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    }

    useEffect(() => {
        fetchAdminList();
    }, [currentPageIndex]);

    const columnData = [
        {
            accessor: 'adminName',
            Header: t('common.name'),
        },
        {
            accessor: 'id',
            Header: t('common.id'),
        },
        {
            accessor: 'adminEmail',
            Header: t('common.email'),
        },
        {
            accessor: 'adminPhone',
            Header: t('common.phone'),
        },
        {
            accessor: 'adminStatusCode',
            Header: t('common.status'),
        }
    ]

    /* tooltips */
    const renderToolTip = (name) => {
        return (
            <Tooltip id="tooltip">
                {name}
            </Tooltip>
        );
    };

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.STAFF_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.staff.title")]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    <Row>
                        {/* main area */}
                        <div
                            style={{
                                width: '100%',
                                height: '800px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {/* search bar */}
                            <div
                                className={"contentInner"}
                                style={{
                                    width: '100%',
                                    display: 'flex'
                                }}
                            >
                                <div
                                    style={{
                                        width: '270px',
                                        height: '50px',
                                    }}
                                >
                                    <select
                                        id={"search-field-select-box"}
                                        defaultValue={"all"}
                                        onChange={(e) => handleSearchFieldChange(e.target.value)}
                                    >
                                        <option value={"all"}>{t("common.search.fields.all")}</option>
                                        <option value={"id"}>{t("common.search.fields.id")}</option>
                                        <option value={"name"}>{t("common.search.fields.name")}</option>
                                        <option value={"phone"}>{t("common.search.fields.phone")}</option>
                                        <option value={"email"}>{t("common.search.fields.email")}</option>
                                    </select>
                                </div>
                                <div
                                    style={{
                                        width: '270px',
                                        height: '50px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <input
                                        id={"search-like-input"}
                                        type={"text"}
                                        placeholder={t("common.search.placeholder")}
                                        value={searchLike}
                                        onChange={(e) => handleSearchLikeChange(e.target.value)}
                                        onKeyDown={(e) => enterEventHandler(e)}
                                    />
                                </div>
                                <div
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <button
                                        id={"box-btn"}
                                        onClick={() => fetchAdminList()}
                                    >
                                        <MagnifierIcon />
                                    </button>
                                </div>
                                <div
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    <RenderOnRole type={"POST"} roleName={ImsSystemRole.STAFF_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        <OverlayTrigger placement={"bottom"} overlay={renderToolTip(t("menu.staff.add.title"))}>
                                            <button
                                                id={"box-btn"}
                                                onClick={() => navigate(`/staff/add`)}
                                            >
                                                <StaffAddIcon />
                                            </button>
                                        </OverlayTrigger>
                                    </RenderOnRole>
                                </div>
                            </div>
                            {/* table */}
                            <div
                                className={"contentInner"}
                                style={{
                                    marginTop: '10px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '600px',
                                        marginTop: '15px'
                                    }}
                                >
                                    <Table columns={columnData} data={adminList} baseUrl={'detail'} />
                                </div>
                                {/* pagination */}
                                <div
                                    style={{
                                        width: '400px',
                                        height: '50px',
                                        marginTop: '20px',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    {/*goBefore, goAfter, goFirst, goLast, pageSize, currentIndex, maxIndex, totalItemNumber*/}
                                    <PaginationHandler
                                        goBefore={handleGoBefore}
                                        goAfter={handleGoAfter}
                                        goFirst={handleGoFirst}
                                        goLast={handleGoLast}
                                        pageSize={9}
                                        currentIndex={currentPageIndex}
                                        maxIndex={maxPageIndex}
                                        totalItemNumber={totalElements}
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default StaffManagement;