import React from 'react';
import styled from "styled-components";
import DataCompareService from "../../../../services/DataCompareService";

const Title = styled.span`
  font-size: 15px;
  color: #212529;
`;

const TextInput = styled.input`
  width: 100%;
  border: 1px solid #e4e7ea;
  border-radius: 5px;
  padding: 7px 10px;
  color: #212529;
  font-size: 15px;
`;

const TitleAndTextInput = ({ title, value, isForEdit, isValid, updateValue, updateIsValid, isEnabled, objectKey, validator, property }) => {

    const handleInputChange = (newValue) => {
        if (isForEdit) {
            /* update value */
            updateValue(objectKey, newValue);
            /* type and empty check */
            if (typeof newValue === "undefined" || newValue == null) {
                updateIsValid(objectKey, false);
                return;
            } else {
                if (validator != null) {
                    /* string validating code */
                    let lengthValidity = false;
                    let patternValidity = false;
                    /* length check */
                    if (property['hasLengthLimit']) {
                        lengthValidity = (newValue.length >= validator['minLength']) && (newValue.length <= validator['minLength']);
                    } else {
                        lengthValidity = true;
                    }
                    /* pattern check */
                    if (validator['pattern'] !== "null") {
                        patternValidity = DataCompareService.regexValidator(newValue, validator['pattern']);
                    } else {
                        patternValidity = true;
                    }
                    updateIsValid(objectKey, lengthValidity && patternValidity);
                } else {
                    updateIsValid(objectKey, true);
                }
            }
        } else {
            return;
        }
    };

    /* enabled case only */
    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* title */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '5px',
                    textAlign: 'left'
                }}
            >
                <Title>
                    {title}
                </Title>
            </div>
            {/* input box */}
            <div
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                <TextInput
                    type={"text"}
                    readOnly={!isForEdit}
                    value={value}
                    onChange={(e) => handleInputChange(e.target.value)}
                    style={
                        isValid ?
                            {}
                            :
                            {
                                borderColor : 'red'
                            }
                    }
                />
            </div>
        </div>
    );
};

export default TitleAndTextInput;