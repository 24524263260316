import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import DataCompareService from "../../services/DataCompareService";
import UserService from "../../services/UserService";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import ModalCloseButton from "../common/modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '430px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    adminDepartmentName: "",
    description: "",
    departmentCompanyId:"",
};

const isValidMapInit = {
    adminDepartmentName: false,
    description: false,
    departmentCompanyId: true,
};

const DepartmentAdd = ({ isOpen, setIsOpen, onRefresh }) => {

    const { t } = useTranslation();

    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    /* validity */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    const [departmentCompanyList, setDepartmentCompanyList] = useState([]);

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* fetch CompanyList*/
    const fetchCompanyList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                let newCompanyList = res.data.item.companyList;
                setDepartmentCompanyList(newCompanyList);
                updateRequestBody("departmentCompanyId", res.data.item.companyList[0]['companyId']);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* handlers */
    const handleAdminDepartmentNameInput = (value) => {
        updateRequestBody("adminDepartmentName", value);
    };


    const handleDescriptionInput = (value) => {
        updateRequestBody("description", value);
    };

    const handleDepartmentCompanyIdChange = (value) => {
        updateRequestBody("departmentCompanyId", value);
    };

    /* validators */
    const checkAdminDepartmentNameValidity = () => {
        if (requestBody["adminDepartmentName"] === "") {
            updateIsValidMap("adminDepartmentName", false);
            return;
        }
        const isValidForm = (requestBody["adminDepartmentName"].length <= 15) &&
            (requestBody["adminDepartmentName"].length >= 3);
        updateIsValidMap("adminDepartmentName", isValidForm);
    };

    const checkDescriptionValidity = () => {
        if (requestBody["description"] === "") {
            updateIsValidMap("description", false);
        } else {
            updateIsValidMap("description", true);
        }
    }


    /* post department */
    const postDepartment = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company/${requestBody['departmentCompanyId']}/department`;
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await onRefresh();
            initialize();
            setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    /* initializer */
    const initialize = () => {
        setRequestBody(requestBodyInit);
        setIsValidMap(isValidMapInit);
        fetchCompanyList();
    };

    /* effects */
    useEffect(() => {
        initialize();
    }, [isOpen]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        checkAdminDepartmentNameValidity();
    }, [requestBody["adminDepartmentName"]]);

    useEffect(() => {
        checkDescriptionValidity();
    }, [requestBody["description"]]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.companyDepartment.addDepartment.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <Col
                        style={{ padding : '0px' }}
                    >
                        <TitleAndInputBox
                            title={t("common.name")}
                            value={requestBody["adminDepartmentName"]}
                            onChange={handleAdminDepartmentNameInput}
                            isForEdit={true}
                            type={"text"}
                            isValid={isValidMap["adminDepartmentName"]}
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndTextArea
                        title={t("common.description")}
                        value={requestBody["description"]}
                        onChange={handleDescriptionInput}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap["description"]}
                        height={"60"}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndSelectBox
                        title={t("common.company")}
                        innerValue={requestBody["departmentCompanyId"]}
                        valueList={departmentCompanyList}
                        valueSelector={'companyId'}
                        viewSelector={'companyName'}
                        onChange={handleDepartmentCompanyIdChange}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {
                        (isValid)
                            ?
                            <button
                                id={"long-save-btn"}
                                onClick={() => postDepartment()}
                            >
                                {t("button.save")}
                            </button>
                            :
                            <button
                                id={"long-save-btn-disabled"}
                            >
                                {t("button.save")}
                            </button>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default DepartmentAdd;