import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import UserService from '../../services/UserService';
import DepartmentAdd from "./DepartmentAdd";
import './DepartmentManagementStyle.css';
import styled from "styled-components";
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {CiSquareCheck, CiSquareMinus, CiSquarePlus} from "react-icons/ci";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import CompanyAdd from "../groupCompany/CompanyAdd";
import RenderOnRole from "../../auth/RenderOnRole";

const requestBodyInit = {
    adminDepartmentName: "",
    description: "",
};
const isValidMapInit = {
    adminDepartmentName: false,
    description: true,
};

const GroupAddButton = styled.button`
  width: 45%;
  border: 1px solid #fc7242;
  border-radius: 10px;
  font-size: 14px;
  padding: 7px;
  background-color: white;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #fcf9ed;
  }
`;

const DepartmentManagement = () => {

    const location = useLocation();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    /* group list */
    const [groupList, setGroupList] = useState([]);
    /* companyList */
    const [companyList, setCompanyList] = useState([]);
    /* modals */
    const [isCompanyAddModalOpen, setIsCompanyAddModalOpen] = useState(false);
    const [isDepartmentAddModalOpen, setIsDepartmentAddModalOpen] = useState(false);

    /* fetch group list */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setGroupList(res.data.item.groupList);
            } else {
                setGroupList([]);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* fetch companyList */
    const fetchCompanyList = async (defaultGroup) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                await setCompanyList(res.data.item.companyList.sort());
                /* default : when no group is selected */
                let locationPathList = location.pathname.split("/");
                if (defaultGroup != null) {
                    let companyList = res.data.item.companyList;
                    let companyRedirectionId = "";
                    companyList.map((companyInfo) => {
                        if (companyInfo['companyGroupId'] === defaultGroup) {
                            if (companyInfo['masterCompany']) {
                                companyRedirectionId = companyInfo['companyId'];
                            }
                        }
                    });
                    navigate(`group/${defaultGroup}/company/${companyRedirectionId}`);
                } else {
                    if (!locationPathList.includes("company")) {
                        navigate(`group/${res.data.item.companyList[0]['companyGroupId']}/company/${res.data.item.companyList[0]['companyId']}`);
                    }
                }
            } else {
                setCompanyList([]);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* utils */
    /* util for render */
    const findTargetCompanyListByGroupId = (id, list) => {
        for (let i=0 ; i<list.length ; i++) {
            if (list[i]['groupId'] === id) {
                return list[i]['companies'];
            }
        }
    };

    const hasIdInPath = (id) => {
        return location.pathname.includes(id);
    };

    const checkIsMasterGroupById = (id) => {
        if (groupList.length === 0) {
            return false;
        } else {
            for (let i=0 ; i < groupList.length ; i++) {
                if (groupList[i]['groupId'] === id) {
                    return groupList[i]['masterGroup'];
                }
            }
            return false;
        }
    };

    /* initialize */
    const initializeAll = async (defaultGroup) => {
        await fetchGroupList();
        await fetchCompanyList(defaultGroup);
    };

    /* effects */
    useEffect(() => {
        initializeAll();
    }, [location.pathname]);

    /* renderers */
    const renderToolTip = (name) => {
        return (
            <Tooltip id="tooltip">
                {name}
            </Tooltip>
        );
    };

    const checkIsGroupDeleteRequested = (id) => {
        if (groupList.length === 0) {
            return true;
        } else {
            for (let i=0 ; i < groupList.length ; i++) {
                if (groupList[i]['groupId'] === id) {
                    return groupList[i]['deleteRequested'];
                }
            }
            return true;
        }
    };


    const craftAccordionData = () => {
        let groupAndCompanyList = [];
        let groupIdList = [];
        companyList.map(companyInfo => {
            let companyGroupId = companyInfo['companyGroupId'];
            if (!checkIsGroupDeleteRequested(companyGroupId)) {
                /* new group while loop */
                if (!groupIdList.includes(companyGroupId)) {
                    let newGroupInfo = {};
                    newGroupInfo['groupId'] = companyGroupId;
                    newGroupInfo['groupName'] = companyInfo['companyGroupName'];
                    newGroupInfo['masterGroup'] = checkIsMasterGroupById(companyGroupId);
                    newGroupInfo['companies'] = [];
                    /* add a new group */
                    groupAndCompanyList.push(newGroupInfo);
                    groupIdList.push(companyGroupId);
                }
                /* add company to the company list */
                let targetCompanyList = findTargetCompanyListByGroupId(companyGroupId, groupAndCompanyList);
                targetCompanyList.push(companyInfo);
            }
        });
        /** company sorting */
        for (let i=0 ; i<groupAndCompanyList.length ; i++) {
            let companyList = groupAndCompanyList[i]['companies'];
            /** sorting */
            const sortedCompanyList = companyList.sort((a, b) => {
                if (a.masterCompany && !b.masterCompany) {
                    return -1;
                }
                if (!a.masterCompany && b.masterCompany) {
                    return 1;
                }
                if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
                    return -1;
                }
                if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            groupAndCompanyList[i]['companies'] = sortedCompanyList;
        }
        /** group sorting */
        const sortedList = groupAndCompanyList.sort((a, b) => {
            if (a.masterGroup && !b.masterGroup) {
                return -1;
            }
            if (!a.masterGroup && b.masterGroup) {
                return 1;
            }
            if (a.groupName.toLowerCase() < b.groupName.toLowerCase()) {
                return -1;
            }
            if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return sortedList;
    };

    const renderAccordion = () => {
        if (companyList.length === 0) {
            return (<></>);
        } else {
            let newAccordionData = craftAccordionData();
            return (
                <Accordion>
                    {/* per group */}
                    {
                        newAccordionData.map(groupData => {
                            return (
                                <AccordionItem
                                    isActive={hasIdInPath(groupData['groupId'])}
                                    key={groupData['groupId']}
                                >
                                    {
                                        ({open}) => (
                                            <>
                                                <AccordionHeader
                                                    className={"groupAccordionHeaderBtn" + (open ? "-selected" : "")}
                                                    onClick={() => navigate(`/manage-cm-dp/group/${groupData['groupId']}/company/${groupData['companies'][0]['companyId']}`)}
                                                >
                                                    {/* group name and expand or shrink button + edit button */}
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            borderBottom: '1px solid #ebebeb',
                                                            paddingLeft: '5px',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            paddingRight: '5px',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                       <span
                                                           style={{
                                                               fontSize: '14px',
                                                               marginLeft: '5px'
                                                           }}
                                                       >
                                                           {groupData['groupName']}
                                                       </span>
                                                        {
                                                            checkIsMasterGroupById(groupData['groupId']) ?
                                                                <OverlayTrigger placement={"top"} overlay={renderToolTip(t("menu.groupCompany.group.detail.masterMessage"))}>
                                                                   <span
                                                                       style={{
                                                                           marginLeft: 'auto'
                                                                       }}
                                                                   >
                                                                       <CiSquareCheck
                                                                           size={"25"}
                                                                       />
                                                                   </span>
                                                                </OverlayTrigger>
                                                                :
                                                                <></>
                                                        }
                                                        <span
                                                            style={
                                                                checkIsMasterGroupById(groupData['groupId']) ?
                                                                    {}
                                                                    :
                                                                    {
                                                                        marginLeft: 'auto'
                                                                    }
                                                            }
                                                        >
                                                           {
                                                               open ?
                                                                   <CiSquareMinus
                                                                       size={"25"}
                                                                   />
                                                                   :
                                                                   <CiSquarePlus
                                                                       size={"25"}
                                                                   />
                                                           }
                                                       </span>
                                                    </div>
                                                </AccordionHeader>
                                                <AccordionBody>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            padding: '0px',
                                                            paddingTop: '5px'
                                                        }}
                                                    >
                                                        {craftCompanyListItem(groupData['groupId'], groupData['companies'])}
                                                    </div>
                                                </AccordionBody>
                                            </>
                                        )
                                    }
                                </AccordionItem>
                            );
                        })
                    }
                </Accordion>
            );
        }
    };

    const craftCompanyListItem = (groupId, companyList) => {
        let companyElements = [];
        companyList.map(companyInfo => {
            companyElements.push(
                <div
                    key={companyInfo['companyId']}
                    style={{
                        width: '100%',
                        marginBottom: '5px'
                    }}
                >
                    <button
                        className={"groupListButton" + (hasIdInPath(companyInfo['companyId']) ? "-selected" : "")}
                        onClick={() => navigate(`/manage-cm-dp/group/${groupId}/company/${companyInfo['companyId']}`)}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            {companyInfo['companyName']}
                            {
                                companyInfo['masterCompany'] ?
                                    <OverlayTrigger placement={"top"} overlay={renderToolTip(t("menu.groupCompany.company.detail.masterMessage"))}>
                                    <span
                                        style={{
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        <CiSquareCheck
                                            size={"25"}
                                        />
                                    </span>
                                    </OverlayTrigger>
                                    :
                                    <></>
                            }
                        </div>
                    </button>
                </div>
            );
        });
        return companyElements;
    };

    return (
        <RenderOnRole type={""} roleName={""} allowedRing={1} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.companyDepartment.title")]}
                    depth={1}
                />
            </div>
            <div className={"contentContainer"}>
                <Container fluid>
                    <Row>
                        {/* accordion */}
                        <Col
                            md={"2"}
                            style={{
                                padding: '0px'
                            }}
                        >
                            <div
                                className={"contentInner"}
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                <GroupAddButton
                                    onClick={() => setIsCompanyAddModalOpen(true)}
                                >
                                    {t("menu.groupCompany.company.add.title")}
                                </GroupAddButton>
                                <GroupAddButton
                                    style={{
                                        marginLeft: '10px'
                                    }}
                                    onClick={() => setIsDepartmentAddModalOpen(true)}
                                >
                                    {t("menu.companyDepartment.addDepartment.title")}
                                </GroupAddButton>
                            </div>
                            <div
                                className={"contentInner"}
                                style={{
                                    marginTop: '10px',
                                    height: '720px',
                                    overflowX: 'auto'
                                }}
                            >
                                <div>
                                    {
                                        (companyList.length > 0) &&
                                        renderAccordion()
                                    }
                                </div>

                            </div>
                        </Col>
                        {/* spec */}
                        <Col
                            md={"10"}
                            style={{
                                padding: '0px',
                                paddingLeft: '10px'
                            }}
                        >
                            {/* group and company spec */}
                            <div
                                style={{
                                    width: '100%',
                                    padding: '0px'
                                }}
                            >
                                <Outlet
                                    context={{
                                        updateGroupAndCompanyList : initializeAll
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* company add modal */}
            <CompanyAdd
                isOpen={isCompanyAddModalOpen}
                setIsOpen={setIsCompanyAddModalOpen}
                onRefresh={() => {
                    initializeAll();
                }}
            />
            <DepartmentAdd
                isOpen={isDepartmentAddModalOpen}
                setIsOpen={setIsDepartmentAddModalOpen}
                onRefresh={() => {
                    initializeAll();
                }}
            />
        </RenderOnRole>
    );
};

export default DepartmentManagement;