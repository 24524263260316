import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import styled from "styled-components";
import {Container, Row, Spinner} from "react-bootstrap";
import JsonValidateUtils from "../common/utils/JsonValidateUtils";
import UserService from "../../services/UserService";
import axios from "axios";
import _ from "lodash";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import DataUtils from "../menu/common/utils/DataUtils";
import DataCompareService from "../../services/DataCompareService";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import ButtonWithSpinner from "../menu/common/buttons/ButtonWithSpinner";
import OrangeButton from "../menu/common/buttons/OrangeButton";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ConfirmModal from "../common/confirm/ConfirmModal";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import FadeTransition from "../common/animation/FadeTransition";
import ConsoleAccountPasswordResetModal from "./modal/ConsoleAccountPasswordResetModal";

const requestBodySchema = {
    "type" : "object",
    "properties" : {
        "name" : {
            "type" : "string",
            "minLength": 1,
            "maxLength": 30,
            "pattern": "^(?!\\s*$).+"
        },
        "isMaster" : {
            "type" : "boolean"
        },
        "email" : {
            "type" : "string",
            "format" : "email",
            "pattern": "^[^\\s]+$"
        }
    },
    "required" : ["name", "isMaster", "email"]
};

const requestBodyInit = {
    "name" : "",
    "isMaster" : false,
    "email" : ""
};

const isValidMapInit = {
    "name" : true,
    "isMaster" : true,
    "email" : true
};

const ViewMessage = styled.span`
  font-size: 14px;
  color: #ff0000;
  margin-top: 5px;
  padding-left: 5px;
`;

const DeleteButton = styled.button`
  border: 1px solid #ff0000;
  color: #ff0000;
  background-color: white;
  font-size: 14px;
  padding: 7px 15px;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  
  &:hover {
    background-color: #ffe6e6;
  }
  
  &:focus {
    outline: none;
  }
`;

const ActivateButton = styled.button`
  border: 1px solid #0099ff;
  color: #0099ff;
  background-color: white;
  font-size: 14px;
  padding: 7px 15px;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  margin-left: 10px;
  
  &:hover {
    background-color: #e6f5ff;
  }
  
  &:focus {
    outline: none;
  }
`;

const InactivateButton = styled.button`
  border: 1px solid #ff5050;
  color: #ff5050;
  background-color: white;
  font-size: 14px;
  padding: 7px 15px;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  margin-left: 10px;
  
  &:hover {
    background-color: #ffe6e6;
  }
  
  &:focus {
    outline: none;
  }
`;

const ButtonDisabled = styled.button`
  border: 1px solid #ebebeb;
  color: #ebebeb;
  background-color: white;
  font-size: 14px;
  padding: 7px 15px;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;

  &:focus {
    outline: none;
  }
`;

const AccountDetailView = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { franchiseId, credentialId } = useParams();
    const { fetchAccountList } = useOutletContext();

    /* states */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    const [isEdited, setIsEdited] = useState(false);

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDuplicationLoading, setIsDuplicationLoading] = useState(false);
    const [isIdGood, setIsIdGood] = useState(true);
    const [isEmailGood, setIsEmailGood] = useState(true);

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [isActivateConfirmOpen, setIsActivateConfirmOpen] = useState(false);
    const [isInactivateConfirmOpen, setIsInactivateConfirmOpen] = useState(false);
    const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);

    /* initialize */
    const initializeAll = async (callback) => {
        await setIsLoading(false);
        await setRequestBody(requestBodyInit);
        await setIsValidMap(isValidMapInit);
        await setIsValid(true);
        await setIsIdGood(true);
        await setIsEmailGood(true);
        await callback();
    };

    /* effects */
    useEffect(() => {
        initializeAll(fetchCredentialInfo);
    }, [credentialId]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody])

    /* update functions */
    const validateField = async (key, value) => {
        return JsonValidateUtils.validateWithJSONSchema(requestBodySchema['properties'][key], value);
    };

    /* fetch detail */
    const fetchCredentialInfo = async () => {
        await setIsDataLoading(true);
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
            + `/api/v1/franchise-console-credential/franchise/${franchiseId}/credential/${credentialId}`;
        try {
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let dataInfo = res.data.item['franchiseConsoleCredential'];
            let newRequestBody = {};
            newRequestBody['id'] = dataInfo['franchiseConsoleCredentialId'];
            newRequestBody['email'] = dataInfo['email'];
            newRequestBody['isMaster'] = dataInfo['isMaster'];
            newRequestBody['isEnabled'] = dataInfo['isEnabled'];
            newRequestBody['name'] = dataInfo['name'];
            await setRequestBody(newRequestBody);
            await setFixedRequestBody(_.cloneDeep(newRequestBody));
        } catch (e) {
            console.log(e);
        }
        await setIsDataLoading(false);
    };

    /* put console account */
    const putAccount = async () => {
        await setIsLoading(true);
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
            + `/api/v1/franchise-console-credential/franchise/${franchiseId}/credential/${credentialId}`;
        try {
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "application/json"
                    }
                }
            ));
            await fetchAccountList();
            await initializeAll(fetchCredentialInfo);
            toast.success(<ToastAlertView message={t("message.saved")} />);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
        await setIsLoading(false);
    };

    /* activate */
    const activateAccount = async () => {
        await setIsLoading(true);
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
            + `/api/v1/franchise-console-credential/franchise/${franchiseId}/credential/${credentialId}/enable`;
        try {
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify({}),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "application/json"
                    }
                }
            ));
            await fetchAccountList();
            await initializeAll(fetchCredentialInfo);
            toast.success(<ToastAlertView message={t("message.saved")} />);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
        await setIsLoading(false);
    };

    /* inactivate */
    const inactivateAccount = async () => {
        await setIsLoading(true);
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
            + `/api/v1/franchise-console-credential/franchise/${franchiseId}/credential/${credentialId}/disable`;
        try {
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify({}),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "application/json"
                    }
                }
            ));
            await fetchAccountList();
            await initializeAll(fetchCredentialInfo);
            toast.success(<ToastAlertView message={t("message.saved")} />);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
        await setIsLoading(false);
    };

    /* check duplication */
    const checkDuplication = async (type, field) => { /** type : "id" or "email" */
        await setIsDuplicationLoading(true);
        if ((type !== "id") && (type !== "email")) {
            console.log("Bad Usage of Type : " + type);
            return false;
        }
        if (type === "id") {
            await setIsIdGood(true);
        } else {
            await setIsEmailGood(true);
        }
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-console-credential/check/duplicate`;
        try {
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    params: {
                        type: type,
                        keyword: field
                    },
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (type === "id") {
                await setIsIdGood(!res.data.item['isDuplicated']);
            } else {
                await setIsEmailGood(!res.data.item['isDuplicated']);
            }
            await setIsDuplicationLoading(false);
            return !res.data.item['isDuplicated'];
        } catch (e) {
            console.log(e);
            await setIsDuplicationLoading(false);
            if (type === "id") {
                await setIsIdGood(false);
            } else {
                await setIsEmailGood(false);
            }
            return false;
        }
    };

    return (
        <div
            style={{
                padding: '10px'
            }}
        >
            {/* password reset */}
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    height: '30px',
                    position: 'relative'
                }}
            >
                <ConsoleAccountPasswordResetModal
                    isOpen={isPasswordResetModalOpen}
                    setIsOpen={setIsPasswordResetModalOpen}
                    franchiseId={franchiseId}
                    credentialId={credentialId}
                    onRefresh={async () => await fetchCredentialInfo()}
                />
                <FadeTransition>
                    {
                        isDataLoading ?
                            <div
                                key={"disabled-password"}
                                style={{
                                    width: '100%',
                                    textAlign: 'right'
                                }}
                            >
                                <ButtonDisabled>
                                    {t("menu.franchiseConsoleCred.detail.button.password")}
                                </ButtonDisabled>
                            </div>
                            :
                            <div
                                key={"enabled-password"}
                                style={{
                                    width: '100%',
                                    textAlign: 'right'
                                }}
                            >
                                <OrangeButton
                                    onClickCallback={async () => await setIsPasswordResetModalOpen(true)}
                                    isEnabled={true}
                                    label={t("menu.franchiseConsoleCred.detail.button.password")}
                                />
                            </div>
                    }
                </FadeTransition>
            </div>
            <div
                style={{
                    width: '100%',
                    height: '690px',
                    position: 'relative'
                }}
            >
                <FadeTransition>
                    {
                        isDataLoading ?
                            <div
                                key={"spinner"}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    padding: '200px 0px',
                                    textAlign: 'center'
                                }}
                            >
                                <Spinner />
                            </div>
                            :
                            <Container
                                key={"info-area"}
                                fluid
                            >
                                <Row>
                                    {/* id */}
                                    <TitleAndInputBox
                                        title={t("menu.franchiseConsoleCred.add.fields.id")}
                                        value={requestBody['id']}
                                        onChange={() => {}}
                                        isForEdit={false}
                                        type={"text"}
                                        isValid={true}
                                        isEnabled={true}
                                    />
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '5px'
                                    }}
                                >
                                    {/* email */}
                                    <TitleAndInputBox
                                        title={t("menu.franchiseConsoleCred.add.fields.email")}
                                        value={requestBody['email']}
                                        onChange={async (value) => {
                                            /* data update */
                                            await DataUtils.updateHelper(setRequestBody, 'email', value);
                                            if (value === fixedRequestBody['email']) {
                                                await setIsEmailGood(true);
                                                await DataUtils.updateHelper(setIsValidMap, 'email', true);
                                            } else {
                                                /* check validity */
                                                let validRes = await validateField('email', value);
                                                let duplicationRes = await checkDuplication('email', value);
                                                await DataUtils.updateHelper(setIsValidMap, 'email', validRes && duplicationRes);
                                            }
                                        }}
                                        isForEdit={true}
                                        type={"text"}
                                        isValid={isValidMap['email']}
                                        isEnabled={true}
                                    />
                                    {/* id duplication message */}
                                    {
                                        (!isEmailGood) &&
                                        <ViewMessage>
                                            {t("menu.franchiseConsoleCred.message.duplicated", { field: t("menu.franchiseConsoleCred.table.email") })}
                                        </ViewMessage>
                                    }
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '5px'
                                    }}
                                >
                                    {/* name */}
                                    <TitleAndInputBox
                                        title={t("menu.franchiseConsoleCred.add.fields.name")}
                                        value={requestBody['name']}
                                        onChange={async (value) => {
                                            /* data update */
                                            await DataUtils.updateHelper(setRequestBody, 'name', value);
                                            /* check validity */
                                            let validRes = await validateField('name', value);
                                            await DataUtils.updateHelper(setIsValidMap, 'name', validRes);
                                        }}
                                        isForEdit={true}
                                        type={"text"}
                                        isValid={isValidMap['name']}
                                        isEnabled={true}
                                    />
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '5px'
                                    }}
                                >
                                    {/* type */}
                                    <TitleAndSelectBox
                                        title={t("menu.franchiseConsoleCred.add.fields.isMaster")}
                                        innerValue={requestBody['isMaster']}
                                        valueList={[
                                            {
                                                "value" : true,
                                                "view" : t("menu.franchiseConsoleCred.message.master")
                                            },
                                            {
                                                "value" : false,
                                                "view" : t("menu.franchiseConsoleCred.message.nonMaster")
                                            }
                                        ]}
                                        valueSelector={"value"}
                                        viewSelector={"view"}
                                        onChange={async (value) => {
                                            await DataUtils.updateHelper(setRequestBody, "isMaster", JSON.parse(value));
                                        }}
                                    />
                                </Row>
                            </Container>
                    }
                </FadeTransition>
            </div>
            <div
                style={{
                    width: '100%',
                    marginTop: '10px',
                    position: 'relative'
                }}
            >
                <FadeTransition>
                    {
                        isDataLoading ?
                            <div
                                key={"loading-buttons"}
                                style={{
                                    width: '100%',
                                    marginTop: '10px',
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start'
                                }}
                            >
                                {/* delete button */}
                                <ButtonDisabled>
                                    {t("button.delete")}
                                </ButtonDisabled>
                                {/* inactivate/activate button */}
                                <div
                                    style={{
                                        width: 'auto',
                                        marginLeft: '10px'
                                    }}
                                >
                                    <ButtonDisabled>
                                        {t("menu.franchiseConsoleCred.detail.button.inactivate")}
                                    </ButtonDisabled>
                                </div>
                                <div
                                    style={{
                                        width: 'auto',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    <ButtonDisabled>
                                        {t("button.save")}
                                    </ButtonDisabled>
                                </div>
                            </div>
                            :
                            <div
                                key={"loading-buttons-done"}
                                style={{
                                    width: '100%',
                                    marginTop: '10px',
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start'
                                }}
                            >
                                {/* delete button */}
                                <DeleteButton
                                    onClick={async () => await setIsDeleteConfirmOpen(true)}
                                >
                                    {t("button.delete")}
                                </DeleteButton>
                                <DeleteConfirmModal
                                    isOpen={isDeleteConfirmOpen}
                                    setIsOpen={setIsDeleteConfirmOpen}
                                    isDelayed={false}
                                    confirmMessage={t("language.message.deletion.confirmation", { entityName : `${fixedRequestBody['name']} (${fixedRequestBody['id']})` })}
                                    id={`franchise-console-credential/franchise/${franchiseId}/credential/${credentialId}`}
                                    onDeleteSuccess={async () => {
                                        await navigate(`/fr-console-credential/franchise/${franchiseId}`);
                                        await fetchAccountList(true);
                                    }}
                                />
                                {/* inactivate/activate button */}
                                {
                                    fixedRequestBody['isEnabled'] ?
                                        <InactivateButton
                                            key={"inactivate-btn"}
                                            onClick={async () => await setIsInactivateConfirmOpen(true)}
                                        >
                                            {t("menu.franchiseConsoleCred.detail.button.inactivate")}
                                        </InactivateButton>
                                        :
                                        <ActivateButton
                                            key={"activate-btn"}
                                            onClick={async () => await setIsActivateConfirmOpen(true)}
                                        >
                                            {t("menu.franchiseConsoleCred.detail.button.activate")}
                                        </ActivateButton>
                                }
                                <ConfirmModal
                                    isOpen={isInactivateConfirmOpen}
                                    setIsOpen={setIsInactivateConfirmOpen}
                                    title={t("menu.franchiseConsoleCred.detail.message.sureToInactivate", { name : fixedRequestBody['name'] })}
                                    confirmLabel={t("button.yes")}
                                    rejectLabel={t("button.no")}
                                    onConfirm={async () => {
                                        await inactivateAccount();
                                    }}
                                    onReject={() => {}}
                                />
                                <ConfirmModal
                                    isOpen={isActivateConfirmOpen}
                                    setIsOpen={setIsActivateConfirmOpen}
                                    title={t("menu.franchiseConsoleCred.detail.message.sureToActivate", { name : fixedRequestBody['name'] })}
                                    confirmLabel={t("button.yes")}
                                    rejectLabel={t("button.no")}
                                    onConfirm={async () => {
                                        await activateAccount();
                                    }}
                                    onReject={() => {}}
                                />
                                <div
                                    style={{
                                        width: 'auto',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    {
                                        (isLoading || isDuplicationLoading) ?
                                            <ButtonWithSpinner
                                                buttonColor={"#fc7242"}
                                            />
                                            :
                                            <OrangeButton
                                                onClickCallback={async () => await putAccount()}
                                                isEnabled={
                                                    isValid &&
                                                    isEdited &&
                                                    isEmailGood
                                                }
                                                label={t("button.save")}
                                            />
                                    }
                                </div>
                            </div>
                    }
                </FadeTransition>
            </div>
        </div>
    );
};

export default AccountDetailView;