import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useOutletContext, useParams} from "react-router-dom";
import DataParseService from "../../../../services/DataParseService";
import UserService from "../../../../services/UserService";
import axios from "axios";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import styled from "styled-components";
import ServerFailed from "../viewArea/info/ServerFailed";
import _ from "lodash";
import TitleAndInputBox from "../../../common/dataView/TitleAndInputBox";
import PaginationHandler from "../../../common/pagination/PaginationHandler";
import './BoothMessageHistoryViewStyle.css';
import MessageLogTable from "./table/MessageLogTable";
import OverlayToolTip from "../../../menu/common/toolTip/OverlayToolTip";
import JsonSchemaParserForLog from "./parser/JsonSchemaParserForLog";
import {CiSquarePlus, CiWarning} from "react-icons/ci";
import {GoCheckCircle} from "react-icons/go";
import MessageHistoryDetailModal from "./MessageHistoryDetailModal";

/* ------------------------------ */
/* static variable for the "size" */
const ITEM_SIZE = 10;
/* ------------------------------ */
/* ------------------------------ */

const colorMap = {
    control : "#24a0ed", /* blue */
    controlBack : "#f3ffff",
    setting : "#52b640", /* green */
    settingBack : "#ffffe3",
    product : "#7f5a37", /* brown */
    productBack : "#ffffed",
    order : "#e73d70", /* pink */
    orderBack : "#fff3f5",
    admin: "#757575",
    customer: "#fc7242", /* orange */
    device: "#6a0dad", /* purple */
    sensor: "#a44ce6", /* lighter purple */
    endName: "#de82ff" /* lighter lighter purple */
};

const DetailButton = styled.button`
  background: none;
  border: none;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: #fff1ec;
  }
`;

const ViewBox = styled.div`
  width: auto;
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid ${(props) => props.color};
  border-radius: 10px;
  color: ${(props) => props.color};
  font-size: 14px;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "white"};
`;

const MainContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

const TitleContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 17px;
`;

const OptionButton = styled.button`
  border: 1px solid #999999;
  color: #999999;
  border-radius: 10px;
  background: none;
  padding: 5px 15px;
  transition: all ease-in-out 0.2s;
  font-size: 14px;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #ececec;
  }
`;

const OptionButtonSelected = styled.button`
  border: 1px solid #fc7242;
  color: #fc7242;
  border-radius: 10px;
  padding: 5px 15px;
  background-color: #fff1ec;
  transition: all ease-in-out 0.2s;
  font-size: 14px;
  
  &:focus {
    outline: none;
  }
`;

const DateTimeView = styled.span`
  font-size: 14px;
  color: #757575;
`;

const BoothMessageHistoryView = () => {

    const { t, i18n } = useTranslation();

    /* translation map */
    const translationMap = {
        "label" : t("language.dataKey.boothControl.json.label"),
        "dataUnknowns" : {
            "device" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.device"),
            "attribute" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.attribute"),
            "sensor" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.sensor"),
            "capability" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.capability"),
            "connection" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.connection"),
            "reqData" : t("menu.boothControl.sideMenus.messageHistory.dataUnknowns.reqData"),
        }
    };

    const { boothId } = useParams();
    const { boothJsonSchemaVersion } = useOutletContext();

    /* states */
    const [jsonSchema, setJsonSchema] = useState({});
    const [messageHistoryList, setMessageHistoryList] = useState([]);
    const [source, setSource] = useState("all"); /* all, admin, user */
    const [sourceId, setSourceId] = useState(""); /* search field */
    const [fromDateStr, setFromDateStr] = useState(""); /* format : yyyy-MM-dd HH:mm:ss */
    const [toDateStr, setToDateStr] = useState(""); /* format : yyyy-MM-dd HH:mm:ss */

    /* pagination */
    const [maxPageIndex, setMaxPageIndex] = useState(0);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalItemNum, setTotalItemNum] = useState(0);

    /* loading */
    const [isLoading, setIsLoading] = useState(false); /* for message */
    const [isJsonLoading, setIsJsonLoading] = useState(false); /* for json */

    /* fetch json schema */
    const fetchJsonSchema = async () => {
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
            + `/booth-control-wrapper/schema/${boothJsonSchemaVersion}`;
        try {
            await setIsJsonLoading(true);
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setJsonSchema(res.data.item['booth']);
            await setIsJsonLoading(false);
            return true;
        } catch (e) {
            console.log(e);
            await setJsonSchema({});
            await setIsJsonLoading(false);
            return false;
        }
    };

    /* fetch message history */
    const fetchMessageHistory = async () => {
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
            + `/booth-control-wrapper/message/booth/${boothId}`
            + `?size=${ITEM_SIZE}`
            + `&page=${currentPageIndex}`
            + `&source=${source}`
            + `&fromDateTimeStr=${fromDateStr}`
            + `&toDataTimeStr=${toDateStr}`
        /* set query param : "id" */
        if ((source !== "all") && (sourceId !== "")) {
            fetchUrl = fetchUrl + `&id=${sourceId}`;
        }
        try {
            await setIsLoading(true);
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setMaxPageIndex(res.data.item['maxPage']);
            await setCurrentPageIndex(res.data.item['currentPage']);
            await setTotalItemNum(res.data.item['totalItems']);
            await setMessageHistoryList(res.data.item['messageList']);
            setIsLoading(false);
        } catch (e) {
            await setMaxPageIndex(0);
            await setCurrentPageIndex(0);
            await setTotalItemNum(0);
            await setMessageHistoryList([]);
            setIsLoading(false);
            console.log(e);
        }
    };

    /* initialize */
    const initialize = async () => {
        /* get json schema */
        const resultOfGetJson = await fetchJsonSchema();

        /* fails */
        if (!resultOfGetJson) {
            return; /* fails */
        }

        /* set current date time */
        const currentDateTime = new Date();
        let currentTimeStr = craftDateTimeStr(currentDateTime);
        /* minus a year */
        currentDateTime.setFullYear(currentDateTime.getFullYear() - 1);
        let beforeOneYearTimeStr = craftDateTimeStr(currentDateTime);
        /* set dates */
        await setFromDateStr(beforeOneYearTimeStr);
        await setToDateStr(currentTimeStr);

        /* set source */
        await setSource("all");

        /* setup pagination values */
        await setMaxPageIndex(0);
        await setCurrentPageIndex(0);
        await setTotalItemNum(0);
        await setSourceId("");

        /* get message list */
        await fetchMessageHistory();
    };

    /* handlers */
    const handleSourceChange = (value) => {
        if (value === "all") {
            if (source !== "all") {
                setSource(value);
                setSourceId("");
            }
        } else if (value === "admin") {
            if (source !== "admin") {
                setSource(value);
                setSourceId("");
            }
        } else { /* user */
            if (source !== "user") {
                setSource(value);
                setSourceId("");
            }
        }
    };

    const handleFromDateInput = (value) => {
        const fromDTStr = formatDateTimeInput(value);
        if (isValidDateTimePair(fromDTStr, toDateStr)) {
            setFromDateStr(fromDTStr);
        }
    };

    const handleToDateInput = (value) => {
        const toDTStr = formatDateTimeInput(value);
        if (isValidDateTimePair(fromDateStr, toDTStr)) {
            setToDateStr(toDTStr);
        }
    };

    const handleBeforePage = () => {
        if (isLoading) {
            return;
        }
        if (currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };

    const handleAfterPage = () => {
        if (isLoading) {
            return;
        }
        if (currentPageIndex < maxPageIndex) {
            setCurrentPageIndex(currentPageIndex + 1);
        }
    };

    const handleGoFirst = () => {
        if (isLoading) {
            return;
        }
        setCurrentPageIndex(0);
    }

    const handleGoLast = () => {
        if (isLoading) {
            return;
        }
        setCurrentPageIndex(maxPageIndex);
    }

    /* utils */
    const craftDateTimeStr = (date) => {
        /* format : yyyy-MM-dd HH:mm:ss */
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = "00"; /* second setting not supported */

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const formatDateTimeInput = (value) => {
        let splitList = value.split("T");
        return splitList[0] + " " + splitList[1] + ":00"
    };

    const isValidDateTimePair = (fromDT, toDT) => {
        /* format : yyyy-MM-dd HH:mm:ss */
        const parseDateTime = (dateTimeStr) => {
            const [date, time] = dateTimeStr.split(' ');
            const [year, month, day] = date.split('-').map(num => parseInt(num, 10));
            const [hour, minute, second] = time.split(':').map(num => parseInt(num, 10));

            return new Date(year, month - 1, day, hour, minute, second);
        };

        const fromDate = parseDateTime(fromDT);
        const toDate = parseDateTime(toDT);

        return fromDate < toDate;
    };

    /* table view */

    /* components */
    const DateTimeColumn = (value) => {
        if ((typeof value.value === "undefined") || (value.value == null)) {
            return (
                <DateTimeView>
                    {t("menu.boothControl.sideMenus.messageHistory.message.noDateInfo")}
                </DateTimeView>
            );
        } else {
            return (
                <DateTimeView>
                    {DataParseService.dateTimeFormat(value.value)}
                </DateTimeView>
            );
        }
    };

    const TypeColumn = (value) => {
        let spec = value.value;
        if ((typeof spec === "undefined") || (spec == null)) {
            return (
                <></>
            );
        } else {
            if ((spec === "attributes") || (spec === "connection")) {
                return (
                    <ViewBox
                        color={colorMap['setting']}
                        backgroundColor={colorMap['settingBack']}
                        style={{
                            marginRight: '10px'
                        }}
                    >
                        {t("menu.booth.detail.messageLog.item.type.setting")}
                    </ViewBox>
                );
            } else if (spec === "capabilities") {
                return (
                    <ViewBox
                        color={colorMap['control']}
                        backgroundColor={colorMap['controlBack']}
                        style={{
                            marginRight: '10px'
                        }}
                    >
                        {t("menu.booth.detail.messageLog.item.type.control")}
                    </ViewBox>
                );
            } else if (spec === "products") {
                return (
                    <ViewBox
                        color={colorMap['product']}
                        backgroundColor={colorMap['productBack']}
                        style={{
                            marginRight: '10px'
                        }}
                    >
                        {t("menu.booth.detail.messageLog.item.type.product")}
                    </ViewBox>
                );
            } else if (spec === "orders") {
                return (
                    <ViewBox
                        color={colorMap['order']}
                        backgroundColor={colorMap['orderBack']}
                        style={{
                            marginRight: '10px'
                        }}
                    >
                        {t("menu.booth.detail.messageLog.item.type.order")}
                    </ViewBox>
                );
            } else {
                return (<></>);
            }
        }
    };

    const SourceColumn = (value) => {
        let clientInfo = value.value;
        if (clientInfo['spec'] === "orders") {
            return (
                <OverlayToolTip
                    text={clientInfo['clientId'] ? clientInfo['clientId'] : t("menu.booth.detail.messageLog.item.unKnown")}
                    direction={"bottom"}
                >
                    <ViewBox
                        color={colorMap['admin']}
                    >
                        {t("menu.booth.detail.messageLog.item.source.franchiseAppServer")}
                    </ViewBox>
                </OverlayToolTip>
            );
        } else if (clientInfo['isAdmin']) {
            return (
                <OverlayToolTip
                    text={clientInfo['clientId'] ? clientInfo['clientId'] : t("menu.booth.detail.messageLog.item.unKnown")}
                    direction={"bottom"}
                >
                    <ViewBox
                        color={colorMap['admin']}
                    >
                        {t("menu.booth.detail.messageLog.item.source.admin")}
                    </ViewBox>
                </OverlayToolTip>
            );
        } else { /* customer */
            return (
                <OverlayToolTip
                    text={clientInfo['clientId'] ? clientInfo['clientId'] : t("menu.booth.detail.messageLog.item.unKnown")}
                    direction={"bottom"}
                >
                    <ViewBox
                        color={colorMap['admin']}
                    >
                        {t("menu.booth.detail.messageLog.item.source.customer")}
                    </ViewBox>
                </OverlayToolTip>
            );
        }
    };

    const TargetColumn = (value) => {
        let pathInfo = value.value;
        if ((typeof pathInfo === "undefined") || (pathInfo == null)) {
            return (
                <></>
            );
        }
        /* capability */
        if (pathInfo['spec'] === "capabilities") {
            return JsonSchemaParserForLog.getNamesFromPathAndBody(pathInfo['path'], pathInfo['body'], jsonSchema, "sensor", translationMap).map((nameObj, index) => {
                if (index === 0) {
                    return (
                        <ViewBox
                            color={colorMap['device']}
                            style={{
                                marginRight: '10px'
                            }}
                        >
                            {nameObj['deviceName']}
                        </ViewBox>
                    );
                } else if (index === 1) {
                    return (
                        <ViewBox
                            color={colorMap['sensor']}
                            style={{
                                marginRight: '10px'
                            }}
                        >
                            {nameObj['sensorName']}
                        </ViewBox>
                    );
                } else {
                    return (
                        <ViewBox
                            color={colorMap['endName']}
                        >
                            {nameObj['capabilityName']}
                        </ViewBox>
                    );
                }
            });
        } else if (pathInfo['spec'] === "attributes") {
            return JsonSchemaParserForLog.getNamesFromPathAndBody(pathInfo['path'], pathInfo['body'], jsonSchema, "device", translationMap).map((nameObj, index) => {
                if (index === 0) {
                    return (
                        <ViewBox
                            color={colorMap['device']}
                            style={{
                                marginRight: '10px'
                            }}
                        >
                            {nameObj['deviceName']}
                        </ViewBox>
                    );
                } else {
                    return (
                        <ViewBox
                            color={colorMap['endName']}
                            style={{
                                marginRight: '10px'
                            }}
                        >
                            {nameObj['attributeName']}
                        </ViewBox>
                    );
                }
            });
        } else if (pathInfo['spec'] === "connection") {
            return JsonSchemaParserForLog.getNamesFromPathAndBody(pathInfo['path'], pathInfo['body'], jsonSchema, "connection", translationMap).map((nameObj, index) => {
                if (index === 0) {
                    return (
                        <ViewBox
                            color={colorMap['device']}
                            style={{
                                marginRight: '10px'
                            }}
                        >
                            {nameObj['deviceName']}
                        </ViewBox>
                    );
                } else {
                    return (
                        <ViewBox
                            color={colorMap['endName']}
                            style={{
                                marginRight: '10px'
                            }}
                        >
                            {nameObj['attributeName']}
                        </ViewBox>
                    );
                }
            });
        }
    };

    const ResultColumn = (value) => {
        if (value.value) {
            return (
                <OverlayToolTip
                    text={t("menu.booth.detail.messageLog.item.result.fail")}
                    direction={"bottom"}
                >
                    <div
                        style={{
                            height: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <CiWarning
                            size={"25"}
                            color={"red"}
                        />
                    </div>
                </OverlayToolTip>
            );
        } else {
            return (
                <OverlayToolTip
                    text={t("menu.booth.detail.messageLog.item.result.success")}
                    direction={"bottom"}
                >
                    <div
                        style={{
                            height: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <GoCheckCircle
                            size={"25"}
                            color={"green"}
                        />
                    </div>
                </OverlayToolTip>
            );
        }
    };

    const DetailColumn = (value) => {

        let data = value.value;

        const [isOpen, setIsOpen] = useState(false);

        return (
            <>
                <OverlayToolTip
                    text={t("menu.booth.detail.messageLog.item.detail")}
                    direction={"bottom"}
                >
                    <DetailButton
                        style={{
                            marginLeft: '10px'
                        }}
                        onClick={() => setIsOpen(true)}
                    >
                        <CiSquarePlus
                            size={"25"}
                        />
                    </DetailButton>
                </OverlayToolTip>
                <MessageHistoryDetailModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    data={data}
                    jsonSchema={jsonSchema}
                />
            </>
        );
    };

    /* define */
    const columnData = [
        {
            accessor: "createdAt",
            Header: t("menu.booth.detail.messageLog.table.createdAt"),
            Cell: ({cell: {value}}) => <DateTimeColumn value={value}/>
        },
        {
            accessor: "spec",
            Header: t("menu.booth.detail.messageLog.table.type"),
            Cell: ({cell: {value}}) => <TypeColumn value={value}/>
        },
        {
            accessor: "clientInfo",
            Header: t("menu.booth.detail.messageLog.table.source"),
            Cell: ({cell: {value}}) => <SourceColumn value={value}/>
        },
        {
            accessor: "pathInfo",
            Header: t("menu.booth.detail.messageLog.table.target"),
            Cell: ({cell: {value}}) => <TargetColumn value={value}/>
        },
        {
            accessor: "error_code",
            Header: t("menu.booth.detail.messageLog.table.result"),
            Cell: ({cell: {value}}) => <ResultColumn value={value}/>
        },
        {
            accessor: "allData",
            Header: t("menu.booth.detail.messageLog.table.detail"),
            Cell: ({cell: {value}}) => <DetailColumn value={value}/>
        }
    ];

    /* data re-struct for table */
    const reStructMessageList = (dataList) => {
        let newDataList = [];
        dataList.map(info => {
            let newObj = {...info};
            let fixedData = _.cloneDeep(info);
            /* craft client information */
            let clientInfo = {};
            clientInfo['isAdmin'] = info['isAdmin'];
            clientInfo['clientId'] = info['clientId'];
            clientInfo['spec'] = info['spec'];
            newObj['clientInfo'] = clientInfo;
            /* craft path information */
            let pathInfo = {};
            pathInfo['spec'] = info['spec'];
            pathInfo['path'] = info['path'];
            pathInfo['body'] = info['body'];
            newObj['pathInfo'] = pathInfo;
            /* craft for modal */
            newObj['allData'] = fixedData;

            newDataList.push(newObj);
        });
        return newDataList;
    };

    /* effects */
    useEffect(() => {
        initialize();
    }, [boothId]);

    useEffect(() => {
        fetchMessageHistory();
    }, [currentPageIndex, source, sourceId, fromDateStr, toDateStr]);

    if (isJsonLoading) {
        /* fetching json schema */
        return (
            <MainContainer
                style={{
                    height: '50%',
                    paddingTop: '10%'
                }}
            >
                <Spinner />
            </MainContainer>
        );
    } else if (!isJsonLoading && (((typeof jsonSchema === "undefined")) || (_.isEqual(jsonSchema, {})))) {
        /* failed to get json schema */
        return (
            <MainContainer
                style={{
                    paddingTop: '50px'
                }}
            >
                <ServerFailed />
            </MainContainer>
        );
    } else {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        {/* title */}
                        <TitleContainer>
                            <Title>
                                {t("menu.booth.detail.messageLog.title")}
                            </Title>
                        </TitleContainer>

                        {/* top state selection */}
                        <div
                            style={{
                                width: '100%',
                                marginTop: '20px',
                                padding: '10px',
                                borderRadius: '10px',
                                backgroundColor: 'white'
                            }}
                        >
                            {/* buttons */}
                            <div
                                style={{
                                    width: '100%',
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                {
                                    (source === "all") ?
                                        <OptionButtonSelected
                                            style={{
                                                marginRight: '10px'
                                            }}
                                        >
                                            {t("menu.booth.detail.messageLog.button.all")}
                                        </OptionButtonSelected>
                                        :
                                        <OptionButton
                                            style={{
                                                marginRight: '10px'
                                            }}
                                            onClick={() => handleSourceChange("all")}
                                        >
                                            {t("menu.booth.detail.messageLog.button.all")}
                                        </OptionButton>
                                }
                                {
                                    (source === "admin") ?
                                        <OptionButtonSelected
                                            style={{
                                                marginRight: '10px'
                                            }}
                                        >
                                            {t("menu.booth.detail.messageLog.button.admin")}
                                        </OptionButtonSelected>
                                        :
                                        <OptionButton
                                            style={{
                                                marginRight: '10px'
                                            }}
                                            onClick={() => handleSourceChange("admin")}
                                        >
                                            {t("menu.booth.detail.messageLog.button.admin")}
                                        </OptionButton>
                                }
                                {
                                    (source === "user") ?
                                        <OptionButtonSelected>
                                            {t("menu.booth.detail.messageLog.button.customer")}
                                        </OptionButtonSelected>
                                        :
                                        <OptionButton
                                            onClick={() => handleSourceChange("user")}
                                        >
                                            {t("menu.booth.detail.messageLog.button.customer")}
                                        </OptionButton>
                                }
                            </div>
                            {/* from to date */}
                            <div
                                style={{
                                    marginTop: '10px',
                                    width: '100%'
                                }}
                            >
                                <Container fluid>
                                    <Row>
                                        <Col
                                            style={{
                                                padding: '0px'
                                            }}
                                        >
                                            {/* from */}
                                            <TitleAndInputBox
                                                title={t("menu.booth.detail.messageLog.detail.fromDate")}
                                                value={fromDateStr}
                                                onChange={handleFromDateInput}
                                                isForEdit={true}
                                                type={"datetime-local"}
                                                isValid={true}
                                                isEnabled={true}
                                            />
                                        </Col>
                                        <Col
                                            style={{
                                                padding: '0px',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            {/* to */}
                                            <TitleAndInputBox
                                                title={t("menu.booth.detail.messageLog.detail.toDate")}
                                                value={toDateStr}
                                                onChange={handleToDateInput}
                                                isForEdit={true}
                                                type={"datetime-local"}
                                                isValid={true}
                                                isEnabled={true}
                                            />
                                        </Col>
                                        <Col
                                            style={{
                                                padding: '0px',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={
                                                    source === "user" ?
                                                        t("menu.booth.detail.messageLog.detail.customerId")
                                                        :
                                                        t("menu.booth.detail.messageLog.detail.adminId")
                                                }
                                                value={sourceId}
                                                onChange={setSourceId}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={true}
                                                isEnabled={source !== "all"}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            {/* search field */}
                            <div
                                style={{
                                    width: '100%',
                                    marginTop: '10px'
                                }}
                            >

                            </div>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                height: '550px',
                                marginTop: '10px',
                                padding: '10px',
                                borderRadius: '10px',
                                backgroundColor: 'white'
                            }}
                        >
                            {
                                isLoading ?
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '470px',
                                            paddingTop: '50px'
                                        }}
                                    >
                                        <Spinner />
                                    </div>
                                    :
                                    <div
                                        className={"withScrollDiv"}
                                        style={{
                                            width: '100%',
                                            height: '470px',
                                            overflow: 'auto'
                                        }}
                                    >
                                        {/* item view */}
                                        {
                                            (messageHistoryList.length === 0) &&
                                            <div
                                                style={{
                                                    width: '100%',
                                                    paddingTop: '50px',
                                                    color: '#757575',
                                                    fontSize: '14px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {t("menu.booth.detail.messageLog.message.noLog")}
                                            </div>
                                        }
                                        {
                                            (messageHistoryList.length > 0) &&
                                            <MessageLogTable
                                                columns={columnData}
                                                data={reStructMessageList(messageHistoryList)}
                                            />
                                        }
                                    </div>
                            }
                            {/* pagination bar */}
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row-reverse'
                                }}
                            >
                                <div
                                    style={{
                                        width: '350px',
                                        height: '50px',
                                        marginTop: '10px'
                                    }}
                                >
                                    <PaginationHandler
                                        goBefore={handleBeforePage}
                                        goAfter={handleAfterPage}
                                        goFirst={handleGoFirst}
                                        goLast={handleGoLast}
                                        pageSize={ITEM_SIZE}
                                        currentIndex={currentPageIndex}
                                        maxIndex={maxPageIndex}
                                        totalItemNumber={totalItemNum}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
};

export default BoothMessageHistoryView;