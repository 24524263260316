import React, {useEffect, useState} from 'react';
import Modal from "react-modal";
import BoothService from "../../axiosServices/BoothService";
import {useTranslation} from "react-i18next";
import DataParseService from "../../../../../services/DataParseService";
import _ from "lodash";
import {Col, Container, Row} from "react-bootstrap";
import styled from "styled-components";
import TitleAndSelectBox from "../../../../common/dataView/TitleAndSelectBox";
import {value} from "lodash/seq";
import {toast} from "react-toastify";
import ToastAlertView from "../../../../common/alert/ToastAlertView";
import ModalCloseButton from "../../../../common/modal/ModalCloseButton";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '300px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    boothVersionId : "",
    serialNumber : "",
    manufactureDate : "",
    boothGroupId : ""
};

const SaveButton = styled.button`
  width: 100%;
  padding: 5px;
  background: none;
  border: 1px solid #fc7242;
  border-radius: 5px;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #fbe1d5;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  width: 100%;
  padding: 5px;
  background: none;
  border: 1px solid #757575;
  border-radius: 5px;
  color: #757575;

  &:focus {
    outline: none;
  }
`;

const BoothVersionEditModal = ({ boothId, isOpen, setIsOpen, onRefresh }) => {

    const { t } = useTranslation();

    /* request body */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    /* booth name list */
    const [boothNameList, setBoothNameList] = useState([]);
    /* selected booth name index */
    const [selectedBoothNameIndex, setSelectedBoothNameIndex] = useState(-1);
    /* isEdited */
    const [isEdited, setIsEdited] = useState(false);

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    /* fetch booth name list */
    const fetchBoothNameList = () => {
        const onSuccessCallBack = (data) => {
            /* filter out the name with zero version */
            let newData = data.filter(boothName => {
                if (boothName['versions'].length === 0) {
                    return false;
                } else {
                    return true;
                }
            });
            setBoothNameList(newData);
        };
        const onFailureCallBack = (e) => {
            setBoothNameList([]);
            toast.error(<ToastAlertView message={t("message.serverFailed")} />);
            setIsOpen(false);
        };
        BoothService.getBoothVersionList(boothId, onSuccessCallBack, onFailureCallBack);
    };

    /* fetch booth information */
    const fetchBoothInfo = () => {
        const onSuccessCallBack = (data) => {
            let newRequestBody = {};
            newRequestBody['boothVersionId'] = data['boothVersionInfo']['boothVersionId'];
            newRequestBody['serialNumber'] = data['serialNumber'];
            newRequestBody['manufactureDate'] = DataParseService.dateTimeToDate(data['manufactureDate']);
            newRequestBody['boothGroupId'] = data['boothGroupId'];
            setRequestBody(newRequestBody);
            setFixedRequestBody(_.cloneDeep(newRequestBody));
            boothNameList.map((boothName, index) => {
                let versionList = boothName['versions'];
                for (let i=0 ; i<versionList.length ; i++) {
                    if (versionList[i]['boothVersionId'] === newRequestBody['boothVersionId']) {
                        setSelectedBoothNameIndex(index);
                    }
                }
            });
        };
        const onFailureCallBack = (e) => {
            toast.error(<ToastAlertView message={t("message.serverFailed")} />);
            setIsOpen(false);
        };
        BoothService.fetchBoothBasicInfo(boothId, onSuccessCallBack, onFailureCallBack);
    };

    /* put booth info */
    const putBoothInfo = async () => {
        const onSuccessCallBack = () => {
            toast.success(<ToastAlertView message={t("message.saved")} />);
            onRefresh();
            setIsOpen(false);
        };
        const onFailureCallBack = (e) => {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            onRefresh();
            setIsOpen(false);
        };
        BoothService.putBoothBasicInfo(boothId, requestBody, onSuccessCallBack, onFailureCallBack);
    };

    /* handlers */
    const handleBoothNameChange = (value) => {
        boothNameList.map((boothName, index) => {
           if (boothName['boothNameId'] === value) {
               setSelectedBoothNameIndex(index);
               updateRequestBody("boothVersionId", boothName['versions'][0]['boothVersionId'])
           }
        });
    };

    const handleBoothVersionChange = (value) => {
        updateRequestBody("boothVersionId", value);
    };

    /* effects */
    useEffect(() => {
        const fetchAsync = async () => {
            await fetchBoothNameList();
            fetchBoothInfo();
        };
        fetchAsync();
    }, [isOpen]);

    useEffect(() => {
        setIsEdited(!(_.isEqual(requestBody, fixedRequestBody)));
    }, [requestBody]);

    if ((boothNameList.length === 0) || selectedBoothNameIndex === -1 || Object.values(requestBody).length === 0) {
        return (
            <Modal
                style={modalStyle}
                isOpen={isOpen}
                onRequestClose={() => {
                    setIsOpen(false);
                }}
            >
                <Container fluid>
                    <Row>
                        <ModalCloseButton
                            title={t("menu.booth.detail.sideBar.menu.boothInfo.basicInfo.editBoothVersion.title")}
                            setIsOpen={setIsOpen}
                        />
                    </Row>
                    <Row>
                        <span
                            style={{
                                color: '#757575'
                            }}
                        >
                            {t("menu.booth.detail.sideBar.menu.boothInfo.basicInfo.editBoothVersion.noBoothVersionMessage")}
                        </span>
                    </Row>
                </Container>
            </Modal>
        );
    } else {
        return (
            <Modal
                style={modalStyle}
                isOpen={isOpen}
                onRequestClose={() => {
                    onRefresh();
                    setIsOpen(false);
                }}
            >
                <Container fluid>
                    <Row>
                        <ModalCloseButton
                            title={t("menu.booth.detail.sideBar.menu.boothInfo.basicInfo.editBoothVersion.title")}
                            setIsOpen={setIsOpen}
                        />
                    </Row>
                    <Row>
                        <TitleAndSelectBox
                            title={t("menu.booth.detail.sideBar.menu.boothInfo.basicInfo.detail.boothName")}
                            innerValue={boothNameList[selectedBoothNameIndex]['boothNameId']}
                            valueList={boothNameList}
                            valueSelector={"boothNameId"}
                            viewSelector={"boothName"}
                            onChange={handleBoothNameChange}
                        />
                    </Row>
                    <Row>
                        <TitleAndSelectBox
                            title={t("menu.booth.detail.sideBar.menu.boothInfo.basicInfo.detail.boothVersion")}
                            innerValue={requestBody['boothVersionId']}
                            valueList={boothNameList[selectedBoothNameIndex]['versions']}
                            valueSelector={"boothVersionId"}
                            viewSelector={"boothVersion"}
                            onChange={handleBoothVersionChange}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '25px'
                        }}
                    >
                        {
                            isEdited
                            ?
                                <SaveButton
                                    onClick={putBoothInfo}
                                >
                                    {t("button.save")}
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("button.save")}
                                </SaveButtonDisabled>
                        }
                    </Row>
                </Container>
            </Modal>
        );
    }
};

export default BoothVersionEditModal;