import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {useTranslation} from "react-i18next";
import axios from "axios";
import UserService from "../../services/UserService";
import useInterval from "../../customHooks/useInterval";
import {Container, Row, Spinner} from "react-bootstrap";
import {FiAlertCircle} from "react-icons/fi";
import DataParseService from "../../services/DataParseService";
import {useNavigate} from "react-router-dom";

const AlertContainer = styled.div`
  width: 100%;
  height: 700px;
  margin-top: 10px;
  overflow: auto;
  padding: 5px;
`;

const AlertElement = styled.div`
  width: 100%;
  height: 70px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  transition: all ease-in-out 0.2s;
  color: #fc7242;
  
  &:hover {
    -webkit-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    -moz-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    color: #fc7242;
  }
`;

const AlertElementRead = styled.div`
  width: 100%;
  height: 70px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  transition: all ease-in-out 0.2s;
  color: #FFF1EC;
  
  &:hover {
    -webkit-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    -moz-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    color: #FFF1EC;
  }
`;

const AlertIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const AlertTitleAndContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const AlertTitleAndContentInner = styled.div`
  text-align: left;
`;

const GenerateDate = styled.span`
  font-size: 13px;
  color: #757575;
`;

const AlertTitle = styled.span`
  font-size: 14px;
  color: #696969;
`;

const AlertContent = styled.div`
  font-size: 13px;
  color: #c2c2c2;
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 150px;
`;

const Message = styled.span`
  color: #757575;
  font-size: 14px;
`;

const AllReadButton = styled.button`
  padding: 3px 15px;
  border: 1px solid #757575;
  color: #757575;
  font-size: 14px;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  background-color: white;
  
  &:hover {
    background-color: #ebebeb;
  }
  
  &:focus {
    outline: none;
  }
`;

const AlertView = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    /* states */
    const [alertList, setAlertList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /* fetch alert list */
    const fetchAlertList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/web-alert?coverage=all`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            let newAlertList = res.data.item.alertList;
            setAlertList(newAlertList);
        } catch (e) {
            console.log(e);
        }
    };

    /* mark as read */
    const markAsRead = async (id) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/web-alert`;
            let requestBody = [];
            requestBody.push(id);
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
        } catch (e) {
            console.log(e);
        }
    };

    /* mark all as read */
    const markAllAsRead = async () =>   {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/web-alert`;
            let requestBody = [];
            alertList.map(alert => {
                requestBody.push(alert['imsWebAlertId']);
            });
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            setIsLoading(false);
            fetchAlertList();
        } catch (e) {
            setIsLoading(false);
            console.log(e);
        }
    };

    /* TIMER : fetch data */
    useInterval(() => {
        fetchAlertList();
    }, 1500);

    useEffect(() => {
        fetchAlertList();
    }, []);

    return (
        <>
            <div className={"headingTitle"}>
                <BackwardButton
                    isShow={true}
                    link={window.localStorage.getItem("ims_alert_before_path") ? window.localStorage.getItem("ims_alert_before_path") : '/'}
                />
                <PageTitleWithDepth
                    titles={[t("menu.alertView.title")]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                    <Container fluid>
                        <Row>
                            {/* check all read button */}
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <div>
                                    <AllReadButton
                                        onClick={() => {
                                            if (alertList.length > 0) {
                                                setIsLoading(true);
                                                markAllAsRead();
                                            }
                                        }}
                                    >
                                        {
                                            isLoading
                                                ?
                                                <Spinner size={"sm"}/>
                                                :
                                                <>{t("button.allRead")}</>
                                        }
                                    </AllReadButton>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            {/* main view area */}
                            <AlertContainer>
                                {
                                    alertList && (alertList.length > 0) &&
                                    alertList.map(alert => {
                                        return (
                                            <AlertElementRead
                                                key={alert['imsWebAlertId']}
                                                onClick={() => {
                                                    markAsRead(alert['imsWebAlertId']);
                                                    navigate(alert['imsGuiPath']);
                                                }}
                                                style={
                                                    !alert['read'] ?
                                                        {
                                                            color: '#fc7242'
                                                        }
                                                        :
                                                        {}
                                                }
                                            >
                                                <AlertIconContainer>
                                                    <FiAlertCircle
                                                        size={"25"}
                                                    />
                                                </AlertIconContainer>
                                                <AlertTitleAndContent>
                                                    {/* generated at */}
                                                    <AlertTitleAndContentInner>
                                                        <GenerateDate>
                                                            {DataParseService.dateTimeFormat(alert['generatedAt'])}
                                                        </GenerateDate>
                                                    </AlertTitleAndContentInner>
                                                    {/* title */}
                                                    <AlertTitleAndContentInner>
                                                        <AlertTitle>
                                                            {alert[t("language.dataKey.alert.title")]}
                                                        </AlertTitle>
                                                    </AlertTitleAndContentInner>
                                                    {/* content */}
                                                    <AlertTitleAndContentInner>
                                                        <AlertContent>
                                                            {alert[t("language.dataKey.alert.content")]}
                                                        </AlertContent>
                                                    </AlertTitleAndContentInner>
                                                </AlertTitleAndContent>
                                            </AlertElementRead>
                                        )
                                    })
                                }
                                {
                                    alertList && (alertList.length === 0) &&
                                    <MessageContainer>
                                        <Message>
                                            {t("message.noAlert")}
                                        </Message>
                                    </MessageContainer>
                                }
                            </AlertContainer>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default AlertView;