import React from 'react';

const AlertViewForBoothDiff = ({ message }) => {

    return (
        <div
            style={{
                width: '100%',
                textAlign: 'left'
            }}
        >
                <span
                    style={{
                        color: '#757575',
                        fontSize: '14px'
                    }}
                >
                    {message}
                </span>
        </div>
    );
};

export default AlertViewForBoothDiff;