import React from 'react';
import './ReactCalendarStyleWrap.css';
import Calendar from "react-calendar";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import DateUtils from "../utils/DateUtils";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { HiOutlineDotsHorizontal } from "react-icons/hi";


const TitleItem = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
  height: ${(props) => props.height ? props.height : "10px"};
  background: none;
`;

const Dot = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${(props) => props.color ? props.color : "black"};
  border-radius: 50%;
  display: flex;
  margin-left: 3px;
`;

const colorMap = {
    0 : "#c2c2c2", /* canceled */
    1 : "#f87171", /* planned */
    2 : "#3399ff", /* done */
};

/** calHeight
 * must have unit "px"
 * at least 250px */
const CalendarView = ({ calWidth, calHeight, isForSmall, selectedDate, setSelectedDate, scheduleData, onMonthChange }) => {

    const { t, i18n } = useTranslation();

    const deleteUnit = (str) => {
        return parseFloat(str.replace(/(px|%)$/, ''));
    };

    const deleteUnitAndModify = (str) => {
        let res;
        if (isForSmall != null) {
            if (isForSmall) {
                res = parseFloat(str.replace(/(px|%)$/, '')) * 0.03;
            } else {
                res = parseFloat(str.replace(/(px|%)$/, '')) * 0.11;
            }
        } else {
            res = parseFloat(str.replace(/(px|%)$/, '')) * 0.11;
        }
        return res;
    };

    const getUnit = (str) => {
        if (str.endsWith("%")) {
            return "%";
        } else {
            return "px";
        }
    };

    return (
        <div
            style={{
                width: calWidth,
                height: calHeight,
                overflow: 'auto'
            }}
        >
            {
                (deleteUnit(calHeight) < 250) &&
                <span>MUST BE OVER 250px HEIGHT</span>
            }
            {
                (deleteUnit(calHeight) >= 250) &&
                <Calendar
                    style={{
                        height: calHeight
                    }}
                    formatDay={(locale, date) => moment(date).format("DD")}
                    navigationLabel={null}
                    locale={i18n.language}
                    minDetail={"month"}
                    maxDetail={"month"}
                    showNeighboringMonth={false}
                    tileContent={({ date, view }) => {
                        let dateStr = DateUtils.formatDate(date, '-');
                        return (
                            <TitleItem
                                height={(deleteUnitAndModify(calHeight)) + getUnit(calHeight)}
                            >
                                {
                                    scheduleData &&
                                    (scheduleData[dateStr] != null) &&
                                    (scheduleData[dateStr]['countOfPlanned'] > 0) &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip
                                                id={"tooltip-bt"}
                                                placement={"bottom"}
                                            >
                                                <strong>{`(${t("language.enums.scheduleStatus." + scheduleData[dateStr]['plannedSchedules'][0]['scheduleStatusCode'])}) `}</strong>
                                                {scheduleData[dateStr]['plannedSchedules'][0]['scheduleTitle']}
                                            </Tooltip>
                                        }
                                    >
                                        <Dot
                                            color={colorMap[scheduleData[dateStr]['plannedSchedules'][0]['scheduleStatusCode']]}
                                        />
                                    </OverlayTrigger>
                                }
                                {
                                    scheduleData &&
                                    (scheduleData[dateStr] != null) &&
                                    (scheduleData[dateStr]['countOfPlanned'] > 1) &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip
                                                id={"tooltip-bt"}
                                                placement={"bottom"}
                                            >
                                                <strong>{`(${t("language.enums.scheduleStatus." + scheduleData[dateStr]['plannedSchedules'][1]['scheduleStatusCode'])}) `}</strong>
                                                {scheduleData[dateStr]['plannedSchedules'][1]['scheduleTitle']}
                                            </Tooltip>
                                        }
                                    >
                                        <Dot
                                            color={colorMap[scheduleData[dateStr]['plannedSchedules'][1]['scheduleStatusCode']]}
                                        />
                                    </OverlayTrigger>
                                }
                                {
                                    scheduleData &&
                                    (scheduleData[dateStr] != null) &&
                                    (scheduleData[dateStr]['countOfPlanned'] > 2) &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip
                                                id={"tooltip-bt"}
                                                placement={"bottom"}
                                            >
                                                <strong>{`(${t("language.enums.scheduleStatus." + scheduleData[dateStr]['plannedSchedules'][2]['scheduleStatusCode'])}) `}</strong>
                                                {scheduleData[dateStr]['plannedSchedules'][2]['scheduleTitle']}
                                            </Tooltip>
                                        }
                                    >
                                        <Dot
                                            color={colorMap[scheduleData[dateStr]['plannedSchedules'][2]['scheduleStatusCode']]}
                                        />
                                    </OverlayTrigger>
                                }
                                {
                                    scheduleData &&
                                    (scheduleData[dateStr] != null) &&
                                    (((scheduleData[dateStr]['countOfPlanned']) > 3) || ((scheduleData[dateStr]['countOfCanceled'] + scheduleData[dateStr]['countOfDone']) > 0)) &&
                                    <HiOutlineDotsHorizontal
                                        color={"#757575"}
                                        size={"15"}
                                        style={
                                            (scheduleData[dateStr]['countOfPlanned'] > 0) ?
                                                {
                                                    marginLeft: '3px'
                                                }
                                                :
                                                {}
                                        }
                                    />
                                }
                            </TitleItem>
                        );
                    }}
                    value={selectedDate}
                    onChange={(value, event) => setSelectedDate(DateUtils.formatDate(value, '-'))}
                    onActiveStartDateChange={({ activeStartDate}) => onMonthChange(activeStartDate)}
                />
            }
        </div>
    );
};

export default CalendarView;