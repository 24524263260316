import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ActiveStatus = styled.div`
  border: 1px solid;
  padding: 3px 15px;
  font-size: 14px;
  border-radius: 10px;
  color: #128cfb;
  borderColor: #128cfb;
  background-color: #e1f5fe;
`;

const InactiveStatus = styled.div`
  border: 1px solid;
  padding: 3px 15px;
  font-size: 14px;
  border-radius: 10px;
  color: #ff4f4b;
  borderColor: #ff4f4b;
  background-color: #FFF1EC;
`;

const ExpiredStatus = styled.div`
  border: 1px solid;
  padding: 3px 15px;
  font-size: 14px;
  border-radius: 10px;
  color: #757575;
  borderColor: #757575;
  background-color: #eeeeee;
  margin-right: 10px;
`;

const KeyStatusView = ({ keyObj }) => {

    const { t } = useTranslation();

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row-reverse',
            }}
        >
            {/* active or inactive */}
            {
                keyObj['enabled'] ?
                    <ActiveStatus>
                        {t("menu.franchiseApiKey.keyStatus.active")}
                    </ActiveStatus>
                    :
                    <InactiveStatus>
                        {t("menu.franchiseApiKey.keyStatus.inactive")}
                    </InactiveStatus>
            }
            {/* expired or not(unseen) */}
            {
                keyObj['expired'] &&
                <ExpiredStatus>
                    {t("menu.franchiseApiKey.keyStatus.expired")}
                </ExpiredStatus>
            }
        </div>
    );
};

export default KeyStatusView;