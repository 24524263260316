import React from 'react';
import styled from "styled-components";
import {CiSquareQuestion} from "react-icons/ci";
import OverlayToolTip from "../../../../../common/toolTip/OverlayToolTip";

const AreaTitle = styled.div`
  font-size: 15px;
  color: #757575;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
`;

const TitleAndColDiv = ({ title, height, marginBtw, marginTop, toolTip, toolTipDirection, padding, children }) => {

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <div
                style={{
                    width: '100%',
                    marginBottom : marginBtw ? marginBtw + "px" : "10px",
                    marginTop : marginTop ? marginTop + "px" : "0px",
                    display: 'flex',
                    flexDirection: 'row',
                    overflow: 'auto'
                }}
            >
                {/* title */}
                <AreaTitle>
                    {title}
                </AreaTitle>
                {
                    toolTip &&
                    <div
                        style={{
                            marginLeft: 'auto',
                            height: '100%',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <OverlayToolTip
                            direction={toolTipDirection}
                            text={toolTip}
                        >
                        <span
                            style={{
                                marginLeft: '10px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#8f8f8f'
                            }}
                        >
                            <CiSquareQuestion
                                size={"25"}
                            />
                        </span>
                        </OverlayToolTip>
                    </div>
                }
            </div>
            <div
                className={"contentInner"}
                style={{
                    width: '100%',
                    height: height ? ((height === "auto") ? "auto" : height + "px") : "100px",
                    overflow: 'auto',
                    padding: padding ? padding + "px" : ""
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default TitleAndColDiv;