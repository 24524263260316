import React, {useEffect, useState} from 'react';
import "./PaginationHandlerStyle.css";
import { ReactComponent as ArrowLeft } from "../../../icons/pagination/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../icons/pagination/arrow-right.svg";
import { ReactComponent as DoubleLeft } from "../../../icons/pagination/double-left.svg";
import { ReactComponent as DoubleRight } from "../../../icons/pagination/double-right.svg";

const PaginationHandler = ({ goBefore,
                               goAfter,
                               goFirst,
                               goLast,
                               pageSize,
                               currentIndex,
                               maxIndex,
                               totalItemNumber }) => {

    const [innerString, setInnerString] = useState("");

    useEffect(() => {
        setInnerString(
            (totalItemNumber === 0 ? 0 : (currentIndex * pageSize + 1))
            + "-"
            + ((currentIndex + 1) * pageSize > totalItemNumber ? totalItemNumber : (currentIndex + 1) * pageSize)
            + " of "
            + (totalItemNumber));
    }, [pageSize, currentIndex, maxIndex, totalItemNumber]);

    return (
        <div
            style={{ display: 'flex', width: '100%', height: '100%' }}
        >
            <div
                style={{
                    border: '2px solid #ebebeb',
                    borderRight: 'none',
                    borderRadius: '10px 0px 0px 10px',
                    width: '15%',
                    height: '100%'
                }}
            >
                <button
                    id={"pagination-btn"}
                    onClick={() => goFirst()}
                >
                    <DoubleLeft
                        style={{ fill: '#b8b8b8' }}
                    />
                </button>
            </div>
            <div
                style={{
                    border: '2px solid #ebebeb',
                    borderRight: 'none',
                    width: '15%',
                    height: '100%'
                }}
            >
                <button
                    id={"pagination-btn"}
                    onClick={() => goBefore()}
                >
                    <ArrowLeft
                        style={{ fill: '#b8b8b8' }}
                    />
                </button>
            </div>
            <div
                style={{
                    border: '2px solid #ebebeb',
                    borderRight: 'none',
                    width: '40%',
                    height: '100%',
                    padding: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}
            >
                <span
                    style={{ color: '#fc7242' }}
                >
                    {innerString}
                </span>
            </div>
            <div
                style={{
                    border: '2px solid #ebebeb',
                    borderRight: 'none',
                    width: '15%',
                    height: '100%'
                }}
            >
                <button
                    id={"pagination-btn"}
                    onClick={() => goAfter()}
                >
                    <ArrowRight
                        style={{ fill: '#b8b8b8' }}
                    />
                </button>
            </div>
            <div
                style={{
                    border: '2px solid #ebebeb',
                    borderRadius: '0px 10px 10px 0px',
                    width: '15%',
                    height: '100%'
                }}
            >
                <button
                    id={"pagination-btn"}
                    onClick={() => goLast()}
                >
                    <DoubleRight
                        style={{ fill: '#b8b8b8' }}
                    />
                </button>
            </div>
        </div>
    );
};

export default PaginationHandler;