import React from 'react';
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import KeyTextViewAndCopyButton from "./keyDetail/KeyTextViewAndCopyButton";
import { GoGear } from "react-icons/go";
import DataParseService from "../../../services/DataParseService";
import {useTranslation} from "react-i18next";
import KeyStatusView from "./keyDetail/KeyStatusView";

const KeyItemContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  padding: 10px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  color: #fc7242;
`;

const KeyItemContainerSelected = styled.div`
  width: 100%;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
  color: #fc7242;
  padding: 10px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  color: #fc7242;
`;

const KeyIconButton = styled.button`
  border: none;
  background: none;
  text-align: center;
  transition: all ease-in-out 0.2s;
  color: #c2c2c2;

  &:hover {
    color: #757575;
  }

  &:focus {
    outline: none;
  }
`;

const DateView = styled.span`
  font-size: 14px;
  color: #757575;
`;

const ApiKeyItemView = ({ keyObj, onClick, selected }) => {

    const { t } = useTranslation();
    const location = useLocation();

    /* utils */

    /* render helper */
    const ContainerWrapper = ({ children }) => {
        if (selected) {
            return (
                <KeyItemContainerSelected>
                    {children}
                </KeyItemContainerSelected>
            );
        } else {
            return (
                <KeyItemContainer>
                    {children}
                </KeyItemContainer>
            );
        }
    };

    return (
        <ContainerWrapper>
            <Container fluid>
                {/* key text */}
                <Row>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'left'
                        }}
                    >
                        <KeyTextViewAndCopyButton
                            apiKey={keyObj['franchiseAppApiKey']}
                        />
                        <KeyIconButton
                            onClick={onClick}
                        >
                            {
                                selected ?
                                    <GoGear
                                        size={"20"}
                                        color={"#757575"}
                                    />
                                    :
                                    <GoGear
                                        size={"20"}
                                    />
                            }
                        </KeyIconButton>
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        {/* expiredAt */}
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'left'
                            }}
                        >
                            <DateView>
                                {
                                    t("menu.franchiseApiKey.detail.message.expiredAt") +
                                    DataParseService.dateTimeToDate(keyObj['expireDate'])
                                }
                            </DateView>
                        </div>
                    </Col>
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        {/* key status */}
                        <KeyStatusView
                            keyObj={keyObj}
                        />
                    </Col>
                </Row>
            </Container>
        </ContainerWrapper>
    );
};

export default ApiKeyItemView;