import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import JsonValidateUtils from "../../common/utils/JsonValidateUtils";
import UserService from "../../../services/UserService";
import axios from "axios";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import DataUtils from "../../menu/common/utils/DataUtils";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import ButtonWithSpinner from "../../menu/common/buttons/ButtonWithSpinner";
import OrangeButton from "../../menu/common/buttons/OrangeButton";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        maxHeight: '80vh',
        borderRadius: '10px',
        overflowY: 'auto',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const requestBodySchema = {
    "type" : "object",
    "properties" : {
        "id" : {
            "type": "string",
            "minLength": 1,
            "maxLength" : 20,
            "pattern": "^[^\\s!\\*'\\(\\);:@&=\\+\\$,/\\?#\\[\\]]+$"
        },
        "name" : {
            "type" : "string",
            "minLength": 1,
            "pattern": "^(?!\\s*$).+"
        },
        "isMaster" : {
            "type" : "boolean"
        },
        "password" : {
            "type" : "string",
            "minLength": 1
        },
        "email" : {
            "type" : "string",
            "format" : "email",
            "pattern": "^[^\\s]+$"
        }
    },
    "required" : ["id", "name", "isMaster", "email", "password"]
};

const ConsoleAccountPasswordResetModal = ({ isOpen, setIsOpen, franchiseId, credentialId, onRefresh }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    /* states */
    const [inputPassword, setInputPassword] = useState("");
    const [reInputPassword, setReInputPassword] = useState("");
    const [isVerified, setIsVerified] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    /* initialize */
    const initialize = async () => {
        await setInputPassword("");
        await setReInputPassword("");
        await setIsVerified(false);
        await setIsValid(false);
        await setIsLoading(false);
    };

    useEffect(() => {
        initialize();
    }, [isOpen]);

    useEffect(() => {
        setIsVerified(inputPassword === reInputPassword);
    }, [inputPassword, reInputPassword]);

    /* update functions */
    const validateField = async (key, value) => {
        return JsonValidateUtils.validateWithJSONSchema(requestBodySchema['properties'][key], value);
    };

    /* put a new password */
    const putPassword = async () => {
        await setIsLoading(true);
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
            + `/api/v1/franchise-console-credential/franchise/${franchiseId}/credential/${credentialId}/password`;
        const requestBody = {
            password : inputPassword
        };
        try {
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "application/json"
                    }
                }
            ));
            await initialize();
            await setIsOpen(false);
            await onRefresh();
            toast.success(<ToastAlertView message={t("message.saved")} />);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
        await setIsLoading(false);
    };

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={async () => {
                if (!isLoading) {
                    await setIsOpen(false);
                }
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchiseConsoleCred.detail.passwordReset.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <TitleAndInputBox
                        title={t("menu.franchiseConsoleCred.add.fields.password")}
                        value={inputPassword}
                        onChange={async (value) => {
                            /* data update */
                            await setInputPassword(value);
                            /* check validity */
                            let validRes = await validateField('password', value);
                            await setIsValid(validRes);
                        }}
                        isForEdit={true}
                        type={"password"}
                        isValid={isValid}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndInputBox
                        title={t("menu.franchiseConsoleCred.add.fields.rePassword")}
                        value={reInputPassword}
                        onChange={async (value) => {
                            /* data update */
                            await setReInputPassword(value);
                        }}
                        isForEdit={true}
                        type={"password"}
                        isValid={isVerified}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {/* save button */}
                    {
                        (isLoading) ?
                            <ButtonWithSpinner
                                buttonColor={"#fc7242"}
                            />
                            :
                            <OrangeButton
                                onClickCallback={async () => await putPassword()}
                                isEnabled={
                                    isValid &&
                                    isVerified
                                }
                                style={{
                                    width: "100%"
                                }}
                                label={t("button.save")}
                            />
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default ConsoleAccountPasswordResetModal;