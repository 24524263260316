import React from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../../../common/dataView/TitleAndInputBox";
import UserService from "../../../../services/UserService";
import axios from "axios";
import {toast} from "react-toastify";


const InfoContainer = styled.div`
    width: 100%;
`;

const UrlContainer = styled.div`
  width: 75%;
`;

const TestButton = styled.button`
  border: 1px solid #fc7242;
  color: #fc7242;
  background-color: white;
  transition: all ease-in-out 0.2s;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  margin-left: auto;

  &:hover {
    background-color: #fcf9ed;
  }

  &:focus {
    outline: none;
  }
`;

const TestButtonDisabled = styled.button`
  border: 1px solid #ebebeb;
  color: #ebebeb;
  background-color: white;
  transition: all ease-in-out 0.2s;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  margin-left: auto;

  &:focus {
    outline: none;
  }
`;

const WebHookView = ({ handleAlertUrlInput,
                         handlePaymentCancelUrlInput,
                         requestBody,
                         isValidMap,
                         testBaseUrl,
                         isEdited}) => {

    const { t } = useTranslation();

    /* test URL */
    const testUrl = async (type) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-app-api-key`
                + testBaseUrl + `/${type}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            toast.success(AlertInner(true, res.status));
        } catch (e) {
            console.log(e);
            toast.error(AlertInner(false, e.response.status));
        }
    };

    const AlertInner = (isSuccess, statusCode) => {
        return (
            <div
                style={{
                    width: '100%',
                    textAlign: 'left'
                }}
            >
                <span
                    style={{
                        color: '#757575',
                        fontSize: '14px'
                    }}
                >
                    {
                        isSuccess ?
                            t("menu.franchiseApiKey.detail.webHook.message.success") + statusCode
                            :
                            t("menu.franchiseApiKey.detail.webHook.message.fail") + statusCode
                    }
                </span>
            </div>
        );
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* info */}
            <InfoContainer>
                <Container fluid>
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'end'
                            }}
                        >
                            <UrlContainer>
                                <TitleAndInputBox
                                    title={t("menu.franchiseApiKey.detail.webHook.detail.alertUrl")}
                                    value={requestBody['alertUrl']}
                                    onChange={handleAlertUrlInput}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={isValidMap['alertUrl']}
                                    isEnabled={true}
                                />
                            </UrlContainer>
                            {/* test button */}
                            {
                                isEdited || (requestBody['alertUrl'] === "") ?
                                    <TestButtonDisabled>
                                        {t("menu.franchiseApiKey.detail.button.testUrl")}
                                    </TestButtonDisabled>
                                    :
                                    <TestButton
                                        onClick={() => testUrl("alert")}
                                    >
                                        {t("menu.franchiseApiKey.detail.button.testUrl")}
                                    </TestButton>
                            }
                        </div>
                    </Row>
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'end'
                            }}
                        >
                            <UrlContainer>
                                <TitleAndInputBox
                                    title={t("menu.franchiseApiKey.detail.webHook.detail.paymentCancelUrl")}
                                    value={requestBody['paymentCancelUrl']}
                                    onChange={handlePaymentCancelUrlInput}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={isValidMap['paymentCancelUrl']}
                                    isEnabled={true}
                                />
                            </UrlContainer>
                            {/* test button */}
                            {
                                isEdited || (requestBody['paymentCancelUrl'] === "") ?
                                    <TestButtonDisabled>
                                        {t("menu.franchiseApiKey.detail.button.testUrl")}
                                    </TestButtonDisabled>
                                    :
                                    <TestButton
                                        onClick={() => testUrl("payment-cancel")}
                                    >
                                        {t("menu.franchiseApiKey.detail.button.testUrl")}
                                    </TestButton>
                            }
                        </div>
                    </Row>
                </Container>
            </InfoContainer>
        </div>
    );
};

export default WebHookView;