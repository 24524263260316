import _ from "lodash";
import {useTranslation} from "react-i18next";

const getNamesFromPathAndBody = (path, body, jsonSchema, depth, translationMap) => {

    /* depth          */
    /* 1.  device     */
    /* 2.  sensor     */
    /* 3.  connection */

    let resultList = [];
    let deviceNameObj = {};
    let attributeNameObj = {};
    let sensorNameObj = {};
    let capabilityNameObj = {};
    /* when JSON not given */
    if (typeof jsonSchema === "undefined" || jsonSchema == null || _.isEqual(jsonSchema, {})) {
        deviceNameObj['deviceName'] = translationMap["dataUnknowns"]["device"];
        resultList.push(deviceNameObj);
        if (depth === "device") {
            attributeNameObj['attributeName'] = translationMap["dataUnknowns"]["attribute"];
            resultList.push(attributeNameObj);
            return resultList;
        } else {
            sensorNameObj['sensorName'] = translationMap["dataUnknowns"]["sensor"];
            resultList.push(attributeNameObj);
            capabilityNameObj['capabilityName'] = translationMap["dataUnknowns"]["capability"];
            resultList.push(capabilityNameObj);
            return resultList;
        }
    }
    let deviceSchemaList = jsonSchema['spec']['devices'];
    let pathList = path.split('/');
    let deviceId = pathList[4];
    /* get device name */
    let deviceSchema = {};
    let isDeviceExist = false;
    for (let i=0 ; i < deviceSchemaList.length ; i++) {
        if (deviceSchemaList[i]['metadata']['id'] === deviceId) {
            isDeviceExist = true;
            deviceSchema = deviceSchemaList[i];
            deviceNameObj['deviceName'] = deviceSchema['metadata'][translationMap["label"]];
            resultList.push(deviceNameObj);
        }
    }
    /* device name not exist */
    if (!isDeviceExist) {
        deviceNameObj['deviceName'] = translationMap["dataUnknowns"]["device"];
        resultList.push(deviceNameObj);
    }
    if (depth === "connection") {
        attributeNameObj['attributeName'] = translationMap["dataUnknowns"]["connection"];
        resultList.push(attributeNameObj);
        return resultList;
    }
    /* attr depth */
    if (depth === "device") {
        /* body not exist */
        if ((typeof body === "undefined") || (body == null)) {
            attributeNameObj['attributeName'] = translationMap["dataUnknowns"]["reqData"];
            resultList.push(attributeNameObj);
            return resultList;
        }
        let attributeName = Object.keys(body)[0];
        if (!isDeviceExist) {
            attributeNameObj['attributeName'] = translationMap["dataUnknowns"]["attribute"];
            resultList.push(attributeNameObj);
        } else {
            /* device exist */
            if (deviceSchema['spec']['attributes'][attributeName]) {
                attributeNameObj['attributeName'] = deviceSchema['spec']['attributes'][attributeName][translationMap["label"]];
                resultList.push(attributeNameObj);
            } else {
                attributeNameObj['attributeName'] = translationMap["dataUnknowns"]["attribute"];
                resultList.push(attributeNameObj);
            }
        }
        return resultList;
    } else { /* capability depth */
        if ((!isDeviceExist) || (pathList.length < 5)) {
            sensorNameObj['sensorName'] = translationMap["dataUnknowns"]["sensor"];
            resultList.push(sensorNameObj);
            capabilityNameObj['capabilityName'] = translationMap["dataUnknowns"]["capability"];
            resultList.push(capabilityNameObj);
            return resultList;
        }
        let sensorId = pathList[6];
        let capabilityName = Object.keys(body)[0];
        let sensorSchemaList = deviceSchema['spec']['sensors'];
        let sensorSchema = {};
        let isSensorExist = false;
        for (let i=0 ; i<sensorSchemaList.length ; i++) {
            if (sensorSchemaList[i]['metadata']['id'] === sensorId) {
                isSensorExist = true;
                sensorSchema = sensorSchemaList[i];
                sensorNameObj['sensorName'] = sensorSchemaList[i]['metadata'][translationMap["label"]];
                resultList.push(sensorNameObj);
            }
        }
        /* sensor not exist */
        if (!isSensorExist) {
            sensorNameObj['sensorName'] = translationMap["dataUnknowns"]["sensor"];
            resultList.push(sensorNameObj);
            capabilityNameObj['capabilityName'] = translationMap["dataUnknowns"]["capability"];
            resultList.push(capabilityNameObj);
            return resultList;
        } else {
            /* sensor exist */
            if (sensorSchema['spec']['capabilities'][capabilityName]) {
                capabilityNameObj['capabilityName'] = sensorSchema['spec']['capabilities'][capabilityName][translationMap["label"]];
                resultList.push(capabilityNameObj);
            } else {
                capabilityNameObj['capabilityName'] = translationMap["dataUnknowns"]["capability"];
                resultList.push(capabilityNameObj);
            }
            return resultList;
        }
    }
};

const JsonSchemaParserForLog = {
    getNamesFromPathAndBody
};

export default JsonSchemaParserForLog;