import React from 'react';
import {Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const NotAllowed = () => {

    const {t} = useTranslation();

    return (
        <div className={"contentContainer"}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '50px'
                }}
            >
                <div>
                    <p
                        style={{
                            color: '#fc7242'
                        }}
                    >
                        {"401"}
                    </p>
                    <p
                        style={{
                            fontSize: '14px',
                            color: '#757575'
                        }}
                    >
                        {t("message.notAllowed")}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NotAllowed;