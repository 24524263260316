import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import DataCompareService from "../../services/DataCompareService";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import RenderOnRole from "../../auth/RenderOnRole";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const requestBodyInit = {
    boardTitle : "",
    boardContent : "",
    boardGroupId : ""
};

const isValidMapInit = {
    boardTitle : false,
    boardContent : false,
    boardGroupId : true
};

const BoardAdd = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    /* isValid */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);
    /* groupList */
    const [groupList, setGroupList] = useState([]);

    /* fetch group list : only at first */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            setGroupList(res.data.item.groupList);
            updateRequestBody("boardGroupId", res.data.item.groupList[0].groupId);
        } catch (e) {
            console.log(e);
        }
    };

    /* post board */
    const postBoard = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/board`;
            const axiosCall = () => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate(`/board`);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* utils */
    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* handlers */
    const handleBoardTitleInput = (value) => {
        updateRequestBody("boardTitle", value);
    };

    const handleBoardContentInput = (value) => {
        updateRequestBody("boardContent", value);
    };

    const handleGroupIdChange = (value) => {
        updateRequestBody("boardGroupId", value);
    };

    /* validators */
    const validateBoardTitle = () => {
        if (requestBody["boardTitle"].length < 3 || requestBody["boardTitle"].length > 70) {
            updateIsValidMap("boardTitle", false);
        } else {
            updateIsValidMap("boardTitle", true);
        }
    };

    const validateBoardContent = () => {
        if (requestBody["boardContent"] === "") {
            updateIsValidMap("boardContent", false);
        } else {
            updateIsValidMap("boardContent", true);
        }
    };

    /* effects */
    useEffect(() => {
        fetchGroupList();
    }, []);

    /* input values */
    useEffect(() => {
        validateBoardTitle();
    }, [requestBody["boardTitle"]]);

    useEffect(() => {
        validateBoardContent();
    }, [requestBody["boardContent"]]);

    /* isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <RenderOnRole type={"POST"} roleName={ImsSystemRole.BOARD_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/board"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.board.title"), t("menu.board.add.title")]}
                    depth={2}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    <Row>
                        {/* main area */}
                        <div
                            className={"contentInner"}
                            style={{
                                width: '100%',
                                height: '700px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '650px',
                                    display: 'flex',
                                    overflow: 'auto',
                                    padding: '10px 0px 20px 0px'
                                }}
                            >
                                <Container fluid>
                                    <Row>
                                        <TitleAndInputBox
                                            title={t("common.title")}
                                            value={requestBody["boardTitle"]}
                                            isForEdit={true}
                                            type={"text"}
                                            isValid={isValidMap["boardTitle"]}
                                            onChange={handleBoardTitleInput}
                                        />
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <Col
                                            style={{
                                                padding: '0px'
                                            }}
                                        >
                                            <TitleAndSelectBox
                                                title={t("common.group")}
                                                innerValue={requestBody["boardGroupId"]}
                                                valueList={groupList}
                                                valueSelector={"groupId"}
                                                viewSelector={"groupName"}
                                                onChange={handleGroupIdChange}
                                            />
                                        </Col>
                                        <Col
                                            style={{
                                                padding: '0px',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.adminId")}
                                                value={window.sessionStorage.getItem("ims_adminId")}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <TitleAndTextArea
                                            title={t("common.boardContent")}
                                            value={requestBody["boardContent"]}
                                            onChange={handleBoardContentInput}
                                            isForEdit={true}
                                            isValid={isValidMap["boardContent"]}
                                            height={"390"}
                                        />
                                    </Row>
                                </Container>
                            </div>
                            {/* bottom btn */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'right'
                                }}
                            >
                                <div
                                    style={{ marginRight: '10px' }}
                                >
                                    <Link
                                        id={"edit-btn"}
                                        to={`/board`}
                                    >
                                        {t("button.cancel")}
                                    </Link>
                                </div>
                                <div>
                                    {
                                        (isValid)
                                            ?
                                            <Link
                                                id={"save-btn"}
                                                onClick={() => postBoard()}
                                            >
                                                {t("button.save")}
                                            </Link>
                                            :
                                            <Link
                                                id={"save-btn-disabled"}
                                            >
                                                {t("button.save")}
                                            </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default BoardAdd;