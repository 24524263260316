import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import TitleAndTextInput from "../dataView/TitleAndTextInput";
import {useParams} from "react-router-dom";
import BoothService from "../axiosServices/BoothService";
import { GoGear } from "react-icons/go";
import BoothVersionEditModal from "./modals/BoothVersionEditModal";
import JsonService from "../axiosServices/JsonService";
import _ from "lodash";
import StatusInner from "./innerDevice/StatusInner";
import ImsSystemRole from "../../../../auth/roles/ImsSystemRole";
import RenderOnRole from "../../../../auth/RenderOnRole";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 17px;
`;

const IconButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  position: relative;
  margin-left: 10px;
  
  &:focus {
    outline: none;
  }
`;

const DataContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 15px 15px 50px 15px;
  background-color: white;
  border-radius: 5px;
`;

const Data = styled.div`
  width: 100%;
  margin-top: 10px;
`;


/* path : "/booth/detail/:boothId/info" */
const BoothInfo = () => {

    const { t } = useTranslation();
    const { boothId } = useParams();

    /* loading */
    const [isLoading, setIsLoading] = useState(false);

    /* booth information */
    const [boothInfo, setBoothInfo] = useState({});
    /* load success */
    const [boothInfoLoadSuccess, setBoothInfoLoadSuccess] = useState(false);
    const [boothJsonLoadSuccess, setBoothJsonLoadSuccess] = useState(false);
    const [boothDataLoadSuccess, setBoothDataLoadSuccess] = useState(false);
    /* modals */
    const [isBoothVersionEditModalOpen, setIsBoothVersionEditModalOpen] = useState(false);

    /* booth json schema */
    const [boothJsonSchemaVersion, setBoothJsonSchemaVersion] = useState("");
    const [boothJsonSchema, setBoothJsonSchema] = useState({});
    /* booth data */
    const [boothData, setBoothData] = useState({});

    /* fetch booth basic info */
    const fetchBoothBasicInfo = () => {
        const onSuccessCallBack = (data) => {
            setBoothInfo(data);
            setBoothJsonSchemaVersion(data['boothVersionInfo']['boothJsonVersion']);
            setBoothInfoLoadSuccess(true);
            setIsLoading(false);
        };
        const onFailureCallBack = (e) => {
            setBoothInfo({});
            setBoothInfoLoadSuccess(false);
            setIsLoading(false);
        };
        setIsLoading(true);
        BoothService.fetchBoothBasicInfo(boothId, onSuccessCallBack, onFailureCallBack);
    };

    /* fetch json schema */
    const fetchJsonSchema = async () => {
        const onSuccessCallBack = (data) => {
            setBoothJsonSchema(data);
            setBoothJsonLoadSuccess(true);
            setIsLoading(false);
        };
        const onFailureCallBack = () => {
            setBoothJsonSchema({});
            setBoothJsonLoadSuccess(false);
            setIsLoading(false);
        };
        setIsLoading(true);
        JsonService.fetchBoothJsonSchema(boothJsonSchemaVersion, onSuccessCallBack, onFailureCallBack);
    };

    /* fetch booth data */
    const fetchBoothData = async () => {
        const onSuccessCallBack = (data) => {
            setBoothData(data);
            setBoothDataLoadSuccess(true);
            setIsLoading(false);
        };
        const onFailureCallBack = () => {
            setBoothData({});
            setBoothDataLoadSuccess(false);
            setIsLoading(false);
        };
        BoothService.fetchBoothData(boothId, onSuccessCallBack, onFailureCallBack);
    };

    useEffect(() => {
        const fetchData = async () => {
            /* fetch booth info */
            await fetchBoothBasicInfo();
            /* fetch json schema */
            await fetchJsonSchema();
            /* fetch booth data */
            fetchBoothData();
        };
        fetchData();
    }, []);


    if (isLoading) {
        /* loading */
        return (
            <MainContainer>
                <Spinner />
            </MainContainer>
        );
    } else if (!boothInfoLoadSuccess) {
        /* failed to fetch */
        return (
            <MainContainer
                style={{
                    paddingTop: '50px'
                }}
            >
                <span
                    style={{
                        color: '#757575'
                    }}
                >
                    {t("menu.booth.detail.sideBar.menu.boothInfo.loadFailedMessage")}
                </span>
            </MainContainer>
        );
    } else {
        return (
            <MainContainer>
                <Container fluid>
                    <Row>
                        <Col>
                            <CardContainer>
                                <TitleContainer>
                                    <Title>
                                        {t("menu.booth.detail.sideBar.menu.boothInfo.basicInfo.title")}
                                    </Title>
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.BOOTH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        <IconButton
                                            onClick={() => setIsBoothVersionEditModalOpen(true)}
                                        >
                                            <GoGear
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '-2px'
                                                }}
                                            />
                                        </IconButton>
                                    </RenderOnRole>
                                </TitleContainer>
                                <DataContainer>
                                    <Data>
                                        <TitleAndTextInput
                                            title={t("menu.booth.detail.sideBar.menu.boothInfo.basicInfo.detail.boothId")}
                                            value={boothInfo['boothId']}
                                            isForEdit={false}
                                            isValid={true}
                                            updateValue={() => {}}
                                            updateIsValid={() => {}}
                                            isEnabled={true}
                                            objectKey={"none"}
                                        />
                                    </Data>
                                    <Data>
                                        <TitleAndTextInput
                                            title={t("menu.booth.detail.sideBar.menu.boothInfo.basicInfo.detail.boothName")}
                                            value={boothInfo['boothVersionInfo']['boothName']}
                                            isForEdit={false}
                                            isValid={true}
                                            updateValue={() => {}}
                                            updateIsValid={() => {}}
                                            isEnabled={true}
                                            objectKey={"none"}
                                        />
                                    </Data>
                                    <Data>
                                        <TitleAndTextInput
                                            title={t("menu.booth.detail.sideBar.menu.boothInfo.basicInfo.detail.boothVersion")}
                                            value={boothInfo['boothVersionInfo']['boothVersion']}
                                            isForEdit={false}
                                            isValid={true}
                                            updateValue={() => {}}
                                            updateIsValid={() => {}}
                                            isEnabled={true}
                                            objectKey={"none"}
                                        />
                                    </Data>
                                    <Data>
                                        <TitleAndTextInput
                                            title={t("menu.booth.detail.sideBar.menu.boothInfo.basicInfo.detail.jsonSchemaVersion")}
                                            value={boothInfo['boothVersionInfo']['boothJsonVersion']}
                                            isForEdit={false}
                                            isValid={true}
                                            updateValue={() => {}}
                                            updateIsValid={() => {}}
                                            isEnabled={true}
                                            objectKey={"none"}
                                        />
                                    </Data>
                                </DataContainer>
                            </CardContainer>
                        </Col>
                        {/* booth status data */}
                        {
                            boothDataLoadSuccess ?
                                <Col>
                                    <CardContainer>
                                        <TitleContainer>
                                            <Title>
                                                {t("menu.booth.detail.sideBar.menu.boothInfo.statusInfo.title")}
                                            </Title>
                                        </TitleContainer>
                                        <div
                                            style={{
                                                marginTop: '20px'
                                            }}
                                        >
                                            <StatusInner
                                                statusData={boothData['status']}
                                            />
                                        </div>
                                    </CardContainer>
                                </Col>
                                :
                                <Col>
                                    <CardContainer>
                                        <TitleContainer>
                                            <Title>
                                                {t("menu.booth.detail.sideBar.menu.boothInfo.statusInfo.title")}
                                            </Title>
                                        </TitleContainer>
                                        <DataContainer>
                                            <span
                                                style={{
                                                    color: '#757575'
                                                }}
                                            >
                                                {t("menu.booth.detail.sideBar.menu.boothInfo.loadFailedMessage")}
                                            </span>
                                        </DataContainer>
                                    </CardContainer>
                                </Col>
                        }
                    </Row>
                </Container>
                {/* modal */}
                <BoothVersionEditModal
                    boothId={boothInfo['boothId']}
                    isOpen={isBoothVersionEditModalOpen}
                    setIsOpen={setIsBoothVersionEditModalOpen}
                    onRefresh={fetchBoothBasicInfo}
                />
            </MainContainer>
        );
    }
};

export default BoothInfo;