import React, {useState} from 'react';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import './TitleAndSlideBoxStyle.css';
import { ReactComponent as ArrowDown } from '../../icons/arrow-down.svg'
import { MdKeyboardArrowDown } from "react-icons/md";
import UserService from "../../services/UserService";
import AuthService from "../../services/AuthService";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const TitleAndSlideBox = ({ title, roleName, children, icon, isActive, ring }) => {

    let res = false;

    if ((typeof roleName !== "undefined") || (roleName != null)) {
        roleName.map(roleNameTemp => {
            res = res || AuthService.hasAuthByRoleNameAndType(roleNameTemp, "GET");
        });
    }

    if (ring != null) {
        if (ring === 1) {
            res = AuthService.hasAuthForOverRingTwo();
        } else if (ring === 0) {
            res = AuthService.hasAuthForOverRingOne();
        }
    }

    if (res) {
        return (
            <div
                style={{
                    width: '100%'
                }}
            >
                <Accordion>
                    <AccordionItem
                        isActive={isActive}
                    >
                        {
                            ({open}) => (
                                <>
                                    <AccordionHeader
                                        className={"accordionHeaderBtn"}
                                    >
                                        {/* title and expand or shrink button */}
                                        <div
                                            style={{
                                                width: '100%',
                                                marginTop: '10px',
                                                borderBottom: '1px solid #ebebeb',
                                                paddingLeft: '5px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                paddingRight: '5px',
                                                justifyContent: 'center'
                                            }}
                                        >
                                        <span
                                            style={{
                                                marginLeft: '5px',
                                                position: 'relative',
                                                top: '-5px'
                                            }}
                                        >
                                            {icon}
                                        </span>
                                            <span
                                                style={{
                                                    fontSize: '13px',
                                                    marginLeft: '10px'
                                                }}
                                            >
                                            {title}
                                        </span>
                                            <span
                                                style={{
                                                    marginLeft: 'auto'
                                                }}
                                            >
                                            <MdKeyboardArrowDown
                                                size={"20"}
                                                style={
                                                    open ?
                                                        {
                                                            transform: 'rotate( 180deg )'
                                                        }
                                                        :
                                                        {}
                                                }
                                            />
                                        </span>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionBody>
                                        <div
                                            style={{
                                                width: '100%',
                                                padding: '0px',
                                                paddingTop: '5px'
                                            }}
                                        >
                                            {children}
                                        </div>
                                    </AccordionBody>
                                </>
                            )
                        }
                    </AccordionItem>
                </Accordion>

            </div>
        );
    } else {
        return <></>;
    }
};

export default TitleAndSlideBox;