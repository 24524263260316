import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import RenderOnRole from "../../auth/RenderOnRole";
import axios from "axios";
import {Col, Container, Image, Row} from "react-bootstrap";
import AuthService from "../../services/AuthService";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import ProductStatusCode from "../../enums/ProductStatusCode";
import DataParseService from "../../services/DataParseService";
import ImageUpload from "../common/image/ImageUpload";
import defaultBoxImage from "../../icons/productDefaultPicture.png";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import LanguageInput from "../../language/component/LanguageInput";
import LanguageSelectBox from "../common/lang/LanguageSelectBox";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../services/DeleteService";

const ProductDetail = () => {

    const { t, i18n } = useTranslation();
    const location = useLocation();

    /* path variable */
    const { productId } = useParams("productId");

    /* state for language */
    const targetFieldsString = `"${t("common.name")}", "${t("common.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* product detail */
    const [productInfo, setProductInfo] = useState({});
    const [isImageUpdateModalOpen, setIsImageUpdateModalOpen] = useState(false);

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    const navigate = useNavigate();

    /* fetch product information */
    const fetchProductInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product/${productId}`;
            const res = await UserService.updateToken(() => axios.get(
              fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            setProductInfo(res.data.item.productDetail);
        } catch (e) {
            console.log(e);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    const getStatusStr = (code) => {
        if (code === 0) {
            return t("menu.product.status.inactive");
        } if (code === 1) {
            return t("menu.product.status.active");
        } else {
            return t("menu.product.status.out_of_order");
        }
    };

    /* effects */
    useEffect(() => {
        fetchProductInfo();
    }, []);

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.PRODUCT_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/product"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.product.title"), t("menu.product.detail.title")]}
                    depth={2}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                {/* main area */}
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <Container fluid>
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <Image
                                src={
                                    (productInfo.productImageUrl == null) ?
                                        defaultBoxImage
                                        :
                                        `${productInfo.productImageUrl}`
                                }
                                alt="Profile Image"
                                style={{ width: '150px', height: '150px', display: 'inline-block', border: '0'}}
                            />
                            <div
                                style={{
                                    display: 'inline-block',
                                    marginTop: 'auto'
                                }}
                            >
                                <RenderOnRole type={"PUT"} roleName={ImsSystemRole.PRODUCT_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                    <button
                                        id={"img-edit-btn"}
                                        onClick={() => setIsImageUpdateModalOpen(true)}
                                    >
                                        {t("button.editProductImage")}
                                    </button>
                                </RenderOnRole>
                            </div>
                            <ImageUpload
                                isOpen={isImageUpdateModalOpen}
                                setIsOpen={setIsImageUpdateModalOpen}
                                usage={"product"}
                                usageId={productInfo.productId}
                                onRefresh={fetchProductInfo}
                            />
                        </Row>
                        <Row
                            style={{
                                marginTop: '5px'
                            }}
                        >
                            <LanguageSelectBox
                                title={t("language.selectBox.title.product")}
                                languageMetadataList={languageMetadataList}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}
                                setIsHighlightOn={setIsHighlightOn}
                                targetFieldString={targetFieldsString}
                            />
                        </Row>
                        <Row
                            style={{
                                marginTop: '10px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end'
                            }}
                        >
                            <Col
                                style={{ padding: '0px' }}
                            >
                                <TitleAndInputBox
                                    title={t("common.id")}
                                    value={productInfo.productId}
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                            <Col
                                style={{ padding: '0px', paddingLeft: '10px' }}
                            >
                                <LanguageInput
                                    type={"text"}
                                    title={t("common.name") + ` (${t("language.callName." + currentLang)})`}
                                    dataKeyTranslationPath={"language.dataKey.product.name"}
                                    valueMap={productInfo}
                                    updateValueMap={() => {}}
                                    isValidMap={{}}
                                    updateIsValidMap={()=>{}}
                                    isForEdit={false}
                                    currentLang={currentLang}
                                    isHighlightOn={isHighlightOn}
                                />
                            </Col>
                            <Col
                                style={{ padding: '0px', paddingLeft: '10px' }}
                            >
                                <TitleAndInputBox
                                    title={t("common.category")}
                                    value={productInfo['productCategoryInfo'] && productInfo['productCategoryInfo'][t("language.dataKey.product.category.name")]}
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <LanguageInput
                                type={"textarea"}
                                title={t("common.description") + ` (${t("language.callName." + currentLang)})`}
                                dataKeyTranslationPath={"language.dataKey.product.description"}
                                valueMap={productInfo}
                                updateValueMap={() => {}}
                                isValidMap={{}}
                                updateIsValidMap={()=>{}}
                                isForEdit={false}
                                height={"110"}
                                currentLang={currentLang}
                                isHighlightOn={isHighlightOn}
                            />
                        </Row>
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <Col
                                style={{ padding: '0px' }}
                                md={"6"}
                            >
                                <TitleAndInputBox
                                    title={t("common.amount")}
                                    value={productInfo.productAmount}
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                            <Col
                                md={"3"}
                                style={{ padding: '0px', paddingLeft: '10px' }}
                            >
                                <TitleAndInputBox
                                    title={t("common.unitAmount")}
                                    value={productInfo.productUnitAmount}
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                            <Col
                                md={"3"}
                                style={{ padding: '0px', paddingLeft: '10px' }}
                            >
                                <TitleAndInputBox
                                    title={t("common.unit")}
                                    value={productInfo.productUnit}
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                        </Row>
                        <Row
                            style={{ marginTop: '10px' }}
                        >
                            <Col
                                style={{ padding: '0px' }}
                            >
                                <TitleAndInputBox
                                    title={t("common.price")}
                                    value={productInfo.productPrice}
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                            <Col
                                style={{ padding: '0px', paddingLeft: '10px' }}
                            >
                                <TitleAndInputBox
                                    title={t("common.priceUnit")}
                                    value={productInfo.productPriceUnit}
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                            <Col
                                style={{ padding: '0px', paddingLeft: '10px' }}
                            >
                                <TitleAndInputBox
                                    title={t("common.group")}
                                    value={productInfo.productGroupName}
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                            <Col
                                style={{ padding: '0px', paddingLeft: '10px' }}
                            >
                                <TitleAndInputBox
                                    title={t("common.status")}
                                    value={getStatusStr(productInfo.productStatusCode)}
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <Col
                                style={{ padding: '0px' }}
                            >
                                <TitleAndInputBox
                                    title={t("common.generateDate")}
                                    value={DataParseService.dateTimeToDate(productInfo.registerDate)}
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                            <Col
                                style={{ padding: '0px', paddingLeft: '10px' }}
                            >
                                <TitleAndInputBox
                                    title={t("common.lastEditDate")}
                                    value={DataParseService.dateTimeToDate(productInfo.lastEditDate)}
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                            <Col
                                style={{ padding: '0px', paddingLeft: '10px' }}
                            >
                                <TitleAndInputBox
                                    title={t("common.futureRestockingDate")}
                                    value={
                                            (productInfo.futureRestockingDate == null) ?
                                            t("common.none")
                                            :
                                            DataParseService.dateTimeToDate(productInfo.futureRestockingDate)
                                    }
                                    isForEdit={false}
                                    type={"text"}
                                    isValid={true}
                                    onChange={() => {}}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* buttons */}
                <div
                    style={{
                        width: '100%',
                        height: '40px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        marginTop: '30px'
                    }}
                >
                    {/* delete button */}
                    <div>
                        <RenderOnRole
                            type={"DELETE"}
                            roleName={ImsSystemRole.PRODUCT_MANAGEMENT}
                            allowedRing={2}
                            isForSuperMaster={false}
                            nothingOnRejected={true}
                        >
                            <Link
                                id={"delete-btn"}
                                onClick={async () => {
                                    await setIsDeleteConfirmOpen(true);
                                }}
                            >
                                {t("button.delete")}
                            </Link>
                            {/* delete confirmation */}
                            <DeleteConfirmModal
                                isOpen={isDeleteConfirmOpen}
                                setIsOpen={setIsDeleteConfirmOpen}
                                isDelayed={true}
                                confirmMessage={t("language.message.deletion.confirmation", { entityName : productInfo[t("language.dataKey.product.name")] })}
                                entity={DeleteService.ValidEntities.PRODUCT}
                                entityName={productInfo[t("language.dataKey.product.name")]}
                                id={`product/${productId}`}
                                onDeleteSuccess={async () => {
                                    await navigate('/product');
                                }}
                                depsToShow={[]}
                                validateMessage={t("language.message.deletion.verificationMessage", { entityName : productInfo[t("language.dataKey.product.name")] })}
                                delayToUndo={DeleteService.getShortDelay()}
                                onUndoSuccess={async () => {
                                    const currLocation = location.pathname;
                                    await navigate(currLocation);
                                }}
                            />
                        </RenderOnRole>
                    </div>
                    <div
                        style={{
                            marginLeft: 'auto',
                            marginRight: '10px'
                        }}
                    >
                        <RenderOnRole type={"PUT"} roleName={ImsSystemRole.PRODUCT_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                            <Link
                                id={"edit-btn"}
                                to={`/product/edit/${productId}`}
                            >
                                {t("button.edit")}
                            </Link>
                        </RenderOnRole>
                    </div>
                    <div>
                        <Link
                            id={"save-btn"}
                            to={"/product"}
                        >
                            {t("button.complete")}
                        </Link>
                    </div>
                </div>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default ProductDetail;