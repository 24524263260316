import React, {useEffect, useState} from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";
import axios from "axios";
import {Col, Container, Image, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import RenderOnRole from "../../auth/RenderOnRole";
import DataCompareService from "../../services/DataCompareService";
import Modal from "react-modal";
import ImageUpload from "../common/image/ImageUpload";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import ModalCloseButton from "../common/modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const passwordModalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '500px',
        margin: 'auto'
    }
}

const StaffDetail = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { adminId } = useParams("adminId");

    const [adminInfo, setAdminInfo] = useState({});
    const [isAdminActive, setIsAdminActive] = useState(true);
    const [isImageEditModalOpen, setIsImageEditModalOpen] = useState(false);

    /* ------------------ */
    /* for password reset */
    /* ------------------ */

    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [passwordInput, setPasswordInput] = useState("");
    const [passwordVerifyInput, setPasswordVerifyInput] = useState("");
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isValidPasswordVerify, setIsValidPasswordVerify] = useState(false);

    const handlePasswordInput = (value) => {
        setPasswordInput(value);
    }

    const handlePasswordValidationInput = (value) => {
        setPasswordVerifyInput(value);
    }

    const checkPasswordValidity = () => {
        let validity = DataCompareService.regexValidator(passwordInput, "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&]{8,20}$");
        setIsValidPassword(validity);
    }

    const resetPassword = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${adminId}/password`;
            const res = await UserService.updateToken(() => axios.put(fetchUrl ,
                JSON.stringify({
                    "adminPassword" : passwordInput
                }),
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            setIsPasswordModalOpen(false);
            navigate(`/staff/detail/${adminId}`);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            setIsPasswordModalOpen(false);
            navigate(`/staff/detail/${adminId}`);
        }

    }

    useEffect(() => {
        checkPasswordValidity();
    }, [passwordInput]);

    useEffect(() => {
        setIsValidPasswordVerify(passwordInput === passwordVerifyInput);
    }, [passwordVerifyInput]);

    /* ------------------ */
    /* ------------------ */

    const isAdminActiveByAdminStatusCode = (code) => {
        if (code === 0) {
            return false;
        } else {
            return true;
        }
    }

    const fetchAdminInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${adminId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setIsAdminActive(isAdminActiveByAdminStatusCode(res.data.item.adminInfo.adminStatusCode));
            setAdminInfo(res.data.item.adminInfo);
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    }

    const inactivateAdmin = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${adminId}/inactivate`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl ,
                {},
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate(`/staff/detail/${adminId}`);
        } catch (err) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(err);
        }
    }

    const activateAdmin = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${adminId}/activate`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl ,
                {},
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate(`/staff/detail/${adminId}`);
        } catch (err) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(err);
        }
    }

    useEffect(() => {
        fetchAdminInfo();
    }, []);

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.STAFF_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/staff"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.staff.title"), t("menu.staff.detail.title")]}
                    depth={2}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                    <Container fluid>
                        <Row>
                            {/* main area */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '700px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                {/* top info */}
                                <div
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'left'
                                        }}
                                    >
                                        <div>
                                            <Image
                                                src={
                                                    (adminInfo.adminImageUrl == null) ?
                                                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                        :
                                                        `${adminInfo.adminImageUrl}`
                                                }
                                                alt="Profile Image"
                                                roundedCircle
                                                style={{ width: '70px', height: '70px', display: 'inline-block', border: '0'}}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: '20px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <div
                                                style={{ textAlign: 'left' }}
                                            >
                                                <span
                                                    style={{ fontWeight: 'bold' }}
                                                >
                                                    {adminInfo.adminName}
                                                </span>
                                            </div>
                                            <div
                                                style={{ textAlign: 'left' }}
                                            >
                                                <button
                                                    style={{
                                                        background: 'none',
                                                        border: 'none',
                                                        padding: '0px',
                                                        textDecoration: 'underline',
                                                        fontSize: '12px',
                                                        color: '#656565'
                                                    }}
                                                    onClick={() => setIsImageEditModalOpen(true)}
                                                >
                                                    {t("button.editImage")}
                                                </button>
                                                <ImageUpload
                                                    isOpen={isImageEditModalOpen}
                                                    setIsOpen={setIsImageEditModalOpen}
                                                    usage={"admin"}
                                                    usageId={adminInfo.adminId}
                                                    onRefresh={fetchAdminInfo}
                                                />
                                            </div>
                                            <div
                                                style={{ textAlign: 'left' }}
                                            >
                                                <RenderOnRole type={"PUT"} roleName={ImsSystemRole.STAFF_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                                    {
                                                        isAdminActive ?
                                                            <button
                                                                style={{
                                                                    background: 'none',
                                                                    border: 'none',
                                                                    padding: '0px',
                                                                    textDecoration: 'underline',
                                                                    fontSize: '12px',
                                                                    color: 'red'
                                                                }}
                                                                onClick={() => inactivateAdmin()}
                                                            >
                                                                {t("button.inactivateStaff")}
                                                            </button>
                                                            :
                                                            <button
                                                                style={{
                                                                    background: 'none',
                                                                    border: 'none',
                                                                    padding: '0px',
                                                                    textDecoration: 'underline',
                                                                    fontSize: '12px',
                                                                    color: 'blue'
                                                                }}
                                                                onClick={() => activateAdmin()}
                                                            >
                                                                {t("button.activateStaff")}
                                                            </button>
                                                    }
                                                </RenderOnRole>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* middle info */}
                                <div
                                    style={{
                                        borderTop: '1px solid #c2c2c2',
                                        width: '100%',
                                        height: '450px',
                                        display: 'flex',
                                        overflow: 'auto',
                                        padding: '20px 0px 20px 0px'
                                    }}
                                >
                                    <Container fluid>
                                        <Row>
                                            <TitleAndInputBox
                                                title={t("common.email")}
                                                value={adminInfo.adminEmail}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Row>
                                        <Row
                                            style={{ marginTop: '10px' }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.id")}
                                                value={adminInfo.adminId}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Row>
                                        <Row
                                            style={{ marginTop: '10px' }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.name")}
                                                value={adminInfo.adminName}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Row>
                                        <Row
                                            style={{ marginTop: '10px' }}
                                        >
                                            <Col
                                                md={"1"}
                                                style={{ padding: '0px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.phone")}
                                                    value={"+82"}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    onChange={() => {}}
                                                />
                                            </Col>
                                            <Col
                                                md={"11"}
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={"　"}
                                                    value={adminInfo.adminPhone}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    onChange={() => {}}
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{ marginTop: '10px' }}
                                        >
                                            <Col
                                                style={{ padding: '0px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.group")}
                                                    value={adminInfo.adminGroupName}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    onChange={() => {}}
                                                />
                                            </Col>
                                            <Col
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.company")}
                                                    value={adminInfo.adminCompanyName}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    onChange={() => {}}
                                                />
                                            </Col>
                                            <Col
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.department")}
                                                    value={adminInfo.adminDepartmentName}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    onChange={() => {}}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                {/* bottom btn */}
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'right'
                                    }}
                                >
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.STAFF_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                        <div
                                            style={{ marginRight: 'auto' }}
                                        >
                                            <Link
                                                id={"reset-password-btn"}
                                                onClick={() => setIsPasswordModalOpen(true)}
                                            >
                                                {t("button.resetPassword")}
                                            </Link>
                                        </div>
                                    </RenderOnRole>
                                    <div
                                        style={{ marginRight: '10px' }}
                                    >
                                        <RenderOnRole type={"PUT"} roleName={ImsSystemRole.STAFF_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                            <Link
                                                id={"edit-btn"}
                                                to={`/staff/edit/${adminId}`}
                                            >
                                                {t("button.edit")}
                                            </Link>
                                        </RenderOnRole>
                                    </div>
                                    <div>
                                        <Link
                                            id={"save-btn"}
                                            to={"/staff"}
                                        >
                                            {t("button.complete")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* password modal */}
            <Modal
                isOpen={isPasswordModalOpen}
                onRequestClose={() => setIsPasswordModalOpen(false)}
                style={passwordModalStyle}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <ModalCloseButton
                        title={t("button.resetPassword")}
                        setIsOpen={setIsPasswordModalOpen}
                    />
                    <div
                        style={{ marginTop: '30px' }}
                    >
                        <span
                            style={{
                                color: '#8f8f8f',
                                fontSize: '12px',
                                marginLeft: '10px'
                            }}
                        >
                            {t("common.password")}
                        </span>
                    </div>
                    <div>
                        <input
                            id={"data-view-input"}
                            type={"password"}
                            value={passwordInput}
                            onChange={(e) => handlePasswordInput(e.target.value)}
                            style={isValidPassword ? {} : { borderColor: 'red' }}
                        />
                    </div>
                    <div
                        style={{
                            marginTop: '20px'
                        }}
                    >
                        <span
                            style={{
                                color: '#8f8f8f',
                                fontSize: '12px',
                                marginLeft: '10px'
                            }}
                        >
                            {t("common.passwordVerify")}
                        </span>
                    </div>
                    <div>
                        <input
                            id={"data-view-input"}
                            type={"password"}
                            value={passwordVerifyInput}
                            onChange={(e) => handlePasswordValidationInput(e.target.value)}
                            style={isValidPasswordVerify ? {} : { borderColor: 'red' }}
                        />
                    </div>
                    <div
                        style={{
                            marginTop: '30px'
                        }}
                    >
                        <span
                            style={{
                                fontSize: '12px',
                                color: '#c2c2c2'
                            }}
                        >
                            {t("message.passwordRule")}
                        </span>
                    </div>
                    <div
                        style={{
                            marginTop: '50px'
                        }}
                    >
                        {
                            isValidPassword && isValidPasswordVerify ?
                                <button
                                    id={"long-save-btn"}
                                    onClick={() => resetPassword()}
                                >
                                    {t("button.save")}
                                </button>
                                :
                                <button
                                    id={"long-save-btn-disabled"}
                                >
                                    {t("button.save")}
                                </button>
                        }
                    </div>
                </div>
            </Modal>
        </RenderOnRole>
    );
};

export default StaffDetail;