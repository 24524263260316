import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import _ from "lodash";
import DataCompareService from "../../../services/DataCompareService";
import DivWithTitle from "./DivWithTitle";
import {Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../common/dataView/TitleAndTextArea";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import UserService from "../../../services/UserService";
import axios from "axios";
import styled from "styled-components";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";
import DeleteConfirmModal from "../../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../../services/DeleteService";

const SaveButton = styled.button`
  border: 1px solid #fc7242;
  color: #fc7242;
  background-color: white;
  font-size: 14px;
  padding: 5px 15px;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  margin-left: auto;
  
  &:hover {
    background-color: #FFF1EC;
  }
  
  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled.button`
  border: 1px solid #ff0000;
  color: #ff0000;
  background-color: white;
  font-size: 14px;
  padding: 5px 15px;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  
  &:hover {
    background-color: #ffe6e6;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  border: 1px solid #c2c2c2;
  color: #c2c2c2;
  background-color: white;
  font-size: 14px;
  padding: 5px 15px;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  margin-left: auto;
  
  &:focus {
    outline: none;
  }
`;

const requestBodyInit = {
    franchiseName: "",
    description: ""
};

const isValidMapInit = {
    franchiseName: true,
    description: true
};

const FranchiseBasicInfo = ({ onRefresh, data }) => {

    const { t } = useTranslation();
    const location = useLocation();
    const { franchiseId } = useParams();
    const navigate = useNavigate();

    /* states */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);

    /* confirmation */
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    /* utils */
    const updateHelper = (setState, key, value) => {
        setState(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* put franchise */
    const putFranchise = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise/${franchiseId}`;
            let newRequestBody = JSON.stringify(requestBody);
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                newRequestBody,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* on success */
            onRefresh(location.pathname);
            toast.success(<ToastAlertView message={t("message.saved")} />);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* handlers */
    const handleFranchiseNameInput = (value) => {
        updateHelper(setRequestBody, "franchiseName", value);
        if (typeof value === "undefined" || value == null || value === "") {
            updateHelper(setIsValidMap, "franchiseName", false);
        } else {
            updateHelper(setIsValidMap, "franchiseName", true);
        }
    };

    const handleDescriptionInput = (value) => {
        updateHelper(setRequestBody, "description", value);
        if (typeof value === "undefined" || value == null || value === "") {
            updateHelper(setIsValidMap, "description", false);
        } else {
            updateHelper(setIsValidMap, "description", true);
        }
    };

    /* effects */
    useEffect(() => {
        /* initialize */
        let newObj = {};
        newObj['franchiseName'] = data['franchiseName'];
        newObj['description'] = data['description'];
        setRequestBody(newObj);
        setFixedRequestBody(_.cloneDeep(newObj));
        setIsValidMap(isValidMapInit);
    }, [data]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);

    return (
        <DivWithTitle
            title={t("menu.franchise.detail.basicInfo.title")}
        >
            <Container fluid>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndInputBox
                        title={t("menu.franchise.add.detail.franchiseName")}
                        value={requestBody["franchiseName"]}
                        onChange={handleFranchiseNameInput}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap["franchiseName"]}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndTextArea
                        title={t("menu.franchise.add.detail.description")}
                        value={requestBody["description"]}
                        onChange={handleDescriptionInput}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap["description"]}
                        height={"90"}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <DeleteButton
                            onClick={async () => {
                                await setIsDeleteConfirmOpen(true);
                            }}
                        >
                            {t("button.delete")}
                        </DeleteButton>
                        {/* buttons */}
                        {
                            isEdited && isValid ?
                                <SaveButton
                                    onClick={putFranchise}
                                >
                                    {t("button.save")}
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("button.save")}
                                </SaveButtonDisabled>
                        }
                    </div>
                    {/* delete modal */}
                    <DeleteConfirmModal
                        isOpen={isDeleteConfirmOpen}
                        setIsOpen={setIsDeleteConfirmOpen}
                        isDelayed={true}
                        confirmMessage={t("language.message.deletion.confirmation", { entityName : data["franchiseName"] })}
                        entity={DeleteService.ValidEntities.FRANCHISE}
                        entityName={data["franchiseName"]}
                        id={`franchise/${data["franchiseId"]}`}
                        onDeleteSuccess={async () => {
                            /** TODO */
                            await navigate('/man-franchise');
                        }}
                        depsToShow={[t("language.message.deletion.depsList.franchise.menu"),
                            t("language.message.deletion.depsList.franchise.menuCategory"),
                            t("language.message.deletion.depsList.franchise.optionCategory"),
                            t("language.message.deletion.depsList.franchise.orderRequest"),
                            t("language.message.deletion.depsList.franchise.appApiKey")]}
                        validateMessage={t("language.message.deletion.verificationMessage", { entityName : data["franchiseName"] })}
                        delayToUndo={DeleteService.getLongDelay()}
                        onUndoSuccess={async () => {
                            await navigate('/man-franchise');
                        }}
                    />
                </Row>
            </Container>
        </DivWithTitle>
    );
};

export default FranchiseBasicInfo;