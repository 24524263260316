import React from 'react';
import uuid from 'react-uuid'
import {useTranslation} from "react-i18next";
import './MultiSelectStyle.css';
import { MultiSelect } from "react-multi-select-component";
import styled from "styled-components";

const SelectedItem = styled.button`
  background-color: white;
  color: #757575;
  border: 1px solid #757575;
  border-radius: 5px;
  margin-right: 5px;
  
  &:focus {
    outline: none;
  }
`;

/** valueList must have the format : [ { label, value, disabled } ] */
const TitleAndMultiSelectBox = ({ title, currentValueList, valueList, updateCallback, isValid, nonSelectMessage }) => {

    const { t } = useTranslation();

    /* multi-select renderer */
    const valueRenderer = (selected) => {
        if (!selected.length) {
            if (nonSelectMessage) {
                return nonSelectMessage;
            } else {
                return "Select...";
            }
        }

        return selected.length === 1 ? (
            <SelectedItem>{selected[0].label}</SelectedItem>
        ) : (
            selected.map(({ label }) => <SelectedItem key={label}>{label}</SelectedItem>)
        );
    };

    if (valueList == null || typeof valueList === "undefined" || valueList.length === 0) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            paddingLeft: '10px',
                            marginBottom: '5px'
                        }}
                    >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px'
                        }}
                    >
                        {title}
                    </span>
                    </div>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <select
                            id={"data-view-input"}
                            value={""}
                        >
                            <option>{t("common.noData")}</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            paddingLeft: '10px',
                            marginBottom: '5px'
                        }}
                    >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px'
                        }}
                    >
                        {title}
                    </span>
                    </div>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <MultiSelect
                            options={valueList}
                            value={currentValueList}
                            onChange={updateCallback}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={valueRenderer}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default TitleAndMultiSelectBox;