import _ from "lodash";

const Ajv = require("ajv");

const sortBoothVersionArray = async (givenArray) => {
    await givenArray.sort((a, b) => {
        /* First, compare by boothName */
        if (a.boothName < b.boothName) return -1;
        if (a.boothName > b.boothName) return 1;

        /* If boothName is the same, then compare by boothVersion */
        if (a.boothVersion < b.boothVersion) return -1;
        if (a.boothVersion > b.boothVersion) return 1;

        /* If both are the same, return 0 (no sorting) */
        return 0;
    });
};

const extractMetadataFromProductJsonList = (productJsonList) => {
    let newArray = [];
    productJsonList.map(jsonSchema => {
        newArray.push(jsonSchema['metadata']);
    });
    return newArray;
};

const extractJsonSchemaFromList = (id, list) => {
    let newSchema = {};
    list.map(schema => {
        if (schema['metadata']['id'] === id) {
            newSchema = _.cloneDeep(schema);
        }
    });
    return newSchema;
};

/** Note. ------------------------------ */
/** 1.  Possible types.                  */
/**   [1] string                         */
/**   [2] integer                        */
/**   [3] number                         */
/**   [4] boolean                        */
const validatePrimitiveData = (prevValidator, data) => {
    let validator = _.cloneDeep(prevValidator);

    /** cast integer to number */
    if (prevValidator['type'] === "integer") {
        validator['type'] = "number";
    }

    const ajv = new Ajv({
        strict: false,
        strictSchema: false
    });

    /** ------------------------------------------- */
    /** These lines are tentative.                  */
    /** When JSON server is completed, delete TODO. */
    /* for not a number, delete "multipleOf" */
    if ((validator['type'] !== "number")
        && (validator['type'] !== "integer")) {
        delete validator['multipleOf'];
    }
    /* for strings, set "maxLength" to be 1000 */
    if (validator['type'] === "string") {
        validator['maxLength'] = 1000;
    }
    /** ------------------------------------------- */

    try {
        const validate = ajv.compile(validator);
        let tempResult = validate(data);
        return tempResult;
    } catch (e) {
        console.log(e);
        return false;
    }
};

/** @param schema::Object
 * @param data::String */
const validateJsonDataWithSchema = (schema, data) => {

    /* non-object */
    if ((typeof schema === "undefined") || (schema == null) || (typeof schema !== "object")) {
        return false;
    }
    /* invalid schema */
    if ((typeof schema['spec'] === "undefined")
        || (schema['spec'] === null)
        || (typeof schema['spec']['attributes'] === "undefined")
        || (schema['spec']['attributes'] === null)) {
        return false;
    }

    /* extract all fields in the schema */
    let schemaObj = _.cloneDeep(schema['spec']['attributes']);
    let fieldKeys = Object.keys(schemaObj);

    /* parse data */
    let dataObj = {};
    try {
        dataObj = JSON.parse(data)['attributes'];
        if ((typeof dataObj === "undefined") || (dataObj == null)) {
            throw { "message" : "parse error", "data" : dataObj }
        }
    } catch (e) {
        /* invalid data */
        console.log(e);
        return false;
    }

    /* validation field by field */
    const ajv = new Ajv({
        strict: false,
        strictSchema: false
    });
    for (let i=0 ; i<fieldKeys.length ; i++) {
        if ((typeof dataObj[fieldKeys[i]] === "undefined") || (dataObj[fieldKeys[i]] == null)) {
            console.log(dataObj);
            /* data field key not exist */
            console.log(schemaObj);
            console.log(fieldKeys[i] + " does NOT exist.");
            return false;
        } else {
            /* validation */
            try {
                /** ------------------------------------------- */
                /** These lines are tentative.                  */
                /** When JSON server is completed, delete TODO. */
                /* for not a number, delete "multipleOf" */
                if ((schemaObj[fieldKeys[i]]['validator']['type'] !== "number")
                    || (schemaObj[fieldKeys[i]]['validator']['type'] !== "integer")) {
                    delete schemaObj[fieldKeys[i]]['validator']['multipleOf'];
                }
                /* for strings, set "maxLength" to be 1000 */
                if (schemaObj[fieldKeys[i]]['validator']['type'] === "string") {
                    schemaObj[fieldKeys[i]]['validator']['maxLength'] = 1000;
                }
                /** ------------------------------------------- */
                const validate = ajv.compile(schemaObj[fieldKeys[i]]['validator']);
                let tempResult = validate(dataObj[fieldKeys[i]]);
                if (!tempResult) {
                    console.log(schemaObj[fieldKeys[i]]['validator']);
                    console.log("JSON validation fails while checking " + fieldKeys[i]);
                    console.log("The data is >> " + dataObj[fieldKeys[i]] + ` (${typeof dataObj[fieldKeys[i]]})`);
                    return false;
                }
            } catch (e) {
                console.log(schemaObj[fieldKeys[i]]['validator']);
                console.log("JSON validation fails while checking " + fieldKeys[i]);
                console.log(e);
                return false;
            }
        }
    }
    return true;
};

/** @param jsonSchema::Object */
const generateDefaultData = (jsonSchema) => {
    /* non-object case */
    if ((typeof jsonSchema === "undefined") || (jsonSchema == null) || (typeof jsonSchema !== "object")) {
        return {};
    }
    /* invalid schema */
    if ((typeof jsonSchema['spec'] === "undefined")
        || (jsonSchema['spec'] === null)
        || (typeof jsonSchema['spec']['attributes'] === "undefined")
        || (jsonSchema['spec']['attributes'] === null)) {
        return {};
    }
    try {
        /* extract all fields in the schema */
        let schemaObj = jsonSchema['spec']['attributes'];
        let fieldKeys = Object.keys(schemaObj);

        /* data generation */
        let newData = {};

        for (let i=0 ; i<fieldKeys.length ; i++) {
            let defaultValueStr = schemaObj[fieldKeys[i]]['defaultValue'];
            let dataType = schemaObj[fieldKeys[i]]['validator']['type'];
            /** handle weird case : empty string */
            let isDefaultValueExist = (typeof defaultValueStr === "undefined") || (defaultValueStr == null) || (defaultValueStr === "");
            let isDefaultValueValid = true;
            /** When default value is out of range */
            if (dataType === "integer" || dataType === "number") {
                let parsedValue = parseFloat(defaultValueStr);
                let minVal = schemaObj[fieldKeys[i]]['validator']['minimum'];
                let maxVal = schemaObj[fieldKeys[i]]['validator']['maximum'];
                if (minVal > parsedValue || maxVal < parsedValue) {
                    if (schemaObj[fieldKeys[i]]['validator']['multipleOf'] != null) {
                        defaultValueStr = `${minVal + schemaObj[fieldKeys[i]]['validator']['multipleOf']}`;
                    } else {
                        defaultValueStr = `${minVal + 1}`;
                    }
                }
            }
            if ((dataType === "integer") || (dataType === "number") || (dataType === "boolean")) {
                if (isDefaultValueExist && isDefaultValueValid) {
                    defaultValueStr = `${_getDefaultValueWhenJsonBoom(dataType, schemaObj[fieldKeys[i]]['validator'])}`;
                }
            }
            /** -------------------------------- */
            let fieldValue = _makeDefaultData(dataType, defaultValueStr);
            if (fieldValue == null) {
                console.log(jsonSchema);
                console.log(`Generating ${fieldKeys[i]} failed. dataType:${dataType} & defaultValueStr:${defaultValueStr}`);
                return {};
            } else {
                newData[fieldKeys[i]] = fieldValue;
            }
        }

        let wrappedData = {};
        wrappedData['attributes'] = newData;

        return wrappedData;
    } catch (e) {
        console.log(e);
        return {};
    }
};

/** Note. ------------------------------ */
/** 1.  Possible types.                  */
/**   [1] string                         */
/**   [2] integer                        */
/**   [3] number                         */
/**   [4] boolean                        */
/** 2.  When parsing fails, return null. */
const _makeDefaultData = (type, defaultDataStr) => {
    if (type === "string") {
        return defaultDataStr;
    } else if (type === "integer") {
        let newInt = parseInt(defaultDataStr)
        return isNaN(newInt) ? null : newInt;
    } else if (type === "number") {
        let newNumber = parseFloat(defaultDataStr)
        return isNaN(newNumber) ? null : newNumber;
    } else if (type === "boolean") {
        try {
            let newBool = JSON.parse(defaultDataStr.toLowerCase());
            return newBool;
        } catch (e) {
            return null;
        }
    }
};

const getBoothVersionNameFromBoothVersionList = (id, boothVersionList) => {
    let res = "Unknown"
    boothVersionList.map(boothVersionInfo => {
        if (boothVersionInfo['boothVersionId'] === id) {
            res = boothVersionInfo['boothName'] + " " + boothVersionInfo['boothVersion'];
        }
    });
    return res;
};

/** Note. ------------------------------ */
/** 1.  Possible types.                  */
/**   [1] integer                        */
/**   [2] number                         */
/**   [3] boolean                        */
const _getDefaultValueWhenJsonBoom = (type, jsonSchema) => {
    if (type === "boolean") {
        return false;
    } else if ((type === "integer") || (type === "number")) {
        if (((typeof jsonSchema['minimum'] === "undefined") || (jsonSchema['minimum'] == null))
            || (typeof jsonSchema['maximum'] === "undefined") || (jsonSchema['maximum'] == null)) {
            return 0;
        }
        let minVal = jsonSchema['minimum'];
        let multipleOf = jsonSchema['multipleOf'];
        return minVal + multipleOf;
    }
};

const RecipeUtils = {
    sortBoothVersionArray,
    extractMetadataFromProductJsonList,
    extractJsonSchemaFromList,
    validateJsonDataWithSchema,
    generateDefaultData,
    getBoothVersionNameFromBoothVersionList,
    validatePrimitiveData
};

export default RecipeUtils;