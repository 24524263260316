import React from 'react';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import DataUtils from "../../../menu/common/utils/DataUtils";
import {Spinner} from "react-bootstrap";
import MessageView from "../common/component/MessageView";
import BoothMenuDiffUtils from "../common/service/BoothMenuDiffUtils";
import _ from "lodash";
import styled from "styled-components";


const TransButton = styled.button`
  width: 100%;
  display: block;
  background: none;
  border: none;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
`;

const MenuItemView = styled.div`
  width: 100%;
  margin-top: 5px;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
`;

const MenuItemViewSelected = styled.div`
  width: 100%;
  margin-top: 5px;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  background-color: #fff3e0;
`;

const MenuNameDisabled = styled.span`
  font-size: 14px;
  color: #c2c2c2;
  margin-left: 5px;
`;

const MenuNameEnabled = styled.span`
  font-size: 14px;
  color: #757575;
  margin-left: 5px;
`;

const MenuNameSelected = styled.span`
  font-size: 14px;
  color: #fc7242;
  margin-left: 5px;
`;

const BoothMenuAccordionView = ({ boothMenuList, selectedMenuId, isLoading, boothVersionId }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { boothId } = useParams();

    /* for menu selection */
    const redirectBaseUrl = `/booth/detail/${boothId}/menu-diff/menu`;

    const renderMenuItems = () => {
        let newMenuItems = [];
        /* sort with menu category + id */
        let newBoothMenuList = _.cloneDeep(boothMenuList);
        newBoothMenuList.sort((ol, or) => {
            return DataUtils.objectSortHelper('franchiseMenuCategoryId', ol['menuInfo'], or['menuInfo']);
        });
        newBoothMenuList.sort((ol, or) => {
            return DataUtils.objectSortHelper('franchiseMenuId', ol['menuInfo'], or['menuInfo']);
        });
        newBoothMenuList.map(menuInfoObj => {
            let menuInfo = menuInfoObj['menuInfo'];
            let targetMenuDetail = BoothMenuDiffUtils.extractTargetDetailEntityByBoothVersionBeforeMerge(menuInfoObj['menuDetailListBeforeMergeDiff'], boothVersionId);
            let targetDiffEntity = BoothMenuDiffUtils.extractTargetDetailDiffEntityByBoothId(menuInfoObj['menuDetailDiffList'], boothId);
            if (_.isEqual(targetDiffEntity, {})) {
                targetDiffEntity = null;
            }
            /* isEnabled */
            let isEnabled = targetDiffEntity == null ?
                targetMenuDetail['isEnabled']
                :
                (targetDiffEntity['isEnabled'] ? targetDiffEntity['isEnabled'] : targetMenuDetail['isEnabled']);
            /* label */
            let label = menuInfo[t("language.dataKey.menu.franchise.info.name")];
            if (DataUtils.hasIdInPath(location.pathname, menuInfo['franchiseMenuId'])) {
                /* selected */
                newMenuItems.push(
                    <TransButton
                        onClick={() => navigate(`${redirectBaseUrl}/${menuInfo['franchiseMenuId']}`)}
                        key={menuInfo['franchiseMenuId']}
                    >
                        <MenuItemViewSelected>
                            <div>
                                <MenuNameSelected>
                                    {label}
                                </MenuNameSelected>
                            </div>
                        </MenuItemViewSelected>
                    </TransButton>
                );
            } else {
                /* not selected */
                newMenuItems.push(
                    <TransButton
                        onClick={() => navigate(`${redirectBaseUrl}/${menuInfo['franchiseMenuId']}`)}
                        key={menuInfo['franchiseMenuId']}
                    >
                        <MenuItemView>
                            <div>
                                {
                                    isEnabled ?
                                        <MenuNameEnabled>
                                            {label}
                                        </MenuNameEnabled>
                                        :
                                        <MenuNameDisabled>
                                            {label}
                                        </MenuNameDisabled>
                                }
                            </div>
                        </MenuItemView>
                    </TransButton>
                );
            }
        });
        return newMenuItems;
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                backgroundColor: 'white'
            }}
        >
            {
                isLoading &&
                <div
                    style={{
                        width: '100%',
                        padding: '50px',
                        textAlign: 'center'
                    }}
                >
                    <Spinner size={"sm"} />
                </div>
            }
            {
                /* menu list empty */
                (!isLoading) && (boothMenuList.length === 0) &&
                <div
                    style={{
                        width: '100%',
                        padding: '0px',
                        textAlign: 'center'
                    }}
                >
                    <MessageView
                        message={t("menu.boothMenuDiff.messages.noMenuExist")}
                    />
                </div>
            }
            {
                /* good */
                (!isLoading) && (boothMenuList.length > 0) &&
                renderMenuItems()
            }
        </div>
    );
};

export default BoothMenuAccordionView;