import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import UserService from "../../../services/UserService";
import axios from "axios";
import DataParseService from "../../../services/DataParseService";
import _ from "lodash";
import {Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import TitleAndSelectBox from "../../common/dataView/TitleAndSelectBox";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import Modal from "react-modal";
import DataCompareService from "../../../services/DataCompareService";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '330px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    startDate: "",
    availableDays: 0
};

const isValidMapInit = {
    startDate: true,
    availableDays: true
};

const ApiKeyEdit = ({ isOpen, setIsOpen, onRefresh, keyInfo }) => {

    const { t } = useTranslation();

    /* states */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isEdited, setIsEdited] = useState(false);
    const [isValid, setIsValid] = useState(true);

    /* put key */
    const editKey = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-app-api-key`
                + `/franchise/${keyInfo['franchiseAppApiKeyFranchiseId']}/key/${keyInfo['franchiseAppApiKeyId']}`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            setIsOpen(false);
            onRefresh();
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* update helper */
    const updateHelper = (setState, key, value) => {
        setState(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* handlers */
    const handleStartDateInput = (value) => {
        updateHelper(setRequestBody, "startDate", value);
        /* validate */
        if (typeof value === "undefined" || value == null || value === "") {
            updateHelper(setIsValidMap, "startDate", false);
        } else {
            updateHelper(setIsValidMap, "startDate", true);
        }
    };

    const handleAvailableDaysInput = (value) => {
        updateHelper(setRequestBody, "availableDays", parseInt(value));
        /* validate */
        if (typeof value === "undefined" || value == null || parseInt(value) === NaN || parseInt(value) <= 0 || value === "") {
            updateHelper(setIsValidMap, "availableDays", false);
        } else {
            updateHelper(setIsValidMap, "availableDays", true);
        }
    };

    /* effects */
    useEffect(() => {
        /* initialize */
        let newRequestBody = {};
        newRequestBody['startDate'] = DataParseService.dateTimeToDate(keyInfo['startDate']);
        newRequestBody['availableDays'] = keyInfo['initialAvailableDays'];
        setFixedRequestBody(_.cloneDeep(newRequestBody));
        setRequestBody(newRequestBody);
        setIsValid(true);
        setIsEdited(false);
        setIsValidMap(isValidMapInit);
    }, [isOpen, keyInfo]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchiseApiKey.detail.button.edit")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    {/* start date */}
                    <TitleAndInputBox
                        title={t("menu.franchiseApiKey.issueKey.detail.startDate")}
                        value={requestBody["startDate"]}
                        onChange={handleStartDateInput}
                        isForEdit={true}
                        type={"date"}
                        isValid={isValidMap["startDate"]}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    {/* available days */}
                    <TitleAndInputBox
                        title={t("menu.franchiseApiKey.issueKey.detail.availableDays")}
                        value={requestBody["availableDays"]}
                        onChange={handleAvailableDaysInput}
                        isForEdit={true}
                        type={"number"}
                        isValid={isValidMap["availableDays"]}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '25px'
                    }}
                >
                    {
                        (isValid && isEdited)
                            ?
                            <button
                                id={"long-save-btn"}
                                onClick={editKey}
                            >
                                {t("button.save")}
                            </button>
                            :
                            <button
                                id={"long-save-btn-disabled"}
                            >
                                {t("button.save")}
                            </button>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default ApiKeyEdit;