import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import RenderOnRole from "../../auth/RenderOnRole";
import {Col, Container, Image, Row, Spinner} from "react-bootstrap";
import DataCompareService from "../../services/DataCompareService";
import { LuPackagePlus, LuPackageSearch, LuPackageCheck, LuPackageX } from "react-icons/lu";
import { TbTruckDelivery } from "react-icons/tb";
import uuid from "react-uuid";
import DataParseService from "../../services/DataParseService";
import AuthService from "../../services/AuthService";
import "./OrderDetailStyle.css";
import OrderProductEdit from "./orderProducts/OrderProductEdit";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import EditShippingAddress from "./EditShippingAddress";
import RegisterOnShip from "./RegisterOnShip";
import EditOnShipInfo from "./EditOnShipInfo";
import RegisterComplete from "./RegisterComplete";
import EditCompleteInfo from "./EditCompleteInfo";
import EditDeliveryCharge from "./EditDeliveryCharge";
import ConfirmModal from "../common/confirm/ConfirmModal";
import defaultBoxImage from "../../icons/productDefaultPicture.png";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../services/DeleteService";

const OrderDetail = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { orderId } = useParams();
    /* orderInfo */
    const [orderInfo, setOrderInfo] = useState({});
    const [priceUnit, setPriceUnit] = useState("");
    const [orderStatusCode, setOrderStatusCode] = useState(-1);
    const [orderProductList, setOrderProductList] = useState([]);
    const [orderComment, setOrderComment] = useState("");
    const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState([]);
    const [shipAddressInfo, setShipAddressInfo] = useState({});
    const [orderUserInfo, setOrderUserInfo] = useState({});
    const [deliveryHistoryInfo, setDeliveryHistoryInfo] = useState({});
    /* loading */
    const [isProductLoading, setIsProductLoading] = useState(false);
    /* modal */
    const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
    const [isAddrEditModalOpen, setIsAddrEditModalOpen] = useState(false);
    const [isRegisterOnShipModalOpen, setIsRegisterOnShipModalOpen] = useState(false);
    const [isEditOnShipModalOpen, setIsEditOnShipModalOpen] = useState(false);
    const [isRegisterCompleteModalOpen, setIsRegisterCompleteModalOpen] = useState(false);
    const [isEditCompleteModalOpen, setIsEditCompleteModalOpen] = useState(false);
    const [isEditDeliveryChargeModalOpen, setIsEditDeliveryChargeModalOpen] = useState(false);
    /* confirm modal */
    const [isOrderCancelModalOpen, setIsOrderCancelModalOpen] = useState(false);
    const [isPaymentCancelModalOpen, setIsPaymentCancelModalOpen] = useState(false);

    /* fetch order information */
    const fetchOrderInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/order/${orderId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            setOrderInfo(DataCompareService.deepCopyObj(res.data.item.orderDetail));
            /* extract price unit */
            setPriceUnit(res.data.item.orderDetail.deliveryChargeUnit);
            /* extract order status */
            setOrderStatusCode(res.data.item.orderDetail.orderStatusCode);
            /* extract order products */
            setOrderProductList(await fetchProductDetails(DataCompareService.deepCopyList(res.data.item.orderDetail.orderProducts)));
            /* extract comment */
            setOrderComment(res.data.item.orderDetail.deliveryHistoryInformation.comment);
            /* extract payment information */
            setIsPaymentCompleted(res.data.item.orderDetail.isPaymentCompleted);
            setPaymentInfo(res.data.item.orderDetail.orderPaymentInformation);
            /* extract shipping address information */
            let addressObj = {
                locationAddress : res.data.item.orderDetail.locationAddress,
                locationAddressDetail : res.data.item.orderDetail.locationAddressDetail,
                locationPostalCode : res.data.item.orderDetail.locationPostalCode
            };
            setShipAddressInfo(addressObj);
            /* extract order user information */
            let userInfoObj = {
                orderGroupId : res.data.item.orderDetail.orderGroupId,
                orderGroupName : res.data.item.orderDetail.orderGroupName,
                orderUserId : res.data.item.orderDetail.orderUserId,
                orderUserName : res.data.item.orderDetail.orderUserName,
            };
            setOrderUserInfo(userInfoObj);
            /* extract delivery history information */
            setDeliveryHistoryInfo(res.data.item.orderDetail.deliveryHistoryInformation);
        } catch (e) {
            console.log(e);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    /* fetch detail of the product list */
    const fetchProductDetails = async (prevList) => {
        try {
            setIsProductLoading(true);
            let newList = [...prevList];
            // for (let i=0 ; i < prevList.length; i++) {
            //     let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product/${prevList[i]["orderProductProductId"]}`;
            //     const res = await UserService.updateToken(() => axios.get(
            //         fetchUrl,
            //         {
            //             headers: {
            //                 Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
            //             }
            //         }
            //     ));
            //     newList[i]["productDetail"] = res.data.item.productDetail;
            // }
            await setIsProductLoading(false);
            return newList;
        } catch (e) {
            console.log(e);
        }
    }

    /* utils */
    const getTotalCost = (list) => {
        let result = 0;
        for (let i=0 ; i<list.length ; i++) {
            result = result + (list[i].price * list[i].amount);
        }
        return result;
    };

    /* cancel payment */
    const cancelPayment = async () => {
        if ((!isPaymentCompleted) || (paymentInfo[0].paymentStatusCode !== 1)) {
            return;
        }
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/payment/${paymentInfo[0].paymentId}`;
            const res = await UserService.updateToken(() => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            /* on success */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            fetchOrderInfo();
        } catch (e) {
            /* on fail */
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
            console.log(e);
        }
    };

    /* cancel order */
    const cancelOrder = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/order/${orderId}`;
            const res = await UserService.updateToken(() => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            /* on success */
            toast.success(<ToastAlertView message={t("message.deleted")} />);
            navigate('/order');
        } catch (e) {
            /* on fail */
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
            console.log(e);
        }
    };

    /* effects */
    useEffect(() => {
        fetchOrderInfo();
    }, []);

    /* renderers */
    const renderTaken = () => {
        let isActive = (orderStatusCode >= 0) && (orderStatusCode !== 4);
        return (
            <div
                style={{
                    width: '20%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <LuPackagePlus
                        color={isActive ? "#757575" : "#ebebeb"}
                        size={"80"}
                    />
                </div>
                <div>
                    <span
                        style={
                            isActive
                            ?
                                {
                                    color : "#757575",
                                    fontSize: '14px'
                                }
                                :
                                {
                                    color : "#ebebeb",
                                    fontSize: '14px'
                                }
                        }
                    >
                        {"1. " + t("menu.order.types.taken")}
                    </span>
                </div>
            </div>
        );
    };

    const renderPrepare = () => {
        let isActive = (orderStatusCode >= 1) && (orderStatusCode !== 4);
        return (
            <div
                style={{
                    width: '20%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <LuPackageSearch
                        color={isActive ? "#757575" : "#ebebeb"}
                        size={"80"}
                    />
                </div>
                <div>
                    <span
                        style={
                            isActive
                                ?
                                {
                                    color : "#757575",
                                    fontSize: '14px'
                                }
                                :
                                {
                                    color : "#ebebeb",
                                    fontSize: '14px'
                                }
                        }
                    >
                        {"2. " + t("menu.order.types.prepare")}
                    </span>
                </div>
            </div>
        );
    };

    const renderOnShip = () => {
        let isActive = (orderStatusCode >= 2) && (orderStatusCode !== 4);
        return (
            <div
                style={{
                    width: '20%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <TbTruckDelivery
                        color={isActive ? "#757575" : "#ebebeb"}
                        size={"80"}
                    />
                </div>
                <div>
                    <span
                        style={
                            isActive
                                ?
                                {
                                    color : "#757575",
                                    fontSize: '14px'
                                }
                                :
                                {
                                    color : "#ebebeb",
                                    fontSize: '14px'
                                }
                        }
                    >
                        {"3. " + t("menu.order.types.onShip")}
                    </span>
                </div>
            </div>
        );
    };

    const renderComplete = () => {
        let isActive = (orderStatusCode === 3);
        return (
            <div
                style={{
                    width: '20%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <LuPackageCheck
                        color={isActive ? "#757575" : "#ebebeb"}
                        size={"80"}
                    />
                </div>
                <div>
                    <span
                        style={
                            isActive
                                ?
                                {
                                    color : "#757575",
                                    fontSize: '14px'
                                }
                                :
                                {
                                    color : "#ebebeb",
                                    fontSize: '14px'
                                }
                        }
                    >
                        {"4. " + t("menu.order.types.complete")}
                    </span>
                </div>
            </div>
        );
    };

    const renderCanceled = () => {
        let isActive = (orderStatusCode === 4);
        return (
            <div
                style={{
                    width: '20%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <LuPackageX
                        color={isActive ? "#757575" : "#ebebeb"}
                        size={"80"}
                    />
                </div>
                <div>
                    <span
                        style={
                            isActive
                                ?
                                {
                                    color : "#757575",
                                    fontSize: '14px'
                                }
                                :
                                {
                                    color : "#ebebeb",
                                    fontSize: '14px'
                                }
                        }
                    >
                        {t("menu.order.types.cancel")}
                    </span>
                </div>
            </div>
        );
    };

    const renderProductList = () => {
        if (isProductLoading) {
            return (
                <Spinner/>
            );
        } else {
            return orderProductList.map((orderProduct, index) => {
                return (
                    <tr
                        key={uuid()}
                        style={{
                            borderBottom: '1px solid #ebebeb'
                        }}
                    >
                        <td>
                            <Image
                                src={
                                    (orderProduct.productImageUrl == null) ?
                                        defaultBoxImage
                                        :
                                        `${orderProduct.productImageUrl}`
                                }
                                alt="Profile Image"
                                style={{ width: '70px', height: '70px', display: 'inline-block', border: '0'}}
                            />
                            <table
                                style={{
                                    display: 'inline'
                                }}
                            >
                                <tr>
                                    <td
                                        style={{
                                            textAlign: 'left'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: '#757575',
                                                fontWeight: 'bold',
                                                fontSize: '14px',
                                                marginLeft: '10px'
                                            }}
                                        >
                                            {orderProduct[t("language.dataKey.product.name")]}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: 'left'
                                        }}
                                    >
                                        {
                                            orderProduct.productCategoryInformation ?
                                            <span
                                                style={{
                                                    color: '#c2c2c2',
                                                    marginLeft: '10px',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {orderProduct.productCategoryInformation[t("language.dataKey.product.category.name")]}
                                            </span>
                                            :
                                            <span
                                                style={{
                                                    color: '#ff0000',
                                                    marginLeft: '10px',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {t("language.message.deletion.depsList.order.deletedProductMessage")}
                                            </span>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td>
                            <span
                                style={{
                                    color: '#757575',
                                    fontSize: '14px'
                                }}
                            >
                                {orderProduct.productUnitAmount + " " + orderProduct.productUnit}
                            </span>
                        </td>
                        <td>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: 'black',
                                    fontSize: '14px'
                                }}
                            >
                                {orderProduct.amount}
                            </span>
                        </td>
                        <td>
                            <span
                                style={{
                                    color: '#757575',
                                    marginLeft: '10px',
                                    fontWeight: 'bold',
                                    fontSize: '14px'
                                }}
                            >
                                {DataParseService.formatNumberWithCommas(orderProduct.amount * orderProduct.price) + " " + priceUnit}
                            </span>
                        </td>
                    </tr>
                );
            });
        }
    };

    const renderPaymentInfo = () => {
        return (
            <div
                style={{
                    width: '100%',
                    border: '1px solid #ebebeb',
                    display: 'flex',
                    padding: '5px'
                }}
            >
                {
                    !isPaymentCompleted ?
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                            >
                                <span
                                    style={{
                                        color: '#757575',
                                        fontSize: '13px'
                                    }}
                                >
                                    {t("message.noPaymentInfo")}
                                </span>
                            </div>
                        </div>
                        :
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                padding: '5px'
                            }}
                        >
                            <Container fluid>
                                <Row>
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.order.detail.content.paymentInfo.paymentDate")}
                                            value={paymentInfo[0].paymentDate.slice(0, 10) + " " + paymentInfo[0].paymentDate.slice(11, 19)}
                                            onChange={() => {}}
                                            type={"text"}
                                            isForEdit={false}
                                            isValid={true}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.order.detail.content.paymentInfo.paymentAmount")}
                                            value={DataParseService.formatNumberWithCommas(paymentInfo[0].paymentAmount) + " " + paymentInfo[0].amountUnit}
                                            onChange={() => {}}
                                            type={"text"}
                                            isForEdit={false}
                                            isValid={true}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.order.detail.content.paymentInfo.pgId")}
                                            value={paymentInfo[0].pgPaymentCode}
                                            onChange={() => {}}
                                            type={"text"}
                                            isForEdit={false}
                                            isValid={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.order.detail.content.paymentInfo.paymentMethod")}
                                            value={t("language.enums.paymentMethod." + paymentInfo[0].paymentMethodCode)}
                                            onChange={() => {}}
                                            type={"text"}
                                            isForEdit={false}
                                            isValid={true}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.order.detail.content.paymentInfo.paymentStatus")}
                                            value={t("language.enums.paymentStatusCode." + paymentInfo[0].paymentStatusCode)}
                                            onChange={() => {}}
                                            type={"text"}
                                            isForEdit={false}
                                            isValid={true}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.order.detail.content.paymentInfo.taxInvoice")}
                                            value={t("language.enums.taxStatus." + paymentInfo[0].taxStatusCode)}
                                            onChange={() => {}}
                                            type={"text"}
                                            isForEdit={false}
                                            isValid={true}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                }
            </div>
        );
    };

    const renderShippingAddressInfo = () => {
        return (
            <div
                style={{
                    width: '100%',
                    padding: '5px'
                }}
            >
                <Container fluid>
                    <Row>
                        <Col
                            md={"2"}
                            style={{ padding: '0px' }}
                        >
                            <TitleAndInputBox
                                title={t("menu.order.detail.content.shipInfo.postalCode")}
                                value={shipAddressInfo.locationPostalCode}
                                onChange={() => {}}
                                type={"text"}
                                isForEdit={false}
                                isValid={true}
                            />
                        </Col>
                        <Col
                            md={"5"}
                            style={{ padding: '0px', paddingLeft: '10px' }}
                        >
                            <TitleAndInputBox
                                title={t("menu.order.detail.content.shipInfo.address")}
                                value={shipAddressInfo.locationAddress}
                                onChange={() => {}}
                                type={"text"}
                                isForEdit={false}
                                isValid={true}
                            />
                        </Col>
                        <Col
                            md={"5"}
                            style={{ padding: '0px', paddingLeft: '10px' }}
                        >
                            <TitleAndInputBox
                                title={t("menu.order.detail.content.shipInfo.detail")}
                                value={shipAddressInfo.locationAddressDetail}
                                onChange={() => {}}
                                type={"text"}
                                isForEdit={false}
                                isValid={true}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };

    {/* main */}
    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.ORDER_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={'/order'}
                />
                <PageTitleWithDepth
                    titles={[t("menu.order.title"), t("menu.order.detail.title")]}
                    depth={2}
                />
            </div>

            {/* main */}
            <div className={"contentContainer"}>
                {/* order basic info : date, group, user */}
                <div className={"contentInner"}>
                    <div
                        style={{
                            width: '100%',
                            display: 'inline-flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <span
                            style={{
                                color: '#757575',
                                marginRight: '30px',
                                fontSize: '14px'
                            }}
                        >
                            {DataParseService.dateTimeFormat(orderInfo.orderDate)}
                        </span>
                        <span
                            style={{
                                fontWeight: 'bold',
                                color: '#1a1a1a',
                                marginRight: '10px',
                                fontSize: '14px'
                            }}
                        >
                            {t("menu.order.detail.content.orderGroupName")}
                        </span>
                        <span
                            style={{
                                color: '#757575',
                                marginRight: '40px',
                                fontSize: '14px'
                            }}
                        >
                            {orderUserInfo.orderGroupName}
                        </span>
                        <span
                            style={{
                                fontWeight: 'bold',
                                color: '#1a1a1a',
                                marginRight: '10px',
                                fontSize: '14px'
                            }}
                        >
                            {t("menu.order.detail.content.orderUserName")}
                        </span>
                        <span
                            style={{
                                color: '#757575',
                                marginRight: '40px',
                                fontSize: '14px'
                            }}
                        >
                            {orderUserInfo.orderUserName + " (" + orderUserInfo.orderUserId + ")"}
                        </span>
                        {/* delete order */}
                        <RenderOnRole
                            type={"DELETE"}
                            roleName={ImsSystemRole.ORDER_MANAGEMENT}
                            isForSuperMaster={false}
                            nothingOnRejected={true}
                        >
                            <button
                                className={"topDeleteBtn"}
                                style={{
                                    marginLeft: 'auto'
                                }}
                                onClick={async () => {
                                    await setIsOrderCancelModalOpen(true);
                                }}
                            >
                                {t("menu.order.detail.content.deleteOrder")}
                            </button>
                            {/* confirmation */}
                            <DeleteConfirmModal
                                isOpen={isOrderCancelModalOpen}
                                setIsOpen={setIsOrderCancelModalOpen}
                                isDelayed={true}
                                confirmMessage={t("language.message.deletion.confirmation", { entityName : t("language.message.deletion.depsList.order.self") })}
                                entity={DeleteService.ValidEntities.ORDER}
                                entityName={t("language.message.deletion.depsList.order.self")}
                                id={`order/${orderId}`}
                                onDeleteSuccess={async () => {
                                    await navigate('/order');
                                }}
                                depsToShow={[
                                    t("language.message.deletion.depsList.order.payment")
                                ]}
                                validateMessage={t("language.message.deletion.verificationMessage", { entityName : t("language.message.deletion.depsList.order.self") })}
                                delayToUndo={DeleteService.getShortDelay()}
                                onUndoSuccess={async () => {
                                    const currLocation = location.pathname;
                                    await navigate(currLocation);
                                }}
                            />
                        </RenderOnRole>
                    </div>
                </div>
                {/* order status view area */}
                <div
                    className={"contentInner"}
                    style={{
                        marginTop: '10px'
                    }}
                >
                    {/* order status */}
                    <div
                        style={{
                            width: '100%',
                            height: '120px',
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '10px',
                            paddingBottom: '0px'
                        }}
                    >
                        {/* taken status */}
                        {renderTaken()}
                        {/* prepare status */}
                        {renderPrepare()}
                        {/* onShip status */}
                        {renderOnShip()}
                        {/* complete status */}
                        {renderComplete()}
                        {/* canceled status */}
                        {renderCanceled()}
                    </div>
                    {/* for top buttons */}
                    <div
                        style={{
                            width: '100%',
                            height: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            margin: '5px 0px'
                        }}
                    >
                        {/* taken */}
                        <div
                            style={{
                                width:'20%',
                                height: '100%',
                                textAlign: 'center'
                            }}
                        >
                            {/*{*/}
                            {/*    (orderStatusCode === 0)*/}
                            {/*        ?*/}
                            {/*        <RenderOnRole type={"DELETE"} roleName={ImsSystemRole.ORDER_MANAGEMENT} isForSuperMaster={false} nothingOnRejected={true}>*/}
                            {/*            <button*/}
                            {/*                className={"topDeleteBtn"}*/}
                            {/*                onClick={async () => {*/}
                            {/*                    await setIsOrderCancelModalOpen(true);*/}
                            {/*                }}*/}
                            {/*            >*/}
                            {/*                {t("menu.order.detail.content.deleteOrder")}*/}
                            {/*            </button>*/}
                            {/*        </RenderOnRole>*/}
                            {/*        :*/}
                            {/*        <></>*/}
                            {/*}*/}
                            {/*<ConfirmModal*/}
                            {/*    isOpen={isOrderCancelModalOpen}*/}
                            {/*    setIsOpen={setIsOrderCancelModalOpen}*/}
                            {/*    title={t("message.sureToCancelOrder")}*/}
                            {/*    confirmLabel={t("button.yes")}*/}
                            {/*    rejectLabel={t("button.no")}*/}
                            {/*    onConfirm={cancelOrder}*/}
                            {/*    onReject={() => {}}*/}
                            {/*/>*/}
                        </div>
                        {/* prepare */}
                        <div
                            style={{
                                width:'20%',
                                height: '100%',
                                textAlign: 'center'
                            }}
                        >
                            {/* intentional empty */}
                        </div>
                        {/* onShip */}
                        <div
                            style={{
                                width:'20%',
                                height: '100%',
                                textAlign: 'center'
                            }}
                        >
                            {
                                /* register onShip */
                                /* only PREPARE */
                                (orderStatusCode === 1)
                                    ?
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.ORDER_MANAGEMENT} isForSuperMaster={false} nothingOnRejected={true}>
                                        <button
                                            className={"topBtn"}
                                            onClick={() => {
                                                setIsRegisterOnShipModalOpen(true);
                                            }}
                                        >
                                            {t("menu.order.detail.content.registerOnShip")}
                                        </button>
                                    </RenderOnRole>
                                    :
                                    <></>
                            }
                            {
                                /* edit onShip */
                                /* only ON_SHIP */
                                (orderStatusCode === 2)
                                    ?
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.ORDER_MANAGEMENT} isForSuperMaster={false} nothingOnRejected={true}>
                                        <button
                                            className={"topBtn"}
                                            onClick={() => {
                                                setIsEditOnShipModalOpen(true);
                                            }}
                                        >
                                            {t("menu.order.detail.content.editOnShip")}
                                        </button>
                                    </RenderOnRole>
                                    :
                                    <></>
                            }
                            {/* register onShip modal */}
                            <RegisterOnShip
                                isOpen={isRegisterOnShipModalOpen}
                                setIsOpen={setIsRegisterOnShipModalOpen}
                                onRefresh={fetchOrderInfo}
                                orderId={orderId}
                            />
                            {/* edit onShip modal */}
                            <EditOnShipInfo
                                isOpen={isEditOnShipModalOpen}
                                setIsOpen={setIsEditOnShipModalOpen}
                                onRefresh={fetchOrderInfo}
                                orderId={orderId}
                                prevData={deliveryHistoryInfo}
                            />
                        </div>
                        {/* complete */}
                        <div
                            style={{
                                width:'20%',
                                height: '100%',
                                textAlign: 'center'
                            }}
                        >
                            {
                                /* register complete */
                                /* only ON_SHIP */
                                (orderStatusCode === 2)
                                    ?
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.ORDER_MANAGEMENT} isForSuperMaster={false} nothingOnRejected={true}>
                                        <button
                                            className={"topBtn"}
                                            onClick={() => setIsRegisterCompleteModalOpen(true)}
                                        >
                                            {t("menu.order.detail.content.registerComplete")}
                                        </button>
                                    </RenderOnRole>
                                    :
                                    <></>
                            }
                            {
                                /* edit complete */
                                /* only COMPLETE */
                                (orderStatusCode === 3)
                                    ?
                                    <RenderOnRole type={"PUT"} roleName={ImsSystemRole.ORDER_MANAGEMENT} isForSuperMaster={false} nothingOnRejected={true}>
                                        <button
                                            className={"topBtn"}
                                            onClick={() => setIsEditCompleteModalOpen(true)}
                                        >
                                            {t("menu.order.detail.content.editComplete")}
                                        </button>
                                    </RenderOnRole>
                                    :
                                    <></>
                            }
                            {/* register complete modal */}
                            <RegisterComplete
                                isOpen={isRegisterCompleteModalOpen}
                                setIsOpen={setIsRegisterCompleteModalOpen}
                                onRefresh={fetchOrderInfo}
                                orderId={orderId}
                            />
                            {/* register complete information modal */}
                            <EditCompleteInfo
                                isOpen={isEditCompleteModalOpen}
                                setIsOpen={setIsEditCompleteModalOpen}
                                onRefresh={fetchOrderInfo}
                                orderId={orderId}
                                prevData={deliveryHistoryInfo}
                            />
                        </div>
                        {/* cancel */}
                        <div
                            style={{
                                width:'20%',
                                height: '100%',
                                textAlign: 'center'
                            }}
                        >
                            {/* intentional empty */}
                        </div>
                    </div>
                </div>

                {/* scroll area */}
                <div
                    className={"scroll-hidden-order-detail"}
                    style={{
                        width: '100%',
                        height: '600px',
                        overflow: 'auto',
                        marginTop: '10px',
                        paddingBottom: '40px'
                    }}
                >
                    <Container fluid>
                        <Row>
                            <Col
                                style={{
                                    padding: '0px'
                                }}
                            >
                                {/* order products */}
                                <div
                                    className={"contentInner"}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            padding: '10px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row-reverse'
                                            }}
                                        >
                                            <RenderOnRole type={"POST"} roleName={ImsSystemRole.ORDER_MANAGEMENT} isForSuperMaster={false} nothingOnRejected={true}>
                                                <div
                                                    style={{
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    {/* if the order is not in "TAKEN" status, disable it. */}
                                                    <button
                                                        className={"editProductBtn"
                                                            + ((orderStatusCode === 0) ? "" : "-disabled")}
                                                        onClick={() => {
                                                            if (orderStatusCode === 0) {
                                                                setIsProductEditModalOpen(true);
                                                            } else {
                                                                return;
                                                            }
                                                        }}
                                                    >
                                                        {t("button.editOrderProduct")}
                                                    </button>
                                                    {/* product edit modal */}
                                                    <OrderProductEdit
                                                        isOpen={isProductEditModalOpen}
                                                        setIsOpen={setIsProductEditModalOpen}
                                                        onRefresh={fetchOrderInfo}
                                                        orderId={orderId}
                                                        orderGroupId={orderInfo.orderGroupId}
                                                        currentProductList={orderProductList}
                                                    />
                                                </div>
                                            </RenderOnRole>
                                            <div
                                                style={{
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                        color: (orderStatusCode === 4) ? '#ebebeb' : '#757575'
                                                    }}
                                                >
                                                    {t("menu.order.detail.content.products")}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '300px',
                                                border: '1px solid #ebebeb',
                                                marginTop: '5px',
                                                overflowY: 'auto'
                                            }}
                                        >
                                            <table>
                                                <thead
                                                    style={{
                                                        position: 'sticky',
                                                        top: '0px'
                                                    }}
                                                >
                                                    <tr>
                                                        <th className={"product-thead"}>
                                                            {t("menu.order.detail.content.productInfo")}
                                                        </th>
                                                        <th className={"product-thead"}>
                                                            {t("menu.order.detail.content.productUnitAmount")}
                                                        </th>
                                                        <th className={"product-thead"}>
                                                            {t("menu.order.detail.content.productAmount")}
                                                        </th>
                                                        <th className={"product-thead"}>
                                                            {t("menu.order.detail.content.totalPrice")}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    renderProductList()
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                marginTop: '5px'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    padding: '30px',
                                                    paddingTop: '15px',
                                                    paddingBottom: '15px'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'right'
                                                    }}
                                                >
                                            <span
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: '#1a1a1a'
                                                }}
                                            >
                                                {t("menu.order.detail.content.totalCost")}
                                            </span>
                                                </div>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        marginLeft: 'auto',
                                                        textAlign: 'right',
                                                        color: '#757575'
                                                    }}
                                                >
                                            <span>
                                                {DataParseService.formatNumberWithCommas(getTotalCost(orderProductList)) + " " + priceUnit}
                                            </span>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    padding: '30px',
                                                    paddingTop: '0px',
                                                    paddingBottom: '15px'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {
                                                        (orderStatusCode === 0 ) ?
                                                            <button
                                                                style={{ marginRight: '10px' }}
                                                                className={"topBtn"}
                                                                onClick={() => setIsEditDeliveryChargeModalOpen(true)}
                                                            >
                                                                {t("button.editDeliveryCharge")}
                                                            </button>
                                                            :
                                                            <></>
                                                    }
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            color: '#1a1a1a'
                                                        }}
                                                    >
                                                        {t("menu.order.detail.content.deliveryCharge")}
                                                    </span>
                                                    <EditDeliveryCharge
                                                        prevData={{
                                                            "deliveryCharge" : orderInfo.deliveryCharge,
                                                            "deliveryChargeUnit" : priceUnit
                                                        }}
                                                        isOpen={isEditDeliveryChargeModalOpen}
                                                        setIsOpen={setIsEditDeliveryChargeModalOpen}
                                                        orderId={orderId}
                                                        onRefresh={fetchOrderInfo}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        marginLeft: 'auto',
                                                        textAlign: 'right',
                                                        color: '#757575'
                                                    }}
                                                >
                                            <span>
                                                {DataParseService.formatNumberWithCommas(orderInfo.deliveryCharge) + " " + priceUnit}
                                            </span>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    padding: '30px',
                                                    paddingTop: '0px',
                                                    paddingBottom: '15px'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            color: '#1a1a1a'
                                                        }}
                                                    >
                                                        {t("menu.order.detail.content.totalPrice")}
                                                    </span>
                                                </div>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        marginLeft: 'auto',
                                                        textAlign: 'right',
                                                        color: '#757575'
                                                    }}
                                                >
                                                    <span>
                                                        {DataParseService.formatNumberWithCommas(getTotalCost(orderProductList) + orderInfo.deliveryCharge) + " " + priceUnit}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                {/* delivery info */}
                                <div
                                    className={"contentInner"}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex'
                                            }}
                                        >
                                            <div>
                                            <span
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: (orderStatusCode === 4 || orderStatusCode === 0) ? '#ebebeb' : '#757575'
                                                }}
                                            >
                                                {t("menu.order.detail.content.deliveryHistory.title")}
                                            </span>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                marginTop: '5px',
                                                padding: '5px'
                                            }}
                                        >
                                            <Container fluid>
                                                <Row>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            textAlign : 'right'
                                                        }}
                                                    >
                                                    <span
                                                        style={{
                                                            color: '#757575',
                                                            fontSize: '14px',
                                                            fontWeight: 'bold',
                                                            marginRight: '15px'
                                                        }}
                                                    >
                                                        {t("common.status") + " : "}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: '#757575',
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        {t("language.enums.deliveryStatusCode." + deliveryHistoryInfo.deliveryStatusCode)}
                                                    </span>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <TitleAndInputBox
                                                        title={t("menu.order.detail.content.deliveryHistory.readyStartDate")}
                                                        value={(deliveryHistoryInfo.readyStartDate != null) ?
                                                            DataParseService.dateTimeToDate(deliveryHistoryInfo.readyStartDate)
                                                            :
                                                            ""}
                                                        type={"date"}
                                                        onChange={()=>{}}
                                                        isForEdit={false}
                                                        isValid={true}
                                                        isEnabled={(deliveryHistoryInfo.readyStartDate != null)}
                                                    />
                                                </Row>
                                                <Row
                                                    style={{
                                                        marginTop: '10px'
                                                    }}
                                                >
                                                    <TitleAndInputBox
                                                        title={t("menu.order.detail.content.deliveryHistory.readyCompleteDate")}
                                                        value={(deliveryHistoryInfo.readyCompleteDate != null) ?
                                                            DataParseService.dateTimeToDate(deliveryHistoryInfo.readyCompleteDate)
                                                            :
                                                            ""}
                                                        type={"date"}
                                                        onChange={()=>{}}
                                                        isForEdit={false}
                                                        isValid={true}
                                                        isEnabled={(deliveryHistoryInfo.readyCompleteDate != null)}
                                                    />
                                                </Row>
                                                <Row
                                                    style={{
                                                        marginTop: '10px'
                                                    }}
                                                >
                                                    <TitleAndInputBox
                                                        title={t("menu.order.detail.content.deliveryHistory.OnShipStartDate")}
                                                        value={(deliveryHistoryInfo.onShipStartDate != null) ?
                                                            DataParseService.dateTimeToDate(deliveryHistoryInfo.onShipStartDate)
                                                            :
                                                            ""}
                                                        type={"date"}
                                                        onChange={()=>{}}
                                                        isForEdit={false}
                                                        isValid={true}
                                                        isEnabled={(deliveryHistoryInfo.onShipStartDate != null)}
                                                    />
                                                </Row>
                                                <Row
                                                    style={{
                                                        marginTop: '10px',
                                                        paddingBottom: '97px'
                                                    }}
                                                >
                                                    <TitleAndInputBox
                                                        title={t("menu.order.detail.content.deliveryHistory.OnShipCompleteDate")}
                                                        value={(deliveryHistoryInfo.onShipCompleteDate != null) ?
                                                            DataParseService.dateTimeToDate(deliveryHistoryInfo.onShipCompleteDate)
                                                            :
                                                            ""}
                                                        type={"date"}
                                                        onChange={()=>{}}
                                                        isForEdit={false}
                                                        isValid={true}
                                                        isEnabled={(deliveryHistoryInfo.onShipCompleteDate != null)}
                                                    />
                                                </Row>
                                            </Container>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                style={{
                                    padding: '0px'
                                }}
                            >
                                {/* location information */}
                                <div
                                    className={"contentInner"}
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            padding: '10px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row-reverse'
                                            }}
                                        >
                                            <RenderOnRole type={"PUT"} roleName={ImsSystemRole.ORDER_MANAGEMENT} isForSuperMaster={false} nothingOnRejected={true}>
                                                <div
                                                    style={{
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    {/* if the order is not in "TAKEN" nor "PREPARE" status, disable it. */}
                                                    <button
                                                        className={"editProductBtn"
                                                            + ((orderStatusCode <= 1) ? "" : "-disabled")}
                                                        onClick={() => {
                                                            if (orderStatusCode <= 1) {
                                                                setIsAddrEditModalOpen(true)
                                                            } else {
                                                                return;
                                                            }
                                                        }}
                                                    >
                                                        {t("button.editShipAddress")}
                                                    </button>
                                                    {/* shipping address edit modal */}
                                                    <EditShippingAddress
                                                        isOpen={isAddrEditModalOpen}
                                                        setIsOpen={setIsAddrEditModalOpen}
                                                        onRefresh={fetchOrderInfo}
                                                        orderId={orderId}
                                                        data={DataCompareService.deepCopyObj(shipAddressInfo)}
                                                    />
                                                </div>
                                            </RenderOnRole>
                                            <div
                                                style={{
                                                    marginRight: 'auto'
                                                }}
                                            >
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                color: (orderStatusCode === 4) ? '#ebebeb' : '#757575'
                                            }}
                                        >
                                            {t("menu.order.detail.content.shipInfo.title")}
                                        </span>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                marginTop: '23px'
                                            }}
                                        >
                                            {
                                                renderShippingAddressInfo()
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                {/* order comment */}
                                <div
                                    className={"contentInner"}
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            padding: '10px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex'
                                            }}
                                        >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            color: '#757575'
                                        }}
                                    >
                                        {t("menu.order.detail.content.comment")}
                                    </span>
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '103px',
                                                marginTop: '5px'
                                            }}
                                        >
                                            <textarea
                                                readOnly={true}
                                                value={(orderComment !== "") ? orderComment : t("menu.order.detail.content.noComment")}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    border: '1px solid #ebebeb',
                                                    resize: 'none',
                                                    outline: 'none',
                                                    padding: '10px',
                                                    color: '#757575',
                                                    fontSize: '14px'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {/* payment information */}
                            <div
                                className={"contentInner"}
                                style={{
                                    marginTop: '10px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        padding: '10px'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row-reverse'
                                        }}
                                    >
                                        <RenderOnRole type={"DELETE"} roleName={ImsSystemRole.ORDER_MANAGEMENT} isForSuperMaster={false} nothingOnRejected={true}>
                                            <div
                                                style={{
                                                    marginRight: '10px'
                                                }}
                                            >
                                                {/* if the payment is not completed or not in success state, disable it. */}
                                                <button
                                                    className={"cancelPaymentBtn"
                                                        + ((isPaymentCompleted && (paymentInfo[0].paymentStatusCode === 1)) ? "" : "-disabled")}
                                                    onClick={() => setIsPaymentCancelModalOpen(true)}
                                                >
                                                    {t("button.cancelPayment")}
                                                </button>
                                            </div>
                                        </RenderOnRole>
                                        <RenderOnRole type={"DELETE"} roleName={ImsSystemRole.ORDER_MANAGEMENT} isForSuperMaster={false} nothingOnRejected={true}>
                                            <ConfirmModal
                                                isOpen={isPaymentCancelModalOpen}
                                                setIsOpen={setIsPaymentCancelModalOpen}
                                                title={t("message.sureToCancelPayment")}
                                                confirmLabel={t("button.yes")}
                                                rejectLabel={t("button.no")}
                                                onConfirm={cancelPayment}
                                                onReject={() => {}}
                                            />
                                        </RenderOnRole>
                                        <div
                                            style={{
                                                marginRight: 'auto'
                                            }}
                                        >
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                color: (orderStatusCode === 0 || orderStatusCode === 4) ? '#ebebeb' : '#757575'
                                            }}
                                        >
                                            {t("menu.order.detail.content.paymentInfo.title")}
                                        </span>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            marginTop: '5px'
                                        }}
                                    >
                                        {
                                            renderPaymentInfo()
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default OrderDetail;