import React from 'react';
import styled from "styled-components";
import {Spinner} from "react-bootstrap";

const Button = styled.button`
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
  background: none;
  padding: 7px 15px;
  transition: all ease-in-out 0.2s;
  text-align: center;
  
  &:focus {
    outline: none;
  }
`;

const ButtonWithSpinner = ({ buttonColor }) => {

    return (
        <Button
            style={{
                color: buttonColor,
                border: '1px solid ' + buttonColor
            }}
        >
            <Spinner size={"sm"} />
        </Button>
    );

};

export default ButtonWithSpinner;