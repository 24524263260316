import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import "./RoleManagementStyle.css";
import {Link, Outlet, useNavigate} from "react-router-dom";
import axios from "axios";
import UserService from "../../services/UserService";
import NotAllowed from "../../auth/NotAllowed";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import RenderOnRole from "../../auth/RenderOnRole";

const RoleManagement = () => {

    /* -------------------------------------------------------- */
    /* Keycloak Roles : IMS_SYSTEM_MANAGEMENT, GROUP_MANAGEMENT */
    /* IMS Systems Roles : none                                 */
    /* -------------------------------------------------------- */

    const {t} = useTranslation();
    const navigate = useNavigate();
    const [groupList, setGroupList] = useState([]);
    const [groupToggleList, setGroupToggleList] = useState([]);

    useEffect(() => {
        fetchGroupList();
    }, []);

    const fetchGroupList = async () => {
        try {
            const res = await UserService.updateToken(() => axios.get(`${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`, {
                headers : {
                    "Authorization" : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }));
            let newGroupList = res.data.item["groupList"];
            /* master group filtering */
            newGroupList.map((group, index) => {
                if (group["masterGroup"]) {
                    newGroupList.splice(index, 1);
                }
            });
            setGroupList(newGroupList);
            setGroupToggleList(Array(newGroupList.length).fill(false));
            if (newGroupList.length > 0) {
                handleToggleChange(0);
                navigate(`${newGroupList[0].groupId}`);
            }
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    }

    const handleToggleChange = (idx) => {
        if (groupToggleList[idx]) {
            return;
        }
        setGroupToggleList((prevList) => {
            let newList = Array(prevList.length).fill(false);
            newList[idx] = true;
            return newList;
        })
    }

    const groupNavigateBarItemRender = (group, idx, to) => {
        if (group["masterGroup"]) {
            return (<></>);
        }
        return (
            <button
                key={to}
                className={"groupListButton" + (groupToggleList[idx] ? "-selected" : "")}
                onClick={() => {
                    handleToggleChange(idx);
                    navigate(to);
                }}
            >
                {group["groupName"]}
            </button>
        );
    }
    /* Authorization */
    return (
        <RenderOnRole roleName={""} type={""} allowedRing={0} isForSuperMaster={false} nothingOnRejected={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.role.group")]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    <Row>
                        <Col
                            md={"2"}
                            style={{
                                padding: '0px'
                            }}
                        >
                            {/* Links for routing */}
                            <div
                                className={"contentInner"}
                                style={{
                                    height: '780px',
                                    overflowX: 'auto'
                                }}
                            >
                                {
                                    groupList.map((group, idx) => {
                                        return groupNavigateBarItemRender(group, idx, group["groupId"]);
                                    })
                                }
                            </div>
                        </Col>
                        <Col
                            md={"10"}
                            style={{
                                padding: '0px',
                                paddingLeft: '10px'
                            }}
                        >
                            <div
                                className={"contentInner"}
                                style={{
                                    width: '100%',
                                    height: '780px'
                                }}
                            >
                                <Outlet />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default RoleManagement;