import React from 'react';
import styled from "styled-components";

const MessageView = ({ message }) => {
    return (
        <div
            style={{
                width: '100%',
                textAlign: 'center',
                padding: '20px 10px',
                color: '#757575',
                fontSize: '14px'
            }}
        >
            {message}
        </div>
    );
};

export default MessageView;