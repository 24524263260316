import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import CommonUtil from "../../utils/CommonUtil";
import BoothService from "../../axiosServices/BoothService";
import {Col, Container, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import _ from "lodash";
import ServerFailed from "../info/ServerFailed";
import styled from "styled-components";
import { MdCircle } from "react-icons/md";
import { ReactComponent as FileIcon } from "../../../../../icons/file_icon.svg";
import uuid from "react-uuid";

const SensorAloneContainer = styled.div`
  width: 100%;
  border: none;
  margin-bottom: 10px;
  padding: 0px 10px;
`;

const SensorAloneButton = styled.button`
  padding: 10px 10px;
  text-align: left;
  width: 100%;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #262626;
  transition: all ease-in-out 0.2s;
  position: relative;
  
  &:focus {
    outline: none;
  }
`;

const SensorMultipleButton = styled.button`
  padding: 10px 20px 10px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #262626;
  transition: all ease-in-out 0.2s;
  position: relative;

  &:focus {
    outline: none;
  }
`;

const SensorCategoryContainer = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 10px;
`;

const InnerTitleContainer = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  border-bottom: 1px solid #e4e7ea;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
`;

const InnerTitle = styled.div`
  color: #848487;
  text-align: left;
`;

const SensorContainer = styled.div`
  width: 100%;
  padding: 20px 0px 10px 10px;
  border-radius: 5px;
  background-color: white;
  text-align: left;
`;

const SensorView = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    /* params */
    const { boothId, deviceId, sensorId } = useParams();

    /* booth schema */
    const boothSchema = JSON.parse(window.localStorage.getItem("ims_json_schema"));
    const schemaVersion = boothSchema['metadata']['id'];

    /* device schema */
    const deviceSchema = CommonUtil.getObjectInListById(deviceId, boothSchema['spec']['devices']);

    /* booth data */
    const [boothData, setBoothData] = useState({});

    /* sensor data */
    const [sensorData, setSensorData] = useState([]);

    /* loading */
    const [isLoading, setIsLoading] = useState(true);

    /* fetch booth data */
    const fetchBoothData = async () => {
        const onSuccessCallBack = (data) => {
            setBoothData(data);
            setSensorData(data[schemaVersion][deviceId]['sensors']);
            setIsLoading(false);
        };
        const onFailureCallBack = () => {
            setBoothData({});
            setSensorData([]);
            setIsLoading(false);
        };
        BoothService.fetchBoothData(boothId, onSuccessCallBack, onFailureCallBack);
    };

    const categorizeSensorsByName = (sensorSchemaList) => {
        /* { categoryName : [sensors] } */
        let categorizedSensors = {};
        sensorSchemaList.map(sensor => {
            let categoryName = sensor['metadata']['name'];
            if (Object.keys(categorizedSensors).includes(categoryName)) {
                /* already existing category */
                categorizedSensors[categoryName].push(sensor);
            } else {
                /* a new category */
                categorizedSensors[categoryName] = [];
                categorizedSensors[categoryName].push(sensor);
            }
        });
        return categorizedSensors;
    };

    /* effects */
    /* fetch booth data */
    useEffect(() => {
        fetchBoothData();
    }, [deviceId, sensorId]);

    const renderStateDetail = (statusInfo) => {
        return (
            <Tooltip id="tooltip">
                <div>
                    <span
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        {statusInfo['state']}
                    </span>
                </div>
                <div>
                    <span>
                        {statusInfo['reason']}
                    </span>
                </div>
            </Tooltip>
        );
    }

    const renderSensors = () => {
        if (sensorData.length === 0) {
            return (<></>);
        } else {
            let categoryElements = [];
            let categorizedSensors = categorizeSensorsByName(deviceSchema['spec']['sensors']);
            const sortTwoListByLengthAsc = (a, b) => {
                return (categorizedSensors[a].length - categorizedSensors[b].length);
            };
            Object.keys(categorizedSensors).sort(sortTwoListByLengthAsc).map(categoryName => {
                if (categorizedSensors[categoryName].length === 1) {
                    /* only one sensor */
                    let sensorSchema = categorizedSensors[categoryName][0];
                    if (Object.keys(sensorData).includes(sensorSchema['metadata']['id'])) {
                        categoryElements.push(
                            <SensorAloneContainer>
                                <OverlayTrigger placement={"right"} overlay={renderStateDetail(sensorData[sensorSchema['metadata']['id']]['status'])}>
                                    <SensorAloneButton
                                        style={
                                            CommonUtil.hasRelativePath(location.pathname, 7, sensorSchema['metadata']['id']) ?
                                                {
                                                    backgroundColor: '#fc7242',
                                                    color: 'white',
                                                    borderColor: '#fc7242'
                                                }
                                                :
                                                {}
                                        }
                                        onClick={() => navigate(sensorSchema['metadata']['id'])}
                                    >
                                        <div
                                            style={{
                                                display: 'inline-block'
                                            }}
                                        >
                                            {sensorSchema['metadata'][t("language.dataKey.boothControl.json.label")]}
                                        </div>
                                        <div
                                            style={{
                                                display: 'inline-block',
                                                marginLeft: '20px',
                                                position: 'absolute',
                                                right: '1%',
                                                bottom: '27px'
                                            }}
                                        >
                                            <MdCircle
                                                size={"10"}
                                                color={CommonUtil.getColorCodeByState(sensorData[sensorSchema['metadata']['id']]['status']['state'])}
                                            />
                                        </div>
                                    </SensorAloneButton>
                                </OverlayTrigger>
                            </SensorAloneContainer>
                        );
                    }
                } else {
                    /* two or more sensors */
                    categoryElements.push(
                        <SensorCategoryContainer>
                            <InnerTitleContainer>
                                <InnerTitle>
                                    {
                                        CommonUtil.extractGroupLabels(deviceSchema, categoryName)[t("language.dataKey.boothControl.json.label")]
                                    }
                                </InnerTitle>
                            </InnerTitleContainer>
                            <SensorContainer>
                                {
                                    categorizedSensors[categoryName].map(sensorSchema => {
                                        if (Object.keys(sensorData).includes(sensorSchema['metadata']['id'])) {
                                            return (
                                                <div
                                                    key={uuid()}
                                                    style={{
                                                        display: 'inline-block',
                                                        marginRight: '10px',
                                                        marginBottom: '10px'
                                                    }}
                                                >
                                                    <OverlayTrigger placement={"right"} overlay={renderStateDetail(sensorData[sensorSchema['metadata']['id']]['status'])}>
                                                        <SensorMultipleButton
                                                            style={
                                                                CommonUtil.hasRelativePath(location.pathname, 7, sensorSchema['metadata']['id']) ?
                                                                    {
                                                                        backgroundColor: '#fc7242',
                                                                        color: 'white',
                                                                        borderColor: '#fc7242'
                                                                    }
                                                                    :
                                                                    {}
                                                            }
                                                            onClick={() => navigate(sensorSchema['metadata']['id'])}
                                                        >
                                                            <div>
                                                                {sensorSchema['metadata'][t("language.dataKey.boothControl.json.label")]}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    marginLeft: '20px',
                                                                    position: 'absolute',
                                                                    left: '72%',
                                                                    bottom: '27px'
                                                                }}
                                                            >
                                                                <MdCircle
                                                                    size={"10"}
                                                                    color={CommonUtil.getColorCodeByState(sensorData[sensorSchema['metadata']['id']]['status']['state'])}
                                                                />
                                                            </div>
                                                        </SensorMultipleButton>
                                                    </OverlayTrigger>
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </SensorContainer>
                        </SensorCategoryContainer>
                    );
                }
            });
            return categoryElements;
        }
    };

    const renderChooseSensor = () => {
        return (
            <div
                style={{
                    width: '100%',
                    padding: '50px',
                    borderRadius: '5px',
                    backgroundColor: 'white'
                }}
            >
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <FileIcon />
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '15px'
                    }}
                >
                    <span
                        style={{
                            color: '#595959'
                        }}
                    >
                        {t("menu.boothControl.schema.sensors.chooseSensorMessage")}
                    </span>
                </div>
            </div>
        );
    }

    /* main render */
    if (isLoading) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '50%',
                    paddingTop: '10%'
                }}
            >
                <Spinner />
            </div>
        );
    } else if (boothSchema == null || _.isEqual(boothData, {}) || sensorData.length === 0) { // server failed
        return (
            <div
                style={{
                    width: '100%',
                    paddingTop: '50px'
                }}
            >
                <ServerFailed />
            </div>
        );
    } else if (deviceSchema['spec']['sensors'].length === 0) {
        return (
            <span
                style={{
                    color: '#757575'
                }}
            >
                    {t("menu.boothControl.schema.sensors.noSensorMessage")}
            </span>
        );
    } else {
        return (
            <Container fluid>
                <Row>
                    {/* sensor list view */}
                    <Col md={"4"}>
                        {renderSensors()}
                    </Col>
                    {/* sensor detail */}
                    <Col md={"8"}>
                        {
                            CommonUtil.isRootPath(location.pathname, 7) ?
                                renderChooseSensor()
                                :
                                <Outlet />
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
};

export default SensorView;