import axios from "axios";
import UserService from "../../../../../services/UserService";

const baseUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`;

const getBoothVersionList = async (franchiseId, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/franchise/${franchiseId}/booth-version`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data.item['franchiseBoothVersionList']);
    } catch (e) {
        onFailureCallback(e);
    }
};

const getProductJsonList = async (franchiseId, menuId, boothVersionId, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/franchise-menu/`
            + `franchise/${franchiseId}/`
            + `menu/${menuId}`
            + `/menu-detail/product-schema?boothVersionId=${boothVersionId}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data.item['productSchemaList']);
    } catch (e) {
        onFailureCallback(e);
    }
};

const saveOrGenerateMenuDetail = async (franchiseId, menuId, requestBody, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/franchise-menu/`
            + `franchise/${franchiseId}/`
            + `menu/${menuId}/`
            + `menu-detail`;
        const axiosCall = () => axios.post(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res);
    } catch (e) {
        onFailureCallback(e);
    }
};

const saveOrGenerateMenuOptionDetail = async (franchiseId, menuId, optionId, requestBody, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/franchise-menu`
            + `/franchise/${franchiseId}`
            + `/menu/${menuId}`
            + `/option/${optionId}/option-detail`;
        const axiosCall = () => axios.post(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res);
    } catch (e) {
        await onFailureCallback(e);
    }
};

const getOptionJsonList = async (franchiseId, menuId, optionId, boothVersionId, productJsonVersion, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/franchise-menu/`
            + `franchise/${franchiseId}`
            + `/menu/${menuId}`
            + `/option/${optionId}/option-detail`
            + `/boothVersion/${boothVersionId}`
            + `/product/${productJsonVersion}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data.item['optionSchemaList']);
    } catch (e) {
        onFailureCallback(e);
    }
}

const ProductJsonAxiosService = {
    getBoothVersionList,
    getProductJsonList,
    saveOrGenerateMenuDetail,
    getOptionJsonList,
    saveOrGenerateMenuOptionDetail
};

export default ProductJsonAxiosService;
