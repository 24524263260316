import React, {useEffect, useState} from 'react';
import Modal from "react-modal";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import UserService from "../../../services/UserService";
import axios from "axios";
import {Col, Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import styled from "styled-components";
import NotAssignedBoothVersion from "../detailInner/boothVersion/NotAssignedBoothVersion";
import AssignedBoothVersion from "../detailInner/boothVersion/AssignedBoothVersion";

const Title = styled.div`
  text-align: left;
  font-size: 15px;
  color: #757575;
`;

const BoothVersionContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  height: 360px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background: none;
  padding: 10px;
  overflow: auto;
`;

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '650px',
        height: '500px',
        margin: 'auto'
    }
};

const FranchiseBoothVersionEditModal = ({ isOpen, setIsOpen, dataList, onRefresh }) => {

    const { t } = useTranslation();
    const location = useLocation();
    const { franchiseId } = useParams();

    /* states */
    const [boothVersionList, setBoothVersionList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    /* utils */
    const extractBoothVersions = (beforeList) => {
        let newList = [];
        beforeList.map((boothNameObj) => {
            let boothName = boothNameObj['boothName'];
            boothNameObj['versions'].map((boothVersionObj) => {
                let newObj = {};
                newObj['boothName'] = boothName;
                newObj['boothVersion'] = boothVersionObj['boothVersion'];
                newObj['boothVersionId'] = boothVersionObj['boothVersionId'];
                newList.push(newObj);
            });
        });
        return newList;
    };

    const deleteAssignedVersions = (prevList) => {
        let existingBoothVersionIds = [];
        /* extract ids from all versions */
        for (let i=0 ; i<dataList.length ; i++) {
            existingBoothVersionIds.push(dataList[i]['franchiseBoothVersionBoothVersionId']);
        }
        let newList = prevList.filter(prevObj => {
            return !existingBoothVersionIds.includes(prevObj['boothVersionId']);
        });
        return newList;
    };

    /* fetch booth version list and delete assigned ones. */
    const fetchBoothVersionList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/booth-version`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newBoothVersionList = res.data.item['boothNameList'];
            let afterExtractionList = extractBoothVersions(newBoothVersionList);
            let finalList = deleteAssignedVersions(afterExtractionList);
            setBoothVersionList(finalList);
        } catch (e) {
            console.log(e);
        }
    };

    /* put data */
    const deleteBoothVersion = async (boothVersionId) => {
        try {
            await setIsLoading(false);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`
                + `/${franchiseId}/booth-version/${boothVersionId}`;
            await UserService.updateToken(() => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setIsLoading(true);
            onRefresh();
        } catch (e) {
            console.log(e);
        }
    };

    const addBoothVersion = async (boothVersionId) => {
        try {
            await setIsLoading(false);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`
                + `/${franchiseId}/booth-version`;
            let newRequestBody = {};
            newRequestBody['franchiseBoothVersionBoothVersionId'] = boothVersionId;
            newRequestBody['boothFranchiseAliasKr'] = "사용안함";
            newRequestBody['boothFranchiseAliasEn'] = "notUse";
            await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            await setIsLoading(true);
            onRefresh();
        } catch (e) {
            console.log(e);
        }
    };

    /* effects */
    useEffect(() => {
        /* fetch boothVersionList */
        fetchBoothVersionList();
    }, [dataList]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchise.detail.boothVersionInfo.editModalTitle")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        <Title>{t("menu.franchise.detail.boothVersionInfo.NotInUse")}</Title>
                        {/* booth versions not in use */}
                        <BoothVersionContainer>
                            {
                                (boothVersionList.length > 0) &&
                                boothVersionList.map(boothVersionObj => {
                                   return (
                                       <NotAssignedBoothVersion
                                           key={boothVersionObj['boothVersionId']}
                                           boothName={boothVersionObj['boothName']}
                                           boothVersion={boothVersionObj['boothVersion']}
                                           onClick={() => addBoothVersion(boothVersionObj['boothVersionId'])}
                                           isEnabled={isLoading}
                                       />
                                   );
                                })
                            }
                            {
                                (boothVersionList.length === 0) &&
                                <div
                                    style={{
                                        width: '100%',
                                        paddingTop: '40px',
                                        color: '#757575',
                                        fontSize: '14px',
                                        textAlign: 'center'
                                    }}
                                >
                                    {t("menu.franchise.detail.boothVersionInfo.noBoothVersion")}
                                </div>
                            }
                        </BoothVersionContainer>
                    </Col>
                    <Col
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        <Title>{t("menu.franchise.detail.boothVersionInfo.inUse")}</Title>
                        {/* booth versions in use */}
                        <BoothVersionContainer>
                            {
                                (dataList.length > 0) &&
                                dataList.map(boothVersionObj => {
                                    return (
                                        <AssignedBoothVersion
                                            key={boothVersionObj['franchiseBoothVersionBoothVersionId']}
                                            boothName={boothVersionObj['boothName']}
                                            boothVersion={boothVersionObj['boothVersion']}
                                            onClick={() => deleteBoothVersion(boothVersionObj['franchiseBoothVersionBoothVersionId'])}
                                            isEnabled={isLoading}
                                        />
                                    );
                                })
                            }
                            {
                                (dataList.length === 0) &&
                                <div
                                    style={{
                                        width: '100%',
                                        paddingTop: '40px',
                                        color: '#757575',
                                        fontSize: '14px',
                                        textAlign: 'center'
                                    }}
                                >
                                    {t("menu.franchise.detail.boothVersionInfo.noBoothVersion")}
                                </div>
                            }
                        </BoothVersionContainer>
                    </Col>
                </Row>
            </Container>
        </Modal>
    );
};

export default FranchiseBoothVersionEditModal;