
const parseToString = (code) => {
    if (code === 0) {
        return "inactive";
    } else if (code === 1) {
        return "active";
    } else {
        return "deleted";
    }
}

const UserStatusCode = {
    parseToString
};

export default UserStatusCode;