import React, {useEffect, useState} from 'react';
import {Link, Outlet, useParams, useNavigate} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from 'uuid';
import { FaCaretSquareRight, FaCaretSquareLeft } from "react-icons/fa";
import AuthParseService from "../../../services/AuthParseService";
import RenderOnRole from "../../../auth/RenderOnRole";
import UserService from '../../../services/UserService';
import "../RoleManagementStyle.css";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const GroupRoleEdit = () => {

    const navigate = useNavigate();

    /* -------------------------------------------------------- */
    /* Keycloak Roles : IMS_SYSTEM_MANAGEMENT, GROUP_MANAGEMENT */
    /* IMS Systems Roles : none                                 */
    /* -------------------------------------------------------- */

    let { groupId } = useParams();
    const {t, i18n} = useTranslation();

    const [prevGroupRoleList, setPrevGroupRoleList] = useState([]);
    const [groupRoleList, setGroupRoleList]= useState([]);
    const [unassignedRoleList, setUnassignedRoleList] = useState([]);
    const [isAssignReady, setIsAssignReady] = useState(false);
    const [isUnassignReady, setIsUnassignReady] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [unassignedRoleSelectList, setUnassignedRoleSelectList] = useState([]);
    const [assignedRoleSelectList, setAssignedRoleSelectList] = useState([]);
    const [selectedAssignedRoleIndex, setSelectedAssignedRoleIndex] = useState(-1);

    const deepCopyArray = (array) => {
        return array.map(item => {
            if (typeof item === 'object' && item !== null) {
                return { ...item };
            }
            return item;
        });
    }
    const fetchGroupRoleList = async () => {
        await setSelectedAssignedRoleIndex(-1);
        try {
            const res = await UserService.updateToken(() => axios.get(`${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/role/group/${groupId}`, {
                headers : {
                    "Authorization" : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }));
            if (res.data['error_code'] === 'GET_EMPTY_DATA') {
                setPrevGroupRoleList([]);
                setGroupRoleList([]);
            } else {
                let newList = res.data.item["roleList"];
                newList.forEach(obj => delete obj.mappedAt);
                let copyOfNewList = deepCopyArray(newList);
                setPrevGroupRoleList(copyOfNewList);
                setGroupRoleList([...newList]);
            }
            fetchUnassignedRoleList();
        } catch (err) {
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
            console.log(err);
        }
    }

    const fetchUnassignedRoleList = async () => {
        try {
            const res = await UserService.updateToken(() => axios.get(`${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/role/group/${groupId}/available`, {
                headers : {
                    "Authorization" : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }));
            if (res.data['error_code'] === 'GET_EMPTY_DATA') {
                setUnassignedRoleList([]);
            } else {
                let newList = res.data.item["roleList"];
                newList.forEach(obj => delete obj.mappedAt);
                setUnassignedRoleList(newList);
            }
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    }

    const isSameObjectArray = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
        const sortedArr1 = arr1.map(obj => JSON.stringify(obj)).sort();
        const sortedArr2 = arr2.map(obj => JSON.stringify(obj)).sort();
        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }
        return true;
    }

    const handleUnassignedRoleSelect = (idx) => {
        setUnassignedRoleSelectList(prevList => {
            let newList = [...prevList];
            newList[idx] = !newList[idx];
            return newList;
        });
    }

    const handleAssignedRoleSelect = (idx) => {
        if (assignedRoleSelectList[idx]) {
            setSelectedAssignedRoleIndex(-1);
        } else {
            setSelectedAssignedRoleIndex(idx);
        }
        setAssignedRoleSelectList(prevList => {
            let newList = [...prevList];
            newList[idx] = !newList[idx];
            return newList;
        });
    }

    const assignRoles = () => {
        if (!isAssignReady) {
            return;
        }
        setGroupRoleList((prevList) => {
            let newList = [...prevList];
            unassignedRoleSelectList.map((value, idx) => {
                if (value) {
                    newList.push({
                        imsSystemRoleId : unassignedRoleList[idx]["imsSystemRoleId"],
                        roleName : unassignedRoleList[idx]["roleName"],
                        label : unassignedRoleList[idx]["label"],
                        label1 : unassignedRoleList[idx]["label1"],
                        label2 : unassignedRoleList[idx]["label2"],
                        label3 : unassignedRoleList[idx]["label3"],
                        label4 : unassignedRoleList[idx]["label4"],
                        label5 : unassignedRoleList[idx]["label5"],
                        description : unassignedRoleList[idx]["description"],
                        accessCode: 0
                    });
                }
            });
            return newList;
        });
        setUnassignedRoleList((prevList) => {
            let newList = [...prevList];
            let targetIndices = [];
            unassignedRoleSelectList.map((value, idx) => {
                if (value) {
                    targetIndices.push(idx);
                }
            });
            targetIndices.sort((a, b) => b - a).forEach((index) => {
                newList.splice(index, 1);
            });
            return newList;
        });
        setSelectedAssignedRoleIndex(-1);
    }

    const unassignRoles = () => {
        if (!isUnassignReady) {
            return;
        }
        setUnassignedRoleList((prevList) => {
            let newList = [...prevList];
            assignedRoleSelectList.map((value, idx) => {
                if (value) {
                    newList.push({
                        imsSystemRoleId : groupRoleList[idx]["imsSystemRoleId"],
                        roleName : groupRoleList[idx]["roleName"],
                        label : groupRoleList[idx]["label"],
                        label1 : groupRoleList[idx]["label1"],
                        label2 : groupRoleList[idx]["label2"],
                        label3 : groupRoleList[idx]["label3"],
                        label4 : groupRoleList[idx]["label4"],
                        label5 : groupRoleList[idx]["label5"],
                        description : groupRoleList[idx]["description"]
                    });
                }
            });
            return newList;
        });
        setGroupRoleList((prevList) => {
           let newList = [...prevList];
            let targetIndices = [];
            assignedRoleSelectList.map((value, idx) => {
                if (value) {
                    targetIndices.push(idx);
                }
            });
            targetIndices.sort((a, b) => b - a).forEach((index) => {
                newList.splice(index, 1);
            });
            return newList;
        });
        setSelectedAssignedRoleIndex(-1);
    }

    const updateRoleAccessCode = (accessCode) => {
        setGroupRoleList(prevList => {
           let newList = [...prevList];
           newList[selectedAssignedRoleIndex]["accessCode"] = accessCode;
           return newList;
        });
    }

    const handleAccessCodeChange = (sort, checked) => {
        if (sort === 'get') {
            if (checked) {
                updateRoleAccessCode(AuthParseService.addGetCode(groupRoleList[selectedAssignedRoleIndex].accessCode));
            } else {
                updateRoleAccessCode(AuthParseService.deleteGetCode(groupRoleList[selectedAssignedRoleIndex].accessCode));
            }
        } else if (sort === 'put') {
            if (checked) {
                updateRoleAccessCode(AuthParseService.addPutCode(groupRoleList[selectedAssignedRoleIndex].accessCode));
            } else {
                updateRoleAccessCode(AuthParseService.deletePutCode(groupRoleList[selectedAssignedRoleIndex].accessCode));
            }
        } else if (sort === 'post') {
            if (checked) {
                updateRoleAccessCode(AuthParseService.addPostCode(groupRoleList[selectedAssignedRoleIndex].accessCode));
            } else {
                updateRoleAccessCode(AuthParseService.deletePostCode(groupRoleList[selectedAssignedRoleIndex].accessCode));
            }
        } else {
            if (checked) {
                updateRoleAccessCode(AuthParseService.addDeleteCode(groupRoleList[selectedAssignedRoleIndex].accessCode));
            } else {
                updateRoleAccessCode(AuthParseService.deleteDeleteCode(groupRoleList[selectedAssignedRoleIndex].accessCode));
            }
        }
    }

    const formatRequestBody = (prevList) => {
        let newList = [...prevList];
        let requestBody = [];
        newList.map(obj => {
            requestBody.push({
                imsSystemRoleId : obj.imsSystemRoleId,
                accessCode : obj.accessCode
            });
        });
        return requestBody;
    }

    const putGroupRole = async () => {
        try {
            await setIsEdited(false);
            await setSelectedAssignedRoleIndex(-1);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/role/group/${groupId}`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl ,
                JSON.stringify(formatRequestBody(groupRoleList)),
                {
                    headers: {
                        "Authorization" : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
        } catch (e) {
            await setIsEdited(false);
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
            navigate(`/group-role`);
        }
    }

    const assignedRoleRender = (role, idx) => {
        return (
            <div
                style={{
                    marginBottom: '5px',
                    width: '100%'
                }}
            >
                <button
                    key={uuidv4()}
                    className={"roleButton"}
                    onClick={() => handleAssignedRoleSelect(idx)}
                    style={
                        (assignedRoleSelectList[idx] || (selectedAssignedRoleIndex === idx))
                        ?
                            {
                                backgroundColor: '#fcf9ed',
                                borderColor: '#fc7242',
                                color: '#fc7242'
                            }
                            :
                            {}
                        }
                >
                    {role[t("language.dataKey.role.label")]}
                </button>
            </div>
        )
    }

    const unassignedRoleRender = (role, idx) => {
        return (
            <div
                style={{
                    marginBottom: '5px',
                    width: '100%'
                }}
            >
                <button
                    key={uuidv4()}
                    className={"roleButton"}
                    onClick={() => handleUnassignedRoleSelect(idx)}
                    style={
                        unassignedRoleSelectList[idx] ?
                            {
                                backgroundColor: '#fcf9ed',
                                borderColor: '#fc7242',
                                color: '#fc7242'
                            }
                            :
                            {}
                    }
                >
                    {role[t("language.dataKey.role.label")]}
                </button>
            </div>
        )
    }

    const roleSpecRender = () => {
        if ((selectedAssignedRoleIndex === -1) || (groupRoleList.length === 0)) {
            return(<></>);
        }
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px'
                }}
            >
                <span
                    id={"small-title"}
                >
                    {t("menu.role.roleName")}
                </span>
                <input
                    id={"small-input-text"}
                    type={"text"}
                    value={groupRoleList[selectedAssignedRoleIndex][t("language.dataKey.role.label")]}
                    readOnly
                />
                <span
                    style={{ marginTop: '20px' }}
                    id={"small-title"}
                >
                    {t("menu.role.roleDescription")}
                </span>
                <textarea
                    id={"small-textarea"}
                    value={groupRoleList[selectedAssignedRoleIndex]["description"]}
                    readOnly
                />
                <span
                    style={{ marginTop: '20px' }}
                    id={"small-title"}
                >
                    {t("menu.role.accessCode")}
                </span>
                <div style={{ marginLeft: '10px' }}>
                    <input
                        type={"checkbox"}
                        checked={AuthParseService.hasGetCode(groupRoleList[selectedAssignedRoleIndex].accessCode)}
                        id={"small-input-checkbox"}
                        style={{ marginRight: "10px" }}
                        onChange={({ target : { checked }}) => handleAccessCodeChange('get', checked)}
                    />
                    <span id={"small-small-title"}>{t("menu.role.sort.get")}</span>
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <input
                        type={"checkbox"}
                        checked={AuthParseService.hasPutCode(groupRoleList[selectedAssignedRoleIndex].accessCode)}
                        id={"small-input-checkbox"}
                        style={{ marginRight: "10px" }}
                        onChange={({ target : { checked }}) => handleAccessCodeChange('put', checked)}
                    />
                    <span id={"small-small-title"}>{t("menu.role.sort.put")}</span>
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <input
                        type={"checkbox"}
                        checked={AuthParseService.hasPostCode(groupRoleList[selectedAssignedRoleIndex].accessCode)}
                        id={"small-input-checkbox"}
                        style={{ marginRight: "10px" }}
                        onChange={({ target : { checked }}) => handleAccessCodeChange('post', checked)}
                    />
                    <span id={"small-small-title"}>{t("menu.role.sort.post")}</span>
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <input
                        type={"checkbox"}
                        checked={AuthParseService.hasDeleteCode(groupRoleList[selectedAssignedRoleIndex].accessCode)}
                        id={"small-input-checkbox"}
                        style={{ marginRight: "10px" }}
                        onChange={({ target : { checked }}) => handleAccessCodeChange('delete', checked)}
                    />
                    <span id={"small-small-title"}>{t("menu.role.sort.delete")}</span>
                </div>
            </div>
        );
    }

    useEffect(() => {
        fetchGroupRoleList();
    }, [groupId]);

    useEffect(() => {
        setIsEdited(!isSameObjectArray(groupRoleList, prevGroupRoleList));
    }, [groupRoleList, unassignedRoleList]);

    useEffect(() => {
        setAssignedRoleSelectList(Array(groupRoleList.length).fill(false));
    }, [groupRoleList]);

    useEffect(() => {
        setSelectedAssignedRoleIndex(-1);
    }, [groupRoleList.length]);

    useEffect(() => {
        setUnassignedRoleSelectList(Array(unassignedRoleList.length).fill(false));
    }, [unassignedRoleList]);

    useEffect(() => {
        setIsUnassignReady(assignedRoleSelectList.length !== 0 && assignedRoleSelectList.includes(true));
    }, [assignedRoleSelectList]);

    useEffect(() => {
        setIsAssignReady(unassignedRoleSelectList.length !== 0 && unassignedRoleSelectList.includes(true));
    }, [unassignedRoleSelectList]);

    return (
        <RenderOnRole roleName={""} type={""} allowedRing={0} isForSuperMaster={false} nothingOnRejected={false}>
            <div>
                <Container fluid>
                    <Row>
                        <Col md={"3"}>
                            <Row>
                                <div style={{ textAlign: 'left', paddingLeft: '10px', marginBottom: '5px' }}>
                                   <span
                                    id={"role-box-title"}
                                   >
                                       {t("menu.role.text.unassignedRoles")}
                                   </span>
                                </div>
                            </Row>
                            <Row>
                                <div
                                    style={{
                                        textAlign: 'left',
                                        height: '650px',
                                        width: '100%',
                                        overflow: 'auto',
                                        border: '1px solid #c2c2c2',
                                        borderRadius : '10px',
                                        padding: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {
                                        unassignedRoleList && unassignedRoleList.map((role, idx) => {
                                            return unassignedRoleRender(role, idx);
                                        })
                                    }
                                </div>
                            </Row>
                        </Col>
                        <Col md={"1"}>
                            <div
                                style={{
                                    height: '650px',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <button
                                    id={"role-assign-btn"}
                                    style={ isAssignReady ? { color: '#fc7242' } : {} }
                                    onClick={() => assignRoles()}
                                >
                                    <FaCaretSquareRight
                                        size={"40"}
                                    />
                                </button>
                                <button
                                    id={"role-assign-btn"}
                                    style={ isUnassignReady ? { color: '#fc7242' } : {} }
                                    onClick={() => unassignRoles()}
                                >
                                    <FaCaretSquareLeft
                                        size={"40"}
                                    />
                                </button>
                            </div>
                        </Col>
                        <Col md={"3"}>
                            <Row>
                                <div style={{ textAlign: 'left', paddingLeft: '10px', marginBottom: '5px'  }}>
                                   <span
                                       id={"role-box-title"}
                                   >
                                       {t("menu.role.text.assignedRoles")}
                                   </span>
                                </div>
                            </Row>
                            <Row>
                                <div
                                    style={{
                                        textAlign: 'left',
                                        height: '650px',
                                        width: '100%',
                                        overflow: 'auto',
                                        border: '1px solid #c2c2c2',
                                        borderRight: '1px dashed #c2c2c2',
                                        borderRadius : '10px 0px 0px 10px',
                                        padding: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {
                                        groupRoleList && groupRoleList.map((role, idx) => {
                                            return assignedRoleRender(role, idx);
                                        })
                                    }
                                </div>
                            </Row>
                        </Col>
                        <Col md={"5"}>
                            <Row>
                                <div style={{ textAlign: 'left', paddingLeft: '10px', marginBottom: '5px'  }}>
                                   <span
                                       id={"role-box-title"}
                                   >
                                       {t("menu.role.text.roleSpecification")}
                                   </span>
                                </div>
                            </Row>
                            <Row>
                                <div
                                    style={{
                                        textAlign: 'left',
                                        height: '650px',
                                        width: '100%',
                                        overflow: 'auto',
                                        border: '1px solid #c2c2c2',
                                        borderLeft: 'none',
                                        borderRadius : '0px 10px 10px 0px',
                                        padding: '10px'
                                    }}
                                >
                                    {roleSpecRender()}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                padding: '15px',
                                display: 'flex',
                                flexDirection: 'row-reverse'
                            }}
                        >
                            {
                                isEdited ?
                                    <button
                                        id={"save-btn"}
                                        onClick={() => putGroupRole()}
                                    >
                                        {t("button.save")}
                                    </button>
                                    :
                                    <button
                                        id={"save-btn-disabled"}
                                    >
                                        {t("button.save")}
                                    </button>
                            }
                        </div>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default GroupRoleEdit;