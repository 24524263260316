import React, {useEffect, useState} from 'react';
import Modal from "react-modal";
import {useTranslation} from "react-i18next";
import DataCompareService from "../../../services/DataCompareService";
import {Container, Image} from "react-bootstrap";
import axios from "axios";
import PaginationHandler from "../../common/pagination/PaginationHandler";
import uuid from "react-uuid";
import DataParseService from "../../../services/DataParseService";
import defaultBoxImage from "../../../icons/productDefaultPicture.png";
import UserService from '../../../services/UserService';
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '800px',
        height: '600px',
        margin: 'auto'
    }
}

const OrderProductEdit = ({ isOpen, setIsOpen, onRefresh, orderId, orderGroupId, currentProductList }) => {

    const { t, i18n } = useTranslation();
    /* all product list */
    const [allProductList, setAllProductList] = useState([]);
    const [prevProductList, setPrevProductList] = useState([]);
    const [fixedCurrentList, setFixedCurrentList] = useState([]);
    /* for pagination */
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);
    /* isEdited */
    const [isEdited, setIsEdited] = useState(false);

    /* fetch all product list */
    const fetchProductList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product?`
                + `&page=${currentPageIndex}`
                + `&size=6`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setAllProductList(res.data.item.productList);
                setCurrentPageIndex(res.data.item.currentPage);
                setMaxPageIndex(res.data.item.totalPages - 1);
                setTotalElements(res.data.item.totalItems);
            } else {
                setAllProductList([]);
                setCurrentPageIndex(0);
                setMaxPageIndex(0);
                setTotalElements(0);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* utils */
    const isInPrevList = (productId) => {
        for (let i=0 ; i<prevProductList.length ; i++) {
            if (prevProductList[i].productId === productId) {
                return true;
            }
        }
        return false;
    };

    const filterOutList = (list) => {
        for (let i=0 ; i < list.length ; i++) {
            list[i]["productId"] = list[i]["orderProductProductId"];
            list[i]["productName"] = list[i].productName;
            list[i]["productName1"] = list[i].productName1;
            list[i]["productName2"] = list[i].productName2;
            list[i]["productName3"] = list[i].productName3;
            list[i]["productName4"] = list[i].productName4;
            list[i]["productName5"] = list[i].productName5;
            delete list[i]["mappedAt"];
            delete list[i]["orderProductId"];
            delete list[i]["orderProductOrderId"];
            delete list[i]["orderProductProductId"];
        }
        return list;
    };

    const toRequestBody = (list) => {
        for (let i=0 ; i < list.length ; i++) {
            delete list[i]["productName"];
            delete list[i]["productName1"];
            delete list[i]["productName2"];
            delete list[i]["productName3"];
            delete list[i]["productName4"];
            delete list[i]["productName5"];
            delete list[i]["productDescription"];
            delete list[i]["productDescription1"];
            delete list[i]["productDescription2"];
            delete list[i]["productDescription3"];
            delete list[i]["productDescription4"];
            delete list[i]["productDescription5"];
            delete list[i]["price"];
            delete list[i]["priceUnit"];
            delete list[i]["productUnit"];
            delete list[i]["productUnitAmount"];
            delete list[i]["productCategoryInformation"];
            delete list[i]["isDeprecatedProduct"];
            delete list[i]["productImageUrl"];
            list[i]["productId"] = list[i]["targetProductId"];
            delete list[i]["targetProductId"];
        }
        return list;
    }

    const addProductIntoPrevProductList = (productInfo) => {
        let newProductInfo = {
            targetProductId : productInfo.productId,
            amount : 1.0,
            price : productInfo.productPrice,
            priceUnit : productInfo.productPriceUnit,
            productName : productInfo.productName,
            productName1 : productInfo.productName1,
            productName2 : productInfo.productName2,
            productName3 : productInfo.productName3,
            productName4 : productInfo.productName4,
            productName5 : productInfo.productName5,
            productUnit : productInfo.productUnit,
            productUnitAmount : productInfo.productUnitAmount,
        }
        setPrevProductList(prevList => {
            let newList = [...prevList];
            newList.push(newProductInfo);
            return newList;
        })
    };

    const updatePrevProductList = (index, key, value) => {
        setPrevProductList(prevList => {
           let newList = [...prevList];
           newList[index][key] = value;
           return newList;
        });
    };

    const deleteFromPrevProductList = (index) => {
        setPrevProductList(prevList => {
            let newList = [...prevList];
            newList.splice(index, 1);
            return newList;
        });
    };

    /* handlers */
    const handleGoBefore = () => {
        if (currentPageIndex !== 0) {
            setCurrentPageIndex(prevValue => {
                return prevValue - 1;
            });
        }
    };

    const handleGoAfter = () => {
        if (currentPageIndex !== maxPageIndex) {
            setCurrentPageIndex(prevValue => {
                return prevValue + 1;
            });
        }
    };

    const handleGoFirst = () => {
        setCurrentPageIndex(0);
    };

    const handleGoLast = () => {
        setCurrentPageIndex(maxPageIndex);
    };

    /* post order product */
    const postProduct = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/order/${orderId}/product`;
            let requestBody = {
                "productList" : toRequestBody(prevProductList)
            };
            console.log(requestBody);
            await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            onRefresh();
            setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    /* effects */
    useEffect(() => {
        fetchProductList();
    }, [currentPageIndex]);

    useEffect(() => {
        setIsEdited(false);
        let filteredList = filterOutList(DataCompareService.deepCopyList(currentProductList));
        setPrevProductList(DataCompareService.deepCopyList(filteredList));
        setFixedCurrentList(DataCompareService.deepCopyList(filteredList));
    }, [currentProductList]);

    useEffect(() => {
        setIsEdited(!DataCompareService.isSameList(fixedCurrentList, prevProductList));
    }, [prevProductList]);

    /* renderer */
    const firstRowRender = () => {
        if (allProductList.length === 0) {
            return (<></>);
        }
        if (allProductList.length < 3) {
            return allProductList.map((product, index) => {
                return (
                    <div
                        key={uuid()}
                        style={{
                            width: '30%',
                            height: '100%',
                            border: '1px solid',
                            borderColor: isInPrevList(product.productId) ? "#fc7242" : "#757575",
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '15px'
                            }}
                        >
                            <Image
                                src={
                                    (product.productImageUrl == null) ?
                                        defaultBoxImage
                                        :
                                        `${product.productImageUrl}`
                                    }
                                alt="Profile Image"
                                style={{ width: '80px', height: '80px', display: 'inline-block', border: '0'}}
                            />
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontSize: '10px'
                                }}
                            >
                                {product[t("language.dataKey.product.name")]}
                            </span>
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontWeight: 'bold'
                                }}
                            >
                                {
                                    product.productUnitAmount + " " + product.productUnit
                                }
                            </span>
                        </div>
                        {
                            (orderGroupId === product.productGroupId) && (!isInPrevList(product.productId)) ?
                                <button
                                    className={"smallAddBtn"}
                                    onClick={() => addProductIntoPrevProductList(product)}
                                >
                                    {t("button.add")}
                                </button>
                                :
                                <button
                                    className={"smallAddBtn-disabled"}
                                >
                                    {t("button.add")}
                                </button>
                        }
                    </div>
                );
            })
        } else {
            let result = [];
            for (let i=0 ; i<3 ; i++) {
                result.push(
                    <div
                        key={uuid()}
                        style={{
                            width: '30%',
                            height: '100%',
                            border: '1px solid',
                            borderColor: isInPrevList(allProductList[i].productId) ? "#fc7242" : "#757575",
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '15px'
                            }}
                        >
                            <Image
                                src={
                                    (allProductList[i].productImageUrl == null) ?
                                        defaultBoxImage
                                        :
                                        `${allProductList[i].productImageUrl}`
                                }
                                alt="Profile Image"
                                style={{ width: '80px', height: '80px', display: 'inline-block', border: '0'}}
                            />
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontSize: '10px'
                                }}
                            >
                                {allProductList[i][t("language.dataKey.product.name")]}
                            </span>
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontWeight: 'bold'
                                }}
                            >
                                {
                                    allProductList[i].productUnitAmount + " " + allProductList[i].productUnit
                                }
                            </span>
                        </div>
                        {
                            (orderGroupId === allProductList[i].productGroupId) && (!isInPrevList(allProductList[i].productId)) ?
                                <button
                                    className={"smallAddBtn"}
                                    onClick={() => addProductIntoPrevProductList(allProductList[i])}
                                >
                                    {t("button.add")}
                                </button>
                                :
                                <button
                                    className={"smallAddBtn-disabled"}
                                >
                                    {t("button.add")}
                                </button>
                        }
                    </div>
                );
            }
            return result;
        }
    };

    const secondRowRender = () => {
        if (allProductList.length === 0) {
            return (<></>);
        }
        if (allProductList.length <= 3) {
            return (<></>);
        } else {
            let result = [];
            for (let i=3 ; i<allProductList.length ; i++) {
                result.push(
                    <div
                        key={uuid()}
                        style={{
                            width: '30%',
                            height: '100%',
                            border: '1px solid',
                            borderColor: isInPrevList(allProductList[i].productId) ? "#fc7242" : "#757575",
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <Image
                                src={
                                    (allProductList[i].productImageUrl == null) ?
                                        defaultBoxImage
                                        :
                                        `${allProductList[i].productImageUrl}`
                                }
                                alt="Profile Image"
                                style={{ width: '80px', height: '80px', display: 'inline-block', border: '0'}}
                            />
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontSize: '10px'
                                }}
                            >
                                {allProductList[i][t("language.dataKey.product.name")]}
                            </span>
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontWeight: 'bold'
                                }}
                            >
                                {
                                    allProductList[i].productUnitAmount + " " + allProductList[i].productUnit
                                }
                            </span>
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '5px'
                            }}
                        >
                            {
                                (orderGroupId === allProductList[i].productGroupId) && (!isInPrevList(allProductList[i].productId)) ?
                                    <button
                                        className={"smallAddBtn"}
                                        onClick={() => addProductIntoPrevProductList(allProductList[i])}
                                    >
                                        {t("button.add")}
                                    </button>
                                    :
                                    <button
                                        className={"smallAddBtn-disabled"}
                                    >
                                        {t("button.add")}
                                    </button>
                            }
                        </div>
                    </div>
                );
            }
            return result;
        }
    };

    const prevProductListRender = () => {
        return prevProductList.map((product, index) => {
           return (
               <div
                style={{
                    width: '100%',
                    height: '50px',
                    marginTop: '5px',
                    border: '1px solid #c2c2c2',
                    borderRadius: '10px',
                    display: 'flex'
                }}
               >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '40%',
                            height: '100%',
                            padding: '5px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '50%',
                                textAlign: 'center'
                            }}
                        >
                           <span
                               style={{
                                   fontSize: '12px',
                                   color: '#757575',
                                   fontWeight: 'bold'
                               }}
                           >
                                {product[t("language.dataKey.product.name")]}
                            </span>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                height: '50%',
                                textAlign: 'center'
                            }}
                        >
                           <span
                               style={{
                                   fontSize: '11px',
                                   color: '#c2c2c2'
                               }}
                           >
                                {product.productUnitAmount + " " + product.productUnit}
                            </span>
                        </div>
                    </div>
                   <div
                       style={{
                           display: 'flex',
                           flexDirection: 'column',
                           width: '30%',
                           height: '100%',
                           padding: '5px'
                       }}
                   >
                       <div
                           style={{
                               width: '100%',
                               height: '50%'
                           }}
                       >
                           <span
                               style={{
                                   fontSize: '11px',
                                   position: 'relative',
                                   top: '-5px'
                               }}
                           >
                               {t("common.amount")}
                           </span>
                       </div>
                        <div
                            style={{
                                width: '100%',
                                height: '50%'
                            }}
                        >
                            <input
                                className={"amountInput"}
                                type={"number"}
                                value={product.amount}
                                isForEdit={true}
                                onChange={(e) => {
                                    if (parseFloat(e.target.value) < 0) {
                                        updatePrevProductList(index, "amount", 1);
                                    } else if (parseFloat(e.target.value) === 0) {
                                        deleteFromPrevProductList(index);
                                    } else {
                                        updatePrevProductList(index, "amount", parseFloat(e.target.value));
                                    }
                                }}
                            />
                        </div>
                   </div>
                   <div
                       style={{
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'center',
                           width: '30%',
                           height: '100%',
                           fontSize: '12px'
                       }}
                   >
                       {DataParseService.formatNumberWithCommas(product.amount * product.price) + " " + (product.priceUnit)}
                   </div>
               </div>
           );
        });
    }

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <ModalCloseButton
                title={t("button.editOrderProduct")}
                setIsOpen={setIsOpen}
            />
            <div
                style={{
                    width: '100%',
                    height: '500px',
                    display: 'flex'
                }}
            >
                {/* products */}
                <div
                    style={{
                        width: '60%',
                        height: '100%'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '89%',
                            padding: '5px',
                            border: '1px solid #c2c2c2',
                            borderRadius: '10px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                height: '50%',
                                padding: '10px',
                                justifyContent: 'space-between'
                            }}
                        >
                            {
                                firstRowRender()
                            }
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                height: '50%',
                                padding: '10px',
                                justifyContent: 'space-between'
                            }}
                        >
                            {
                                secondRowRender()
                            }
                        </div>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '1%'
                        }}
                    />
                    {/* pagination */}
                    <div
                        style={{
                            width: '100%',
                            height: '10%'
                        }}
                    >
                        <PaginationHandler
                            goBefore={handleGoBefore}
                            goAfter={handleGoAfter}
                            goFirst={handleGoFirst}
                            goLast={handleGoLast}
                            pageSize={6}
                            currentIndex={currentPageIndex}
                            maxIndex={maxPageIndex}
                            totalItemNumber={totalElements}
                        />
                    </div>
                </div>
                {/* current product list */}
                <div
                    style={{
                        width: '1%',
                        height: '100%'
                    }}
                />
                <div
                    style={{
                        width: '39%',
                        height: '100%',
                        border: '1px solid #c2c2c2',
                        borderRadius: '10px',
                        padding: '5px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '10%'
                        }}
                    >
                        {
                            !isEdited || (prevProductList.length === 0) ?
                                <button
                                    id={"long-save-btn-disabled"}
                                >
                                    {t("button.save")}
                                </button>
                                :
                                <button
                                    id={"long-save-btn"}
                                    onClick={() => postProduct()}
                                >
                                    {t("button.save")}
                                </button>
                        }
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '90%',
                            overflowY: 'auto'
                        }}
                    >
                        {
                            prevProductListRender()
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default OrderProductEdit;