import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import UserService from "../../../services/UserService";
import {Col, Container, Row} from "react-bootstrap";
import styled from "styled-components";
import {MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight} from "react-icons/md";
import uuid from "react-uuid";
import { LiaEqualsSolid } from "react-icons/lia";
import TitleAndTextArea from "../../common/dataView/TitleAndTextArea";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import DataCompareService from "../../../services/DataCompareService";
import DataParseService from "../../../services/DataParseService";
import AddBoothName from "./AddBoothName";
import TitleAndSelectBox from "../../common/dataView/TitleAndSelectBox";
import AddBoothVersion from "./AddBoothVersion";
import BackwardButton from "../../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";
import ConfirmModal from "../../common/confirm/ConfirmModal";
import _ from "lodash";
import BoothErrorCodeConfigModal from "./errorCode/BoothErrorCodeConfigModal";
import RenderOnRole from "../../../auth/RenderOnRole";
import DeleteConfirmModal from "../../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../../services/DeleteService";

const boothNameRequestBodyInit = {
    boothName: "",
    description: ""
};

const boothNameIsValidMapInit = {
    boothName: true,
    description: true
};

const boothVersionRequestBodyInit = {
    boothVersion : "",
    description : "",
    boothJsonVersion : "",
    generateDate : ""
};

const boothVersionIsValidMapInit = {
    boothVersion : true,
    description : true,
    boothJsonVersion : true,
    generateDate : true
};

const MessageView = styled.div`
  width: 100%;
  padding-top: 30px;
  text-align: center;
`;

const Message = styled.span`
  color: #757575;
  font-size: 14px;
`;

const SmallMessage = styled.span`
  color: #c2c2c2;
  font-size: 14px;
`;

const BoothNameView = styled.span`
  font-weight: 600;
`;

const BoothNameListElement = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  border: 1px solid #fc7242;
  color: #fc7242;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const SelectedBoothNameListElement = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  border: 1px solid #fc7242;
  color: #fc7242;
  background-color: #FFF1EC;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const BoothVersionListElement = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  border: 1px solid #a44ce6;
  color: #a44ce6;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const SelectedBoothVersionListElement = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  border: 1px solid #a44ce6;
  color: #a44ce6;
  background-color: #fff4ff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const SaveButton = styled.button`
  padding: 3px 10px;
  border: 1px solid #fc7242;
  border-radius: 5px;
  color: #fc7242;
  background: none;
  font-size: 14px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #fc7242;
    color: white;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  padding: 3px 10px;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  color: #c2c2c2;
  background: none;
  font-size: 14px;
  
  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled.button`
  padding: 3px 10px;
  border: 1px solid red;
  border-radius: 5px;
  color: red;
  background: none;
  font-size: 14px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: red;
    color: white;
  }
  
  &:focus {
    outline: none;
  }
`;

const BoothNameAddButton = styled.button`
  background: none;
  color: blue;
  border: 1px solid blue;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 15px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #abd4ff;
  }
  
  &:focus {
    outline: none;
  }
`;

const BoothErrorCodeConfigButton = styled.button`
  background: none;
  color: #fc7242;
  border: 1px solid #fc7242;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 15px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #FFF1EC;
  }
  
  &:focus {
    outline: none;
  }
`;

const BoothVersionAddButton = styled.button`
  background: none;
  color: #757575;
  border: 1px solid #757575;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 15px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #ebebeb;
  }
  
  &:focus {
    outline: none;
  }
`;

const BoothVersionAddButtonDisabled = styled.button`
  background: none;
  color: #ebebeb;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 15px;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
`;

const BoothVersionManagement = () => {

    const { t } = useTranslation();

    /* booth name list */
    const [boothNameList, setBoothNameList] = useState([]);
    const [selectedBoothNameIndex, setSelectedBoothNameIndex] = useState(-1);
    const [boothNameRequestBody, setBoothNameRequestBody] = useState(boothNameRequestBodyInit);
    const [fixedBoothNameRequestBody, setFixedBoothNameRequestBody] = useState(boothNameRequestBodyInit);
    const [boothNameValidMap, setBoothNameValidMap] = useState(boothNameIsValidMapInit);
    const [isBoothNameValid, setIsBoothNameValid] = useState(true);
    const [isBoothNameEdited, setIsBoothNameEdited] = useState(false);
    /* booth version */
    const [selectedBoothVersionIndex, setSelectedBoothVersionIndex] = useState(-1);
    const [boothVersionRequestBody, setBoothVersionRequestBody] = useState(boothVersionRequestBodyInit);
    const [fixedBoothVersionRequestBody, setFixedBoothVersionRequestBody] = useState(boothVersionRequestBodyInit);
    const [boothVersionValidMap, setBoothVersionValidMap] = useState(boothVersionIsValidMapInit);
    const [isBoothVersionValid, setIsBoothVersionValid] = useState(true);
    const [isBoothVersionEdited, setIsBoothVersionEdited] = useState(false);
    /* modals */
    const [isBoothNameAddModalOpen, setIsBoothNameAddModalOpen] = useState(false);
    const [isBoothVersionAddModalOpen, setIsBoothVersionAddModelOpen] = useState(false);
    const [isBoothNameDeleteConfirmOpen, setIsBoothNameDeleteConfirmOpen] = useState(false);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [isErrorCodeEditModalOpen, setIsErrorCodeEditModalOpen] = useState(false);
    /* json schema list */
    const [jsonSchemaList, setJsonSchemaList] = useState([]);

    /* fetch Json schema list */
    const fetchJsonSchemaList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/booth-control-wrapper/schema`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            let newList = [];
            if (res.data.item != null) {
                res.data.item.map((element) => {
                   let newElem = {};
                   newElem['schemaName'] = "[JSON] " + element['fields']['name'] + " " + element['fields']['version'];
                   newElem['schemaVersion'] = element['pk'];
                   newList.push(newElem);
                });
            }
            setJsonSchemaList(newList);
        } catch (err) {
            console.log(err);
            toast.error(<ToastAlertView message={t("message.serverFailed")} />);
        }
    };

    /* fetch booth name and versions */
    const fetchBoothName = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/booth-version`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                setBoothNameList([]);
            } else {
                let sortedList = res.data.item.boothNameList.sort((a, b) => a.boothName.localeCompare(b.boothName));
                setBoothNameList(sortedList);
            }
        } catch (err) {
            console.log(err);
            toast.error(<ToastAlertView message={t("message.serverFailed")} />);
        }
    };

    /* put booth name */
    const putBoothName = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/booth-version/name/${boothNameList[selectedBoothNameIndex]['boothNameId']}`;
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(boothNameRequestBody),
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            setIsBoothNameEdited(false);
            fetchBoothName();
        } catch (err) {
            console.log(err);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    const deleteBoothName = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/booth-version/name/${boothNameList[selectedBoothNameIndex]['boothNameId']}`;
            const axiosCall = () => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.deleted")} />);
            setSelectedBoothNameIndex(-1);
            fetchBoothName();
        } catch (err) {
            console.log(err);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    const putBoothVersion = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/booth-version/name/${boothNameList[selectedBoothNameIndex]['boothNameId']}`
                + `/version/${boothNameList[selectedBoothNameIndex]['versions'][selectedBoothVersionIndex]['boothVersionId']}`;
            /* delete boothJsonVersion */
            let newRequestBody = _.cloneDeep(boothVersionRequestBody);
            delete newRequestBody['boothJsonVersion'];
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            setIsBoothVersionEdited(false);
            fetchBoothName();
        } catch (err) {
            console.log(err);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    const deleteBoothVersion = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/booth-version/name/${boothNameList[selectedBoothNameIndex]['boothNameId']}`
                + `/version/${boothNameList[selectedBoothNameIndex]['versions'][selectedBoothVersionIndex]['boothVersionId']}`;
            const axiosCall = () => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.deleted")} />);
            fetchBoothName();
        } catch (err) {
            console.log(err);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* handlers */
    const handleBoothNameSelect = (index) => {
        if (selectedBoothNameIndex === index) {
            setSelectedBoothNameIndex(-1);
        } else {
            setSelectedBoothNameIndex(index);
        }
    };

    const handleBoothVersionSelect = (index) => {
        if (selectedBoothVersionIndex === index) {
            setSelectedBoothVersionIndex(-1);
        } else {
            setSelectedBoothVersionIndex(index);
        }
    };

    /* input handlers */
    const handleBoothNameInput = (value) => {
        updateBoothNameRequestBody('boothName', value);
        if (value === "" || value == null) {
            updateBoothNameValidMap('boothName', false);
        } else {
            updateBoothNameValidMap('boothName', true);
        }
    };

    const handleBoothNameDescriptionInput = (value) => {
        updateBoothNameRequestBody('description', value);
        if (value === "" || value == null) {
            updateBoothNameValidMap('description', false);
        } else {
            updateBoothNameValidMap('description', true);
        }
    };

    const handleBoothVersionInput = (value) => {
        updateBoothVersionRequestBody('boothVersion', value);
        if (value === "" || value == null) {
            updateBoothVersionValidMap('boothVersion', false);
        } else {
            updateBoothVersionValidMap('boothVersion', true);
        }
    };

    const handleBoothVersionDescriptionInput = (value) => {
        updateBoothVersionRequestBody('description', value);
        if (value === "" || value == null) {
            updateBoothVersionValidMap('description', false);
        } else {
            updateBoothVersionValidMap('description', true);
        }
    };

    const handleBoothVersionGenerateDateInput = (value) => {
        updateBoothVersionRequestBody('generateDate', value);
        if (value === "" || value == null) {
            updateBoothVersionValidMap('generateDate', false);
        } else {
            updateBoothVersionValidMap('generateDate', true);
        }
    };

    /* utils */
    const updateBoothNameRequestBody = (key, value) => {
        setBoothNameRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateBoothNameValidMap = (key, value) => {
        setBoothNameValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateBoothVersionRequestBody = (key, value) => {
        setBoothVersionRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateBoothVersionValidMap = (key, value) => {
        setBoothVersionValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* effects */
    useEffect(() => {
        fetchJsonSchemaList();
        fetchBoothName();
    }, []);

    useEffect(() => {
        setSelectedBoothVersionIndex(-1);
        if (boothNameList.length > 0 && selectedBoothNameIndex > -1) {
            let newRequestBody = DataCompareService.deepCopyObj(boothNameList[selectedBoothNameIndex]);
            delete newRequestBody['versions'];
            delete newRequestBody['boothCount'];
            delete newRequestBody['boothNameId'];
            delete newRequestBody['generateDate'];
            setBoothNameRequestBody(newRequestBody);
            setFixedBoothNameRequestBody(DataCompareService.deepCopyObj(newRequestBody));
            setBoothNameValidMap(boothNameIsValidMapInit);
            setIsBoothNameValid(true);
            setIsBoothNameEdited(false);
        }
    }, [selectedBoothNameIndex]);

    useEffect(() => {
        if (selectedBoothNameIndex === -1) {
            return;
        }
        if (boothNameList[selectedBoothNameIndex]['versions'].length > 0 && selectedBoothVersionIndex > -1) {
            let newRequestBody = DataCompareService.deepCopyObj(boothNameList[selectedBoothNameIndex]['versions'][selectedBoothVersionIndex]);
            delete newRequestBody['boothCount'];
            delete newRequestBody['boothNameId'];
            delete newRequestBody['boothVersionId'];
            delete newRequestBody['versionIndex'];
            newRequestBody['generateDate'] = DataParseService.dateTimeToDate(newRequestBody['generateDate']);
            setBoothVersionRequestBody(newRequestBody);
            setFixedBoothVersionRequestBody(DataCompareService.deepCopyObj(newRequestBody));
            setBoothVersionValidMap(boothVersionIsValidMapInit);
            setIsBoothVersionValid(true);
            setIsBoothVersionEdited(false);
        }
    }, [selectedBoothVersionIndex]);

    useEffect(() => {
        setIsBoothNameValid(DataCompareService.checkIsAllTrue(boothNameValidMap));
    }, [boothNameValidMap]);

    useEffect(() => {
        setIsBoothNameEdited(!DataCompareService.isSameObj(boothNameRequestBody, fixedBoothNameRequestBody));
    }, [boothNameRequestBody]);

    useEffect(() => {
        setIsBoothVersionValid(DataCompareService.checkIsAllTrue(boothVersionValidMap));
    }, [boothVersionValidMap]);

    useEffect(() => {
        setIsBoothVersionEdited(!DataCompareService.isSameObj(boothVersionRequestBody, fixedBoothVersionRequestBody));
    }, [boothVersionRequestBody]);

    /* renderers */
    const renderBoothNameList = () => {
        if (boothNameList.length === 0) {
            return(
                <MessageView>
                    <Message>
                        {t("menu.booth.version.boothName.notFound")}
                    </Message>
                </MessageView>
            );
        } else {
            let boothNameElementList = [];
            boothNameList.map((boothName, index) => {
                if (selectedBoothNameIndex === index) {
                    boothNameElementList.push(
                        <SelectedBoothNameListElement
                            key={uuid()}
                            onClick={() => handleBoothNameSelect(index)}
                        >
                            <div>
                                <BoothNameView>
                                    {boothName['boothName']}
                                </BoothNameView>
                            </div>
                            <div
                                style={{
                                    marginLeft: 'auto'
                                }}
                            >
                                <MdKeyboardDoubleArrowRight
                                    size={"20"}
                                />
                            </div>
                        </SelectedBoothNameListElement>
                    );
                } else {
                    boothNameElementList.push(
                        <BoothNameListElement
                            key={uuid()}
                            onClick={() => handleBoothNameSelect(index)}
                        >
                            <div>
                                <BoothNameView>
                                    {boothName['boothName']}
                                </BoothNameView>
                            </div>
                            <div
                                style={{
                                    marginLeft: 'auto'
                                }}
                            >
                                <LiaEqualsSolid
                                    size={"20"}
                                />
                            </div>
                        </BoothNameListElement>
                    );
                }
            });
            return boothNameElementList;
        }
    };

    const renderBoothNameSpec = () => {
        if (selectedBoothNameIndex === -1) {
            return(
                <MessageView>
                    <Message>
                        {t("menu.booth.version.boothName.chooseBoothName")}
                    </Message>
                </MessageView>
            );
        } else {
            let boothNameInfo = boothNameList[selectedBoothNameIndex];
            return (
                <div
                    style={{
                        width: '100%',
                        padding: '10px'
                    }}
                >
                    <Container fluid>
                        <Row>
                            <Col style={{ padding: '0px' }}>
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left'
                                    }}
                                >
                                    <TitleAndInputBox
                                        title={""}
                                        value={boothNameRequestBody['boothName']}
                                        onChange={handleBoothNameInput}
                                        isForEdit={true}
                                        type={"text"}
                                        isValid={boothNameValidMap['boothName']}
                                        isEnabled={true}
                                    />
                                </div>

                            </Col>
                            <Col style={{ padding: '0px', paddingLeft: '10px' }}>
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'right'
                                    }}
                                >
                                    <span
                                        style={{
                                            color: '#757575',
                                            fontSize: '14px'
                                        }}
                                    >
                                        {
                                            boothNameList[selectedBoothNameIndex]['boothCount']
                                            + t("menu.booth.version.boothName.boothCount")
                                        }
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: '5px'
                            }}
                        >
                            <TitleAndTextArea
                                title={""}
                                value={boothNameRequestBody['description']}
                                onChange={handleBoothNameDescriptionInput}
                                isForEdit={true}
                                height={"90"}
                                isValid={boothNameValidMap['description']}
                            />
                        </Row>
                        <Row
                            style={{
                                marginTop: '3px'
                            }}
                        >
                            <Col
                                style={{
                                    padding: '0px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left'
                                    }}
                                >
                                    <SmallMessage>
                                        {
                                            t("menu.booth.version.boothName.generateDate")
                                            + " "
                                            + DataParseService.dateTimeToDate(boothNameInfo['generateDate'])
                                        }
                                    </SmallMessage>
                                </div>
                            </Col>
                            <Col
                                style={{
                                    padding: '0px',
                                    paddingLeft: '5px',
                                    textAlign: 'right'
                                }}
                            >
                                {
                                    isBoothNameValid && isBoothNameEdited ?
                                        <SaveButton
                                            onClick={() => putBoothName()}
                                        >
                                            {t("button.save")}
                                        </SaveButton>
                                        :
                                        <SaveButtonDisabled>
                                            {t("button.save")}
                                        </SaveButtonDisabled>
                                }
                                {
                                    boothNameInfo['versions'].length === 0 ?
                                        <DeleteButton
                                            style={{
                                                marginLeft: '10px'
                                            }}
                                            onClick={() => setIsBoothNameDeleteConfirmOpen(true)}
                                        >
                                            {t("button.delete")}
                                        </DeleteButton>
                                        :
                                        <SaveButtonDisabled
                                            style={{
                                                marginLeft: '10px'
                                            }}
                                        >
                                            {t("button.delete")}
                                        </SaveButtonDisabled>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
    };

    const renderBoothVersionList = () => {
        if (selectedBoothNameIndex === -1) {
            return(
                <MessageView>
                    <Message>
                        {t("menu.booth.version.boothName.chooseBoothName")}
                    </Message>
                </MessageView>
            );
        } else {
            let boothVersionList = boothNameList[selectedBoothNameIndex]['versions'];
            if (boothVersionList.length === 0) {
                return(
                    <MessageView>
                        <Message>
                            {t("menu.booth.version.boothVersion.notFound")}
                        </Message>
                    </MessageView>
                );
            } else {
                let boothVersionElementList = [];
                boothVersionList.map((boothVersion, index) => {
                    if (selectedBoothVersionIndex === index) {
                        boothVersionElementList.push(
                            <SelectedBoothVersionListElement
                                key={uuid()}
                                onClick={() => handleBoothVersionSelect(index)}
                            >
                                <div>
                                    <BoothNameView>
                                        {boothVersion['boothVersion']}
                                    </BoothNameView>
                                </div>
                                <div
                                    style={{
                                        marginLeft: 'auto'
                                    }}
                                >
                                    <MdKeyboardDoubleArrowRight
                                        size={"20"}
                                    />
                                </div>
                            </SelectedBoothVersionListElement>
                        );
                    } else {
                        boothVersionElementList.push(
                            <BoothVersionListElement
                                key={uuid()}
                                onClick={() => handleBoothVersionSelect(index)}
                            >
                                <div>
                                    <BoothNameView>
                                        {boothVersion['boothVersion']}
                                    </BoothNameView>
                                </div>
                                <div
                                    style={{
                                        marginLeft: 'auto'
                                    }}
                                >
                                    <LiaEqualsSolid
                                        size={"20"}
                                    />
                                </div>
                            </BoothVersionListElement>
                        );
                    }
                });
                return boothVersionElementList;
            }
        }
    };

    const renderBoothVersionSpec = () => {
        if (selectedBoothVersionIndex === -1 || selectedBoothNameIndex === -1) {
            return(
                <MessageView>
                    <Message>
                        {t("menu.booth.version.boothVersion.chooseBoothVersion")}
                    </Message>
                </MessageView>
            );
        } else {
            if (boothNameList[selectedBoothNameIndex]['versions'].length === 0) {
                return(
                    <MessageView>
                        <Message>
                            {t("menu.booth.version.boothVersion.chooseBoothVersion")}
                        </Message>
                    </MessageView>
                );
            }
            let boothVersionInfo = boothNameList[selectedBoothNameIndex]['versions'][selectedBoothVersionIndex];
            if (typeof boothVersionInfo === "undefined") {
                return(
                    <MessageView>
                        <Message>
                            {t("menu.booth.version.boothVersion.chooseBoothVersion")}
                        </Message>
                    </MessageView>
                );
            }
            return (
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <Container fluid>
                        <Row>
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    paddingLeft: '10px'
                                }}
                            >
                                <SmallMessage
                                    style={{
                                        color: '#757575'
                                    }}
                                >
                                    {
                                        boothVersionInfo['boothCount'] + t("menu.booth.version.boothVersion.detail.boothCount")
                                    }
                                </SmallMessage>
                            </div>
                        </Row>
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <TitleAndInputBox
                                title={t("menu.booth.version.boothVersion.detail.boothVersion")}
                                value={boothVersionRequestBody['boothVersion']}
                                onChange={handleBoothVersionInput}
                                isForEdit={true}
                                type={"text"}
                                isValid={boothVersionValidMap['boothVersion']}
                                isEnabled={true}
                            />
                        </Row>
                        <Row
                            style={{
                                marginTop: '5px'
                            }}
                        >
                            <TitleAndTextArea
                                title={t("menu.booth.version.boothVersion.detail.description")}
                                value={boothVersionRequestBody['description']}
                                onChange={handleBoothVersionDescriptionInput}
                                isForEdit={true}
                                height={"100"}
                                isValid={boothVersionValidMap['description']}
                            />
                        </Row>
                        <Row
                            style={{
                                marginTop: '5px'
                            }}
                        >
                            <TitleAndInputBox
                                title={t("menu.booth.version.boothVersion.detail.generateDate")}
                                value={boothVersionRequestBody['generateDate']}
                                onChange={handleBoothVersionGenerateDateInput}
                                isForEdit={true}
                                type={"date"}
                                isValid={boothVersionValidMap['generateDate']}
                                isEnabled={true}
                            />
                        </Row>
                        <Row>
                            <div
                                style={{
                                    paddingRight: '10px',
                                    width: '100%',
                                    textAlign: 'right'
                                }}
                            >
                                <SmallMessage>
                                    {t("menu.booth.version.boothVersion.detail.sortMessage")}
                                </SmallMessage>
                            </div>
                        </Row>
                        <Row
                            style={{
                                marginTop: '5px'
                            }}
                        >
                            <TitleAndSelectBox
                                title={t("menu.booth.version.boothVersion.detail.boothJsonVersion")}
                                innerValue={boothVersionRequestBody['boothJsonVersion']}
                                valueList={jsonSchemaList}
                                valueSelector={"schemaVersion"}
                                viewSelector={"schemaName"}
                                onChange={() => {}}
                            />
                        </Row>
                        <Row
                            style={{
                                marginTop: '15px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'inline-flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <BoothErrorCodeConfigButton
                                    onClick={() => setIsErrorCodeEditModalOpen(true)}
                                >
                                    {t("menu.booth.version.additional.configErrorCode.button")}
                                </BoothErrorCodeConfigButton>
                                {
                                    isBoothVersionValid && isBoothVersionEdited ?
                                        <SaveButton
                                            style={{
                                                marginLeft : 'auto'
                                            }}
                                            onClick={() => putBoothVersion()}
                                        >
                                            {t("button.save")}
                                        </SaveButton>
                                        :
                                        <SaveButtonDisabled
                                            style={{
                                                marginLeft : 'auto'
                                            }}
                                        >
                                            {t("button.save")}
                                        </SaveButtonDisabled>
                                }
                                {
                                    boothVersionInfo['boothCount'] === 0 ?
                                        <DeleteButton
                                            style={{
                                                marginLeft: '10px'
                                            }}
                                            onClick={() => setIsDeleteConfirmOpen(true)}
                                        >
                                            {t("button.delete")}
                                        </DeleteButton>
                                        :
                                        <SaveButtonDisabled
                                            style={{
                                                marginLeft: '10px'
                                            }}
                                        >
                                            {t("button.delete")}
                                        </SaveButtonDisabled>
                                }
                            </div>
                        </Row>
                    </Container>
                </div>
            );
        }
    };

    /* main return */
    return (
        <RenderOnRole roleName={""} type={""} allowedRing={0} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/booth"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.booth.title"), t("menu.booth.version.title")]}
                    depth={2}
                />
            </div>
            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                    <Container fluid>
                        {/* main area */}
                        <Row>
                            {/* booth name selection */}
                            <Col md={"4"}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '700px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {/* booth name list */}
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '640px',
                                            border: '1px solid #ebebeb',
                                            borderRadius: '5px',
                                            padding: '10px',
                                            overflow: 'auto'
                                        }}
                                    >
                                        {renderBoothNameList()}
                                    </div>
                                    {/* bottom button */}
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            width: '100%',
                                            height: '50px',
                                            border: '1px solid #ebebeb',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <BoothNameAddButton
                                            onClick={() => setIsBoothNameAddModalOpen(true)}
                                        >
                                            {t("menu.booth.version.boothName.add.title")}
                                        </BoothNameAddButton>
                                        <AddBoothName
                                            isOpen={isBoothNameAddModalOpen}
                                            setIsOpen={setIsBoothNameAddModalOpen}
                                            onRefresh={() => {
                                                fetchBoothName();
                                                setSelectedBoothNameIndex(-1);
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={"8"}>
                                {/* booth name specification */}
                                <Row>
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '200px',
                                            border: '1px solid #ebebeb',
                                            borderRadius: '5px'
                                        }}
                                    >
                                        {renderBoothNameSpec()}
                                    </div>
                                </Row>
                                {/* booth versions */}
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <Col md={"5"} style={{ padding: '0px' }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '490px',
                                                border: '1px solid #ebebeb',
                                                borderRadius: '5px',
                                                padding: '10px'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '430px',
                                                    overflow: 'auto'
                                                }}
                                            >
                                                {renderBoothVersionList()}
                                            </div>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '50px',
                                                    paddingTop: '10px',
                                                    textAlign: 'left',
                                                    borderTop: '1px solid #ebebeb',
                                                }}
                                            >
                                                {
                                                    (selectedBoothNameIndex === -1) ?
                                                        <BoothVersionAddButtonDisabled>
                                                            {t("menu.booth.version.boothVersion.add.title")}
                                                        </BoothVersionAddButtonDisabled>
                                                        :
                                                        <BoothVersionAddButton
                                                            onClick={() => setIsBoothVersionAddModelOpen(true)}
                                                        >
                                                            {t("menu.booth.version.boothVersion.add.title")}
                                                        </BoothVersionAddButton>
                                                }
                                                {
                                                    selectedBoothNameIndex !== -1 &&
                                                    <AddBoothVersion
                                                        jsonSchemaList={jsonSchemaList}
                                                        isOpen={isBoothVersionAddModalOpen}
                                                        setIsOpen={setIsBoothVersionAddModelOpen}
                                                        onRefresh={() => {
                                                            setSelectedBoothVersionIndex(-1);
                                                            fetchBoothName();
                                                        }}
                                                        boothName={boothNameList[selectedBoothNameIndex]['boothName']}
                                                        boothNameId={boothNameList[selectedBoothNameIndex]['boothNameId']}
                                                    />
                                                }
                                            </div>

                                        </div>
                                    </Col>
                                    <Col md={"7"} style={{ padding: '0px', paddingLeft: '10px' }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '490px',
                                                border: '1px solid #ebebeb',
                                                borderRadius: '5px',
                                                padding: '10px'
                                            }}
                                        >
                                            {renderBoothVersionSpec()}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* version */}
                {
                    (selectedBoothNameIndex !== -1) &&
                    (selectedBoothVersionIndex !== -1) &&
                    (typeof boothVersionRequestBody['boothJsonVersion'] !== "undefined") &&
                    (boothVersionRequestBody['boothJsonVersion'] != null) &&
                    (boothVersionRequestBody['boothJsonVersion'] !== "") &&
                    <BoothErrorCodeConfigModal
                        isOpen={isErrorCodeEditModalOpen}
                        setIsOpen={setIsErrorCodeEditModalOpen}
                        boothNameId={boothNameList[selectedBoothNameIndex]['boothNameId']}
                        boothVersionId={boothNameList[selectedBoothNameIndex]['versions'][selectedBoothVersionIndex]['boothVersionId']}
                        boothJsonVersion={boothVersionRequestBody['boothJsonVersion']}
                    />
                }
                {
                    (selectedBoothNameIndex !== -1) &&
                    <DeleteConfirmModal
                        isOpen={isBoothNameDeleteConfirmOpen}
                        setIsOpen={setIsBoothNameDeleteConfirmOpen}
                        isDelayed={false}
                        confirmMessage={t("language.message.deletion.confirmation", { entityName: boothNameList[selectedBoothNameIndex]['boothName'] })}
                        entity={DeleteService.ValidEntities.BOOTH_NAME}
                        entityName={boothNameList[selectedBoothNameIndex]['boothName']}
                        id={`booth-version/name/${boothNameList[selectedBoothNameIndex]['boothNameId']}`}
                        onDeleteSuccess={async () => {
                            await fetchBoothName();
                            await setSelectedBoothNameIndex(-1);
                        }}
                    />
                }
                {
                    (selectedBoothVersionIndex !== -1) &&
                    <DeleteConfirmModal
                        isOpen={isDeleteConfirmOpen}
                        setIsOpen={setIsDeleteConfirmOpen}
                        isDelayed={false}
                        confirmMessage={t("language.message.deletion.confirmation", { entityName: boothNameList[selectedBoothNameIndex]['versions'][selectedBoothVersionIndex]['boothVersion'] })}
                        entity={DeleteService.ValidEntities.BOOTH_VERSION}
                        entityName={boothNameList[selectedBoothNameIndex]['versions'][selectedBoothVersionIndex]['boothVersion']}
                        id={`booth-version/name/${boothNameList[selectedBoothNameIndex]['boothNameId']}/version/${boothNameList[selectedBoothNameIndex]['versions'][selectedBoothVersionIndex]['boothVersionId']}`}
                        onDeleteSuccess={async () => {
                            await fetchBoothName();
                            await setSelectedBoothVersionIndex(-1);
                        }}
                    />
                }
            </div>
        </RenderOnRole>
    );

};

export default BoothVersionManagement;