import React, {useEffect, useState} from 'react';
import axios from "axios";
import UserService from "../../../services/UserService";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import DataCompareService from "../../../services/DataCompareService";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../common/dataView/TitleAndTextArea";
import Modal from "react-modal";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '600px',
        height: '400px',
        margin: 'auto'
    }
};

const boothNameRequestBodyInit = {
    boothName: "",
    description: ""
};

const boothNameIsValidMapInit = {
    boothName: false,
    description: false
};

const SaveButton = styled.button`
  background: none;
  border-radius: 5px;
  font-size: 15px;
  width: 100%;
  color: #fc7242;
  border: 1px solid #fc7242;
  transition: all ease-in-out 0.2s;
  padding: 10px 15px;

  &:hover {
    background-color: #fc7242;
    color: white;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  background: none;
  border-radius: 5px;
  font-size: 15px;
  width: 100%;
  color: #c2c2c2;
  border: 1px solid #c2c2c2;
  padding: 10px 15px;
  
  &:focus {
    outline: none;
  }
`;

const AddBoothName = ({ isOpen, setIsOpen, onRefresh }) => {

    const { t } = useTranslation();

    const [boothNameRequestBody, setBoothNameRequestBody] = useState(boothNameRequestBodyInit);
    const [boothNameValidMap, setBoothNameValidMap] = useState(boothNameIsValidMapInit);
    const [isBoothNameValid, setIsBoothNameValid] = useState(false);

    /* post booth name */
    const postBoothName = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/booth-version/name`;
            const axiosCall = () => axios.post(
                fetchUrl,
                JSON.stringify(boothNameRequestBody),
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            setIsOpen(false);
            onRefresh();
            toast.success(<ToastAlertView message={t("message.saved")} />);
        } catch (err) {
            console.log(err);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* input handlers */
    const handleBoothNameInput = (value) => {
        updateBoothNameRequestBody('boothName', value);
        if (value === "" || value == null) {
            updateBoothNameValidMap('boothName', false);
        } else {
            updateBoothNameValidMap('boothName', true);
        }
    };

    const handleBoothNameDescriptionInput = (value) => {
        updateBoothNameRequestBody('description', value);
        if (value === "" || value == null) {
            updateBoothNameValidMap('description', false);
        } else {
            updateBoothNameValidMap('description', true);
        }
    };

    /* utils */
    const updateBoothNameRequestBody = (key, value) => {
        setBoothNameRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateBoothNameValidMap = (key, value) => {
        setBoothNameValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* effects */
    useEffect(() => {
        setIsBoothNameValid(DataCompareService.checkIsAllTrue(boothNameValidMap));
    }, [boothNameValidMap]);

    useEffect(() => {
        setBoothNameRequestBody(boothNameRequestBodyInit);
        setBoothNameValidMap(boothNameIsValidMapInit);
    }, [isOpen]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.booth.version.boothName.add.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'left'
                        }}
                    >
                        <TitleAndInputBox
                            title={t("menu.booth.version.boothName.add.boothName")}
                            value={boothNameRequestBody['boothName']}
                            onChange={handleBoothNameInput}
                            isForEdit={true}
                            type={"text"}
                            isValid={boothNameValidMap['boothName']}
                            isEnabled={true}
                        />
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndTextArea
                        title={t("menu.booth.version.boothName.add.description")}
                        value={boothNameRequestBody['description']}
                        onChange={handleBoothNameDescriptionInput}
                        isForEdit={true}
                        height={"120"}
                        isValid={boothNameValidMap['description']}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    {
                        isBoothNameValid ?
                            <SaveButton
                                onClick={() => postBoothName()}
                            >
                                {t("button.save")}
                            </SaveButton>
                            :
                            <SaveButtonDisabled>
                                {t("button.save")}
                            </SaveButtonDisabled>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default AddBoothName;