import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import "./ImageUploadStyle.css";
import Modal from "react-modal";
import {Container, Image, Row} from "react-bootstrap";
import axios from "axios";
import UserService from '../../../services/UserService';
import ModalCloseButton from "../modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '400px',
        height: '360px',
        margin: 'auto'
    }
}

const ImageUpload = ({ isOpen, setIsOpen, usage, usageId, onRefresh }) => {

    const { t } = useTranslation();
    const [imgFile, setImgFile] = useState("");
    const imgRef = useRef();

    const handleFileChange = () => {
        const file = imgRef.current.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImgFile(reader.result);
        };
    };

    const postImage = async () => {
        const formData = new FormData();
        formData.append("file", imgRef.current.files[0]);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/image/${usage}/${usageId}`;
            const axiosCall = () => axios.post(
                fetchUrl,
                formData,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "multipart/form-data",
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            onRefresh();
            toast.success(<ToastAlertView message={t("message.saved")} />);
            setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    /* effect */
    useEffect(() => {
        setImgFile("");
    }, [isOpen]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("common.image.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row
                    style={{
                        marginTop : '40px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '180px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center'
                            }}
                        >
                            <Image
                                src={
                                    imgFile == "" ?
                                        "https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png"
                                    :
                                        imgFile
                                }
                                alt="image"
                                roundedCircle={(usage === "user" || usage === "admin") ? true : false}
                                style={{ width: '90px', height: '90px', display: 'inline-block', border: '0'}}
                            />
                        </div>
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                marginTop: '20px'
                            }}
                        >
                            <label className="signup-profileImg-label" htmlFor="profileImg">
                                {t("common.image.uploadImage")}
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                id="profileImg"
                                onChange={() => handleFileChange()}
                                ref={imgRef}
                            />
                        </div>
                    </div>
                </Row>
                <Row>
                    {
                        (imgFile === "")
                        ?
                            <button
                                id={'long-save-btn-disabled'}
                            >
                                {t("button.save")}
                            </button>
                            :
                            <button
                                id={'long-save-btn'}
                                onClick={() => postImage()}
                            >
                                {t("button.save")}
                            </button>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default ImageUpload;