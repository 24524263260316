import React, {useEffect, useState} from 'react';
import {toast, ToastContainer, Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from "styled-components";
import './AlertStyle.css';
import { ReactComponent as AlertIcon } from "../../icons/alertIcon.svg";
import useInterval from "../../customHooks/useInterval";
import axios from "axios";
import UserService from "../../services/UserService";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import AlertPreview from "./AlertPreview";
import {Nav} from "react-bootstrap";

const TransButton = styled.button`
  background: none;
  border: none;
  z-index: 9998;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  &:focus {
    outline: none;
  }
`;

const Badge = styled.button`
  width: 25px;
  height: 25px;
  position: absolute;
  right: -5px;
  bottom: 10px;
  background-color: #fc7242;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #fc7242;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  
  &:focus {
    outline: none;
  }
`;

const AlertTitle = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: #696969;
`;

const AlertContent = styled.span`
  font-size: 14px;
  color: #c2c2c2;
  text-overflow: ellipsis;
`;


const AlertMagic = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    /* states */
    const [badgeCount, setBadgeCount] = useState(0);
    const [alertList, setAlertList] = useState([]);
    const [isAlertPreviewOpen, setIsAlertPreviewOpen] = useState(false);

    /* utils */
    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + '...';
        } else {
            return str;
        }
    }

    /* fetch alert list */
    const fetchAlertList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/web-alert?coverage=unread`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            let newAlertList = res.data.item.alertList;
            setAlertList(newAlertList);
            setBadgeCount(newAlertList.length);
        } catch (e) {
            console.log(e);
        }
    };

    const AlertInner = (data) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
                onClick={() => navigate(data['imsGuiPath'])}
            >
                {/* title */}
                <div>
                    <AlertTitle>
                        {data[t("language.dataKey.alert.title")]}
                    </AlertTitle>
                </div>
                {/* content */}
                <div>
                    <AlertContent>
                        {data[t("language.dataKey.alert.content")]}
                    </AlertContent>
                </div>
            </div>
        );
    };

    const notifyAlert = (data) => toast(AlertInner(data));

    /* TIMER : fetch data */
    useInterval(() => {
        fetchAlertList();
    }, 1500);

    /* effects for make alert */
    useEffect(() => {
        /* get received alert list from localStorage */
        const jsonStr = window.localStorage.getItem("ims_received_alerts");
        let receivedIds;
        let alertTargets = [];
        if (typeof jsonStr === "undefined" || jsonStr == null) {
            receivedIds = [];
        } else {
            receivedIds = JSON.parse(jsonStr);
        }
        /* compare and extract difference */
        for (let i=0 ; i<alertList.length ; i++) {
            if (!receivedIds.includes(alertList[i]['imsWebAlertId'])) {
                receivedIds.push(alertList[i]['imsWebAlertId']);
                alertTargets.push(alertList[i]);
            }
        }
        /* make alert */
        alertTargets.map(alert => {
            notifyAlert(alert);
        });
        /* update received alert into localStorage */
        localStorage.setItem("ims_received_alerts", JSON.stringify(receivedIds));
    }, [alertList]);

    const NavItem = (props) => {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                }}
            >
                <TransButton className="icon-button" onClick={() => setIsAlertPreviewOpen(!isAlertPreviewOpen)}>
                    {props.icon}
                </TransButton>
                {isAlertPreviewOpen && props.children}
            </div>
        );
    };

    return (
        <div
            style={{
                display: 'inline-block',
                position: 'relative'
            }}
        >
            {/* preview */}
            <NavItem
                icon={
                    <>
                        {
                            (badgeCount !== 0) &&
                            <Badge>
                                {badgeCount}
                            </Badge>
                        }
                        <AlertIcon
                            style={
                                isAlertPreviewOpen ?
                                    {
                                        fill : "#696969"
                                    }
                                    :
                                    {
                                        fill : "#c0c0c0"
                                    }
                            }
                        />
                    </>
                }
            >
                <AlertPreview
                    setIsOpen={setIsAlertPreviewOpen}
                    data={alertList}
                    fetchData={fetchAlertList}
                />
            </NavItem>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
                pauseOnFocusLoss={false}
            />
        </div>
    );
};

export default AlertMagic;