import React from 'react';
import {useTranslation} from "react-i18next";
import DataUtils from "../../common/utils/DataUtils";
import styled from "styled-components";
import OverlayToolTip from "../../common/toolTip/OverlayToolTip";
import Switch from "react-switch";
import Form from 'react-bootstrap/Form';
import { FiLock, FiUnlock } from "react-icons/fi";

const colorMap = {
    backgroundGrey : "#F8F8F8",
    defaultButtonColor : "#c2c2c2",
    defaultButtonHoverColor : "#F5F5F5",
    defaultButtonColorOnSelected : "#A9A9A9",
    diffUseButtonColor : "#F0B3FF",
    diffUseButtonHoverColor : "#FAE6FF",
    diffUseButtonColorOnSelected : "#CC00FF"
};

const BooleanContainer = styled.div`
  padding-left: 15px;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.reversed ? 'flex-start' : 'flex-end'};
`;

const OptionButton = styled.button`
  width: auto;
  padding: 3px 7px;
  color: ${(props) => props.color ? props.color : "#757575"};
  font-size: 14px;
  border: 1px solid ${(props) => props.color ? props.color : "#757575"};
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "white"};
  transition: all ease-in-out 0.2s;
  margin-left: ${(props) => props.marginLeft ? props.marginLeft : "0px"};
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.hoverColor ? props.hoverColor : "white"};
  }
`;

const TransButton = styled.button`
  border: none;
  background: none;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  color: #757575;
  
  &:hover {
    color: ${(props) => props.enabled ? "black" : ""}; 
  }
  
  &:focus {
    outline: none;
  }
`;

const BooleanMessage = styled.span`
  margin-left: 15px;
  color: ${(props) => props.isOn ? "#4586ff" : "#DC143C"};
  font-size: 14px;
`;

const DiffDataView = ({ type,
                          hoverMessageForUseDiffButton,
                          title,
                          originalDataKey,
                          diffDataKey,
                          originalData,
                          diffEntity,
                          isValidMap,
                          updateDiffEntity,
                          updateIsValidMap,
                          isForEdit,
                          height,
                          booleanReversed,
                          booleanOnMessage,
                          booleanOffMessage}) => {
    /** NOTE */
    /** 1. possible types : number, text, textarea, switch */

    const { t } = useTranslation();

    const handleDiffUpdate = (value) => {
        if (type === "number") {
            updateDiffEntity(diffDataKey, parseFloat(value));
        } else {
            updateDiffEntity(diffDataKey, value);
        }
        /* validation */
        if (type === "number") {
            updateIsValidMap(diffDataKey, DataUtils.isValidNumStr(value));
        }
    };

    const handleNoUseDiff = () => {
        if (!isForEdit) {
            return;
        }
        if (diffEntity[diffDataKey] == null) {
            return;
        }
        /* set null */
        updateDiffEntity(diffDataKey, null);
        /* empty is valid one */
        updateIsValidMap(diffDataKey, true);
    };

    const handleUseDiff = () => {
        if (!isForEdit) {
            return;
        }
        if (diffEntity[diffDataKey] != null) {
            return;
        }
        /* initial value set */
        updateDiffEntity(diffDataKey, originalData[originalDataKey]);
        /* valid one */
        updateIsValidMap(diffDataKey, true);
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: (type === "switch") ? (booleanReversed ? "flex-start" : "flex-end") : "flex-start",
                        paddingLeft: '10px',
                        marginBottom: '5px'
                    }}
                >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px',
                        }}
                    >
                        {title}
                    </span>
                    {
                        (type !== "switch") &&
                        <div
                            style={{
                                height: '100%',
                                display: 'inline-flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginLeft: "10px"
                            }}
                        >
                            {
                                (diffEntity[diffDataKey] != null) &&
                                <OverlayToolTip
                                    direction={"top"}
                                    text={hoverMessageForUseDiffButton}
                                >
                                    <TransButton
                                        onClick={() => {
                                            if (diffEntity[diffDataKey] != null) {
                                                handleNoUseDiff();
                                            } else {
                                                handleUseDiff();
                                            }
                                        }}
                                    >
                                        <FiUnlock
                                            size={"14"}
                                            color={"#fc7242"}
                                        />
                                    </TransButton>
                                </OverlayToolTip>
                            }
                            {
                                (diffEntity[diffDataKey] == null) &&
                                <TransButton
                                    onClick={() => {
                                        if (diffEntity[diffDataKey] != null) {
                                            handleNoUseDiff();
                                        } else {
                                            handleUseDiff();
                                        }
                                    }}
                                    enabled={true}
                                >
                                    <FiLock
                                        size={"14"}
                                    />
                                </TransButton>
                            }
                        </div>
                    }
                </div>
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    {
                        (type === "textarea") &&
                        <textarea
                            id={"data-view-input"}
                            type={type}
                            readOnly={!((diffEntity[diffDataKey] != null) && isForEdit)}
                            value={
                                (diffEntity[diffDataKey] != null) ? diffEntity[diffDataKey] : originalData[originalDataKey]
                            }
                            onChange={(e) => handleDiffUpdate(e.target.value)}
                            style={
                                (diffEntity[diffDataKey] != null) ?
                                    (isValidMap[diffDataKey] ?
                                        {
                                            height : height ? height + "px" : "100px",
                                            resize : 'none',
                                            outline : 'none'
                                        }
                                        :
                                        {
                                            height : height ? height + "px" : "100px",
                                            resize : 'none',
                                            outline : 'none',
                                            borderColor: isForEdit ? "red" : ""
                                        })
                                    :
                                    {
                                        height : height ? height + "px" : "100px",
                                        resize : 'none',
                                        outline : 'none',
                                        backgroundColor: colorMap["backgroundGrey"]
                                    }
                            }
                        />
                    }
                    {
                        ((type === "text") || (type === "number")) &&
                        <input
                            id={"data-view-input"}
                            type={type}
                            readOnly={!((diffEntity[diffDataKey] != null) && isForEdit)}
                            value={(diffEntity[diffDataKey] != null) ? diffEntity[diffDataKey] : originalData[originalDataKey]}
                            onChange={(e) => handleDiffUpdate(e.target.value)}
                            style={
                                (diffEntity[diffDataKey] != null) ?
                                    (isValidMap[diffDataKey] ?
                                        {}
                                        :
                                        {
                                            borderColor: isForEdit ? "red" : ""
                                        })
                                    :
                                    {
                                        backgroundColor: colorMap["backgroundGrey"]
                                    }
                            }
                        />
                    }
                    {
                        (type === "switch") &&
                        <BooleanContainer
                            reversed={booleanReversed}
                        >
                            {
                                (diffEntity[diffDataKey] == null) ?
                                    <Switch
                                        checked={originalData[originalDataKey]}
                                        readOnly
                                        onChange={() => {}}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        onColor={"#008000"}
                                    />
                                    :
                                    <Switch
                                        checked={diffEntity[diffDataKey]}
                                        onChange={(newValue) => handleDiffUpdate(newValue)}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        onColor={"#52b640"}
                                    />
                            }
                            {
                                (diffEntity[diffDataKey] != null) &&
                                booleanOnMessage &&
                                (diffEntity[diffDataKey]) &&
                                <BooleanMessage
                                    isOn={true}
                                >
                                    {booleanOnMessage}
                                </BooleanMessage>
                            }
                            {
                                (diffEntity[diffDataKey] != null) &&
                                booleanOnMessage &&
                                (!diffEntity[diffDataKey]) &&
                                <BooleanMessage
                                    isOn={false}
                                >
                                    {booleanOffMessage}
                                </BooleanMessage>
                            }
                        </BooleanContainer>
                    }
                </div>
            </div>
        </div>
    );
};

export default DiffDataView;