import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import DataParseService from "../../../services/DataParseService";
import DataCompareService from "../../../services/DataCompareService";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import TitleAndSelectBox from "../../common/dataView/TitleAndSelectBox";
import TitleAndTextArea from "../../common/dataView/TitleAndTextArea";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import UserService from "../../../services/UserService";
import axios from "axios";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '440px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    franchiseName: "",
    description: "",
    franchiseGroupId:""
};

const isValidMapInit = {
    franchiseName: false,
    description: false,
    franchiseGroupId: false
};

const AddFranchiseModal = ({ isOpen, setIsOpen, onRefresh, groupList }) => {

    const { t } = useTranslation();

    /* states */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);

    /* initialization */
    const initializeAll = () => {
        setRequestBody(requestBodyInit);
        setIsValidMap(isValidMapInit);
        /* set default franchiseGroupId */
        if (groupList.length > 0) {
            handleGroupIdChange(groupList[0]['groupId']);
        }
    };

    /* post franchise */
    const postFranchise = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`;
            let newRequestBody = JSON.stringify(requestBody);
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                newRequestBody,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            let generateFranchiseId = res.data.item['generatedFranchiseInfo']['franchiseId'];
            /* on success */
            onRefresh(`/man-franchise/group/${requestBody['franchiseGroupId']}/franchise/${generateFranchiseId}`);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* utils */
    const updateHelper = (setState, key, value) => {
        setState(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    }

    /* input handlers */
    const handleFranchiseNameInput = (value) => {
        updateHelper(setRequestBody, "franchiseName", value);
        if (typeof value === "undefined" || value == null || value === "") {
            updateHelper(setIsValidMap, "franchiseName", false);
        } else {
            updateHelper(setIsValidMap, "franchiseName", true);
        }
    };

    const handleDescriptionInput = (value) => {
        updateHelper(setRequestBody, "description", value);
        if (typeof value === "undefined" || value == null || value === "") {
            updateHelper(setIsValidMap, "description", false);
        } else {
            updateHelper(setIsValidMap, "description", true);
        }
    };

    const handleGroupIdChange = (value) => {
        updateHelper(setRequestBody, "franchiseGroupId", value);
        if (typeof value === "undefined" || value == null || value === "") {
            updateHelper(setIsValidMap, "franchiseGroupId", false);
        } else {
            updateHelper(setIsValidMap, "franchiseGroupId", true);
        }
    };

    useEffect(() => {
        if (groupList.length > 0) {
            initializeAll();
        }
    }, [isOpen]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap))
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchise.add.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndSelectBox
                        title={t("menu.franchise.add.detail.franchiseGroup")}
                        innerValue={requestBody["franchiseGroupId"]}
                        valueList={groupList}
                        valueSelector={"groupId"}
                        viewSelector={"groupName"}
                        onChange={handleGroupIdChange}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndInputBox
                        title={t("menu.franchise.add.detail.franchiseName")}
                        value={requestBody["franchiseName"]}
                        onChange={handleFranchiseNameInput}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap["franchiseName"]}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndTextArea
                        title={t("menu.franchise.add.detail.description")}
                        value={requestBody["description"]}
                        onChange={handleDescriptionInput}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap["description"]}
                        height={"80"}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {
                        (isValid)
                            ?
                            <button
                                id={"long-save-btn"}
                                onClick={() => postFranchise()}
                            >
                                {t("button.save")}
                            </button>
                            :
                            <button
                                id={"long-save-btn-disabled"}
                            >
                                {t("button.save")}
                            </button>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default AddFranchiseModal;