import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import CommonUtil from "../../utils/CommonUtil";
import BoothService from "../../axiosServices/BoothService";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import _ from "lodash";
import ServerFailed from "../info/ServerFailed";
import DataView from "../../dataView/DataView";
import BoothControlAuthService from "../../auth/BoothControlAuthService";

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DataContainerRounded = styled.div`
  margin-top: 20px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
`;

const DataContainerInnerTitleContainer = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  border-bottom: 1px solid #e4e7ea;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
`;

const InnerTitle = styled.div`
  color: #848487;
  text-align: left;
`;

const Attribute = () => {

    const { t, i18n } = useTranslation();

    /* params */
    const { boothId, deviceId } = useParams();

    /* booth schema */
    const boothSchema = JSON.parse(window.localStorage.getItem("ims_json_schema"));
    const schemaVersion = boothSchema['metadata']['id'];

    /* device schema */
    const deviceSchema = CommonUtil.getObjectInListById(deviceId, boothSchema['spec']['devices']);

    /* attribute schema initialization */
    let attributeSchemas = deviceSchema['spec']['attributes'];
    Object.keys(attributeSchemas).map(attrName => {
        if (!BoothControlAuthService.hasGetAuth(attributeSchemas[attrName]['superPermission']) &&
            !BoothControlAuthService.hasPostAuth(attributeSchemas[attrName]['superPermission'])){
            delete attributeSchemas[attrName];
        }
    });

    /* booth data */
    const [boothData, setBoothData] = useState({});

    /* loading */
    const [isLoading, setIsLoading] = useState(true);

    /* fetch booth data */
    const fetchBoothData = () => {
        console.log("data fetch start");
        const onSuccessCallBack = (data) => {
            console.log("data fetched");
            setBoothData(data);
            setIsLoading(false);
        };
        const onFailureCallBack = () => {
            setBoothData({});
            setIsLoading(false);
        };
        BoothService.fetchBoothData(boothId, onSuccessCallBack, onFailureCallBack);
    };

    /* effects */
    /* fetch booth data */
    useEffect(() => {
        setIsLoading(true);
        fetchBoothData();
    }, [deviceId]);

    /* TIMER : fetch data */
    // useInterval(() => {
    //     fetchBoothData();
    // }, 1500);

    const renderAttributesOdd = () => {
        let attributeKeyList = Object.keys(attributeSchemas);
        let attributeElements = [];
        let attributeData = boothData[schemaVersion][deviceId]['attributes'];
        attributeKeyList.map((attributeKey, index) => {
            if (index % 2 === 1) {
                attributeElements.push(<></>);
            } else {
                attributeElements.push(
                    <DataContainerRounded>
                        <DataContainerInnerTitleContainer>
                            <InnerTitle>
                                {attributeSchemas[attributeKey][t("language.dataKey.boothControl.json.label")]}
                            </InnerTitle>
                        </DataContainerInnerTitleContainer>
                        {/* data */}
                        <div
                            style={{
                                padding: '5px 15px 15px 15px'
                            }}
                        >
                            <DataView
                                key={deviceId}
                                dataSchema={attributeSchemas[attributeKey]}
                                mainKey={attributeKey}
                                sort={"ATTRIBUTE"}
                                data={attributeData[attributeKey]}
                                requestPath={`/booth/${boothId}/device/${deviceId}`}
                                onRefresh={fetchBoothData}
                            />
                        </div>
                    </DataContainerRounded>
                );
            }
        });
        return attributeElements;
    };

    const renderAttributesEven = () => {
        let attributeKeyList = Object.keys(attributeSchemas);
        let attributeElements = [];
        let attributeData = boothData[schemaVersion][deviceId]['attributes'];
        attributeKeyList.map((attributeKey, index) => {
            if (index % 2 !== 1) {
                attributeElements.push(<></>);
            } else {
                attributeElements.push(
                    <DataContainerRounded>
                        <DataContainerInnerTitleContainer>
                            <InnerTitle>
                                {attributeSchemas[attributeKey][t("language.dataKey.boothControl.json.label")]}
                            </InnerTitle>
                        </DataContainerInnerTitleContainer>
                        {/* data */}
                        <div
                            style={{
                                padding: '5px 15px 15px 15px'
                            }}
                        >
                            <DataView
                                key={deviceId}
                                dataSchema={attributeSchemas[attributeKey]}
                                mainKey={attributeKey}
                                sort={"ATTRIBUTE"}
                                data={attributeData[attributeKey]}
                                requestPath={`/booth/${boothId}/device/${deviceId}`}
                                onRefresh={fetchBoothData}
                            />
                        </div>
                    </DataContainerRounded>
                );
            }
        });
        return attributeElements;
    };

    if (isLoading) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '50%',
                    paddingTop: '10%'
                }}
            >
                <Spinner />
            </div>
        );
    } else if (boothSchema == null || _.isEqual(boothData, {})) { // server failed
        return (
            <div
                style={{
                    width: '100%',
                    paddingTop: '50px'
                }}
            >
                <ServerFailed />
            </div>
        );
    } else if (Object.keys(attributeSchemas).length === 0) {
        return (
            <span
                style={{
                    color: '#757575'
                }}
            >
                    {t("menu.boothControl.schema.attributes.noAttrMessage")}
            </span>
        );
    } else {
        return (
            <CardContainer>
                <Container fluid>
                    <Row>
                        <Col>
                            {renderAttributesOdd()}
                        </Col>
                        <Col>
                            {renderAttributesEven()}
                        </Col>
                    </Row>
                </Container>
            </CardContainer>
        );
    }
};

export default Attribute;