import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import UserService from "../../../../../services/UserService";
import axios from "axios";
import DataUtils from "../../../common/utils/DataUtils";
import DataCompareService from "../../../../../services/DataCompareService";
import _ from "lodash";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../../../../common/dataView/TitleAndInputBox";
import SimpleButton from "../../../common/buttons/SimpleButton";
import ButtonWithSpinner from "../../../common/buttons/ButtonWithSpinner";
import ConfirmModal from "../../../../common/confirm/ConfirmModal";
import LanguageInput from "../../../../../language/component/LanguageInput";
import {toast} from "react-toastify";
import ToastAlertView from "../../../../common/alert/ToastAlertView";
import DeleteConfirmModal from "../../../../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../../../../services/DeleteService";

const OptionCategoryContainer = styled.div`
  width: 100%;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
`;

const MessageNotExist = styled.span`
  font-size: 14px;
  color: #c2c2c2;
`;

const MessageExist = styled.span`
  font-size: 14px;
  color: #6bbbed;
`;

const OptionCategoryItemView = ({ optionCategoryInfo,
                                    updateRequestBody,
                                    updateIsValidMap,
                                    updateIsValid,
                                    updateIsEdited,
                                    requestBody,
                                    isValidMap,
                                    isValid,
                                    isEdited,
                                    refreshCategoryList,
                                    onDeleteCallback,
                                    currentLang,
                                    isHighlightOn }) => {

    const { t } = useTranslation();

    /* states */
    const [isLoading, setIsLoading] = useState(false);

    /* confirmation */
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    /* save */
    const putOptionCategory = async () => {
        try {
            await setIsLoading(true);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-option-category`
                + `/franchise/${optionCategoryInfo['franchiseMenuOptionCategoryFranchiseId'] ? optionCategoryInfo['franchiseMenuOptionCategoryFranchiseId'] : "default"}`
                + `/category/${optionCategoryInfo["franchiseMenuOptionCategoryId"]}`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            await setIsLoading(false);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            refreshCategoryList();
        } catch (e) {
            console.log(e);
            await setIsLoading(false);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    const handleRequestBodyInput = async (key, value) => {
        await updateRequestBody(key, value);
        let fixedRequestBody = {};
        fixedRequestBody["franchiseMenuOptionCategoryName"] = optionCategoryInfo["franchiseMenuOptionCategoryName"];
        fixedRequestBody["franchiseMenuOptionCategoryName1"] = optionCategoryInfo["franchiseMenuOptionCategoryName1"];
        fixedRequestBody["franchiseMenuOptionCategoryName2"] = optionCategoryInfo["franchiseMenuOptionCategoryName2"];
        fixedRequestBody["franchiseMenuOptionCategoryName3"] = optionCategoryInfo["franchiseMenuOptionCategoryName3"];
        fixedRequestBody["franchiseMenuOptionCategoryName4"] = optionCategoryInfo["franchiseMenuOptionCategoryName4"];
        fixedRequestBody["franchiseMenuOptionCategoryName5"] = optionCategoryInfo["franchiseMenuOptionCategoryName5"];
        updateIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    };

    const handleIsValidMapChange = async (key, value) => {
        await updateIsValidMap(key, value);
        updateIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    };

    return (
        <OptionCategoryContainer>
            <Container fluid>
                <Row>
                    <LanguageInput
                        type={"text"}
                        title={t("menu.franchiseMenu.optionCategoryEdit.detail.categoryName") + ` (${t("language.callName." + currentLang)})`}
                        dataKeyTranslationPath={"language.dataKey.menu.option.category.name"}
                        valueMap={requestBody}
                        updateValueMap={handleRequestBodyInput}
                        isValidMap={isValidMap}
                        updateIsValidMap={handleIsValidMapChange}
                        isForEdit={true}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        {/* menu count message */}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {
                                (optionCategoryInfo['optionCountInCategory'] > 0) ?
                                    <MessageExist>
                                        {
                                            optionCategoryInfo['optionCountInCategory']
                                            + t("menu.franchiseMenu.optionCategoryEdit.message.optionCount")
                                        }
                                    </MessageExist>
                                    :
                                    <MessageNotExist>
                                        {t("menu.franchiseMenu.optionCategoryEdit.message.noOption")}
                                    </MessageNotExist>
                            }
                        </div>
                        {/* delete button */}
                        <div
                            style={{
                                width: '100px',
                                marginLeft: 'auto'
                            }}
                        >
                            <SimpleButton
                                onClickCallback={() => setIsDeleteConfirmOpen(true)}
                                isEnabled={!(optionCategoryInfo['optionCountInCategory'] > 0)}
                                label={t("button.delete")}
                                borderColor={"#ff4d4d"}
                                buttonColor={"#ff4d4d"}
                                buttonHoverColor={"#ffcccc"}
                                buttonDisabledColor={"#ebebeb"}
                            />
                        </div>
                        {/* save button */}
                        <div
                            style={{
                                width: '100px',
                                marginLeft: '10px'
                            }}
                        >
                            {
                                isLoading ?
                                    <ButtonWithSpinner
                                        buttonColor={"#fc7242"}
                                    />
                                    :
                                    <SimpleButton
                                        onClickCallback={() => putOptionCategory()}
                                        isEnabled={isEdited && isValid}
                                        label={t("button.save")}
                                        borderColor={"#fc7242"}
                                        buttonColor={"#fc7242"}
                                        buttonHoverColor={"#fff1ec"}
                                        buttonDisabledColor={"#c2c2c2"}
                                    />
                            }
                        </div>
                    </div>
                </Row>
            </Container>
            {/* confirmation modal */}
            <DeleteConfirmModal
                isOpen={isDeleteConfirmOpen}
                setIsOpen={setIsDeleteConfirmOpen}
                isDelayed={false}
                confirmMessage={t("language.message.deletion.confirmation", { entityName: optionCategoryInfo[t("language.dataKey.menu.option.category.name")] })}
                entity={DeleteService.ValidEntities.MENU_OPTION_CATEGORY}
                entityName={optionCategoryInfo[t("language.dataKey.menu.option.category.name")]}
                id={`franchise-option-category`
                    + `/franchise/${optionCategoryInfo['franchiseMenuOptionCategoryFranchiseId'] ? optionCategoryInfo['franchiseMenuOptionCategoryFranchiseId'] : "default"}`
                    + `/category/${optionCategoryInfo["franchiseMenuOptionCategoryId"]}`}
                onDeleteSuccess={async () => await refreshCategoryList()}
            />
        </OptionCategoryContainer>
    );
};

export default OptionCategoryItemView;