import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import UserService from "../../../../services/UserService";
import axios from "axios";
import _ from "lodash";
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import {CiSquareCheck, CiSquareMinus, CiSquarePlus, CiSquareInfo} from "react-icons/ci";
import DataUtils from "../../common/utils/DataUtils";
import NotAllowed from "../../../../auth/NotAllowed";
import BackwardButton from "../../../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../../../common/layoutSub/PageTitleWithDepth";
import {Col, Container, Row, Tooltip} from "react-bootstrap";
import FranchiseNotExist from "./message/FranchiseNotExist";
import OverlayToolTip from "../../common/toolTip/OverlayToolTip";
import "./FranchiseMenuManagementStyle.css";
import RenderOnRole from "../../../../auth/RenderOnRole";

/* PATH : "/man-menu-franchise" */
const FranchiseMenuManagement = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    /* states */
    const [groupList, setGroupList] = useState([]); /* for group labeling */
    const [franchiseList, setFranchiseList] = useState([]);

    /* fetch group list */
    const fetchGroupList = async (successCallBack) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setGroupList(res.data.item.groupList);
            successCallBack(); /* supposed to call "fetchFranchiseList". */
        } catch (e) {
            console.log(e);
        }
    };

    /* fetch franchise list */
    const fetchFranchiseList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newFranchiseList = res.data.item.franchiseList;
            await setFranchiseList(newFranchiseList);
            /* default redirection */
            if ((newFranchiseList.length > 0) && (location.pathname.split('/').length < 3)) {
                let defaultGroupId = Object.keys(newFranchiseList[0])[0];
                let defaultFranchiseId = newFranchiseList[0][defaultGroupId][0]['franchiseId'];
                navigate(`/man-menu-franchise/franchise/${defaultFranchiseId}`);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* craft Accordion data from groupList and franchiseList */
    const craftAccordionData = () => {
        let newAccordionData = [];
        for (let i=0 ; i<groupList.length ; i++) {
            let newGroupData = {};
            newGroupData['groupInfo'] = groupList[i];
            newGroupData['franchiseList'] = [];
            franchiseList.map(groupFranchiseObj => {
                let tempList = Object.keys(groupFranchiseObj);
                let tempGroupId = tempList[0];
                if (tempGroupId === groupList[i]['groupId']) {
                    newGroupData['franchiseList'] = groupFranchiseObj[tempGroupId];
                }
            });
            newAccordionData.push(newGroupData);
        }
        /* add a default franchise */
        for (let i=0 ; i<newAccordionData.length ; i++) {
            /* default franchise must be in a master group */
            if (newAccordionData[i]['groupInfo']['masterGroup']) {
                let franchiseInfoList = [...newAccordionData[i]['franchiseList']];
                let defaultFranchiseInfo = {
                    description : "default franchise",
                    franchiseGroupId: newAccordionData[i]['groupInfo']['groupId'],
                    franchiseId : "default",
                    franchiseName : t("menu.franchise.detail.defaultFranchise"),
                    generateDate : new Date()
                };
                franchiseInfoList.unshift(defaultFranchiseInfo);
                newAccordionData[i]['franchiseList'] = franchiseInfoList;
            }
        }
        return newAccordionData;
    };

    /* refresh function */
    const refreshAll = async (redirectUrl) => {
        await fetchGroupList(fetchFranchiseList);
        if (!(typeof redirectUrl === "undefined") && !(typeof redirectUrl == null)) {
            navigate(redirectUrl);
        }
    };

    /* effect for initialization */
    useEffect(() => {
        fetchGroupList(fetchFranchiseList);
    }, []);

    /* render AccordionBody */
    const renderAccordionBody = (groupId, franchiseList) => {
        let franchiseElement = [];
        let franchiseListSorted = _.cloneDeep(franchiseList);
        franchiseListSorted.sort((ol, or) => {
            if (ol['franchiseId'] === "default") {
                return -1;
            } else if (or['franchiseId'] === "default") {
                return 1;
            } else {
                return DataUtils.objectSortHelper('franchiseName', ol, or);
            }
        });
        franchiseListSorted.map(franchiseInfo => {
            franchiseElement.push(
                <div
                    key={franchiseInfo['franchiseId']}
                    style={{
                        width: '100%',
                        marginBottom: '5px'
                    }}
                >
                    <button
                        className={"groupListButton" + (DataUtils.hasIdInPath(location.pathname, franchiseInfo['franchiseId']) ? "-selected" : "")}
                        onClick={() => navigate(`/man-menu-franchise/franchise/${franchiseInfo['franchiseId']}`)}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <div>
                                {franchiseInfo['franchiseName']}
                            </div>
                            {
                                (franchiseInfo['franchiseId'] === "default") &&
                                    <div
                                        style={{
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        <OverlayToolTip
                                            text={t("menu.franchiseMenu.message.notifyDefaultFranchise")}
                                            direction={"right"}
                                        >
                                            <span
                                                style={{
                                                    marginLeft: 'auto'
                                                }}
                                            >
                                                <CiSquareInfo
                                                    size={"25"}
                                                />
                                            </span>
                                        </OverlayToolTip>
                                    </div>
                            }
                        </div>
                    </button>
                </div>
            );
        });
        return franchiseElement;
    };

    /* render Accordion */
    const renderAccordion = () => {
        if ((groupList.length === 0) || (franchiseList.length === 0)) {
            return (<></>);
        } else {
            let totalFranchiseNum = 0;
            franchiseList.map(groupFranchise => {
                let tempGroupId = Object.keys(groupFranchise)[0];
                totalFranchiseNum += groupFranchise[tempGroupId].length;
            });
            let newAccordionData = craftAccordionData();
            return (
                <Accordion>
                    {/* per group */}
                    {
                        newAccordionData.map(groupData => {
                            if (groupData['franchiseList'].length === 0) {
                                return (<></>);
                            }
                            return (
                                <AccordionItem
                                    isActive={DataUtils.hasValuesInPath(location.pathname, DataUtils.extractIdsFromObjList("franchiseId", groupData['franchiseList']))}
                                    key={groupData['groupInfo']['groupId'] + totalFranchiseNum}
                                >
                                    {
                                        ({open}) => (
                                            <>
                                                <AccordionHeader
                                                    className={"groupAccordionHeaderBtn" + (open ? "-selected" : "")}
                                                    onClick={() => navigate(`/man-menu-franchise/franchise/${groupData['franchiseList'][0]['franchiseId']}`)}
                                                >
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px',
                                                            borderBottom: '1px solid #ebebeb',
                                                            paddingLeft: '5px',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            paddingRight: '5px',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: '14px',
                                                                marginLeft: '5px'
                                                            }}
                                                        >
                                                            {groupData['groupInfo']['groupName']}
                                                        </span>
                                                        <span
                                                            style={{
                                                                marginLeft: 'auto'
                                                            }}
                                                        >
                                                           {
                                                               open ?
                                                                   <CiSquareMinus
                                                                       size={"25"}
                                                                   />
                                                                   :
                                                                   <CiSquarePlus
                                                                       size={"25"}
                                                                   />
                                                           }
                                                       </span>
                                                    </div>
                                                </AccordionHeader>
                                                <AccordionBody>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            padding: '0px',
                                                            paddingTop: '5px'
                                                        }}
                                                    >
                                                        {renderAccordionBody(groupData['groupInfo']['groupId'], groupData['franchiseList'])}
                                                    </div>
                                                </AccordionBody>
                                            </>
                                        )
                                    }
                                </AccordionItem>
                            );
                        })
                    }
                </Accordion>
            );
        }
    };


    return (
        <RenderOnRole roleName={""} type={""} allowedRing={1} isForSuperMaster={false}>
            <div className={"headingTitle"}>
                <BackwardButton isShow={false} />
                <PageTitleWithDepth
                    titles={[ t("menu.franchiseMenu.title") ]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <Container fluid>
                    <Row>
                        {/* franchise accordion */}
                        <Col
                            md={"2"}
                            style={{
                                padding: '0px'
                            }}
                        >
                            <div
                                className={"contentInner"}
                                style={{
                                    height: '797px',
                                    overflow: 'auto'
                                }}
                            >
                                <div>
                                    {
                                        (groupList.length > 0) && (franchiseList.length > 0) &&
                                        renderAccordion()
                                    }
                                    {
                                        ((groupList.length === 0) || (franchiseList.length === 0)) &&
                                        <FranchiseNotExist />
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col
                            md={"10"}
                            style={{
                                padding: '0px',
                                paddingLeft: '10px'
                            }}
                        >
                            {/* outlet area */}
                            <div
                                style={{
                                    width: '100%',
                                    padding: '0px'
                                }}
                            >
                                <Outlet
                                    context={{
                                        refreshAll
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </RenderOnRole>
    );
};

export default FranchiseMenuManagement;