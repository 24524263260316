import React from 'react';
import styled from "styled-components";

const TextInput = styled.input`
  width: 100%;
  border: 1px solid #e4e7ea;
  border-radius: 5px;
  padding: 7px 10px;
  color: #212529;
  font-size: 15px;
`;

const StringField = ({ fieldKey, value, isValid, updateValue, isForEdit }) => {
    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* input box */}
            <div
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                <TextInput
                    type={"text"}
                    readOnly={!isForEdit}
                    value={value}
                    onChange={(e) => updateValue(fieldKey, e.target.value)}
                    style={
                        isValid ?
                            {}
                            :
                            {
                                borderColor : 'red'
                            }
                    }
                />
            </div>
        </div>
    );
};

export default StringField;