import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../../common/modal/ModalCloseButton";
import DataUtils from "../../../menu/common/utils/DataUtils";
import DataCompareService from "../../../../services/DataCompareService";
import styled from "styled-components";
import DateUtils from "../../utils/DateUtils";
import TitleAndInputBox from "../../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../../common/dataView/TitleAndTextArea";
import TitleAndSelectBox from "../../../common/dataView/TitleAndSelectBox";
import axios from "axios";
import UserService from "../../../../services/UserService";
import {toast} from "react-toastify";
import ToastAlertView from "../../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '490px',
        margin: 'auto'
    }
};

const ScheduleBlockTitle = styled.div`
  width: 100%;
  padding-left: 5px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.color ? props.color : "#757575"};
  font-size: 14px;
`;

const SaveButton = styled.button`
  font-size: 14px;
  padding: 7px;
  width: 100%;
  border: 1px solid ${props => props.enabled ? "#fc7242" : "#c2c2c2"};
  color: ${props => props.enabled ? "#fc7242" : "#c2c2c2"};
  border-radius: 5px;
  background: none;
  
  &:hover {
    background-color: ${props => props.enabled ? "#fcf9ed" : "white"};
  }
  
  &:focus {
    outline: none;
  }
`;

const requestBodyInit = {
    "scheduleTitle" : "",
    "scheduleContent" : "",
    "scheduleStatusCode" : 1,
    "scheduleDate" : ""
};

const isValidMapInit = {
    "scheduleTitle" : false,
    "scheduleContent" : true,
    "scheduleStatusCode" : true,
    "scheduleDate" : true
};

const ScheduleAddModal = ({ isOpen, setIsOpen, dateStr, onRefresh }) => {

    const { t, i18n } = useTranslation();

    /* states */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);

    const statusDataList = [
        {
            "label" : t("language.enums.scheduleStatus.0"),
            "value" : 0
        },
        {
            "label" : t("language.enums.scheduleStatus.1"),
            "value" : 1
        },
        {
            "label" : t("language.enums.scheduleStatus.2"),
            "value" : 2
        }
    ];

    const addSchedule = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/schedule`;
            const axiosCall = () => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            let newRequestBodyInit = {...requestBodyInit};
            newRequestBodyInit['scheduleDate'] = dateStr;
            await setRequestBody(newRequestBodyInit);
            await setIsValidMap(isValidMapInit);
            await onRefresh();
            await setIsOpen(false);
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* handlers */
    const handleTitleInput = async (value) => {
        await DataUtils.updateHelper(setRequestBody, "scheduleTitle", value);
        await DataUtils.updateHelper(setIsValidMap, "scheduleTitle", !DataUtils.isEmptyStr(value));
    };

    const handleContentInput = async (value) => {
        await DataUtils.updateHelper(setRequestBody, "scheduleContent", value);
    };

    const handleStatusChange = async (value) => {
        await DataUtils.updateHelper(setRequestBody, "scheduleStatusCode", parseInt(value));
    };

    /* effects */
    useEffect(() => {
        /* requestBody initialization */
        let newRequestBodyInit = {...requestBodyInit};
        newRequestBodyInit['scheduleDate'] = dateStr;
        setRequestBody(newRequestBodyInit);
        setIsValidMap(isValidMapInit);
    }, [dateStr]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.schedule.button.add")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <ScheduleBlockTitle>
                        {DateUtils.craftLocaleString(dateStr, i18n.language)}
                    </ScheduleBlockTitle>
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* title */}
                    <TitleAndInputBox
                        title={t("menu.schedule.fields.title")}
                        value={requestBody['scheduleTitle']}
                        onChange={handleTitleInput}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap['scheduleTitle']}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* content */}
                    <TitleAndTextArea
                        title={t("menu.schedule.fields.content")}
                        value={requestBody['scheduleContent']}
                        onChange={handleContentInput}
                        isForEdit={true}
                        height={"120"}
                        isValid={isValidMap['scheduleContent']}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* status */}
                    <TitleAndSelectBox
                        title={t("menu.schedule.fields.status")}
                        innerValue={requestBody['scheduleStatusCode']}
                        valueList={statusDataList}
                        valueSelector={"value"}
                        viewSelector={"label"}
                        onChange={handleStatusChange}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '25px'
                    }}
                >
                    {/* save button */}
                    <SaveButton
                        enabled={isValid}
                        onClick={() => {
                            if (isValid) {
                                addSchedule();
                            }
                        }}
                    >
                        {t("button.save")}
                    </SaveButton>
                </Row>
            </Container>
        </Modal>
    );
};

export default ScheduleAddModal;