import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import _ from "lodash";
import styled from "styled-components";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import TitleAndSwitchBox from "../../../../../../common/dataView/TitleAndSwitchBox";
import DataUtils from "../../../../../common/utils/DataUtils";
import DataCompareService from "../../../../../../../services/DataCompareService";
import TitleAndInputBox from "../../../../../../common/dataView/TitleAndInputBox";
import ExtraInformationView from "../../../../../common/extrainfo/ExtraInformationView";
import SimpleButton from "../../../../../common/buttons/SimpleButton";
import ButtonWithSpinner from "../../../../../common/buttons/ButtonWithSpinner";
import OrangeButton from "../../../../../common/buttons/OrangeButton";
import UserService from "../../../../../../../services/UserService";
import axios from "axios";
import ConfirmModal from "../../../../../../common/confirm/ConfirmModal";
import LanguageInput from "../../../../../../../language/component/LanguageInput";
import { BsArrowUpSquare, BsArrowDownSquare } from "react-icons/bs";
import {toast} from "react-toastify";
import ToastAlertView from "../../../../../../common/alert/ToastAlertView";
import DeleteConfirmModal from "../../../../../../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../../../../../../services/DeleteService";

const requestBodyInit = {
    franchiseMenuOptionPrice : 0.0,
    franchiseMenuOptionAlias : "",
    franchiseMenuOptionAlias1 : null,
    franchiseMenuOptionAlias2 : null,
    franchiseMenuOptionAlias3 : null,
    franchiseMenuOptionAlias4 : null,
    franchiseMenuOptionAlias5 : null,
    extraInformationList : []
};

const isValidMapInit = {
    franchiseMenuOptionPrice : true,
    franchiseMenuOptionAlias : true,
    franchiseMenuOptionAlias1 : true,
    franchiseMenuOptionAlias2 : true,
    franchiseMenuOptionAlias3 : true,
    franchiseMenuOptionAlias4 : true,
    franchiseMenuOptionAlias5 : true,
    extraInformationList : true
};

const OptionItemContainer = styled.div`
  width: 100%;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(117,117,117,1);
  -moz-box-shadow: 0px 0px 4px 0px rgba(117,117,117,1);
  box-shadow: 0px 0px 4px 0px rgba(117,117,117,1);
`;

const ArrowButton = styled.button`
  background: none;
  color: ${(props) => props.enabled ? "#fc7242" : "#ebebeb"};
  border: none;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: ${(props) => props.enabled ? "red" : "#ebebeb"};
  }
`;

const OptionInfoItem = ({ optionInfo, refreshOptionList, length, index, currentLang, isHighlightOn }) => {

    const { t } = useTranslation();
    const { franchiseId, menuId } = useParams();

    /* states */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);

    /* loading */
    const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);

    /* confirmation */
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    /* put option info */
    const putOptionInfo = async () => {
        await setIsSaveButtonLoading(true);
        /* extra information setup */
        let newRequestBody = _.cloneDeep(requestBody);
        if (newRequestBody['extraInformationList'].length > 0) {
            newRequestBody['extraInformation'] = DataUtils.formatObjArrayToObj(newRequestBody['extraInformationList']);
            delete newRequestBody['extraInformationList'];
        } else {
            delete newRequestBody['extraInformationList'];
        }
        try {
            /* axios call */
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/`
                + `franchise-menu/franchise/${franchiseId}/menu/${menuId}/option/${optionInfo['franchiseMenuOptionId']}`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await refreshOptionList();
            setIsEdited(false);
            setFixedRequestBody(_.cloneDeep(requestBody));
            setIsSaveButtonLoading(false);
        } catch (e) {
            console.log(e);
            setIsSaveButtonLoading(false);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* down or up viewIndex */
    const downOrUpViewIndex = async (type) => {
        await setIsSaveButtonLoading(true);
        try {
            /* axios call */
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/`
                + `franchise-menu/franchise/${franchiseId}/menu/${menuId}/option/${optionInfo['franchiseMenuOptionId']}/`
                + `view-index/${type}`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            await refreshOptionList();
            setIsSaveButtonLoading(false);
        } catch (e) {
            console.log(e);
            setIsSaveButtonLoading(false);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* reset */
    const resetData = async () => {
        await setIsValidMap(isValidMapInit);
        await setIsValid(true);
        await setIsEdited(false);
        setRequestBody(_.cloneDeep(fixedRequestBody));
    };

    /* for extra information : object to array */
    const objectToArray = (object) => {
        let newArray = [];
        Object.keys(object).map(objectKey => {
            let newElement = {};
            newElement[objectKey] = object[objectKey];
            newArray.push(newElement);
        });
        return newArray;
    };

    /* input handlers */
    const handleOptionPriceInput = (value) => {
        DataUtils.updateHelper(setRequestBody, "franchiseMenuOptionPrice", parseFloat(value));
        DataUtils.updateHelper(setIsValidMap, "franchiseMenuOptionPrice", DataUtils.isValidNumStr(value));
    };

    const handleExtraInfoChange = (newObjArray) => {
        DataUtils.updateHelper(setRequestBody, "extraInformationList", newObjArray);
    };

    const initialize = () => {
        let newRequestBody = {};
        newRequestBody['franchiseMenuOptionPrice'] = optionInfo['franchiseMenuOptionPrice'];
        newRequestBody['franchiseMenuOptionAlias'] = optionInfo['franchiseMenuOptionAlias'];
        newRequestBody['franchiseMenuOptionAlias1'] = optionInfo['franchiseMenuOptionAlias1'];
        newRequestBody['franchiseMenuOptionAlias2'] = optionInfo['franchiseMenuOptionAlias2'];
        newRequestBody['franchiseMenuOptionAlias3'] = optionInfo['franchiseMenuOptionAlias3'];
        newRequestBody['franchiseMenuOptionAlias4'] = optionInfo['franchiseMenuOptionAlias4'];
        newRequestBody['franchiseMenuOptionAlias5'] = optionInfo['franchiseMenuOptionAlias5'];
        /* craft extra information */
        newRequestBody["extraInformationList"] = optionInfo["extraInformation"] ? objectToArray(optionInfo["extraInformation"]) : [];
        /* set request body */
        setRequestBody(newRequestBody);
        setFixedRequestBody(_.cloneDeep(newRequestBody));
        setIsValidMap(isValidMapInit);
        setIsValid(true);
        setIsEdited(false);
    };

    /* track isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    /* track isEdited */
    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);

    /* effects */
    useEffect(() => {
        initialize(optionInfo);
    }, [optionInfo]);

    return (
        <OptionItemContainer>
            <Container fluid>
                <Row>
                    <Col
                        md={"2"}
                    >
                        {/* index modification */}
                        {
                            isSaveButtonLoading ?
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Spinner
                                        size={"sm"}
                                        style={{
                                            color: '#fc7242'
                                        }}
                                    />
                                </div>
                                :
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start'
                                    }}
                                >
                                    {/* up button */}
                                    <ArrowButton
                                        enabled={(index !== 0)}
                                        onClick={() => downOrUpViewIndex("up")}
                                        style={{
                                            marginBottom: '10px'
                                        }}
                                    >
                                        <BsArrowUpSquare
                                            size={"30"}
                                        />
                                    </ArrowButton>
                                    {/* down button */}
                                    <ArrowButton
                                        enabled={(index !== (length - 1))}
                                        onClick={() => downOrUpViewIndex("down")}
                                    >
                                        <BsArrowDownSquare
                                            size={"30"}
                                        />
                                    </ArrowButton>
                                </div>
                        }
                    </Col>
                    <Col
                        md={"10"}
                    >
                        {/* main */}
                        <Row
                            style={{
                                marginTop: '5px'
                            }}
                        >
                            <LanguageInput
                                type={"text"}
                                title={t("menu.franchiseMenu.menuInfo.optionInfo.detail.alias") + ` (${t("language.callName." + currentLang)})`}
                                dataKeyTranslationPath={"language.dataKey.menu.option.name"}
                                valueMap={requestBody}
                                updateValueMap={(key, value) => DataUtils.updateHelper(setRequestBody, key, value)}
                                isValidMap={isValidMap}
                                updateIsValidMap={(key, value) => DataUtils.updateHelper(setIsValidMap, key, value)}
                                isForEdit={true}
                                currentLang={currentLang}
                                isHighlightOn={isHighlightOn}
                            />
                        </Row>
                        <Row>
                            <TitleAndInputBox
                                title={t("menu.franchiseMenu.menuInfo.optionInfo.detail.optionPrice")}
                                value={requestBody["franchiseMenuOptionPrice"]}
                                onChange={handleOptionPriceInput}
                                isForEdit={true}
                                type={"number"}
                                isValid={isValidMap["franchiseMenuOptionPrice"]}
                                isEnabled={true}
                            />
                        </Row>
                        <Row
                            style={{
                                marginTop: '5px'
                            }}
                        >
                            <ExtraInformationView
                                dataObjList={requestBody['extraInformationList']}
                                setDataObjList={handleExtraInfoChange}
                                setIsValidMap={setIsValidMap}
                                objKey={"extraInformationList"}
                                title={t("menu.franchiseMenu.menuInfo.optionInfo.detail.extraInfo.title")}
                                height={"120"}
                                isForEdit={true}
                            />
                        </Row>
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                {/* delete button */}
                                <div
                                    style={{
                                        width: '100px'
                                    }}
                                >
                                    <SimpleButton
                                        onClickCallback={() => setIsDeleteConfirmOpen(true)}
                                        isEnabled={true}
                                        label={t("button.delete")}
                                        borderColor={"#ff4d4d"}
                                        buttonColor={"#ff4d4d"}
                                        buttonHoverColor={"#ffcccc"}
                                        buttonDisabledColor={"#ebebeb"}
                                    />
                                </div>
                                <div
                                    style={{
                                        width: '100px',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    <SimpleButton
                                        onClickCallback={resetData}
                                        isEnabled={isEdited}
                                        label={t("menu.franchiseMenu.menuInfo.button.reset")}
                                        borderColor={"#999999"}
                                        buttonColor={"#999999"}
                                        buttonHoverColor={"#ececec"}
                                        buttonDisabledColor={"#c2c2c2"}
                                    />
                                </div>
                                <div
                                    style={{
                                        width: '100px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    {
                                        isSaveButtonLoading ?
                                            <ButtonWithSpinner
                                                buttonColor={"#fc7242"}
                                            />
                                            :
                                            <OrangeButton
                                                onClickCallback={() => putOptionInfo()}
                                                isEnabled={isValid && isEdited}
                                                label={t("button.save")}
                                            />
                                    }
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Container>
            {/* delete confirmation */}
            <DeleteConfirmModal
                isOpen={isDeleteConfirmOpen}
                setIsOpen={setIsDeleteConfirmOpen}
                isDelayed={false}
                confirmMessage={t("language.message.deletion.confirmation", { entityName : optionInfo[t("language.dataKey.menu.option.name")] })}
                entity={DeleteService.ValidEntities.MENU_OPTION}
                entityName={optionInfo[t("language.dataKey.menu.option.name")]}
                id={`franchise-menu/franchise/${franchiseId}/menu/${menuId}/option/${optionInfo['franchiseMenuOptionId']}`}
                onDeleteSuccess={async () => await refreshOptionList()}
            />
        </OptionItemContainer>
    );
};

export default OptionInfoItem;