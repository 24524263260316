import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import OverlayToolTip from "../toolTip/OverlayToolTip";
import ConfirmModal from "../../../common/confirm/ConfirmModal";

const Title = styled.span`
  color: ${(props) => props.disabled ? '#8f8f8f' : '#c2c2c2'};
  font-size: 14px;
  margin-left: 5px;
`;

const InputDataView = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 10px;
  transition: all ease-in-out 0.2s;
  font-size: 13px;
  
  &:focus {
    border-color: #fc7242;
  }
`;

const InputDataViewDisabled = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: #f5f5f5;
  padding: 10px;
  transition: all ease-in-out 0.2s;
  font-size: 13px;
`;

const LockedTitleAndInput = ({ isLocked,
                                 lockReleaseConfirmMessage,
                                 onLockRelease,
                                 lockHoldConfirmMessage,
                                 onLockHold,
                                 checkBoxHoverMessageOnHold,
                                 checkBoxHoverMessageOnRelease,
                                 checkBoxCondition,
                                 isLockEditable,
                                 title,
                                 value,
                                 onChange,
                                 isForEdit,
                                 type,
                                 isValid }) => {

    const { t } = useTranslation();

    /* states */
    const [isReleaseConfirmOpen, setIsReleaseConfirmOpen] = useState(false);
    const [isHoldConfirmOpen, setIsHoldConfirmOpen] = useState(false);

    const handleCheckBoxClick = (value) => {
        if (!isLockEditable) {
            return;
        } else {
            if (isLocked) {
                /* prev : true */
                if (value) {
                    /* not changed */
                    return;
                } else {
                    /* changed */
                    setIsReleaseConfirmOpen(true);
                }
            } else {
                /* prev : false */
                if (!value) {
                    /* not changed */
                    return;
                } else {
                    /* changed */
                    setIsHoldConfirmOpen(true);
                }
            }
        }
    };

    const handleInput = (value) => {
        if (isLocked) {
            return;
        } else {
            onChange(value);
        }
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* title and check box */}
                <div
                    style={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: '5px',
                        marginBottom: '5px'
                    }}
                >
                    {/* check box */}
                    <OverlayToolTip
                        text={
                            isLocked ?
                                checkBoxHoverMessageOnHold
                                :
                                checkBoxHoverMessageOnRelease
                        }
                        direction={"top"}
                    >
                        <input
                            type={"checkbox"}
                            checked={
                                (checkBoxCondition === "hold") ?
                                    isLocked
                                    :
                                    !isLocked
                            }
                            onChange={(e) => handleCheckBoxClick(e.target.value)}
                        />
                    </OverlayToolTip>
                    {/* title */}
                    <Title
                        disabled={
                            (checkBoxCondition === "hold") ?
                                isLocked
                                :
                                !isLocked
                        }
                    >
                        {title}
                    </Title>
                </div>
                {/* input box */}
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    {
                        isLocked ?
                            <InputDataViewDisabled
                                type={type}
                                readOnly={true}
                                value={value}
                            />
                            :
                            <InputDataView
                                type={type}
                                readOnly={!isForEdit}
                                value={value}
                                onChange={(e) => handleInput(e.target.value)}
                                style={isValid ? {} : { borderColor: 'red' }}
                            />
                    }
                </div>
            </div>
            {/* confirmation */}
            <ConfirmModal
                isOpen={isHoldConfirmOpen}
                setIsOpen={setIsHoldConfirmOpen}
                title={lockHoldConfirmMessage}
                confirmLabel={t("button.yes")}
                rejectLabel={t("button.no")}
                onConfirm={onLockHold}
                onReject={() => {}}
            />
            <ConfirmModal
                isOpen={isReleaseConfirmOpen}
                setIsOpen={setIsReleaseConfirmOpen}
                title={lockReleaseConfirmMessage}
                confirmLabel={t("button.yes")}
                rejectLabel={t("button.no")}
                onConfirm={onLockRelease}
                onReject={() => {}}
            />
        </div>
    );
};

export default LockedTitleAndInput;