import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import UserService from "../../../../services/UserService";
import useInterval from "../../../../customHooks/useInterval";
import Modal from "react-modal";
import _, {isEmpty, isEqual, xorWith} from "lodash";
import {Col, Spinner, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import styled from "styled-components";
import SchemaParser from "../utils/SchemaParser";
import CommonUtil from "../utils/CommonUtil";
import DataParseService from "../../../../services/DataParseService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


const OnGoing = styled.button`
  border: none;
  color: #969696;
  background-color: #e1e1e1;
  width: 100%;
  margin-bottom: 5px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;

  &:hover {
    background-color: #d2d2d2;
  }

  &:focus {
    outline: none;
  }
`;

const Success = styled.button`
  border: none;
  color: #039be5;
  background-color: #e1f5fe;
  width: 100%;
  margin-bottom: 5px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #b3e5fc;
  }
  
  &:focus {
    outline: none;
  }
`;

const Fail = styled.button`
  border: none;
  color: #ff4f4b;
  background-color: #ffcccb;
  width: 100%;
  margin-bottom: 5px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;

  &:hover {
    background-color: #ffb3be;
  }

  &:focus {
    outline: none;
  }
`;

/* IMPORTANT : isView is VERY IMPORTANT for updating */
const BoothOperationView = ({ boothId, isBoothOnline, onNote, isView, badgeNum, unSeenCount, setUnSeenCount }) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    /* json schema */
    let jsonSchema = JSON.parse(window.localStorage.getItem("ims_json_schema"));

    /* ------------------------------------ */
    /* -------------- STATES -------------- */

    /* boothOperationList */
    const [boothTempOperationList, setBoothTempOperationList] = useState([]);
    const [boothOperationList, setBoothOperationList] = useState([]);

    /* loading */
    const [isLoading, setIsLoading] = useState(true);

    /* failed */
    const [loadFailed, setLoadFailed] = useState(false);

    /* last update */
    const [lastUpdateAt, setLastUpdateAt] = useState("");

    /* ------------------------------------ */
    /* ------------------------------------ */

    /* utils */
    const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));
    const getChangedItemNum = (x, y) => (xorWith(x, y, isEqual).length);
    const getDateTime = () => {
        let now     = new Date();
        let year    = now.getFullYear();
        let month   = now.getMonth()+1;
        let day     = now.getDate();
        let hour    = now.getHours();
        let minute  = now.getMinutes();
        let second  = now.getSeconds();
        if(month.toString().length == 1) {
            month = '0'+month;
        }
        if(day.toString().length == 1) {
            day = '0'+day;
        }
        if(hour.toString().length == 1) {
            hour = '0'+hour;
        }
        if(minute.toString().length == 1) {
            minute = '0'+minute;
        }
        if(second.toString().length == 1) {
            second = '0'+second;
        }
        let dateTime = year+'/'+month+'/'+day+' '+hour+':'+minute+':'+second;
        return dateTime;
    };

    const getDeviceNameById = (id) => {
        let targetDevice = CommonUtil.getObjectInListById(id, jsonSchema['spec']['devices']);
        return targetDevice['metadata'][t("language.dataKey.boothControl.json.label")];
    };

    const getSensorNameByIds = (deviceId, sensorId) => {
        let targetDevice = CommonUtil.getObjectInListById(deviceId, jsonSchema['spec']['devices']);
        let targetSensor = CommonUtil.getObjectInListById(sensorId, targetDevice['spec']['sensors']);
        return targetSensor['metadata'][t("language.dataKey.boothControl.json.label")];
    };

    /* fetch data */
    const fetchBoothOperationData = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/booth-operation/booth/${boothId}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            /* isLoading */
            setIsLoading(false);
            setLoadFailed(false);
            /* update datetime */
            setLastUpdateAt(getDateTime());
            /* update data */
            if (res.data['error_code'] === "GET_EMPTY_DATA") {
                setBoothTempOperationList([]);
            } else {
                setBoothTempOperationList(res.data.item.boothOperationList);
            }
        } catch (e) {
            setIsLoading(false);
            setLoadFailed(true);
            setBoothTempOperationList([]);
        }
    };

    /* TIMER : fetch data */
    useInterval(() => {
        if (isBoothOnline) {
            fetchBoothOperationData();
        }
    }, 1500);

    useEffect(() => {
        fetchBoothOperationData();
    }, []);

    /* effects */
    /* comparing */
    useEffect(() => {
        /* check if changes */
        let isChanged = !isArrayEqual(boothOperationList, boothTempOperationList);
        if (isChanged) {
            /* isLoading */
            setIsLoading(true);
            let changeNum = getChangedItemNum(boothOperationList, boothTempOperationList);
            setBoothOperationList(_.cloneDeep(boothTempOperationList));
            if (!isView) {
                setUnSeenCount(prevValue => {
                   return changeNum + prevValue;
                });
                onNote(changeNum + unSeenCount);
            } else {
                setUnSeenCount(0);
                onNote(0);
            }
            /* isLoading */
            setIsLoading(false);
        }
    }, [boothTempOperationList]);


    /* renderers */
    const renderInner = () => {
        if (isLoading) {
            return (
                <div
                    style={{
                        width: '100%',
                        paddingTop: '100px'
                    }}
                >
                    <Spinner />
                </div>
            );
        } else if (loadFailed) {
            return (
                <div
                    style={{
                        width: '100%',
                        paddingTop: '100px'
                    }}
                >
                    <span
                        style={{
                            color: '#757575',
                            fontSize: '15px'
                        }}
                    >
                        {t("menu.boothOperation.message.failedToLoad")}
                    </span>
                </div>
            );
        } else if (boothOperationList.length === 0) {
            return (
                <div
                    style={{
                        width: '100%',
                        paddingTop: '100px'
                    }}
                >
                    <span
                        style={{
                            color: '#757575',
                            fontSize: '15px'
                        }}
                    >
                        {t("menu.boothOperation.message.noCommands")}
                    </span>
                </div>
            );
        } else {
            let boothOpElements = [];
            boothOperationList.map(boothOperation => {
                let boothOperationRequestBody = JSON.parse(boothOperation['requestBody']); /* for now, unused */
                let boothOperationPathList = boothOperation['resourcePath'].split("/");
                let targetDeviceId = boothOperationPathList[4];
                let targetDeviceName = getDeviceNameById(targetDeviceId);
                let targetSensorId = "";
                let targetSensorName = "";
                let isSensorDepth = boothOperationPathList.includes('sensor');
                let isAttributeDepth = boothOperationPathList.includes('attribute');
                if (isSensorDepth) { /* at sensor depth */
                    targetSensorId = boothOperationPathList[6];
                    targetSensorName = getSensorNameByIds(targetDeviceId, targetSensorId);
                }
                if (!boothOperation['done']) {
                    /* on going */
                    boothOpElements.push(
                        <OnGoing
                            key={boothOperation['boothOperationId']}
                            onClick={() => {
                                if (isBoothOnline) {
                                    if (isSensorDepth) {
                                        navigate(`/booth/detail/${boothId}/device/${targetDeviceId}/sensors/${targetSensorId}`);
                                    } else if (isAttributeDepth) {
                                        navigate(`/booth/detail/${boothId}/device/${targetDeviceId}/attributes`);
                                    } else {
                                        navigate(`/booth/detail/${boothId}/device/${targetDeviceId}/status`);
                                    }
                                } else {
                                    return;
                                }
                            }}
                        >
                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                {/* time */}
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        fontSize: '12px'
                                    }}
                                >
                                    <span
                                        style={{
                                            color: '#878787'
                                        }}
                                    >
                                        {
                                            DataParseService.dateTimeFormat(boothOperation['generatedAt'])
                                        }
                                    </span>
                                </div>
                                {/* status */}
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        color: '#464646',
                                        position: 'relative'
                                    }}
                                >
                                    <span
                                        style={{
                                            marginRight: '15px'
                                        }}
                                    >
                                        {
                                            t("menu.boothOperation.status.ongoing")
                                        }
                                    </span>
                                    <Spinner
                                        size={"sm"}
                                        style={{
                                            position: 'absolute',
                                            top: '2px'
                                        }}
                                    />
                                </div>
                                {/* type */}
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        fontWeight: '500'
                                    }}
                                >
                                    <span>
                                        {
                                            boothOperation['methodType'] === "POST" ?
                                                t("menu.boothOperation.type.post") + " : "
                                                :
                                                t("menu.boothOperation.type.put") + " : "
                                        }
                                    </span>
                                    {/* device */}
                                    <span
                                        style={{
                                            color: '#7d7d7d'
                                        }}
                                    >
                                            {targetDeviceName}
                                    </span>
                                    {/* sensor */}
                                    {
                                        isSensorDepth ?
                                            <span
                                                style={{
                                                    color: '#7d7d7d'
                                                }}
                                            >
                                                {" / " + targetSensorName}
                                            </span>
                                            :
                                            <></>
                                    }
                                    {/* attribute */}
                                    {
                                        isAttributeDepth ?
                                            <span
                                                style={{
                                                    color: '#7d7d7d'
                                                }}
                                            >
                                                {" / " + t("menu.boothControl.device.attributes")}
                                            </span>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </OnGoing>
                    );
                } else {
                    const renderReason = () => {
                        return (
                            <Tooltip id="tooltip">
                                <div>
                                    <span
                                        style={{
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {t("menu.boothOperation.failedReason")}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        {boothOperation['failedReason']}
                                    </span>
                                </div>
                            </Tooltip>
                        );
                    };
                    if (boothOperation['failed']) {
                        /* failed */
                        boothOpElements.push(
                            <OverlayTrigger
                                key={boothOperation['boothOperationId']}
                                placement={"left"}
                                overlay={renderReason()}
                            >
                                <Fail
                                    onClick={() => {
                                        if (isBoothOnline) {
                                            if (isSensorDepth) {
                                                navigate(`/booth/detail/${boothId}/device/${targetDeviceId}/sensors/${targetSensorId}`);
                                            } else if (isAttributeDepth) {
                                                navigate(`/booth/detail/${boothId}/device/${targetDeviceId}/attributes`);
                                            } else {
                                                navigate(`/booth/detail/${boothId}/device/${targetDeviceId}/status`);
                                            }
                                        } else {
                                            return;
                                        }
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        {/* time */}
                                        <div
                                            style={{
                                                width: '100%',
                                                textAlign: 'left',
                                                fontSize: '12px'
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: '#757575'
                                                }}
                                            >
                                                {
                                                    DataParseService.dateTimeFormat(boothOperation['generatedAt'])
                                                }
                                            </span>
                                        </div>
                                        {/* status */}
                                        <div
                                            style={{
                                                width: '100%',
                                                textAlign: 'left',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                color: '#ff2800'
                                            }}
                                        >
                                            <span>
                                                {
                                                    t("menu.boothOperation.status.failed")
                                                }
                                            </span>
                                        </div>
                                        {/* type */}
                                        <div
                                            style={{
                                                width: '100%',
                                                textAlign: 'left',
                                                fontSize: '12px',
                                                fontWeight: '500'
                                            }}
                                        >
                                            <span>
                                                {
                                                    boothOperation['methodType'] === "POST" ?
                                                        t("menu.boothOperation.type.post") + " : "
                                                        :
                                                        t("menu.boothOperation.type.put") + " : "
                                                }
                                            </span>
                                            {/* device */}
                                            <span
                                                style={{
                                                    color: '#8d021f'
                                                }}
                                            >
                                                {targetDeviceName}
                                            </span>
                                            {/* sensor */}
                                            {
                                                isSensorDepth ?
                                                    <span
                                                        style={{
                                                            color: '#8d021f'
                                                        }}
                                                    >
                                                        {" / " + targetSensorName}
                                                    </span>
                                                    :
                                                    <></>
                                            }
                                            {/* attribute */}
                                            {
                                                isAttributeDepth ?
                                                    <span
                                                        style={{
                                                            color: '#8d021f'
                                                        }}
                                                    >
                                                        {" / " + t("menu.boothControl.device.attributes")}
                                                    </span>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                </Fail>
                            </OverlayTrigger>
                        );
                    } else {
                        /* success */
                        boothOpElements.push(
                            <Success
                                key={boothOperation['boothOperationId']}
                                onClick={() => {
                                    if (isBoothOnline) {
                                        if (isSensorDepth) {
                                            navigate(`/booth/detail/${boothId}/device/${targetDeviceId}/sensors/${targetSensorId}`);
                                        } else if (isAttributeDepth) {
                                            navigate(`/booth/detail/${boothId}/device/${targetDeviceId}/attributes`);
                                        } else {
                                            navigate(`/booth/detail/${boothId}/device/${targetDeviceId}/status`);
                                        }
                                    } else {
                                        return;
                                    }
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%'
                                    }}
                                >
                                    {/* time */}
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                            fontSize: '12px'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: '#757575'
                                            }}
                                        >
                                            {
                                                DataParseService.dateTimeFormat(boothOperation['generatedAt'])
                                            }
                                        </span>
                                    </div>
                                    {/* status */}
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            color: '#01579B'
                                        }}
                                    >
                                        <span>
                                            {
                                                t("menu.boothOperation.status.success")
                                            }
                                        </span>
                                    </div>
                                    {/* type */}
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                            fontSize: '12px',
                                            fontWeight: '500'
                                        }}
                                    >
                                        <span>
                                            {
                                                boothOperation['methodType'] === "POST" ?
                                                    t("menu.boothOperation.type.post") + " : "
                                                    :
                                                    t("menu.boothOperation.type.put") + " : "
                                            }
                                        </span>
                                        {/* device */}
                                        <span
                                            style={{
                                                color: '#0288d1'
                                            }}
                                        >
                                            {targetDeviceName}
                                        </span>
                                        {/* sensor */}
                                        {
                                            isSensorDepth ?
                                                <span
                                                    style={{
                                                        color: '#0288d1'
                                                    }}
                                                >
                                                    {" / " + targetSensorName}
                                                </span>
                                                :
                                                <></>
                                        }
                                        {/* attribute */}
                                        {
                                            isAttributeDepth ?
                                                <span
                                                    style={{
                                                        color: '#0288d1'
                                                    }}
                                                >
                                                    {" / " + t("menu.boothControl.device.attributes")}
                                                </span>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </Success>
                        );
                    }
                }
            });
            return boothOpElements;
        }
    };


    if (!isView) {
        return (<></>);
    } else {
        return (
            <Col
                md={"2"}
                style={{
                    padding: '0px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '850px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'left'
                            }}
                        >
                            <span
                                style={{
                                    color: '#757575',
                                    fontWeight: 'bold'
                                }}
                            >
                                {t("menu.boothOperation.title")}
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: '10px'
                        }}
                    >
                        <div
                            style={{
                                display: 'inline-block',
                                position: 'relative'
                            }}
                        >
                            <GoDotFill
                                color={
                                    isBoothOnline ?
                                        "#8cef74"
                                        :
                                        "#757575"
                                }
                                style={{
                                    position: 'absolute',
                                    bottom: '-3px'
                                }}
                            />
                        </div>
                        <span
                            style={{
                                color: '#757575',
                                fontSize: '13px',
                                marginLeft: '20px'
                            }}
                        >
                            {
                                isBoothOnline ?
                                    t("menu.boothOperation.message.boothOnline")
                                    :
                                    t("menu.boothOperation.message.boothOffline")
                            }
                        </span>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: '10px'
                        }}
                    >
                        <span
                            style={{
                                color: '#c2c2c2',
                                fontSize: '13px'
                            }}
                        >
                            {
                                t("menu.boothOperation.message.lastRefreshedAt")
                                + " " + lastUpdateAt
                            }
                        </span>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '750px',
                            padding: '0px 5px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '730px',
                                overflow: 'auto',
                                backgroundColor: 'white',
                                borderRadius: '5px',
                                padding: '5px'
                            }}
                        >
                            {renderInner()}
                        </div>

                    </div>
                </div>
            </Col>
        );
    }

};

export default BoothOperationView;