import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import DataCompareService from "../../../services/DataCompareService";
import JsonValidateUtils from "../../common/utils/JsonValidateUtils";
import DataUtils from "../../menu/common/utils/DataUtils";
import Modal from "react-modal";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import UserService from "../../../services/UserService";
import axios from "axios";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import styled from "styled-components";
import TitleAndSelectBox from "../../common/dataView/TitleAndSelectBox";
import ButtonWithSpinner from "../../menu/common/buttons/ButtonWithSpinner";
import OrangeButton from "../../menu/common/buttons/OrangeButton";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";
import {useNavigate} from "react-router-dom";


const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        maxHeight: '80vh',
        borderRadius: '10px',
        overflowY: 'auto',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const requestBodySchema = {
    "type" : "object",
    "properties" : {
        "id" : {
            "type": "string",
            "minLength": 1,
            "pattern": "^[^\\s!\\*'\\(\\);:@&=\\+\\$,/\\?#\\[\\]]+$"
        },
        "name" : {
            "type" : "string",
            "minLength": 1,
            "pattern": "^(?!\\s*$).+"
        },
        "isMaster" : {
            "type" : "boolean"
        },
        "password" : {
            "type" : "string",
            "minLength": 1
        },
        "email" : {
            "type" : "string",
            "format" : "email",
            "pattern": "^[^\\s]+$"
        }
    },
    "required" : ["id", "name", "isMaster", "email", "password"]
};

const requestBodyInit = {
    "id" : "",
    "name" : "",
    "isMaster" : false,
    "password" : "",
    "email" : ""
};

const isValidMapInit = {
    "id" : false,
    "name" : false,
    "isMaster" : true,
    "password" : false,
    "email" : false
};

const ViewMessage = styled.span`
  font-size: 14px;
  color: #ff0000;
  margin-top: 5px;
  padding-left: 5px;
`;

const ConsoleAccountAddModal = ({ isOpen, setIsOpen, franchiseId, refreshList }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    /* states */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDuplicationLoading, setIsDuplicationLoading] = useState(false);
    const [isIdGood, setIsIdGood] = useState(true);
    const [isEmailGood, setIsEmailGood] = useState(true);
    const [isPasswordVerified, setIsPasswordVerified] = useState(false);
    const [passwordReInput, setPasswordReInput] = useState("");

    /* initialize */
    const initializeAll = async () => {
        await setIsLoading(false);
        await setRequestBody(requestBodyInit);
        await setIsValidMap(isValidMapInit);
        await setIsValid(false);
        await setIsIdGood(true);
        await setIsEmailGood(true);
        await setIsPasswordVerified(false);
        await setPasswordReInput("");
    };

    /* effects */
    useEffect(() => {
        initializeAll();
    }, [franchiseId]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        setIsPasswordVerified(requestBody['password'] === passwordReInput);
    }, [requestBody['password'], passwordReInput]);

    /* update functions */
    const validateField = async (key, value) => {
        return JsonValidateUtils.validateWithJSONSchema(requestBodySchema['properties'][key], value);
    };

    /* check duplication */
    const checkDuplication = async (type, field) => { /** type : "id" or "email" */
        if ((type !== "id") && (type !== "email")) {
            console.log("Bad Usage of Type : " + type);
            return false;
        }
        await setIsDuplicationLoading(true);
        if (type === "id") {
            await setIsIdGood(true);
        } else {
            await setIsEmailGood(true);
        }
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-console-credential/check/duplicate`;
        try {
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    params: {
                        type: type,
                        keyword: field
                    },
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (type === "id") {
                await setIsIdGood(!res.data.item['isDuplicated']);
            } else {
                await setIsEmailGood(!res.data.item['isDuplicated']);
            }
            await setIsDuplicationLoading(false);
            return !res.data.item['isDuplicated'];
        } catch (e) {
            console.log(e);
            await setIsDuplicationLoading(false);
            if (type === "id") {
                await setIsIdGood(false);
            } else {
                await setIsEmailGood(false);
            }
            return false;
        }
    };

    /* post a new account */
    const postAccount = async () => {
        await setIsLoading(true);
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
            + `/api/v1/franchise-console-credential/franchise/${franchiseId}`;
        try {
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "application/json"
                    }
                }
            ));
            let generatedId = res.data.item['generatedFranchiseConsoleInfo']['franchiseConsoleCredentialId'];
            await initializeAll();
            await setIsOpen(false);
            await refreshList();
            await navigate(`/fr-console-credential/franchise/${franchiseId}/credential/${generatedId}`);
            toast.success(<ToastAlertView message={t("message.saved")} />);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
        await setIsLoading(false);
    };

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={async () => {
                if (!isLoading) {
                    await setIsOpen(false);
                }
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchiseConsoleCred.add.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    {/* id */}
                    <TitleAndInputBox
                        title={t("menu.franchiseConsoleCred.add.fields.id")}
                        value={requestBody['id']}
                        onChange={async (value) => {
                            /* data update */
                            await DataUtils.updateHelper(setRequestBody, 'id', value);
                            /* check validity */
                            let validRes = await validateField('id', value);
                            let duplicationRes = await checkDuplication('id', value);
                            await DataUtils.updateHelper(setIsValidMap, 'id', validRes && duplicationRes);
                        }}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap['id']}
                        isEnabled={true}
                    />
                    {/* id duplication message */}
                    {
                        (!isIdGood) &&
                        <ViewMessage>
                            {t("menu.franchiseConsoleCred.message.duplicated", { field: t("menu.franchiseConsoleCred.table.id") })}
                        </ViewMessage>
                    }
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* email */}
                    <TitleAndInputBox
                        title={t("menu.franchiseConsoleCred.add.fields.email")}
                        value={requestBody['email']}
                        onChange={async (value) => {
                            /* data update */
                            await DataUtils.updateHelper(setRequestBody, 'email', value);
                            /* check validity */
                            let validRes = await validateField('email', value);
                            let duplicationRes = await checkDuplication('email', value);
                            await DataUtils.updateHelper(setIsValidMap, 'email', validRes && duplicationRes);
                        }}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap['email']}
                        isEnabled={true}
                    />
                    {/* email duplication message */}
                    {
                        (!isEmailGood) &&
                        <ViewMessage>
                            {t("menu.franchiseConsoleCred.message.duplicated", { field: t("menu.franchiseConsoleCred.table.email") })}
                        </ViewMessage>
                    }
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* name */}
                    <TitleAndInputBox
                        title={t("menu.franchiseConsoleCred.add.fields.name")}
                        value={requestBody['name']}
                        onChange={async (value) => {
                            /* data update */
                            await DataUtils.updateHelper(setRequestBody, 'name', value);
                            /* check validity */
                            let validRes = await validateField('name', value);
                            await DataUtils.updateHelper(setIsValidMap, 'name', validRes);
                        }}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap['name']}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* password */}
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        <TitleAndInputBox
                            title={t("menu.franchiseConsoleCred.add.fields.password")}
                            value={requestBody['password']}
                            onChange={async (value) => {
                                /* data update */
                                await DataUtils.updateHelper(setRequestBody, 'password', value);
                                /* check validity */
                                let validRes = await validateField('password', value);
                                await DataUtils.updateHelper(setIsValidMap, 'password', validRes);
                            }}
                            isForEdit={true}
                            type={"password"}
                            isValid={isValidMap['password']}
                            isEnabled={true}
                        />
                    </Col>
                    {/* password verification */}
                    <Col
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        <TitleAndInputBox
                            title={t("menu.franchiseConsoleCred.add.fields.rePassword")}
                            value={passwordReInput}
                            onChange={async (value) => {
                                /* data update */
                                await setPasswordReInput(value);
                            }}
                            isForEdit={true}
                            type={"password"}
                            isValid={isPasswordVerified}
                            isEnabled={true}
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* type */}
                    <TitleAndSelectBox
                        title={t("menu.franchiseConsoleCred.add.fields.isMaster")}
                        innerValue={requestBody['isMaster']}
                        valueList={[
                            {
                                "value" : true,
                                "view" : t("menu.franchiseConsoleCred.message.master")
                            },
                            {
                                "value" : false,
                                "view" : t("menu.franchiseConsoleCred.message.nonMaster")
                            }
                        ]}
                        valueSelector={"value"}
                        viewSelector={"view"}
                        onChange={async (value) => {
                            await DataUtils.updateHelper(setRequestBody, "isMaster", JSON.parse(value));
                        }}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {/* save button */}
                    {
                        (isLoading || isDuplicationLoading) ?
                            <ButtonWithSpinner
                                buttonColor={
                                    isDuplicationLoading ?
                                        "#ebebeb"
                                        :
                                        "#fc7242"
                                }
                            />
                            :
                            <OrangeButton
                                onClickCallback={async () => await postAccount()}
                                isEnabled={
                                    isValid &&
                                    isIdGood &&
                                    isEmailGood &&
                                    isPasswordVerified
                                }
                                style={{
                                    width: "100%"
                                }}
                                label={t("button.save")}
                            />
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default ConsoleAccountAddModal;