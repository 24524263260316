import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import DivWithTitle from "./DivWithTitle";
import {Col, Container, Row} from "react-bootstrap";
import styled from "styled-components";
import NotInBranch from "./boothVersion/NotInBranch";
import UserService from "../../../services/UserService";
import axios from "axios";
import InBranch from "./boothVersion/InBranch";

const Title = styled.div`
  text-align: left;
  font-size: 14px;
  color: #8f8f8f;
  padding-left: 15px;
`;

const BranchContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  height: 390px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background: none;
  padding: 10px;
  overflow: auto;
`;

const FranchiseBranch = ({ onRefresh, dataList }) => {

    const { t } = useTranslation();
    const location = useLocation();
    const { groupId, franchiseId } = useParams();

    /* states */
    const [branchList, setBranchList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /* fetch branchList */
    const fetchBranchList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/branch`
                + `/group/${groupId}/no-franchise`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newBranchList = res.data.item['branchList'];
            setBranchList(newBranchList);
        } catch (e) {
            console.log(e);
        }
    };

    /* put data */
    const deleteBranch = async (branchId) => {
        try {
            await setIsLoading(true);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`
                + `/${franchiseId}/branch/${branchId}`;
            await UserService.updateToken(() => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setIsLoading(false);
            onRefresh();
        } catch (e) {
            console.log(e);
        }
    };

    const addBranch = async (branchId) => {
        try {
            await setIsLoading(true);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`
                + `/${franchiseId}/branch`;
            let newRequestBody = {};
            newRequestBody['franchiseBranchBranchId'] = branchId;
            await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            await setIsLoading(false);
            onRefresh();
        } catch (e) {
            console.log(e);
        }
    };

    /* effects */
    useEffect(() => {
        fetchBranchList();
    }, [dataList]);

    return (
        <DivWithTitle
            title={t("menu.franchise.detail.branchInfo.title")}
        >
            <Container fluid>
                <Row>
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        <Title>
                            {t("menu.franchise.detail.branchInfo.notInFranchise")}
                        </Title>
                        <BranchContainer>
                            {
                                (branchList.length > 0) &&
                                branchList.map(branchObj => {
                                    return (
                                        <NotInBranch
                                            key={branchObj["branchId"]}
                                            branchName={branchObj['branchName']}
                                            masterName={branchObj['masterName']}
                                            masterId={branchObj['branchMasterId']}
                                            address={branchObj['locationAddress']}
                                            onClick={() => addBranch(branchObj["branchId"])}
                                            isEnabled={!isLoading}
                                        />
                                    );
                                })
                            }
                            {
                                (branchList.length === 0) &&
                                <div
                                    style={{
                                        width: '100%',
                                        paddingTop: '40px',
                                        color: '#757575',
                                        fontSize: '14px',
                                        textAlign: 'center'
                                    }}
                                >
                                    {t("menu.franchise.detail.branchInfo.noBranch")}
                                </div>
                            }
                        </BranchContainer>
                    </Col>
                    <Col
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        <Title>
                            {t("menu.franchise.detail.branchInfo.inFranchise")}
                        </Title>
                        <BranchContainer>
                            {
                                (dataList.length > 0) &&
                                dataList.map(branchObj => {
                                    return (
                                        <InBranch
                                            key={branchObj["branchId"]}
                                            branchName={branchObj['branchName']}
                                            masterName={branchObj['branchMasterName']}
                                            masterId={branchObj['branchMasterId']}
                                            address={branchObj['locationAddress']}
                                            onClick={() => deleteBranch(branchObj["branchId"])}
                                            isEnabled={!isLoading}
                                        />
                                    );
                                })
                            }
                            {
                                (dataList.length === 0) &&
                                <div
                                    style={{
                                        width: '100%',
                                        paddingTop: '40px',
                                        color: '#757575',
                                        fontSize: '14px',
                                        textAlign: 'center'
                                    }}
                                >
                                    {t("menu.franchise.detail.branchInfo.noBranch")}
                                </div>
                            }
                        </BranchContainer>
                    </Col>
                </Row>
            </Container>
        </DivWithTitle>
    );
};

export default FranchiseBranch;