import React from 'react';
import {Col, Row, Container, Figure} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import UserService from "../services/UserService";
import "./LoginStyle.css";

const Login = () => {

    const { t } = useTranslation();

    return (
        <Container fluid>
            <Row style={{ padding: '30px' }}>
                <Figure>
                    <Figure.Image src={"/logos/black_logo3.png"} width={186} height={36}/>
                </Figure>
            </Row>
            <Row style={{ marginTop: '10%' }}>
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                    <p>{t("login.title")}</p>
                    <button
                        id='login-btn'
                        onClick={() => UserService.doLogin()}
                    >
                        {t("login.button")}
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default Login;