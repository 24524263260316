import React, {useRef} from 'react';
import {useTranslation} from "react-i18next";
import RecipeUtils from "../../util/RecipeUtils";
import styled from "styled-components";
import OverlayToolTip from "../../../toolTip/OverlayToolTip";

const Title = styled.span`
  font-size: 14px;
  color: #8f8f8f;
  padding-left: 5px;
`;

const TextInput = styled.input`
  width: 100%;
  border: 1px solid ${(props) => props.isValid ? "#e4e7ea" : "red"};
  border-radius: 5px;
  padding: 7px 10px;
  color: #212529;
  font-size: 15px;
  transition: all ease-in-out 0.2s;

  :focus {
    border-color: #fc7242;
  }
`;

const NumberFieldView = ({ title,
                             data,
                             validator,
                             updateData,
                             updateIsValidMap,
                             isValid,
                             isForEdit,
                             unitOfValue }) => {

    const { t } = useTranslation();

    /** to prevent scroll */
    const inputElem = useRef();

    const handleOnWheel = (e) => {
        e.preventDefault();
        inputElem.current.blur();
    };

    const handleInputChange = async (valueStr) => {
        if (isForEdit) {
            await updateData(valueStr);
            updateIsValidMap(RecipeUtils.validatePrimitiveData(validator, valueStr));
        } else {
            return;
        }

    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* title */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '5px',
                    textAlign: 'left'
                }}
            >
                <Title>
                    {title}
                </Title>
            </div>
            {/* input box */}
            <div
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                <OverlayToolTip
                    text={t("menu.franchiseMenu.recipeInfo.menuRecipe.message.inputHelpMessage.number", {
                        fieldName: title,
                        unitOfValue: unitOfValue,
                        minValue: validator['minimum'],
                        maxValue: validator['maximum']
                    })}
                    direction={"left"}
                >
                    <TextInput
                        type={"number"}
                        readOnly={!isForEdit}
                        ref={inputElem}
                        value={data}
                        onChange={(e) => handleInputChange(e.target.value)}
                        isValid={isValid}
                        onWheel={handleOnWheel}
                    />
                </OverlayToolTip>
            </div>
        </div>
    );
};

export default NumberFieldView;