import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import UserService from "../../../../services/UserService";
import axios from "axios";
import _ from "lodash";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../../common/modal/ModalCloseButton";
import OrangeButton from "../../common/buttons/OrangeButton";
import MenuCategoryItemView from "./modalInner/MenuCategoryItemView";
import DataUtils from "../../common/utils/DataUtils";
import MenuCategoryAddModal from "./MenuCategoryAddModal";
import LanguageSelectBox from "../../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../../common/alert/ToastAlertView";
import DeleteConfirmModal from "../../../common/deleteComponents/DeleteConfirmModal";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '650px',
        height: '700px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    franchiseMenuCategoryName: "",
    franchiseMenuCategoryName1: null,
    franchiseMenuCategoryName2: null,
    franchiseMenuCategoryName3: null,
    franchiseMenuCategoryName4: null,
    franchiseMenuCategoryName5: null
};

const isValidMapInit = {
    franchiseMenuCategoryName: true,
    franchiseMenuCategoryName1: true,
    franchiseMenuCategoryName2: true,
    franchiseMenuCategoryName3: true,
    franchiseMenuCategoryName4: true,
    franchiseMenuCategoryName5: true
};

const MenuCategoryEditModal = ({ isOpen, setIsOpen, franchiseId, onRefresh }) => {
    /* onRefresh :: (optional redirectUrl) => { reload menus & redirect } */

    const { t } = useTranslation();

    /* states */
    const [menuCategoryList, setMenuCategoryList] = useState([]);

    /* request states */
    const [requestBodyList, setRequestBodyList] = useState([]);
    const [isValidMapList, setIsValidMapList] = useState([]);
    const [isEditedList, setIsEditedList] = useState([]);
    const [isValidList, setIsValidList] = useState([]);

    /* state for language */
    const targetFieldsString = `"${t("menu.franchiseMenu.menuCategoryEdit.detail.categoryName")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* modals */
    const [isMenuCategoryAddModalOpen, setIsMenuCategoryAddModalOpen] = useState(false);

    /* fetch data */
    const fetchMenuCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/franchise-menu-category/franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newMenuCategoryList = res.data.item['franchiseMenuCategoryList'];
            /* sort by id */
            newMenuCategoryList.sort((a, b) => DataUtils.objectSortHelper("franchiseMenuCategoryLookupId", a, b));
            setMenuCategoryList(newMenuCategoryList);
            await initializeRequestStates(newMenuCategoryList);
        } catch (e) {
            console.log(e);
            setMenuCategoryList([]);
        }
    };

    /* build states */
    const initializeRequestStates = async (newCategoryList) => {
        /* init */
        await setRequestBodyList([]);
        await setIsValidMapList([]);
        await setIsEditedList([]);
        await setIsValidList([]);
        /* check length */
        const length = newCategoryList.length;
        /* new lists */
        let newRequestBodyList = [];
        let newIsValidMapList = [];
        let newIsEditedList = [];
        let newIsValidList = [];
        for (let i=0 ; i<length ; i++) {
            /* requestBody */
            let newRequestBody = {};
            newRequestBody["franchiseMenuCategoryName"] = newCategoryList[i]["franchiseMenuCategoryLookupName"];
            newRequestBody["franchiseMenuCategoryName1"] = newCategoryList[i]["franchiseMenuCategoryLookupName1"];
            newRequestBody["franchiseMenuCategoryName2"] = newCategoryList[i]["franchiseMenuCategoryLookupName2"];
            newRequestBody["franchiseMenuCategoryName3"] = newCategoryList[i]["franchiseMenuCategoryLookupName3"];
            newRequestBody["franchiseMenuCategoryName4"] = newCategoryList[i]["franchiseMenuCategoryLookupName4"];
            newRequestBody["franchiseMenuCategoryName5"] = newCategoryList[i]["franchiseMenuCategoryLookupName5"];
            newRequestBodyList.push(newRequestBody);
            /* isValidMap */
            newIsValidMapList.push(_.cloneDeep(isValidMapInit));
            /* isValid */
            newIsValidList.push(true);
            /* isEdited */
            newIsEditedList.push(false);
        }
        /* set */
        await setRequestBodyList(newRequestBodyList);
        await setIsValidMapList(newIsValidMapList);
        await setIsEditedList(newIsEditedList);
        await setIsValidList(newIsValidList);
    };

    /* effects */
    useEffect(() => {
        fetchMenuCategoryList();
    }, [isOpen]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchiseMenu.menuCategoryEdit.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    {/* menu category add button */}
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end'
                        }}
                    >
                        {/* language selection */}
                        <LanguageSelectBox
                            title={t("language.selectBox.title.menuCategory")}
                            languageMetadataList={languageMetadataList}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}
                            setIsHighlightOn={setIsHighlightOn}
                            targetFieldString={targetFieldsString}
                        />
                        <div
                            style={{
                                marginLeft: 'auto',
                                width: '200px'
                            }}
                        >
                            <OrangeButton
                                onClickCallback={() => setIsMenuCategoryAddModalOpen(true)}
                                isEnabled={true}
                                label={t("menu.franchiseMenu.menuCategoryEdit.button.addMenuCategory")}
                            />
                        </div>
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '550px',
                            padding: '5px',
                            overflow: 'auto',
                            borderRadius: '5px'
                        }}
                    >
                        {/* category items */}
                        {
                            (menuCategoryList.length > 0) &&
                            menuCategoryList.map((menuCategoryInfo, index) => {
                                return (
                                    <MenuCategoryItemView
                                        key={menuCategoryInfo['franchiseMenuCategoryLookupId'] + menuCategoryList.length}
                                        menuCategoryInfo={menuCategoryInfo}
                                        updateRequestBody={(key, value) => DataUtils.updateObjectArrayHelper(setRequestBodyList, index, key, value)}
                                        updateIsValidMap={(key, value) => DataUtils.updateObjectArrayHelper(setIsValidMapList, index, key, value)}
                                        updateIsValid={(value) => DataUtils.updateArrayHelper(setIsValidList, index, value)}
                                        updateIsEdited={(value) => DataUtils.updateArrayHelper(setIsEditedList, index, value)}
                                        requestBody={requestBodyList[index]}
                                        isValidMap={isValidMapList[index]}
                                        isValid={isValidList[index]}
                                        isEdited={isEditedList[index]}
                                        refreshCategoryList={() => {
                                            fetchMenuCategoryList();
                                            onRefresh();
                                        }}
                                        onDeleteCallback={() => {/** deprecated */}}
                                        currentLang={currentLang}
                                        isHighlightOn={isHighlightOn}
                                    />
                                );
                            })
                        }
                        {
                            (menuCategoryList.length === 0) &&
                            <div
                                style={{
                                    width: '100%',
                                    padding: '30px',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    color: '#757575'
                                }}
                            >
                                {t("menu.franchiseMenu.menuCategoryEdit.message.noMenuCategory")}
                            </div>
                        }
                    </div>
                </Row>
            </Container>
            {/* menu category add modal */}
            <MenuCategoryAddModal
                isOpen={isMenuCategoryAddModalOpen}
                setIsOpen={setIsMenuCategoryAddModalOpen}
                onRefresh={fetchMenuCategoryList}
                franchiseId={franchiseId}
            />
        </Modal>
    );
};

export default MenuCategoryEditModal;