import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useParams, useNavigate, useLocation} from "react-router-dom";
import axios from "axios";
import DataCompareService from "../../services/DataCompareService";
import RenderOnRole from "../../auth/RenderOnRole";
import {Col, Container, Image, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import UserService from '../../services/UserService';
import CustomerAppRoleEdit from "./customerAppRole/CustomerAppRoleEdit";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import DeleteConfirmModal from "../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../services/DeleteService";

const requestBodySchema = {
    "userName": {
        "type": "string",
        "minLength": 2,
        "maxLength": 100,
        "pattern": "^[가-힣]{1}[가-힣\\s]{0,15}[가-힣]{1}$|^[a-zA-Z]{1}[a-zA-Z\\s]{0,98}[a-zA-Z]{1}$"
    },
    "userEmail": {
        "type": "string",
        "minLength": 6,
        "maxLength": 40,
        "pattern": "^[0-9a-zA-Z-_]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\\.[a-zA-Z]+$"
    },
    "userPhone": {
        "type" : "string"
    },
    "userType": {
        "type": "string",
        "pattern" : "^(manager|master)"
    },
    "userGroupId" : {
        "type" : "string"
    }
};

const isValidMapInit = {
    userName : true,
    userEmail : true,
    userPhone : true,
    userType : true,
    userGroupId : true
}

const CustomerEdit = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    /* path variable */
    const { customerId } = useParams("customerId");

    /* setting options */
    const [groupList, setGroupList] = useState([]);

    /* customer information */
    const [prevCustomerInfo, setPrevCustomerInfo] = useState({});
    const [afterCustomerInfo, setAfterCustomerInfo] = useState({});

    /* trackers */
    const [isValidMap, setIsValidMap] = useState({...isValidMapInit});
    const [isEdited, setIsEdited] = useState(false);
    const [isValid, setIsValid] = useState(true);

    /* modal state */
    const [isAuthEditModalOpen, setIsAuthEditModalOpen] = useState(false);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    /* fetch information */
    const fetchCustomerInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user/${customerId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            let customerData = {};
            customerData["userId"] = res.data.item.userInfo.userId;
            customerData["userName"] = res.data.item.userInfo.userName;
            customerData["userEmail"] = res.data.item.userInfo.userEmail;
            customerData["userPhone"] = res.data.item.userInfo.userPhone;
            customerData["userType"] = res.data.item.userInfo.userType;
            customerData["userGroupId"] = res.data.item.userInfo.userGroupId;
            customerData["userImageUrl"] = res.data.item.userInfo.userImageUrl;
            setPrevCustomerInfo(DataCompareService.deepCopyObj(customerData));
            setAfterCustomerInfo(DataCompareService.deepCopyObj(customerData));
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setGroupList(res.data.item.groupList);
        } catch (e) {
            console.log(e);
        }
    }

    /* input validation */
    /* check validation */
    const checkEmailValidity = async () => {
        try {
            const isValidForm = DataCompareService.regexValidator(afterCustomerInfo["userEmail"], "^[0-9a-zA-Z-_]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\\.[a-zA-Z]+$") &&
                (afterCustomerInfo["userEmail"].length <= 40) &&
                (afterCustomerInfo["userEmail"].length >= 6);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user/check/email/duplicate?target=${afterCustomerInfo["userEmail"]}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            const isDuplicate = res.data.item.result;
            if (afterCustomerInfo["userEmail"] === prevCustomerInfo["userEmail"]) {
                setIsValidMap(prevObj => {
                    let newObj = {...prevObj};
                    newObj["userEmail"] = isValidForm;
                    return newObj;
                });
            } else {
                setIsValidMap(prevObj => {
                    let newObj = {...prevObj};
                    newObj["userEmail"] = isValidForm && (!isDuplicate);
                    return newObj;
                });
            }
        } catch (e) {
            console.log(e);
            setIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj["userEmail"] = false;
                return newObj;
            });
        }
    }

    /* handlers */
    const handleGroupIdChange = (value) => {
        setAfterCustomerInfo(prevObj => {
            let newObj = {...prevObj};
            newObj["userGroupId"] = value;
            return newObj;
        });
    }

    const handleEmailInput = (value) => {
        setAfterCustomerInfo(prevObj => {
            let newObj = {...prevObj};
            newObj["userEmail"] = value;
            return newObj;
        });
    }

    const handlePhoneInput = (value) => {
        setAfterCustomerInfo(prevObj => {
            let newObj = {...prevObj};
            newObj["userPhone"] = value;
            return newObj;
        });
    }

    const handleNameInput = (value) => {
        setAfterCustomerInfo(prevObj => {
            let newObj = {...prevObj};
            newObj["userName"] = value;
            return newObj;
        });
    }

    const handleTypeInput = (value) => {
        setAfterCustomerInfo(prevObj => {
            let newObj = {...prevObj};
            newObj["userType"] = value;
            return newObj;
        });
    }

    const checkPhoneValidity = async () => {
        try {
            const isValidForm = true; // no form
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user/check/phone/duplicate?target=${afterCustomerInfo["userPhone"]}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            const isDuplicate = res.data.item.result;
            if (afterCustomerInfo["userPhone"] === prevCustomerInfo["userPhone"]) {
                setIsValidMap(prevObj => {
                    let newObj = {...prevObj};
                    newObj["userPhone"] = isValidForm;
                    return newObj;
                });
            } else {
                setIsValidMap(prevObj => {
                    let newObj = {...prevObj};
                    newObj["userPhone"] = isValidForm && (!isDuplicate);
                    return newObj;
                });
            }
        } catch (e) {
            console.log(e);
            setIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj["userPhone"] = false;
                return newObj;
            });
        }
    }

    const checkNameValidity = async () => {
        try {
            const isValidForm = DataCompareService.regexValidator(afterCustomerInfo["userName"], "^[가-힣]{1}[가-힣\\s]{0,15}[가-힣]{1}$|^[a-zA-Z]{1}[a-zA-Z\\s]{0,98}[a-zA-Z]{1}$") &&
                (afterCustomerInfo["userName"].length <= 100) &&
                (afterCustomerInfo["userName"].length >= 2);;

            setIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj["userName"] = isValidForm;
                return newObj;
            });
        } catch (e) {
            console.log(e);
            setIsValidMap(prevObj => {
                let newObj = {...prevObj};
                newObj["userName"] = false;
                return newObj;
            });
        }
    }

    /* put customer information */
    const putCustomerInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user/${customerId}`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl ,
                JSON.stringify(afterCustomerInfo),
                {
                    headers: {
                        "Authorization" : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate(`/customer/detail/${customerId}`);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    /* effects */
    useEffect(() => {
        fetchCustomerInfo();
        fetchGroupList();
    }, []);

    /* input trackers */
    useEffect(() => {
        setIsEdited(!DataCompareService.isSameObj(prevCustomerInfo, afterCustomerInfo));
    }, [afterCustomerInfo]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        if (typeof afterCustomerInfo["userEmail"] === "undefined") {
            return;
        }
        checkEmailValidity();
    }, [afterCustomerInfo["userEmail"]]);

    useEffect(() => {
        if (typeof afterCustomerInfo["userPhone"] === "undefined") {
            return;
        }
        checkPhoneValidity();
    }, [afterCustomerInfo["userPhone"]]);

    useEffect(() => {
        if (typeof afterCustomerInfo["userName"] === "undefined") {
            return;
        }
        checkNameValidity();
    }, [afterCustomerInfo["userName"]]);

    return (
        <RenderOnRole type={"PUT"} roleName={ImsSystemRole.CUSTOMER_MANAGEMENT} allowedRing={2}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={`/customer/detail/${customerId}`}
                />
                <PageTitleWithDepth
                    titles={[t("menu.customer.title"), t("menu.customer.detail.title"), t("menu.customer.edit.title")]}
                    depth={3}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                    <Container fluid>
                        <Row>
                            {/* main area */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '700px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                {/* top info */}
                                <div
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'left'
                                        }}
                                    >
                                        <div>
                                            <Image
                                                src={
                                                    (prevCustomerInfo.userImageUrl == null) ?
                                                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                        :
                                                        `${prevCustomerInfo.userImageUrl}`
                                                }
                                                alt="Profile Image"
                                                roundedCircle
                                                style={{ width: '70px', height: '70px', display: 'inline-block', border: '0'}}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: '20px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <div
                                                style={{ textAlign: 'left' }}
                                            >
                                            <span
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {prevCustomerInfo.userName}
                                            </span>
                                            </div>
                                            <div
                                                style={{ textAlign: 'left' }}
                                            >
                                                <button
                                                    style={{
                                                        background: 'none',
                                                        border: 'none',
                                                        padding: '0px',
                                                        textDecoration: 'underline',
                                                        fontSize: '12px',
                                                        color: '#656565'
                                                    }}
                                                    onClick={() => setIsAuthEditModalOpen(true)}
                                                >
                                                    {t("button.editFranchiseAppAuth")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* middle info */}
                                <div
                                    style={{
                                        borderTop: '1px solid #c2c2c2',
                                        width: '100%',
                                        height: '450px',
                                        display: 'flex',
                                        overflow: 'auto',
                                        padding: '20px 0px 20px 0px'
                                    }}
                                >
                                    <Container fluid>
                                        <Row>
                                            <TitleAndInputBox
                                                title={t("common.email")}
                                                value={afterCustomerInfo.userEmail}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["userEmail"]}
                                                onChange={handleEmailInput}
                                            />
                                        </Row>
                                        <Row
                                            style={{ marginTop: '10px' }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.name")}
                                                value={afterCustomerInfo.userName}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["userName"]}
                                                onChange={handleNameInput}
                                            />
                                        </Row>
                                        <Row
                                            style={{ marginTop: '10px' }}
                                        >
                                            <Col
                                                md={"1"}
                                                style={{ padding: '0px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.phone")}
                                                    value={"+82"}
                                                    isForEdit={false}
                                                    type={"text"}
                                                    isValid={true}
                                                    onChange={() => {}}
                                                />
                                            </Col>
                                            <Col
                                                md={"11"}
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={"　"}
                                                    value={afterCustomerInfo.userPhone}
                                                    isForEdit={true}
                                                    type={"text"}
                                                    isValid={isValidMap["userPhone"]}
                                                    onChange={handlePhoneInput}
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{ marginTop: '10px' }}
                                        >
                                            <TitleAndSelectBox
                                                title={t("common.group")}
                                                innerValue={afterCustomerInfo.userGroupId}
                                                valueList={groupList}
                                                valueSelector={"groupId"}
                                                viewSelector={"groupName"}
                                                onChange={handleGroupIdChange}
                                            />
                                        </Row>
                                    </Container>
                                </div>
                                {/* bottom btn */}
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <RenderOnRole type={"DELETE"} roleName={ImsSystemRole.CUSTOMER_MANAGEMENT} allowedRing={2} nothingOnRejected={true}>
                                        <div
                                            style={{ marginRight: 'auto' }}
                                        >
                                            <Link
                                                id={"reset-password-btn"}
                                                onClick={async () => {
                                                    await setIsDeleteConfirmOpen(true);
                                                }}
                                            >
                                                {t("button.delete")}
                                            </Link>
                                            {/* delete confirmation */}
                                            <DeleteConfirmModal
                                                isOpen={isDeleteConfirmOpen}
                                                setIsOpen={setIsDeleteConfirmOpen}
                                                isDelayed={true}
                                                confirmMessage={t("language.message.deletion.confirmation", { entityName : prevCustomerInfo['userName'] })}
                                                entity={DeleteService.ValidEntities.CUSTOMER}
                                                entityName={prevCustomerInfo['userName']}
                                                id={`user/${customerId}`}
                                                onDeleteSuccess={async () => {
                                                    await navigate('/customer');
                                                }}
                                                depsToShow={[
                                                    t("language.message.deletion.depsList.user.qna"),
                                                    t("language.message.deletion.depsList.user.branch"),
                                                    t("language.message.deletion.depsList.user.coupon"),
                                                    t("language.message.deletion.depsList.user.membership"),
                                                    t("language.message.deletion.depsList.user.clientDevice")
                                                ]}
                                                validateMessage={t("language.message.deletion.verificationMessage", { entityName : prevCustomerInfo['userName'] })}
                                                delayToUndo={DeleteService.getLongDelay()}
                                                onUndoSuccess={async () => {
                                                    const currLocation = location.pathname;
                                                    await navigate(currLocation);
                                                }}
                                            />
                                        </div>
                                    </RenderOnRole>
                                    <div
                                        style={{ marginRight: '10px' }}
                                    >
                                        <Link
                                            id={"edit-btn"}
                                            to={`/customer/detail/${customerId}`}
                                        >
                                            {t("button.cancel")}
                                        </Link>
                                    </div>
                                    <div>
                                        {
                                            (isEdited && isValid)
                                                ?
                                                <Link
                                                    id={"save-btn"}
                                                    onClick={() => putCustomerInfo()}
                                                >
                                                    {t("button.save")}
                                                </Link>
                                                :
                                                <Link
                                                    id={"save-btn-disabled"}
                                                >
                                                    {t("button.save")}
                                                </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* auth edit modal */}
            {
                (typeof prevCustomerInfo.userGroupId !== "undefined") ?
                    <CustomerAppRoleEdit
                        isOpen={isAuthEditModalOpen}
                        setIsOpen={setIsAuthEditModalOpen}
                        groupId={prevCustomerInfo.userGroupId}
                        userId={prevCustomerInfo.userId}
                    />
                    :
                    <></>
            }
        </RenderOnRole>
    );
};

export default CustomerEdit;