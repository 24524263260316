import axios from "axios";
import UserService from "../../../services/UserService";

const BASE_URL = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`;
const DASHBOARD_BASE_URL = BASE_URL + "/dashboard"

const fetchBoothInfo = async (onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${DASHBOARD_BASE_URL}/booth`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data.item);
    } catch (e) {
        console.log(e);
        onFailureCallback(e);
    }
};

const fetchOrderAndSalesInfo = async (onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${DASHBOARD_BASE_URL}/order`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data.item);
    } catch (e) {
        console.log(e);
        onFailureCallback(e);
    }
};

const fetchNotDoneTodoList = async (onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${BASE_URL}/todo?coverage=not-done`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data.item);
    } catch (e) {
        console.log(e);
        onFailureCallback(e);
    }
};

const DashboardAxiosService = {
    fetchBoothInfo,
    fetchOrderAndSalesInfo,
    fetchNotDoneTodoList
};

export default DashboardAxiosService;