import React, {useEffect, useState} from 'react';
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import UserService from "../../../services/UserService";
import axios from "axios";
import _ from "lodash";
import DataCompareService from "../../../services/DataCompareService";
import styled from "styled-components";
import {Container, Row, Spinner} from "react-bootstrap";
import KeyTextViewAndCopyButton from "./keyDetail/KeyTextViewAndCopyButton";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import DataParseService from "../../../services/DataParseService";
import WebHookView from "./keyDetail/WebHookView";
import ApiKeyExtensionHistoryItemView from "./keyDetail/ApiKeyExtensionHistoryItemView";
import ApiKeyExtend from "../modals/ApiKeyExtend";
import ConfirmModal from "../../common/confirm/ConfirmModal";
import ApiKeyEdit from "../modals/ApiKeyEdit";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";
import AuthService from "../../../services/AuthService";
import DeleteConfirmModal from "../../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../../services/DeleteService";

const SaveButton = styled.button`
  border: 1px solid #fc7242;
  color: #fc7242;
  background-color: white;
  transition: all ease-in-out 0.2s;
  padding: 5px 20px;
  border-radius: 10px;
  font-size: 14px;
  margin-left: auto;

  &:hover {
    background-color: #fcf9ed;
  }

  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  border: 1px solid #ebebeb;
  color: #ebebeb;
  background-color: white;
  transition: all ease-in-out 0.2s;
  padding: 5px 20px;
  border-radius: 10px;
  font-size: 14px;
  margin-left: auto;

  &:focus {
    outline: none;
  }
`;

const RedButton = styled.button`
  border: 1px solid #ff3333;
  color: #ff3333;
  background-color: white;
  transition: all ease-in-out 0.2s;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  
  &:hover {
    background-color: #ffe6e6;
  }
  
  &:focus {
    outline: none;
  }
`;

const ActivateButton = styled.button`
  border: 1px solid #128cfb;
  color: #128cfb;
  background-color: white;
  transition: all ease-in-out 0.2s;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;

  &:hover {
    background-color: #e1f5fe;
  }

  &:focus {
    outline: none;
  }
`;

const EditButton = styled.button`
  border: 1px solid #757575;
  color: #757575;
  background-color: white;
  transition: all ease-in-out 0.2s;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;

  &:hover {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
  }
`;

const OrangeButton = styled.button`
  border: 1px solid #fc7242;
  color: #fc7242;
  background-color: white;
  transition: all ease-in-out 0.2s;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;

  &:hover {
    background-color: #fcf9ed;
  }

  &:focus {
    outline: none;
  }
`;

const webHookRequestBodyInit = {
    "alertUrl" : "",
    "paymentCancelUrl" : ""
};

const isWebHookValidMapInit = {
    "alertUrl" : true,
    "paymentCancelUrl" : true
};

const ApiKeyDetailView = () => {

    const { t } = useTranslation();

    /* route variables */
    const { groupId, franchiseId, keyId } = useParams();
    const navigate = useNavigate();
    const { initializeAll } = useOutletContext();

    /* states */
    /* isLoading */
    const [isLoading, setIsLoading] = useState(true);
    /* modals */
    const [isKeyEditModalOpen, setIsKeyEditModalOpen] = useState(false);
    const [isKeyExtensionModalOpen, setIsKeyExtensionModalOpen] = useState(false);
    /* confirm */
    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
    /* values */
    const [apikeyInfo, setApiKeyInfo] = useState({});
    const [keyExtensionHistoryList, setKeyExtensionHistoryList] = useState([]);
    /* request body */
    const [webHookRequestBody, setWebHookRequestBody] = useState(webHookRequestBodyInit);
    const [fixedWebHookRequestBody, setFixedWebHookRequestBody] = useState(webHookRequestBodyInit);
    const [isWebHookValidMap, setIsWebHookValidMap] = useState(isWebHookValidMapInit);
    /* isValid, isEdited */
    const [isWebHookEdited, setIsWebHookEdited] = useState(false);
    const [isWebHookValid, setIsWebHookValid] = useState(true);

    /* utils */
    const initializeAllInner = async () => {
        await setIsLoading(true);
        /* reset */
        setWebHookRequestBody(webHookRequestBodyInit);
        setFixedWebHookRequestBody(webHookRequestBodyInit);
        setIsWebHookValidMap(isWebHookValidMapInit);
        /* fetch */
        fetchApiKey();
    };

    const updateHelper = (setState, key, value) => {
        setState(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        })
    };

    /* handlers */
    const handleAlertUrlInput = (value) => {
        updateHelper(setWebHookRequestBody, "alertUrl", value);
        if (typeof value === "undefined" || value == null || value === "") {
            updateHelper(setIsWebHookValidMap, "alertUrl", false);
        } else {
            updateHelper(setIsWebHookValidMap, "alertUrl", true);
        }
    };

    const handlePaymentCancelUrlInput = (value) => {
        updateHelper(setWebHookRequestBody, "paymentCancelUrl", value);
        if (typeof value === "undefined" || value == null || value === "") {
            updateHelper(setIsWebHookValidMap, "paymentCancelUrl", false);
        } else {
            updateHelper(setIsWebHookValidMap, "paymentCancelUrl", true);
        }
    };

    /* fetch key info */
    const fetchApiKey = async () => {
        try {
            setIsLoading(true);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-app-api-key`
                + `/franchise/${franchiseId}/key/${keyId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newKeyInfo = res.data.item['franchiseAppApiKeyInfo'];
            let newExtensionHistoryList = res.data.item['apiKeyExtensionHistoryList'];
            let newWebHookInfo = res.data.item['webHookInfo'];
            /* set key info */
            setApiKeyInfo(newKeyInfo);
            /* init webHookRequestBody */
            let newRequestBody = {};
            newRequestBody["alertUrl"] = newWebHookInfo["franchiseWebHookAlertUrl"];
            newRequestBody["paymentCancelUrl"] = newWebHookInfo["franchiseWebHookPaymentCancelUrl"];
            await setWebHookRequestBody(newRequestBody);
            await setFixedWebHookRequestBody(_.cloneDeep(newRequestBody));
            /* set extension history */
            await setKeyExtensionHistoryList(newExtensionHistoryList);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    /* save webHook */
    const saveWebHook = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-app-api-key`
                + `/franchise/${franchiseId}/key/${keyId}/web-hook`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(webHookRequestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* on success */
            initializeAllInner();
            toast.success(<ToastAlertView message={t("message.saved")} />);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* inactivate key */
    const inactivateKey = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-app-api-key`
                + `/franchise/${franchiseId}/key/${keyId}/disable`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify({}),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "application/json"
                    }
                }
            ));
            /* on success */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            initializeAll();
            initializeAllInner();
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* activate key */
    const activateKey = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-app-api-key`
                + `/franchise/${franchiseId}/key/${keyId}/enable`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify({}),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "application/json"
                    }
                }
            ));
            /* on success */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            initializeAll();
            initializeAllInner();
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* effects */
    /* init */
    useEffect(() => {
        initializeAllInner();
    }, [keyId]);

    /* isValid */
    useEffect(() => {
        setIsWebHookValid(DataCompareService.checkIsAllTrue(isWebHookValidMap));
    }, [isWebHookValidMap]);

    /* isEdited */
    useEffect(() => {
        setIsWebHookEdited(!_.isEqual(webHookRequestBody, fixedWebHookRequestBody));
    }, [webHookRequestBody]);

    /* loading */
    const renderSpinner = () => {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Spinner
                    style={{
                        color: '#757575'
                    }}
                />
            </div>
        );
    };

    if (isLoading) {
        return renderSpinner();
    } else {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <Container fluid>
                    <Row>
                        {
                            AuthService.hasAuthForOverRingOne() ?
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    {/* activate or inactivate button */}
                                    {
                                        apikeyInfo['enabled'] ?
                                            <RedButton
                                                onClick={() => inactivateKey()}
                                            >
                                                {t("menu.franchiseApiKey.detail.button.inactivate")}
                                            </RedButton>
                                            :
                                            <ActivateButton
                                                onClick={() => activateKey()}
                                            >
                                                {t("menu.franchiseApiKey.detail.button.activate")}
                                            </ActivateButton>
                                    }
                                    {/* extend button */}
                                    <OrangeButton
                                        style={{
                                            marginLeft: 'auto'
                                        }}
                                        onClick={() => setIsKeyExtensionModalOpen(true)}
                                    >
                                        {t("menu.franchiseApiKey.detail.button.extend")}
                                    </OrangeButton>
                                    {/* delete button */}
                                    <RedButton
                                        style={{
                                            marginLeft: '10px'
                                        }}
                                        onClick={() => setIsDeleteConfirmModalOpen(true)}
                                    >
                                        {t("menu.franchiseApiKey.detail.button.delete")}
                                    </RedButton>
                                    {/* edit button */}
                                    {
                                        apikeyInfo['extended'] ?
                                            <></>
                                            :
                                            <EditButton
                                                style={{
                                                    marginLeft: '10px'
                                                }}
                                                onClick={() => setIsKeyEditModalOpen(true)}
                                            >
                                                {t("menu.franchiseApiKey.detail.button.edit")}
                                            </EditButton>
                                    }
                                </div>
                            :
                                <></>
                        }
                    </Row>
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        {/* key text view */}
                        <KeyTextViewAndCopyButton
                            apiKey={apikeyInfo['franchiseAppApiKey']}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        {/* generate date */}
                        <TitleAndInputBox
                            title={t("menu.franchiseApiKey.issueKey.detail.startDate")}
                            value={DataParseService.dateTimeToDate(apikeyInfo['startDate'])}
                            onChange={()=> {}}
                            isForEdit={false}
                            type={"date"}
                            isValid={true}
                            isEnabled={true}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        {/* expire date */}
                        <TitleAndInputBox
                            title={t("menu.franchiseApiKey.detail.expireDate")}
                            value={DataParseService.dateTimeToDate(apikeyInfo['expireDate'])}
                            onChange={()=> {}}
                            isForEdit={false}
                            type={"date"}
                            isValid={true}
                            isEnabled={true}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        {/* web hooks and test buttons */}
                        <WebHookView
                            handleAlertUrlInput={handleAlertUrlInput}
                            handlePaymentCancelUrlInput={handlePaymentCancelUrlInput}
                            requestBody={webHookRequestBody}
                            testBaseUrl={`/franchise/${franchiseId}/key/${keyId}/web-hook/test`}
                            isValidMap={isWebHookValidMap}
                            isEdited={isWebHookEdited}
                        />
                    </Row>
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        {/* save button */}
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'left'
                            }}
                        >
                            {
                                isWebHookEdited && isWebHookValid ?
                                    <SaveButton
                                        onClick={saveWebHook}
                                    >
                                        {t("menu.franchiseApiKey.detail.button.saveUrl")}
                                    </SaveButton>
                                    :
                                    <SaveButtonDisabled>
                                        {t("menu.franchiseApiKey.detail.button.saveUrl")}
                                    </SaveButtonDisabled>
                            }
                        </div>
                    </Row>
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '255px',
                                border: '1px solid #ebebeb',
                                borderRadius: '5px',
                                overflow: 'auto'
                            }}
                        >
                            <ApiKeyExtensionHistoryItemView
                                extensionHistoryList={keyExtensionHistoryList}
                                onRefresh={initializeAllInner}
                                franchiseId={franchiseId}
                            />
                        </div>
                    </Row>
                </Container>
                {/* modals */}
                <ApiKeyExtend
                    isOpen={isKeyExtensionModalOpen}
                    setIsOpen={setIsKeyExtensionModalOpen}
                    onRefresh={initializeAllInner}
                    franchiseId={franchiseId}
                    keyId={keyId}
                />
                {
                    !isLoading &&
                    <ApiKeyEdit
                        isOpen={isKeyEditModalOpen}
                        setIsOpen={setIsKeyEditModalOpen}
                        onRefresh={initializeAllInner}
                        keyInfo={apikeyInfo}
                    />
                }
                {/* delete confirmation */}
                <DeleteConfirmModal
                    isOpen={isDeleteConfirmModalOpen}
                    setIsOpen={setIsDeleteConfirmModalOpen}
                    isDelayed={false}
                    confirmMessage={t("language.message.deletion.confirmation", { entityName : t("language.message.deletion.depsList.franchiseApiKey.self") })}
                    entity={DeleteService.ValidEntities.FRANCHISE_APP_API_KEY}
                    entityName={t("language.message.deletion.depsList.franchiseApiKey.self")}
                    id={`franchise-app-api-key/franchise/${franchiseId}/key/${keyId}`}
                    onDeleteSuccess={async () => {
                        await navigate(`/franchise-app-api/group/${groupId}/franchise/${franchiseId}`);
                    }}
                />
            </div>
        );
    }
};

export default ApiKeyDetailView;