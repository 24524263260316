import React from 'react';
import OverlayToolTip from "../../../../../../menu/common/toolTip/OverlayToolTip";

const HoverMessageCell = ({ value }) => {

    return (
        <OverlayToolTip
            direction={"bottom"}
            text={value[1]}
        >
            <span
                style={{
                    fontSize: '14px',
                    color: '#757575'
                }}
            >
                {value[0]}
            </span>
        </OverlayToolTip>
    );
};

export default HoverMessageCell;