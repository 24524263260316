import {datetime} from "react-table/src/sortTypes";

const formatNumberWithCommas = (num) => {
    if (typeof num === "undefined") {
        return "undefined";
    }
    return num.toLocaleString('en-US');
};

const dateTimeToDate = (datetime) => {
    if (typeof datetime === "undefined" || datetime == null) {
        return "";
    }
    return datetime.split('T')[0];
};

const dateTimeFormat = (datetime) => {
    if (typeof datetime === "undefined" || datetime == null) {
        return "";
    }
    let dateTimeSplit = datetime.split('T');
    let time = dateTimeSplit[1].substring(0, 8);
    return dateTimeSplit[0] + " " + time;
};

const DataParseService = {
    formatNumberWithCommas,
    dateTimeToDate,
    dateTimeFormat
};

export default DataParseService;