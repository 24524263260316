import React from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {CiCircleChevRight} from "react-icons/ci";
import {useNavigate} from "react-router-dom";

const OutContainer = styled.div`
  width: 100%;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  margin-bottom: 10px;
  transition: all ease-in-out 0.2s;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
  &:hover {
    -webkit-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    -moz-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
  }
`;


const PlusButton = styled.button`
  background: none;
  color: #c2c2c2;
  border: none;
  transition: all ease-in-out 0.2s;
  padding: 0px;
  
  &:hover {
    color: #757575;
  }
  
  &:focus {
    outline: none;
  }
`;

const Title = styled.span`
  color: #757575;
  font-weight: bold;
  font-size: 14px;
`;

const Content = styled.span`
  margin-top: 5px;
  color: #c2c2c2;
  font-size: 14px;
`;

const TodoItemView = ({ todoInfoObj }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <OutContainer>
            {/* title and content */}
            <div
                style={{
                    width: '80%',
                    overflow: 'auto',
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    paddingLeft: '10px'
                }}
            >
                <Title>
                    {todoInfoObj[t("language.dataKey.todo.title")]}
                </Title>
                <Content>
                    {todoInfoObj[t("language.dataKey.todo.content")]}
                </Content>
            </div>
            {/* navigate */}
            <div
                style={{
                    width: '20%',
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingRight: '5px'
                }}
            >
                <PlusButton
                    onClick={() => navigate(todoInfoObj['imsGuiPath'])}
                >
                    <CiCircleChevRight size={"25"} />
                </PlusButton>
            </div>
        </OutContainer>
    );
};

export default TodoItemView;