import React from 'react';
import styled from "styled-components";
import DataParseService from "../../../../services/DataParseService";

const VersionItem = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  color: #fc7242;
  padding: 10px;
  border-radius: 10px;
`;

const BoothName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 50px;
  color: #757575;
  margin-right: 15px;
`;

const BoothVersion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  height: 25px;
  font-size: 14px;
  border: 1px solid #a44ce6;
  color: #a44ce6;
  background-color: #fff4ff;
  border-radius: 5px;
`;

const MappedAt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  font-size: 14px;
  height: 50px;
  color: #757575;
`;

const BoothVersionItemView = ({ boothName, boothVersion, mappedAt }) => {

    return (
        <VersionItem>
            {/* booth Name */}
            <BoothName>
                {boothName}
            </BoothName>
            {/* booth version */}
            <BoothVersion>
                {boothVersion}
            </BoothVersion>

            {/* mapped at */}
            <MappedAt>
                {DataParseService.dateTimeFormat(mappedAt)}
            </MappedAt>
        </VersionItem>
    );
};

export default BoothVersionItemView;