import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import UserService from "./services/UserService";
import './language/i18n.js';
import {Spinner} from "react-bootstrap";

//Modal.setAppElement('#root');
const root = ReactDOM.createRoot(document.getElementById('root'));

/** Tab icon config */
const iconLink = document.querySelector("link[rel='icon']");
const iconUrl = `${process.env.REACT_APP_ICON_URL}`;

if (iconLink) {
    iconLink.href = iconUrl;
}

const renderApp = () => root.render(
    <App/>
);

const renderLoading = () => root.render(
    <div
        style={{
            textAlign: 'center',
            padding: '150px'
        }}
    >
        <Spinner />
    </div>
);

UserService.initKeycloak(renderLoading, renderApp);