import React from 'react';
import './ErrorTableStyle.css';
import {useTable} from "react-table";

const ErrorTable = ({ columns, data }) => {

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    return (
        <table {...getTableProps()}>
            <thead
                style={{
                    position: 'sticky',
                    top:'0px'
                }}
            >
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                                return (
                                    <td id='tdComponent-err' key={index} {...cell.getCellProps()}>
                                        <span>{cell.render("Cell")}</span>
                                    </td>
                                );
                            }
                        )}
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default ErrorTable;