import React from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import IntegerFieldView from "./data/IntegerFieldView";
import NumberFieldView from "./data/NumberFieldView";
import CategoricalStringFieldView from "./data/CategoricalStringFieldView";
import BooleanFieldView from "./data/BooleanFieldView";
import StringFieldView from "./data/StringFieldView";


/** string, integer, number, boolean */
const RecipeDataView = ({ title,
                            fieldKey,
                            validator,
                            isCategorical,
                            data,
                            updateData,
                            isValidMap,
                            updateIsValidMap,
                            isForEdit,
                            unitOfValue }) => {

    const { t } = useTranslation();

    if (validator['type'] === 'integer') {
        return (
            <IntegerFieldView
                title={title}
                data={data}
                validator={validator}
                updateData={updateData}
                updateIsValidMap={updateIsValidMap}
                isValid={isValidMap[fieldKey]}
                isForEdit={isForEdit}
                unitOfValue={unitOfValue}
                hasRangeLimit={
                    ((typeof validator['minimum'] !== "undefined") && (typeof validator['maximum'] !== "undefined"))
                        && (validator['minimum'] != null) && (validator['maximum'] != null)
                }
            />
        );
    } else if (validator['type'] === "number") {
        return (
            <NumberFieldView
                title={title + (unitOfValue ? ` (${unitOfValue})` : "")}
                data={data}
                validator={validator}
                updateData={updateData}
                updateIsValidMap={updateIsValidMap}
                isValid={isValidMap[fieldKey]}
                isForEdit={isForEdit}
                unitOfValue={unitOfValue}
            />
        );
    } else if ((validator['type'] === "string") && isCategorical) {
        return (
            <CategoricalStringFieldView
                title={title + (unitOfValue ? ` (${unitOfValue})` : "")}
                data={data}
                validator={validator}
                updateData={updateData}
                updateIsValidMap={updateIsValidMap}
                isValid={isValidMap[fieldKey]}
                isForEdit={isForEdit}
                fieldKey={fieldKey}
            />
        );
    } else if ((validator['type'] === "string") && !isCategorical) {
        return (
            <StringFieldView
                title={title}
                data={data}
                validator={validator}
                updateData={updateData}
                updateIsValidMap={updateIsValidMap}
                isValid={isValidMap[fieldKey]}
                isForEdit={isForEdit}
            />
        );
    } else if (validator['type'] === "boolean") {
        return (
            <BooleanFieldView
                title={title}
                data={data}
                updateData={updateData}
                updateIsValidMap={updateIsValidMap}
                isForEdit={isForEdit}
            />
        );
    }
};

export default RecipeDataView;