import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Col, Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../../common/modal/ModalCloseButton";
import LanguageSelectBox from "../../../common/lang/LanguageSelectBox";
import DataUtils from "../../../menu/common/utils/DataUtils";
import LanguageInput from "../../../../language/component/LanguageInput";
import TitleAndMultiSelectBox from "../../../common/dataView/TitleAndMultiSelectBox";
import TitleAndSelectBox from "../../../common/dataView/TitleAndSelectBox";
import TitleAndInputBox from "../../../common/dataView/TitleAndInputBox";
import styled from "styled-components";


const MessageView = styled.span`
  font-size: 14px;
  color: ${(props) => props.color ? props.color : "#757575"};
`;

/** Below definitions are based on JSON schema (backend) */
const permission_define = {
    1 : "System Master",
    2 : "Franchise",
    4 : "Branch Owner"
};

const origin_define = ["Booth"];

const error_level_define = [
    "Info",
    "Warning",
    "Debug",
    "Error",
    "Critical",
    "Fatal"
];

const errorLevelDefinition = [
    {
        label : "Info",
        value : "Info"
    },
    {
        label : "Warning",
        value : "Warning"
    },
    {
        label : "Debug",
        value : "Debug"
    },
    {
        label : "Error",
        value : "Error"
    },
    {
        label : "Critical",
        value : "Critical"
    },
    {
        label : "Fatal",
        value : "Fatal"
    }
];

const permissionListDefinition = [
    {
        label : "System Master",
        value : 1
    },
    {
        label : "Franchise",
        value : 2
    },
    {
        label : "Branch Owner",
        value : 4
    }
];

const originListDefinition = [
    {
        label : "Booth",
        value : "Booth"
    }
];

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '700px',
        height: '650px',
        margin: 'auto',
        overflow: 'auto'
    }
};

const requestBodyInit = {
    code: "",
    origin: origin_define[0],
    permission: [],
    device: "",
    level: error_level_define[0],
    title: "",
    description: "",
    title_1: "",
    description_1: "",
    title_2: "",
    description_2: "",
    title_3: "",
    description_3: "",
    title_4: "",
    description_4: "",
    title_5: "",
    description_5: ""
};

const isValidMapInit = {
    code: false,
    origin: true,
    permission: false,
    device: false,
    level: true,
    title: false,
    description: false,
    title_1: true,
    description_1: true,
    title_2: true,
    description_2: true,
    title_3: true,
    description_3: true,
    title_4: true,
    description_4: true,
    title_5: true,
    description_5: true
};

/** deprecated */
const BoothErrorCodeAddModal = ({ isOpen, setIsOpen, boothNameId, boothVersionId, deviceListInBooth }) => {

    const { t } = useTranslation();

    /* states */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);

    /* for multi-lang */
    const targetFieldsString = `"${t("menu.booth.version.additional.configErrorCode.field.title")}", "${t("menu.booth.version.additional.configErrorCode.field.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* handlers */
    const updatePermissionCode = (value) => {
        DataUtils.updateHelper(setRequestBody, "permission", value);
        if (value.length > 0) {
            DataUtils.updateHelper(setIsValidMap, "permission", true);
        } else {
            DataUtils.updateHelper(setIsValidMap, "permission", false);
        }
    };

    const updateOrigin = (value) => {
        DataUtils.updateHelper(setRequestBody, "origin", value);
    };

    const updateLevel = (value) => {
        DataUtils.updateHelper(setRequestBody, "level", value);
    };

    const updateDeviceId = (value) => {
        DataUtils.updateHelper(setRequestBody, "device", value);
        /** existence check */
        if (DataUtils.isEmptyStr(value)) {
            DataUtils.updateHelper(setIsValidMap, "device", false);
        } else {
            /** validity check */
            /** TODO */
            DataUtils.updateHelper(setIsValidMap, "device", true);
        }
    };

    const updateCode = (value) => {
        DataUtils.updateHelper(setRequestBody, "code", value);
        /** existence check */
        if (DataUtils.isEmptyStr(value)) {
            DataUtils.updateHelper(setIsValidMap, "code", false);
        } else {
            /** duplication check */
            /** TODO */
            DataUtils.updateHelper(setIsValidMap, "code", true);
        }
    };


    /* initialization */
    const initialize = async () => {
        await setRequestBody(requestBodyInit);
        await setIsValidMap(isValidMapInit);
        await setIsValid(false);
        await setCurrentLang("en-US");
    };

    /* effects */
    useEffect(() => {
        initialize();
    }, [boothVersionId]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.booth.version.additional.configErrorCode.add.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <LanguageSelectBox
                        title={t("language.selectBox.title.menu")}
                        languageMetadataList={languageMetadataList}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        setIsHighlightOn={setIsHighlightOn}
                        targetFieldString={targetFieldsString}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        {/* code */}
                        <TitleAndInputBox
                            title={t("menu.booth.version.additional.configErrorCode.field.code")}
                            value={requestBody["code"]}
                            onChange={updateCode}
                            isForEdit={true}
                            type={"text"}
                            isValid={isValidMap["code"]}
                            isEnabled={true}
                        />
                    </Col>
                    <Col
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        {/* device */}
                        <TitleAndSelectBox
                            title={t("menu.booth.version.additional.configErrorCode.field.device")}

                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* permission */}
                    <TitleAndMultiSelectBox
                        title={t("menu.booth.version.additional.configErrorCode.field.permission")}
                        currentValueList={requestBody["permission"]}
                        valueList={permissionListDefinition}
                        updateCallback={updatePermissionCode}
                        isValid={isValidMap["permission"]}
                        nonSelectMessage={t("menu.booth.version.additional.configErrorCode.message.selectPermission")}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* title */}
                    <LanguageInput
                        type={"text"}
                        title={t("menu.booth.version.additional.configErrorCode.field.title") + ` (${t("language.callName." + currentLang)})`}
                        dataKeyTranslationPath={"language.dataKey.boothErrorCode.title"}
                        valueMap={requestBody}
                        updateIsValidMap={(key, value) => DataUtils.updateHelper(setIsValidMap, key, value)}
                        updateValueMap={(key, value) => DataUtils.updateHelper(setRequestBody, key, value)}
                        isValidMap={isValidMap}
                        isForEdit={true}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* description */}
                    <LanguageInput
                        type={"textarea"}
                        title={t("menu.booth.version.additional.configErrorCode.field.description") + ` (${t("language.callName." + currentLang)})`}
                        dataKeyTranslationPath={"language.dataKey.boothErrorCode.description"}
                        valueMap={requestBody}
                        updateIsValidMap={(key, value) => DataUtils.updateHelper(setIsValidMap, key, value)}
                        updateValueMap={(key, value) => DataUtils.updateHelper(setRequestBody, key, value)}
                        isValidMap={isValidMap}
                        isForEdit={true}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                        height={"100"}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* origin */}
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        <TitleAndSelectBox
                            title={t("menu.booth.version.additional.configErrorCode.field.origin")}
                            innerValue={requestBody["origin"]}
                            valueList={originListDefinition}
                            valueSelector={"value"}
                            viewSelector={"label"}
                            onChange={updateOrigin}
                        />
                    </Col>
                    {/* level */}
                    <Col
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        <TitleAndSelectBox
                            title={t("menu.booth.version.additional.configErrorCode.field.level")}
                            innerValue={requestBody["level"]}
                            valueList={errorLevelDefinition}
                            valueSelector={"value"}
                            viewSelector={"label"}
                            onChange={updateLevel}
                        />
                    </Col>
                </Row>
            </Container>
        </Modal>
    );
};

export default BoothErrorCodeAddModal;