import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import { HiOutlineStatusOnline } from "react-icons/hi";
import OverlayToolTip from "../toolTip/OverlayToolTip";
import BoothMenuSyncStatusView from "../../franchiseMenu/modals/BoothMenuSyncStatusView";

const MenuItemView = styled.div`
  width: 100%;
  margin-top: 5px;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
`;

const MenuItemViewSelected = styled.div`
  width: 100%;
  margin-top: 5px;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  background-color: #fff3e0;
`;

const MenuNameDisabled = styled.span`
  font-size: 14px;
  color: #c2c2c2;
  margin-left: 5px;
`;

const MenuNameEnabled = styled.span`
  font-size: 14px;
  color: #757575;
  margin-left: 5px;
`;

const MenuNameSelected = styled.span`
  font-size: 14px;
  color: #fc7242;
  margin-left: 5px;
`;

const MenuItem = ({ menuName, isEnabled, isOpen }) => {

    const { t } = useTranslation();

    if (isOpen) {
        return (
            <MenuItemViewSelected>
                <div>
                    <MenuNameSelected>
                        {menuName}
                    </MenuNameSelected>
                </div>
            </MenuItemViewSelected>
        );
    } else {
        return (
            <MenuItemView>
                {
                    isEnabled ?
                        <MenuNameEnabled>
                            {menuName}
                        </MenuNameEnabled>
                        :
                        <MenuNameDisabled>
                            {menuName}
                        </MenuNameDisabled>
                }
            </MenuItemView>
        );
    }
};

export default MenuItem;