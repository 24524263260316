import React, {useEffect, useState} from 'react';
import SchemaParser from "../utils/SchemaParser";
import _ from "lodash";
import DataCompareService from "../../../../services/DataCompareService";
import TitleAndTextInput from "./TitleAndTextInput";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import TitleAndCategoricalText from "./TitleAndCategoricalText";
import TitleAndIntegerInput from "./TitleAndIntegerInput";
import TitleAndBoolean from "./TitleAndBoolean";
import ObjectView from "./object/ObjectView";
import ArrayView from "./array/ArrayView";
import BoothService from "../axiosServices/BoothService";
import {Spinner} from "react-bootstrap";
import uuid from "react-uuid";
import ConfirmModal from "../../../common/confirm/ConfirmModal";
import {toast} from "react-toastify";
import AuthService from "../../../../services/AuthService";
import ImsSystemRole from "../../../../auth/roles/ImsSystemRole";

const SaveButton = styled.button`
  width: 30%;
  border: 1px solid #fc7242;
  background-color: white;
  color: #fc7242;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #fff1ec;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  width: 30%;
  border: 1px solid #ebebeb;
  background-color: white;
  color: #ebebeb;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  
  &:focus {
    outline: none;
  }
`;

const DataView = ({ dataSchema, mainKey, sort, requestPath, data, isTitleUsed, onRefresh }) => {
    /* -------------------------------------------- */
    /* NOTE.                                        */
    /* "sort" = ATTRIBUTE | CAPABILITY | CONNECTION */
    /* "data" is nullable                           */
    /* -------------------------------------------- */

    const { t, i18n } = useTranslation();

    /* flags */
    const [isDataExist, setIsDataExist] = useState(true);

    /* request body */
    const [requestBody, setRequestBody] = useState({});
    const [fixedRequestBody, setFixedRequestBody] = useState({});

    /* isValid */
    const [isValidMap, setIsValidMap] = useState({});
    const [isValid, setIsValid] = useState(true);

    /* isEdited */
    const [isEdited, setIsEdited] = useState(false);

    /* isButtonLoading */
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    /* confirmation modal */
    const [isPutConfirmModalOpen, setIsPutConfirmModalOpen] = useState(false);
    const [isPostConfirmModalOpen, setIsPostConfirmModalOpen] = useState(false);

    /* -------------------------------- */
    /* Supported Render Types           */
    /* -- each type means ONLY          */
    /* -- GET type is default for PUT   */
    /* -------------------------------- */
    /* STRING_GET                       */
    /* STRING_PUT                       */
    /* STRING_POST                      */
    /* CAT_STRING_GET                   */
    /* CAT_STRING_PUT                   */
    /* CAT_STRING_POST                  */
    /* INTEGER_GET                      */
    /* INTEGER_PUT                      */
    /* INTEGER_POST                     */
    /* BOOLEAN_GET                      */
    /* BOOLEAN_PUT                      */
    /* BOOLEAN_POST                     */
    /* PRIMITIVE_OBJECT_GET             */
    /* PRIMITIVE_OBJECT_PUT             */
    /* PRIMITIVE_OBJECT_POST            */
    /* PRIMITIVE_ARRAY_GET              */
    /* PRIMITIVE_ARRAY_PUT              */
    /* -------------------------------- */
    /* render types */
    const [renderType, setRenderType] = useState("NONE");

    /* for toast alert */
    const ToastMessage = (text) => {
        return (
            <span
                style={{
                    fontSize: '14px',
                    color: '#757575'
                }}
            >
                {text}
            </span>
        );
    };

    const toastAlert = (text, isSuccess) => {
        if (isSuccess) {
            toast.success(ToastMessage(text));
        } else {
            toast.error(ToastMessage(text));
        }
    };

    /* put data */
    const putData = () => {
        if (!AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "PUT")) {
            toastAlert(t("message.notAllowed"), false);
        }
        const onSuccess = () => {
            setIsButtonLoading(false);
            setIsEdited(false);
            onRefresh();
            toastAlert(t("menu.boothControl.infoMessage.editRequestSuccess"), true);
        };
        const onFailure = (e) => {
            setIsButtonLoading(false);
            toastAlert(t("menu.boothControl.infoMessage.editRequestFail"), false);
        };
        setIsButtonLoading(true);
        BoothService.putBoothDataToEss(requestPath, requestBody, onSuccess, onFailure);
    };

    /* post data */
    const postData = () => {
        if (!AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "POST")) {
            toastAlert(t("message.notAllowed"), false);
        }
        const onSuccess = () => {
            setIsButtonLoading(false);
            setIsEdited(false);
            setIsValid(false);
            onRefresh();
            toastAlert(t("menu.boothControl.infoMessage.controlRequestSuccess"), true);
        };
        const onFailure = (e) => {
            setIsButtonLoading(false);
            setIsEdited(false);
            setIsValid(false);
            toastAlert(t("menu.boothControl.infoMessage.controlRequestFail"), false);
        };
        setIsButtonLoading(true);
        BoothService.postBoothDataToEss(requestPath, requestBody, onSuccess, onFailure);
    };

    /* utils */
    const updateValue = (key, val) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = val;
            return newObj;
        });
    };

    const updateIsValidMap = (key, val) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = val;
            return newObj;
        });
    };

    /* effects */
    /* initialization */
    useEffect(() => {
        /* initialize render type */
        setRenderType(SchemaParser.extractRenderType(dataSchema));
        /* check data exists */
        if (typeof data === "undefined" || data == null) {
            setIsDataExist(false);
        } else {
            setIsDataExist(true);
        }
    }, [dataSchema]);

    useEffect(() => {
        /* initialize requestBody */
        let newRequestBody = {};
        if (isDataExist) {
            newRequestBody[mainKey] = _.cloneDeep(data);
        } else {
            newRequestBody[mainKey] = null;
        }
        setRequestBody(newRequestBody);
        setFixedRequestBody(_.cloneDeep(newRequestBody));

        /* initialize isValidMap */
        let newIsValidMap = {};
        if (isDataExist) {
            newIsValidMap[mainKey] = true;
        } else {
            newIsValidMap[mainKey] = false;
        }
        setIsValidMap(newIsValidMap);
    }, [isDataExist, mainKey, data]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);


    /* ------------------------------ */
    /* main routing                   */
    /* ------------------------------ */
    const renderData = () => {
        if (renderType === "STRING_GET") {
            return (
                <TitleAndTextInput
                    title={
                        isTitleUsed ?
                            dataSchema[t("language.dataKey.boothControl.json.label")]
                            :
                            ""
                    }
                    value={requestBody[mainKey]}
                    isForEdit={false}
                    isValid={true}
                    updateValue={()=>{}}
                    updateIsValid={()=>{}}
                    objectKey={mainKey}
                    validator={dataSchema['validator']}
                    property={dataSchema}
                />
            );
        } else if (renderType === "STRING_PUT") {
            return (
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <TitleAndTextInput
                        title={
                            isTitleUsed ?
                                dataSchema[t("language.dataKey.boothControl.json.label")]
                                :
                                ""
                        }
                        value={requestBody[mainKey]}
                        isForEdit={true}
                        isValid={isValidMap[mainKey]}
                        updateValue={updateValue}
                        updateIsValid={updateIsValidMap}
                        objectKey={mainKey}
                        validator={dataSchema['validator']}
                        property={dataSchema}
                    />
                    <div
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            textAlign: 'right'
                        }}
                    >
                        {
                            isEdited && isValid ?
                                <SaveButton
                                    onClick={() => setIsPutConfirmModalOpen(true)}
                                >
                                    {
                                        isButtonLoading ?
                                            <Spinner size={"sm"} />
                                            :
                                            t("menu.boothControl.button.sendRequest")
                                    }
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("menu.boothControl.button.sendRequest")}
                                </SaveButtonDisabled>
                        }
                    </div>
                </div>
            );
        } else if (renderType === "STRING_POST") {
            return (
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <TitleAndTextInput
                        title={
                            isTitleUsed ?
                                dataSchema[t("language.dataKey.boothControl.json.label")]
                                :
                                ""
                        }
                        value={requestBody[mainKey]}
                        isForEdit={true}
                        isValid={isValidMap[mainKey]}
                        updateValue={updateValue}
                        updateIsValid={updateIsValidMap}
                        objectKey={mainKey}
                        validator={dataSchema['validator']}
                        property={dataSchema}
                    />
                    <div
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            textAlign: 'right'
                        }}
                    >
                        {
                            isValid ?
                                <SaveButton
                                    onClick={() => setIsPostConfirmModalOpen(true)}
                                >
                                    {
                                        isButtonLoading ?
                                            <Spinner size={"sm"} />
                                            :
                                            t("menu.boothControl.button.sendControl")
                                    }
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("menu.boothControl.button.sendControl")}
                                </SaveButtonDisabled>
                        }
                    </div>
                </div>
            );
        } else if (renderType === "CAT_STRING_GET") {
            return (
                <TitleAndCategoricalText
                    title={
                        isTitleUsed ?
                            dataSchema[t("language.dataKey.boothControl.json.label")]
                            :
                            ""
                    }
                    value={requestBody[mainKey]}
                    isForEdit={false}
                    updateValue={()=>{}}
                    updateIsValid={()=>{}}
                    objectKey={mainKey}
                    validator={dataSchema['validator']}
                />
            );
        } else if (renderType === "CAT_STRING_PUT") {
            return (
                <div>
                    <TitleAndCategoricalText
                        title={
                            isTitleUsed ?
                                dataSchema[t("language.dataKey.boothControl.json.label")]
                                :
                                ""
                        }
                        value={requestBody[mainKey]}
                        isForEdit={true}
                        updateValue={updateValue}
                        updateIsValid={updateIsValidMap}
                        objectKey={mainKey}
                        validator={dataSchema['validator']}
                    />
                    <div
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            textAlign: 'right'
                        }}
                    >
                        {
                            isValid && isEdited ?
                                <SaveButton
                                    onClick={() => setIsPutConfirmModalOpen(true)}
                                >
                                    {
                                        isButtonLoading ?
                                            <Spinner size={"sm"} />
                                            :
                                            t("menu.boothControl.button.sendRequest")
                                    }
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("menu.boothControl.button.sendRequest")}
                                </SaveButtonDisabled>
                        }
                    </div>
                </div>
            );
        } else if (renderType === "CAT_STRING_POST") {
            return (
                <div>
                    <TitleAndCategoricalText
                        title={
                            isTitleUsed ?
                                dataSchema[t("language.dataKey.boothControl.json.label")]
                                :
                                ""
                        }
                        value={requestBody[mainKey]}
                        isForEdit={true}
                        updateValue={updateValue}
                        updateIsValid={updateIsValidMap}
                        objectKey={mainKey}
                        validator={dataSchema['validator']}
                    />
                    <div
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            textAlign: 'right'
                        }}
                    >
                        {
                            isValid ?
                                <SaveButton
                                    onClick={() => setIsPostConfirmModalOpen(true)}
                                >
                                    {
                                        isButtonLoading ?
                                            <Spinner size={"sm"} />
                                            :
                                            t("menu.boothControl.button.sendControl")
                                    }
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("menu.boothControl.button.sendControl")}
                                </SaveButtonDisabled>
                        }
                    </div>
                </div>
            );
        } else if (renderType === "INTEGER_GET") {
            return (
                <TitleAndIntegerInput
                    title={
                        isTitleUsed ?
                            dataSchema[t("language.dataKey.boothControl.json.label")]
                            :
                            ""
                    }
                    value={requestBody[mainKey]}
                    isForEdit={false}
                    updateValue={()=>{}}
                    updateIsValid={()=>{}}
                    objectKey={mainKey}
                    validator={dataSchema['validator']}
                    unitOfValue={dataSchema['unitOfValue']}
                    property={dataSchema}
                />
            );
        } else if (renderType === "INTEGER_PUT") {
            return (
                <div>
                    <TitleAndIntegerInput
                        title={
                            isTitleUsed ?
                                dataSchema[t("language.dataKey.boothControl.json.label")]
                                :
                                ""
                        }
                        value={requestBody[mainKey]}
                        isForEdit={true}
                        updateValue={updateValue}
                        updateIsValid={updateIsValidMap}
                        objectKey={mainKey}
                        validator={dataSchema['validator']}
                        unitOfValue={dataSchema['unitOfValue']}
                        property={dataSchema}
                    />
                    <div
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            textAlign: 'right'
                        }}
                    >
                        {
                            isValid && isEdited ?
                                <SaveButton
                                    onClick={() => setIsPutConfirmModalOpen(true)}
                                >
                                    {
                                        isButtonLoading ?
                                            <Spinner size={"sm"} />
                                            :
                                            t("menu.boothControl.button.sendRequest")
                                    }
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("menu.boothControl.button.sendRequest")}
                                </SaveButtonDisabled>
                        }
                    </div>
                </div>
            );
        } else if (renderType === "INTEGER_POST") {
            return (
                <div>
                    <TitleAndIntegerInput
                        title={
                            isTitleUsed ?
                                dataSchema[t("language.dataKey.boothControl.json.label")]
                                :
                                ""
                        }
                        value={requestBody[mainKey]}
                        isForEdit={true}
                        updateValue={updateValue}
                        updateIsValid={updateIsValidMap}
                        objectKey={mainKey}
                        validator={dataSchema['validator']}
                        unitOfValue={dataSchema['unitOfValue']}
                        property={dataSchema}
                    />
                    <div
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            textAlign: 'right'
                        }}
                    >
                        {
                            isValid ?
                                <SaveButton
                                    onClick={() => setIsPostConfirmModalOpen(true)}
                                >
                                    {
                                        isButtonLoading ?
                                            <Spinner size={"sm"} />
                                            :
                                            t("menu.boothControl.button.sendControl")
                                    }
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("menu.boothControl.button.sendControl")}
                                </SaveButtonDisabled>
                        }
                    </div>
                </div>
            );
        } else if (renderType === "BOOLEAN_GET") {
            return (
                <TitleAndBoolean
                    title={
                        isTitleUsed ?
                            dataSchema[t("language.dataKey.boothControl.json.label")]
                            :
                            ""
                    }
                    value={requestBody[mainKey]}
                    isForEdit={false}
                    updateValue={()=>{}}
                    updateIsValid={()=>{}}
                    objectKey={mainKey}
                />
            );
        } else if (renderType === "BOOLEAN_PUT") {
            return (
                <div>
                    <TitleAndBoolean
                        title={
                            isTitleUsed ?
                                dataSchema[t("language.dataKey.boothControl.json.label")]
                                :
                                ""
                        }
                        value={requestBody[mainKey]}
                        isForEdit={true}
                        updateValue={updateValue}
                        updateIsValid={updateIsValidMap}
                        objectKey={mainKey}
                    />
                    <div
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            textAlign: 'right'
                        }}
                    >
                        {
                            isValid && isEdited ?
                                <SaveButton
                                    onClick={() => setIsPutConfirmModalOpen(true)}
                                >
                                    {
                                        isButtonLoading ?
                                            <Spinner size={"sm"} />
                                            :
                                            t("menu.boothControl.button.sendRequest")
                                    }
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("menu.boothControl.button.sendRequest")}
                                </SaveButtonDisabled>
                        }
                    </div>
                </div>
            );
        } else if (renderType === "BOOLEAN_POST") {
            return (
                <div>
                    <TitleAndBoolean
                        title={
                            isTitleUsed ?
                                dataSchema[t("language.dataKey.boothControl.json.label")]
                                :
                                ""
                        }
                        value={requestBody[mainKey]}
                        isForEdit={true}
                        updateValue={updateValue}
                        updateIsValid={updateIsValidMap}
                        objectKey={mainKey}
                    />
                    <div
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            textAlign: 'right'
                        }}
                    >
                        {
                            isValid ?
                                <SaveButton
                                    onClick={() => setIsPostConfirmModalOpen(true)}
                                >
                                    {
                                        isButtonLoading ?
                                            <Spinner size={"sm"} />
                                            :
                                            t("menu.boothControl.button.sendControl")
                                    }
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("menu.boothControl.button.sendControl")}
                                </SaveButtonDisabled>
                        }
                    </div>
                </div>
            );
        } else if (renderType === "PRIMITIVE_OBJECT_GET") {
            return (
                <ObjectView
                    key={mainKey}
                    isTitleUsed={isTitleUsed}
                    title={
                        isTitleUsed ?
                            dataSchema[t("language.dataKey.boothControl.json.label")]
                            :
                            ""
                    }
                    objValue={requestBody[mainKey]}
                    isForEdit={false}
                    updateValue={()=>{}}
                    updateIsValid={()=>{}}
                    mainKey={mainKey}
                    validator={dataSchema['validator']}
                    properties={dataSchema['properties']}
                />
            );
        } else if (renderType === "PRIMITIVE_OBJECT_PUT") {
            return (
                <div
                    key={mainKey}
                >
                    <ObjectView
                        isTitleUsed={isTitleUsed}
                        title={
                            isTitleUsed ?
                                dataSchema[t("language.dataKey.boothControl.json.label")]
                                :
                                ""
                        }
                        objValue={requestBody[mainKey]}
                        isForEdit={true}
                        updateValue={updateValue}
                        updateIsValid={updateIsValidMap}
                        mainKey={mainKey}
                        validator={dataSchema['validator']}
                        properties={dataSchema['properties']}
                    />
                    <div
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            textAlign: 'right'
                        }}
                    >
                        {
                            isValid && isEdited ?
                                <SaveButton
                                    onClick={() => setIsPutConfirmModalOpen(true)}
                                >
                                    {
                                        isButtonLoading ?
                                            <Spinner size={"sm"} />
                                            :
                                            t("menu.boothControl.button.sendRequest")
                                    }
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("menu.boothControl.button.sendRequest")}
                                </SaveButtonDisabled>
                        }
                    </div>
                </div>
            );
        } else if (renderType === "PRIMITIVE_OBJECT_POST") {
            return (
                <div
                    key={mainKey}
                >
                    <ObjectView
                        isTitleUsed={isTitleUsed}
                        title={
                            isTitleUsed ?
                                dataSchema[t("language.dataKey.boothControl.json.label")]
                                :
                                ""
                        }
                        objValue={requestBody[mainKey]}
                        isForEdit={true}
                        updateValue={updateValue}
                        updateIsValid={updateIsValidMap}
                        mainKey={mainKey}
                        validator={dataSchema['validator']}
                        properties={dataSchema['properties']}
                    />
                    <div
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            textAlign: 'right'
                        }}
                    >
                        {
                            isValid ?
                                <SaveButton
                                    onClick={() => setIsPostConfirmModalOpen(true)}
                                >
                                    {
                                        isButtonLoading ?
                                            <Spinner size={"sm"} />
                                            :
                                            t("menu.boothControl.button.sendControl")
                                    }
                                </SaveButton>
                                :
                                <SaveButtonDisabled>
                                    {t("menu.boothControl.button.sendControl")}
                                </SaveButtonDisabled>
                        }
                    </div>
                </div>
            );
        } else if (renderType === "PRIMITIVE_ARRAY_GET") {
            return (
                <ArrayView
                    key={mainKey}
                    isTitleUsed={isTitleUsed}
                    title={
                        isTitleUsed ?
                            dataSchema[t("language.dataKey.boothControl.json.label")]
                            :
                            ""
                    }
                    arrayValue={requestBody[mainKey]}
                    isForEdit={false}
                    updateValue={updateValue}
                    updateIsValid={updateIsValidMap}
                    mainKey={mainKey}
                    validator={dataSchema['validator']}
                    itemProperty={dataSchema['items']}
                />
            );
        } else if (renderType === "PRIMITIVE_ARRAY_PUT") {
            /* ArrayView component will handle axios call inside itself. */
            return (
                <ArrayView
                    key={mainKey}
                    isTitleUsed={isTitleUsed}
                    title={
                        isTitleUsed ?
                            dataSchema[t("language.dataKey.boothControl.json.label")]
                            :
                            ""
                    }
                    arrayValue={requestBody[mainKey]}
                    isForEdit={true}
                    updateValue={updateValue}
                    updateIsValid={updateIsValidMap}
                    mainKey={mainKey}
                    validator={dataSchema['validator']}
                    itemProperty={dataSchema['items']}
                />
            );
        } else { // renderType === "NONE"
            return (
                <span
                    style={{
                        color : '#757575'
                    }}
                >
                    {t("menu.boothControl.infoMessage.notSupport")}
                </span>
            );
        }
    }

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {renderData()}
            {/* confirmation */}
            <ConfirmModal
                isOpen={isPutConfirmModalOpen}
                setIsOpen={setIsPutConfirmModalOpen}
                title={t("menu.boothControl.infoMessage.sureToPut")}
                confirmLabel={t("button.yes")}
                rejectLabel={t("button.no")}
                onConfirm={putData}
                onReject={() => {}}
            />
            <ConfirmModal
                isOpen={isPostConfirmModalOpen}
                setIsOpen={setIsPostConfirmModalOpen}
                title={t("menu.boothControl.infoMessage.sureToPost")}
                confirmLabel={t("button.yes")}
                rejectLabel={t("button.no")}
                onConfirm={postData}
                onReject={() => {}}
            />
        </div>
    );
};

export default DataView;