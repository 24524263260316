import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import DataCompareService from "../../services/DataCompareService";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import DataParseService from "../../services/DataParseService";
import AuthService from "../../services/AuthService";
import RenderOnRole from "../../auth/RenderOnRole";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const requestBodyInit = {
    boardTitle : "",
    boardContent : "",
    boardGroupId : ""
};

const isValidMapInit = {
    boardTitle : true,
    boardContent : true,
    boardGroupId : true
};

const BoardEdit = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { boardId } = useParams("boardId");
    /* board information */
    const [boardInfo, setBoardInfo] = useState({});
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    /* isValid, isEdited */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);

    /* fetch board information */
    const fetchBoardDetail = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/board/${boardId}`;
            const axiosCall = () => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            setBoardInfo(res.data.item.boardDetail);
            let requestDataObj = {
                boardTitle : res.data.item.boardDetail.boardTitle,
                boardContent : res.data.item.boardDetail.boardContent,
                boardGroupId : res.data.item.boardDetail.boardGroupId
            };
            setRequestBody(DataCompareService.deepCopyObj(requestDataObj));
            setFixedRequestBody(DataCompareService.deepCopyObj(requestDataObj));
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    /* put board */
    const putBoardDetail = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/board/${boardId}`;
            const axiosCall = () => axios.put(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate(`/board/detail/${boardId}`);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* utils */
    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* handlers */
    const handleBoardTitleInput = (value) => {
        updateRequestBody("boardTitle", value);
    };

    const handleBoardContentInput = (value) => {
        updateRequestBody("boardContent", value);
    };

    /* validators */
    const validateBoardTitle = () => {
        if (requestBody["boardTitle"].length < 3 || requestBody["boardTitle"].length > 70) {
            updateIsValidMap("boardTitle", false);
        } else {
            updateIsValidMap("boardTitle", true);
        }
    };

    const validateBoardContent = () => {
        if (requestBody["boardContent"] === "") {
            updateIsValidMap("boardContent", false);
        } else {
            updateIsValidMap("boardContent", true);
        }
    };

    /* effects */
    useEffect(() => {
        fetchBoardDetail();
    }, []);

    /* input values */
    useEffect(() => {
        validateBoardTitle();
    }, [requestBody["boardTitle"]]);

    useEffect(() => {
        validateBoardContent();
    }, [requestBody["boardContent"]]);

    /* isEdited, isValid */
    useEffect(() => {
        setIsEdited(!DataCompareService.isSameObj(requestBody, fixedRequestBody));
    }, [requestBody]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);


    return (
        <RenderOnRole type={"PUT"} roleName={ImsSystemRole.BOARD_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={`/board/detail/${boardId}`}
                />
                <PageTitleWithDepth
                    titles={[t("menu.board.title"), t("menu.board.detail.title"), t("menu.board.edit.title")]}
                    depth={3}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                <Container fluid>
                    <Row>
                        {/* main area */}
                        <div
                            style={{
                                width: '100%',
                                height: '700px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '650px',
                                    display: 'flex',
                                    overflow: 'auto',
                                    padding: '10px 0px 20px 0px'
                                }}
                            >
                                <Container fluid>
                                    <Row>
                                        <TitleAndInputBox
                                            title={t("common.title")}
                                            value={requestBody["boardTitle"]}
                                            isForEdit={true}
                                            type={"text"}
                                            isValid={isValidMap["boardTitle"]}
                                            onChange={handleBoardTitleInput}
                                        />
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <Col
                                            style={{
                                                padding: '0px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.group")}
                                                value={boardInfo.boardGroupName}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                        <Col
                                            style={{
                                                padding: '0px',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.adminId")}
                                                value={boardInfo.boardAdminId ? boardInfo.boardAdminId : t("common.noInfo")}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <TitleAndTextArea
                                            title={t("common.boardContent")}
                                            value={requestBody["boardContent"]}
                                            onChange={handleBoardContentInput}
                                            isForEdit={true}
                                            isValid={isValidMap["boardContent"]}
                                            height={"390"}
                                        />
                                    </Row>
                                    <Row>
                                        <div
                                            style={{
                                                textAlign: 'right',
                                                width: '100%'
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: '#757575',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {
                                                    t("common.generatedAt")
                                                    + " "
                                                    + DataParseService.dateTimeToDate(boardInfo.generateDate)
                                                    + " , "
                                                    + t("common.lastEditedAt")
                                                    + " "
                                                    + DataParseService.dateTimeToDate(boardInfo.lastEditDate)
                                                }
                                            </span>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                            {/* bottom btn */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'right'
                                }}
                            >
                                <div
                                    style={{ marginRight: '10px' }}
                                >
                                    <Link
                                        id={"edit-btn"}
                                        to={`/board/detail/${boardId}`}
                                    >
                                        {t("button.cancel")}
                                    </Link>
                                </div>
                                <div>
                                    {
                                        (isEdited && isValid)
                                            ?
                                            <Link
                                                id={"save-btn"}
                                                onClick={() => putBoardDetail()}
                                            >
                                                {t("button.save")}
                                            </Link>
                                            :
                                            <Link
                                                id={"save-btn-disabled"}
                                            >
                                                {t("button.save")}
                                            </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default BoardEdit;