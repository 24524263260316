import React from 'react';
import styled from "styled-components";

const OuterContainer = styled.button`
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding: 7px 10px;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  background-color: ${(props) => props.isSelected ? "#ffeee6" : "white"};
  border: ${(props) => props.isSelected ? "1px solid #ffeee6" : "1px solid #ebebeb"};
  margin-bottom: 10px;
  transition: all ease-in-out 0.2s;
  -webkit-box-shadow: ${(props) => props.isSelected ? "" : "0px 0px 3px 0px rgba(105,105,105,0.36)"};
  -moz-box-shadow: ${(props) => props.isSelected ? "" : "0px 0px 3px 0px rgba(105,105,105,0.36)"};
  box-shadow: ${(props) => props.isSelected ? "" : "0px 0px 3px 0px rgba(105,105,105,0.36)"};
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    -webkit-box-shadow: 0px 0px 9px 0px rgba(105,105,105,0.36);
    -moz-box-shadow: 0px 0px 9px 0px rgba(105,105,105,0.36);
    box-shadow: 0px 0px 9px 0px rgba(105,105,105,0.36);
  }
`;

const BoothName = styled.span`
  font-size: 14px;
  color: ${(props) => props.isEnabled ? "#757575" : "#c2c2c2"};
`;

const BoothVersion = styled.div`
  display: inline-block;
  border-radius: 5px;
  margin-left: auto;
  color: #3399ff;
  border: 1px solid #3399ff;
  font-size: 14px;
  padding: 3px 5px;
  background-color: #e6f2ff;
`;

const BoothVersionItemView = ({ boothName, boothVersion, onClickCallback, isSelected, isEnabled }) => {

    return (
        <OuterContainer
            isSelected={isSelected}
            onClick={onClickCallback}
        >
            <BoothName
                isEnabled={isEnabled}
            >
                {boothName}
            </BoothName>
            <BoothVersion>
                {boothVersion}
            </BoothVersion>
        </OuterContainer>
    );
};

export default BoothVersionItemView;