import React from 'react';

const PageTitleWithDepth = ({ titles, depth }) => {

    const renderTitle = () => {
        let titleElement = []
        for (let i=0 ; i<(depth - 1) ; i++) {
            titleElement.push(
                <span
                    style={{
                        color: '#757575',
                        fontSize: '17px',
                        marginLeft: '10px'
                    }}
                >
                    {
                        titles[i] + " /"
                    }
                </span>
            )
        }
        /* last title */
        titleElement.push(
            <span
                style={{
                    fontWeight: 'bold',
                    fontSize: '17px',
                    marginLeft: '10px'
                }}
            >
                    {
                        titles[depth - 1]
                    }
                </span>
        );
        return titleElement;
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '20px'
            }}
        >
            {renderTitle()}
        </div>
    );
};

export default PageTitleWithDepth;