import React, {useEffect, useState} from 'react';
import Modal from "react-modal";
import {useTranslation} from "react-i18next";
import {Container, Row, Spinner} from "react-bootstrap";
import ModalCloseButton from "../../../common/modal/ModalCloseButton";
import styled from "styled-components";
import JsonService from "../../detail/axiosServices/JsonService";
import ErrorCodeJsonValidateService from "./service/ErrorCodeJsonValidateService";
import TitleAndTextArea from "../../../common/dataView/TitleAndTextArea";
import './BoothErrorCodeConfigModalStyle.css';
import { BsFiletypeJson } from "react-icons/bs";
import {toast} from "react-toastify";
import ToastAlertView from "../../../common/alert/ToastAlertView";
import axios from "axios";
import UserService from "../../../../services/UserService";
import ConfirmModal from "../../../common/confirm/ConfirmModal";
import BoothErrorCodeAddModal from "./BoothErrorCodeAddModal";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '850px',
        height: '700px',
        margin: 'auto'
    }
};

const colorMap = {
    "invalid" : "red",
    "good" : "blue",
    normal : "#757575"
};

const JsonInitButton = styled.button`
  font-size: 14px;
  padding: 4px 10px;
  color: #fc7242;
  background-color: ${(props) => props.selected ? "#FFF1EC" : "white"};
  border: 1px solid #fc7242;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #FFF1EC;
  }
`;

const BoothErrorCodeAddButton = styled.button`
  font-size: 14px;
  padding: 4px 10px;
  color: #fc7242;
  background-color: white;
  border: 1px solid #fc7242;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #FFF1EC;
  }
`;

const UploadButton = styled.button`
  font-size: 14px;
  padding: 7px 15px;
  color: ${(props) => props.isActive ? "#fc7242" : "#ebebeb"};
  background-color: white;
  transition: all ease-in-out 0.2s;
  border: 1px solid ${(props) => props.isActive ? "#fc7242" : "#ebebeb"};
  border-radius: 10px;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.isActive ? "#FFF1EC" : "white"};
  }
`;

/** only JSON config enabled */
const BoothErrorCodeConfigModal = ({ isOpen, setIsOpen, boothNameId, boothVersionId, boothJsonVersion }) => {

    const { t } = useTranslation();

    /** always true */
    const [isJsonInputMode, setIsJsonInputMode] = useState(true);

    /* for json */
    const [validDeviceList, setValidDeviceList] = useState([]);
    const [inputJsonStr, setInputJsonStr] = useState("");
    const [isInputJsonValid, setIsInputJsonValid] = useState(true);
    const [jsonValidateMessage, setJsonValidateMessage] = useState("");
    const [isActive, setActive] = useState(false);
    const [fileName, setFileName] = useState("");
    const [messageColor, setMessageColor] = useState("#757575");

    /* fot table */
    const [deviceInfoList, setDeviceInfoList] = useState([]);
    const [errorCodeList, setErrorCodeList] = useState([]);

    /* loading */
    const [isJsonLoading, setIsJsonLoading] = useState(false);
    const [isValidationLoading, setIsValidationLoading] = useState(false);
    const [isInitSaveLoading, setIsInitSaveLoading] = useState(false);
    const [isErrorCodeFetchLoading, setIsErrorCodeFetchLoading] = useState(false);

    /* confirmation */
    const [isInitConfirmOpen, setIsInitConfirmOpen] = useState(false);

    /* add a new error code */
    const [isErrorCodeAddModalOpen, setIsErrorCodeAddModalOpen] = useState(false);

    /* fetch error code list */
    const fetchErrorCodeList = async () => {
        await setIsErrorCodeFetchLoading(true);
        await setIsErrorCodeFetchLoading(false);
    };

    /* fetch device list */
    const fetchValidDeviceList = async () => {
        await setIsJsonLoading(true);
        const callback = async (data, total) => {
            await setDeviceInfoList(total);
            await setValidDeviceList(data);
        };
        await JsonService.getBoothDeviceListInJson(boothJsonVersion, callback);
        await setIsJsonLoading(false);
    };

    /* save init error code */
    const initErrorCodeWithJson = async () => {
        await setIsInitSaveLoading(true);
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                        + `/booth-version`
                        + `/name/${boothNameId}`
                        + `/version/${boothVersionId}`
                        + `/error-code/json`;
        const axiosCall = () => axios.post(
            fetchUrl,
            inputJsonStr,
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        try {
            await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")}/>);
            await initialize(false);
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")}/>);
        }
        await setIsInitSaveLoading(false);
    };

    /* file input event handler */
    const handleFileRead = async (file) => {
        if (file && file.type === "application/json") {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileContents = e.target.result;
                handleJsonInput(fileContents);
            };
            await reader.readAsText(file);
            await setFileName(file.name);
        } else {
            toast.error(<ToastAlertView message={t("menu.booth.version.additional.configErrorCode.message.file.plzJson")} />);
        }
    };

    /* update and validate input json */
    const handleJsonInput = async (value) => {
        await setIsValidationLoading(true);
        await setInputJsonStr(value);
        let res = await ErrorCodeJsonValidateService.validateErrorCodeJson(value, validDeviceList);
        if (res['isValid']) {
            await setIsInputJsonValid(true);
            await setJsonValidateMessage("");
            await setMessageColor(colorMap["good"]);
        } else {
            await setIsInputJsonValid(false);
            await setMessageColor(colorMap["invalid"]);
            if (res['reason'] === "PARSE_FAIL") {
                await setJsonValidateMessage(t("menu.booth.version.additional.configErrorCode.message.PARSE_FAIL"));
            } else if (res['reason'] === "VALIDATE_FAIL") {
                await setJsonValidateMessage(t("menu.booth.version.additional.configErrorCode.message.VALIDATE_FAIL"));
            } else if (res['reason'] === "DUPLICATE_CODE") {
                await setJsonValidateMessage(t("menu.booth.version.additional.configErrorCode.message.DUPLICATE_CODE", { code : res['invalidData'] }));
            } else if (res['reason'] === "INVALID_DEVICE_ID") {
                await setJsonValidateMessage(t("menu.booth.version.additional.configErrorCode.message.INVALID_DEVICE_ID", { deviceId : res['invalidData'] }));
            }
        }
        await setIsValidationLoading(false);
    };

    /* initialize states */
    const initialize = async (isModeInit) => {
        await fetchValidDeviceList();
        await fetchErrorCodeList();
        await setInputJsonStr("");
        await setIsInputJsonValid(true);
        await setIsInputJsonValid(true);
        await setFileName("");
        await setMessageColor("#757575");
    };

    const handleDragStart = () => setActive(true);
    const handleDragEnd = () => setActive(false);
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setActive(false);
        const file = event.dataTransfer.files[0];
        handleFileRead(file);
    };

    const handleUpload = ({ target }) => {
        const file = target.files[0];
        handleFileRead(file);
    };

    useEffect(() => {
        initialize();
    }, [boothNameId, boothVersionId]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.booth.version.additional.configErrorCode.button")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                {/** deprecated */}
                {/*<Row>*/}
                {/*    /!* json init button area *!/*/}
                {/*    <div*/}
                {/*        style={{*/}
                {/*            width:'100%',*/}
                {/*            textAlign: 'right'*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <JsonInitButton*/}
                {/*            selected={isJsonInputMode}*/}
                {/*            onClick={() => setIsJsonInputMode(!isJsonInputMode)}*/}
                {/*        >*/}
                {/*            {t("menu.booth.version.additional.configErrorCode.jsonInit")}*/}
                {/*        </JsonInitButton>*/}
                {/*    </div>*/}
                {/*</Row>*/}
                {
                    isJsonInputMode &&
                    isJsonLoading &&
                    <div
                        style={{
                            width: '100%',
                            padding: '20px',
                            textAlign: 'center'
                        }}
                    >
                        {/* loading */}
                        <Spinner size={"sm"} />
                    </div>
                }
                {
                    isJsonInputMode &&
                    (!isJsonLoading) &&
                    (validDeviceList.length === 0) &&
                    <Row>
                        {/* no device list */}
                        <div
                            style={{
                                width: '100%',
                                padding: '50px',
                                fontSize: '14px',
                                color: '#757575',
                                textAlign: 'center'
                            }}
                        >
                            {t("menu.booth.version.additional.configErrorCode.message.noDeviceGiven")}
                        </div>
                    </Row>
                }
                {
                    isJsonInputMode &&
                    (!isJsonLoading) &&
                    (validDeviceList.length > 0) &&
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        {/* json input */}
                        <label
                            className={`preview${isActive ? ' active' : ''}`}
                            onDragEnter={handleDragStart}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragEnd}
                            onDrop={handleDrop}
                        >
                            <input type="file" className="file" accept={".json"} onChange={handleUpload} />
                            <p
                                style={{
                                    margin: '5px 0px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <BsFiletypeJson className={`file_logo${isActive ? ' active' : ''}`} size={"45"} />
                            </p>
                            <p className="preview_msg">{t("menu.booth.version.additional.configErrorCode.message.file.uploadMessage")}</p>
                            <p className="preview_desc">{t("menu.booth.version.additional.configErrorCode.message.file.maxNum")}</p>
                            <p className="file_name">
                                {
                                    (fileName === "") ?
                                        ""
                                        :
                                        fileName
                                }
                            </p>
                        </label>
                        <TitleAndTextArea
                            title={t("menu.booth.version.additional.configErrorCode.field.jsonStr")}
                            value={inputJsonStr}
                            isForEdit={false}
                            onChange={() => {}}
                            height={"270"}
                            isValid={isInputJsonValid}
                            placeHolder={t("menu.booth.version.additional.configErrorCode.field.notUploaded")}
                        />
                        <span
                            className={"message-json"}
                            style={{
                                color: messageColor
                            }}
                        >
                            {jsonValidateMessage}
                        </span>
                    </Row>
                }
                {
                    isJsonInputMode &&
                    (!isJsonLoading) &&
                    (validDeviceList.length > 0) &&
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        {/* json message and save buttons */}
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'right'
                            }}
                        >
                            <UploadButton
                                onClick={() => {
                                    if (isInputJsonValid && (!isJsonLoading) && (!isValidationLoading) && (inputJsonStr !== "") && (!isInitSaveLoading)) {
                                        setIsInitConfirmOpen(true);
                                    }
                                }}
                                isActive={isInputJsonValid && (!isJsonLoading) && (!isValidationLoading) && (inputJsonStr !== "") && (!isInitSaveLoading)}
                            >
                                {
                                    isInitSaveLoading ?
                                        <Spinner size={"sm"} />
                                        :
                                        t("button.save")
                                }
                            </UploadButton>
                        </div>
                    </Row>
                }
                {
                    (!isJsonInputMode) &&
                    (isErrorCodeFetchLoading) &&
                    <Row>
                        {/* fetch loading */}
                        <div
                            style={{
                                width: '100%',
                                padding: '20px',
                                textAlign: 'center'
                            }}
                        >
                            {/* loading */}
                            <Spinner size={"sm"} />
                        </div>
                    </Row>
                }
                {
                    (!isJsonInputMode) &&
                    (!isErrorCodeFetchLoading) &&
                    (errorCodeList.length > 0) &&
                    <Row>
                        {/* add error code add button when prev existing */}
                        <div
                            style={{
                                width: '100%',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >

                        </div>
                    </Row>
                }
                {
                    (!isJsonInputMode) &&
                    (!isErrorCodeFetchLoading) &&
                    (errorCodeList.length === 0) &&
                    <Row>
                        {/* empty error code */}
                        <div
                            style={{
                                width: '100%',
                                padding: '50px 0px 20px 0px',
                                fontSize: '14px',
                                color: '#757575',
                                textAlign: 'center'
                            }}
                        >
                            {t("menu.booth.version.additional.configErrorCode.message.noErrorCodeGiven")}
                        </div>
                        {/* add error code add button when prev NOT existing */}
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center'
                            }}
                        >
                            <BoothErrorCodeAddButton
                                onClick={() => setIsErrorCodeAddModalOpen(true)}
                            >
                                {t("menu.booth.version.additional.configErrorCode.add.title")}
                            </BoothErrorCodeAddButton>
                        </div>
                    </Row>
                }
                {
                    (!isJsonInputMode) &&
                    (!isErrorCodeFetchLoading) &&
                    (errorCodeList.length > 0) &&
                    <Row>
                        {/* search field area */}
                        search box
                    </Row>
                }
                {
                    (!isJsonInputMode) &&
                    (!isErrorCodeFetchLoading) &&
                    (errorCodeList.length > 0) &&
                    <Row>
                        {/* table area */}
                        table
                    </Row>
                }

            </Container>
            <ConfirmModal
                isOpen={isInitConfirmOpen}
                setIsOpen={setIsInitConfirmOpen}
                confirmLabel={t("button.yes")}
                rejectLabel={t("button.no")}
                onConfirm={initErrorCodeWithJson}
                onReject={()=>{}}
                title={t("menu.booth.version.additional.configErrorCode.message.file.sureToInit")}
            />
            {
                (deviceInfoList.length > 0) &&
                <BoothErrorCodeAddModal
                    isOpen={isErrorCodeAddModalOpen}
                    setIsOpen={setIsErrorCodeAddModalOpen}
                    boothNameId={boothNameId}
                    boothVersionId={boothVersionId}
                    deviceListInBooth={deviceInfoList}
                />
            }
        </Modal>
    );
};

export default BoothErrorCodeConfigModal;