import axios from "axios";
import UserService from "../../../../services/UserService";

const baseUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/booth-control-wrapper`;

const _extractIds = (objList) => {
    let idList = [];
    objList.map(obj => {
       idList.push(obj['metadata']['id']);
    });
    return idList;
}


const fetchBoothJsonSchema = async (schemaVersion, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${baseUrl}/schema/${schemaVersion}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack(res.data.item.booth);
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

/** if fails, an empty array will be return */
const getBoothDeviceListInJson = async (schemaVersion, callback) => {
    try {
        let fetchUrl = `${baseUrl}/schema/${schemaVersion}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        /* extract device id list */
        let deviceObjList = res.data.item['booth']['spec']['devices'];
        callback(_extractIds(deviceObjList), deviceObjList);
    } catch (e) {
        console.log(e);
        callback([]);
    }
};

const JsonService = {
    fetchBoothJsonSchema,
    getBoothDeviceListInJson
};

export default JsonService;