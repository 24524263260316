import React, {useEffect, useState} from 'react';
import RenderOnRole from "../../auth/RenderOnRole";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Col, Container, Row, Tooltip} from "react-bootstrap";
import DataCompareService from "../../services/DataCompareService";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {AiOutlineQuestionCircle} from "react-icons/ai";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import {Link, useNavigate} from "react-router-dom";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const requestBodyInit = {
    userId : "",
    userName : "",
    userEmail : "",
    userPhone : "",
    userPassword : "",
    userGroupId : "",
    userType: "master"
};

const isValidMapInit = {
    userId : false,
    userName : false,
    userEmail : false,
    userPhone : false,
    userPassword : false,
    userGroupId : true,
    userType: true
}

const CustomerAdd = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [passwordVerify, setPasswordVerify] = useState("");
    const [isPasswordVerify, setIsPasswordVerify] = useState(false);

    /* group list */
    const [groupList, setGroupList] = useState([]);

    /* fetch group list */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const axiosCall = () => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            setGroupList(res.data.item.groupList);
            updateRequestBody("userGroupId", res.data.item.groupList[0].groupId);
        } catch (e) {
            console.log(e);
        }
    }

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    }

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    }

    /* handlers, validators */
    const handleUserIdInput = (value) => {
        updateRequestBody("userId", value);
    }

    const handleUserNameInput = (value) => {
        updateRequestBody("userName", value);
    }

    const handleUserEmailInput = (value) => {
        updateRequestBody("userEmail", value);
    }

    const handleUserPhoneInput = (value) => {
        updateRequestBody("userPhone", value);
    }

    const handleUserPasswordInput = async (value) => {
        await setIsPasswordVerify(value === passwordVerify);
        await updateRequestBody("userPassword", value);
    }

    const handleUserGroupIdChange = (value) => {
        updateRequestBody("userGroupId", value);
    }

    const handleUserTypeInput = (value) => {
        updateRequestBody("userType", value);
    }

    const handleUserPasswordVerifyInput = (value) => {
        setPasswordVerify(value);
    }

    /* validation */
    const validateUserId = async () => {
        if (requestBody["userId"] === "") {
            return;
        }
        try {
            let validity =
                DataCompareService.regexValidator(requestBody["userId"], "^[A-Za-z]{1}[A-Za-z0-9_-]{4,19}$")
                && (requestBody["userId"].length >= 5)
                && (requestBody["userId"].length <= 20)
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user/check/id/duplicate?target=${requestBody["userId"]}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            const isDuplicate = res.data.item.result;
            updateIsValidMap("userId", validity && !isDuplicate);
        } catch (e) {
            console.log(e);
        }
    }

    const validateUserName = () => {
        if (requestBody["userName"] === "") {
            return;
        }
        let validity = DataCompareService.regexValidator(requestBody["userName"], "^[가-힣]{1}[가-힣\\s]{0,15}[가-힣]{1}$|^[a-zA-Z]{1}[a-zA-Z\\s]{0,98}[a-zA-Z]{1}$");
        updateIsValidMap("userName", validity);
    }

    const validateUserEmail = async () => {
        if (requestBody["userEmail"] === "") {
            return;
        }
        try {
            let validity =
                DataCompareService.regexValidator(requestBody["userEmail"], "^[0-9a-zA-Z-_]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\\.[a-zA-Z]+$")
                && (requestBody["userEmail"].length >= 6)
                && (requestBody["userEmail"].length <= 40)
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user/check/email/duplicate?target=${requestBody["userEmail"]}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            const isDuplicate = res.data.item.result;
            updateIsValidMap("userEmail", validity && !isDuplicate);
        } catch (e) {
            console.log(e);
        }
    }

    const validateUserPhone = async () => {
        try {
            let validity = !(requestBody["userPhone"] === "");
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user/check/phone/duplicate?target=${requestBody["userPhone"]}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            const isDuplicate = res.data.item.result;
            updateIsValidMap("userPhone", validity && !isDuplicate);
        } catch (e) {
            console.log(e);
        }
    }

    const validateUserPassword = () => {
        if (requestBody["userPassword"] === "") {
            return;
        }
        let validity = DataCompareService.regexValidator(requestBody["userPassword"], "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&]{8,20}$");
        updateIsValidMap("userPassword", validity);
    }

    /* post customer */
    const postCustomer = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user`;
            const axiosCall = () => axios.post(
                fetchUrl ,
                JSON.stringify(requestBody),
                {
                    headers: {
                        "Authorization" : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate(`/customer`);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    /* effects */
    useEffect(() => {
        fetchGroupList();
    }, []);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        if (requestBody["userPassword"] === "") {
            setIsPasswordVerify(false);
        } else {
            setIsPasswordVerify(passwordVerify === requestBody["userPassword"]);
        }
    }, [passwordVerify]);

    useEffect(() => {
        validateUserId();
    }, [requestBody["userId"]]);

    useEffect(() => {
        validateUserName();
    }, [requestBody["userName"]]);

    useEffect(() => {
        validateUserEmail();
    }, [requestBody["userEmail"]]);

    useEffect(() => {
        validateUserPhone();
    }, [requestBody["userPhone"]]);

    useEffect(() => {
        validateUserPassword();
    }, [requestBody["userPassword"]]);

    /* renderer */
    const tooltip = (
        <Tooltip id="tooltip">
            <span>
                {t("message.passwordRule")}
            </span>
        </Tooltip>
    );

    return (
        <RenderOnRole type={"POST"} roleName={ImsSystemRole.CUSTOMER_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/customer"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.customer.title"), t("menu.customer.add.title")]}
                    depth={2}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                    <Container fluid>
                        <Row>
                            {/* main area */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '700px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '650px'
                                    }}
                                >
                                    <Container fluid>
                                        <Row>
                                            <TitleAndInputBox
                                                title={t("common.id")}
                                                value={requestBody["userId"]}
                                                onChange={handleUserIdInput}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["userId"]}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '15px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.name")}
                                                value={requestBody["userName"]}
                                                onChange={handleUserNameInput}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["userName"]}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '15px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.email")}
                                                value={requestBody["userEmail"]}
                                                onChange={handleUserEmailInput}
                                                isForEdit={true}
                                                type={"email"}
                                                isValid={isValidMap["userEmail"]}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '15px'
                                            }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.phone")}
                                                value={requestBody["userPhone"]}
                                                onChange={handleUserPhoneInput}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["userPhone"]}
                                            />
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '15px'
                                            }}
                                        >
                                            <OverlayTrigger placement="left" overlay={tooltip}>
                                                <button
                                                    style={{
                                                        outline: 'none',
                                                        border: 'none',
                                                        background: 'none',
                                                        padding: '0px'
                                                    }}
                                                >
                                                    <AiOutlineQuestionCircle
                                                        size={20}
                                                        style={{
                                                            color: '#c2c2c2',
                                                            marginLeft: '10px'
                                                        }}
                                                    />
                                                </button>
                                            </OverlayTrigger>
                                        </Row>
                                        <Row>
                                            <Col
                                                style={{ padding: '0px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.password")}
                                                    value={requestBody["userPassword"]}
                                                    onChange={handleUserPasswordInput}
                                                    isForEdit={true}
                                                    type={"password"}
                                                    isValid={isValidMap["userPassword"]}
                                                />
                                            </Col>
                                            <Col
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndInputBox
                                                    title={t("common.passwordVerify")}
                                                    value={passwordVerify}
                                                    onChange={handleUserPasswordVerifyInput}
                                                    isForEdit={true}
                                                    type={"password"}
                                                    isValid={isPasswordVerify}
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: '15px'
                                            }}
                                        >
                                            <Col
                                                style={{ padding: '0px' }}
                                            >
                                                <TitleAndSelectBox
                                                    title={t("common.group")}
                                                    innerValue={requestBody["userGroupId"]}
                                                    valueList={groupList}
                                                    valueSelector={"groupId"}
                                                    viewSelector={"groupName"}
                                                    onChange={handleUserGroupIdChange}
                                                />
                                            </Col>
                                            <Col
                                                style={{ padding: '0px', paddingLeft: '10px' }}
                                            >
                                                <TitleAndSelectBox
                                                    title={t("common.type")}
                                                    innerValue={requestBody["userType"]}
                                                    valueList={[
                                                        {
                                                            "id" : "master",
                                                            "name" : t("menu.customer.add.customerType.master")
                                                        },
                                                        {
                                                            "id" : "manager",
                                                            "name" : t("menu.customer.add.customerType.manager")
                                                        }
                                                    ]}
                                                    valueSelector={"id"}
                                                    viewSelector={"name"}
                                                    onChange={handleUserTypeInput}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50px'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '50px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'right'
                                        }}
                                    >
                                        <div
                                            style={{ marginRight: '10px' }}
                                        >
                                            <Link
                                                id={"edit-btn"}
                                                to={`/customer`}
                                            >
                                                {t("button.cancel")}
                                            </Link>
                                        </div>
                                        <div>
                                            {
                                                isValid && isPasswordVerify
                                                    ?
                                                    <Link
                                                        id={"save-btn"}
                                                        onClick={() => postCustomer()}
                                                    >
                                                        {t("button.save")}
                                                    </Link>
                                                    :
                                                    <Link
                                                        id={"save-btn-disabled"}
                                                    >
                                                        {t("button.save")}
                                                    </Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default CustomerAdd;