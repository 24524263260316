import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import JsonService from "../axiosServices/JsonService";
import SchemaParser from "../utils/SchemaParser";
import uuid from "react-uuid";

const Title = styled.span`
  font-size: 15px;
  color: #212529;
`;

const CategoryContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const Category = styled.div`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
`;

const CategoryButton = styled.button`
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid #e4e7ea;
  color: #212529;
  background-color: white;
  font-size: 13px;
  
  &:focus {
    outline: none;
  }
`;

const CategoryButtonSelected = styled.button`
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid #1a83ff;
  color: white;
  background-color: #1a83ff;
  font-size: 13px;
  
  &:focus {
    outline: none;
  }
`;

const TitleAndCategoricalText = ({ title, value, isForEdit, updateValue, updateIsValid, objectKey, validator }) => {

    let pattern = validator['pattern'];

    /* category list */
    const [categoryList, setCategoryList] = useState([]);

    const handleValueChange = (newValue) => {
        if (!isForEdit) {
            return;
        } else {
            updateValue(objectKey, newValue);
            updateIsValid(objectKey, true);
        }
    };

    const renderCategories = () => {
        let categoryElements = [];
        categoryList.map(category => {
           if (category === value) {
               let color = "#1a83ff";
               /* ONLY for status render */
               if (category === "Active") {
                    color = "#28AEFF";
               } else if (category === "Pending") {
                   color = "#FFC314";
               } else if (category === "Failed") {
                   color = "#FF3232";
               } else if (category === "Unknown") {
                   color = "#C45FDD";
               }
               categoryElements.push(
                   <Category
                       key={uuid()}
                   >
                       <CategoryButtonSelected
                           style={{
                               backgroundColor : color,
                               borderColor : color
                           }}
                       >
                           {category}
                       </CategoryButtonSelected>
                   </Category>
               );
           } else {
               categoryElements.push(
                   <Category
                       key={uuid()}
                   >
                       <CategoryButton
                           onClick={()=>handleValueChange(category)}
                       >
                           {category}
                       </CategoryButton>
                   </Category>
               );
           }
        });
        return categoryElements;
    };

    /* effects */
    /* initialization */
    useEffect(() => {
        setCategoryList(SchemaParser.extractListFromPattern(pattern));
    }, [validator, value]);

    /* enabled case only */
    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* title */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '5px',
                    textAlign: 'left'
                }}
            >
                <Title>
                    {title}
                </Title>
            </div>
            {/* categories */}
            <div
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                <CategoryContainer>
                    {renderCategories()}
                </CategoryContainer>
            </div>
        </div>
    );
};

export default TitleAndCategoricalText;