import React from 'react';
import styled from "styled-components";
import Switch from "react-switch";

const Title = styled.span`
  font-size: 14px;
  color: #8f8f8f;
  padding-left: 5px;
`;

const BooleanContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const BooleanFieldView = ({ title,
                              data,
                              updateData,
                              updateIsValidMap,
                              isForEdit }) => {

    const handleValueChange = (newValue) => {
        if (isForEdit) {
            updateData(newValue);
            updateIsValidMap(true);
        } else {
            return;
        }
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            {/* title */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '5px',
                    textAlign: 'left'
                }}
            >
                <Title>
                    {title}
                </Title>
            </div>
            {/* switch */}
            <div
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                <BooleanContainer>
                    {
                        isForEdit ?
                            <Switch
                                checked={data}
                                onChange={handleValueChange}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onColor={"#52b640"}
                            />
                            :
                            <Switch
                                checked={data}
                                onChange={() => {}}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onColor={"#008000"}
                            />
                    }
                </BooleanContainer>
            </div>
        </div>
    );
};

export default BooleanFieldView;