import React from 'react';
import styled from "styled-components";
import { CiSquareRemove } from "react-icons/ci";

const KeyValueContainer = styled.div`
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
`;

const DataInputKey = styled.input`
  width: 30%;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 10px;
  transition: all ease-in-out 0.2s;
  font-size: 13px;
  margin-right: 5px;
  
  &:focus {
    outline: none;
    border-color: #fc7242;
  }
`;

const TwoDotContainer = styled.div`
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #c2c2c2;
`;

const DataInputValue = styled.input`
  width: 60%;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 10px;
  transition: all ease-in-out 0.2s;
  font-size: 13px;
  
  &:focus {
    outline: none;
    border-color: #fc7242;
  }
`;

const DeleteButton = styled.button`
  border: none;
  background: none;
  transition: all ease-in-out 0.2s;
  color: #f44336;
  margin-left: auto;
  
  &:hover {
    color: #ffe6e6;
  }
  
  &:focus {
    outline: none;
  }
`;

const ExtraInfoItemView = ({ item, index, handleKeyChange, handleValueChange, isForEdit, onDelete, isDeletePossible }) => {

    let objKey = Object.keys(item)[0];

    return (
        <KeyValueContainer>
            <DataInputKey
                onChange={(e) => handleKeyChange(index, objKey, e.target.value)}
                value={objKey}
                readOnly={!isForEdit}
            />
            <TwoDotContainer>
                {":"}
            </TwoDotContainer>
            <DataInputValue
                onChange={(e) => handleValueChange(index, objKey, e.target.value)}
                value={item[objKey]}
                readOnly={!isForEdit}
            />
            <DeleteButton
                onClick={() => {
                    if (isForEdit) {
                        onDelete();
                    }
                }}
            >
                {
                    (isDeletePossible == null) ?
                        <CiSquareRemove
                            size={"25"}
                        />
                        :
                        (
                            isDeletePossible ?
                                <CiSquareRemove
                                    size={"25"}
                                />
                                :
                                <CiSquareRemove
                                    size={"25"}
                                    color={"#ebebeb"}
                                />
                        )
                }
            </DeleteButton>
        </KeyValueContainer>
    );
};

export default ExtraInfoItemView;