import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import RenderOnRole from "../../auth/RenderOnRole";
import uuid from "react-uuid";
import DataParseService from "../../services/DataParseService";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import OrderProductEdit from "./orderProducts/OrderProductEdit";
import {Link, useNavigate} from "react-router-dom";
import DataCompareService from "../../services/DataCompareService";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import styled from "styled-components";
import {useDaumPostcodePopup} from "react-daum-postcode";
import OrderProductAdd from "./orderProducts/OrderProductAdd";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const requestBodyInit = {
    deliveryCharge : "",
    deliveryChargeUnit : "",
    locationPostalCode : "",
    locationAddress : "",
    locationAddressDetail : "",
    orderBranchId : "",
    orderUserId : "",
    orderGroupId : ""
};

const isValidMapInit = {
    deliveryCharge : false,
    deliveryChargeUnit : false,
    locationPostalCode : false,
    locationAddress : false,
    locationAddressDetail : false,
    orderBranchId : true,
    orderUserId : false,
    orderGroupId : false
};

const AddressSearchButton = styled.button`
  border: 1px solid #fc7242;
  background: none;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  color: #fc7242;
  font-size: 14px;
  padding: 4px 10px;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #fcf9ed;
  }
`;

const OrderAdd = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    /* isValid */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    const [isProductIn, setIsProductIn] = useState(false);
    /* group, user, branch */
    const [groupList, setGroupList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    /* order product list */
    const [orderProductList, setOrderProductList] = useState([]);


    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    }

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    }

    const toRequestBody = (list) => {
        for (let i=0 ; i < list.length ; i++) {
            delete list[i]["productName"];
            delete list[i]["productName1"];
            delete list[i]["productName2"];
            delete list[i]["productName3"];
            delete list[i]["productName4"];
            delete list[i]["productName5"];
            delete list[i]["price"];
            delete list[i]["priceUnit"];
            delete list[i]["productUnit"];
            delete list[i]["productUnitAmount"];
        }
        return list;
    }

    /* fetch group list : only at first */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            setGroupList(res.data.item.groupList);
            updateRequestBody("orderGroupId", res.data.item.groupList[0].groupId);
        } catch (e) {
            console.log(e);
        }
    };

    /* fetch user list : only when groupId is changed */
    const fetchUserList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/user/group/${requestBody["orderGroupId"]}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data.item["error_code"] === "GET_EMPTY_DATA") {
                setUserList([]);
                updateRequestBody("orderUserId", "");
            } else {
                setUserList(res.data.item.userList);
                updateRequestBody("orderUserId", res.data.item.userList[0].userId);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* fetch branch list : only when userId is changed */
    const fetchBranchList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/branch/user/${requestBody["orderUserId"]}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] === "GET_EMPTY_DATA") {
                setBranchList([]);
                updateRequestBody("orderBranchId", "");
            } else {
                setBranchList(res.data.item.branchList);
                updateRequestBody("orderBranchId", res.data.item.branchList[0].branchId);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* handlers */
    const handleOrderGroupIdChange = (value) => {
        updateRequestBody("orderGroupId", value);
    };

    const handleOrderUserIdChange = (value) => {
        updateRequestBody("orderUserId", value);
    };

    const handleOrderBranchIdChange = (value) => {
        updateRequestBody("orderBranchId", value);
    };

    const handleDeliveryChargeInput = (value) => {
        let floatValue = parseFloat(value);
        if (isNaN(floatValue)) {
            updateRequestBody("deliveryCharge", value);
        } else {
            updateRequestBody("deliveryCharge", floatValue);
        }
    };

    const handleDeliveryChargeUnitInput = (value) => {
        updateRequestBody("deliveryChargeUnit", value);
    };

    const handleLocationAddressInput = (value) => {
        updateRequestBody("locationAddress", value);
    };

    const handleLocationAddressDetailInput = (value) => {
        updateRequestBody("locationAddressDetail", value);
    };

    const handleLocationPostalCodeInput = (value) => {
        updateRequestBody("locationPostalCode", value);
    };

    /* validators */
    const validateOrderGroupId = () => {
        if (requestBody["orderGroupId"] === "") {
            updateIsValidMap("orderGroupId", false);
        } else {
            updateIsValidMap("orderGroupId", true);
        }
    };

    const validateOrderUserId = () => {
        if (requestBody["orderUserId"] === "") {
            updateIsValidMap("orderUserId", false);
        } else {
            updateIsValidMap("orderUserId", true);
        }
    };

    const validateOrderBranchId = () => {
        if (requestBody["orderBranchId"] === "") {
            updateIsValidMap("orderBranchId", false);
        } else {
            updateIsValidMap("orderBranchId", true);
        }
    };

    const validateDeliveryCharge = () => {
        if ((requestBody["deliveryCharge"] == null)
            || (requestBody["deliveryCharge"] === "")
            || (typeof requestBody["deliveryCharge"] !== "number")) {
            updateIsValidMap("deliveryCharge", false);
        } else {
            if (requestBody["deliveryCharge"] < 0) {
                updateIsValidMap("deliveryCharge", false);
            } else {
                updateIsValidMap("deliveryCharge", true);
            }
        }
    };

    const validateDeliveryChargeUnit = () => {
        if (requestBody["deliveryChargeUnit"] === "") {
            updateIsValidMap("deliveryChargeUnit", false);
        } else {
            updateIsValidMap("deliveryChargeUnit", true);
        }
    };

    const validateLocationAddress = () => {
        if (requestBody["locationAddress"] === "") {
            updateIsValidMap("locationAddress", false);
        } else {
            updateIsValidMap("locationAddress", true);
        }
    };

    const validateLocationAddressDetail = () => {
        if (requestBody["locationAddressDetail"] === "") {
            updateIsValidMap("locationAddressDetail", false);
        } else {
            updateIsValidMap("locationAddressDetail", true);
        }
    };

    const validateLocationPostalCode = () => {
        if (requestBody["locationPostalCode"] === "") {
            updateIsValidMap("locationPostalCode", false);
        } else {
            updateIsValidMap("locationPostalCode", true);
        }
    };

    /* post order */
    const postOrder = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/order`;
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            let generatedOrderId = res.data.item['generated order info']['orderId'];
            addProductsToOrder(generatedOrderId);
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
        }
    }

    const addProductsToOrder = async (generatedOrderId) => {
        try {
            let productRequestBody = {};
            productRequestBody['productList'] = toRequestBody(orderProductList);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/order/${generatedOrderId}/product`;
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(productRequestBody),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate('/order');
        } catch (e) {
            console.log(e);
            /* delete generated order */
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/order/${generatedOrderId}`;
            UserService.updateToken(() => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
        }
    };

    const openPostalCodeSearchPopup = useDaumPostcodePopup();

    const onSuccessPostalCodeSearch = (data) => {
        handleLocationPostalCodeInput(data['zonecode']);
        handleLocationAddressInput(data['address']);
    };

    /* effects */
    /* fetch group list => at first only */
    useEffect(() => {
        fetchGroupList();
    }, []);

    /* fetch user list => when group id changes only */
    useEffect(() => {
        validateOrderGroupId();
        if (requestBody["orderGroupId"] === "" || requestBody["orderGroupId"] == null) {
            return;
        } else{
            fetchUserList();
        }
    }, [requestBody["orderGroupId"]]);

    /* fetch branch list => when user id changes only */
    useEffect(() => {
        validateOrderUserId();
        if (requestBody["orderUserId"] === "" || requestBody["orderUserId"] == null) {
            return;
        } else{
            fetchBranchList();
        }
    }, [requestBody["orderUserId"]]);

    /* validate branch id */
    useEffect(() => {
        validateOrderBranchId();
    }, [requestBody["orderBranchId"]]);

    /* validate deliverCharge */
    useEffect(() => {
        validateDeliveryCharge();
    }, [requestBody["deliveryCharge"]]);

    /* validate deliverChargeUnit */
    useEffect(() => {
        validateDeliveryChargeUnit();
    }, [requestBody["deliveryChargeUnit"]]);

    /* validate address */
    useEffect(() => {
        validateLocationAddress();
    }, [requestBody["locationAddress"]]);

    /* validate address detail */
    useEffect(() => {
        validateLocationAddressDetail();
    }, [requestBody["locationAddressDetail"]]);

    /* validate postalCode */
    useEffect(() => {
        validateLocationPostalCode();
    }, [requestBody["locationPostalCode"]]);

    /* isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <RenderOnRole type={"POST"} roleName={ImsSystemRole.ORDER_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/order"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.order.title"), t("menu.order.add.title")]}
                    depth={2}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                    <Container fluid>
                        <Row>
                            {/* address search */}
                            <AddressSearchButton
                                onClick={() => openPostalCodeSearchPopup({onComplete : onSuccessPostalCodeSearch})}
                            >
                                {t("menu.order.add.searchAddr")}
                            </AddressSearchButton>
                        </Row>
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <Col
                                md={"2"}
                                style={{
                                    padding: '0px'
                                }}
                            >
                                <TitleAndInputBox
                                    title={t("menu.order.detail.content.shipInfo.postalCode")}
                                    value={requestBody["locationPostalCode"]}
                                    onChange={handleLocationPostalCodeInput}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={isValidMap["locationPostalCode"]}
                                    isEnabled={true}
                                />
                            </Col>
                            <Col
                                md={"4"}
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                <TitleAndInputBox
                                    title={t("menu.order.detail.content.shipInfo.address")}
                                    value={requestBody["locationAddress"]}
                                    onChange={handleLocationAddressInput}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={isValidMap["locationAddress"]}
                                    isEnabled={true}
                                />
                            </Col>
                            <Col
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                <TitleAndInputBox
                                    title={t("menu.order.detail.content.shipInfo.detail")}
                                    value={requestBody["locationAddressDetail"]}
                                    onChange={handleLocationAddressDetailInput}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={isValidMap["locationAddressDetail"]}
                                    isEnabled={true}
                                />
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <Col
                                style={{
                                    padding: '0px'
                                }}
                            >
                                <TitleAndInputBox
                                    title={t("menu.order.detail.content.deliveryCharge")}
                                    value={requestBody["deliveryCharge"]}
                                    onChange={handleDeliveryChargeInput}
                                    isForEdit={true}
                                    type={"number"}
                                    isValid={isValidMap["deliveryCharge"]}
                                    isEnabled={true}
                                />
                            </Col>
                            <Col
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                <TitleAndInputBox
                                    title={t("menu.order.detail.content.deliveryChargeUnit")}
                                    value={requestBody["deliveryChargeUnit"]}
                                    onChange={handleDeliveryChargeUnitInput}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={isValidMap["deliveryChargeUnit"]}
                                    isEnabled={true}
                                />
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <Col
                                style={{
                                    padding: '0px'
                                }}
                            >
                                <TitleAndSelectBox
                                    title={t("common.group")}
                                    innerValue={requestBody["orderGroupId"]}
                                    valueList={groupList}
                                    valueSelector={"groupId"}
                                    viewSelector={"groupName"}
                                    onChange={handleOrderGroupIdChange}
                                />
                            </Col>
                            <Col
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                <TitleAndSelectBox
                                    title={t("common.customer")}
                                    innerValue={requestBody["orderUserId"]}
                                    valueList={userList}
                                    valueSelector={"userId"}
                                    viewSelector={"userName"}
                                    onChange={handleOrderUserIdChange}
                                />
                            </Col>
                            <Col
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                <TitleAndSelectBox
                                    title={t("common.branch")}
                                    innerValue={requestBody["orderBranchId"]}
                                    valueList={branchList}
                                    valueSelector={"branchId"}
                                    viewSelector={"branchName"}
                                    onChange={handleOrderBranchIdChange}
                                />
                            </Col>
                        </Row>
                        {/* add product step */}
                        <Row
                            style={{
                                marginTop: '30px'
                            }}
                        >
                            {
                                <OrderProductAdd
                                    groupId={requestBody["orderGroupId"]}
                                    deliveryCharge={requestBody["deliveryCharge"]}
                                    setIsValid={setIsProductIn}
                                    updateOrderProductList={setOrderProductList}
                                />
                            }
                        </Row>
                        <Row
                            style={{
                                marginTop: '10px',
                                marginBottom: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'right'
                                }}
                            >
                                <div
                                    style={{ marginRight: '10px' }}
                                >
                                    <Link
                                        id={"edit-btn"}
                                        to={`/order`}
                                    >
                                        {t("button.cancel")}
                                    </Link>
                                </div>
                                <div>
                                    {
                                        isValid && isProductIn
                                            ?
                                            <Link
                                                id={"save-btn"}
                                                onClick={() => postOrder()}
                                            >
                                                {t("button.save")}
                                            </Link>
                                            :
                                            <Link
                                                id={"save-btn-disabled"}
                                            >
                                                {t("button.save")}
                                            </Link>
                                    }
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default OrderAdd;