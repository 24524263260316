import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import axios from "axios";
import UserService from "../../../../../services/UserService";
import BoothService from "../../axiosServices/BoothService";
import DataParseService from "../../../../../services/DataParseService";
import _ from "lodash";
import TitleAndInputBox from "../../../../common/dataView/TitleAndInputBox";
import TitleAndSelectBox from "../../../../common/dataView/TitleAndSelectBox";
import styled from "styled-components";
import DataCompareService from "../../../../../services/DataCompareService";
import {toast} from "react-toastify";
import ToastAlertView from "../../../../common/alert/ToastAlertView";
import ModalCloseButton from "../../../../common/modal/ModalCloseButton";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '450px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    boothVersionId : "",
    serialNumber : "",
    manufactureDate : "",
    boothGroupId : ""
};

const isValidMapInit = {
    boothVersionId : true,
    serialNumber : true,
    manufactureDate : true,
    boothGroupId : true
};

const SaveButton = styled.button`
  width: 100%;
  padding: 5px;
  background: none;
  border: 1px solid #fc7242;
  border-radius: 5px;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #fbe1d5;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  width: 100%;
  padding: 5px;
  background: none;
  border: 1px solid #757575;
  border-radius: 5px;
  color: #757575;

  &:focus {
    outline: none;
  }
`;

const BoothBasicInfoEditModal = ({ isOpen, setIsOpen, onRefresh, groupId, boothId, prevInfo }) => {

    const { t } = useTranslation();

    /* groupList */
    const [groupList, setGroupList] = useState([]);

    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);

    /* isValid */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);

    /* isEdited */
    const [isEdited, setIsEdited] = useState(false);

    /* NONE | FAIL_TO_CHECK | ON_CHECK | DUPLICATED | OK */
    const [serialStatus, setSerialStatus] = useState("NONE");
    /* duplicate message */
    const [serialMessage, setSerialMessage] = useState("");


    /* fetch group list */
    const fetchGroupList = async () => {
        try {
            const fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data['error_code'] === "GET_EMPTY_DATA" ) {
                setGroupList([]);
            } else {
                setGroupList(res.data.item.groupList);
            }
        } catch (e) {
            setGroupList([]);
            console.log(e);
        }
    };

    /* put booth info */
    const putBoothInfo = async () => {
        const onSuccessCallBack = () => {
            toast.success(<ToastAlertView message={t("message.saved")} />);
            onRefresh();
            setIsOpen(false);
        };
        const onFailureCallBack = (e) => {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            onRefresh();
            setIsOpen(false);
        };
        console.log(requestBody);
        BoothService.putBoothBasicInfo(boothId, requestBody, onSuccessCallBack, onFailureCallBack);
    };


    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };


    /* input handlers */
    const handleBoothGroupIdChange = (value) => {
        updateRequestBody("boothGroupId", value);
        if (value === "" | value == null) {
            updateIsValidMap("boothGroupId", false);
        } else {
            updateIsValidMap("boothGroupId", true);
        }
    };

    const handleBoothSerialInput = (value) => {
        /* NONE | FAIL_TO_CHECK | ON_CHECK | DUPLICATED | OK */
        updateRequestBody("serialNumber", value);
        if (value === "" | value == null) {
            updateIsValidMap("serialNumber", false);
            setSerialStatus("NONE");
            setSerialMessage(t("menu.booth.add.message.input"));
        } else {
            if (value === fixedRequestBody['serialNumber']) {
                /* no change */
                /* okay */
                updateIsValidMap("serialNumber", true);
                setSerialStatus("OK");
                setSerialMessage(t("menu.booth.add.message.ok"));
            } else {
                /* duplication check : for nonempty */
                const onSuccess = (result) => {
                    if (result) {
                        /* duplicated! */
                        updateIsValidMap("serialNumber", false);
                        setSerialStatus("DUPLICATED");
                        setSerialMessage(t("menu.booth.add.message.duplicated"));
                    } else {
                        /* okay */
                        updateIsValidMap("serialNumber", true);
                        setSerialStatus("OK");
                        setSerialMessage(t("menu.booth.add.message.ok"));
                    }
                };
                const onFailure = (e) => {
                    console.log(e);
                    updateIsValidMap("serialNumber", false);
                    setSerialStatus("FAIL_TO_CHECK");
                    setSerialMessage(t("menu.booth.add.message.failed"));
                };
                updateIsValidMap("serialNumber", false);
                setSerialStatus("ON_CHECK");
                setSerialMessage(t("menu.booth.add.message.onCheck"));
                BoothService.checkDuplicateSerial(value, onSuccess, onFailure);
            }
        }
    };

    const handleManufactureDate = (value) => {
        updateRequestBody("manufactureDate", value);
        if (value === "" | value == null) {
            updateIsValidMap("manufactureDate", false);
        } else {
            updateIsValidMap("manufactureDate", true);
        }
    };

    useEffect(() => {
        fetchGroupList();
        if (!_.isEqual(prevInfo, {})) {
            let newRequestBody = {};
            newRequestBody['boothVersionId'] = prevInfo['boothVersionInfo']['boothVersionId'];
            newRequestBody['boothGroupId'] = prevInfo['boothGroupId'];
            newRequestBody['manufactureDate'] = DataParseService.dateTimeToDate(prevInfo['manufactureDate']);
            newRequestBody['serialNumber'] = prevInfo['serialNumber'];
            handleBoothGroupIdChange(newRequestBody["boothGroupId"]);
            setRequestBody(newRequestBody);
            setFixedRequestBody(_.cloneDeep(newRequestBody));
            /* additional initialization */
            setSerialStatus("NONE");
            setSerialMessage("");
            setIsValidMap(isValidMapInit);
        }
    }, [prevInfo, isOpen]);

    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    const renderSerialMessage = () => {
        let color = "";
        /* NONE | FAIL_TO_CHECK | ON_CHECK | DUPLICATED | OK */
        if (serialStatus === "FAIL_TO_CHECK") {
            color = "red";
        } else if (serialStatus === "ON_CHECK") {
            color = "#757575";
        } else if (serialStatus === "DUPLICATED") {
            color = "red";
        } else if (serialStatus === "OK") {
            color = "blue";
        } else {
            color = "red";
        }
        return (
            <span
                style={{
                    color: color,
                    fontSize: '13px'
                }}
            >
                {serialMessage}
            </span>
        );
    };

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.booth.detail.basic.edit.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                {/* manufactured date */}
                <Row>
                    <TitleAndInputBox
                        title={t("menu.booth.add.detail.manufactureDate")}
                        value={requestBody['manufactureDate']}
                        onChange={handleManufactureDate}
                        isForEdit={true}
                        type={"date"}
                        isValid={isValidMap['manufactureDate']}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndSelectBox
                        title={t("menu.booth.add.detail.boothGroup")}
                        innerValue={requestBody['boothGroupId']}
                        valueList={groupList}
                        valueSelector={"groupId"}
                        viewSelector={"groupName"}
                        onChange={handleBoothGroupIdChange}
                    />
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'right'
                        }}
                    >
                        <span
                            style={{
                                fontSize: '13px',
                                color: '#757575'
                            }}
                        >
                            {t("menu.booth.detail.basic.edit.message")}
                        </span>
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndInputBox
                        title={t("menu.booth.add.detail.serialNumber")}
                        value={requestBody['serialNumber']}
                        onChange={handleBoothSerialInput}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap['serialNumber']}
                        isEnabled={true}
                    />
                </Row>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'right'
                        }}
                    >
                        {
                            renderSerialMessage()
                        }
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '30px'
                    }}
                >
                    {
                        isValid && isEdited ?
                            <SaveButton
                                onClick={() => putBoothInfo()}
                            >
                                {t("button.save")}
                            </SaveButton>
                            :
                            <SaveButtonDisabled>
                                {t("button.save")}
                            </SaveButtonDisabled>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default BoothBasicInfoEditModal;