import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import ModalCloseButton from "../../common/modal/ModalCloseButton";
import DataCompareService from "../../../services/DataCompareService";
import TitleAndInputBox from "../../common/dataView/TitleAndInputBox";
import UserService from "../../../services/UserService";
import axios from "axios";
import {toast} from "react-toastify";
import ToastAlertView from "../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '250px',
        margin: 'auto'
    }
}

const requestBodyInit = {
    extendDays : 0
};

const isValidMapInit = {
    extendDays: false
};

const ApiKeyExtend = ({ isOpen, setIsOpen, onRefresh, franchiseId, keyId }) => {

    const { t } = useTranslation();

    /* states */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);

    /* extend a key */
    const extendKey = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-app-api-key`
                + `/franchise/${franchiseId}/key/${keyId}/extension`;
            await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            onRefresh();
            toast.success(<ToastAlertView message={t("message.saved")} />);
            setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    };

    /* utils */
    const updateHelper = (setState, key, value) => {
        setState(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    /* handlers */
    const handleExtendDaysInput = (value) => {
        updateHelper(setRequestBody, "extendDays", parseInt(value));
        /* validate */
        if (typeof value === "undefined" || value == null || parseInt(value) === NaN || parseInt(value) <= 0 || value === "") {
            updateHelper(setIsValidMap, "extendDays", false);
        } else {
            updateHelper(setIsValidMap, "extendDays", true);
        }
    };

    /* effects */
    useEffect(() => {
        setRequestBody(requestBodyInit);
        setIsValidMap(isValidMapInit);
        setIsValid(false);
    }, [isOpen, keyId]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchiseApiKey.detail.button.extend")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    {/* available days */}
                    <TitleAndInputBox
                        title={t("menu.franchiseApiKey.detail.extendDays")}
                        value={requestBody["extendDays"]}
                        onChange={handleExtendDaysInput}
                        isForEdit={true}
                        type={"number"}
                        isValid={isValidMap["extendDays"]}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '25px'
                    }}
                >
                    {
                        isValid
                            ?
                            <button
                                id={"long-save-btn"}
                                onClick={extendKey}
                            >
                                {t("button.save")}
                            </button>
                            :
                            <button
                                id={"long-save-btn-disabled"}
                            >
                                {t("button.save")}
                            </button>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default ApiKeyExtend;