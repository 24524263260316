import React from 'react';
import styled from "styled-components";
import DataParseService from "../../../../services/DataParseService";
import {CiSquareCheck, CiSquareMinus, CiSquarePlus} from "react-icons/ci";

const VersionItem = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.36);
  padding: 10px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  color: #fc7242;

  &:hover {
    -webkit-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    -moz-box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    box-shadow: 0px 0px 3px 2px rgba(105,105,105,0.36);
    color: #fc7242;
  }
`;

const BoothName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 50px;
  color: #757575;
  margin-right: 15px;
`;

const BoothVersion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  height: 25px;
  font-size: 14px;
  border: 1px solid #a44ce6;
  color: #a44ce6;
  background-color: #fff4ff;
  border-radius: 5px;
`;

const AddButton = styled.button`
  margin-left: auto;
  border: none;
  text-align: center;
  background: none;
  transition: all ease-in-out 0.2s;
  color: #1e8cfb;
  
  &:hover {
    color: blue;
  }
  
  &:focus {
    outline: none;
  }
`;

const AddButtonDisabled = styled.button`
  margin-left: auto;
  border: none;
  text-align: center;
  background: none;
  color: #ebebeb;
  
  &:focus {
    outline: none;
  }
`;

const NotAssignedBoothVersion = ({ boothName, boothVersion, onClick, isEnabled }) => {
    return (
        <VersionItem>
            {/* booth Name */}
            <BoothName>
                {boothName}
            </BoothName>
            {/* booth version */}
            <BoothVersion>
                {boothVersion}
            </BoothVersion>

            {/* add button */}
            {
                isEnabled ?
                    <AddButton
                        onClick={onClick}
                    >
                        <CiSquarePlus
                            size={"25"}
                        />
                    </AddButton>
                    :
                    <AddButtonDisabled>
                        <CiSquarePlus
                            size={"25"}
                            color={"#c2c2c2"}
                        />
                    </AddButtonDisabled>
            }
        </VersionItem>
    );
};

export default NotAssignedBoothVersion;