import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import CommonUtil from "../../utils/CommonUtil";
import styled from "styled-components";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import StatusInner from "./StatusInner";
import _ from "lodash";
import ServerFailed from "../info/ServerFailed";
import BoothService from "../../axiosServices/BoothService";
import ConnectionInner from "./ConnectionInner";
import DataCompareService from "../../../../../services/DataCompareService";
import SchemaParser from "../../utils/SchemaParser";
import BoothStockObjectView from "../../dataView/object/BoothStockObjectView";
import {toast} from "react-toastify";
import ConfirmModal from "../../../../common/confirm/ConfirmModal";
import {type} from "@testing-library/user-event/dist/type";

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DataContainerRounded = styled.div`
  margin-top: 20px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
`;

const DataContainerInnerTitleContainer = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  border-bottom: 1px solid #e4e7ea;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
`;

const InnerTitle = styled.div`
  color: #848487;
  text-align: left;
`;

const SaveButton = styled.button`
  width: 40%;
  border: 1px solid #fc7242;
  background-color: white;
  color: #fc7242;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #fff1ec;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  width: 40%;
  border: 1px solid #ebebeb;
  background-color: white;
  color: #ebebeb;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  
  &:focus {
    outline: none;
  }
`;

const Status = () => {

    const { t, i18n } = useTranslation();

    /* params */
    const { boothId, deviceId } = useParams();

    /* booth schema */
    const boothSchema = JSON.parse(window.localStorage.getItem("ims_json_schema"));
    const schemaVersion = boothSchema['metadata']['id'];

    /* -------------------------------------------------------- */
    /* ------------------------- NOTE ------------------------- */
    /* 1.  If the connection information does not exist,        */
    /*     the HARD-CODED capability will be shown.             */
    /* 2.  The HARD-CODED values are like below.                */
    let DEVICE_NAME = "operator";
    let SENSOR_NAME = "operator_controller";
    /* Below can be not present.                                */
    let CAPABILITY_NAME = "remainIngredient";  /* Assume object */
    /* 3.  Belows are all for this HARD-CODED view.             */

    /* states */
    const [stockRequestBody, setStockRequestBody] = useState({});
    const [fixedStockRequestBody, setFixedStockRequestBody] = useState({});

    const [stockObject, setStockObject] = useState({});

    const [operatorDeviceId, setOperatorDeviceId] = useState("");
    const [operatorControllerSensorId, setOperatorControllerSensorId] = useState("");

    const [isValidStockObject, setIsValidStockObject] = useState(true);
    const [isStockObjectEdited, setIsStockObjectEdited] = useState(false);

    /* optional */
    const [stockObjectValidator, setStockObjectValidator] = useState({});
    const [stockObjectProperty, setStockObjectProperty] = useState({});

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    /* confirm */
    const [isStockSaveConfirmModalOpen, setIsStockSaveConfirmModalOpen] = useState(false);

    /* for toast alert */
    const ToastMessage = (text) => {
        return (
            <span
                style={{
                    fontSize: '14px',
                    color: '#757575'
                }}
            >
                {text}
            </span>
        );
    };

    const toastAlert = (text, isSuccess) => {
        if (isSuccess) {
            toast.success(ToastMessage(text));
        } else {
            toast.error(ToastMessage(text));
        }
    };

    /* utils */
    const updateStockRequestBody = (key, value) => {
        let newBody = {};
        newBody[CAPABILITY_NAME] = value;
        setStockRequestBody(newBody);
    };

    const updateStockRequestIsValid = (key, value) => {
        setIsValidStockObject(value);
    };

    const saveStock = () => {
        let requestPath = `/booth/${boothId}/device/${deviceId}/sensor/${operatorControllerSensorId}`;
        const onSuccess = () => {
            setIsButtonLoading(false);
            setIsStockObjectEdited(false);
            setIsValidStockObject(false);
            fetchBoothData();
            toastAlert(t("menu.boothControl.infoMessage.controlRequestSuccess"), true);
        };
        const onFailure = (e) => {
            setIsButtonLoading(false);
            setIsStockObjectEdited(false);
            setIsValidStockObject(false);
            toastAlert(t("menu.boothControl.infoMessage.controlRequestFail"), false);
        };
        setIsButtonLoading(true);
        BoothService.putBoothDataToEss(requestPath, stockRequestBody, onSuccess, onFailure);
    };

    /* effects */
    /* initialize ids */
    useEffect(() => {
        if (typeof boothSchema === "undefined" || boothSchema == null) {
            return;
        } else {
            /* get device */
            let deviceSchemaList = boothSchema['spec']['devices'];
            let targetDeviceSchema = CommonUtil.getObjectInListByName(DEVICE_NAME, deviceSchemaList);
            /* get sensor */
            let sensorSchemaList = targetDeviceSchema['spec']['sensors'];
            let targetSensorSchema = CommonUtil.getObjectInListByName(SENSOR_NAME, sensorSchemaList);
            /* set ids */
            setOperatorDeviceId(targetDeviceSchema['metadata']['id']);
            setOperatorControllerSensorId(targetSensorSchema['metadata']['id']);
            /* set schemas */
            if ((typeof targetSensorSchema['spec']['capabilities'][CAPABILITY_NAME] !== "undefined") &&
                (targetSensorSchema['spec']['capabilities'][CAPABILITY_NAME] != null)) {
                setStockObjectValidator(targetSensorSchema['spec']['capabilities'][CAPABILITY_NAME]['validator']);
                setStockObjectProperty(targetSensorSchema['spec']['capabilities'][CAPABILITY_NAME]['properties']);
            }
        }
    }, [deviceId]);

    /* track isEdited */
    useEffect(() => {
        setIsStockObjectEdited(!_.isEqual(stockRequestBody, fixedStockRequestBody));
    }, [stockRequestBody]);

    /* -------------------------------------------------------- */

    /* device schema */
    const deviceSchema = CommonUtil.getObjectInListById(deviceId, boothSchema['spec']['devices']);

    /* booth data */
    const [boothData, setBoothData] = useState({});

    /* loading */
    const [isLoading, setIsLoading] = useState(true);

    /* fetch booth data */
    const fetchBoothData = async () => {
        const onSuccessCallBack = async (data) => {
            setBoothData(data);
            /* ------------------ */
            /* HARD-CODED         */
            /* extract stock data */
            if (operatorDeviceId !== "" || operatorControllerSensorId !== "") {
                let extractedStockData = data[schemaVersion][operatorDeviceId]['sensors'][operatorControllerSensorId]['capabilities'][CAPABILITY_NAME];
                /* if null, an empty object */
                if (typeof extractedStockData === "undefined" || extractedStockData == null) {
                    extractedStockData = {};
                }
                await setStockObject(extractedStockData);
                let newBody = {};
                newBody[CAPABILITY_NAME] = extractedStockData;
                await setStockRequestBody(newBody);
                setFixedStockRequestBody(_.cloneDeep(newBody));
            }
            /* ------------------ */
            setIsLoading(false);
        };
        const onFailureCallBack = () => {
            setBoothData({});
            setIsLoading(false);
        };
        BoothService.fetchBoothData(boothId, onSuccessCallBack, onFailureCallBack);
    };

    /* effects */
    /* fetch booth data */
    useEffect(() => {
        setIsLoading(true);
        fetchBoothData();
    }, [deviceId, operatorDeviceId, operatorControllerSensorId]);

    if (isLoading) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '50%',
                    paddingTop: '10%'
                }}
            >
                <Spinner />
            </div>
        );
    } else if (boothSchema == null
                || _.isEqual(boothData, {})
                || (typeof boothSchema === "undefined")
                || (boothSchema == null)) { // server failed
        return (
            <div
                style={{
                    width: '100%',
                    paddingTop: '50px'
                }}
            >
                <ServerFailed />
            </div>
        );
    } else {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <CardContainer>
                            <DataContainerRounded>
                                <DataContainerInnerTitleContainer>
                                    <InnerTitle>
                                        {t("menu.boothControl.status.title")}
                                    </InnerTitle>
                                </DataContainerInnerTitleContainer>
                                <StatusInner
                                    statusData={boothData[schemaVersion][deviceId]['status']}
                                />
                            </DataContainerRounded>
                        </CardContainer>
                    </Col>
                    <Col>
                        {
                            (Object.keys(deviceSchema['spec']['connection']).length !== 0
                                && (deviceId !== operatorDeviceId)) ?
                                <CardContainer>
                                    <DataContainerRounded>
                                        <DataContainerInnerTitleContainer>
                                            <InnerTitle>
                                                {t("menu.boothControl.schema.connection.title")}
                                            </InnerTitle>
                                        </DataContainerInnerTitleContainer>
                                        <ConnectionInner
                                            onRefresh={fetchBoothData}
                                            connectionSchema={deviceSchema['spec']['connection']}
                                            connectionData={boothData[schemaVersion][deviceId]['connection']}
                                        />
                                    </DataContainerRounded>
                                </CardContainer>
                                :
                                    (_.isEqual(stockObjectValidator, {}) || _.isEqual(stockObjectProperty, {}) || (deviceId !== operatorDeviceId)) ?
                                        <CardContainer>
                                            <DataContainerRounded>
                                                <DataContainerInnerTitleContainer>
                                                    <InnerTitle>
                                                        {t("menu.boothControl.schema.connection.title")}
                                                    </InnerTitle>
                                                </DataContainerInnerTitleContainer>
                                                <ConnectionInner
                                                    onRefresh={()=>{}}
                                                    connectionSchema={{}}
                                                    connectionData={{}}
                                                />
                                            </DataContainerRounded>
                                        </CardContainer>
                                    :
                                    <CardContainer>
                                        <DataContainerRounded>
                                            <DataContainerInnerTitleContainer>
                                                <InnerTitle>
                                                    {t("menu.boothControl.device.remainingStocks")}
                                                </InnerTitle>
                                            </DataContainerInnerTitleContainer>
                                            <BoothStockObjectView
                                                isTitleUsed={false}
                                                title={""}
                                                objValue={stockObject}
                                                isForEdit={true}
                                                updateValue={updateStockRequestBody}
                                                updateIsValid={updateStockRequestIsValid}
                                                mainKey={CAPABILITY_NAME}
                                                validator={stockObjectValidator}
                                                properties={stockObjectProperty}
                                            />
                                            {/* save button */}
                                            <div
                                                style={{
                                                    width: '100%',
                                                    padding: '15px 15px',
                                                    textAlign: 'right'
                                                }}
                                            >
                                                {
                                                    isStockObjectEdited && isValidStockObject ?
                                                        <SaveButton
                                                            onClick = {() => setIsStockSaveConfirmModalOpen(true)}
                                                        >
                                                            {
                                                                isButtonLoading ?
                                                                    <Spinner size={"sm"} />
                                                                    :
                                                                    t("menu.boothControl.button.sendRequest")
                                                            }
                                                        </SaveButton>
                                                        :
                                                        <SaveButtonDisabled>
                                                            {t("menu.boothControl.button.sendRequest")}
                                                        </SaveButtonDisabled>
                                                }
                                            </div>
                                        </DataContainerRounded>
                                    </CardContainer>

                        }
                    </Col>
                </Row>
                <ConfirmModal
                    isOpen={isStockSaveConfirmModalOpen}
                    setIsOpen={setIsStockSaveConfirmModalOpen}
                    title={t("menu.boothControl.infoMessage.sureToPut")}
                    confirmLabel={t("button.yes")}
                    rejectLabel={t("button.no")}
                    onConfirm={saveStock}
                    onReject={() => {}}
                />
            </Container>
        );
    }
};

export default Status;