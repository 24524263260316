import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import DataCompareService from "../../services/DataCompareService";
import UserService from "../../services/UserService";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import ModalCloseButton from "../common/modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import _ from "lodash";


const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '420px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    companyName: "",
    description: "",
    companyGroupId: "",
    isMasterCompany: false
};

const isValidMapInit = {
    companyName: false,
    description: false,
    companyGroupId: true,
    isMasterCompany: true
};

const CompanyAdd = ({ isOpen, setIsOpen, onRefresh }) => {

    const { t, i18n } = useTranslation();

    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    /* validity */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);
    const [companyGroupList, setCompanyGroupList] = useState([]);
    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };
    /* fetch GroupList */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                let newGroupListPrev = res.data.item.groupList;
                let newGroupList = [];
                for (let j=0 ; j<newGroupListPrev.length ; j++) {
                    if (!newGroupListPrev[j]['masterGroup']) {
                        newGroupList.push(newGroupListPrev[j]);
                    }
                }
                setCompanyGroupList(newGroupList);
                if (newGroupList.length > 0) {
                    handleCompanyGroupIdChange(newGroupList[0]['groupId']);
                } else {
                    handleCompanyGroupIdChange("");
                }
            }
        } catch (e) {
            console.log(e);
        }
    };
    /* handlers */
    const handleCompanyNameInput = (value) => {
        updateRequestBody("companyName", value);
    };


    const handleDescriptionInput = (value) => {
        updateRequestBody("description", value);
    };

    const handleCompanyGroupIdChange = (value) => {
        updateRequestBody("companyGroupId", value);
        if (value === "") {
            updateIsValidMap("companyGroupId", false);
        } else {
            updateIsValidMap("companyGroupId", true);
        }
    };

    /* validators */
    const checkCompanyNameValidity = () => {
        if (requestBody["companyName"] === "") {
            updateIsValidMap("companyName", false);
            return;
        }
        const isValidForm = true;
        updateIsValidMap("companyName", isValidForm);
    };


    const checkDescriptionValidity = () => {
        if (requestBody["description"] === "") {
            updateIsValidMap("description", false);
        } else {
            updateIsValidMap("description", true);
        }
    }

    /* post company */
    const postCompany = async () => {
        let newRequestBody = _.cloneDeep(requestBody);
        /* handle master group */
        for (let i=0 ; i<companyGroupList.length ; i++) {
            if (companyGroupList[i] === newRequestBody['companyGroupId']) {
                if (companyGroupList[i]['masterGroup']) {
                    newRequestBody['isMasterCompany'] = true;
                }
            }
        }
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/company`;
            await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await onRefresh();
            await initialize();
            await setIsOpen(false);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    /* initializer */
    const initialize = async () => {
        await setRequestBody(requestBodyInit);
        await setIsValidMap(isValidMapInit);
        await fetchGroupList();
    };

    /* effects */
    useEffect(() => {
        initialize();
    }, [isOpen]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        checkCompanyNameValidity();
    }, [requestBody["companyName"]]);

    useEffect(() => {
        checkDescriptionValidity();
    }, [requestBody["description"]]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.groupCompany.company.add.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <TitleAndInputBox
                        title={t("common.name")}
                        value={requestBody["companyName"]}
                        onChange={handleCompanyNameInput}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap["companyName"]}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndTextArea
                        title={t("common.description")}
                        value={requestBody["description"]}
                        onChange={handleDescriptionInput}
                        isForEdit={true}
                        type={"text"}
                        isValid={isValidMap["description"]}
                        height={"60"}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndSelectBox
                        title={t("menu.groupCompany.group.title")}
                        innerValue={requestBody["companyGroupId"]}
                        valueList={companyGroupList}
                        valueSelector={"groupId"}
                        viewSelector={'groupName'}
                        onChange={handleCompanyGroupIdChange}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {
                        (isValid)
                            ?
                            <button
                                id={"long-save-btn"}
                                onClick={() => postCompany()}
                            >
                                {t("button.save")}
                            </button>
                            :
                            <button
                                id={"long-save-btn-disabled"}
                            >
                                {t("button.save")}
                            </button>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default CompanyAdd;