import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import UserService from "../../services/UserService";
import axios from "axios";
import styled from "styled-components";
import _ from "lodash";
import AccountTable from "./table/AccountTable";
import ConsoleAccountAddModal from "./modal/ConsoleAccountAddModal";
import StrUtils from "../common/utils/StrUtils";

const AddButton = styled.button`
  border: 1px solid #fc7242;
  border-radius: 10px;
  font-size: 14px;
  padding: 7px;
  background-color: white;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #fcf9ed;
  }
`;

const InfoMessage = styled.span`
  font-size: 14px;
  color: #757575;
`;

const Active = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #0099ff;
  background-color: #e6f5ff;
  color: #0099ff;
  padding: 5px 15px;
  font-size: 13px;
`;

const Inactive = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #ff0000;
  background-color: #ffe6e6;
  color: #ff0000;
  padding: 5px 15px;
  font-size: 13px;
`;

const Master = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #fc7242;
  background-color: #fcf9ed;
  color: #fc7242;
  padding: 5px 15px;
  font-size: 13px;
`;

const NonMaster = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #c2c2c2;
  background-color: white;
  color: #c2c2c2;
  padding: 5px 15px;
  font-size: 13px;
`;

const Empty = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid transparent;
  background: none;
  padding: 5px 5px;
  font-size: 13px;
`;

const AccountInFranchiseView = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { franchiseId } = useParams();
    const location = useLocation();

    /** states */
    const [isLoading, setIsLoading] = useState(false);
    const [consoleAccountList, setConsoleAccountList] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    useEffect(() => {
        fetchAccountList();
    }, [franchiseId]);

    const fetchAccountList = async (doForceRedirection) => {
        await setIsLoading(true);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-console-credential`;
            fetchUrl = fetchUrl + `/franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            /** rename fields and sorting */
            const prevList = res.data.item['franchiseConsoleCredentialList'];
            let newList = _.cloneDeep(prevList);
            newList.sort((a, b) => {
                return (b.isMaster === true) - (a.isMaster === true);
            });
            for (let i=0 ; i<newList.length ; i++) {
                newList[i]['id'] = newList[i]['franchiseConsoleCredentialId'];
                newList[i]['name'] = StrUtils.truncateWithEllipsis(newList[i]['name'], 15);
                newList[i]['email'] = StrUtils.truncateWithEllipsis(newList[i]['email'], 15);
                newList[i]['franchiseConsoleCredentialId'] = StrUtils.truncateWithEllipsis(newList[i]['franchiseConsoleCredentialId'], 15);
            }
            /** default routing */
            if ((!location.pathname.includes('/credential')) || doForceRedirection) {
                if (newList.length > 0) {
                    await navigate(`/fr-console-credential/franchise/${franchiseId}/credential/${newList[0]['id']}`);
                }
            }
            await setConsoleAccountList(newList);
            await setIsLoading(false);
        } catch (e) {
            await setIsLoading(false);
            console.log(e);
        }
    };

    /* table components */
    const columnData = [
        {
            accessor: 'name',
            Header: t('menu.franchiseConsoleCred.table.name'),
        },
        {
            accessor: 'franchiseConsoleCredentialId',
            Header: t('menu.franchiseConsoleCred.table.id'),
        },
        {
            accessor: 'email',
            Header: t('menu.franchiseConsoleCred.table.email'),
        },
        {
            accessor: 'isEnabled',
            Header: t('menu.franchiseConsoleCred.table.isEnabled'),
            Cell: ({ cell: { value } }) => <IsActiveView value={value} />
        },
        {
            accessor: 'isMaster',
            Header: t('menu.franchiseConsoleCred.table.isMaster'),
            Cell: ({ cell: { value } }) => <IsMasterView value={value} />
        }
    ];

    const IsActiveView = (value) => {
        if (value.value == null) {
            return (
                <Empty>
                    {"　"}
                </Empty>
            );
        } else if (value.value) {
            return (
                <Active>
                    {t("menu.franchiseConsoleCred.message.active")}
                </Active>
            );
        } else { /* false */
            return (
                <Inactive>
                    {t("menu.franchiseConsoleCred.message.inactive")}
                </Inactive>
            );
        }
    };

    const IsMasterView = (value) => {
        if (value.value == null) {
            return (
                <Empty>
                    {"　"}
                </Empty>
            );
        } else if (value.value) {
            return (
                <Master>
                    {t("menu.franchiseConsoleCred.message.master")}
                </Master>
            );
        } else { /* false */
            return (
                <NonMaster>
                    {t("menu.franchiseConsoleCred.message.nonMaster")}
                </NonMaster>
            );
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col
                    style={{
                        padding: '0px'
                    }}
                >
                    {/* table view */}
                    <div
                        style={{
                            width: '100%',
                            height: '800px',
                            backgroundColor: 'white',
                            borderRadius: '5px'
                        }}
                    >
                        {/* add credential button */}
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'left',
                                padding: '10px'
                            }}
                        >
                            <AddButton
                                onClick={async () => await setIsAddModalOpen(true)}
                            >
                                {t("menu.franchiseConsoleCred.button.add")}
                            </AddButton>
                            {/* add modal */}
                            <ConsoleAccountAddModal
                                isOpen={isAddModalOpen}
                                setIsOpen={setIsAddModalOpen}
                                franchiseId={franchiseId}
                                refreshList={async () => {
                                    await fetchAccountList();
                                }}
                            />
                        </div>
                        {/* loading */}
                        {
                            (isLoading || consoleAccountList.length === 0) &&
                            <div
                                style={{
                                    width: '100%',
                                    height: '750px',
                                    display: 'inline-flex',
                                    paddingTop: '200px',
                                    justifyContent: 'center'
                                }}
                            >
                                {
                                    isLoading &&
                                    <Spinner />
                                }
                                {
                                    (!isLoading) &&
                                    <InfoMessage>
                                        {t("menu.franchiseConsoleCred.message.noAccount")}
                                    </InfoMessage>
                                }
                            </div>
                        }
                        {/* table */}
                        {
                            (!isLoading) &&
                            (consoleAccountList.length > 0) &&
                            <div
                                style={{
                                    width: '100%',
                                    height: '720px',
                                    padding: '10px',
                                    paddingTop: '0px',
                                    overflow: 'auto'
                                }}
                            >
                                <AccountTable columns={columnData} data={consoleAccountList} baseUrl={'credential'} />
                            </div>
                        }
                    </div>
                </Col>
                <Col
                    style={{
                        padding: '0px',
                        paddingLeft: '10px'
                    }}
                >
                    {/* detail view */}
                    <div
                        style={{
                            width: '100%',
                            height: '800px',
                            backgroundColor: 'white',
                            borderRadius: '5px'
                        }}
                    >
                        <Outlet
                            context={{
                                fetchAccountList
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AccountInFranchiseView;