import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import UserService from "../../../../../services/UserService";
import _ from "lodash";
import DataParseService from "../../../../../services/DataParseService";
import DataCompareService from "../../../../../services/DataCompareService";
import BoothService from "../../axiosServices/BoothService";
import Modal from "react-modal";
import {Container, Row} from "react-bootstrap";
import TitleAndSelectBox from "../../../../common/dataView/TitleAndSelectBox";
import TitleAndInputBox from "../../../../common/dataView/TitleAndInputBox";
import styled from "styled-components";
import {toast} from "react-toastify";
import ToastAlertView from "../../../../common/alert/ToastAlertView";
import ModalCloseButton from "../../../../common/modal/ModalCloseButton";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '350px',
        margin: 'auto'
    }
};

const SaveButton = styled.button`
  width: 100%;
  padding: 5px;
  background: none;
  border: 1px solid #fc7242;
  border-radius: 5px;
  color: #fc7242;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #fbe1d5;
  }
  
  &:focus {
    outline: none;
  }
`;

const SaveButtonDisabled = styled.button`
  width: 100%;
  padding: 5px;
  background: none;
  border: 1px solid #757575;
  border-radius: 5px;
  color: #757575;

  &:focus {
    outline: none;
  }
`;

const requestBodyInit = {
    installDate : "",
    boothBranchId : ""
};

const isValidMapInit = {
    installDate : true,
    boothBranchId : true
};

const BoothInstallEditModal = ({ isOpen, setIsOpen, onRefresh, userId, boothId, prevInfo }) => {

    const { t, i18n } = useTranslation();

    /* branchList : dependent on userId */
    const [branchList, setBranchList] = useState([]);

    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);

    /* isValid */
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);

    /* isEdited */
    const [isEdited, setIsEdited] = useState(false);

    /* fetch branchList */
    const fetchBranchList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/branch`
                + `/user/${userId}`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data['error_code'] === "GET_EMPTY_DATA") {
                setBranchList([]);
            } else {
                setBranchList(res.data.item.branchList);
            }
        } catch (e) {
            console.log(e);
            setBranchList([]);
        }
    };

    const putBoothInstallInfo = () => {
        const onSuccess = () => {
            toast.success(<ToastAlertView message={t("message.saved")} />);
            onRefresh();
            setIsOpen(false);
        };
        const onFailure = (e) => {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        };
        BoothService.putBoothInstallInfo(boothId, requestBody, onSuccess, onFailure);
    };

    /* change handler */
    const handleBranchIdChange = (value) => {
        updateRequestBody("boothBranchId", value);
        if (value === "" | value == null) {
            updateIsValidMap("boothBranchId", false);
        } else {
            updateIsValidMap("boothBranchId", true);
        }
    };

    const handleInstallDateInput = (value) => {
        updateRequestBody("installDate", value);
        if (value === "" | value == null) {
            updateIsValidMap("installDate", false);
        } else {
            updateIsValidMap("installDate", true);
        }
    };

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* effects */
    useEffect(() => {
        if (userId != null && !_.isEqual(prevInfo, {}) && prevInfo['isInstalled']) {
            fetchBranchList();
            let newRequestBody = {};
            newRequestBody['boothBranchId'] = prevInfo['boothBranchId'];
            newRequestBody['installDate'] = DataParseService.dateTimeToDate(prevInfo['installDate']);
            setRequestBody(newRequestBody);
            setFixedRequestBody(_.cloneDeep(newRequestBody));
            setIsValidMap(isValidMapInit);
        }
    }, [isOpen, prevInfo]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.booth.detail.install.edit.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                {/* booth branch id */}
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndSelectBox
                        title={t("menu.booth.detail.install.boothBranch")}
                        innerValue={requestBody["boothBranchId"]}
                        valueList={branchList}
                        valueSelector={"branchId"}
                        viewSelector={"branchName"}
                        onChange={handleBranchIdChange}
                    />
                </Row>
                {/* installed date */}
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <TitleAndInputBox
                        title={t("menu.booth.detail.install.installDate")}
                        value={requestBody["installDate"]}
                        onChange={handleInstallDateInput}
                        isForEdit={true}
                        type={"date"}
                        isValid={isValidMap["installDate"]}
                        isEnabled={true}
                    />
                </Row>
                {/* save button */}
                <Row
                    style={{
                        marginTop: '40px'
                    }}
                >
                    {
                        isValid && isEdited ?
                            <SaveButton
                                onClick={() => putBoothInstallInfo()}
                            >
                                {t("button.save")}
                            </SaveButton>
                            :
                            <SaveButtonDisabled>
                                {t("button.save")}
                            </SaveButtonDisabled>
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default BoothInstallEditModal;