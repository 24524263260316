import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import CalendarView from "../schedule/components/CalendarView";
import AuthService from "../../services/AuthService";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import TopInfoBar from "./components/TopInfoBar";
import BoothInfoPart from "./components/BoothInfoPart";
import OrdersAndSalesPart from "./components/OrdersAndSalesPart";
import SchedulePart from "./components/SchedulePart";

const Dashboard = () => {

    /** env : USA */
    //const isForUSA = Boolean(window.sessionStorage.getItem("service_region_ims"));
    const isForUSA = (window.sessionStorage.getItem("service_region_ims").toLowerCase() === "true");

    const {t} = useTranslation();

    /* roles */
    /** for the USA, always false */
    const hasOrderRole = isForUSA ? false : AuthService.hasAuthByRoleNameAndType(ImsSystemRole.ORDER_MANAGEMENT, "GET");
    const hasBoothRole = AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "GET");

    return (
        <>
            {/* title */}
            <div className={"headingTitle"}>
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("menu.dashboard.title")]}
                    depth={1}
                />
            </div>
            <div
                className={"contentContainer"}
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Container fluid>
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                height: '50px'
                            }}
                        >
                            {/* top information */}
                            <TopInfoBar />
                        </div>
                    </Row>
                    {
                        hasBoothRole &&
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100px'
                                }}
                            >
                                <BoothInfoPart />
                            </div>
                        </Row>
                    }
                    {
                        hasOrderRole &&
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '250px'
                                }}
                            >
                                <OrdersAndSalesPart />
                            </div>
                        </Row>
                    }
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: hasBoothRole ?
                                        (
                                            hasOrderRole ?
                                                '372px'
                                                :
                                                '632px'
                                        )
                                    :
                                        (
                                            hasOrderRole ?
                                                '482px'
                                                :
                                                '742px'
                                        )
                            }}
                        >
                            <SchedulePart
                                isForLarge={(!hasOrderRole)}
                                calHeight={
                                    hasBoothRole ?
                                        (
                                            hasOrderRole ?
                                                '372px'
                                                :
                                                '632px'
                                        )
                                        :
                                        (
                                            hasOrderRole ?
                                                '482px'
                                                :
                                                '742px'
                                        )
                                }
                            />
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Dashboard;