import React from 'react';
import styled from "styled-components";

const colors = {
    buttonColor : "#fc7242",
    buttonHoverColor : "#fff1ec",
    buttonDisabledColor : "#c2c2c2"
};

const Button = styled.button`
  width: 100%;
  border: 1px solid ${(props) => props.borderColor ? props.borderColor : colors["buttonColor"]};
  border-radius: 10px;
  font-size: 14px;
  color: ${(props) => props.buttonColor ? props.buttonColor : colors["buttonColor"]};
  background: none;
  padding: 7px 10px;
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.backgroundColor ? props.backgroundColor : colors["buttonHoverColor"]};
  }
`;

const ButtonDisabled = styled.button`
  width: 100%;
  border: 1px solid ${(props) => props.buttonColor ? props.buttonColor : colors["buttonDisabledColor"]};
  border-radius: 10px;
  font-size: 14px;
  color: ${(props) => props.buttonColor ? props.buttonColor : colors["buttonDisabledColor"]};
  background: none;
  padding: 7px 10px;
  transition: all ease-in-out 0.2s;

  &:focus {
    outline: none;
  }
`;

const SimpleButton = ({ onClickCallback,
                          isEnabled,
                          label,
                          borderColor,
                          buttonColor,
                          buttonHoverColor,
                          buttonDisabledColor }) => {

    if (isEnabled) {
        return (
            <Button
                borderColor={borderColor}
                buttonColor={buttonColor}
                backgroundColor={buttonHoverColor}
                onClick={() => onClickCallback()}
            >
                {label}
            </Button>
        );
    } else {
        return (
            <ButtonDisabled
                buttonColor={buttonDisabledColor}
            >
                {label}
            </ButtonDisabled>
        );
    }
};

export default SimpleButton;