import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom";
import UserService from "../../services/UserService";
import axios from "axios";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import ApiKeyItemView from "./innerComponents/ApiKeyItemView";

const FranchiseName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: 15px;
  color: #757575;
`

const FranchiseAppKeyDetail = () => {

    const { t } = useTranslation();

    /* routing variables */
    const { refreshAll } = useOutletContext();
    const { groupId, franchiseId, keyId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    /* states */
    const [isLoading, setIsLoading] = useState(true);
    const [apiKeyList, setApiKeyList] = useState([]);
    const [franchiseInfo, setFranchiseInfo] = useState([]);

    /* utils */
    const hasIsInPath = (id) => {
        return location.pathname.split('/').includes(id);
    };

    /* fetch franchise info */
    const fetchFranchiseInfo = async (onSuccessCallback) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise/`
                + `${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setFranchiseInfo(res.data.item['franchiseDetail']);
            onSuccessCallback();
        } catch (e) {
            console.log(e);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    /* fetch franchise api key list */
    const fetchFranchiseApiKeyList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-app-api-key/`
                + `franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            /* check and assign */
            if (res.data.item['apiKeys'].length > 0) {
                setApiKeyList(res.data.item['apiKeys'][0][groupId][0][franchiseId]);
                if (location.pathname.split('/').length < 8) {
                    /* default redirection */
                    let newApiKeyId = res.data.item['apiKeys'][0][groupId][0][franchiseId][0]['franchiseAppApiKeyId'];
                    navigate(`/franchise-app-api/group/${groupId}/franchise/${franchiseId}/app-api-code/${newApiKeyId}`);
                }
            } else { /* no key */
                await setApiKeyList([]);
            }
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    /* initialization */
    const initializeAll = () => {
        fetchFranchiseInfo(fetchFranchiseApiKeyList);
    };

    /* effects */
    useEffect(() => {
        initializeAll();
    }, [groupId, franchiseId, keyId]);

    /* loading */
    const renderSpinner = () => {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Spinner
                    style={{
                        color: '#757575'
                    }}
                />
            </div>
        );
    };

    const renderNoKey = () => {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <span
                    style={{
                        fontSize: '14px',
                        color: '#757575'
                    }}
                >
                    {t("menu.franchiseApiKey.detail.message.noKey")}
                </span>
            </div>
        );
    };

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <Container fluid>
                {/* franchise name */}
                <Row>
                    <div className={"contentInner"}>
                        {
                            isLoading ?
                                renderSpinner()
                                :
                                <FranchiseName>
                                    {franchiseInfo['franchiseName']}
                                </FranchiseName>
                        }
                    </div>
                </Row>
                {/* key */}
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        <div
                            className={"contentInner"}
                            style={{
                                height: '735px',
                                overflow: 'auto'
                            }}
                        >
                            {/* key list */}
                            {
                                isLoading ?
                                    renderSpinner()
                                    :
                                    (apiKeyList.length > 0) ?
                                        apiKeyList.map(keyObj => {
                                            return (
                                                <ApiKeyItemView
                                                    key={keyObj['franchiseAppApiKeyId'] + hasIsInPath(keyObj['franchiseAppApiKeyId'])}
                                                    keyObj={keyObj}
                                                    selected={hasIsInPath(keyObj['franchiseAppApiKeyId'])}
                                                    onClick={() => navigate(`/franchise-app-api/group/${groupId}/franchise/${franchiseId}/app-api-code/${keyObj['franchiseAppApiKeyId']}`)}
                                                />
                                            );
                                        })
                                        :
                                        renderNoKey()
                            }
                        </div>
                    </Col>
                    <Col
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        <div
                            className={"contentInner"}
                            style={{
                                height: '735px'
                            }}
                        >
                            <Outlet
                                context={{
                                    initializeAll
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FranchiseAppKeyDetail;