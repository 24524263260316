import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Image} from "react-bootstrap";
import { GrLanguage } from "react-icons/gr";
import { MdLanguage } from "react-icons/md";
import axios from "axios";
import UserService from "../../../../services/UserService";

const TransButton = styled.button`
  background: none;
  border: none;
  z-index: 9998;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  &:focus {
    outline: none;
  }
`;

const DropDownItemButton = styled.button`
  background: none;
  border: none;
  z-index: 9998;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${(props) => props.color ? props.color : "#757575"};
  transition: all ease-in-out 0.2s;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: ${(props) => props.hoverColor ? props.hoverColor : "black"};
  }
`;

const DropDownContainer = styled.ul`
  position: absolute;
  right: -50px;
  top: 50px;
  width: 180px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.38);
  -moz-box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.38);
  box-shadow: 0px 0px 3px 0px rgba(105,105,105,0.38);
  z-index: 9998;
`;

const DropDownItem = styled.li`
  width: 100%;
  padding: 7px 0px;
  text-align: center;
`;


/* default lang */
const DEFAULT_LANG = "en-US";


const LangSelect = () => {

    const { t, i18n } = useTranslation();
    const areaRef = useRef();

    const [lang, setLang] = useState(sessionStorage.getItem("ims_language") ? DEFAULT_LANG : sessionStorage.getItem("ims_language"));
    const [languageMetaList, setLanguageMetaList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );

    const [open, setOpen] = useState(false);

    const handleLanguageChange = async (lang) => {
        await setLang(lang);
        if (lang === i18n.language) {
            return;
        }
        i18n.changeLanguage(lang);
        const axiosCall = () => axios.put(`${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${window.sessionStorage.getItem("ims_adminId")}/language`,
            JSON.stringify({
                adminLang : lang
            }),
            {
                headers: {
                    Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                    "Content-Type" : "application/json"
                }
            }
        );
        try {
            await UserService.updateToken(axiosCall);
            sessionStorage.setItem("ims_language", lang);
        } catch (e) {
            console.log(e);
        }
    };

    const NavItem = (props) => {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                }}
            >
                <TransButton className="icon-button" onClick={() => setOpen(!open)}>
                    {props.icon}
                </TransButton>
                {open && props.children}
            </div>
        );
    };

    useEffect(() => {
        const handleFocus = (e) => {
            if (areaRef.current && !areaRef.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mouseup", handleFocus);
        return () => { document.removeEventListener("mouseup", handleFocus); }
    }, [areaRef]);

    return (
        <NavItem
            icon={
                <MdLanguage
                    size={"25"}
                    color={
                        open ?
                            "#696969"
                            :
                            "#c0c0c0"
                    }
                />
            }
        >
            <DropDownContainer
                ref={areaRef}
            >
                    {
                        languageMetaList &&
                        languageMetaList.map(languageInfo => {
                            return (
                                <DropDownItem
                                    key={languageInfo['languageMetadataBrowserCode']}
                                >
                                    <DropDownItemButton
                                        color={
                                            (i18n.language === languageInfo['languageMetadataBrowserCode']) ?
                                                '#696969'
                                                :
                                                '#c2c2c2'
                                        }
                                        onClick={() => {
                                            setOpen(false);
                                            handleLanguageChange(languageInfo['languageMetadataBrowserCode']);
                                        }}
                                    >
                                        {languageInfo['languageMetadataLabel']}
                                    </DropDownItemButton>
                                </DropDownItem>
                            );
                        })
                    }
            </DropDownContainer>
        </NavItem>
    );
};

export default LangSelect;