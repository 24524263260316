const ImsSystemRole = {
    MANAGER_OF_COMPANY: "manager_of_company",
    STAFF_MANAGEMENT: "staff_management",
    CUSTOMER_MANAGEMENT : "customer_management",
    ORDER_MANAGEMENT : "order_management",
    PRODUCT_MANAGEMENT : "product_management",
    BRANCH_MANAGEMENT : "branch_management",
    BOARD_MANAGEMENT : "board_management",
    SOFTWARE_MANAGEMENT : "software_management",
    BOOTH_MANAGEMENT : "booth_management",
    QNA_MANAGEMENT : "qna_management"
};

Object.freeze(ImsSystemRole);

export default ImsSystemRole;