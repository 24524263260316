import UserService from "./UserService";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`;

const shortDelayStr = `${process.env.REACT_APP_DELETION_SHORT_DELAY_IN_SECOND}`;
const mediumDelayStr = `${process.env.REACT_APP_DELETION_MED_DELAY_IN_SECOND}`;
const longDelayStr = `${process.env.REACT_APP_DELETION_LONG_DELAY_IN_SECOND}`;

const ValidEntities = {
    ADMIN_DEPARTMENT : "AdminDepartment",
    FRANCHISE : "Franchise",
    FRANCHISE_APP_API_KEY : "FranchiseAppApiKey",
    BOARD : "Board",
    BOOTH_NAME : "BoothName",
    BOOTH_VERSION : "BoothVersion",
    FAQ : "Faq",
    MENU_CATEGORY : "MenuCategory",
    MENU_OPTION_CATEGORY : "MenuOptionCategory",
    MENU_OPTION : "MenuOption",
    PRODUCT_CATEGORY : "ProductCategory",
    QNA_CATEGORY : "QnaCategory",
    SCHEDULE : "Schedule",
    QNA : "Qna",
    QNA_ANSWER : "QnaAnswer",
    ADMIN : "Admin",
    BOOTH : "Booth",
    GROUP : "Group",
    COMPANY : "Company",
    FRANCHISE_MENU : "FranchiseMenu",
    ORDER : "Order",
    PRODUCT : "Product",
    CUSTOMER : "Customer",
    BRANCH : "Branch"
};


/** Do the short deletion */
const doDelete = async (entity, id, onSuccessCallback, onFailureCallback) => {
    let fetchUrl = `${baseUrl}/${id}`;
    try {
        await UserService.updateToken(() => axios.delete(
            fetchUrl,
            {
                headers: {
                    Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                    "Content-Type": "application/json"
                }
            }
        ));
    } catch (e) {
        await onFailureCallback(e);
        return;
    }
    await onSuccessCallback();
};

/** Register the delayed deletion */
const registerDelete = async (entity, id, onSuccessCallback, onFailureCallback) => {
    let fetchUrl = `${baseUrl}/${id}/register`;
    try {
        await UserService.updateToken(() => axios.delete(
            fetchUrl,
            {
                headers: {
                    Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                    "Content-Type": "application/json"
                }
            }
        ));
    } catch (e) {
        await onFailureCallback(e);
        return;
    }
    await onSuccessCallback();
};

/** Undo the delayed deletion */
const undoDelete = async (entity, id, onSuccessCallback, onFailureCallback) => {
    let fetchUrl = `${baseUrl}/${id}/cancel`;
    try {
        await UserService.updateToken(() => axios.delete(
            fetchUrl,
            {
                headers: {
                    Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                    "Content-Type": "application/json"
                }
            }
        ));
    } catch (e) {
        await onFailureCallback(e);
        return;
    }
    await onSuccessCallback();
};

const getShortDelay = () => {
    try {
        return parseInt(shortDelayStr);
    } catch (e) {
        return 10;
    }
};

const getMediumDelay = () => {
    try {
        return parseInt(mediumDelayStr);
    } catch (e) {
        return 10;
    }
};

const getLongDelay = () => {
    try {
        return parseInt(longDelayStr);
    } catch (e) {
        return 10;
    }
};

Object.freeze(ValidEntities);

const DeleteService = {
    ValidEntities,
    doDelete,
    registerDelete,
    undoDelete,
    getShortDelay,
    getMediumDelay,
    getLongDelay
};

export default DeleteService;