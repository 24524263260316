import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import TextCell from "./table/cells/TextCell";
import ErrorLevelCell from "./table/cells/ErrorLevelCell";
import HoverMessageCell from "./table/cells/HoverMessageCell";
import DateTimeCell from "./table/cells/DateTimeCell";
import BoothService from "../../axiosServices/BoothService";
import UserService from "../../../../../services/UserService";
import axios from "axios";
import _ from "lodash";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import TitleAndInputBox from "../../../../common/dataView/TitleAndInputBox";
import PaginationHandler from "../../../../common/pagination/PaginationHandler";
import ErrorTable from "./table/ErrorTable";

/* ------------------------------ */
/* static variable for the "size" */
const ITEM_SIZE = 10;
/* ------------------------------ */
/* ------------------------------ */


const ResolvedErrorView = () => {

    const { t } = useTranslation();
    const { boothId } = useParams();

    /* pagination */
    const [maxPageIndex, setMaxPageIndex] = useState(0);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalItemNum, setTotalItemNum] = useState(0);

    /* search */
    const [fromDateStr, setFromDateStr] = useState(""); /* format : yyyy-MM-dd HH:mm:ss */
    const [toDateStr, setToDateStr] = useState(""); /* format : yyyy-MM-dd HH:mm:ss */

    /* states */
    const [jsonSchema, setJsonSchema] = useState({});
    const [pastErrorList, setPastErrorList] = useState([]);
    const [errorCodeDefineList, setErrorCodeDefineList] = useState([]);
    const [isJsonLoading, setIsJsonLoading] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);


    /* column data for the table */
    const columnData = [
        {
            accessor: "errorCode",
            Header: t("menu.boothControl.sideMenus.boothErrors.fields.errorCode"),
            Cell: ({cell: {value}}) => <TextCell value={value} />
        },
        {
            accessor: "level",
            Header: t("menu.boothControl.sideMenus.boothErrors.fields.level"),
            Cell: ({cell: {value}}) => <ErrorLevelCell value={value} />
        },
        {
            accessor: "device",
            Header: t("menu.boothControl.sideMenus.boothErrors.fields.device"),
            Cell: ({cell: {value}}) => <TextCell value={value} />
        },
        {
            accessor: "title",
            Header: t("menu.boothControl.sideMenus.boothErrors.fields.title"),
            Cell: ({cell: {value}}) => <HoverMessageCell value={value} />
        },
        {
            accessor: "lastProbeDate",
            Header: t("menu.boothControl.sideMenus.boothErrors.fields.lastProbeDate"),
            Cell: ({cell: {value}}) => <DateTimeCell value={value} />
        },
        {
            accessor: "resolvedDate",
            Header: t("menu.boothControl.sideMenus.boothErrors.fields.resolvedDate"),
            Cell: ({cell: {value}}) => <DateTimeCell value={value} />
        }
    ];

    const initialize = async () => {
        /* set current date time */
        const currentDateTime = new Date();
        let currentTimeStr = craftDateTimeStr(currentDateTime);
        /* minus a year */
        currentDateTime.setFullYear(currentDateTime.getFullYear() - 1);
        let beforeOneYearTimeStr = craftDateTimeStr(currentDateTime);
        /* set dates */
        await setFromDateStr(beforeOneYearTimeStr);
        await setToDateStr(currentTimeStr);

        await setIsJsonLoading(true);
        await setIsDataLoading(true);
        fetchBoothInfo();
    };

    const fetchBoothInfo = async (callback) => {
        await setIsJsonLoading(true);
        const onSuccessCallback = async (data) => {
            await fetchJsonSchema(data['boothVersionInfo']['boothJsonVersion'], fetchErrorList);
        };
        const onFailureCallback = async (e) => {
            await setJsonSchema({});
            await setPastErrorList([]);
            await setIsJsonLoading(false);
            await setIsDataLoading(false);
            console.log(e);
        };
        await BoothService.fetchBoothBasicInfo(boothId, onSuccessCallback, onFailureCallback);
    };

    const fetchJsonSchema = async (boothJsonSchemaVersion, callback) => {
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
            + `/booth-control-wrapper/schema/${boothJsonSchemaVersion}`;
        try {
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setJsonSchema(res.data.item['booth']);
            await setIsJsonLoading(false);
            await callback();
        } catch (e) {
            console.log(e);
            await setJsonSchema({});
            await setPastErrorList([]);
            await setIsJsonLoading(false);
            await setIsDataLoading(false);
        }
    };

    const fetchErrorList = async () => {
        await setIsDataLoading(true);
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
            + `/booth/${boothId}/error-history/resolved`
            + `?size=${ITEM_SIZE}`
            + `&page=${currentPageIndex}`
            + `&fromDateTimeStr=${fromDateStr}`
            + `&toDataTimeStr=${toDateStr}`
        try {
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            await setMaxPageIndex(res.data.item['maxPage']);
            await setCurrentPageIndex(res.data.item['currentPage']);
            await setTotalItemNum(res.data.item['totalItems']);
            await setPastErrorList(res.data.item['boothErrorList']);
            await setErrorCodeDefineList(res.data.item['boothErrorCodeList']);
            await setIsDataLoading(false);
        } catch (e) {
            console.log(e);
            await setPastErrorList([]);
            await setIsDataLoading(false);
        }
    };

    /* utils */
    const formatDateTimeInput = (value) => {
        let splitList = value.split("T");
        return splitList[0] + " " + splitList[1] + ":00"
    };

    const isValidDateTimePair = (fromDT, toDT) => {
        /* format : yyyy-MM-dd HH:mm:ss */
        const parseDateTime = (dateTimeStr) => {
            const [date, time] = dateTimeStr.split(' ');
            const [year, month, day] = date.split('-').map(num => parseInt(num, 10));
            const [hour, minute, second] = time.split(':').map(num => parseInt(num, 10));

            return new Date(year, month - 1, day, hour, minute, second);
        };

        const fromDate = parseDateTime(fromDT);
        const toDate = parseDateTime(toDT);

        return fromDate < toDate;
    };

    const handleFromDateInput = (value) => {
        const fromDTStr = formatDateTimeInput(value);
        if (isValidDateTimePair(fromDTStr, toDateStr)) {
            setFromDateStr(fromDTStr);
        }
    };

    const handleToDateInput = (value) => {
        const toDTStr = formatDateTimeInput(value);
        if (isValidDateTimePair(fromDateStr, toDTStr)) {
            setToDateStr(toDTStr);
        }
    };

    const handleBeforePage = () => {
        if (isDataLoading) {
            return;
        }
        if (currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };

    const handleAfterPage = () => {
        if (isDataLoading) {
            return;
        }
        if (currentPageIndex < maxPageIndex) {
            setCurrentPageIndex(currentPageIndex + 1);
        }
    };

    const handleGoFirst = () => {
        if (isDataLoading) {
            return;
        }
        setCurrentPageIndex(0);
    }

    const handleGoLast = () => {
        if (isDataLoading) {
            return;
        }
        setCurrentPageIndex(maxPageIndex);
    }

    const craftDateTimeStr = (date) => {
        /* format : yyyy-MM-dd HH:mm:ss */
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = "00"; /* second setting not supported */

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const formatErrorCodeList = (prevErrorCodeList) => {
        let newErrorCodeList = [];
        prevErrorCodeList.map(prevData => {
            let newData = {};
            newData['id'] = prevData['errorCode'] + prevData['lastProbeDate'];
            newData['lastProbeDate'] = prevData['lastProbeDate'];
            newData['resolvedDate'] = prevData['resolvedDate'];
            newData['errorCode'] = prevData['errorCode'];
            /** level and device */
            /* find by "errorCode" */
            let isFound = false;
            for (let i=0 ; i<errorCodeDefineList.length ; i++) {
                if (errorCodeDefineList[i]['errorCode'] === prevData['errorCode']) {
                    isFound = true;
                    newData['level'] = errorCodeDefineList[i]['level'];
                    newData['title'] = [errorCodeDefineList[i][t("language.dataKey.boothErrorCode.titleF")], errorCodeDefineList[i][t("language.dataKey.boothErrorCode.descriptionF")]];
                    newData['device'] = findDeviceNameFromSchema(jsonSchema, errorCodeDefineList[i]['device']);
                }
            }
            if (!isFound) {
                newData['level'] = "Unknown";
                newData['title'] = [t("menu.boothControl.sideMenus.boothErrors.fields.Unknown"), t("menu.boothControl.sideMenus.boothErrors.fields.Unknown")];
                newData['device'] = t("menu.boothControl.sideMenus.boothErrors.fields.Unknown");
            }
            newErrorCodeList.push(newData);
        });
        return newErrorCodeList;
    };

    const findDeviceNameFromSchema = (schema, id) => {
        if (schema == null || (_.isEqual(schema, {}))) {
            return t("menu.boothControl.sideMenus.boothErrors.fields.Unknown");
        }
        let deviceList = jsonSchema['spec']['devices'];
        let isFound = false;
        let res = "";
        deviceList.map(deviceObj => {
            if (deviceObj['metadata']['id'] === id) {
                isFound = true;
                res = deviceObj['metadata'][t("language.dataKey.boothControl.json.label")];
            }
        });
        if (isFound) {
            return res;
        } else {
            return t("menu.boothControl.sideMenus.boothErrors.fields.Unknown");
        }
    };

    /* effects */
    useEffect(() => {
        initialize();
    }, [boothId]);

    useEffect(() => {
        fetchErrorList();
    }, [currentPageIndex, fromDateStr, toDateStr]);

    return (
        <>
            <div
                style={{
                    marginTop: '5px',
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '5px'
                }}
            >
                <Container fluid>
                    <Row>
                        <Col
                            style={{
                                padding: '0px'
                            }}
                        >
                            {/* from */}
                            <TitleAndInputBox
                                title={t("menu.boothControl.sideMenus.boothErrors.search.startDate")}
                                value={fromDateStr}
                                onChange={handleFromDateInput}
                                isForEdit={true}
                                type={"datetime-local"}
                                isValid={true}
                                isEnabled={true}
                            />
                        </Col>
                        <Col
                            style={{
                                padding: '0px',
                                paddingLeft: '10px'
                            }}
                        >
                            {/* to */}
                            <TitleAndInputBox
                                title={t("menu.boothControl.sideMenus.boothErrors.search.endDate")}
                                value={toDateStr}
                                onChange={handleToDateInput}
                                isForEdit={true}
                                type={"datetime-local"}
                                isValid={true}
                                isEnabled={true}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                style={{
                    marginTop: '10px',
                    width: '100%',
                    overflow: 'auto',
                    backgroundColor: 'white',
                    height: '477px'
                }}
            >
                {
                    (isDataLoading || isJsonLoading) &&
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            padding: '50px'
                        }}
                    >
                        <Spinner size={"sm"} />
                    </div>
                }
                {
                    !isDataLoading &&
                    !isJsonLoading &&
                    (pastErrorList.length === 0) &&
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            padding: '50px',
                            fontSize: '14px',
                            color: '#757575'
                        }}
                    >
                        {t("menu.boothControl.sideMenus.boothErrors.messages.noErrorExist")}
                    </div>
                }
                {/* table view */}
                {
                    !isDataLoading &&
                    !isJsonLoading &&
                    (pastErrorList.length > 0) &&
                    <ErrorTable
                        columns={columnData}
                        data={formatErrorCodeList(pastErrorList)}
                    />
                }
            </div>
            <div
                style={{
                    marginTop: '10px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    padding: '10px'
                }}
            >
                <div
                    style={{
                        width: '350px',
                        height: '50px'
                    }}
                >
                    <PaginationHandler
                        goBefore={handleBeforePage}
                        goAfter={handleAfterPage}
                        goFirst={handleGoFirst}
                        goLast={handleGoLast}
                        pageSize={ITEM_SIZE}
                        currentIndex={currentPageIndex}
                        maxIndex={maxPageIndex}
                        totalItemNumber={totalItemNum}
                    />
                </div>
            </div>
        </>
    );
};

export default ResolvedErrorView;