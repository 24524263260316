import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import TitleAndColDiv from "../../../menu/franchiseMenu/components/innerRoute/recipe/components/TitleAndColDiv";
import styled from "styled-components";
import OverlayToolTip from "../../../menu/common/toolTip/OverlayToolTip";
import {HiOutlineStatusOnline} from "react-icons/hi";
import MenuRecipeDiffView from "./innerDiffView/MenuRecipeDiffView";
import OptionRecipeDiffView from "./innerDiffView/OptionRecipeDiffView";
import {CiWarning} from "react-icons/ci";
import {GoCheckCircle} from "react-icons/go";
import BoothMenuDiffAxiosService from "../common/service/BoothMenuDiffAxiosService";
import {toast} from "react-toastify";
import AlertViewForBoothDiff from "../common/component/AlertViewForBoothDiff";
import AuthService from "../../../../services/AuthService";
import ImsSystemRole from "../../../../auth/roles/ImsSystemRole";

const SimpleButton = styled.button`
  font-size: 14px;
  padding: 1px 10px;
  color: ${(props) => props.enabled ? "#fc7242" : "#c2c2c2"};
  border: 1px solid ${(props) => props.enabled ? "#fc7242" : "#c2c2c2"};
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  background-color: white;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.enabled ? "#ffeee6" : "white"};
  }
`;

const AreaTitle = styled.div`
  font-size: 15px;
  color: #757575;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
`;

const BoothSyncButton = styled.button`
  border: none;
  border-radius: 5px;
  background: none;
  transition: all ease-in-out 0.2s;
  color: #fc7242;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: red;
  }
`;

const BoothMenuDiffView = () => {

    const { t } = useTranslation();
    const { boothId, menuId } = useParams();
    const { boothInfo } = useOutletContext();
    const navigate = useNavigate();

    /* states */
    const [isBoothSync, setIsBoothSync] = useState(false);
    const [isBoothSyncLoading, setIsBoothSyncLoading] = useState(false);

    /* fetch booth sync */
    const getBoothSyncStatus = async () => {
        await setIsBoothSyncLoading(true);
        const successCallback = async (data) => {
            let syncStatus = data['boothSyncInfo']['boothMenuSyncInformation'];
            await setIsBoothSync(syncStatus['synchronized']);
            await setIsBoothSyncLoading(false);
        };
        const failureCallback = async (err) => {
            /* not found */
            if (err.response.status === 404) {
                navigate('/not-found');
            }
            await setIsBoothSyncLoading(false);
            console.log(err);
        };
        BoothMenuDiffAxiosService.getBoothMenuSyncInfo(boothId, menuId, successCallback, failureCallback);
    };

    const retryBoothSync = async () => {
        await setIsBoothSyncLoading(true);
        const successCallback = async (data) => {
            await getBoothSyncStatus();
            await setIsBoothSyncLoading(false);
        };
        const failureCallback = async (err) => {
            /* not found */
            if (err.response.status === 404) {
                navigate('/not-found');
            }
            await toast.error(<AlertViewForBoothDiff message={t("message.failed")} />);
            await setIsBoothSyncLoading(false);
            console.log(err);
        };
        BoothMenuDiffAxiosService.tryBoothMenuSyncInfo(boothId, menuId, successCallback, failureCallback);
    };

    /* effects */
    useEffect(() => {
        getBoothSyncStatus();
    }, [menuId]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            marginBottom: '0px'
                        }}
                    >
                        <AreaTitle>
                            {t("menu.boothMenuDiff.menuRecipe.title")}
                        </AreaTitle>
                        <div
                            style={{
                                marginLeft: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {/* sync-status */}
                            {
                                isBoothSyncLoading &&
                                <OverlayToolTip
                                    text={t("menu.franchiseMenu.boothSync.detail.ongoing")}
                                    direction={"top"}
                                >
                                    <div
                                        style={{
                                            width: '30px',
                                            height: '50px',
                                            marginLeft: 'auto',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Spinner
                                            style={{
                                                color: "#c2c2c2"
                                            }}
                                            size={"sm"}
                                        />
                                    </div>
                                </OverlayToolTip>
                            }
                            {
                                (!isBoothSyncLoading) && (!isBoothSync) &&
                                <OverlayToolTip
                                    text={t("menu.franchiseMenu.boothSync.detail.failed")}
                                    direction={"top"}
                                >
                                    <div
                                        style={{
                                            width: '30px',
                                            height: '50px',
                                            marginLeft: 'auto',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <CiWarning
                                            size={"25"}
                                            color={"red"}
                                        />
                                    </div>
                                </OverlayToolTip>
                            }
                            {
                                (!isBoothSyncLoading) && isBoothSync &&
                                <OverlayToolTip
                                    text={t("menu.franchiseMenu.boothSync.detail.success")}
                                    direction={"top"}
                                >
                                    <div
                                        style={{
                                            width: '30px',
                                            height: '50px',
                                            marginLeft: 'auto',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <GoCheckCircle
                                            size={"25"}
                                            color={"green"}
                                        />
                                    </div>
                                </OverlayToolTip>
                            }
                            {/* retry-button */}
                            <SimpleButton
                                enabled={(!isBoothSyncLoading) && (!isBoothSync) && AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "PUT")}
                                onClick={retryBoothSync}
                            >
                                {t("menu.franchiseMenu.boothSync.button.retry")}
                            </SimpleButton>
                        </div>
                    </div>
                    <div
                        className={"contentInner"}
                        style={{
                            height: '655px',
                            width: '100%'
                        }}
                    >
                        {/* menu recipe */}
                        <MenuRecipeDiffView
                            boothVersionId={boothInfo['boothVersionInfo']['boothVersionId']}
                        />
                    </div>
                </Col>
                <Col
                    style={{
                        padding: '0px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: '28px'
                        }}
                    >
                        <AreaTitle>
                            {t("menu.boothMenuDiff.optionRecipe.title")}
                        </AreaTitle>
                    </div>
                    <div
                        className={"contentInner"}
                        style={{
                            height: '655px',
                            width: '100%'
                        }}
                    >
                        {/* option recipe */}
                        <OptionRecipeDiffView
                            boothVersionId={boothInfo['boothVersionInfo']['boothVersionId']}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default BoothMenuDiffView;