import React from 'react';
import AuthService from "../services/AuthService";
import NotAllowed from "./NotAllowed";

const RenderOnRole = ({ children, roleName, type, allowedRing, isForSuperMaster, nothingOnRejected }) => {
    if (allowedRing < 0 || allowedRing > 2) {
        return false;
    }

    if (allowedRing === 0) {
        if (isForSuperMaster) {
            if (AuthService.hasSuperMasterAuth()) {
                return children;
            } else {
                return nothingOnRejected ? <></> : <NotAllowed />;
            }
        } else {
            if (AuthService.hasAuthForOverRingOne()) {
                return children;
            } else {
                return nothingOnRejected ? <></> : <NotAllowed />;
            }
        }
    } else if (allowedRing === 1) {
        if (AuthService.hasAuthForOverRingTwo()) {
            return children;
        } else {
            return nothingOnRejected ? <></> : <NotAllowed />;
        }
    } else { /* allowedRing === 2 */
        if (AuthService.hasAuthByRoleNameAndType(roleName, type.toUpperCase())) {
            return children;
        } else {
            return nothingOnRejected ? <></> : <NotAllowed />;
        }
    }
};

export default RenderOnRole;