import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import DataCompareService from "../../services/DataCompareService";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import {Link, useNavigate} from "react-router-dom";
import RenderOnRole from "../../auth/RenderOnRole";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import LanguageInput from "../../language/component/LanguageInput";
import LanguageSelectBox from "../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";

const requestBodyInit = {
    productProductCategoryId : "",
    productName: "",
    productName1 : null,
    productName2 : null,
    productName3 : null,
    productName4 : null,
    productName5 : null,
    productDescription : "",
    productDescription1 : null,
    productDescription2 : null,
    productDescription3 : null,
    productDescription4 : null,
    productDescription5 : null,
    productAmount : "",
    productUnit : "",
    productUnitAmount : "",
    productPrice : "",
    productPriceUnit : "",
    productStatusCode : 1,
    productGroupId : ""
}

const isValidMapInit = {
    productProductCategoryId : true,
    productName: false,
    productName1 : true,
    productName2 : true,
    productName3 : true,
    productName4 : true,
    productName5 : true,
    productDescription : false,
    productDescription1 : true,
    productDescription2 : true,
    productDescription3 : true,
    productDescription4 : true,
    productDescription5 : true,
    productAmount : false,
    productUnit : false,
    productUnitAmount : false,
    productPrice : false,
    productPriceUnit : false,
    productStatusCode : true,
    productGroupId : true
};

const ProductAdd = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const productStatusList = [
        {
            value : 0,
            statusName: t("menu.product.status.inactive")
        },
        {
            value : 1,
            statusName: t("menu.product.status.active")
        },
        {
            value : 2,
            statusName: t("menu.product.status.out_of_order")
        }
    ];

    /* group list */
    const [groupList, setGroupList] = useState([]);
    /* product category list */
    const [productCategoryList, setProductCategoryList] = useState([]);
    const [groupProductCategoryList, setGroupProductCategoryList] = useState([]);

    /* requestBody */
    const [requestBody, setRequestBody] = useState(requestBodyInit);

    /* trackers */
    const [isEdited, setIsEdited] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);

    /* state for language */
    const targetFieldsString = `"${t("common.name")}", "${t("common.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* utils */
    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj =>{
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    }

    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    }

    /* fetch group list */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/group`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setGroupList(res.data.item.groupList);
            updateRequestBody("productGroupId", res.data.item.groupList[0].groupId);
        } catch (e) {
            console.log(e);
        }
    }

    /* fetch category list */
    const fetchProductCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product-category`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers : {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            if (res.data["error_code"] !== "GET_EMPTY_DATA") {
                setProductCategoryList(res.data.item.productCategoryList);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* handlers */

    const handleProductAmountInput = (value) => {
        updateRequestBody("productAmount", parseFloat(value));
    };

    const handleProductUnitInput = (value) => {
        updateRequestBody("productUnit", value);
    };

    const handleProductUnitAmountInput = (value) => {
        updateRequestBody("productUnitAmount", parseFloat(value));
    };

    const handleProductPriceInput = (value) => {
        updateRequestBody("productPrice", parseFloat(value));
    };

    const handleProductPriceUnitInput = (value) => {
        updateRequestBody("productPriceUnit", value);
    };

    const handleProductStatusCodeChange = (value) => {
        updateRequestBody("productStatusCode", parseInt(value));
    };

    const handleGroupIdChange = (value) => {
        updateRequestBody("productGroupId", value);
    };

    const handleProductCategoryChange = (value) => {
        updateRequestBody("productProductCategoryId", value);
    };

    /* validators */

    const checkProductAmountValidity = () => {
        if (typeof requestBody["productAmount"] !== "number") {
            updateIsValidMap("productAmount", false);
        } else {
            updateIsValidMap("productAmount", true);
        }
    }

    const checkProductUnitValidity = () => {
        if (requestBody["productUnit"] === "") {
            updateIsValidMap("productUnit", false);
        } else {
            updateIsValidMap("productUnit", true);
        }
    }

    const checkProductUnitAmountValidity = () => {
        if (typeof requestBody["productUnitAmount"] !== "number") {
            updateIsValidMap("productUnitAmount", false);
        } else {
            updateIsValidMap("productUnitAmount", true);
        }
    }

    const checkProductPriceValidity = () => {
        if (typeof requestBody["productPrice"] !== "number") {
            updateIsValidMap("productPrice", false);
        } else {
            updateIsValidMap("productPrice", true);
        }
    }

    const checkProductPriceUnitValidity = () => {
        if (requestBody["productPriceUnit"] === "") {
            updateIsValidMap("productPriceUnit", false);
        } else {
            updateIsValidMap("productPriceUnit", true);
        }
    }

    /* post product */
    const postProductInformation = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/product`;
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(requestBody),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            navigate('/product');
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            console.log(e);
        }
    }

    /* effects */
    useEffect(() => {
        fetchGroupList();
        fetchProductCategoryList();
    }, []);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    useEffect(() => {
        checkProductAmountValidity();
    }, [requestBody["productAmount"]]);

    useEffect(() => {
        checkProductUnitValidity();
    }, [requestBody["productUnit"]]);

    useEffect(() => {
        checkProductUnitAmountValidity();
    }, [requestBody["productUnitAmount"]]);

    useEffect(() => {
        checkProductPriceValidity();
    }, [requestBody["productPrice"]]);

    useEffect(() => {
        checkProductPriceUnitValidity();
    }, [requestBody["productPriceUnit"]]);

    useEffect(() => {
        if (requestBody["productGroupId"] === "") {
            return;
        }
        let filteredList = productCategoryList.filter(product => {
            return (product.productCategoryGroupId === requestBody["productGroupId"]);
        });
        if (filteredList.length === 0) {
            updateRequestBody("productProductCategoryId", "");
            updateIsValidMap("productProductCategoryId", false);
        } else {
            updateRequestBody("productProductCategoryId", filteredList[0].productCategoryId);
            updateIsValidMap("productProductCategoryId", true);
        }
        setGroupProductCategoryList(filteredList);
    }, [requestBody["productGroupId"], productCategoryList]);

    return (
        <RenderOnRole type={"POST"} roleName={ImsSystemRole.PRODUCT_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/product"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.product.title"), t("menu.product.add.title")]}
                    depth={2}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                <Container fluid>
                    {/* main area */}
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                height: '650px'
                            }}
                        >
                            <Container fluid>
                                <Row>
                                    <LanguageSelectBox
                                        title={t("language.selectBox.title.product")}
                                        languageMetadataList={languageMetadataList}
                                        currentLang={currentLang}
                                        setCurrentLang={setCurrentLang}
                                        setIsHighlightOn={setIsHighlightOn}
                                        targetFieldString={targetFieldsString}
                                    />
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <LanguageInput
                                        type={"text"}
                                        title={t("common.name") + ` (${t("language.callName." + currentLang)})`}
                                        dataKeyTranslationPath={"language.dataKey.product.name"}
                                        valueMap={requestBody}
                                        updateValueMap={updateRequestBody}
                                        isValidMap={isValidMap}
                                        updateIsValidMap={updateIsValidMap}
                                        isForEdit={true}
                                        currentLang={currentLang}
                                        isHighlightOn={isHighlightOn}
                                    />
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <LanguageInput
                                        type={"textarea"}
                                        title={t("common.description") + ` (${t("language.callName." + currentLang)})`}
                                        dataKeyTranslationPath={"language.dataKey.product.description"}
                                        valueMap={requestBody}
                                        updateValueMap={updateRequestBody}
                                        isValidMap={isValidMap}
                                        updateIsValidMap={updateIsValidMap}
                                        isForEdit={true}
                                        height={"150"}
                                        currentLang={currentLang}
                                        isHighlightOn={isHighlightOn}
                                    />
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.amount")}
                                            value={requestBody["productAmount"]}
                                            onChange={handleProductAmountInput}
                                            isForEdit={true}
                                            type={"number"}
                                            isValid={isValidMap["productAmount"]}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.unitAmount")}
                                            value={requestBody["productUnitAmount"]}
                                            onChange={handleProductUnitAmountInput}
                                            isForEdit={true}
                                            type={"number"}
                                            isValid={isValidMap["productUnitAmount"]}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.unit")}
                                            value={requestBody["productUnit"]}
                                            onChange={handleProductUnitInput}
                                            isForEdit={true}
                                            type={"text"}
                                            isValid={isValidMap["productUnit"]}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.price")}
                                            value={requestBody["productPrice"]}
                                            onChange={handleProductPriceInput}
                                            isForEdit={true}
                                            type={"number"}
                                            isValid={isValidMap["productPrice"]}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.priceUnit")}
                                            value={requestBody["productPriceUnit"]}
                                            onChange={handleProductPriceUnitInput}
                                            isForEdit={true}
                                            type={"text"}
                                            isValid={isValidMap["productPriceUnit"]}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                >
                                    <Col
                                        style={{ padding: '0px' }}
                                    >
                                        <TitleAndSelectBox
                                            title={t("common.status")}
                                            innerValue={requestBody["productStatusCode"]}
                                            onChange={handleProductStatusCodeChange}
                                            valueList={productStatusList}
                                            valueSelector={"value"}
                                            viewSelector={"statusName"}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndSelectBox
                                            title={t("common.group")}
                                            innerValue={requestBody["productGroupId"]}
                                            onChange={handleGroupIdChange}
                                            valueList={groupList}
                                            valueSelector={"groupId"}
                                            viewSelector={"groupName"}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: '0px', paddingLeft: '10px' }}
                                    >
                                        <TitleAndSelectBox
                                            title={t("common.category")}
                                            innerValue={requestBody["productProductCategoryId"]}
                                            onChange={handleProductCategoryChange}
                                            valueList={groupProductCategoryList}
                                            valueSelector={"productCategoryId"}
                                            viewSelector={t("language.dataKey.product.category.name")}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Row>
                    {/* buttons */}
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                height: '50px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'right'
                            }}
                        >
                            <div
                                style={{ marginRight: '10px' }}
                            >
                                <Link
                                    id={"edit-btn"}
                                    to={`/product`}
                                >
                                    {t("button.cancel")}
                                </Link>
                            </div>
                            <div>
                                {
                                    isValid
                                        ?
                                        <Link
                                            id={"save-btn"}
                                            onClick={() => postProductInformation()}
                                        >
                                            {t("button.save")}
                                        </Link>
                                        :
                                        <Link
                                            id={"save-btn-disabled"}
                                        >
                                            {t("button.save")}
                                        </Link>
                                }
                            </div>
                        </div>
                    </Row>
                </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default ProductAdd;