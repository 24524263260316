import React, {useEffect, useState} from 'react';
import uuid from 'react-uuid'
import {useTranslation} from "react-i18next";
import SelectSearch from "react-select-search";
import {createGlobalStyle} from "styled-components";
import backgroundArrow from "../../common/style/arrow-down.png";

const GlobalStyle = createGlobalStyle`
  @import url('https://rsms.me/inter/inter.css');

  /**
   * Main wrapper
   */
  .select-search-container {
    --select-search-background: #fff;
    --select-search-border: #ebebeb;
    --select-search-selected: #fc7242;
    --select-search-text: #000;
    --select-search-subtle-text: #757575;
    --select-search-inverted-text: var(--select-search-background);
    --select-search-highlight: #eff1f5;
    --select-search-font: 'Inter', sans-serif;
    height: auto;
    width: 100%;
    position: relative;
    color: var(--select-search-text);
  }

  .select-search-container *,
  .select-search-container *::after,
  .select-search-container *::before {
    box-sizing: inherit;
  }

  .select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    height: auto;
    width: 100%;
    padding: 10px;
    background: var(--select-search-background);
    border: 1px solid var(--select-search-border);
    color: var(--select-search-text);
    border-radius: 5px;
    outline: none;
    font-size: 13px;
    font-weight: normal;
    text-align: left;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    background: url(${backgroundArrow}) no-repeat 96% 50%/24px auto;
    transition: all ease-in-out 0.2s;
  }

  .select-search-is-multiple .select-search-input {
    margin-bottom: -2px;
  }

  .select-search-is-multiple .select-search-input {
    border-radius: 3px 3px 0 0;
  }

  .select-search-input::-webkit-search-decoration,
  .select-search-input::-webkit-search-cancel-button,
  .select-search-input::-webkit-search-results-button,
  .select-search-input::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  .select-search-input[readonly] {
    cursor: pointer;
  }

  .select-search-is-disabled .select-search-input {
    cursor: not-allowed;
  }

  .select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input,
  .select-search-container:not(.select-search-is-disabled) {
    border-color: var(--select-search-selected);
  }

  .select-search-select {
    background: var(--select-search-background);
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border: 1px solid var(--select-search-border);
    overflow: auto;
    max-height: 360px;
  }

  .select-search-container:not(.select-search-is-multiple) .select-search-select {
    position: absolute;
    z-index: 2;
    top: 58px;
    right: 0;
    left: 0;
    border-radius: 3px;
    display: none;
  }

  .select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
    display: block;
  }

  .select-search-has-focus .select-search-select {
    border-color: var(--select-search-selected);
  }

  .select-search-options {
    list-style: none;
  }

  .select-search-option,
  .select-search-not-found {
    display: block;
    height: 42px;
    width: 100%;
    padding: 0 16px;
    background: var(--select-search-background);
    border: none;
    outline: none;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.01rem;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    transition: all ease-in-out 0.2s;
  }

  .select-search-option:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: transparent !important;
  }

  .select-search-is-highlighted,
  .select-search-option:not(.select-search-is-selected):hover {
    background: var(--select-search-highlight);
  }

  .select-search-is-selected {
    font-weight: bold;
    color: var(--select-search-selected);
  }

  .select-search-group-header {
    font-size: 14px;
    text-transform: uppercase;
    background: var(--select-search-border);
    color: var(--select-search-subtle-text);
    letter-spacing: 0.1rem;
    padding: 10px 16px;
  }

  .select-search-row:not(:first-child) .select-search-group-header {
    margin-top: 10px;
  }

  .select-search-row:not(:last-child) .select-search-group-header {
    margin-bottom: 10px;
  }
`;

const TitleAndSearchSelectBox = ({ title, innerValue, valueList, valueSelector, viewSelector, placeHolderStr, onChange }) => {

    const { t } = useTranslation();
    const [newValueList, setNewValueList] = useState([]);

    const renameList = (prevList) => {
        let newList = [...prevList];
        for (let i=0 ; i<newList.length ; i++) {
            newList[i]['name'] = newList[i][viewSelector];
            newList[i]['value'] = newList[i][valueSelector];
        }
        return newList;
    };

    useEffect(() => {
        setNewValueList(renameList(valueList));
    }, [valueList]);

    if (valueList == null || typeof valueList === "undefined" || valueList.length === 0) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            paddingLeft: '10px',
                            marginBottom: '5px'
                        }}
                    >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px'
                        }}
                    >
                        {title}
                    </span>
                    </div>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <select
                            id={"data-view-input"}
                            value={""}
                        >
                            <option>{t("common.noData")}</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <GlobalStyle />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            paddingLeft: '10px',
                            marginBottom: '5px'
                        }}
                    >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px'
                        }}
                    >
                        {title}
                    </span>
                    </div>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <SelectSearch
                            options={newValueList}
                            value={innerValue}
                            name={"name"}
                            placeholder={placeHolderStr}
                            search={true}
                            autoComplete={true}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default TitleAndSearchSelectBox;