import axios from "axios";
import UserService from "../../../../services/UserService";

const baseUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/booth`;
const boothVersionBaseUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/booth-version`;
const boothDataBaseUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/booth-control-wrapper`;

const fetchBoothBasicInfo = async (boothId, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${baseUrl}/${boothId}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack(res.data.item.boothDetail);
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const checkDuplicateSerial = async (payload, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${baseUrl}/check/duplicate/serial/${payload}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack(res.data.item.result);
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
}

const putBoothBasicInfo = async (boothId, requestBody, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${baseUrl}/${boothId}`;
        const axiosCall = () => axios.put(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack();
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const registerBoothSalesInfo = async (boothId, requestBody, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${baseUrl}/${boothId}/sales/register`;
        const axiosCall = () => axios.put(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack();
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const putBoothSalesInfo = async (boothId, requestBody, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${baseUrl}/${boothId}/sales`;
        const axiosCall = () => axios.put(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack();
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const clearBoothSalesInfo = async (boothId, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${baseUrl}/${boothId}/sales/clear`;
        const axiosCall = () => axios.put(
            fetchUrl,
            {},
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack();
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const registerBoothInstallInfo = async (boothId, requestBody, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${baseUrl}/${boothId}/install/register`;
        const axiosCall = () => axios.put(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack();
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const putBoothInstallInfo = async (boothId, requestBody, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${baseUrl}/${boothId}/install`;
        const axiosCall = () => axios.put(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack();
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const clearBoothInstallInfo = async (boothId, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${baseUrl}/${boothId}/install/clear`;
        const axiosCall = () => axios.put(
            fetchUrl,
            {},
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack();
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const postBooth = async (requestBody, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = baseUrl;
        const axiosCall = () => axios.post(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack();
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const getBoothVersionList = async (boothId, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${boothVersionBaseUrl}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        if (res.data['error_code'] === "GET_EMPTY_DATA") {
            onSuccessCallBack([]);
        } else {
            onSuccessCallBack(res.data.item.boothNameList);
        }
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const fetchBoothData = async (boothId, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${boothDataBaseUrl}/booth/${boothId}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallBack(res.data.item.body);
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const putBoothDataToEss = async (requestPath, requestBody, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${boothDataBaseUrl}${requestPath}`;
        const axiosCall = () => axios.put(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        await UserService.updateToken(axiosCall);
        onSuccessCallBack();
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const postBoothDataToEss = async (requestPath, requestBody, onSuccessCallBack, onFailureCallBack) => {
    try {
        let fetchUrl = `${boothDataBaseUrl}${requestPath}`;
        const axiosCall = () => axios.post(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        await UserService.updateToken(axiosCall);
        onSuccessCallBack();
    } catch (e) {
        console.log(e);
        onFailureCallBack(e);
    }
};

const BoothService = {
    fetchBoothBasicInfo,
    checkDuplicateSerial,
    putBoothBasicInfo,
    registerBoothSalesInfo,
    putBoothSalesInfo,
    clearBoothSalesInfo,
    registerBoothInstallInfo,
    putBoothInstallInfo,
    clearBoothInstallInfo,
    postBooth,
    getBoothVersionList,
    fetchBoothData,
    putBoothDataToEss,
    postBoothDataToEss
};

export default BoothService;