import en from "./en.json";
import ko from "./ko.json";
import es from "./es.json";

/* target to modify */
/* when language settings are changed */
let initOption = {
    resources: {
        "en-US": {
            translation: en,
        },
        "ko-KR": {
            translation: ko,
        },
        "es-ES": {
            translation: es
        }
    },
    lng: "en-US",
    fallbackLng: {
        "ko-KR": ["en-US"],
        "es-ES": ["en-US"],
        default: ["en-US"],
    },
    debug: true,
    defaultNS: "translation",
    ns: "translation",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
    }
};

const getInitOption = () => {
    return initOption;
}

const i18n_setUp = {
    getInitOption
};

export default i18n_setUp;