import Keycloak from "keycloak-js";
import axios from "axios";
import {toast} from "react-toastify";
import ToastAlertView from "../components/common/alert/ToastAlertView";

// const _kcConfig = {
//     "realm": "CRP",
//     "auth-server-url": process.env.REACT_APP_KEYCLOAK_URL,
//     "ssl-required": "external",
//     "resource": "IMSGUI",
//     "public-client": true,
//     "confidential-port": 0
// };

const _kcConfig = {
    realm: "CRP",
    url: process.env.REACT_APP_KEYCLOAK_URL,
    clientId: "IMSGUI"
};

const _kc = new Keycloak(_kcConfig);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 * When calling 'initKeycloak', pass the argument that is a function type.
 * @param userName
 * @param token
 */

const _fetchUserData = async (userName, token) => {
    try {
        /* get admin info */
        const response = await axios.get(`${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${userName}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        await window.sessionStorage.setItem("ims_accessToken", token);
        await window.sessionStorage.setItem("ims_adminId", response.data.item.adminInfo.adminId);
        await window.sessionStorage.setItem("ims_imageUrl", response.data.item.adminInfo.adminImageUrl);
        await window.sessionStorage.setItem("ims_userName", response.data.item.adminInfo.adminName);
        await window.sessionStorage.setItem("ims_groupName", response.data.item.adminInfo.adminGroupName);
        await window.sessionStorage.setItem("ims_companyName", response.data.item.adminInfo.adminCompanyName);
        await window.sessionStorage.setItem("ims_departmentName", response.data.item.adminInfo.adminDepartmentName);
        await window.sessionStorage.setItem("ims_language", response.data.item.adminInfo.adminLanguage);
        /* get admin roles */
        const roleResponse = await axios.get(`${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/role/admin/${userName}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        /* auth type */
        await window.sessionStorage.setItem("ims_authType", roleResponse.data.item.authType);

        /* isSuperMaster */
        await window.sessionStorage.setItem("ims_isSuperMaster", roleResponse.data.item.isSuperMaster);

        /* group role */
        await window.sessionStorage.setItem("ims_group_roleList", JSON.stringify(roleResponse.data.item.roleListOfGroup));

        /* company role */
        await window.sessionStorage.setItem("ims_company_roleList", JSON.stringify(roleResponse.data.item.roleListOfCompany));

        /* admin role */
        await window.sessionStorage.setItem("ims_roleList", JSON.stringify(roleResponse.data.item.roleList));
    } catch (err) {
        toast.error(<ToastAlertView message={err.toString()}/>);
        await doLogout();
        console.log(err);
    }
}

const fetchLanguageInfo = async (callback) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/language`, {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
            }
        });
        let languageListBefore = response.data.item['languageMetadataList'];
        /* filter out disabled language */
        let languageMetadataList = [];
        let languageInfoList = [];
        languageListBefore.map(languageInfo => {
            if (languageInfo['enabled']) {
                languageMetadataList.push(languageInfo['languageMetadataBrowserCode']);
                languageInfoList.push(languageInfo);
            }
        });
        /* check admin lang is supported */
        let adminLanguage = window.sessionStorage.getItem("ims_language");
        if (!languageMetadataList.includes(adminLanguage)) {
            /* not-supported lang */
            await window.sessionStorage.setItem("ims_language", "en-US");
        }
        /* set language info */
        await window.sessionStorage.setItem("ims_server_lang_list", JSON.stringify(languageInfoList));
        callback();
    } catch (e) {
        console.log(e);
    }
}

const hasKeycloakRole = (roles) => {
    return roles.some((role) => _kc.hasResourceRole(role, "IMS"));
}

const initKeycloak = async (onLoading, onAuthenticatedCallback) => {
    try {
        onLoading();

        let isForUSA = (typeof process.env.REACT_APP_IS_FOR_USA === "undefined") ? "false" : process.env.REACT_APP_IS_FOR_USA;
        await window.sessionStorage.setItem("service_region_ims", isForUSA);

        const authenticated = await _kc.init({
            onLoad: 'login-required', // can be 'login-required' or 'check-sso'
            pkceMethod: 'S256', // SHA-256
        });
        if (!authenticated) {
            console.log("user authentication FAILED.");
            onAuthenticatedCallback();
        } else {
            if (hasRole(["admin"])) {
                /* get user information */
                await _fetchUserData(getUsername(), getToken());
                /* get server language information and init language */
                await fetchLanguageInfo(onAuthenticatedCallback);
            } else {
                toast.error(<ToastAlertView message={"Invalid user."} />);
                await doLogout();
            }
        }
    } catch (err) {
        console.log(err);
    }
};

const doLogin = () => _kc.login();

const doLogout = () =>  _kc.logout();

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

/** A call back function when update is success is required. */
const updateToken = (successCallback) => {
    if (_kc.isTokenExpired(5)) {
        _kc.updateToken(5)
        .then(() => {
            window.sessionStorage.setItem("ims_accessToken", getToken());
            return successCallback();
        })
        .catch(() => {
            doLogin();
        });
    } else {
        return successCallback();
    }
};

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    hasKeycloakRole
};

export default UserService;
