import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Container, Row, Spinner} from "react-bootstrap";
import ProductJsonAxiosService from "../../../../../../common/jsonDynamic/service/ProductJsonAxiosService";
import _ from "lodash";
import styled from "styled-components";
import RecipeUtils from "../../../../../../common/jsonDynamic/util/RecipeUtils";
import ConfirmModal from "../../../../../../../common/confirm/ConfirmModal";
import {toast} from "react-toastify";
import OptionDetailView from "./OptionDetailView";

const MessageView = styled.div`
  text-align: center;
  padding: 10px 20px;
  color: #757575;
  font-size: 14px;
`;

const SimpleButton = styled.button`
  font-size: 14px;
  padding: 7px 10px;
  color: #fc7242;
  border: 1px solid #fc7242;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  background-color: white;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #ffeee6;
  }
`;

const OptionRecipeInnerView = ({ selectedBoothVersionId,
                                   selectedOptionId,
                                   optionInfoList,
                                   productJsonVersion,
                                   boothVersionList,
                                   refreshMenuList,
                                   initializeParentState }) => {

    const { t } = useTranslation();
    const { franchiseId, menuId } = useParams();

    /* states */
    const [optionJsonList, setOptionJsonList] = useState([]);
    const [selectedOptionJsonVersion, setSelectedOptionJsonVersion] = useState("");

    /* for recipe */
    const [currentOptionRecipeStr, setCurrentOptionRecipeStr] = useState("");

    /* loading */
    const [isValidationLoading, setIsValidationLoading] = useState(false);

    /* confirmation */
    const [optionDetailInitializeConfirmModalOpen, setOptionDetailInitializeConfirmModalOpen] = useState(false);

    /* fetch option schema list */
    const fetchOptionSchemaList = async (prevOptionSchemaVersion) => {
        const successCallback = async (data) => {
            await setOptionJsonList(data);
            /* default */
            if ((prevOptionSchemaVersion === "") && (data.length > 0)) {
                setSelectedOptionJsonVersion(data[0]['metadata']['id']);
            }
            await setIsValidationLoading(false);
        };
        const failCallback = async (err) => {
            console.log(err);
            await setIsValidationLoading(false);
        };
        await ProductJsonAxiosService.getOptionJsonList(franchiseId, menuId, selectedOptionId, selectedBoothVersionId, productJsonVersion, successCallback, failCallback);
    };

    /* get target option detail */
    const extractOptionDetail = (optionId, boothVersion) => {
        let newOptionDetail = {};
        optionInfoList.map(optionInfo => {
            if (optionInfo['optionInfo']['franchiseMenuOptionId'] === optionId) {
                optionInfo['franchiseMenuOptionDetailList'].map(optionDetailInfo => {
                   if (optionDetailInfo['franchiseMenuOptionDetailBoothVersionId'] === boothVersion) {
                       newOptionDetail = optionDetailInfo;
                   }
                });
            }
        });
        return newOptionDetail;
    };

    const extractOptionLabel = (optionId) => {
        let label = ""
        optionInfoList.map(optionInfo => {
            if (optionInfo['optionInfo']['franchiseMenuOptionId'] === optionId) {
                label = `(${optionInfo['optionInfo']['categoryInfo'][t("language.dataKey.menu.option.category.name")]}) ${optionInfo['optionInfo'][t("language.dataKey.menu.option.name")]}`;
            }
        });
        return label;
    };

    /* alert */
    const AlertInner = (message) => {
        return (
            <div
                style={{
                    width: '100%',
                    textAlign: 'left'
                }}
            >
                <span
                    style={{
                        color: '#757575',
                        fontSize: '14px'
                    }}
                >
                    {message}
                </span>
            </div>
        );
    };

    /* init */
    const initializeStates = async () => {
        if (selectedOptionId === "") {
            return;
        } else {
            let extractedOptionDetail = extractOptionDetail(selectedOptionId, selectedBoothVersionId);
            await setIsValidationLoading(true);
            await setCurrentOptionRecipeStr(_.isEqual(extractedOptionDetail, {}) ? "" : extractedOptionDetail['franchiseMenuOptionDetailRecipe']);
            await setSelectedOptionJsonVersion(_.isEqual(extractedOptionDetail, {}) ? "" : extractedOptionDetail['franchiseMenuOptionDetailOptionJsonVersion']);
            await setOptionJsonList([]);
            await fetchOptionSchemaList(_.isEqual(extractedOptionDetail, {}) ? "" : extractedOptionDetail['franchiseMenuOptionDetailOptionJsonVersion']);
        }
    };

    /* set default option detail */
    const setDefaultMenuOptionRecipe = async (newOptionJsonVersion) => {
        await setIsValidationLoading(true);

        /* save prev data */
        let prevSelectedOptionJsonVersion = selectedOptionJsonVersion;
        let prevCurrentOptionJsonStr = currentOptionRecipeStr;

        try {
            let newOptionJsonSchema = RecipeUtils.extractJsonSchemaFromList(newOptionJsonVersion, optionJsonList);
            let newDefaultRecipe = RecipeUtils.generateDefaultData(newOptionJsonSchema);

            /* in case failed to gen */
            if (_.isEqual(newDefaultRecipe, {})) {
                /* failed to generate default data */
                throw { message: "Failed to generate a default option recipe.", alertMsgKey: "genDataError" };
            } else {
                /* set state */
                await setSelectedOptionJsonVersion(newOptionJsonVersion);
            }

            /* validation of data */
            let resOfValidation = RecipeUtils.validateJsonDataWithSchema(newOptionJsonSchema, JSON.stringify(newDefaultRecipe));
            if (resOfValidation === false) {
                throw { message: "Failed to validate a new default menu.", alertMsgKey: "genDataError" };
            }

            /* update option recipe str */
            setCurrentOptionRecipeStr(JSON.stringify(newDefaultRecipe));

            /* save option detail */
            let newRequestBody = {};
            newRequestBody['franchiseMenuOptionDetailBoothVersionId'] = selectedBoothVersionId;
            newRequestBody['franchiseMenuOptionDetailOptionJsonVersion'] = newOptionJsonVersion;
            newRequestBody['franchiseMenuOptionDetailRecipe'] = JSON.stringify(newDefaultRecipe);

            /* save change */
            let isFailed = false;
            const onSuccessCallback = async (res) => {
                /* refresh all */
                await initializeParentState();
                await setIsValidationLoading(false);
            };
            const onFailureCallback = async (e) => {
                console.log(e);
                isFailed = true;
            };
            await ProductJsonAxiosService.saveOrGenerateMenuOptionDetail(franchiseId, menuId, selectedOptionId, newRequestBody, onSuccessCallback, onFailureCallback);
            if (isFailed) {
                throw { message: "Failed to save a new default menu.", alertMsgKey: "saveError" };
            }
            await setIsValidationLoading(false);
        } catch (err) {
            console.log(err);
            /* alert */
            let message = t(`menu.franchiseMenu.recipeInfo.optionRecipe.message.error.${err['alertMsgKey']}`);
            toast.error(AlertInner(message));
            /* roll-back */
            await setSelectedOptionJsonVersion(prevSelectedOptionJsonVersion);
            await setCurrentOptionRecipeStr(prevCurrentOptionJsonStr);
            await setIsValidationLoading(false);
        }
    };

    /* effects */
    useEffect(() => {
        initializeStates();
    }, [selectedOptionId, selectedBoothVersionId, productJsonVersion, optionInfoList]);

    return (
        <Container fluid>
            {/* loading */}
            {
                isValidationLoading &&
                <Row>
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            paddingTop: '30px'
                        }}
                    >
                        <Spinner size={"sm"}/>
                    </div>
                </Row>
            }
            {/* option not selected */}
            {
                (selectedOptionId === "") && (!isValidationLoading) &&
                <Row>
                    <div
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <MessageView>
                            {t("menu.franchiseMenu.recipeInfo.optionRecipe.message.chooseOption")}
                        </MessageView>
                    </div>
                </Row>
            }
            {/* no recipe */}
            {
                (currentOptionRecipeStr === "") && (selectedOptionId !== "") && (!isValidationLoading) &&
                <Row>
                    <div
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <MessageView>
                            {t("menu.franchiseMenu.recipeInfo.optionRecipe.message.noOptionDetail")}
                        </MessageView>
                        {
                            (selectedOptionJsonVersion === "") &&
                            <MessageView>
                                {t("menu.franchiseMenu.recipeInfo.optionRecipe.message.noSchemaAvailable")}
                            </MessageView>
                        }
                        {
                            (selectedOptionJsonVersion !== "") &&
                            <SimpleButton
                                onClick={() => setOptionDetailInitializeConfirmModalOpen(true)}
                            >
                                {t("menu.franchiseMenu.recipeInfo.menuRecipe.button.registerRecipe")}
                            </SimpleButton>
                        }
                    </div>
                    {/* confirmation modal */}
                    <ConfirmModal
                        title={t("menu.franchiseMenu.recipeInfo.optionRecipe.message.sureToRegister", {
                            optionName: extractOptionLabel(selectedOptionId),
                            boothVersionName: RecipeUtils.getBoothVersionNameFromBoothVersionList(selectedBoothVersionId, boothVersionList)
                        })}
                        isOpen={optionDetailInitializeConfirmModalOpen}
                        setIsOpen={setOptionDetailInitializeConfirmModalOpen}
                        onConfirm={async () => await setDefaultMenuOptionRecipe(selectedOptionJsonVersion)}
                        onReject={() => {}}
                        confirmLabel={t("button.yes")}
                        rejectLabel={t("button.no")}
                    />
                </Row>
            }
            {/* validation fail */}
            {
                (currentOptionRecipeStr !== "") &&
                (selectedOptionId !== "") &&
                (!isValidationLoading) &&
                (!RecipeUtils.validateJsonDataWithSchema(RecipeUtils.extractJsonSchemaFromList(selectedOptionJsonVersion, optionJsonList), currentOptionRecipeStr)) &&
                <Row>
                    <div
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <MessageView>
                            {t("menu.franchiseMenu.recipeInfo.optionRecipe.message.validationFail")}
                        </MessageView>
                        {
                            (selectedOptionJsonVersion === "") &&
                            <MessageView>
                                {t("menu.franchiseMenu.recipeInfo.optionRecipe.message.noSchemaAvailable")}
                            </MessageView>
                        }
                        {
                            (selectedOptionJsonVersion !== "") &&
                            <SimpleButton
                                onClick={() => setOptionDetailInitializeConfirmModalOpen(true)}
                            >
                                {t("menu.franchiseMenu.recipeInfo.menuRecipe.button.initializeRecipe")}
                            </SimpleButton>
                        }
                    </div>
                    {/* confirmation modal */}
                    <ConfirmModal
                        title={t("menu.franchiseMenu.recipeInfo.optionRecipe.message.sureToInitialize", {
                            optionName: extractOptionLabel(selectedOptionId),
                            boothVersionName: RecipeUtils.getBoothVersionNameFromBoothVersionList(selectedBoothVersionId, boothVersionList)
                        })}
                        isOpen={optionDetailInitializeConfirmModalOpen}
                        setIsOpen={setOptionDetailInitializeConfirmModalOpen}
                        onConfirm={async () => await setDefaultMenuOptionRecipe(selectedOptionJsonVersion)}
                        onReject={() => {}}
                        confirmLabel={t("button.yes")}
                        rejectLabel={t("button.no")}
                    />
                </Row>
            }
            {/* good */}
            {
                (currentOptionRecipeStr !== "") &&
                (selectedOptionId !== "") &&
                (!isValidationLoading) &&
                (RecipeUtils.validateJsonDataWithSchema(RecipeUtils.extractJsonSchemaFromList(selectedOptionJsonVersion, optionJsonList), currentOptionRecipeStr)) &&
                <Row>
                    <OptionDetailView
                        selectedBoothVersionId={selectedBoothVersionId}
                        selectedOptionJsonVersion={selectedOptionJsonVersion}
                        updateSelectedOptionJsonVersion={async (newOptionJsonVersion) => await setDefaultMenuOptionRecipe(newOptionJsonVersion)}
                        optionJsonList={optionJsonList}
                        optionInfoList={optionInfoList}
                        selectedOptionId={selectedOptionId}
                        initializeParentState={initializeParentState}
                    />
                </Row>
            }
        </Container>
    );
};

export default OptionRecipeInnerView;