import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import DataCompareService from "../../services/DataCompareService";
import DataParseService from "../../services/DataParseService";
import DataUtils from "../../components/menu/common/utils/DataUtils";
import OverlayToolTip from "../../components/menu/common/toolTip/OverlayToolTip";
import { CiSquareQuestion } from "react-icons/ci";


const LangSelectButton = styled.button`
  width: auto;
  padding: 3px 7px;
  color: ${(props) => props.color ? props.color : "#757575"};
  font-size: 14px;
  border: 1px solid ${(props) => props.color ? props.color : "#757575"};
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "white"};
  transition: all ease-in-out 0.2s;
  margin-left: ${(props) => props.marginLeft ? props.marginLeft : "0px"};
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.hoverColor ? props.hoverColor : "white"};
  }
`;

const LanguageInput = ({ type,
                           title,
                           dataKeyTranslationPath,
                           valueMap,
                           updateValueMap,
                           isValidMap,
                           updateIsValidMap,
                           isForEdit,
                           height,
                           currentLang,
                           isHighlightOn }) => {
    /** NOTE */
    /** 1.  update functions must get 2 parameter, key and value. */
    /** 2.  "en-US" is always mandatory field. */

    const { t } = useTranslation();

    /** design modification : NO more option */
    // const [languageMetadataList, setLanguageMetadataList] = useState(
    //     sessionStorage.getItem("ims_server_lang_list")
    //         ?
    //         JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
    //         :
    //         [{
    //             "languageMetadataBrowserCode" : "en-US",
    //             "languageMetadataLabel" : "English"
    //         }]
    // );

    const handleUpdate = (key, value) => {
        if (isForEdit) {
            updateValueMap(key, value);
            /* check is null or empty */
            if (currentLang === "en-US") { /** English field is always mandatory. */
                updateIsValidMap(key, !DataUtils.isEmptyStr(value));
            }
        } else {
            return;
        }
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: '10px',
                        marginBottom: '5px'
                    }}
                >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px'
                        }}
                    >
                        {title}
                    </span>
                </div>
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    {
                        (type === "textarea") ?
                            <textarea
                                id={"data-view-input"}
                                type={type}
                                readOnly={!isForEdit}
                                value={valueMap[t(dataKeyTranslationPath, { lng : currentLang })] ? valueMap[t(dataKeyTranslationPath, { lng : currentLang })] : ""}
                                onChange={(e) => handleUpdate(t(dataKeyTranslationPath, { lng : currentLang }), e.target.value)}
                                style={
                                    isHighlightOn ?
                                        {
                                            height : height ? height + "px" : "100px",
                                            resize : 'none',
                                            outline : 'none',
                                            borderColor: '#fc7242'
                                        }
                                        :
                                        isValidMap[t(dataKeyTranslationPath, { lng : currentLang })] ?
                                            {
                                                height : height ? height + "px" : "100px",
                                                resize : 'none',
                                                outline : 'none'
                                            }
                                            :
                                            {
                                                borderColor: isForEdit ? "red" : "",
                                                height : height ? height + "px" : "100px",
                                                resize : 'none',
                                                outline : 'none'
                                            }
                                }
                            />
                            :
                            <input
                                id={"data-view-input"}
                                type={type}
                                readOnly={!isForEdit}
                                value={valueMap[t(dataKeyTranslationPath, { lng : currentLang })] ? valueMap[t(dataKeyTranslationPath, { lng : currentLang })] : ""}
                                onChange={(e) => handleUpdate(t(dataKeyTranslationPath, { lng : currentLang }), e.target.value)}
                                style={
                                    isHighlightOn ?
                                        {
                                            borderColor: "#fc7242"
                                        }
                                        :
                                        (isValidMap[t(dataKeyTranslationPath, { lng : currentLang })] ?
                                            {}
                                            :
                                            {
                                                borderColor: isForEdit ? "red" : ""
                                            })
                                }
                            />
                    }
                </div>
            </div>
        </div>
    );

};

export default LanguageInput;