import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import _ from "lodash";
import styled from "styled-components";
import DataCompareService from "../../../../../services/DataCompareService";
import TitleAndTextInput from "../TitleAndTextInput";
import TitleAndCategoricalText from "../TitleAndCategoricalText";
import TitleAndIntegerInput from "../TitleAndIntegerInput";
import TitleAndBoolean from "../TitleAndBoolean";
import uuid from "react-uuid";

const DataContainerRounded = styled.div`
  width: 100%;
  margin-top: 5px;
  background-color: white;
  border-radius: 5px;
`;

const DataContainerInnerTitleContainer = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  border-bottom: 1px solid #e4e7ea;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
`;

const InnerTitle = styled.div`
  color: #848487;
  text-align: left;
`;

const ObjectFieldContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

/* This component will check its own validity and edit-info and send it to parent when a change happens */
const ObjectView = ({ isTitleUsed, title, objValue, isForEdit, updateValue, updateIsValid, mainKey, validator, properties }) => {

    const { t, i18n } = useTranslation();

    /* object that will be sent to the parent */
    const [dataObject, setDataObject] = useState({});

    /* object isValid */
    const [isValidObjectMap, setIsValidObjectMap] = useState({});
    const [isObjectValid, setIsObjectValid] = useState({});

    /* object field keys from schema */
    let objectFieldKeys = Object.keys(validator['properties']);

    /* initialize an object + dependent variables */
    const initializeObject = () => {
        /* initialize an empty object */
        let newObject = {};
        let newIsObjectValidMap = {};
        objectFieldKeys.map(key => {
            newObject[key] = null;
            newIsObjectValidMap[key] = true;
        });
        /* if data exists, update it. */
        if (!(typeof objValue === "undefined" || objValue == null || _.isEqual(objValue, {}))) {
            objectFieldKeys.map(key => {
                newObject[key] = objValue[key];
            });
        }

        /* update state */
        setDataObject(newObject);
        setIsValidObjectMap(newIsObjectValidMap);
    };

    const handleFieldChange = (key, value) => {
        setDataObject(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const handleIsValidMapChange = (key, value) => {
        setIsValidObjectMap(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    /* effects */
    useEffect(() => {
        initializeObject();
    }, []);

    /* validity tracking and propagation */
    useEffect(() => {
        setIsObjectValid(DataCompareService.checkIsAllTrue(isValidObjectMap));
        updateIsValid(mainKey, DataCompareService.checkIsAllTrue(isValidObjectMap));
    }, [isValidObjectMap]);

    /* data change propagation */
    useEffect(() => {
        updateValue(mainKey, dataObject);
    }, [dataObject]);


    /* This function checks each field type and calls appropriate data view component. */
    const renderObjectInnerData = () => {
        let innerDataElements = [];
        objectFieldKeys.map(objectFieldKey => {
            let primitive = validator['properties'][objectFieldKey]['type'];
            let fieldValidator = validator['properties'][objectFieldKey];
            let fieldProperty = properties[objectFieldKey];
            if (primitive === "string") {
                if (!properties[objectFieldKey]['isCategorical']) {
                    /* just string */
                    innerDataElements.push(
                        <ObjectFieldContainer
                            key={objectFieldKey}
                        >
                            <TitleAndTextInput
                                title={fieldProperty[t("language.dataKey.boothControl.json.label")]}
                                value={dataObject[objectFieldKey]}
                                isForEdit={isForEdit}
                                isValid={isValidObjectMap[objectFieldKey]}
                                updateValue={handleFieldChange}
                                updateIsValid={handleIsValidMapChange}
                                objectKey={objectFieldKey}
                                validator={fieldValidator}
                                property={fieldProperty}
                            />
                        </ObjectFieldContainer>
                    );
                } else {
                    /* categorical string */
                    innerDataElements.push(
                        <ObjectFieldContainer
                            key={objectFieldKey}
                        >
                            <TitleAndCategoricalText
                                title={fieldProperty[t("language.dataKey.boothControl.json.label")]}
                                value={dataObject[objectFieldKey]}
                                isForEdit={isForEdit}
                                updateValue={handleFieldChange}
                                updateIsValid={handleIsValidMapChange}
                                objectKey={objectFieldKey}
                                validator={fieldValidator}
                            />
                        </ObjectFieldContainer>
                    );
                }
            } else if (primitive === "integer") {
                /* integer */
                innerDataElements.push(
                    <ObjectFieldContainer
                        key={objectFieldKey}
                    >
                        <TitleAndIntegerInput
                            title={fieldProperty[t("language.dataKey.boothControl.json.label")]}
                            value={dataObject[objectFieldKey]}
                            isForEdit={isForEdit}
                            isValid={isValidObjectMap[objectFieldKey]}
                            updateValue={handleFieldChange}
                            updateIsValid={handleIsValidMapChange}
                            objectKey={objectFieldKey}
                            validator={fieldValidator}
                            property={fieldProperty}
                            unitOfValue={fieldProperty['unitOfValue']}
                        />
                    </ObjectFieldContainer>
                );
            } else if (primitive === "boolean") {
                innerDataElements.push(
                    <ObjectFieldContainer
                        key={objectFieldKey}
                    >
                        <TitleAndBoolean
                            title={fieldProperty[t("language.dataKey.boothControl.json.label")]}
                            value={dataObject[objectFieldKey]}
                            isForEdit={isForEdit}
                            updateValue={handleFieldChange}
                            updateIsValid={handleIsValidMapChange}
                            objectKey={objectFieldKey}
                        />
                    </ObjectFieldContainer>
                );
            }
        });
        return innerDataElements;
    };


    if (_.isEqual(dataObject, {})) {
        return (
            <></>
        );
    } else {
        return (
            <DataContainerRounded>
                {
                    isTitleUsed ?
                        <DataContainerInnerTitleContainer>
                            <InnerTitle>
                                {title}
                            </InnerTitle>
                        </DataContainerInnerTitleContainer>
                        :
                        <></>
                }
                {/* data */}
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    {
                        renderObjectInnerData()
                    }
                </div>
            </DataContainerRounded>
        );
    }
};

export default ObjectView;