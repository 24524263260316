const hasGetAuth = (code) => {
    if ((code & 32) === 32) {
        return false;
    } else {
        return ((code & 1) === 1);
    }
};

const hasPutAuth = (code) => {
    if ((code & 32) === 32) {
        return false;
    } else {
        return ((code & 4) === 4);
    }
};

const hasPostAuth = (code) => {
    if ((code & 32) === 32) {
        return false;
    } else {
        return ((code & 2) === 2);
    }
};

const hasDeleteAuth = (code) => {
    if ((code & 32) === 32) {
        return false;
    } else {
        return ((code & 8) === 8);
    }
};

const BoothControlAuthService = {
    hasGetAuth,
    hasPutAuth,
    hasPostAuth,
    hasDeleteAuth
};

export default BoothControlAuthService;