import React, {useEffect, useState} from 'react';

const TitleAndTextArea = ({ title, value, onChange, isForEdit, height, isValid, placeHolder }) => {

    const [isValidWrap, setIsValidWrap] = useState(true);

    useEffect(() => {
        if (typeof isValid !== "undefined") {
            setIsValidWrap(isValid);
        }
    }, [isValid]);

    return (
        <div
            style={{
                width: '100%',
                height: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        textAlign: 'left',
                        paddingLeft: '10px',
                        marginBottom: '5px'
                    }}
                >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px'
                        }}
                    >
                        {title}
                    </span>
                </div>
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <textarea
                        id={"data-view-input"}
                        readOnly={!isForEdit}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        style={
                            isValidWrap ?
                                {
                                    resize : 'none',
                                    height : height + 'px',
                                    outline: 'none'
                                }
                                :
                                {
                                    resize : 'none',
                                    height : height + 'px',
                                    outline: 'none',
                                    borderColor: 'red'
                                }
                        }
                        placeholder={placeHolder ? placeHolder : ""}
                    />
                </div>
            </div>
        </div>
    );
};

export default TitleAndTextArea;