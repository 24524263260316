
const getObjectInListById = (id, list) => {
    for (let i=0 ; i<list.length ; i++) {
        if (list[i]['metadata']['id'] === id) {
            return list[i];
        }
    }
    return {};
};

const getObjectInListByName = (name, list) => {
    for (let i=0 ; i<list.length ; i++) {
        if (list[i]['metadata']['name'] === name) {
            return list[i];
        }
    }
    return {};
};

const hasRelativePath = (pathname, depth, value) => {
    let pathList = pathname.split('/');
    if (pathList.length === 0 || (pathList.length - 1) < depth) {
        return false;
    } else {
        return pathList[depth] === value;
    }
};

const isRootPath = (pathname, minDepth) => {
    let pathList = pathname.split('/');
    return pathList.length === minDepth;
};


/* get group labels from deviceSchema and sensorName */
const extractGroupLabels = (deviceSchema, sensorName) => {
    if (deviceSchema['metadata']['sensorGroupLabels']) {
        if (deviceSchema['metadata']['sensorGroupLabels'][sensorName]) {
            return deviceSchema['metadata']['sensorGroupLabels'][sensorName];
        } else {
            return {
                label : "Unknown",
                label_1 : "Unknown",
                label_2 : "Unknown",
                label_3 : "Unknown",
                label_4 : "Unknown",
                label_5 : "Unknown"
            };
        }
    } else {
        return {
            label : "Unknown",
            label_1 : "Unknown",
            label_2 : "Unknown",
            label_3 : "Unknown",
            label_4 : "Unknown",
            label_5 : "Unknown"
        };
    }
};

const getColorCodeByState = (state) => {
    let color = "";
    if (state === "Active") {
        color = "#28AEFF";
    } else if (state === "Pending") {
        color = "#FFC314";
    } else if (state === "Failed") {
        color = "#FF3232";
    } else {
        color = "#C45FDD";
    }
    return color;
}

const CommonUtil = {
    getObjectInListById,
    getObjectInListByName,
    hasRelativePath,
    isRootPath,
    extractGroupLabels,
    getColorCodeByState
};

export default CommonUtil;