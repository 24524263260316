import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import UserService from "../../../../../../services/UserService";
import axios from "axios";
import DataUtils from "../../../../common/utils/DataUtils";
import DataCompareService from "../../../../../../services/DataCompareService";
import _ from "lodash";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndSwitchBox from "../../../../../common/dataView/TitleAndSwitchBox";
import ImageUploadBox from "../../../../common/image/ImageUploadBox";
import TitleAndInputBox from "../../../../../common/dataView/TitleAndInputBox";
import TitleAndSelectBox from "../../../../../common/dataView/TitleAndSelectBox";
import TitleAndTextArea from "../../../../../common/dataView/TitleAndTextArea";
import ExtraInformationView from "../../../../common/extrainfo/ExtraInformationView";
import ButtonWithSpinner from "../../../../common/buttons/ButtonWithSpinner";
import OrangeButton from "../../../../common/buttons/OrangeButton";
import OnUploadModal from "../../../../common/image/modal/OnUploadModal";
import SimpleButton from "../../../../common/buttons/SimpleButton";
import LanguageInput from "../../../../../../language/component/LanguageInput";
import LanguageSelectBox from "../../../../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../../../../common/alert/ToastAlertView";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import DeleteConfirmModal from "../../../../../common/deleteComponents/DeleteConfirmModal";
import DeleteService from "../../../../../../services/DeleteService";

const requestBodyInit = {
    "franchiseMenuAlias" : "",
    "franchiseMenuAlias1" : "",
    "franchiseMenuAlias2" : "",
    "franchiseMenuAlias3" : "",
    "franchiseMenuAlias4" : "",
    "franchiseMenuAlias5" : "",
    "franchiseMenuDescription" : "",
    "franchiseMenuDescription1" : "",
    "franchiseMenuDescription2" : "",
    "franchiseMenuDescription3" : "",
    "franchiseMenuDescription4" : "",
    "franchiseMenuDescription5" : "",
    "isBoothMenu" : true,
    "franchiseMenuPrice" : 0.0,
    "franchiseMenuPriceUnit" : "",
    "franchiseMenuCategoryId" : "",
    "extraInformationList" : []
};

const isValidMapInit = {
    "franchiseMenuAlias" : true,
    "franchiseMenuAlias1" : true,
    "franchiseMenuAlias2" : true,
    "franchiseMenuAlias3" : true,
    "franchiseMenuAlias4" : true,
    "franchiseMenuAlias5" : true,
    "franchiseMenuDescription" : true,
    "franchiseMenuDescription1" : true,
    "franchiseMenuDescription2" : true,
    "franchiseMenuDescription3" : true,
    "franchiseMenuDescription4" : true,
    "franchiseMenuDescription5" : true,
    "isBoothMenu" : true,
    "franchiseMenuPrice" : true,
    "franchiseMenuPriceUnit" : true,
    "franchiseMenuCategoryId" : true,
    "extraInformationList" : true
};

const emptyCategoryList = [{
    franchiseMenuCategoryLookupFranchiseId : "",
    franchiseMenuCategoryLookupName : "None",
    franchiseMenuCategoryLookupName1 : "None",
    franchiseMenuCategoryLookupName2 : "None",
    franchiseMenuCategoryLookupName3 : "None",
    franchiseMenuCategoryLookupName4 : "None",
    franchiseMenuCategoryLookupName5 : "None"
}];

const DeleteButton = styled.button`
  border: 1px solid #ff0000;
  color: #ff0000;
  background-color: white;
  font-size: 14px;
  padding: 5px 15px;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
  
  &:hover {
    background-color: #ffe6e6;
  }
  
  &:focus {
    outline: none;
  }
`;

const MenuInfoView = ({ franchiseId, menuId, refreshMenuList }) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    /* state for image */
    const [imageFileElement, setImageFileElement] = useState();
    const [imgFile, setImageFile] = useState("");

    /* state for language */
    const targetFieldsString = `"${t("menu.franchiseMenu.menuInfo.detail.name")}", "${t("menu.franchiseMenu.menuInfo.detail.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* states */
    const [menuInfo, setMenuInfo] = useState({});
    const [menuCategoryList, setMenuCategoryList] = useState([]);

    /* for editing */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);

    /* loading */
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
    const [isImageUploading, setIsImageUploading] = useState(false);

    /* deletion */
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    /* fetch category list */
    const fetchCategoryList = async (successCallback) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/franchise-menu-category/franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newMenuCategoryList = res.data.item['franchiseMenuCategoryList'];
            if (newMenuCategoryList.length > 0) {
                setMenuCategoryList(newMenuCategoryList);
                successCallback();
            } else {
                setMenuCategoryList(emptyCategoryList);
            }
        } catch (e) {
            console.log(e);
            setMenuCategoryList(emptyCategoryList);
        }
    };

    /* fetch menu information */
    const fetchMenuInformation = async () => {
        try {
            await setIsLoading(true);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-menu`
                + `/franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newMenuList = res.data.item['franchiseMenuList'];
            const targetMenuInfo = extractTargetMenu(newMenuList);
            if (_.isEqual(targetMenuInfo, {})) {
                navigate('/not-found');
            }
            await setMenuInfo(targetMenuInfo);
            /* request body init */
            initRequestBody(targetMenuInfo);
        } catch (e) {
            console.log(e);
            await setIsLoading(false);
            setMenuInfo({});
        }
    };

    /* upload image */
    const uploadImage = async (file) => {
        await setIsImageUploading(true);
        const formData = new FormData();
        formData.append("file", file);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/image/menu/${menuId}`;
            const axiosCall = () => axios.post(
                fetchUrl,
                formData,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "multipart/form-data",
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            /* success */
            await setIsImageUploading(false);
            initialization();
        } catch (e) {
            console.log(e);
            await setIsImageUploading(false);
            toast.error(<ToastAlertView message={t("message.failedToSaveImage")} />);
            initialization();
        }
    };

    /* put menu */
    const putMenuInfo = async () => {
        await setIsSaveButtonLoading(true);
        /* extra information setup */
        let newRequestBody = _.cloneDeep(requestBody);
        if (newRequestBody['extraInformationList'].length > 0) {
            newRequestBody['extraInformation'] = DataUtils.formatObjArrayToObj(newRequestBody['extraInformationList']);
            delete newRequestBody['extraInformationList'];
        } else {
            newRequestBody['extraInformation'] = {};
            delete newRequestBody['extraInformationList'];
        }
        /* axios call */
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/`
                + `franchise-menu/franchise/${franchiseId}/menu/${menuId}`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await refreshMenuList();
            /* reset */
            setFixedRequestBody(_.cloneDeep(requestBody));
            setIsEdited(false);
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
        setIsSaveButtonLoading(false);
    };

    /* reset */
    const resetData = async () => {
        await setIsValidMap(isValidMapInit);
        await setIsValid(true);
        await setIsEdited(false);
        setRequestBody(_.cloneDeep(fixedRequestBody));
    };

    /* initialize request body */
    const initRequestBody = async (menuInfo) => {
        let newRequestBody = {};
        newRequestBody["franchiseMenuAlias"] = menuInfo["franchiseMenuAlias"];
        newRequestBody["franchiseMenuAlias1"] = menuInfo["franchiseMenuAlias1"];
        newRequestBody["franchiseMenuAlias2"] = menuInfo["franchiseMenuAlias2"];
        newRequestBody["franchiseMenuAlias3"] = menuInfo["franchiseMenuAlias3"];
        newRequestBody["franchiseMenuAlias4"] = menuInfo["franchiseMenuAlias4"];
        newRequestBody["franchiseMenuAlias5"] = menuInfo["franchiseMenuAlias5"];
        newRequestBody["franchiseMenuDescription"] = menuInfo["franchiseMenuDescription"]
        newRequestBody["franchiseMenuDescription1"] = menuInfo["franchiseMenuDescription1"]
        newRequestBody["franchiseMenuDescription2"] = menuInfo["franchiseMenuDescription2"]
        newRequestBody["franchiseMenuDescription3"] = menuInfo["franchiseMenuDescription3"]
        newRequestBody["franchiseMenuDescription4"] = menuInfo["franchiseMenuDescription4"]
        newRequestBody["franchiseMenuDescription5"] = menuInfo["franchiseMenuDescription5"]
        newRequestBody["isBoothMenu"] = menuInfo["isBoothMenu"];
        newRequestBody["franchiseMenuPrice"] = menuInfo["franchiseMenuPrice"];
        newRequestBody["franchiseMenuPriceUnit"] = menuInfo["franchiseMenuPriceUnit"];
        newRequestBody["franchiseMenuCategoryId"] = menuInfo["franchiseMenuCategoryId"];
        newRequestBody["extraInformationList"] = menuInfo["extraInformation"] ? objectToArray(menuInfo["extraInformation"]) : [];
        await setRequestBody(newRequestBody);
        await setFixedRequestBody(_.cloneDeep(newRequestBody));
    };

    /* for extra information : object to array */
    const objectToArray = (object) => {
        let newArray = [];
        Object.keys(object).map(objectKey => {
            let newElement = {};
            newElement[objectKey] = object[objectKey];
            newArray.push(newElement);
        });
        return newArray;
    };

    /* extract target menu from menu list */
    const extractTargetMenu = (menuList) => {
        for (let i=0 ; i<menuList.length ; i++) {
            if (menuList[i]['menuInfo']['franchiseMenuId'] === menuId) {
                return menuList[i]['menuInfo'];
            }
        }
        return {};
    };

    /* initialization */
    const initialization = async () => {
        await setMenuCategoryList(emptyCategoryList);
        await setRequestBody(requestBodyInit);
        await setIsValidMap(isValidMapInit);
        await setIsValid(true);
        await setIsEdited(false);
        await setImageFile("");
        await setImageFileElement(null);
        await setIsLoading(false);
        fetchCategoryList(fetchMenuInformation);
    };

    /* input handlers */
    const handleIsBoothMenuChange = (value) => {
        DataUtils.updateHelper(setRequestBody, "isBoothMenu", value);
        DataUtils.updateHelper(setIsValidMap, "isBoothMenu", true);
    };

    const handleMenuPriceUnitInput = (value) => {
        DataUtils.updateHelper(setRequestBody, "franchiseMenuPriceUnit", value);
        DataUtils.updateHelper(setIsValidMap, "franchiseMenuPriceUnit", !DataUtils.isEmptyStr(value));
    };

    const handleMenuCategoryIdInput = (value) => {
        DataUtils.updateHelper(setRequestBody, "franchiseMenuCategoryId", value);
        DataUtils.updateHelper(setIsValidMap, "franchiseMenuCategoryId", !DataUtils.isEmptyStr(value));
    };

    const handleMenuPriceInput = (value) => {
        DataUtils.updateHelper(setRequestBody, "franchiseMenuPrice", parseFloat(value));
        DataUtils.updateHelper(setIsValidMap, "franchiseMenuPrice", DataUtils.isValidNumStr(value));
    };

    const handleExtraInfoChange = (newObjArray) => {
        DataUtils.updateHelper(setRequestBody, "extraInformationList", newObjArray);
    };

    /* effects */
    useEffect(() => {
        initialization();
    }, [menuId]);

    /* track isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    /* track isEdited */
    useEffect(() => {
        setIsEdited(!_.isEqual(requestBody, fixedRequestBody));
    }, [requestBody]);

    return (
        <Container fluid>
            <Row>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start'
                    }}
                >
                    {/* language selection */}
                    <LanguageSelectBox
                        title={t("language.selectBox.title.menu")}
                        languageMetadataList={languageMetadataList}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        setIsHighlightOn={setIsHighlightOn}
                        targetFieldString={targetFieldsString}
                    />
                    {/* isBoothMenu */}
                    <div
                        style={{
                            display: 'inline-block',
                            marginLeft: 'auto'
                        }}
                    >
                        <TitleAndSwitchBox
                            title={t("menu.franchiseMenu.menuInfo.detail.isBoothMenu")}
                            value={requestBody["isBoothMenu"]}
                            onChange={handleIsBoothMenuChange}
                            isForEdit={true}
                            isEnabled={true}
                        />
                    </div>
                </div>
            </Row>
            <Row
                style={{
                    marginTop: '10px'
                }}
            >
                <Col
                    md={"3"}
                    style={{
                        padding: '0px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '15px'
                        }}
                    >
                        <ImageUploadBox
                            boxKey={menuId}
                            imgFile={
                                (menuInfo["franchiseMenuImage"] && (imgFile === "")) ? menuInfo["franchiseMenuImage"] : imgFile
                            }
                            setImgFile={setImageFile}
                            buttonText={t("menu.franchiseMenu.button.uploadImage")}
                            setImageFileElement={async (file) => {
                                await setImageFileElement(file);
                                uploadImage(file);
                            }}
                        />
                    </div>
                </Col>
                <Col
                    // md={"9"}
                    style={{
                        padding: '0px',
                        paddingLeft: '10px'
                    }}
                >
                    {/* menu alias, menu price info */}
                    <Row>
                        <Col>
                            {/* menuAlias(es) */}
                            <LanguageInput
                                type={"text"}
                                title={t("menu.franchiseMenu.menuInfo.detail.name") + ` (${t("language.callName." + currentLang)})`}
                                dataKeyTranslationPath={"language.dataKey.menu.franchise.info.name"}
                                valueMap={requestBody}
                                updateIsValidMap={(key, value) => DataUtils.updateHelper(setIsValidMap, key, value)}
                                updateValueMap={(key, value) => DataUtils.updateHelper(setRequestBody, key, value)}
                                isValidMap={isValidMap}
                                isForEdit={true}
                                currentLang={currentLang}
                                isHighlightOn={isHighlightOn}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <Col>
                            {/* menuPrice */}
                            <TitleAndInputBox
                                title={t("menu.franchiseMenu.menuInfo.detail.price")}
                                value={requestBody["franchiseMenuPrice"]}
                                onChange={handleMenuPriceInput}
                                isForEdit={true}
                                type={"number"}
                                isValid={isValidMap["franchiseMenuPrice"]}
                                isEnabled={true}
                            />
                        </Col>
                        <Col>
                            {/* menuPriceUnit */}
                            <TitleAndInputBox
                                title={t("menu.franchiseMenu.menuInfo.detail.priceUnit")}
                                value={requestBody["franchiseMenuPriceUnit"]}
                                onChange={handleMenuPriceUnitInput}
                                isForEdit={true}
                                type={"text"}
                                isValid={isValidMap["franchiseMenuPriceUnit"]}
                                isEnabled={true}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* menu category */}
            <Row
                style={{
                    marginTop: '5px'
                }}
            >
                <TitleAndSelectBox
                    title={t("menu.franchiseMenu.menuInfo.detail.category")}
                    innerValue={requestBody["franchiseMenuCategoryId"]}
                    valueList={menuCategoryList}
                    valueSelector={"franchiseMenuCategoryLookupId"}
                    viewSelector={t("language.dataKey.menu.detail.category")}
                    onChange={handleMenuCategoryIdInput}
                />
            </Row>
            <Row
                style={{
                    marginTop: '5px'
                }}
            >
                <Col
                    style={{
                        padding: '0px'
                    }}
                >
                    {/* menu description */}
                    <LanguageInput
                        type={"textarea"}
                        title={t("menu.franchiseMenu.menuInfo.detail.description") + ` (${t("language.callName." + currentLang)})`}
                        dataKeyTranslationPath={"language.dataKey.menu.franchise.info.description"}
                        valueMap={requestBody}
                        updateIsValidMap={(key, value) => DataUtils.updateHelper(setIsValidMap, key, value)}
                        updateValueMap={(key, value) => DataUtils.updateHelper(setRequestBody, key, value)}
                        isValidMap={isValidMap}
                        height={"80"}
                        isForEdit={true}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Col>
            </Row>
            {/* extra information */}
            <Row
                style={{
                    marginTop: '5px'
                }}
            >
                <ExtraInformationView
                    dataObjList={requestBody['extraInformationList']}
                    setDataObjList={handleExtraInfoChange}
                    setIsValidMap={setIsValidMap}
                    objKey={"extraInformation"}
                    title={t("menu.franchiseMenu.menuInfo.detail.extraInfo.title")}
                    height={"140"}
                    isForEdit={true}
                />
            </Row>
            {/* reset and save button */}
            <Row
                style={{
                    marginTop: '20px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    {/* deletion button */}
                    <DeleteButton
                        onClick={async () => await setIsDeleteConfirmOpen(true)}
                    >
                        {t("button.delete")}
                    </DeleteButton>
                    {/* delete confirmation */}
                    <DeleteConfirmModal
                        isOpen={isDeleteConfirmOpen}
                        setIsOpen={setIsDeleteConfirmOpen}
                        isDelayed={true}
                        confirmMessage={t("language.message.deletion.confirmation", { entityName : menuInfo[t("language.dataKey.menu.franchise.info.name")] })}
                        entity={DeleteService.ValidEntities.FRANCHISE_MENU}
                        entityName={menuInfo[t("language.dataKey.menu.franchise.info.name")]}
                        id={`franchise-menu/franchise/${franchiseId}/menu/${menuId}`}
                        onDeleteSuccess={async () => {
                            /* have to check */
                            await navigate(`/man-menu-franchise/franchise/${franchiseId}`);
                        }}
                        depsToShow={[
                            t("language.message.deletion.depsList.franchiseMenu.menuRecipe"),
                            t("language.message.deletion.depsList.franchiseMenu.menuSale"),
                            t("language.message.deletion.depsList.franchiseMenu.menuOption"),
                            t("language.message.deletion.depsList.franchiseMenu.branchMenuDiff")
                        ]}
                        validateMessage={t("language.message.deletion.verificationMessage", { entityName : menuInfo[t("language.dataKey.menu.franchise.info.name")] })}
                        delayToUndo={DeleteService.getMediumDelay()}
                        onUndoSuccess={async () => {
                            const currLocation = location.pathname;
                            await refreshMenuList();
                            await navigate(currLocation);
                        }}
                    />
                    <div
                        style={{
                            width: '100px',
                            marginLeft: 'auto'
                        }}
                    >
                        <SimpleButton
                            onClickCallback={resetData}
                            isEnabled={isEdited}
                            label={t("menu.franchiseMenu.menuInfo.button.reset")}
                            borderColor={"#999999"}
                            buttonColor={"#999999"}
                            buttonHoverColor={"#ececec"}
                            buttonDisabledColor={"#c2c2c2"}
                        />
                    </div>
                    <div
                        style={{
                            width: '100px',
                            marginLeft: '10px'
                        }}
                    >
                        {
                            isSaveButtonLoading ?
                                <ButtonWithSpinner
                                    buttonColor={"#fc7242"}
                                />
                                :
                                <OrangeButton
                                    onClickCallback={() => putMenuInfo()}
                                    isEnabled={isValid && isEdited}
                                    label={t("button.save")}
                                />
                        }
                    </div>
                </div>
            </Row>
            {/* image uploading modal */}
            <OnUploadModal
                isOpen={isImageUploading}
            />
        </Container>
    );
};

export default MenuInfoView;