import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import DataUtils from "../../common/utils/DataUtils";
import OverlayToolTip from "../../common/toolTip/OverlayToolTip";
import styled from "styled-components";
import Switch from "react-switch";
import Form from "react-bootstrap/Form";
import {FiLock, FiUnlock} from "react-icons/fi";

const colorMap = {
    backgroundGrey : "#F8F8F8",
    defaultButtonColor : "#c2c2c2",
    defaultButtonHoverColor : "#F5F5F5",
    defaultButtonColorOnSelected : "#A9A9A9",
    diffUseButtonColor : "#F0B3FF",
    diffUseButtonHoverColor : "#FAE6FF",
    diffUseButtonColorOnSelected : "#CC00FF"
};

const OptionButton = styled.button`
  width: auto;
  padding: 3px 7px;
  color: ${(props) => props.color ? props.color : "#757575"};
  font-size: 14px;
  border: 1px solid ${(props) => props.color ? props.color : "#757575"};
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "white"};
  transition: all ease-in-out 0.2s;
  margin-left: ${(props) => props.marginLeft ? props.marginLeft : "0px"};
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${(props) => props.hoverColor ? props.hoverColor : "white"};
  }
`;

const TransButton = styled.button`
  border: none;
  background: none;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  color: #757575;

  &:hover {
    color: ${(props) => props.enabled ? "black" : ""};
  }

  &:focus {
    outline: none;
  }
`;

const DiffDataViewWithLang = ({ type,
                                  hoverMessageForUseDiffButton,
                                  title,
                                  originalData,
                                  diffEntity,
                                  isValidMap,
                                  updateDiffEntity,
                                  updateIsValidMap,
                                  isForEdit,
                                  height,
                                  originalDataKeyTranslationPath,
                                  diffDataKeyTranslationPath,
                                  currentLang,
                                  isHighlightOn}) => {
    /** NOTE */
    /** 1. possible types : text, textarea */

    const { t } = useTranslation();

    const [diffDataKey, setDiffDataKey] = useState("");
    const [originalDataKey, setOriginalDataKey] = useState("");

    /* data key update */
    useEffect(() => {
        setDiffDataKey(t(diffDataKeyTranslationPath, { lng: currentLang }));
        setOriginalDataKey(t(originalDataKeyTranslationPath, { lng: currentLang }));
    }, [currentLang]);

    const handleDiffUpdate = (key, value) => {
        if (isForEdit && (diffEntity[key] != null)) {
            updateDiffEntity(key, value);
            /* check is null or empty */
            if (currentLang === "en-US") { /** English field is always mandatory. */
                updateIsValidMap(key, !DataUtils.isEmptyStr(value));
            }
        } else {
            return;
        }
    };

    const handleNoUseDiff = () => {
        if (!isForEdit) {
            return;
        }
        if (diffEntity[diffDataKey] == null) {
            return;
        }
        /* set null */
        updateDiffEntity(diffDataKey, null);
        /* empty is valid one */
        updateIsValidMap(diffDataKey, true);
    };

    const handleUseDiff = () => {
        if (!isForEdit) {
            return;
        }
        if (diffEntity[diffDataKey] != null) {
            return;
        }
        /* initial value set */
        updateDiffEntity(diffDataKey, originalData[originalDataKey]);
        /* valid one */
        updateIsValidMap(diffDataKey, true);
    };


    return (
        <div
            style={{
                width: '100%',
                height: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: '10px',
                        marginBottom: '5px'
                    }}
                >
                    <span
                        style={{
                            color: '#8f8f8f',
                            fontSize: '14px'
                        }}
                    >
                        {title}
                    </span>
                    {/* diff use or not buttons */}
                    <div
                        style={{
                            height: '100%',
                            display: 'inline-block',
                            marginLeft: "10px"
                        }}
                    >
                        {
                            (diffEntity[diffDataKey] != null) &&
                            <OverlayToolTip
                                direction={"top"}
                                text={hoverMessageForUseDiffButton}
                            >
                                <TransButton
                                    onClick={() => {
                                        if (diffEntity[diffDataKey] != null) {
                                            handleNoUseDiff();
                                        } else {
                                            handleUseDiff();
                                        }
                                    }}
                                >
                                    <FiUnlock
                                        size={"14"}
                                        color={"#fc7242"}
                                    />
                                </TransButton>
                            </OverlayToolTip>
                        }
                        {
                            (diffEntity[diffDataKey] == null) &&
                            <TransButton
                                onClick={() => {
                                    if (diffEntity[diffDataKey] != null) {
                                        handleNoUseDiff();
                                    } else {
                                        handleUseDiff();
                                    }
                                }}
                                enabled={true}
                            >
                                <FiLock
                                    size={"14"}
                                />
                            </TransButton>
                        }
                    </div>
                </div>
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    {
                        (type === "textarea") &&
                        <textarea
                            id={"data-view-input"}
                            type={type}
                            readOnly={!(diffEntity[diffDataKey] && isForEdit)}
                            value={
                                diffEntity[diffDataKey] ? diffEntity[diffDataKey] : originalData[originalDataKey]
                            }
                            onChange={(e) => handleDiffUpdate(diffDataKey, e.target.value)}
                            style={
                                isHighlightOn ?
                                    {
                                        height : height ? height + "px" : "100px",
                                        resize : 'none',
                                        outline : 'none',
                                        borderColor: '#fc7242'
                                    }
                                    :
                                    (diffEntity[diffDataKey]) ?
                                        (isValidMap[diffDataKey] ?
                                            {
                                                height : height ? height + "px" : "100px",
                                                resize : 'none',
                                                outline : 'none'
                                            }
                                            :
                                            {
                                                height : height ? height + "px" : "100px",
                                                resize : 'none',
                                                outline : 'none',
                                                borderColor: isForEdit ? "red" : ""
                                            })
                                        :
                                        {
                                            height : height ? height + "px" : "100px",
                                            resize : 'none',
                                            outline : 'none',
                                            backgroundColor: colorMap["backgroundGrey"]
                                        }
                            }
                        />
                    }
                    {
                        (type === "text") &&
                        <input
                            id={"data-view-input"}
                            type={type}
                            readOnly={!(diffEntity[diffDataKey] && isForEdit)}
                            value={diffEntity[diffDataKey] ? diffEntity[diffDataKey] : originalData[originalDataKey]}
                            onChange={(e) => handleDiffUpdate(diffDataKey, e.target.value)}
                            style={
                                isHighlightOn ?
                                    {
                                        borderColor: "#fc7242"
                                    }
                                    :
                                    (diffEntity[diffDataKey]) ?
                                        (isValidMap[diffDataKey] ?
                                            {}
                                            :
                                            {
                                                borderColor: isForEdit ? "red" : ""
                                            })
                                        :
                                        {
                                            backgroundColor: colorMap["backgroundGrey"]
                                        }
                            }
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default DiffDataViewWithLang;