import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import _ from "lodash";
import DataCompareService from "../../../../../../services/DataCompareService";
import {Col, Container, Row} from "react-bootstrap";
import AuthService from "../../../../../../services/AuthService";
import TitleAndSwitchBox from "../../../../../common/dataView/TitleAndSwitchBox";
import DiffDataView from "../../../dataView/DiffDataView";
import DataUtils from "../../../../common/utils/DataUtils";
import DiffDataViewWithLang from "../../../dataView/DiffDataViewWithLang";
import ExtraInfoDiffView from "../../../dataView/ExtraInfoDiffView";
import SimpleButton from "../../../../common/buttons/SimpleButton";
import ButtonWithSpinner from "../../../../common/buttons/ButtonWithSpinner";
import OrangeButton from "../../../../common/buttons/OrangeButton";
import UserService from "../../../../../../services/UserService";
import axios from "axios";
import {toast} from "react-toastify";
import ToastAlertView from "../../../../../common/alert/ToastAlertView";
import ImsSystemRole from "../../../../../../auth/roles/ImsSystemRole";

const diffEntityInit = {
    isEnabled : null,
    franchiseMenuOptionPrice : null,
    franchiseMenuOptionAlias : null,
    franchiseMenuOptionAlias1 : null,
    franchiseMenuOptionAlias2 : null,
    franchiseMenuOptionAlias3 : null,
    franchiseMenuOptionAlias4 : null,
    franchiseMenuOptionAlias5 : null,
    extraInformationList : null
};

const isValidMapInit = {
    isEnabled : true,
    franchiseMenuOptionPrice : true,
    franchiseMenuOptionAlias : true,
    franchiseMenuOptionAlias1 : true,
    franchiseMenuOptionAlias2 : true,
    franchiseMenuOptionAlias3 : true,
    franchiseMenuOptionAlias4 : true,
    franchiseMenuOptionAlias5 : true,
    extraInformationList : true
};

const OptionItemContainer = styled.div`
  width: 100%;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(117,117,117,1);
  -moz-box-shadow: 0px 0px 4px 0px rgba(117,117,117,1);
  box-shadow: 0px 0px 4px 0px rgba(117,117,117,1);
`;

const OptionDiffItemView = ({ optionInfo, refreshOptionList, currentLang, isHighlightOn }) => {

    const { t } = useTranslation();
    const { branchId, menuId } = useParams();

    /* states */
    const [diffEntity, setDiffEntity] = useState(diffEntityInit);
    const [fixedDiffEntity, setFixedDiffEntity] = useState(diffEntityInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);

    /* isLoading */
    const [isLoading, setIsLoading] = useState(false);

    /* data put */
    const putOptionDiff = async () => {
        await setIsLoading(true);
        let newRequestBody = _.cloneDeep(diffEntity);
        /* set extraInformation */
        if (diffEntity['extraInformationList'] == null) {
            newRequestBody['extraInformation'] = null;
        } else {
            newRequestBody['extraInformation'] = DataUtils.formatObjArrayToObj(diffEntity['extraInformationList']);
        }
        delete newRequestBody['extraInformationList'];

        /* axios call */
        try {
            console.log(optionInfo);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/`
                + `branch-menu/branch/${branchId}/menu/${menuId}/option/${optionInfo['optionInfoBeforeMergeDiff']['franchiseMenuOptionId']}/diff`;
            await UserService.updateToken(() => axios.put(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            /* reset */
            await refreshOptionList();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
        setIsLoading(false);
    };

    /* for extra information : object to array */
    const objectToArray = (object) => {
        let newArray = [];
        Object.keys(object).map(objectKey => {
            let newElement = {};
            newElement[objectKey] = object[objectKey];
            newArray.push(newElement);
        });
        return newArray;
    };

    /* initialize */
    const initialize = async () => {
        await setIsLoading(true);
        /* initialize diffEntity */
        let newDiffEntity = {};
        if (optionInfo['optionInfoDiffEntity'] != null) {
            newDiffEntity['isEnabled'] = optionInfo['optionInfoDiffEntity']['isEnabled'];
            newDiffEntity['franchiseMenuOptionPrice'] = optionInfo['optionInfoDiffEntity']['branchMenuOptionDiffPrice'];
            newDiffEntity['franchiseMenuOptionAlias'] = optionInfo['optionInfoDiffEntity']['branchMenuOptionDiffAlias'];
            newDiffEntity['franchiseMenuOptionAlias1'] = optionInfo['optionInfoDiffEntity']['branchMenuOptionDiffAlias1'];
            newDiffEntity['franchiseMenuOptionAlias2'] = optionInfo['optionInfoDiffEntity']['branchMenuOptionDiffAlias2'];
            newDiffEntity['franchiseMenuOptionAlias3'] = optionInfo['optionInfoDiffEntity']['branchMenuOptionDiffAlias3'];
            newDiffEntity['franchiseMenuOptionAlias4'] = optionInfo['optionInfoDiffEntity']['branchMenuOptionDiffAlias4'];
            newDiffEntity['franchiseMenuOptionAlias5'] = optionInfo['optionInfoDiffEntity']['branchMenuOptionDiffAlias5'];
            /* extra information */
            newDiffEntity['extraInformationList'] = optionInfo['optionInfoDiffEntity']['extraInformation'] ?
                objectToArray(JSON.parse(optionInfo['optionInfoDiffEntity']['extraInformation'])) : null;
        } else {
            newDiffEntity = diffEntityInit;
        }
        await setDiffEntity(newDiffEntity);
        await setFixedDiffEntity(_.cloneDeep(newDiffEntity));

        /* isValid, isEdited */
        await setIsValidMap(isValidMapInit);
        await setIsValid(true);
        await setIsEdited(false);
        await setIsLoading(false);
    };

    /* auth */
    const hasPutAuth = () => {
        return AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BRANCH_MANAGEMENT, "PUT");
    };

    /* utils */
    const updateDiffEntity = (key, value) => {
        DataUtils.updateHelper(setDiffEntity, key, value);
    };

    const updateIsValidMap = (key, value) => {
        DataUtils.updateHelper(setIsValidMap, key, value);
    };

    /* track isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    /* track isEdited */
    useEffect(() => {
        setIsEdited(!_.isEqual(diffEntity, fixedDiffEntity));
    }, [diffEntity]);

    /* effects */
    useEffect(() => {
        initialize();
    }, [optionInfo]);

    return (
        <OptionItemContainer>
            <Container fluid>
                <Row>
                    <DiffDataView
                        type={"switch"}
                        hoverMessageForUseDiffButton={t("menu.branch.branchMenu.message.useDiffMessage", { fieldName : `"${t("menu.branch.branchMenu.option.isEnabled")}"` })}
                        title={t("menu.branch.branchMenu.option.isEnabled")}
                        originalDataKey={"isEnabled"}
                        diffDataKey={"isEnabled"}
                        originalData={optionInfo['optionInfoBeforeMergeDiff']}
                        diffEntity={diffEntity}
                        isValidMap={isValidMap}
                        updateDiffEntity={updateDiffEntity}
                        updateIsValidMap={updateIsValidMap}
                        isForEdit={hasPutAuth()}
                        booleanReversed={true}
                        booleanOnMessage={t("message.menuOptionOnThisBranch")}
                        booleanOffMessage={t("message.menuOptionOffThisBranch")}
                    />
                </Row>
                <Row>
                    <DiffDataViewWithLang
                        type={"text"}
                        hoverMessageForUseDiffButton={t("menu.branch.branchMenu.message.useDiffMessage", { fieldName : `"${t("menu.branch.branchMenu.option.name")}"` })}
                        title={t("menu.branch.branchMenu.option.name") + ` (${t("language.callName." + currentLang)})`}
                        originalData={optionInfo['optionInfoBeforeMergeDiff']}
                        diffEntity={diffEntity}
                        isValidMap={isValidMap}
                        updateDiffEntity={updateDiffEntity}
                        updateIsValidMap={updateIsValidMap}
                        isForEdit={hasPutAuth()}
                        originalDataKeyTranslationPath={"language.dataKey.branchMenu.option.name"}
                        diffDataKeyTranslationPath={"language.dataKey.branchMenu.option.name"}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                <Row>
                    <DiffDataView
                        type={"number"}
                        hoverMessageForUseDiffButton={t("menu.branch.branchMenu.message.useDiffMessage", { fieldName : `"${t("menu.branch.branchMenu.option.price")}"` })}
                        title={t("menu.branch.branchMenu.option.price")}
                        originalDataKey={"franchiseMenuOptionPrice"}
                        diffDataKey={"franchiseMenuOptionPrice"}
                        originalData={optionInfo['optionInfoBeforeMergeDiff']}
                        diffEntity={diffEntity}
                        isValidMap={isValidMap}
                        updateDiffEntity={updateDiffEntity}
                        updateIsValidMap={updateIsValidMap}
                        isForEdit={hasPutAuth()}
                    />
                </Row>
                <Row>
                    <ExtraInfoDiffView
                        title={t("menu.branch.branchMenu.option.extraInfo.title")}
                        originalDataObjList={
                            optionInfo['optionInfoBeforeMergeDiff']['extraInformation'] ?
                                objectToArray(optionInfo['optionInfoBeforeMergeDiff']['extraInformation'])
                                :
                                []
                        }
                        diffDataObjList={diffEntity['extraInformationList']}
                        updateDiffObjList={(newList) => updateDiffEntity("extraInformationList", newList)}
                        updateIsValidMap={setIsValidMap}
                        diffObjKey={"extraInformationList"}
                        height={"100"}
                        isForEdit={hasPutAuth()}
                        hoverMessageForUseDiffButton={t("menu.branch.branchMenu.message.useDiffMessage", { fieldName : `"${t("menu.branch.branchMenu.option.extraInfo.title")}"` })}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div
                            style={{
                                width: '100px',
                                marginLeft: 'auto'
                            }}
                        >
                            <SimpleButton
                                onClickCallback={initialize}
                                isEnabled={isEdited}
                                label={t("menu.franchiseMenu.menuInfo.button.reset")}
                                borderColor={"#999999"}
                                buttonColor={"#999999"}
                                buttonHoverColor={"#ececec"}
                                buttonDisabledColor={"#c2c2c2"}
                            />
                        </div>
                        <div
                            style={{
                                width: '100px',
                                marginLeft: '10px'
                            }}
                        >
                            {
                                isLoading ?
                                    <ButtonWithSpinner
                                        buttonColor={"#fc7242"}
                                    />
                                    :
                                    <OrangeButton
                                        onClickCallback={() => putOptionDiff()}
                                        isEnabled={isValid && isEdited}
                                        label={t("button.save")}
                                    />
                            }
                        </div>
                    </div>
                </Row>
            </Container>
        </OptionItemContainer>
    );
};

export default OptionDiffItemView;