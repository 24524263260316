import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import UserService from "../../services/UserService";
import RenderOnRole from "../../auth/RenderOnRole";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import {Col, Container, Row } from "react-bootstrap";
import TitleAndSelectBox from "../common/dataView/TitleAndSelectBox";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import styled from "styled-components";
import {useDaumPostcodePopup} from "react-daum-postcode";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import DataCompareService from "../../services/DataCompareService";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import _ from "lodash";
import uuid from "react-uuid";
import TitleAndSearchSelectBox from "../common/dataView/TitleAndSearchSelectBox";

const BusinessNumberVerifyButton = styled.button`
  border: 1px solid #fc7242;
  color: #fc7242;
  border-radius: 10px;
  background-color: white;
  padding: 5px 10px;
  transition: all ease-in-out 0.2s;
  font-size: 14px;
  
  &:hover {
    background-color: #FFF1EC;
  }
  
  &:focus {
    outline: none;
  }
`;

const BusinessNumberVerifyButtonDisabled = styled.button`
  border: 1px solid #c2c2c2;
  color: #c2c2c2;
  border-radius: 10px;
  background-color: white;
  padding: 5px 10px;
  transition: all ease-in-out 0.2s;
  font-size: 14px;
  
  &:focus {
    outline: none;
  }
`;

const AddressSearchButton = styled.button`
  border: 1px solid #fc7242;
  background: none;
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  color: #fc7242;
  font-size: 14px;
  padding: 4px 10px;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #fcf9ed;
  }
`;

const defaultInfoInit = {
    branchName : "",
    branchDescription : ""
};

const ownerInfoInit = {
    branchMasterId : "",
    branchGroupId : ""
};

/** for the USA, the locationPostalCode and locationDetail field does not be used */
/** plus, locationAddress fields are split 5 fields */
const locationInfoInit = {
    locationPostalCode : "1111",
    locationAddressNumber : "",
    locationAddressStreet : "",
    locationAddressCityOrTown : "",
    locationAddressState : "",
    locationAddressZipCode : "",
    locationAddressDetail : "None",
    locationLatitude : "0.0",
    locationLongitude : "0.0",
};

/** for the USA, the locationPostalCode and locationDetail field does not be used */
const locationInfoIsValidMapInit = {
    locationPostalCode : true,
    locationAddressNumber : false,
    locationAddressStreet : false,
    locationAddressCityOrTown : false,
    locationAddressState : false,
    locationAddressZipCode : false,
    locationAddressDetail : true,
    locationLatitude : true,
    locationLongitude : true,
};

/** for the USA, the business info field does not be used */
const businessInfoInit = {
    businessRegistrationNumber : "",
};

/** for the USA, the business info field does not be used */
const businessInfoIsValidMapInit = {
    businessRegistrationNumber : true,
};

const defaultInfoIsValidMapInit = {
    branchName : false,
    branchDescription : false
};

const ownerInfoIsValidMapInit = {
    branchMasterId : false,
    branchGroupId : false
};

const BranchAddUSA = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const openPostalCodeSearchPopup = useDaumPostcodePopup();

    /* -------------------------- */
    /* States */
    /* default information */
    const [defaultInfo, setDefaultInfo] = useState(defaultInfoInit);
    const [defaultInfoIsValidMap, setDefaultInfoIsValidMap] = useState(defaultInfoIsValidMapInit);
    const [isDefaultInfoValid, setIsDefaultInfoValid] = useState(false);

    /* owner information */
    const [ownerInfo, setOwnerInfo] = useState(ownerInfoInit);
    const [ownerInfoIsValidMap, setOwnerInfoIsValidMap] = useState(ownerInfoIsValidMapInit);
    const [isOwnerInfoValid, setIsOwnerInfoValid] = useState(false);
    const [ownerName, setOwnerName] = useState("");

    /* location information */
    const [locationInfo, setLocationInfo] = useState(locationInfoInit);
    const [locationInfoIsValidMap, setLocationInfoIsValidMap] = useState(locationInfoIsValidMapInit);
    const [isLocationInfoValid, setIsLocationInfoValid] = useState(false);

    /* business information */
    const [businessInfo, setBusinessInfo] = useState(businessInfoInit);
    const [businessInfoIsValidMap, setBusinessInfoIsValidMap] = useState(businessInfoIsValidMapInit);
    /** for the USA, default true */
    const [isBusinessInfoValid, setIsBusinessInfoValid] = useState(true);
    const [numberInputList, setNumberInputList] = useState(["", "", ""]);
    const [message, setMessage] = useState("");
    const [messageColor, setMessageColor] = useState("#757575");

    /** New: franchise registration shortcut functionality */
    const [franchiseList, setFranchiseList] = useState([]);
    const [selectedFranchiseId, setSelectedFranchiseId] = useState("none");
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    /* confirmation modal */
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    /* lists */
    const [groupList, setGroupList] = useState([]);
    const [userList, setUserList] = useState([]);


    /* -------------------------- */
    /* Utils */
    /** ----------------- */
    /** for the USA utils */
    const _toUSAFormatter = (prevRequestBody) => {
        let newRequestBody = _.cloneDeep(prevRequestBody);
        /** randomly generate business number */
        newRequestBody['businessRegistrationNumber'] = generateRandomBizCode();
        return newRequestBody;
    };

    const handleLocationNumberInput = async (value) => {
        await updateHelper(setLocationInfoIsValidMap, "locationAddressNumber", checkValidityOfInput(value));
        await updateHelper(setLocationInfo, "locationAddressNumber", value);
    };

    const handleLocationStreetInput = async (value) => {
        await updateHelper(setLocationInfoIsValidMap, "locationAddressStreet", checkValidityOfInput(value));
        await updateHelper(setLocationInfo, "locationAddressStreet", value);
    };

    const handleLocationCityInput = async (value) => {
        await updateHelper(setLocationInfoIsValidMap, "locationAddressCityOrTown", checkValidityOfInput(value));
        await updateHelper(setLocationInfo, "locationAddressCityOrTown", value);
    };

    const handleLocationStateInput = async (value) => {
        await updateHelper(setLocationInfoIsValidMap, "locationAddressState", checkValidityOfInput(value));
        await updateHelper(setLocationInfo, "locationAddressState", value);
    };

    const handleLocationZipCodeInput = async (value) => {
        await updateHelper(setLocationInfoIsValidMap, "locationAddressZipCode", checkValidityOfInput(value));
        await updateHelper(setLocationInfo, "locationAddressZipCode", value);
    };

    const checkValidityOfInput = (value) => {
        if (value.includes(',')) {
            return false;
        }

        if (/^\s+$/.test(value)) {
            return false;
        }

        if (value === "") {
            return false;
        }

        return true;
    }

    const getRandomDigit = () => {
        return Math.floor(Math.random() * 10);
    }

    const generateRandomBizCode = () => {
        let result = '';

        for (let i = 0; i < 3; i++) {
            result += getRandomDigit();
        }
        result += '-';

        for (let i = 0; i < 2; i++) {
            result += getRandomDigit();
        }
        result += '-';

        for (let i = 0; i < 5; i++) {
            result += getRandomDigit();
        }

        return result;
    }

    /** ----------------- */

    const updateHelper = (updateFunction, key, value) => {
        updateFunction(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    const findNameByIdFromObjectList = (objList, id) => {
        for (let i=0 ; i<objList.length ; i++) {
            if (objList[i]['userId'] === id) {
                return objList[i]['userName'];
            }
        }
    };

    const updateNumberList = (index, value) => {
        setNumberInputList(prevList => {
            let newList = [...prevList];
            newList[index] = value;
            return newList;
        });
    };

    // const onSuccessPostalCodeSearch = (data) => {
    //     updateHelper(setLocationInfo, "locationPostalCode", data['zonecode']);
    //     updateHelper(setLocationInfo, "locationAddress", data['address']);
    // };

    /* -------------------------- */
    /* Data fetching */
    /* fetch group list */
    const fetchGroupList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/group`;
            const axiosCall = () => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            if (res.data['error_code'] !== "GET_EMPTY_DATA") {
                let newGroupArray = res.data.item.groupList;
                setGroupList(newGroupArray);
                /* initial value */
                updateHelper(setOwnerInfo, "branchGroupId", newGroupArray[0]['groupId']);
                updateHelper(setOwnerInfoIsValidMap, "branchGroupId", true);
            } else {
                setGroupList([]);
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* fetch user list */
    const fetchUserList = async (targetGroupId) => {
        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
            + `/api/v1/user/group/${targetGroupId}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        if (res.data['error_code'] !== "GET_EMPTY_DATA") {
            let newUserArray = res.data.item.userList;
            setUserList(newUserArray);
            updateHelper(setOwnerInfo, "branchMasterId", newUserArray[0]['userId']);
            updateHelper(setOwnerInfoIsValidMap, "branchMasterId", true);
        } else {
            setUserList([]);
        }
    };

    /* fetch franchise list : when groupId selected */
    const fetchFranchiseListByGroupId = async () => {
        const initialFranchiseList = [
            {
                franchiseName : t("menu.branch.franchise.search.none"),
                franchiseId : "none"
            }
        ];
        if (ownerInfo["branchGroupId"] === "") {
            await setFranchiseList(initialFranchiseList);
            await setSelectedFranchiseId("none");
        } else {
            let targetGroupId = ownerInfo["branchGroupId"];
            try {
                let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise`;
                const axiosCall = () => axios.get(
                    fetchUrl,
                    {
                        headers: {
                            Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                        }
                    }
                );
                const res = await UserService.updateToken(axiosCall);
                let newFranchiseList = _.cloneDeep(initialFranchiseList);
                const dataList = res.data.item.franchiseList;
                dataList.map(groupAndFranchiseObj => {
                    let franchiseList = groupAndFranchiseObj[targetGroupId] ? groupAndFranchiseObj[targetGroupId] : [];
                    for (let i=0 ; i<franchiseList.length ; i++) {
                        newFranchiseList.push(franchiseList[i]);
                    }
                });
                await setSelectedFranchiseId("none");
                await setFranchiseList(newFranchiseList);
            } catch (e) {
                console.log(e);
                await setFranchiseList(initialFranchiseList);
                await setSelectedFranchiseId("none");
            }
        }
    };

    /* post branch */
    const postBranch = async () => {
        await setIsSaveLoading(true);
        let requestBody = {};
        /* location info */
        requestBody["locationPostalCode"] = locationInfo["locationPostalCode"];
        requestBody["locationAddress"] = locationInfo["locationAddressNumber"]
            + ', ' + locationInfo["locationAddressStreet"]
            + ', ' + locationInfo["locationAddressCityOrTown"]
            + ', ' + locationInfo["locationAddressState"]
            + ', ' + locationInfo["locationAddressZipCode"];
        requestBody["locationAddressDetail"] = locationInfo["locationAddressDetail"];
        requestBody["locationLatitude"] = locationInfo["locationLatitude"];
        requestBody["locationLongitude"] = locationInfo["locationLongitude"];
        /* business info */
        requestBody["businessRegistrationNumber"] = businessInfo["businessRegistrationNumber"];
        /* owner info */
        requestBody["branchGroupId"] = ownerInfo["branchGroupId"];
        requestBody["branchMasterId"] = ownerInfo["branchMasterId"];
        /* default info */
        requestBody["branchName"] = defaultInfo["branchName"];
        requestBody["branchDescription"] = defaultInfo["branchDescription"];

        let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
            + `/api/v1/branch`;
        let generatedBranchId = "";
        try {
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(_toUSAFormatter(requestBody)),
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            generatedBranchId = res.data.item['generated branch info']['branchId'];
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
            await setIsSaveLoading(false);
            return;
        }

        /** New: franchise registration shortcut */
        if (selectedFranchiseId === "none") {
            /* pass */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await setIsSaveLoading(false);
            await navigate("/branch");
        } else {
            /** register to the franchise */
            let newFetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}`
                + `/api/v1/franchise/${selectedFranchiseId}/branch`;
            let newRequestBody = {
                franchiseBranchBranchId : generatedBranchId
            };
            try {
                await UserService.updateToken(() => axios.post(
                    newFetchUrl,
                    JSON.stringify(newRequestBody),
                    {
                        headers: {
                            Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                            "Content-Type" : "application/json"
                        }
                    }
                ));
                toast.success(<ToastAlertView message={t("message.saved")} />);
                await setIsSaveLoading(false);
                await navigate("/branch");
            } catch (e) {
                console.log(e);
                toast.error(<ToastAlertView message={t("menu.branch.franchise.message.failed")} />);
                await setIsSaveLoading(false);
                await navigate("/branch");
                return;
            }
        }
    };

    /* -------------------------- */
    /* validators */
    const validateLocationPostalCode = () => {
        if ((locationInfo['locationPostalCode'] != null) && (locationInfo['locationPostalCode'] !== "")) {
            updateHelper(setLocationInfoIsValidMap, "locationPostalCode", true);
        } else {
            updateHelper(setLocationInfoIsValidMap, "locationPostalCode", false);
        }
    };

    // const validateLocationAddress = () => {
    //     if ((locationInfo['locationAddress'] != null) && (locationInfo['locationAddress'] !== "")) {
    //         updateHelper(setLocationInfoIsValidMap, "locationAddress", true);
    //     } else {
    //         updateHelper(setLocationInfoIsValidMap, "locationAddress", false);
    //     }
    // };

    const validateLocationAddressDetail = () => {
        if ((locationInfo['locationAddressDetail'] != null) && (locationInfo['locationAddressDetail'] !== "")) {
            updateHelper(setLocationInfoIsValidMap, "locationAddressDetail", true);
        } else {
            updateHelper(setLocationInfoIsValidMap, "locationAddressDetail", false);
        }
    };

    const validateBranchName = () => {
        if ((defaultInfo['branchName'] != null) && (defaultInfo['branchName'] !== "")) {
            if ((defaultInfo['branchName'].length > 3) && (defaultInfo['branchName'].length < 100)) {
                updateHelper(setDefaultInfoIsValidMap, "branchName", true);
            } else {
                updateHelper(setDefaultInfoIsValidMap, "branchName", false);
            }
        } else {
            updateHelper(setDefaultInfoIsValidMap, "branchName", false);
        }
    };

    const validateBranchDescription = () => {
        if ((defaultInfo['branchDescription'] != null) && (defaultInfo['branchDescription'] !== "")) {
            updateHelper(setDefaultInfoIsValidMap, "branchDescription", true);
        } else {
            updateHelper(setDefaultInfoIsValidMap, "branchDescription", false);
        }
    };

    // const validateBusinessRegistrationNumber = () => {
    //     let regex = "^\\d{3}-\\d{2}-\\d{5}$";
    //     if (DataCompareService.regexValidator(businessInfo['businessRegistrationNumber'], regex)) {
    //         updateHelper(setBusinessInfoIsValidMap, "businessRegistrationNumber", true);
    //     } else {
    //         updateHelper(setBusinessInfoIsValidMap, "businessRegistrationNumber", false);
    //     }
    // };

    /* -------------------------- */
    /* initializers */
    const initializeBusinessInfo = () => {
        setBusinessInfo(businessInfoInit);
        setBusinessInfoIsValidMap(businessInfoIsValidMapInit);
        setIsBusinessInfoValid(false);
        setNumberInputList(["", "", ""]);
    };

    /* -------------------------- */
    /* Effects */
    /* initialization */
    useEffect(() => {
        fetchGroupList();
    }, []);

    /* fetch franchise list whenever the selected group changes */
    useEffect(() => {
        fetchFranchiseListByGroupId();
    }, [ownerInfo['branchGroupId']]);


    /* fetch user list whenever the selected group changes */
    useEffect(() => {
        if (!((ownerInfo['branchGroupId'] == null) || (ownerInfo['branchGroupId'] === ""))) {
            fetchUserList(ownerInfo['branchGroupId']);
        }
    }, [ownerInfo['branchGroupId']]);

    /* initialize business info whenever the selected user changes */
    useEffect(() => {
        if (!((ownerInfo['branchMasterId'] == null) || (ownerInfo['branchMasterId'] === ""))) {
            /** for USA */
            // initializeBusinessInfo();
            setOwnerName(findNameByIdFromObjectList(userList, ownerInfo['branchMasterId']));
        }
    }, [ownerInfo['branchMasterId']]);

    /* craft business registration number from list */
    useEffect(() => {
        let newNumber = numberInputList[0] + "-" + numberInputList[1] + "-" + numberInputList[2];
        updateHelper(setBusinessInfo, "businessRegistrationNumber", newNumber);
    }, [numberInputList]);

    /* change message shown whenever "businessInfoIsValidMap['businessRegistrationNumber']" changes */
    useEffect(() => {
        /* bad form */
        if (!businessInfoIsValidMap['businessRegistrationNumber']) {
            setMessage(t("menu.branch.add.message.form"));
            setMessageColor("#757575");
        }
        /* good form */
        if (businessInfoIsValidMap['businessRegistrationNumber']) {
            setMessage(t("menu.branch.add.message.good"));
            setMessageColor("blue");
        }
    }, [businessInfoIsValidMap['businessRegistrationNumber']]);

    /* input tracking */
    // useEffect(() => {
    //     validateBusinessRegistrationNumber();
    // }, [businessInfo['businessRegistrationNumber']]);

    useEffect(() => {
        validateBranchName();
    }, [defaultInfo['branchName']]);

    useEffect(() => {
        validateBranchDescription();
    }, [defaultInfo['branchDescription']]);

    useEffect(() => {
        validateLocationPostalCode();
    }, [locationInfo['locationPostalCode']]);

    // useEffect(() => {
    //     validateLocationAddress();
    // }, [locationInfo['locationAddress']]);

    useEffect(() => {
        validateLocationAddressDetail();
    }, [locationInfo['locationAddressDetail']]);

    /* valid map tracking */
    useEffect(() => {
        setIsBusinessInfoValid(DataCompareService.checkIsAllTrue(businessInfoIsValidMap));
    }, [businessInfoIsValidMap]);

    useEffect(() => {
        setIsLocationInfoValid(DataCompareService.checkIsAllTrue(locationInfoIsValidMap));
    }, [locationInfoIsValidMap]);

    useEffect(() => {
        setIsDefaultInfoValid(DataCompareService.checkIsAllTrue(defaultInfoIsValidMap));
    }, [defaultInfoIsValidMap]);

    useEffect(() => {
        setIsOwnerInfoValid(DataCompareService.checkIsAllTrue(ownerInfoIsValidMap));
    }, [ownerInfoIsValidMap]);

    return (
        <RenderOnRole type={"POST"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={'/branch'}
                />
                <PageTitleWithDepth
                    titles={[t("menu.branch.title"), t("menu.branch.add.title")]}
                    depth={2}
                />
            </div>

            {/* search & buttons */}
            <div className={"contentContainer"}>
                {/* owner information */}
                <div className={"contentInner"}>
                    <Container fluid>
                        <Row>
                            <Col
                                style={{
                                    padding: '0px'
                                }}
                            >
                                {/* select group */}
                                <TitleAndSelectBox
                                    title={t("menu.branch.add.branchGroupId")}
                                    innerValue={ownerInfo['branchGroupId']}
                                    valueList={groupList}
                                    valueSelector={"groupId"}
                                    viewSelector={"groupName"}
                                    onChange={(value) => updateHelper(setOwnerInfo, "branchGroupId", value)}
                                />
                            </Col>
                            <Col
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                {/* select franchise */}
                                <TitleAndSearchSelectBox
                                    title={t("menu.branch.franchise.title")}
                                    innerValue={selectedFranchiseId}
                                    valueList={franchiseList}
                                    valueSelector={"franchiseId"}
                                    viewSelector={"franchiseName"}
                                    onChange={async (value) => await setSelectedFranchiseId(value)}
                                    placeHolderStr={t("menu.franchise.selectFranchise")}
                                />
                            </Col>
                            <Col
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                {/* select user */}
                                <TitleAndSelectBox
                                    title={t("menu.branch.add.branchMasterId")}
                                    innerValue={ownerInfo['branchMasterId']}
                                    valueList={userList}
                                    valueSelector={"userId"}
                                    viewSelector={"userName"}
                                    onChange={(value) => updateHelper(setOwnerInfo, "branchMasterId", value)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* business information */}
                {/*<div*/}
                {/*    className={"contentInner"}*/}
                {/*    style={{*/}
                {/*        marginTop: '10px'*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Container fluid>*/}
                {/*        <Row>*/}
                {/*            <Col*/}
                {/*                md={"2"}*/}
                {/*                style={{*/}
                {/*                    padding: '0px'*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <TitleAndInputBox*/}
                {/*                    title={t("menu.branch.add.businessRegistrationNumber")}*/}
                {/*                    value={numberInputList[0]}*/}
                {/*                    onChange={(value) => updateNumberList(0, value)}*/}
                {/*                    isForEdit={true}*/}
                {/*                    type={"text"}*/}
                {/*                    isValid={businessInfoIsValidMap["businessRegistrationNumber"]}*/}
                {/*                    isEnabled={true}*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*            <Col*/}
                {/*                md={"1"}*/}
                {/*                style={{*/}
                {/*                    padding: '0px',*/}
                {/*                    paddingLeft: '5px'*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <TitleAndInputBox*/}
                {/*                    title={"　"}*/}
                {/*                    value={numberInputList[1]}*/}
                {/*                    onChange={(value) => updateNumberList(1, value)}*/}
                {/*                    isForEdit={true}*/}
                {/*                    type={"text"}*/}
                {/*                    isValid={businessInfoIsValidMap["businessRegistrationNumber"]}*/}
                {/*                    isEnabled={true}*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*            <Col*/}
                {/*                md={"3"}*/}
                {/*                style={{*/}
                {/*                    padding: '0px',*/}
                {/*                    paddingLeft: '5px'*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <TitleAndInputBox*/}
                {/*                    title={"　"}*/}
                {/*                    value={numberInputList[2]}*/}
                {/*                    onChange={(value) => updateNumberList(2, value)}*/}
                {/*                    isForEdit={true}*/}
                {/*                    type={"text"}*/}
                {/*                    isValid={businessInfoIsValidMap["businessRegistrationNumber"]}*/}
                {/*                    isEnabled={true}*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*            <Col*/}
                {/*                md={"6"}*/}
                {/*                style={{*/}
                {/*                    padding: '0px',*/}
                {/*                    paddingLeft: '10px'*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                /!* message *!/*/}
                {/*                <div*/}
                {/*                    style={{*/}
                {/*                        width: '100%',*/}
                {/*                        height: '100%',*/}
                {/*                        display: 'flex',*/}
                {/*                        alignItems: 'flex-end',*/}
                {/*                        justifyContent: 'left'*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <span*/}
                {/*                        style={{*/}
                {/*                            fontSize: '14px',*/}
                {/*                            color: messageColor*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        {message}*/}
                {/*                    </span>*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </Container>*/}
                {/*</div>*/}
                {/* default info */}
                <div
                    className={"contentInner"}
                    style={{
                        width: '100%',
                        marginTop: '10px'
                    }}
                >
                    <Container fluid>
                        <Row>
                            <TitleAndInputBox
                                title={t("menu.branch.add.branchName")}
                                value={defaultInfo['branchName']}
                                onChange={(value) => updateHelper(setDefaultInfo, "branchName", value)}
                                isForEdit={true}
                                type={"text"}
                                isValid={defaultInfoIsValidMap["branchName"]}
                                isEnabled={true}
                            />
                        </Row>
                        <Row
                            style={{
                                marginTop: '5px'
                            }}
                        >
                            <TitleAndTextArea
                                title={t("menu.branch.add.branchDescription")}
                                value={defaultInfo['branchDescription']}
                                onChange={(value) => updateHelper(setDefaultInfo, "branchDescription", value)}
                                isForEdit={true}
                                isValid={defaultInfoIsValidMap["branchDescription"]}
                                height={"200"}
                            />
                        </Row>
                    </Container>
                </div>
                {/* location info */}
                <div
                    className={"contentInner"}
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <Container fluid>
                        {/*<Row>*/}
                        {/*    /!* search button *!/*/}
                        {/*    <AddressSearchButton*/}
                        {/*        onClick={() => openPostalCodeSearchPopup({onComplete : onSuccessPostalCodeSearch})}*/}
                        {/*    >*/}
                        {/*        {t("menu.order.add.searchAddr")}*/}
                        {/*    </AddressSearchButton>*/}
                        {/*</Row>*/}
                        <Row
                            style={{
                                marginTop: '5px'
                            }}
                        >
                            <Col
                                md={"2"}
                                style={{
                                    padding: '0px'
                                }}
                            >
                                <TitleAndInputBox
                                    title={t("menu.branch.detail.us.locationNumber")}
                                    value={locationInfo['locationAddressNumber']}
                                    onChange={(value) => handleLocationNumberInput(value)}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={locationInfoIsValidMap["locationAddressNumber"]}
                                    isEnabled={true}
                                    placeHolder={"ex) 300"}
                                />
                            </Col>
                            <Col
                                md={"3"}
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                <TitleAndInputBox
                                    title={t("menu.branch.detail.us.locationStreet")}
                                    value={locationInfo['locationAddressStreet']}
                                    onChange={(value) => handleLocationStreetInput(value)}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={locationInfoIsValidMap["locationAddressStreet"]}
                                    isEnabled={true}
                                    placeHolder={"ex) Washington St."}
                                />
                            </Col>
                            <Col
                                md={"3"}
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                <TitleAndInputBox
                                    title={t("menu.branch.detail.us.locationCityOrTown")}
                                    value={locationInfo['locationAddressCityOrTown']}
                                    onChange={(value) => handleLocationCityInput(value)}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={locationInfoIsValidMap["locationAddressCityOrTown"]}
                                    isEnabled={true}
                                    placeHolder={"ex) Newton"}
                                />
                            </Col>
                            <Col
                                md={"2"}
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                <TitleAndInputBox
                                    title={t("menu.branch.detail.us.locationState")}
                                    value={locationInfo['locationAddressState']}
                                    onChange={(value) => handleLocationStateInput(value)}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={locationInfoIsValidMap["locationAddressState"]}
                                    isEnabled={true}
                                    placeHolder={"ex) MA(Massachusetts)"}
                                />
                            </Col>
                            <Col
                                md={"2"}
                                style={{
                                    padding: '0px',
                                    paddingLeft: '10px'
                                }}
                            >
                                <TitleAndInputBox
                                    title={t("menu.branch.detail.us.locationZipCode")}
                                    value={locationInfo['locationAddressZipCode']}
                                    onChange={(value) => handleLocationZipCodeInput(value)}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={locationInfoIsValidMap["locationAddressZipCode"]}
                                    isEnabled={true}
                                    placeHolder={"ex) 02458"}
                                />
                            </Col>
                            {/*<Col*/}
                            {/*    md={"3"}*/}
                            {/*    style={{*/}
                            {/*        padding: '0px'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <TitleAndInputBox*/}
                            {/*        title={t("menu.branch.add.locationPostalCode")}*/}
                            {/*        value={locationInfo['locationPostalCode']}*/}
                            {/*        onChange={(value) => updateHelper(setLocationInfo, "locationPostalCode", value)}*/}
                            {/*        isForEdit={true}*/}
                            {/*        type={"text"}*/}
                            {/*        isValid={locationInfoIsValidMap["locationPostalCode"]}*/}
                            {/*        isEnabled={true}*/}
                            {/*    />*/}
                            {/*</Col>*/}
                            {/*<Col*/}
                            {/*    md={"4"}*/}
                            {/*    style={{*/}
                            {/*        padding: '0px',*/}
                            {/*        paddingLeft: '10px'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <TitleAndInputBox*/}
                            {/*        title={t("menu.branch.add.locationAddress")}*/}
                            {/*        value={locationInfo['locationAddress']}*/}
                            {/*        onChange={(value) => updateHelper(setLocationInfo, "locationAddress", value)}*/}
                            {/*        isForEdit={true}*/}
                            {/*        type={"text"}*/}
                            {/*        isValid={locationInfoIsValidMap["locationAddress"]}*/}
                            {/*        isEnabled={true}*/}
                            {/*    />*/}
                            {/*</Col>*/}
                            {/*<Col*/}
                            {/*    md={"5"}*/}
                            {/*    style={{*/}
                            {/*        padding: '0px',*/}
                            {/*        paddingLeft: '10px'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <TitleAndInputBox*/}
                            {/*        title={t("menu.branch.add.locationAddressDetail")}*/}
                            {/*        value={locationInfo['locationAddressDetail']}*/}
                            {/*        onChange={(value) => updateHelper(setLocationInfo, "locationAddressDetail", value)}*/}
                            {/*        isForEdit={true}*/}
                            {/*        type={"text"}*/}
                            {/*        isValid={locationInfoIsValidMap["locationAddressDetail"]}*/}
                            {/*        isEnabled={true}*/}
                            {/*    />*/}
                            {/*</Col>*/}
                        </Row>
                        <Row
                            style={{
                                marginTop: '40px',
                                marginBottom: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'right'
                                }}
                            >
                                <div
                                    style={{ marginRight: '10px' }}
                                >
                                    <Link
                                        id={"edit-btn"}
                                        to={`/branch`}
                                    >
                                        {t("button.cancel")}
                                    </Link>
                                </div>
                                <div>
                                    {
                                        isBusinessInfoValid && isDefaultInfoValid && isLocationInfoValid && isOwnerInfoValid
                                            ?
                                            <Link
                                                id={"save-btn"}
                                                onClick={() => postBranch()}
                                            >
                                                {t("button.save")}
                                            </Link>
                                            :
                                            <Link
                                                id={"save-btn-disabled"}
                                            >
                                                {t("button.save")}
                                            </Link>
                                    }
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default BranchAddUSA;