import React, {useState} from 'react';
import styled from "styled-components";
import {Trans, useTranslation} from "react-i18next";
import DateUtils from "../../schedule/utils/DateUtils";
import useInterval from "../../../customHooks/useInterval";

const WelcomeMessage = styled.span`
  font-size: 14px;
  color: #757575;
`;

const WelcomeMessageBold = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const GroupMessage = styled.span`
  margin-left: auto;
  font-size: 14px;
  color: #757575;
`;

const DateMessage = styled.span`
  margin-left: 15px;
  color: #111111;
  font-size: 14px;
`;


const TopInfoBar = () => {

    const { t, i18n } = useTranslation();

    /* states */
    const [currentTime, setCurrentTime] = useState(new Date());

    /* update */
    /* TIMER : update date */
    /* every 30 sec */
    useInterval(() => {
        setCurrentTime(new Date());
    }, 30000);

    return (
        <div
            className={"contentInner"}
            style={{
                width: '100%',
                height: '100%',
                display: 'inline-flex',
                flex: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }}
        >
            {/* welcome message */}
            <Trans
                i18nKey={"menu.dashboard.topInfoBar.welcomeMessage"}
                components={[<WelcomeMessage></WelcomeMessage>, <WelcomeMessageBold></WelcomeMessageBold>]}
                values={{ "name" : window.sessionStorage.getItem("ims_userName"), "space" : "‎ " }}
            />
            {/* group */}
            <GroupMessage>
                {window.sessionStorage.getItem("ims_groupName")}
            </GroupMessage>
            {/* date */}
            <DateMessage>
                {DateUtils.craftLocaleString(DateUtils.formatDate(currentTime, "-"), i18n.language)}
            </DateMessage>
        </div>
    );
};

export default TopInfoBar;