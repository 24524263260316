import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import UserService from "../../../../../../services/UserService";
import axios from "axios";
import {Col, Container, Row} from "react-bootstrap"
import TitleAndSelectBox from "../../../../../common/dataView/TitleAndSelectBox";
import OptionInfoItem from "./inner/OptionInfoItem";
import LanguageSelectBox from "../../../../../common/lang/LanguageSelectBox";

const OptionInfoView = ({ optionInfoList, refreshOptionList }) => {

    const { t, i18n } = useTranslation();
    const { franchiseId, menuId } = useParams();
    const navigate = useNavigate();

    /* states */
    const [optionCategoryList, setOptionCategoryList] = useState([]);
    const [filteredOptionList, setFilteredOptionList] = useState([]);
    const [selectedOptionCategoryId, setSelectedOptionCategoryId] = useState("");

    /* state for language */
    const targetFieldsString = `"${t("menu.franchiseMenu.menuInfo.optionInfo.detail.alias")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* fetch option category list */
    const fetchOptionCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/franchise-option-category/franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newOptionCategoryList = res.data.item['franchiseMenuOptionCategoryList'];
            setOptionCategoryList(newOptionCategoryList);
        } catch (e) {
            console.log(e);
            setOptionCategoryList([]);
            if (e.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    /* initialization */
    const initialization = async () => {
        await fetchOptionCategoryList();
        /* filter out category list */
        setOptionCategoryList(prevList => {
            let newList = [...prevList];
            /* extract ids */
            const categoryIds = [];
            optionInfoList.map(optionInfo => {
                categoryIds.push(optionInfo['franchiseMenuOptionCategoryId']);
            });
            newList = newList.filter(categoryInfo => {
                return categoryIds.includes(categoryInfo['franchiseMenuOptionCategoryId']);
            });
            if ((newList.length > 0) && (selectedOptionCategoryId === "")) {
                setSelectedOptionCategoryId(newList[0]['franchiseMenuOptionCategoryId']);
            }
            return newList;
        });
    };

    /* effects */
    useEffect(() => {
        initialization();
    }, [optionInfoList, menuId]);

    useEffect(() => {
        setFilteredOptionList(prevList => {
            /* filtering processing */
            let newFilteredList = optionInfoList.filter(optionInfo => {
                return (optionInfo['franchiseMenuOptionCategoryId'] === selectedOptionCategoryId);
            });
            return newFilteredList;
        });
    }, [selectedOptionCategoryId, optionInfoList]);

    return (
        <Container fluid>
            {
                (optionInfoList.length > 0) &&
                <Row>
                    <Col
                        style={{
                            padding: '0px'
                        }}
                    >
                        {/* language selection */}
                        <LanguageSelectBox
                            title={t("language.selectBox.title.menuOption")}
                            languageMetadataList={languageMetadataList}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}
                            setIsHighlightOn={setIsHighlightOn}
                            targetFieldString={targetFieldsString}
                        />
                    </Col>
                    <Col
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        {/* category selection box */}
                        <TitleAndSelectBox
                            title={t("menu.franchiseMenu.menuInfo.optionInfo.detail.category")}
                            innerValue={selectedOptionCategoryId}
                            valueList={optionCategoryList}
                            valueSelector={"franchiseMenuOptionCategoryId"}
                            viewSelector={t("language.dataKey.menu.option.category.name")}
                            onChange={(value) => setSelectedOptionCategoryId(value)}
                        />
                    </Col>
                </Row>
            }
            {
                (filteredOptionList.length === 0) ?
                    <Row>
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                fontSize: '14px',
                                color: '#757575',
                                paddingTop: '50px'
                            }}
                        >
                            {t("menu.franchiseMenu.menuInfo.optionInfo.message.noOption")}
                        </div>
                    </Row>
                    :
                    <Row
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '580px',
                                border: 'none',
                                overflow: 'auto',
                                padding: '5px'
                            }}
                        >
                            {
                                filteredOptionList.map((optionInfo, index) => {
                                    return (
                                        <OptionInfoItem
                                            key={optionInfo["franchiseMenuOptionId"] + filteredOptionList.length + optionInfo['viewIndex']}
                                            optionInfo={optionInfo}
                                            refreshOptionList={refreshOptionList}
                                            length={filteredOptionList.length}
                                            index={index}
                                            currentLang={currentLang}
                                            isHighlightOn={isHighlightOn}
                                        />
                                    );
                                })
                            }
                        </div>
                    </Row>
            }
        </Container>
    );
};

export default OptionInfoView;