import React from 'react';
import {useTranslation} from "react-i18next";

const NoFranchiseSelected = () => {
    const { t } = useTranslation();

    return (
        <div className={"contentInner"}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
            <span
                style={{
                    fontSize: '14px',
                    color: '#757575'
                }}
            >
                {t("menu.franchise.selectFranchise")}
            </span>
            </div>
        </div>
    );
};

export default NoFranchiseSelected;