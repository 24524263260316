import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import UserService from "../../../../services/UserService";
import axios from "axios";
import _ from "lodash";
import DataUtils from "../../common/utils/DataUtils";
import DataCompareService from "../../../../services/DataCompareService";
import {Col, Container, Row} from "react-bootstrap";
import Modal from "react-modal";
import ModalCloseButton from "../../../common/modal/ModalCloseButton";
import TitleAndSwitchBox from "../../../common/dataView/TitleAndSwitchBox";
import TitleAndSelectBox from "../../../common/dataView/TitleAndSelectBox";
import TitleAndInputBox from "../../../common/dataView/TitleAndInputBox";
import ExtraInformationView from "../../common/extrainfo/ExtraInformationView";
import ButtonWithSpinner from "../../common/buttons/ButtonWithSpinner";
import OrangeButton from "../../common/buttons/OrangeButton";
import LanguageInput from "../../../../language/component/LanguageInput";
import LanguageSelectBox from "../../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '650px',
        height: '630px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    franchiseMenuOptionPrice : 0.0,
    franchiseMenuOptionAlias : "",
    franchiseMenuOptionAlias1 : null,
    franchiseMenuOptionAlias2 : null,
    franchiseMenuOptionAlias3 : null,
    franchiseMenuOptionAlias4 : null,
    franchiseMenuOptionAlias5 : null,
    extraInformationList : [],
    franchiseMenuOptionCategoryId : ""
};

const isValidMapInit = {
    franchiseMenuOptionPrice : true,
    franchiseMenuOptionAlias : false,
    franchiseMenuOptionAlias1 : true,
    franchiseMenuOptionAlias2 : true,
    franchiseMenuOptionAlias3 : true,
    franchiseMenuOptionAlias4 : true,
    franchiseMenuOptionAlias5 : true,
    extraInformationList : true,
    franchiseMenuOptionCategoryId : false
};

const OptionAddModal = ({ isOpen, setIsOpen, onRefresh }) => {

    const { t, i18n } = useTranslation();
    const { franchiseId, menuId } = useParams();

    /* states */
    const [optionCategoryList, setOptionCategoryList] = useState([]);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);

    /* state for language */
    const targetFieldsString = `"${t("menu.franchiseMenu.menuInfo.optionInfo.detail.alias")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* loading */
    const [isLoading, setIsLoading] = useState(false);

    /* fetch option category list */
    const fetchOptionCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/franchise-option-category/franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newOptionCategoryList = res.data.item['franchiseMenuOptionCategoryList'];
            if (newOptionCategoryList.length > 0) {
                handleOptionCategoryIdChange(newOptionCategoryList[0]['franchiseMenuOptionCategoryId']);
            }
            setOptionCategoryList(newOptionCategoryList);
        } catch (e) {
            console.log(e);
            setOptionCategoryList([]);
        }
    };

    /* post a new option */
    const postOption = async () => {
        await setIsLoading(true);
        /* extra information setup */
        let newRequestBody = _.cloneDeep(requestBody);
        if (newRequestBody['extraInformationList'].length > 0) {
            newRequestBody['extraInformation'] = DataUtils.formatObjArrayToObj(newRequestBody['extraInformationList']);
            delete newRequestBody['extraInformationList'];
        } else {
            delete newRequestBody['extraInformationList'];
        }
        try {
            /* axios call */
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/`
                + `franchise-menu/franchise/${franchiseId}/menu/${menuId}/option`;
            await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            /* success */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            onRefresh();
            setIsLoading(false);
            setIsOpen(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    };

    /* initialization */
    const initialization = async () => {
        await setOptionCategoryList([]);
        await setRequestBody(requestBodyInit);
        await setIsValidMap(isValidMapInit);
        await setIsValid(false);
        fetchOptionCategoryList();
    };

    /* input handlers */
    const handleOptionPriceInput = (value) => {
        DataUtils.updateHelper(setRequestBody, "franchiseMenuOptionPrice", parseFloat(value));
        DataUtils.updateHelper(setIsValidMap, "franchiseMenuOptionPrice", DataUtils.isValidNumStr(value));
    };

    const handleOptionCategoryIdChange = (value) => {
        DataUtils.updateHelper(setRequestBody, "franchiseMenuOptionCategoryId", value);
        DataUtils.updateHelper(setIsValidMap, "franchiseMenuOptionCategoryId", !DataUtils.isEmptyStr(value));
    };

    const handleExtraInfoChange = (newObjArray) => {
        DataUtils.updateHelper(setRequestBody, "extraInformationList", newObjArray);
    };

    /* effects */
    useEffect(() => {
        initialization();
    }, [isOpen]);

    /* track isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                if (!isLoading) {
                    setIsOpen(false);
                }
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchiseMenu.optionAdd.title")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    {/* language selection */}
                    <LanguageSelectBox
                        title={t("language.selectBox.title.menuOption")}
                        languageMetadataList={languageMetadataList}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        setIsHighlightOn={setIsHighlightOn}
                        targetFieldString={targetFieldsString}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* category */}
                    <TitleAndSelectBox
                        title={t("menu.franchiseMenu.optionAdd.detail.category")}
                        innerValue={requestBody["franchiseMenuOptionCategoryId"]}
                        valueList={optionCategoryList}
                        valueSelector={"franchiseMenuOptionCategoryId"}
                        viewSelector={t("language.dataKey.menu.option.category.name")}
                        onChange={handleOptionCategoryIdChange}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <LanguageInput
                        type={"text"}
                        title={t("menu.franchiseMenu.menuInfo.optionInfo.detail.alias") + ` (${t("language.callName." + currentLang)})`}
                        dataKeyTranslationPath={"language.dataKey.menu.option.name"}
                        valueMap={requestBody}
                        updateValueMap={(key, value) => DataUtils.updateHelper(setRequestBody, key, value)}
                        isValidMap={isValidMap}
                        updateIsValidMap={(key, value) => DataUtils.updateHelper(setIsValidMap, key, value)}
                        isForEdit={true}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndInputBox
                        title={t("menu.franchiseMenu.optionAdd.detail.optionPrice")}
                        value={requestBody["franchiseMenuOptionPrice"]}
                        onChange={handleOptionPriceInput}
                        isForEdit={true}
                        type={"number"}
                        isValid={isValidMap["franchiseMenuOptionPrice"]}
                        isEnabled={true}
                    />
                </Row>
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <ExtraInformationView
                        dataObjList={requestBody['extraInformationList']}
                        setDataObjList={handleExtraInfoChange}
                        setIsValidMap={setIsValidMap}
                        objKey={"extraInformationList"}
                        title={t("menu.franchiseMenu.optionAdd.detail.extraInfo.title")}
                        height={"140"}
                        isForEdit={true}
                    />
                </Row>
                {/* save button */}
                <Row
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {
                        isLoading ?
                            <ButtonWithSpinner
                                buttonColor={"#fc7242"}
                            />
                            :
                            <OrangeButton
                                onClickCallback={postOption}
                                isEnabled={isValid}
                                label={t("button.save")}
                            />
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default OptionAddModal;