import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import RenderOnRole from "../../auth/RenderOnRole";
import {Col, Container, Row} from "react-bootstrap";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import DataParseService from "../../services/DataParseService";
import TitleAndTextArea from "../common/dataView/TitleAndTextArea";
import UserService from '../../services/UserService';
import AddManager from "./manager/AddManager";
import uuid from "react-uuid";
import styled from "styled-components";
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import BranchBoothViewModal from "./booth/BranchBoothViewModal";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";
import ImsSystemRole from "../../auth/roles/ImsSystemRole";
import AuthService from "../../services/AuthService";
import _ from "lodash";

const EnabledCustomer = styled.div`
  width: 100%;
  height: 40px;
  padding: 5px 20px;
  border: 1px solid #ebebeb;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: all ease-in-out 0.2s;
  color: #757575;
  background-color: #F8F8F8;
  display: flex;
  flex-direction: row;
`;

const EnabledButton = styled.button`
  border: 1px solid red;
  border-radius: 5px;
  background-color: white;
  color: red;
  font-size: 14px;
  padding: 2px 10px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: red;
    color: white;
  }
  
  &:focus {
    outline: none;
  }
`;

const BoothViewButton = styled.button`
  border: 1px solid #fc7242;
  border-radius: 10px;
  background-color: white;
  color: #fc7242;
  font-size: 14px;
  padding: 5px 15px;
  transition: all ease-in-out 0.2s;
  
  &:hover {
    background-color: #FFF1EC;
  }

  &:focus {
    outline: none;
  }
`;

const BoothViewButtonDisabled = styled.button`
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  background-color: white;
  color: #c2c2c2;
  font-size: 14px;
  padding: 5px 15px;

  &:focus {
    outline: none;
  }
`;

const BranchDetailUSA = () => {

    const { t } = useTranslation();
    const { branchId } = useParams();
    const navigate = useNavigate();

    /* branch information */
    const [branchInfo, setBranchInfo] = useState({});
    /* manager modal */
    const [isManagerModalOpen, setIsManagerModalOpen] = useState(false);
    /* booth modal */
    const [isBoothModalOpen, setIsBoothModalOpen] = useState(false);

    /* fetch branch info */
    const fetchBranchDetail = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/branch/${branchId}`;
            const axiosCall = () => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            const res = await UserService.updateToken(axiosCall);
            /** for the USA, format the given data */
            let newBranchInfo = _.cloneDeep(res.data.item.branchInfo);
            let splitAddressInfo = newBranchInfo['locationAddress'].split(", ");
            newBranchInfo['locationAddressNumber'] = splitAddressInfo[0] ? splitAddressInfo[0] : "";
            newBranchInfo['locationAddressStreet'] = splitAddressInfo[1] ? splitAddressInfo[1] : "";
            newBranchInfo['locationAddressCityOrTown'] = splitAddressInfo[2] ? splitAddressInfo[2] : "";
            newBranchInfo['locationAddressState'] = splitAddressInfo[3] ? splitAddressInfo[3] : "";
            newBranchInfo['locationAddressZipCode'] = splitAddressInfo[4] ? splitAddressInfo[4] : "";
            setBranchInfo(newBranchInfo);
            /** ---------------------------------- */
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    const deleteManager = async (customerId) => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/branch/`
                + `${branchId}/`
                + `manager/${customerId}`;
            const axiosCall = () => axios.delete(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            toast.success(<ToastAlertView message={t("message.deleted")} />);
            fetchBranchDetail();
        } catch (e) {
            console.error(e);
            toast.error(<ToastAlertView message={t("message.saveFailed")} />);
        }
    }

    const renderManagerList = (customer, index) => {
        return (
            <EnabledCustomer
                key={uuid()}
            >
                <div>
                        <span
                            style={{
                                fontWeight: '500'
                            }}
                        >
                            {customer['userName']}
                        </span>
                    <span
                        style={{
                            marginLeft: '40px',
                            color: '#c2c2c2'
                        }}
                    >
                            {customer['userId']}
                        </span>
                </div>
                <div
                    style={{
                        marginLeft: 'auto'
                    }}
                >
                    <RenderOnRole type={"DELETE"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
                        <EnabledButton
                            onClick={() => deleteManager(customer['userId'])}
                        >
                            {t("button.delete")}
                        </EnabledButton>
                    </RenderOnRole>
                </div>
            </EnabledCustomer>
        );
    };

    /* effects */
    useEffect(() => {
        fetchBranchDetail();
    }, []);

    return (
        <RenderOnRole type={"GET"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false}>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={true}
                    link={"/branch"}
                />
                <PageTitleWithDepth
                    titles={[t("menu.branch.title"), t("menu.branch.detail.title")]}
                    depth={2}
                />
            </div>
            <div className={"contentContainer"}>
                <div className={"contentInner"} >
                    <div
                        style={{
                            width: '100%',
                            height: '700px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '650px',
                                display: 'flex',
                                overflow: 'auto',
                                padding: '10px 0px 20px 0px'
                            }}
                        >
                            <Container fluid>
                                <Row>
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {/* menu config */}
                                        <BoothViewButton
                                            style={{
                                                marginRight: '10px'
                                            }}
                                            onClick={() => navigate(`menu-diff`)}
                                        >
                                            {t("menu.branch.detail.menu.title")}
                                        </BoothViewButton>
                                        {/* booth information */}
                                        {
                                            AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "GET") ?
                                                <BoothViewButton
                                                    onClick={() => setIsBoothModalOpen(true)}
                                                >
                                                    {t("menu.branch.detail.booth.title")}
                                                </BoothViewButton>
                                                :
                                                <BoothViewButtonDisabled>
                                                    {t("menu.branch.detail.booth.title")}
                                                </BoothViewButtonDisabled>
                                        }
                                    </div>
                                    {/* modal */}
                                    {
                                        AuthService.hasAuthByRoleNameAndType(ImsSystemRole.BOOTH_MANAGEMENT, "GET") ?
                                            <BranchBoothViewModal
                                                isOpen={isBoothModalOpen}
                                                setIsOpen={setIsBoothModalOpen}
                                                data={branchInfo['branchBoothList']}
                                            />
                                            :
                                            <></>
                                    }
                                </Row>
                                <Row>
                                    <Col
                                        style={{
                                            padding: '0px'
                                        }}
                                        md={"8"}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.name")}
                                            value={branchInfo.branchName}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            onChange={() => {}}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            padding: '0px',
                                            paddingLeft: '10px'
                                        }}
                                        md={"4"}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.customer")}
                                            value={branchInfo.branchMasterName}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            onChange={() => {}}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <Col
                                        style={{
                                            padding: '0px'
                                        }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.group")}
                                            value={branchInfo.branchGroupName}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            onChange={() => {}}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            padding: '0px',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.branch.franchise.title")}
                                            value={branchInfo['franchiseInformation'] ? branchInfo['franchiseInformation']['franchiseName'] : t("menu.branch.franchise.search.none")}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            onChange={() => {}}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            padding: '0px',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.registerDate")}
                                            value={DataParseService.dateTimeToDate(branchInfo.registerDate)}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            onChange={() => {}}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <Col
                                        md={"2"}
                                        style={{
                                            padding: '0px'
                                        }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.branch.detail.us.locationNumber")}
                                            value={branchInfo['locationAddressNumber']}
                                            onChange={() => {}}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            isEnabled={true}
                                            placeHolder={"ex) 300"}
                                        />
                                    </Col>
                                    <Col
                                        md={"3"}
                                        style={{
                                            padding: '0px',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.branch.detail.us.locationStreet")}
                                            value={branchInfo['locationAddressStreet']}
                                            onChange={() => {}}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            isEnabled={true}
                                        />
                                    </Col>
                                    <Col
                                        md={"3"}
                                        style={{
                                            padding: '0px',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.branch.detail.us.locationCityOrTown")}
                                            value={branchInfo['locationAddressCityOrTown']}
                                            onChange={() => {}}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            isEnabled={true}
                                        />
                                    </Col>
                                    <Col
                                        md={"2"}
                                        style={{
                                            padding: '0px',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.branch.detail.us.locationState")}
                                            value={branchInfo['locationAddressState']}
                                            onChange={() => {}}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            isEnabled={true}
                                        />
                                    </Col>
                                    <Col
                                        md={"2"}
                                        style={{
                                            padding: '0px',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <TitleAndInputBox
                                            title={t("menu.branch.detail.us.locationZipCode")}
                                            value={branchInfo['locationAddressZipCode']}
                                            onChange={() => {}}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            isEnabled={true}
                                        />
                                    </Col>
                                    {/*<Col*/}
                                    {/*    style={{*/}
                                    {/*        padding: '0px'*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <TitleAndInputBox*/}
                                    {/*        title={t("menu.order.detail.content.shipInfo.postalCode")}*/}
                                    {/*        value={branchInfo.locationPostalCode}*/}
                                    {/*        isForEdit={false}*/}
                                    {/*        type={"text"}*/}
                                    {/*        isValid={true}*/}
                                    {/*        onChange={() => {}}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    {/*<Col*/}
                                    {/*    style={{*/}
                                    {/*        padding: '0px',*/}
                                    {/*        paddingLeft: '10px'*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <TitleAndInputBox*/}
                                    {/*        title={t("menu.order.detail.content.shipInfo.address")}*/}
                                    {/*        value={branchInfo.locationAddress}*/}
                                    {/*        isForEdit={false}*/}
                                    {/*        type={"text"}*/}
                                    {/*        isValid={true}*/}
                                    {/*        onChange={() => {}}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    {/*<Col*/}
                                    {/*    style={{*/}
                                    {/*        padding: '0px',*/}
                                    {/*        paddingLeft: '10px'*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <TitleAndInputBox*/}
                                    {/*        title={t("menu.order.detail.content.shipInfo.detail")}*/}
                                    {/*        value={branchInfo.locationAddressDetail}*/}
                                    {/*        isForEdit={false}*/}
                                    {/*        type={"text"}*/}
                                    {/*        isValid={true}*/}
                                    {/*        onChange={() => {}}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <TitleAndTextArea
                                        title={t("common.description")}
                                        value={branchInfo.branchDescription}
                                        onChange={() => {}}
                                        isForEdit={false}
                                        isValid={true}
                                        height={"100"}
                                    />
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                <span
                                    style={{
                                        color: '#8f8f8f',
                                        marginLeft: '10px',
                                        marginBottom: '5px',
                                        fontSize: '14px'
                                    }}
                                >
                                    {t("menu.branch.detail.manager.title")}
                                </span>
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '170px',
                                            padding: '5px',
                                            border: '1px solid #ebebeb',
                                            borderRadius: '5px',
                                            overflow: 'auto'
                                        }}
                                    >
                                        <RenderOnRole type={"POST"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                            <div
                                                style={{
                                                    padding: '0px',
                                                    width: '100%',
                                                    height: '20px',
                                                    textAlign: 'right'
                                                }}
                                            >
                                                <button
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        background: 'none',
                                                        textDecoration: 'underline',
                                                        color: '#757575',
                                                        fontSize: '14px'
                                                    }}
                                                    onClick={() => setIsManagerModalOpen(true)}
                                                >
                                                    {t("menu.branch.detail.manager.addManager")}
                                                </button>
                                            </div>
                                        </RenderOnRole>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '130px',
                                                marginTop: '5px',
                                                padding: '5px',
                                                overflowX: 'auto'
                                            }}
                                        >
                                            {
                                                branchInfo.branchManagerList &&
                                                (branchInfo['branchManagerList'].length === 0) ?
                                                    <div
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: '#787878',
                                                                fontSize: '14px'
                                                            }}
                                                        >
                                                            {t("menu.branch.detail.manager.noManagerMessage")}
                                                        </span>
                                                    </div>
                                                    :
                                                    branchInfo.branchManagerList &&
                                                    branchInfo.branchManagerList.map((customer, index) => {
                                                        return renderManagerList(customer, index);
                                                    })
                                            }
                                        </div>
                                        {
                                            branchInfo.branchManagerList &&
                                            <AddManager
                                                isOpen={isManagerModalOpen}
                                                setIsOpen={setIsManagerModalOpen}
                                                onRefresh={fetchBranchDetail}
                                                managerIdList={branchInfo.branchManagerList.map(customer => customer.userId)}
                                                branchGroupId={branchInfo.branchGroupId}
                                                branchId={branchInfo.branchId}
                                            />
                                        }
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        {/* bottom btn */}
                        <div
                            style={{
                                width: '100%',
                                height: '50px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'right'
                            }}
                        >
                            <div
                                style={{ marginRight: '10px' }}
                            >
                                <RenderOnRole type={"PUT"} roleName={ImsSystemRole.BRANCH_MANAGEMENT} allowedRing={2} isForSuperMaster={false} nothingOnRejected={true}>
                                    <Link
                                        id={"edit-btn"}
                                        to={`/branch/edit/${branchId}`}
                                    >
                                        {t("button.edit")}
                                    </Link>
                                </RenderOnRole>
                            </div>
                            <div>
                                <Link
                                    id={"save-btn"}
                                    to={"/branch"}
                                >
                                    {t("button.complete")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </RenderOnRole>
    );
};

export default BranchDetailUSA;