import axios from "axios";
import UserService from "../../../../../services/UserService";

const baseUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`;


const fetchBoothMenuList = async (boothId, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/booth-menu/booth/${boothId}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data.item);
    } catch (e) {
        onFailureCallback(e);
    }
};

const fetchBoothInfo = async (boothId, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/booth/${boothId}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data.item);
    } catch (e) {
        onFailureCallback(e);
    }
};

const fetchProductSchema = async (boothVersionId, productSchemaVersion, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/booth-menu/booth-version/${boothVersionId}/product-schema/${productSchemaVersion}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data.item);
    } catch (e) {
        onFailureCallback(e);
    }
};

const fetchOptionSchema = async (boothVersionId, optionSchemaVersion, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/booth-menu/booth-version/${boothVersionId}/option-schema/${optionSchemaVersion}`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data.item);
    } catch (e) {
        onFailureCallback(e);
    }
};

const putBoothMenuDiff = async (boothId, menuId, menuDetailId, requestBody, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/booth-menu`
            + `/booth/${boothId}`
            + `/menu/${menuId}`
            + `/menu-detail/${menuDetailId}/diff`;
        const axiosCall = () => axios.put(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data);
    } catch (e) {
        onFailureCallback(e);
    }
};

const putBoothMenuOptionDiff = async (boothId, menuId, optionId, optionDetailId, requestBody, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/booth-menu`
            + `/booth/${boothId}`
            + `/menu/${menuId}`
            + `/option/${optionId}`
            + `/option-detail/${optionDetailId}/diff`;
        const axiosCall = () => axios.put(
            fetchUrl,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data);
    } catch (e) {
        onFailureCallback(e);
    }
};

const getBoothMenuSyncInfo = async (boothId, menuId, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/booth-menu/booth/${boothId}/menu/${menuId}/booth-sync-status`;
        const axiosCall = () => axios.get(
            fetchUrl,
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res.data.item);
    } catch (e) {
        onFailureCallback(e);
    }
};

const tryBoothMenuSyncInfo = async (boothId, menuId, onSuccessCallback, onFailureCallback) => {
    try {
        let fetchUrl = `${baseUrl}/booth-menu/booth/${boothId}/menu/${menuId}/booth-sync-status`;
        const axiosCall = () => axios.put(
            fetchUrl,
            "{}",
            {
                headers: {
                    Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                    "Content-Type" : "application/json"
                }
            }
        );
        const res = await UserService.updateToken(axiosCall);
        onSuccessCallback(res);
    } catch (e) {
        onFailureCallback(e);
    }
};

const BoothMenuDiffAxiosService = {
    fetchBoothMenuList,
    fetchBoothInfo,
    fetchProductSchema,
    fetchOptionSchema,
    putBoothMenuDiff,
    putBoothMenuOptionDiff,
    getBoothMenuSyncInfo,
    tryBoothMenuSyncInfo
};

export default BoothMenuDiffAxiosService;