import React, {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {Container, Image, Row} from "react-bootstrap";
import uuid from "react-uuid";

const ImageUploadBox = (props) => {

    const fileRef = useRef();

    /* props */
    /* boxKey, imgFile, setImgFile, buttonText, setImageFileElement (onChange event handler) */

    const handleFileChange = async () => {
        const file = fileRef.current.files[0];
        props.setImageFileElement(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            props.setImgFile(reader.result);
        };
    };

    return (
        <div
            style={{
                width: '100%',
                display: 'inline-flex',
                flexDirection: 'column'
            }}
        >
            <div
                style={{
                    width: '100%',
                    textAlign: 'center'
                }}
            >
                <Image
                    src={
                        props.imgFile === "" ?
                            "https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png"
                            :
                            props.imgFile
                    }
                    alt=""
                    onError={(e) => e.currentTarget.src="https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png"}
                    roundedCircle={false}
                    style={{ width: '100px', height: '100px', display: 'inline-block', border: '0'}}
                />
            </div>
            <div
                style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '5px'
                }}
            >
                <label className="signup-profileImg-label" htmlFor={props.boxKey + "-profileImg"}>
                    {props.buttonText}
                </label>
                <input
                    type="file"
                    accept="image/*"
                    id={props.boxKey + "-profileImg"}
                    onChange={() => handleFileChange()}
                    ref={fileRef}
                />
            </div>
        </div>
    );
};

export default ImageUploadBox;