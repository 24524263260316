import React from 'react';
import Modal from "react-modal";
import {Spinner} from "react-bootstrap";

const OnUploadModal = ({ isOpen }) => {

    const modalStyle = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            color: 'white',
            width: '100px',
            height: '100px',
            margin: 'auto',
            background: 'transparent',
            border: 'none',
            textAlign: 'center'
        }
    };

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
        >
            <Spinner />
        </Modal>
    );
};

export default OnUploadModal;