import React from 'react';

const DivWithTitle = ({ title, children }) => {

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                padding: '10px'
            }}
        >
            {/* title */}
            <div
                style={{
                    width: '100%',
                    textAlign: 'left',
                    paddingLeft: '5px',
                    paddingTop: '5px'
                }}
            >
                <span
                    style={{
                        color: '#757575',
                        fontSize: '15px'
                    }}
                >
                    {title}
                </span>
            </div>
            {/* children */}
            <div
                style={{
                    width: '100%',
                    marginTop: '10px'
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default DivWithTitle;