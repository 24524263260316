import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import ModalCloseButton from "../../../common/modal/ModalCloseButton";
import UserService from "../../../../services/UserService";
import axios from "axios";
import DataCompareService from "../../../../services/DataCompareService";
import DataUtils from "../../common/utils/DataUtils";
import TitleAndSwitchBox from "../../../common/dataView/TitleAndSwitchBox";
import ImageUploadBox from "../../common/image/ImageUploadBox";
import TitleAndInputBox from "../../../common/dataView/TitleAndInputBox";
import TitleAndTextArea from "../../../common/dataView/TitleAndTextArea";
import TitleAndSelectBox from "../../../common/dataView/TitleAndSelectBox";
import OrangeButton from "../../common/buttons/OrangeButton";
import ButtonWithSpinner from "../../common/buttons/ButtonWithSpinner";
import _ from "lodash";
import ExtraInformationView from "../../common/extrainfo/ExtraInformationView";
import LanguageInput from "../../../../language/component/LanguageInput";
import LanguageSelectBox from "../../../common/lang/LanguageSelectBox";
import {toast} from "react-toastify";
import ToastAlertView from "../../../common/alert/ToastAlertView";

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '650px',
        height: '835px',
        margin: 'auto'
    }
};

const requestBodyInit = {
    franchiseMenuAlias : "",
    franchiseMenuAlias1 : null,
    franchiseMenuAlias2 : null,
    franchiseMenuAlias3 : null,
    franchiseMenuAlias4 : null,
    franchiseMenuAlias5 : null,
    franchiseMenuDescription : "",
    franchiseMenuDescription1 : "",
    franchiseMenuDescription2 : "",
    franchiseMenuDescription3 : "",
    franchiseMenuDescription4 : "",
    franchiseMenuDescription5 : "",
    isBoothMenu : true,
    franchiseMenuPrice : 0,
    franchiseMenuPriceUnit: "",
    franchiseMenuCategoryId: "",
    extraInformationList: []
};

const isValidMapInit = {
    franchiseMenuAlias : false,
    franchiseMenuAlias1 : true,
    franchiseMenuAlias2 : true,
    franchiseMenuAlias3 : true,
    franchiseMenuAlias4 : true,
    franchiseMenuAlias5 : true,
    franchiseMenuDescription : false,
    franchiseMenuDescription1 : true,
    franchiseMenuDescription2 : true,
    franchiseMenuDescription3 : true,
    franchiseMenuDescription4 : true,
    franchiseMenuDescription5 : true,
    isBoothMenu : true,
    franchiseMenuPrice : true,
    franchiseMenuPriceUnit: false,
    franchiseMenuCategoryId: false,
    extraInformationList: true
};

const emptyCategoryList = [{
    franchiseMenuCategoryLookupFranchiseId : "",
    franchiseMenuCategoryLookupName : "None",
    franchiseMenuCategoryLookupName1 : "None",
    franchiseMenuCategoryLookupName2 : "None",
    franchiseMenuCategoryLookupName3 : "None",
    franchiseMenuCategoryLookupName4 : "None",
    franchiseMenuCategoryLookupName5 : "None",
}];

const FranchiseMenuAddModal = ({ isOpen, setIsOpen, onRefresh }) => {

    const { t, i18n } = useTranslation();
    const { franchiseId } = useParams();

    /* ref and state for image */
    const [imageFileElement, setImageFileElement] = useState();
    const [imgFile, setImageFile] = useState("");

    /* states */
    const [menuCategoryList, setMenuCategoryList] = useState(emptyCategoryList);
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(false);

    /* state for language */
    const targetFieldsString = `"${t("menu.franchiseMenu.menuInfo.detail.name")}", "${t("menu.franchiseMenu.menuInfo.detail.description")}"`;
    const [languageMetadataList, setLanguageMetadataList] = useState(
        sessionStorage.getItem("ims_server_lang_list")
            ?
            JSON.parse(sessionStorage.getItem("ims_server_lang_list"))
            :
            [{
                "languageMetadataBrowserCode" : "en-US",
                "languageMetadataLabel" : "English"
            }]
    );
    const [currentLang, setCurrentLang] = useState("en-US");
    const [isHighlightOn, setIsHighlightOn] = useState(false);

    /* loading */
    const [isLoading, setIsLoading] = useState(false);

    /* fetch category list */
    const fetchCategoryList = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/franchise-menu-category/franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`
                    }
                }
            ));
            let newMenuCategoryList = res.data.item['franchiseMenuCategoryList'];
            if (newMenuCategoryList.length > 0) {
                setMenuCategoryList(newMenuCategoryList);
                /* default value set */
                handleMenuCategoryIdInput(newMenuCategoryList[0]['franchiseMenuCategoryLookupId'])
            } else {
                setMenuCategoryList(emptyCategoryList);
            }
        } catch (e) {
            console.log(e);
            setMenuCategoryList(emptyCategoryList);
        }
    };

    /* post a new menu */
    const postMenu = async () => {
        await setIsLoading(true);
        /* extra information setup */
        let newRequestBody = _.cloneDeep(requestBody);
        if (newRequestBody['extraInformationList'].length > 0) {
            newRequestBody['extraInformation'] = DataUtils.formatObjArrayToObj(newRequestBody['extraInformationList']);
            delete newRequestBody['extraInformationList'];
        } else {
            delete newRequestBody['extraInformationList'];
        }
        /* axios call */
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/franchise-menu/franchise/${franchiseId}`;
            const res = await UserService.updateToken(() => axios.post(
                fetchUrl,
                JSON.stringify(newRequestBody),
                {
                    headers: {
                        Authorization: `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type" : "application/json"
                    }
                }
            ));
            let generatedMenuId = res.data.item['generated franchiseMenu info']['franchiseMenuId'];
            /* success */
            /* check image existence */
            if (imgFile !== "") {
                /* upload image */
                await uploadImage(generatedMenuId);
            } else {
                toast.success(<ToastAlertView message={t("message.saved")} />);
                onRefresh(`/man-menu-franchise/franchise/${franchiseId}/menu/${generatedMenuId}/info`);
                setIsOpen(false);
            }
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
        setIsLoading(false);
    };

    /* upload image */
    const uploadImage = async (menuId) => {
        const formData = new FormData();
        formData.append("file", imageFileElement);
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1`
                + `/image/menu/${menuId}`;
            const axiosCall = () => axios.post(
                fetchUrl,
                formData,
                {
                    headers: {
                        Authorization : `Bearer ${window.sessionStorage.getItem("ims_accessToken")}`,
                        "Content-Type": "multipart/form-data",
                    }
                }
            );
            await UserService.updateToken(axiosCall);
            /* success */
            toast.success(<ToastAlertView message={t("message.saved")} />);
            onRefresh(`/man-menu-franchise/franchise/${franchiseId}/menu/${menuId}/info`);
            setIsOpen(false);
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failedToSaveImage")} />);
            onRefresh();
            setIsOpen(false);
        }
    };

    /* initialization */
    const initialization = async () => {
        await setMenuCategoryList(emptyCategoryList);
        await setRequestBody(requestBodyInit);
        await setIsValidMap(isValidMapInit);
        await setIsValid(false);
        await setImageFile("");
        await setIsLoading(false);
        fetchCategoryList();
    };

    /* input handlers */

    const handleIsBoothMenuChange = (value) => {
        DataUtils.updateHelper(setRequestBody, "isBoothMenu", value);
        DataUtils.updateHelper(setIsValidMap, "isBoothMenu", true);
    };

    const handleMenuPriceUnitInput = (value) => {
        DataUtils.updateHelper(setRequestBody, "franchiseMenuPriceUnit", value);
        DataUtils.updateHelper(setIsValidMap, "franchiseMenuPriceUnit", !DataUtils.isEmptyStr(value));
    };

    const handleMenuCategoryIdInput = (value) => {
        DataUtils.updateHelper(setRequestBody, "franchiseMenuCategoryId", value);
        DataUtils.updateHelper(setIsValidMap, "franchiseMenuCategoryId", !DataUtils.isEmptyStr(value));
    };

    const handleMenuPriceInput = (value) => {
        DataUtils.updateHelper(setRequestBody, "franchiseMenuPrice", parseFloat(value));
        DataUtils.updateHelper(setIsValidMap, "franchiseMenuPrice", DataUtils.isValidNumStr(value));
    };

    const handleExtraInfoChange = (newObjArray) => {
        DataUtils.updateHelper(setRequestBody, "extraInformationList", newObjArray);
    };

    /* effects */
    useEffect(() => {
        initialization();
    }, [isOpen]);

    /* track isValid */
    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <Modal
            style={modalStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                if (!isLoading) {
                    setIsOpen(false);
                }
            }}
        >
            <Container fluid>
                <Row>
                    <ModalCloseButton
                        title={t("menu.franchiseMenu.button.addFranchiseMenu")}
                        setIsOpen={setIsOpen}
                    />
                </Row>
                <Row>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start'
                        }}
                    >
                        {/* language selection */}
                        <LanguageSelectBox
                            title={t("language.selectBox.title.menu")}
                            languageMetadataList={languageMetadataList}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}
                            setIsHighlightOn={setIsHighlightOn}
                            targetFieldString={targetFieldsString}
                        />
                        {/* isBoothMenu */}
                        <div
                            style={{
                                display: 'inline-block',
                                marginLeft: 'auto'
                            }}
                        >
                            <TitleAndSwitchBox
                                title={t("menu.franchiseMenu.menuInfo.detail.isBoothMenu")}
                                value={requestBody["isBoothMenu"]}
                                onChange={handleIsBoothMenuChange}
                                isForEdit={true}
                                isEnabled={true}
                            />
                        </div>
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '10px'
                    }}
                >
                    <Col
                        md={"3"}
                        style={{
                            padding: '0px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: '15px'
                            }}
                        >
                            {/* image and upload button */}
                            <ImageUploadBox
                                boxKey={franchiseId + "-menu-profile-pic"}
                                imgFile={imgFile}
                                setImgFile={setImageFile}
                                buttonText={t("menu.franchiseMenu.button.uploadImage")}
                                setImageFileElement={setImageFileElement}
                                uploadCallback={() => {}}
                            />
                        </div>
                    </Col>
                    <Col
                        // md={"9"}
                        style={{
                            padding: '0px',
                            paddingLeft: '10px'
                        }}
                    >
                        {/* menu alias, menu price info */}
                        <Row>
                            <Col>
                                {/* menuAlias */}
                                <LanguageInput
                                    type={"text"}
                                    title={t("menu.franchiseMenu.menuInfo.detail.name")}
                                    dataKeyTranslationPath={"language.dataKey.menu.franchise.info.name"}
                                    valueMap={requestBody}
                                    updateIsValidMap={(key, value) => DataUtils.updateHelper(setIsValidMap, key, value)}
                                    updateValueMap={(key, value) => DataUtils.updateHelper(setRequestBody, key, value)}
                                    isValidMap={isValidMap}
                                    isForEdit={true}
                                    currentLang={currentLang}
                                    isHighlightOn={isHighlightOn}
                                />
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: '5px'
                            }}
                        >
                            <Col>
                                {/* menuPrice */}
                                <TitleAndInputBox
                                    title={t("menu.franchiseMenu.menuInfo.detail.price")}
                                    value={requestBody["franchiseMenuPrice"]}
                                    onChange={handleMenuPriceInput}
                                    isForEdit={true}
                                    type={"number"}
                                    isValid={isValidMap["franchiseMenuPrice"]}
                                    isEnabled={true}
                                />
                            </Col>
                            <Col>
                                {/* menuPriceUnit */}
                                <TitleAndInputBox
                                    title={t("menu.franchiseMenu.menuInfo.detail.priceUnit")}
                                    value={requestBody["franchiseMenuPriceUnit"]}
                                    onChange={handleMenuPriceUnitInput}
                                    isForEdit={true}
                                    type={"text"}
                                    isValid={isValidMap["franchiseMenuPriceUnit"]}
                                    isEnabled={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* menu category */}
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <TitleAndSelectBox
                        title={t("menu.franchiseMenu.menuInfo.detail.category")}
                        innerValue={requestBody["franchiseMenuCategoryId"]}
                        valueList={menuCategoryList}
                        valueSelector={"franchiseMenuCategoryLookupId"}
                        viewSelector={t("language.dataKey.menu.detail.category")}
                        onChange={handleMenuCategoryIdInput}
                    />
                </Row>
                {/* menu description */}
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {/* menu description */}
                    <LanguageInput
                        type={"textarea"}
                        title={t("menu.franchiseMenu.menuInfo.detail.description")}
                        dataKeyTranslationPath={"language.dataKey.menu.franchise.info.description"}
                        valueMap={requestBody}
                        updateIsValidMap={(key, value) => DataUtils.updateHelper(setIsValidMap, key, value)}
                        updateValueMap={(key, value) => DataUtils.updateHelper(setRequestBody, key, value)}
                        isValidMap={isValidMap}
                        height={"150"}
                        isForEdit={true}
                        currentLang={currentLang}
                        isHighlightOn={isHighlightOn}
                    />
                </Row>
                {/* extra information */}
                <Row
                    style={{
                        marginTop: '5px'
                    }}
                >
                    <ExtraInformationView
                        dataObjList={requestBody['extraInformationList']}
                        setDataObjList={handleExtraInfoChange}
                        setIsValidMap={setIsValidMap}
                        objKey={"extraInformationList"}
                        title={t("menu.franchiseMenu.menuInfo.detail.extraInfo.title")}
                        height={"135"}
                        isForEdit={true}
                    />
                </Row>
                {/* save button */}
                <Row
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {
                        isLoading ?
                            <ButtonWithSpinner
                                buttonColor={"#fc7242"}
                            />
                            :
                            <OrangeButton
                                onClickCallback={postMenu}
                                isEnabled={isValid}
                                label={t("button.save")}
                            />
                    }
                </Row>
            </Container>
        </Modal>
    );
};

export default FranchiseMenuAddModal;