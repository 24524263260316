import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import DataCompareService from "../../services/DataCompareService";
import {Col, Container, Image, Row} from "react-bootstrap";
import ImageUpload from "../common/image/ImageUpload";
import AuthService from "../../services/AuthService";
import TitleAndInputBox from "../common/dataView/TitleAndInputBox";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Modal from "react-modal";
import UserService from '../../services/UserService';
import BackwardButton from "../common/layoutSub/BackwardButton";
import PageTitleWithDepth from "../common/layoutSub/PageTitleWithDepth";
import ModalCloseButton from "../common/modal/ModalCloseButton";
import {toast} from "react-toastify";
import ToastAlertView from "../common/alert/ToastAlertView";

const passwordModalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        color: 'black',
        width: '500px',
        height: '460px',
        margin: 'auto'
    }
}

const requestBodyInit = {
    adminName: "",
    adminEmail: "",
    adminPhone: ""
};

const isValidMapInit = {
    adminName: true,
    adminEmail: true,
    adminPhone: true
}

const MyPage = () => {

    const navigate = useNavigate();
    /* session information */
    const adminId = window.sessionStorage.getItem("ims_adminId");
    /* translation */
    const { t } = useTranslation();
    /* information */
    const [adminInfo, setAdminInfo] = useState({});
    /* password modal */
    const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);
    /* password variable */
    const [passwordInput, setPasswordInput] = useState("");
    const [passwordVerifyInput, setPasswordVerifyInput] = useState("");
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isValidPasswordVerify, setIsValidPasswordVerify] = useState(false);
    /* for editing */
    const [requestBody, setRequestBody] = useState(requestBodyInit);
    const [fixedRequestBody, setFixedRequestBody] = useState(requestBodyInit);
    const [isValidMap, setIsValidMap] = useState(isValidMapInit);
    const [isValid, setIsValid] = useState(true);
    const [isEdited, setIsEdited] = useState(false);
    /* image edit */
    const [isImageEditModalOpen ,setIsImageEditModalOpen] = useState(false);

    /* initialize */
    const initializeAll = async () => {
        await setIsValidMap(isValidMapInit);
        await setIsValid(true);
        await setIsEdited(false);
        await fetchAdminInfo();
    };

    /* fetchAdminInfo */
    const fetchAdminInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${adminId}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            setAdminInfo(res.data.item.adminInfo);
            let requestDataObj = {
                adminName : res.data.item.adminInfo.adminName,
                adminEmail : res.data.item.adminInfo.adminEmail,
                adminPhone : res.data.item.adminInfo.adminPhone
            };
            setRequestBody(DataCompareService.deepCopyObj(requestDataObj));
            setFixedRequestBody(DataCompareService.deepCopyObj(requestDataObj));
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) { /* not found */
                navigate('/not-found');
            }
        }
    };

    /* password reset */
    const resetPassword = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${adminId}/password`;
            const res = await UserService.updateToken(() => axios.put(fetchUrl ,
                JSON.stringify({
                    "adminPassword" : passwordInput
                }),
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            setIsPasswordResetModalOpen(false);
            navigate(`/my-page`);
        } catch (e) {
            toast.error(<ToastAlertView message={t("message.failed")} />);
            setIsPasswordResetModalOpen(false);
            navigate(`/my-page`);
        }
    };

    /* put self information */
    const putAdminInfo = async () => {
        try {
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/${adminId}/self`;
            const res = await UserService.updateToken(() => axios.put(
                fetchUrl ,
                JSON.stringify(requestBody),
                {
                    headers: {
                        "Authorization" : "Bearer " + sessionStorage.getItem("ims_accessToken"),
                        "Content-Type" : "application/json"
                    }
                }
            ));
            toast.success(<ToastAlertView message={t("message.saved")} />);
            await initializeAll();
        } catch (e) {
            console.log(e);
            toast.error(<ToastAlertView message={t("message.failed")} />);
        }
    }

    /* utils */
    const updateIsValidMap = (key, value) => {
        setIsValidMap(prevObj => {
           let newObj = {...prevObj};
           newObj[key] = value;
           return newObj;
        });
    };

    const updateRequestBody = (key, value) => {
        setRequestBody(prevObj => {
            let newObj = {...prevObj};
            newObj[key] = value;
            return newObj;
        });
    };

    /* handlers */
    const handlePasswordInput = (value) => {
        setPasswordInput(value);
    };

    const handlePasswordValidationInput = (value) => {
        setPasswordVerifyInput(value);
    };

    const handleEmailInput = (value) => {
        updateRequestBody("adminEmail", value);
    };

    const handlePhoneInput = (value) => {
        updateRequestBody("adminPhone", value);
    };

    const handleNameInput = (value) => {
        updateRequestBody("adminName", value);
    };


    /* validation */
    const checkPasswordValidity = () => {
        let validity = DataCompareService.regexValidator(passwordInput, "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&]{8,20}$");
        setIsValidPassword(validity);
    };

    const checkEmailValidity = async () => {
        try {
            const isValidForm = DataCompareService.regexValidator(requestBody["adminEmail"], "^[0-9a-zA-Z-_]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\\.[a-zA-Z]+$") &&
                (requestBody["adminEmail"].length <= 40) &&
                (requestBody["adminEmail"].length >= 6);
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/check/email/duplicate?target=${requestBody["adminEmail"]}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl ,
                {
                    headers: {
                        Authorization : "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            const isDuplicate = res.data.item.result;
            if (requestBody["adminEmail"] === fixedRequestBody["adminEmail"]) {
                updateIsValidMap("adminEmail", isValidForm);
            } else {
                updateIsValidMap("adminEmail", isValidForm && (!isDuplicate));
            }
        } catch (e) {
            console.log(e);
            updateIsValidMap("adminEmail", false);
        }
    };

    const checkPhoneValidity = async () => {
        try {
            const isValidForm = true; // no form
            let fetchUrl = `${process.env.REACT_APP_IMS_SERVER_URL}:${process.env.REACT_APP_IMS_SERVER_PORT}/api/v1/admin/check/phone/duplicate?target=${requestBody["adminPhone"]}`;
            const res = await UserService.updateToken(() => axios.get(
                fetchUrl,
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("ims_accessToken")
                    }
                }
            ));
            const isDuplicate = res.data.item.result;
            if (requestBody["adminPhone"] === fixedRequestBody["adminPhone"]) {
                updateIsValidMap("adminPhone", isValidForm);
            } else {
                updateIsValidMap("adminPhone", isValidForm && (!isDuplicate));
            }
        } catch (e) {
            console.log(e);
            updateIsValidMap("adminPhone", false);
        }
    };

    const checkNameValidity = async () => {
        try {
            const isValidForm = DataCompareService.regexValidator(requestBody["adminName"], "^[가-힣]{1}[가-힣\\s]{0,15}[가-힣]{1}$|^[a-zA-Z]{1}[a-zA-Z\\s]{0,98}[a-zA-Z]{1}$") &&
                (requestBody["adminName"].length <= 100) &&
                (requestBody["adminName"].length >= 2);;

            updateIsValidMap("adminName", isValidForm);
        } catch (e) {
            console.log(e);
            updateIsValidMap("adminName", false);
        }
    };

    /* effects */
    useEffect(() => {
        initializeAll();
    }, [adminId]);

    /* check password validity */
    useEffect(() => {
        checkPasswordValidity();
    }, [passwordInput]);

    /* check password verification */
    useEffect(() => {
        setIsValidPasswordVerify(passwordInput === passwordVerifyInput);
    }, [passwordVerifyInput]);

    /* input values */
    useEffect(() => {
        if (typeof requestBody["adminEmail"] === "undefined") {
            return;
        }
        checkEmailValidity();
    }, [requestBody["adminEmail"]]);

    useEffect(() => {
        if (typeof requestBody["adminPhone"] === "undefined") {
            return;
        }
        checkPhoneValidity();
    }, [requestBody["adminPhone"]]);

    useEffect(() => {
        if (typeof requestBody["adminName"] === "undefined") {
            return;
        }
        checkNameValidity();
    }, [requestBody["adminName"]]);

    useEffect(() => {
        setIsEdited(!DataCompareService.isSameObj(requestBody, fixedRequestBody));
    }, [requestBody]);

    useEffect(() => {
        setIsValid(DataCompareService.checkIsAllTrue(isValidMap));
    }, [isValidMap]);

    return (
        <>
            {/* title */}
            <div
                className={"headingTitle"}
            >
                <BackwardButton
                    isShow={false}
                />
                <PageTitleWithDepth
                    titles={[t("common.myPage.title")]}
                    depth={1}
                />
            </div>

            <div className={"contentContainer"}>
                <div className={"contentInner"}>
                <Container fluid>
                    <Row>
                        {/* main area */}
                        <div
                            style={{
                                width: '100%',
                                height: '700px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {/* top info */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '200px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'left'
                                    }}
                                >
                                    <div>
                                        <Image
                                            src={
                                                (adminInfo.adminImageUrl == null) ?
                                                    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                    :
                                                    `${adminInfo.adminImageUrl}`
                                            }
                                            alt="Profile Image"
                                            roundedCircle
                                            style={{ width: '70px', height: '70px', display: 'inline-block', border: '0'}}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: '20px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <div
                                            style={{ textAlign: 'left' }}
                                        >
                                            <span
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {adminInfo.adminName}
                                            </span>
                                        </div>
                                        <div
                                            style={{ textAlign: 'left' }}
                                        >
                                            <button
                                                style={{
                                                    background: 'none',
                                                    border: 'none',
                                                    padding: '0px',
                                                    textDecoration: 'underline',
                                                    fontSize: '12px',
                                                    color: '#656565'
                                                }}
                                                onClick={() => setIsImageEditModalOpen(true)}
                                            >
                                                {t("button.editImage")}
                                            </button>
                                            <ImageUpload
                                                isOpen={isImageEditModalOpen}
                                                setIsOpen={setIsImageEditModalOpen}
                                                usage={"admin"}
                                                usageId={adminId}
                                                onRefresh={initializeAll}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* middle info */}
                            <div
                                style={{
                                    borderTop: '1px solid #c2c2c2',
                                    width: '100%',
                                    height: '450px',
                                    display: 'flex',
                                    overflow: 'auto',
                                    padding: '20px 0px 20px 0px'
                                }}
                            >
                                <Container fluid>
                                    <Row>
                                        <TitleAndInputBox
                                            title={t("common.id")}
                                            value={adminInfo.adminId}
                                            isForEdit={false}
                                            type={"text"}
                                            isValid={true}
                                            onChange={() => {}}
                                        />
                                    </Row>
                                    <Row
                                        style={{ marginTop: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.email")}
                                            value={requestBody["adminEmail"]}
                                            isForEdit={true}
                                            type={"text"}
                                            isValid={isValidMap["adminEmail"]}
                                            onChange={handleEmailInput}
                                        />
                                    </Row>
                                    <Row
                                        style={{ marginTop: '10px' }}
                                    >
                                        <TitleAndInputBox
                                            title={t("common.name")}
                                            value={requestBody["adminName"]}
                                            isForEdit={true}
                                            type={"text"}
                                            isValid={isValidMap["adminName"]}
                                            onChange={handleNameInput}
                                        />
                                    </Row>
                                    <Row
                                        style={{ marginTop: '10px' }}
                                    >
                                        <Col
                                            md={"1"}
                                            style={{ padding: '0px' }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.phone")}
                                                value={"+82"}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                        <Col
                                            md={"11"}
                                            style={{ padding: '0px', paddingLeft: '10px' }}
                                        >
                                            <TitleAndInputBox
                                                title={"　"}
                                                value={requestBody["adminPhone"]}
                                                isForEdit={true}
                                                type={"text"}
                                                isValid={isValidMap["adminPhone"]}
                                                onChange={handlePhoneInput}
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{ marginTop: '10px' }}
                                    >
                                        <Col
                                            style={{ padding: '0px' }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.group")}
                                                value={adminInfo.adminGroupName}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                        <Col
                                            style={{ padding: '0px', paddingLeft: '10px' }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.company")}
                                                value={adminInfo.adminCompanyName}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                        <Col
                                            style={{ padding: '0px', paddingLeft: '10px' }}
                                        >
                                            <TitleAndInputBox
                                                title={t("common.department")}
                                                value={adminInfo.adminDepartmentName}
                                                isForEdit={false}
                                                type={"text"}
                                                isValid={true}
                                                onChange={() => {}}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            {/* bottom btn */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'right'
                                }}
                            >
                                <div
                                    style={{ marginRight: 'auto' }}
                                >
                                    <Link
                                        id={"reset-password-btn"}
                                        onClick={() => setIsPasswordResetModalOpen(true)}
                                    >
                                        {t("button.resetPassword")}
                                    </Link>
                                </div>
                                <div
                                    style={{ marginRight: '10px' }}
                                >
                                    <Link
                                        id={"edit-btn"}
                                        to={`/`}
                                    >
                                        {t("button.cancel")}
                                    </Link>
                                </div>
                                <div>
                                    {
                                        (isEdited && isValid)
                                            ?
                                            <Link
                                                id={"save-btn"}
                                                onClick={() => putAdminInfo()}
                                            >
                                                {t("button.save")}
                                            </Link>
                                            :
                                            <Link
                                                id={"save-btn-disabled"}
                                            >
                                                {t("button.save")}
                                            </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
                {/* password reset modal */}
                <Modal
                    isOpen={isPasswordResetModalOpen}
                    onRequestClose={() => setIsPasswordResetModalOpen(false)}
                    style={passwordModalStyle}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <ModalCloseButton
                            title={t("button.resetPassword")}
                            setIsOpen={setIsPasswordResetModalOpen}
                        />
                        <div
                            style={{ marginTop: '30px' }}
                        >
                            <span
                                style={{
                                    color: '#8f8f8f',
                                    fontSize: '12px',
                                    marginLeft: '10px'
                                }}
                            >
                                {t("common.password")}
                            </span>
                        </div>
                        <div>
                            <input
                                id={"data-view-input"}
                                type={"password"}
                                value={passwordInput}
                                onChange={(e) => handlePasswordInput(e.target.value)}
                                style={isValidPassword ? {} : { borderColor: 'red' }}
                            />
                        </div>
                        <div
                            style={{
                                marginTop: '20px'
                            }}
                        >
                            <span
                                style={{
                                    color: '#8f8f8f',
                                    fontSize: '12px',
                                    marginLeft: '10px'
                                }}
                            >
                                {t("common.passwordVerify")}
                            </span>
                        </div>
                        <div>
                            <input
                                id={"data-view-input"}
                                type={"password"}
                                value={passwordVerifyInput}
                                onChange={(e) => handlePasswordValidationInput(e.target.value)}
                                style={isValidPasswordVerify ? {} : { borderColor: 'red' }}
                            />
                        </div>
                        <div
                            style={{
                                marginTop: '30px'
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '12px',
                                    color: '#c2c2c2'
                                }}
                            >
                                {t("message.passwordRule")}
                            </span>
                        </div>
                        <div
                            style={{
                                marginTop: '50px'
                            }}
                        >
                            {
                                isValidPassword && isValidPasswordVerify ?
                                    <button
                                        id={"long-save-btn"}
                                        onClick={() => resetPassword()}
                                    >
                                        {t("button.save")}
                                    </button>
                                    :
                                    <button
                                        id={"long-save-btn-disabled"}
                                    >
                                        {t("button.save")}
                                    </button>
                            }
                        </div>
                    </div>
                </Modal>
                </div>
            </div>
        </>
    );
};

export default MyPage;